import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faCircleXmark, faBolt, faEllipsis, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { MultiSelect } from 'react-multi-select-component';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargoplane from "../../../assets/images/plane.svg";
import Cuser from "../../../assets/images/cuser.png";
import Trash from '../../../assets/images/trash.png';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDREPAIRGRID, GETDAMAGETYPE, GETULDDETAILS } from '../../../Utils/conts.js';
import ActivityComments from '../../../Componenets/common/ActivityComments.js';
import ImageUpload from '../../../Componenets/common/ImageUpload.js';
import DownloadGrid from './components/DownloadGrid.js';

const Repair = () => {
    const isMounted = useRef(false);
    const [totalRepair, setTotalRepair] = useState({});
    const [totalDam, setTotalDam] = useState({});
    const [totalDis, setTotalDis] = useState({});
    const [gridData, setGridData] = useState({
        Repair: [],
        Damaged: [],
        Discarded: [],

    });
    const fetchDataGrid = async () => {
            try {
                const res = await withRFSToken.post(ULDREPAIRGRID) // { type: 'SER' }
                    .then(function (res) {
                        const innerData = res.data.response_data.data;
                        // console.log("🚀 ~ .then ~ innerData:654", innerData)
                        setGridData({ ...gridData, Repair: innerData });
                        setTotalRepair(res.data.response_data.pagination)
                    });


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    useEffect(() => {
        
        if (isMounted.current) return;
        // Call the fetchData function to fetch data from the API
        fetchDataGrid();
        isMounted.current = true;
    }, []);

    const [damageType, setDamageType] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalRecordsR, setTotalRecordsR] = useState(0);

    const fetchDamageType = async () => {
        try {
            const res = await withRFSToken.post(GETDAMAGETYPE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setDamageType(innerData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const [activeTab, setActiveTab] = useState('Repair');
    const gridRefs = {
        Repair: useRef(null),
        Damaged: useRef(null),
        Discarded: useRef(null),
    };
    const handleTabChange = async (tab) => {
        setActiveTab(tab);
        let tabType = '';
        if (tab == 'Repair') { tabType = 'SER' } else if (tab == 'Damaged') { tabType = 'DAM' } else if (tab == 'Discarded') { tabType = 'DIS' }
        try {
            const res = await withRFSToken.post(ULDREPAIRGRID, { type: tabType });
            // .then(function (res) {
            if (res.data.response_code == 200) {
                const innerData = res.data.response_data.data;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                setGridData(prevData => ({
                    ...prevData,
                    [tab]: innerData
                }));
                if (tabType == 'SER') {
                    setTotalRepair(res.data.response_data.pagination)
                } else if (tabType == 'DAM') {
                    setTotalDam(res.data.response_data.pagination)
                } else if (tabType == 'DIS') {
                    setTotalDis(res.data.response_data.pagination)
                }
            }


            // });


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {

        if (gridRefs[activeTab].current) {
            if (gridData[activeTab]) {
                // const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                // }));
                // console.log("🚀 ~ localData ~ localData:", localData)
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'uld_number', type: 'string' },
                        { name: 'type_code', type: 'string' },
                        { name: 'serial_number', type: 'string' },
                        { name: 'owner_code', type: 'string' },
                        { name: 'condition', type: 'string' },
                        { name: 'condition_code', type: 'string' },
                        { name: 'repair_name', type: 'string' },
                        { name: 'repair_station', type: 'string' },
                        { name: 'repair_out', type: 'string' },
                        { name: 'repair_in', type: 'string' },
                        { name: 'damage_type', type: 'string' },
                        { name: 'damage_desc', type: 'string' },

                        { name: 'origin', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'damage_report', type: 'string' },

                        { name: 'discarded_date', type: 'string' },
                        { name: 'discarded_report', type: 'string' },
                        { name: 'discarded_desc', type: 'string' },
                    ],
                    localdata: gridData[activeTab],
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                // console.log('localdata', source.localdata)
                let columns;
                if (activeTab === 'Repair') {
                    columns = [
                        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                        {
                            text: 'Actions',
                            cellsrenderer: function () {
                                return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                            },
                            width: '5%', filterable: false,
                        },
                        { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
                        { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '6%', cellclassname: '', },
                        { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '8%', cellclassname: '', },
                        { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '6%', cellclassname: '', },
                        { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition', width: '8%', cellclassname: '',}, // checkedlist
                        { text: 'Repair Name', filtertype: 'textbox', datafield: 'repair_name', width: '8%', cellclassname: '', },
                        { text: 'Repair Station', filtertype: 'textbox', datafield: 'repair_station', width: '8%', cellclassname: '', },
                        { text: 'Repair Out', filtertype: 'textbox', datafield: 'repair_out',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
                            },
                            width: '8%', cellclassname: 'frcl',
                        },
                        {
                            text: 'Repair In', filtertype: 'textbox', datafield: 'repair_in',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
                            },
                            width: '8%', cellsformat: 'dd-MM-yyyy', cellclassname: 'frcl',
                        },
                        { text: 'Damage Type', filtertype: 'textbox', datafield: 'damage_type', width: '10%', cellclassname: '', },
                        { text: 'Damage Description', filtertype: 'textbox', datafield: 'damage_desc', width: '10%', cellclassname: '', },
                    ];
                } else if (activeTab === 'Damaged') {
                    columns = [
                        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                        {
                            text: 'Actions',
                            cellsrenderer: function () {
                                return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                            },
                            width: '5%', filterable: false,
                        },
                        { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '10%', cellclassname: '', },
                        { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '6%', cellclassname: '', },
                        { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '8%', cellclassname: '', },
                        { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '6%', cellclassname: '', },
                        { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition', width: '8%', cellclassname: '',},
                        { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                        { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                        { text: 'Status', filtertype: 'textbox', datafield: 'status', width: '8%', cellclassname: '', },
                        { text: 'Damage Report', filtertype: 'textbox', datafield: 'damage_report', width: '8%', cellclassname: '', },
                        { text: 'Damage Type', filtertype: 'textbox', datafield: 'damage_type', width: '8%', cellclassname: '', },
                        {
                            text: 'Repair In', filtertype: 'textbox', datafield: 'repair_in',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
                            },
                            width: '8%', cellclassname: 'frcl', cellsformat: 'dd-MM-yyyy',
                        },
                        {
                            text: 'Repair Out', filtertype: 'textbox', datafield: 'repair_out',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
                            },
                            width: '6%',
                        },
                    ];
                } else if (activeTab === 'Discarded') {
                    columns = [
                        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                        {
                            text: 'Actions',
                            cellsrenderer: function () {
                                return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                            },
                            width: '5%', filterable: false,
                        },
                        { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '10%', cellclassname: '', },
                        { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '6%', cellclassname: '', },
                        { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '8%', cellclassname: '', },
                        { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition', width: '8%', cellclassname: '', },
                        { text: 'Discarded Date', filtertype: 'textbox', cellsformat: 'dd-MM-yyyy', datafield: 'discarded_date', width: '8%', cellclassname: '', },
                        { text: 'Discarded Report', filtertype: 'textbox', datafield: 'discarded_report', width: '10%', cellclassname: '', },
                        { text: 'Discarded Desc.', filtertype: 'textbox', datafield: 'discarded_desc', width: '10%', cellclassname: '', },

                    ];
                }

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: true,
                    editable: false,
                    selectionmode: 'checkbox',
                    pageable: false,
                    pageSize: 10,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                };

                window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
                window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

                window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });
                // row selection by checkbox
                window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        const selectedRowId = selectedRow.uid;
                        if (!gridSelect.some(row => row.uid === selectedRowId)) {
                            setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData[activeTab];
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.uld_number;
                        console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                        setGridSelect(prevSelection => prevSelection.filter(row => row.uld_number !== unselectedRowId));
                    }
                });
                // End row selection 

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
        
        window.$(gridRefs[activeTab].current).off('cellclick');
        window.$(gridRefs[activeTab].current).on('cellclick', (event) => {
            const args = event.args.row.bounddata;
            if (event.args.columnindex > 1) {

                if (gridSelect) {
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                }
                loadDetails(args.uld_number);
            }
        });
    }, [activeTab, gridData, selectedRowData]);

    const loadDetails = async (uldNumber) => {
        try {
            const res = await withRFSToken.post(GETULDDETAILS, { uld_number: uldNumber })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setSelectedRowData(innerData);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [downloadOpen, setDownloadOpen] = useState(false);
    const showDownloadModal = () => {
        setDownloadOpen(true);
    };
    const closeDownloadModal = () => {
        setDownloadOpen(false);
    };



    // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider

    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFile('');
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image

    const handleConfirmChange = () => {
        // deleteRow(gridSelect);
        changeFunction(gridSelect)
        setShowConfirmation(false);
    };

    //   +++++++++ single select station +++++++
    let [selected, setSelected] = useState([]);
    if (selected.length > 1) {
        selected = selected.slice(1);
    }
    const options = [
        { id: '1', label: 'option1', value: 'option1' },
        { id: '1', label: 'option2', value: 'option2' },
        { id: '1', label: 'option3', value: 'option3' },
        { id: '1', label: 'option4', value: 'option4' },
    ];

    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [changeFunction, setchangeFunction] = useState(null);
    const [action, setAction] = useState(null);

    // const handleDropdownItemClick = (action) => {
    //     console.log("Event:", action);
    //     // event.preventDefault();
    //     // setShowConfirmation(true);
    //     const keysToRemove = ['Repair', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
    //     keysToRemove.forEach(key => {
    //         localStorage.removeItem(key);
    //     });

    //     if (selectedRowData) {
    //         if (['Repair', 'SCM', 'Lease Out'].includes(action)) {
    //             const storageData = {
    //                 "ULD": selectedRowData,  // ULD Details section
    //                 "Form": {}  // Empty form section
    //             };
    //             localStorage.setItem(action, JSON.stringify(storageData));
    //             localStorage.setItem('tab', 'repair');

    //             const uldId = selectedRowData.uld_number;
    //             console.log("🚀 ~ handleDropdownItemClick ~ uldId:", uldId)
    //             // const fnPageUrl = `/ULDControl/ULDFunction?tab=repair&ids=${uldId}&action=${action}`;  // ${itemId} Change "/details/" to the actual path of your details page
    //             const fnPageUrl = '/ULDControl/ULDFunction';
    //             // Navigate to the details page
    //             window.location.href = fnPageUrl;
    //         } else {
    //             if (action !== 'Condition') {
    //                 if (selectedRowData.status == action) {
    //                     toast.error('Status already in operation')
    //                 } else {
    //                     setAction(action);
    //                     setShowConfirmation(true);
    //                     setchangeFunction(() => changeStatus);
    //                 }
    //             } else {
    //                 setAction(action);
    //                 setShowConfirmation(true);
    //                 fetchDamageType();
    //                 setchangeFunction(() => changeStatus);
    //             }
    //             // setAction(action);
    //             // setShowConfirmation(true);
    //             // setchangeFunction(() => changeStatus);
    //         }
    //     } else if (gridSelect.length === 0) {
    //         toast.error('Please select atleast one stock to Operate')
    //     } else {
    //         if (['Repair', 'SCM', 'Lease Out'].includes(action)) {
    //             const storageData = {
    //                 "ULD": gridSelect,  // ULD Details section
    //                 "Form": {}  // Empty form section
    //             };
    //             localStorage.setItem(action, JSON.stringify(storageData));
    //             localStorage.setItem('tab', 'repair');

    //             const uldIds = gridSelect.map(item => item.uld_number);
    //             const fnPageUrl = `/ULDControl/ULDFunction?tab=repair&ids=${uldIds}&action=${action}`;  // ${itemId} Change "/details/" to the actual path of your details page
    //             // Navigate to the details page
    //             window.location.href = fnPageUrl;
    //         } else {
    //             if (action !== 'Condition') {
    //                 const checkStatus = gridSelect && gridSelect.find(status => status.status == action)
    //                 if (checkStatus) {
    //                     toast.error(`Status already ${action}`)
    //                 } else {
    //                     setAction(action);
    //                     setShowConfirmation(true);
    //                     setchangeFunction(() => changeStatus);
    //                 }
    //             } else {
    //                 setAction(action);
    //                 setShowConfirmation(true);
    //                 fetchDamageType();
    //                 setchangeFunction(() => changeStatus);
    //             }
    //         }

    //     }
    // }
    const handleDropdownItemClick = (pos, action) => {
        const keysToRemove = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
        keysToRemove.forEach(key => localStorage.removeItem(key));

        // Helper function to save data to localStorage and navigate to a URL
        const saveAndNavigate = (action, data) => {
            localStorage.setItem(action, JSON.stringify(data));
            localStorage.setItem('tab', 'repair');
            const fnPageUrl = '/ULDControl/ULDFunction';  // Replace with your dynamic path if needed
            window.location.href = fnPageUrl;
        };

        // Check if an action is already in operation
        const checkStatusAlreadyInOperation = (gridSelect, action) => {
            return gridSelect.some(item => item.status === action);
        };

        // Logic for handling 'details' position
        const handleDetailsAction = () => {
            if (['Repair In', 'Repair Out', 'SCM'].includes(action)) {
                const storageData = { "ULD": [selectedRowData], "Form": {} };
                saveAndNavigate(action, storageData);
            } else if (action === 'Lease Out') {
                if (selectedRowData.asset_type === 'Y') {
                    toast.error("ULDs with asset type Owned selected, this action is not possible.");
                } else {
                    const storageData = { "ULD": [selectedRowData], "Form": {} };
                    saveAndNavigate('Lease Out', storageData);
                }
            } else {
                if (action !== 'Condition') {
                    if (selectedRowData.status === action) {
                        toast.error('Status already in operation');
                    } else {
                        setAction(action);
                        setShowConfirmation(true);
                        setchangeFunction(() => changeStatus);
                    }
                } else {
                    setAction(action);
                    setShowConfirmation(true);
                    fetchDamageType();
                    setchangeFunction(() => changeStatus);
                }
            }
        };

        // Logic for handling 'top' position
        const handleTopAction = () => {
            if (gridSelect.length === 0) {
                if (['Repair In', 'Repair Out', 'SCM', 'Lease Out'].includes(action)) {
                    const storageData = { ULD: [], Form: {} };
                    saveAndNavigate(action, storageData);
                } else {
                    toast.error('Please select at least one stock to Operate');
                }
            } else {
                if (['Repair In', 'Repair Out', 'SCM'].includes(action)) {
                    const storageData = { ULD: gridSelect, Form: {} };
                    saveAndNavigate(action, storageData);
                } else if (action === 'Lease Out') {
                    const hasOwnedAssetType = gridSelect.some(item => item.asset_type === 'Y');
                    if (hasOwnedAssetType) {
                        toast.error("ULDs with asset type Owned selected, this action is not possible.");
                    } else {
                        const storageData = { ULD: gridSelect, Form: {} };
                        saveAndNavigate(action, storageData);
                    }
                } else {
                    if (action !== 'Condition') {
                        if (checkStatusAlreadyInOperation(gridSelect, action)) {
                            toast.error(`Status already ${action}`);
                        } else {
                            setAction(action);
                            setShowConfirmation(true);
                            setchangeFunction(() => changeStatus);
                        }
                    } else {
                        setAction(action);
                        setShowConfirmation(true);
                        fetchDamageType();
                        setchangeFunction(() => changeStatus);
                    }
                }
            }
        };

        // Main conditional logic based on the position (pos)
        if (pos === 'details') {
            handleDetailsAction();
        } else if (pos === 'top') {
            handleTopAction();
        }
    };

    const handleSelectChange = (selected) => {
        setSelected(selected);
    };
    //   +++++++++ single select station +++++++
    const handleRemove = (indexToRemove) => {
        const idToRemove = gridSelect[indexToRemove];
        const selectedRowBoundIndex = idToRemove.boundindex;
        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
        // setGridSelect(prevGridSelect => prevGridSelect.filter((_, index) => index !== indexToRemove));
        const newGridSelect = [...gridSelect.slice(0, indexToRemove), ...gridSelect.slice(indexToRemove + 1)];
        setGridSelect(newGridSelect);
    };

    const changeStatus = async (selectedIds) => {
        // console.log("🚀 ~ deleteSelectedRows ~ selectedIds:", selectedIds)

        //console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // try {
        //   const res = await withToken.post(TRASH_ROW_IDS, {booking_ids: uniqueIds});
        //   console.log("responsehg==>", res);
        //   window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
        //   const updategrid = window.$(gridRef.current).jqxGrid();
        //   // setupGrid();
        //   reloadGrid();
        //   setGridSelect([]);
        //   window.$(gridRef.current).jqxGrid('clearselection');

        //   // setTimeout(() => {
        //   //    window.location.replace('/')  
        //   // },1000);

        // } catch (error) {
        //   console.error('Error sending request:', error);
        // }
    };

    const [conditionChange, setConditionChange] = useState('Serviceable');

    const handleConditionRadioChange = (e) => {

        setConditionChange(e.target.value);
    };

    // upload Section
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [base64Files, setBase64Files] = useState([]);

    // const imageModalUpload = (id) => {
    //     setSelectedRowId(id);
    //     setShowModalUpload(true)
    //     setFiles([])
    // }
    const imageModalUpload = (id) => {
        console.log('9876', id);
        const selectedRowFiles = gridSelect.find(item => item.uid === id)?.files || [];
        setFiles(selectedRowFiles);
        setSelectedRowId(id); // Store the ID of the selected row
        setShowModalUpload(true);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        getBase64(droppedFiles[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
        base64Files.splice(index, 1);
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithBase64 = [];

        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                filesWithBase64.push({ file, base64: reader.result });

                if (filesWithBase64.length === selectedFiles.length) {
                    setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
                    setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const imageUpload = () => {
        setGridSelect(prevGridSelect =>
            prevGridSelect.map(item => {
                if (item.uid === selectedRowId) {
                    return { ...item, files: files, base64Files: base64Files };
                }
                return item;
            })
        );
        setShowModalUpload(false);
    };

    const movementHistoryClick = () => {
        try {
            // Check if selectedRowData is defined
            if (!selectedRowData) {
                throw new Error('Selected row data is not available.');
            }

            localStorage.removeItem('MHistory');
            const storageData = {
                ULD: selectedRowData,  // ULD Details section
                Form: {}  // Empty form section
            };
            localStorage.setItem('MHistory', JSON.stringify(storageData));
            localStorage.setItem('tab', 'assets');
            window.location.href = '/ULDControl/MovementHistory'
        } catch (error) {
            // Log the error for debugging purposes
            console.error('Error during navigation:', error.message);
            alert('An error occurred: ' + error.message); // Optional user alert
        }
    }
    // upload forward
    const imageUploadRef = useRef(null);
    const browseClick = () => {
        if (imageUploadRef.current) {
            imageUploadRef.current.handleBrowseFiles();
        }
    };

    const handleDetailsClick = (history) => {

        if (!selectedRowData) {
            throw new Error('Selected row data is not available.');
        }

        localStorage.removeItem('MHistory');
        localStorage.removeItem('MHDetails');
        const storageData = {
            ULD: selectedRowData,  // ULD Details section
            Form: {}  // Empty form section
        };
        localStorage.setItem('MHistory', JSON.stringify(storageData));
        const storageDataDetails = {
            ULD: history,  // ULD Details section
            tab: 'All'  // Empty form section
        };
        localStorage.setItem('MHDetails', JSON.stringify(storageDataDetails));
        localStorage.setItem('tab', 'repair');
        let detailsPageUrl;
        const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
        if (detailsType) {

            detailsPageUrl = '/ULDControl/MovementHistorydetais';
            window.location.href = detailsPageUrl;
        } else {
            console.log('No valid details type found');
        }

    };

    return (
        <>
            {downloadOpen && <DownloadGrid download_sec={activeTab} onClose={closeDownloadModal} />}
            <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex'>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='action'>
                            <FontAwesomeIcon icon={faGear} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair In')}>Repair In</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair Out')}>Repair Out</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                    <button variant="primary" className='condition' onClick={() => handleDropdownItemClick('top', 'Condition')}>
                        <span> <FontAwesomeIcon icon={faCircleCheck} /> Condition</span>
                    </button>
                </div>
            </div>

            <div className='ulddown'>
                <button onClick={showDownloadModal}><img src={Export} /> Download</button>
            </div>

            <div className="maindiv">
                <div className='trashdonload'>
                    {/* {console.log('000', gridRefs.Active)} */}
                    <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                        <Tab eventKey="Repair" title={<span>In Repair <Badge className='rabadge'>{totalRepair.total}</Badge></span>} className='moveto'>

                            <div ref={gridRefs.Repair} className='bookinggrid' ></div>
                        </Tab>
                        <Tab eventKey="Damaged" title={<span>Damaged <Badge className='rabadge'>{totalDam.total}</Badge></span>} className='moveto'>
                            {/* <div className='ulddown'>
                                <button onClick={exportToExcel}><img src={Export} /> Download</button>
                            </div> */}
                            <div ref={gridRefs.Damaged} className='bookinggrid' ></div>
                        </Tab>

                        <Tab eventKey="Discarded" title={<span>Discarded <Badge className='rabadge'>{totalDis.total}</Badge></span>} className='moveto'>
                            {/* <div className='ulddown'>
                                <button onClick={exportToExcel}><img src={Export} /> Download</button>
                            </div> */}
                            <div ref={gridRefs.Discarded} className='bookinggrid' ></div>
                        </Tab>
                    </Tabs>
                </div>

                {selectedRowData && (
                    // <div className='SecondDiv'>
                    //     {/* {console.log('55', selectedRowData)} */}
                    //     <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                    //     <div className='girddtals_header'>
                    //         <h3>
                    //             {selectedRowData.bookingtruck_no} <span>{selectedRowData.status === 'A' ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />}</span> <span>{selectedRowData.status === 'A' ? 'Planned' : 'To be planned'}</span>
                    //         </h3>
                    //         <div className='d-flex actbtn'>

                    //             <Button variant="light" onClick={handleBrowseFiles} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                    //             <Dropdown>
                    //                 <Dropdown.Toggle variant="light" id="dropdown-basic">
                    //                     <FontAwesomeIcon icon={faBolt} /> Action
                    //                 </Dropdown.Toggle>

                    //                 <Dropdown.Menu>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('In Operation')}>In Operation</Dropdown.Item>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('Repair')}>Repair</Dropdown.Item>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('In Reserve')}>In Reserve</Dropdown.Item>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('On Air')}>On Air</Dropdown.Item>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('Lease Out')}>Lease Out</Dropdown.Item>
                    //                     <Dropdown.Item onClick={() => handleDropdownItemClick('SCM')}>SCM</Dropdown.Item>
                    //                 </Dropdown.Menu>
                    //             </Dropdown>

                    //         </div>

                    //     </div>
                    //     <div className='d-flex justify-content-between rabord'>
                    //         <div className='firstbrd'>
                    //             <p>Origin</p>
                    //             <span>{selectedRowData.origin}</span>
                    //         </div>
                    //         <div className='middlebrd'>
                    //             <p>{selectedRowData.date}</p>
                    //             <p><img src={Cargoplane} /></p>
                    //             <p>{selectedRowData.time}</p>
                    //         </div>
                    //         <div className='lastbrd'>
                    //             <p>destination</p>
                    //             <span>{selectedRowData.destination}</span>
                    //         </div>
                    //     </div>
                    //     <div>
                    //         <h3>Operational Trucks</h3>

                    //         {/* {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                    //            <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                    //                {console.log("🚀 ~ ExportActive ~ truck:", truck)}
                    //                <div>
                    //                    <span>Truck No</span>
                    //                    <h4>{truck.truck_no}</h4>
                    //                </div>
                    //                <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                    //                    <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                    //                </div>
                    //                <div>
                    //                    <Link>Details</Link>
                    //                </div>
                    //            </div>
                    //        ))} */}
                    //     </div>

                    //     <div className='my-3'>
                    //         <div className='d-flex justify-content-between align-items-center'>
                    //             <h3 className='mb-0'>Attachments</h3>
                    //             <div className='d-flex rightdrop'>
                    //                 <Dropdown>
                    //                     <Dropdown.Toggle variant="link" id="dropdown-basic">
                    //                         <FontAwesomeIcon icon={faEllipsis} />
                    //                     </Dropdown.Toggle>

                    //                     <Dropdown.Menu>
                    //                         <Dropdown.Item href="#/action-1">Open gallery view</Dropdown.Item>
                    //                         <Dropdown.Item href="#/action-2">Download all</Dropdown.Item>
                    //                     </Dropdown.Menu>
                    //                 </Dropdown>

                    //                 <div
                    //                     onDrop={handleDrop}
                    //                     onDragOver={handleDragOver}
                    //                 >
                    //                     <button className='raplas' onClick={handleBrowseFiles}><FontAwesomeIcon icon={faPlus} /></button>
                    //                     <input
                    //                         type="file"
                    //                         ref={fileInputRef}
                    //                         style={{ display: 'none' }}
                    //                         onChange={handleFileChange}
                    //                         multiple
                    //                     />


                    //                 </div>
                    //             </div>
                    //         </div>

                    //         <div className='raslider'>
                    //             <div className="slider-container">
                    //                 {
                    //                     files.length > 2 ?
                    //                         <Slider {...settings}>
                    //                             {files.map((file, index) => (
                    //                                 <div key={index} className='slideimg'>
                    //                                     {file.type.startsWith('image/') ? (
                    //                                         <>
                    //                                             <img
                    //                                                 src={URL.createObjectURL(file)}
                    //                                                 alt="Preview"
                    //                                                 style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                    //                                                 onClick={() => handleFileClick(URL.createObjectURL(file))}
                    //                                             />
                    //                                             <span className='rafilename'>{file.name}</span>
                    //                                         </>
                    //                                     ) : (
                    //                                         <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                    //                                     )}
                    //                                     <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                    //                                 </div>
                    //                             ))}
                    //                         </Slider>
                    //                         :
                    //                         <>
                    //                             <div className='nonslide'>
                    //                                 {files.map((file, index) => (
                    //                                     <div key={index} className='slideimg'>
                    //                                         {file.type.startsWith('image/') ? (
                    //                                             <>
                    //                                                 <img
                    //                                                     src={URL.createObjectURL(file)}
                    //                                                     alt="Preview"
                    //                                                     style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                    //                                                     onClick={() => handleFileClick(URL.createObjectURL(file))}
                    //                                                 />
                    //                                                 <span className='rafilename'>{file.name}</span>
                    //                                             </>
                    //                                         ) : (
                    //                                             <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                    //                                         )}
                    //                                         <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                    //                                     </div>
                    //                                 ))}
                    //                             </div>
                    //                         </>
                    //                 }


                    //             </div>

                    //             <Modal show={show} onHide={handleClose} centered className='imgmodal'>
                    //                 <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                    //                 <Modal.Body >
                    //                     {/* {console.log(selectedFile)} */}
                    //                     <img src={selectedFile} className="img-fluid" alt="Selected" />
                    //                 </Modal.Body>
                    //             </Modal>

                    //         </div>

                    //     </div>

                    //     <div>
                    //         <h3>Activity</h3>

                    //         <div className='activity'>
                    //             <span>Show :</span>
                    //             <Button variant="link">All</Button>
                    //             <Button variant="link active">Comments</Button>
                    //             <Button variant="link">History</Button>
                    //         </div>
                    //         <div className='activity'>
                    //             <span>Truck :</span>
                    //             <Button variant="link">All</Button>
                    //             <Button variant="link">#123</Button>
                    //             <Button variant="link">#124</Button>
                    //             <Button variant="link">#125</Button>
                    //             <Button variant="link">#126</Button>
                    //         </div>
                    //     </div>
                    //     <div className='mt-4'>
                    //         <div className='mb-3'>
                    //             <div className='d-flex comntbox'>
                    //                 <div>
                    //                     <span className='user_com'>RS</span>
                    //                 </div>
                    //                 <div className='edithtml'>
                    //                     <CKEditor
                    //                         editor={ClassicEditor}
                    //                         data="<p> Hello </p>"
                    //                         onReady={editor => {
                    //                             // You can store the "editor" and use when it is needed.
                    //                             // console.log('Editor is ready to use!', editor);
                    //                         }}
                    //                         onChange={(event) => {
                    //                             // console.log(event);
                    //                         }}
                    //                         onBlur={(event, editor) => {
                    //                             // console.log('Blur.', editor);
                    //                         }}
                    //                         onFocus={(event, editor) => {
                    //                             // console.log('Focus.', editor);
                    //                         }}
                    //                     />
                    //                 </div>
                    //             </div>
                    //             <div className='commnt_save'>
                    //                 <Button variant="primary rasave">Save</Button>
                    //                 <Button variant="link racancel">Cancel</Button>
                    //             </div>
                    //         </div>
                    //         <div className='d-flex racomment'>
                    //             <div className='coment_img'><img src={Cuser} /></div>
                    //             <div>
                    //                 <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> </div>
                    //                 <p>I have no reply, so i will take this ticket to next week</p>
                    //             </div>
                    //         </div>

                    //         <div className='d-flex racomment'>
                    //             <div className='coment_img'><img src={Cuser} /></div>
                    //             <div>
                    //                 <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                    //                 <p>I have no reply, so i will take this ticket to next week</p>
                    //             </div>
                    //         </div>

                    //         <div className='d-flex racomment'>
                    //             <div className='coment_img'><img src={Cuser} /></div>
                    //             <div>
                    //                 <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                    //                 <p>I have no reply, so i will take this ticket to next week</p>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className='SecondDiv mt-45'>
                        {/* {console.log('55', selectedRowData)} */}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.uld_number} &nbsp; <span>{selectedRowData.status}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={browseClick} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic" >
                                        <FontAwesomeIcon icon={faBolt} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair In')}>Repair In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair Out')}>Repair Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Origin</p>
                                <span>{selectedRowData.origin}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.flight_date}</p>
                                <p><img src={Cargoplane} /></p>
                                <p>{selectedRowData.flight_number}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>Destination</p>
                                <span>{selectedRowData.destination}</span>
                            </div>
                        </div>
                        <div className='movment_history'>
                            <h3>Movement History</h3> <Link to='#' onClick={movementHistoryClick}>View All</Link>
                        </div>
                        <div className='movment_list'>
                            <ul>
                                {selectedRowData && selectedRowData.movement_history.slice(0, 5).map((history, index) => (
                                    <li key={index}>
                                        <span className='movem'>{history.operation_type}</span> <span className='mvdate'>{history.operation_date}</span>
                                        <Link to="#" onClick={() => handleDetailsClick(history)}>Details</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <ImageUpload
                            ref={imageUploadRef}
                            attachable_type={"uld"}
                            attachable_id={selectedRowData.uld_number}
                            attachments={selectedRowData.attachments}
                        />
                        <ActivityComments
                            commentable_type={"uld"}
                            commentable_id={selectedRowData.uld_number}
                            allactivities={selectedRowData.activities}
                            onReloadDetails={loadDetails}
                        />
                    </div>
                )}
            </div>
            <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='type_work'>

                        <div className='ra_condition'>
                            <div
                                className='dropfile'
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <h3>Drag and drop files here</h3>
                                <p>Or</p>
                                <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
                                <span>Maximum file size 4 MB</span>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    multiple
                                />
                            </div>
                            <div className='uploadbox'>
                                {files.map((file, index) => (
                                    <div key={index} className='uploadfil'>
                                        {file.type.startsWith('image/') ? (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt="Preview"
                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                            />
                                        ) : (
                                            <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                        )}
                                        <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="primary" onClick={imageUpload}>
                            Upload
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='addfrom in_oparation' show={showConfirmation} onHide={() => setShowConfirmation(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{action}</Modal.Title>
                    <p>{action === 'Condition' ? 'Select Condition by selecting the option.' : 'Do you want to change select ULD' + action + '?'}</p>
                    {/* {['Repair', 'Lease Out', 'Condition'].includes(action) ? action : null} */}
                </Modal.Header>
                <Modal.Body>
                    {action == 'Repair' ?
                        (
                            <>
                                <Form.Group controlId="dropdown" className='mb-3'>
                                    <Form.Label>Repair Name</Form.Label>
                                    <Form.Control type="text" placeholder="Repair Name" />
                                </Form.Group>
                                <Form.Group controlId="dropdown" className='mb-3'>
                                    <Form.Label>Repair Station</Form.Label>
                                    <MultiSelect
                                        inline
                                        options={options}
                                        isCreatable={true}
                                        hasSelectAll={false}
                                        showCheckbox={false}
                                        value={selected}
                                        selected={selected}
                                        onChange={handleSelectChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="dropdown" className='mb-3'>
                                    <Form.Label>Repair Out</Form.Label>
                                    <Form.Control
                                        type="dateTime-local"
                                        name='cell_value'
                                    // value={cellEditData.cell_value} 
                                    // onChange={handleDateTimeChange} 
                                    />
                                </Form.Group>
                                <Form.Group controlId="dropdown" className='mb-3'>
                                    <Form.Label>Repair In</Form.Label>
                                    <Form.Control
                                        type="dateTime-local"
                                        name='cell_value'
                                    // value={cellEditData.cell_value} 
                                    // onChange={handleDateTimeChange} 
                                    />
                                </Form.Group>
                            </>
                        ) : (
                            action == 'Lease In' ?
                                (
                                    <>
                                        <Form.Group controlId="dropdown" className='mb-3'>
                                            <Form.Label>Lease In</Form.Label>
                                            <Form.Control
                                                type="dateTime-local"
                                                name='cell_value'
                                            // value={cellEditData.cell_value} 
                                            // onChange={handleDateTimeChange} 
                                            />
                                        </Form.Group>
                                    </>
                                ) : (
                                    action == 'Lease Out' ?
                                        (
                                            <>
                                                <Form.Group controlId="dropdown" className='mb-3'>
                                                    <Form.Label>Lease Out</Form.Label>
                                                    <Form.Control
                                                        type="dateTime-local"
                                                        name='cell_value'
                                                    // value={cellEditData.cell_value} 
                                                    // onChange={handleDateTimeChange} 
                                                    />
                                                </Form.Group>
                                            </>
                                        ) : (
                                            action == 'Condition' ?
                                                (
                                                    <>
                                                        <Form.Group controlId="dropdown" className='mb-3'>
                                                            <Form.Check
                                                                label="Serviceable"
                                                                name="condition_type"
                                                                type='radio'
                                                                id='Serviceable'
                                                                value="Serviceable"
                                                                checked={conditionChange === 'Serviceable'}
                                                                onChange={handleConditionRadioChange}
                                                            />
                                                            <Form.Check
                                                                label="Damage"
                                                                name="condition_type"
                                                                type='radio'
                                                                id='Damage'
                                                                value="Damage"
                                                                checked={conditionChange === 'Damage'}
                                                                onChange={handleConditionRadioChange}
                                                            />
                                                            <Form.Check
                                                                label="Discarded"
                                                                name="condition_type"
                                                                type='radio'
                                                                id='Discarded'
                                                                value="Discarded"
                                                                checked={conditionChange === 'Discarded'}
                                                                onChange={handleConditionRadioChange}
                                                            />
                                                        </Form.Group>

                                                        <div className={conditionChange == 'Serviceable' ? 'd-flex flex-row bd-highlight inop' : 'd-flex flex-column bd-highlight inop'}>
                                                            {gridSelect && gridSelect.map((num, index) => (
                                                                <>
                                                                    {conditionChange == 'Serviceable' ? (
                                                                        <div key={index} className='inbox d-flex justify-content-between align-items-center'>
                                                                            <span>{num.uld_number}</span>

                                                                            <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                                        </div>
                                                                    ) : (conditionChange == 'Damage' ? (
                                                                        <div key={index} className='inbox d-flex justify-content-between align-items-center'>
                                                                            <span>{num.uld_number}</span>

                                                                            <Form.Select>{console.log('9632', num)}
                                                                                <option>Damag Type</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </Form.Select>

                                                                            <Form.Control type="email" placeholder="Damage Description" />

                                                                            <Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uid)}>Upload file</Button>

                                                                            <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                                        </div>
                                                                    ) : (
                                                                        <div key={index} className='inbox d-flex justify-content-between align-items-center'>
                                                                            <span>{num.uld_number}</span>

                                                                            <Form.Control type="email" placeholder="Discarded Description" />

                                                                            <Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uld_number)}>Upload file</Button>

                                                                            <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                                        </div>
                                                                    ))}

                                                                </>
                                                            ))}
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='d-flex flex-wrap inop'>

                                                            {gridSelect && gridSelect.map((num, index) => (
                                                                <div key={index} className='inbox'>
                                                                    <span>{num.uld_number}</span>
                                                                    <img src={Trash} alt='' onClick={() => handleRemove(index)} />
                                                                </div>
                                                            ))}
                                                            {selectedRowData && <div className='inbox'><span>{selectedRowData.uld_number}</span></div>}
                                                        </div>

                                                    </>
                                                )
                                        )
                                )
                        )}

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleCancelChange}> */}
                    {/* {['Repair', 'Lease In', 'Lease Out', 'Condition'].includes(action) ? 'Cancel' : 'No'} */}
                    {/* </Button> */}
                    <Button variant="primary" onClick={handleConfirmChange}>
                        {['Repair', 'Lease Out', 'Condition', 'SCM'].includes(action) ? 'Update Status ' + action : 'Update Status'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Repair