import axios from "axios";

// we need to pass the baseURL as an object
// console.log("  token 4 axios ",localStorage.getItem('token'));
// console.log('fffffff',process.env);
const withToken = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
});
const withoutToken = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

// const withTokenUser = axios.create({
//     baseURL: process.env.REACT_APP_USER_STATION_URL,
//     headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`
//     }
// });
// const withoutTokenUser = axios.create({
//     baseURL: process.env.REACT_APP_USER_STATION_URL
// });
const actionTypes = ['RFS', 'ULD'];
let keyType = null;
// Loop through the actionTypes array
for (let i = 0; i < localStorage.length; i++) {
  const key = localStorage.key(i);
  // Check if the current key matches any of the action keyTypes
  if (actionTypes.includes(key)) {
    keyType = key;
    break;
  }
}
const withRFSToken = axios.create({
    baseURL: process.env.REACT_APP_RFS_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(keyType)}`
    }
});
const withoutRFSToken = axios.create({
    baseURL: process.env.REACT_APP_RFS_URL
});

const withULDToken = axios.create({
    baseURL: process.env.REACT_APP_RFS_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('ULD')}`
    }
});
const withoutULDToken = axios.create({
    baseURL: process.env.REACT_APP_RFS_URL
});
// export default withToken;
export { withToken, withoutToken, withRFSToken, withoutRFSToken };
