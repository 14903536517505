import React, { useState, useEffect, useRef } from 'react';
import 'jqwidgets-framework/jqwidgets/jqxcore';
import 'jqwidgets-framework/jqwidgets/jqxdata';
import 'jqwidgets-framework/jqwidgets/jqxbuttons';
import 'jqwidgets-framework/jqwidgets/jqxscrollbar';
import 'jqwidgets-framework/jqwidgets/jqxmenu';
import 'jqwidgets-framework/jqwidgets/jqxgrid';
import 'jqwidgets-framework/jqwidgets/jqxgrid.pager';
import 'jqwidgets-framework/jqwidgets/jqxgrid.sort';
import 'jqwidgets-framework/jqwidgets/jqxgrid.edit';
import 'jqwidgets-framework/jqwidgets/jqxgrid.filter';
import 'jqwidgets-framework/jqwidgets/jqxgrid.selection';
import 'jqwidgets-framework/jqwidgets/jqxgrid.columnsresize';
import 'jqwidgets-framework/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-framework/jqwidgets/styles/jqx.dark.css';
import $ from 'jqwidgets-framework/jqwidgets/jqx-all';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faCircleXmark, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
// import Slider from "react-slick";
import { Link } from 'react-router-dom';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import { MultiSelect } from 'react-multi-select-component';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Activ from "../../../assets/images/active.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Trash from '../../../assets/images/trash.png';
import Cargoplane from "../../../assets/images/plane.svg";
// import Cuser from "../../../assets/images/cuser.png";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Badge from 'react-bootstrap/Badge';
import ImageUpload from '../../../Componenets/common/ImageUpload';
import ActivityComments from '../../../Componenets/common/ActivityComments';
import EditUld from './components/EditUld.js';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDASSETSGRID, GETDAMAGETYPE, UPDATESTATUS, UPDATECONDITION, GETULDDETAILS, MOVETOACTARC, DOWNLOADULD, GETTYPECODE, GETDESTINATION, GETCONDITIONCODE, GETOWNERCODE, MVTHDETAILS } from '../../../Utils/conts.js';
import Loader from '../../../Componenets/common/Loader.js';
import DownloadGrid from './components/DownloadGrid.js';

const Assets = () => {

    const [gridData, setGridData] = useState({
        Active: [],
        Archived: [],
        // Trash: []

    });
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef(false);
    const [damageType, setDamageType] = useState([]);
    const [totalRows, setTotalRows] = useState({});
    const [totalRowsR, setTotalRowsR] = useState(0);

    const fetchDataGrid = async (pageNumber, psize) => {
        const page_number = pageNumber ? pageNumber : 1;
        const pageSize = psize ? psize : 20;
        setIsLoading(true);
        try {
            const res = await withRFSToken.post(ULDASSETSGRID, { page: page_number, type: 'A', page_size: pageSize })
            // .then(function (res) {
            if (res.data.response_code == 200) {
                setIsLoading(false);
                const innerData = res.data.response_data.ulds;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
                setGridData({ Active: innerData });
                setTotalRows({
                    pageSize: res.data.response_data.page_size,
                    currPage: res.data.response_data.current_page,
                    lastPage: res.data.response_data.last_page,
                    totalUlds: res.data.response_data.total_ulds
                });
                // window.$(gridRefs[activeTab].current).jqxGrid('pagesize', res.data.response_data.page_size);
            }
            // });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };
    const fetchDataGridR = async (pageNumber, psize) => {
        const page_number = pageNumber ? pageNumber : 1;
        const pageSize = psize ? psize : 20;
        setIsLoading(true);
        try {
            const res = await withRFSToken.post(ULDASSETSGRID, { page: page_number, type: 'R', page_size: pageSize });
            setIsLoading(false);
            // .then(function (res) {
            if (res.data.response_code == 200) {
                const innerData = res.data.response_data.ulds;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
                setGridData({ Archived: innerData });
                setTotalRowsR({
                    pageSize: res.data.response_data.page_size,
                    currPage: res.data.response_data.current_page,
                    lastPage: res.data.response_data.last_page,
                    totalUlds: res.data.response_data.total_ulds
                });
                // });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted.current) return;
        // Call the fetchData function to fetch data from the API
        fetchDataGrid();
        fetchDataGridR();
        isMounted.current = true;
    }, []);
    const fetchDamageType = async () => {
        try {
            const res = await withRFSToken.post(GETDAMAGETYPE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setDamageType(innerData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRowDetails, setSelectedRowDetails] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const [activeTab, setActiveTab] = useState('Active');

    const gridRefs = {
        Active: useRef(null),
        Archived: useRef(null),
    };
    const handleTabChange = async (tab) => {
        setActiveTab(tab);
        // const tabType = () => {tab == 'Active' ? 'A' : 'R'}
        if (selectedRowData) {
            setSelectedRowData(null)
        }
        setGridSelect([]);
        let tabType = '';
        // if (tab == 'Active') { tabType = 'A' } else { tabType = 'R' }
        if (tab == 'Active') { fetchDataGrid() } else { fetchDataGridR() }
        // setIsLoading(true);
        // try {
        //     const res = await withRFSToken.post(ULDASSETSGRID, { type: tabType });
        //     setIsLoading(false);
        //     // .then(function (res) {
        //     if (res.data.response_code == 200) {
        //         const innerData = res.data.response_data.ulds;
        //         // console.log("🚀 ~ .then ~ innerData:654", innerData)
        //         // const transformData = innerData ? innerData.map(data => ({...data, reg: 'R' })) : '';
        //         if (tabType == 'A') {
        //             // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
        //             setGridData({ Active: innerData });
        //             // setTotalRows(res.data.response_data.total_ulds);
        //             setTotalRows({
        //                 pageSize: res.data.response_data.page_size,
        //                 currPage: res.data.response_data.current_page,
        //                 lastPage: res.data.response_data.last_page,
        //                 totalUlds: res.data.response_data.total_ulds
        //             });
        //         } else {
        //             // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'N' })) : '';
        //             setGridData({ Archived: innerData });
        //             setTotalRowsR(res.data.response_data.total_ulds);
        //         }
        //         // });
        //     }
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };
    const [cellEdit, setCellEdit] = useState(false);
    const [cellEditData, setCellEditData] = useState({});
    // Edit Uld
    const [showEdit, setShowEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const handleCloseEditModal = () => {
        setShowEdit(false);
        setEditData({});
    };
    // Edit Uld
    const pageSize = activeTab == 'Active' ? totalRows.pageSize : totalRowsR.pageSize;
    const totalRowsCount = activeTab == 'Active' ? totalRows.totalUlds : totalRowsR.totalUlds;
    const initCurrentPage = activeTab == 'Active' ? totalRows.currPage : totalRowsR.currPage;
    // console.log("🚀 ~ Assets ~ pageSize:", pageSize)

    useEffect(() => {
        // console.log('500', totalRecords);
        if (gridRefs[activeTab].current) {
            if (gridData[activeTab]) {

                const source = {
                    datatype: 'json',
                    id: `${activeTab}-`,
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'uld_number', type: 'string' },
                        { name: 'type_code', type: 'string' },
                        { name: 'serial_number', type: 'string' },
                        { name: 'owner_code', type: 'string' },
                        { name: 'condition', type: 'string' },
                        { name: 'condition_code', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'origin', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'scm', type: 'string' },
                        { name: 'scm_date', type: 'date' },
                        { name: 'asset_type', type: 'string' },
                        { name: 'handler_name', type: 'string' },
                        { name: 'repair_name', type: 'string' },
                        { name: 'repair_station', type: 'string' },
                        { name: 'repair_out', type: 'string' },
                        { name: 'repair_in', type: 'string' },
                        { name: 'airline_name', type: 'string' },
                        { name: 'damage_type', type: 'string' },
                        { name: 'damage_desc', type: 'string' },
                        { name: 'discarded_desc', type: 'string' },

                        { name: 'reg', type: 'string' },
                        { name: 'register', type: 'string' },
                    ],
                    localdata: gridData[activeTab],
                    totalrecords: totalRowsCount,
                    // pagesize: pageSize
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                //     , {
                //     autoBind: true,
                //     async: false,
                //     // Enable filtering in the dataAdapter
                //     filter: function () {
                //         $(gridRefs[activeTab].current).jqxGrid('updatebounddata', 'filter');
                //     },
                // });
                // // console.log('localdata', source.localdata)
                const columns = [
                    {
                        text: 'Actions',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                        },
                        width: '5%', filterable: false,
                    },
                    { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
                    { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '5%', cellclassname: '', },
                    { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '8%', cellclassname: '', },
                    { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '8%', cellclassname: '', },
                    {
                        text: 'Condition Code', filtertype: 'checkedlist', filterable: true,
                        // filteritems: [
                        //     { label: 'Serviceable', value: 'SER' },
                        //     { label: 'Damaged', value: 'DAM' },
                        //     { label: 'Discarded', value: 'DIS' }
                        // ],
                        // createfilterwidget: (column, htmlElement, editor) => {
                        //     editor.jqxDropDownList({
                        //         placeHolder: 'Please hhhhh',
                        //         source: [
                        //             { label: 'Serviceable', value: 'SER' },
                        //             { label: 'Damaged', value: 'DAM' },
                        //             { label: 'Discarded', value: 'DIS' }
                        //         ],
                        //         displayMember: 'label',
                        //         valueMember: 'value'
                        //     });
                        // },
                        // cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                        //     if (rowdata.condition_code === 'SER') {
                        //         return '<div class="jqx-grid-cell-left-align set_dock">Serviceable</div>';
                        //     } else if (rowdata.condition_code === 'DAM') {
                        //         return '<div class="jqx-grid-cell-left-align set_dock">Damaged</div>';
                        //     } else {
                        //         return '<div class="jqx-grid-cell-left-align set_dock">Discarded</div>';
                        //     }
                        // },
                        datafield: 'condition', width: '10%', cellclassname: '',
                    },
                    { text: 'Status', filtertype: 'checkedlist', datafield: 'status', width: '10%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                    { text: 'SCM', filtertype: 'textbox', datafield: 'scm', width: '6%', cellclassname: '', },
                    { text: 'SCM Date', filtertype: 'range', cellsformat: 'dd-MM-yyyy', filtermode: 'range', datafield: 'scm_date', width: '10%', cellclassname: '', },
                    {
                        text: 'Asset Type', filtertype: 'checkedlist',
                        filteritems: [
                            { label: 'Owned', value: 'Y' },
                            { label: 'Lease', value: 'N' },
                        ],
                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                            if (rowdata.asset_type === 'Y') {
                                // Return HTML with class 'inactive' for 'i' status
                                return '<div class="jqx-grid-cell-left-align set_dock ">Owned</div>';
                            } else {
                                // Return HTML with class 'active' for 'a' status
                                return '<div class="jqx-grid-cell-left-align set_dock ">Lease</div>';
                            }
                        },
                        datafield: 'asset_type', width: '6%', cellclassname: '',
                    },
                    { text: 'Handler Name', filtertype: 'textbox', datafield: 'handler_name', width: '6%', cellclassname: '', },
                    { text: 'Airline Name', filtertype: 'textbox', datafield: 'airline_name', width: '11%', cellclassname: '', },
                ];

                // const adjustGridHeight = () => {
                //     const pageSize = window.$(gridRefs[activeTab].current).jqxGrid('pagesize');
                //     const rowHeight =window.$(gridRefs[activeTab].current).jqxGrid('rowsheight');
                //     const visibleRowsHeight = pageSize * rowHeight;

                //     if (visibleRowsHeight > 933) { // Adjust this threshold as needed
                //         window.$(gridRefs[activeTab].current).jqxGrid({ autoheight: false, height: 933 });
                //         $('.jqx-grid').css('overflow-y', 'auto'); // Ensure vertical scroll
                //     } else {
                //         window.$(gridRefs[activeTab].current).jqxGrid({ autoheight: true });
                //         $('.jqx-grid').css('overflow-y', 'hidden');
                //     }
                // };
                // const initCurrentPage = activeTab == 'Active' ? totalRows.currPage : totalRowsR.currPage;
                // const pagerRenderer = (initCurrentPage) => {
                //     const element = window.$('<div style="width: 100%; height: 100%; text-align: center;"></div>');
                //     const start = initCurrentPage * pageSize + 1;
                //     const end = Math.min(start + pageSize - 1, totalRows.totalUlds);
                //     const text = `${start}-${end} of ${totalRows.totalUlds}`;
                //     element.text(text);
                //     return element;
                // };

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: false,
                    height: 1040,
                    sortable: true,
                    altrows: false,
                    columnsresize: true,
                    enabletooltips: false,
                    editable: false,
                    selectionmode: 'checkbox',
                    pageable: true,
                    pageSize: pageSize,
                    pagesizeoptions: ['20', '50', '100', '200', '500'],
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    showstatusbar: true,
                    virtualmode: true, // Enable virtual mode for large data sets
                    // pagerrenderer: pagerRenderer,
                    rendergridrows: function (params) {
                        // This is crucial for virtual mode to handle the total number of rows correctly
                        return params.data;
                    },

                    // pagerrenderer: function () {
                    //     const element = window.$('<div style="width: 100%; height: 100%; text-align: center;"></div>');
                    //     element.addClass('jqx-custom-pager-info'); // Add a class to easily find this element
                    //     return element;
                    // }
                    // ready: adjustGridHeight
                    // pagerrenderer: function (pagenum, pagesize, oldpagenum) {
                    //     // Called when a page or page size is changed
                    //     console.log('Page changed to:', pagenum, 'with pageSize:', pagesize);  // Debug log
                    //     // fetchData(pagenum + 1, pagesize);
                    // },
                    // pagerrenderer: function () {
                    //     return createCustomPager();
                    // }

                };
                // window.$(gridRefs[activeTab].current).on('pagesizechanged', adjustGridHeight);


                window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
                window.$(gridRefs[activeTab].current).jqxGrid('gotopage', initCurrentPage - 1);

                // function createCustomPager() {
                //     // Create a div to hold the pager text
                //     const pagerDiv = document.createElement('div');
                //     pagerDiv.className = 'custom-pager';

                //     // Update the pager details text
                //     const updatePagerText = () => {
                //         const pagingInfo = window.$(gridRefs[activeTab].current).jqxGrid('getpaginginformation');
                //         const pageNumber = pagingInfo.pagenum + 1; // Convert to 1-based index
                //         const pageSize = pagingInfo.pagesize;
                //         const totalRecords = pagingInfo.totalrecords;
                //         const startRecord = (pagingInfo.pagenum * pageSize) + 1;
                //         const endRecord = Math.min(startRecord + pageSize - 1, totalRecords);

                //         pagerDiv.innerText = `Page ${pageNumber} - Displaying records ${startRecord}-${endRecord} of ${totalRecords}`;
                //     };

                //     // Update the pager text initially and every time the page changes
                //     updatePagerText();
                //     window.$(gridRefs[activeTab].current).on('pagechanged', updatePagerText);
                //     window.$(gridRefs[activeTab].current).on('pagesizechanged', updatePagerText);

                //     return pagerDiv;
                // }

                // const updatePagerDetails = (pageNumber, pageSize) => {
                //     const gridElement = gridRefs[activeTab].current;

                //     if (gridElement) {
                //         const pagingInfo = window.$(gridElement).jqxGrid('getpaginginformation');
                //         console.log("Paging Information:", pagingInfo);
                //         const totalRecords = totalRows.totalUlds || 0;
                //         const startRecord = pageNumber * pageSize + 1;
                //         const endRecord = Math.min((pageNumber + 1) * pageSize, totalRecords);

                //         // Update the pagination display (if necessary)
                //         console.log(`Displaying records ${startRecord}-${endRecord} of ${totalRecords}`);
                //     }
                //     window.$(gridRefs[activeTab].current).jqxGrid('updatebounddata', 'cells');
                //     const pagingInfo = window.$(gridRefs[activeTab].current).jqxGrid('getpaginginformation');
                //     const { pagesize, pagenum, totalrecords } = pagingInfo;

                //     const startItemIndex = pagenum * pagesize + 1;
                //     const endItemIndex = Math.min((pagenum + 1) * pagesize, totalrecords);

                //     // Update the pagination details in the pager
                //     // window.$(gridRefs[activeTab].current).find('.jqx-custom-pager-info').text(`Showing ${startItemIndex} - ${endItemIndex} of ${totalrecords}`);
                //     // window.$('.jqx-grid-pager').find('.jqx-grid-pager-details').text(pagerText);
                // };

                const handlePageChanged = (event) => {
                    const args = event.args;
                    // console.log("🚀 ~ args:zsss", args)
                    const pageNumber = args.pagenum;
                    const pageSize = args.pagesize;
                    if (activeTab == 'Active') {
                        if (pageSize !== totalRows.pageSize) {
                            fetchDataGrid(pageNumber, pageSize);
                        } else {
                            fetchDataGrid(pageNumber + 1, pageSize);
                        }
                        // fetchDataGrid(pageNumber + 1, pageSize);
                        // setTotalRows(prevState => ({
                        //     ...prevState,
                        //     currPage: pageNumber + 1,
                        // }));
                    } else {
                        if (pageSize !== totalRows.pageSize) {
                            fetchDataGridR(pageNumber, pageSize);
                        } else {
                            fetchDataGridR(pageNumber + 1, pageSize);
                        }
                        // fetchDataGridR(pageNumber + 1, pageSize);
                        // setTotalRowsR(prevState => ({
                        //     ...prevState,
                        //     currPage: pageNumber + 1,
                        // }));
                    }
                    // updatePagerDetails(pageNumber, pageSize);
                };
                // const handlePageSizeChanged = (event) => {
                //     const args = event.args;

                //     const pageNumber = args.pagenum;
                //     const pageSize = args.pagesize;
                //     if (activeTab == 'Active') {
                //         fetchDataGrid(pageNumber, pageSize);
                //     } else {
                //         fetchDataGridR(pageNumber, pageSize);
                //     }
                // };
                window.$(gridRefs[activeTab].current).on('pagechanged', handlePageChanged);
                // window.$(gridRefs[activeTab].current).on('pagesizechanged', handlePageSizeChanged);

                // window.$(gridRefs[activeTab].current).on('pagechanged', function (event) {
                //     const args = event.args;
                //     console.log("🚀 ~ args:zsss", args, activeTab)
                //     const pageNumber = args.pagenum; // + 1;
                //     const pageSize = args.pagesize;
                //     if(activeTab == 'Active'){
                //     fetchDataGrid(pageNumber, pageSize);
                //     } else {
                //         fetchDataGridR(pageNumber);
                //     }
                // });

                window.$(gridRefs[activeTab].current).on('click', '.row_edit', function () {
                    // console.log("🚀 ~ event:01010", event)
                    const rowDiv = window.$(this).closest('[role="row"]');
                    // const cellDiv = this.id;
                    // console.log("🚀 ~ cellDiv:", rowDiv)
                    const rowIndex = window.$(rowDiv).attr('row-id');
                    const rowData = window.$(gridRefs[activeTab].current).jqxGrid('getrowdata', rowIndex);
                    const dataToSend = {
                        uld_number: rowData.uld_number,
                        type_code: rowData.type_code,
                        serial_number: rowData.serial_number,
                        owner_code: rowData.owner_code,
                        condition_code: rowData.condition_code,
                        asset_type: rowData.asset_type == 'Y' ? false : true
                    };
                    setEditData(dataToSend);
                    setShowEdit(true);

                });

                window.$(gridRefs[activeTab].current).on('click', '.set_datetime', function () {
                    // console.log("🚀 ~ event:01010", event)

                    const rowDiv = window.$(this).closest('[role="row"]');
                    const cellDiv = this.id;
                    // console.log("🚀 ~ cellDiv:", cellDiv)

                    const rowIndex = window.$(rowDiv).attr('row-id');
                    const rowData = window.$(gridRefs[activeTab].current).jqxGrid('getrowdata', rowIndex);
                    // console.log("🚀 ~ rowDiv:", rowIndex)

                    if (rowData.hasOwnProperty(cellDiv)) {
                        const cellValue = rowData[cellDiv];
                        if (cellValue == "Set") {
                            const currentDate = new Date().toISOString().slice(0, -5);
                            // const formattedDateTime = new Date(cellEditData.cell_value).toISOString().slice(0, -5);

                            const combineData = {
                                ...rowData, // Spread existing rowData
                                field_check: 'time', // Add the static string field
                                data_field: cellDiv,
                                cell_value: currentDate // Add the cell value to combineData
                            };

                            // Now combineData has the cell value included
                            // You can proceed with further actions using combineData
                            setCellEditData(combineData);
                            setCellEdit(true);
                        } else {
                            const combineData = {
                                ...rowData, // Spread existing rowData
                                field_check: 'time', // Add the static string field
                                data_field: cellDiv,
                                cell_value: cellValue // Add the cell value to combineData
                            };

                            // Now combineData has the cell value included
                            // You can proceed with further actions using combineData
                            setCellEditData(combineData);
                            setCellEdit(true);
                        }

                        // Create combineData object with cellValue included

                    } else {
                        console.log("Cell not found in rowData");
                    }

                });

                window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

                window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });
                // row selection by checkbox
                // window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
                //     // console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

                //     if (event.args.row) {
                //         const selectedRow = event.args.row;
                //         const selectedRowId = selectedRow.id;

                //         if (!gridSelect.some(row => row.id === selectedRowId)) {
                //             setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                //         }
                //     } else {
                //         // Handle "Select All" case
                //         const allIds = gridData[activeTab]; // .map(row => row);
                //         // console.log("All IDs:", allIds);
                //         setGridSelect(allIds);
                //         // console.log("Select All triggered");
                //     }
                // });
                // window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
                //     // console.log('UNS', event);
                //     if (event.args.row) {
                //         // console.log('unselect one');
                //         const unselectedRowId = event.args.row.id;
                //         setGridSelect(prevSelection => prevSelection.filter(row => row.id !== unselectedRowId));
                //     } else if (event.args.rows) {
                //         // console.log('Deselect All');
                //         setGridSelect([]);
                //     }
                // });

                window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
                    console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        const selectedRowId = selectedRow.uid;
                        if (!gridSelect.some(row => row.uid === selectedRowId)) {
                            setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData[activeTab];
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.uld_number;
                        // console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                        setGridSelect(prevSelection => prevSelection.filter(row => row.uld_number !== unselectedRowId));
                    }
                });
                // End row selection 
                // const handleCellClick = (event) => {
                //     console.log('event', event);
                //     const args = event.args.row.bounddata;
                //     if (event.args.columnindex > 1) {

                //         if (gridSelect) {
                //             gridSelect.forEach(item => {
                //                 const selectedRowBoundIndex = item.boundindex;
                //                 window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                //             });
                //             setGridSelect([]);
                //         }
                //       loadDetails(args.uld_number);
                //     }
                //   };
                // window.$(gridRefs[activeTab].current).on('cellclick', handleCellClick);
                window.$(gridRefs[activeTab].current).off('cellclick');
                window.$(gridRefs[activeTab].current).on('cellclick', (event) => {
                    console.log('event', event);
                    const args = event.args.row.bounddata;
                    if (event.args.columnindex > 1) {

                        if (gridSelect) {
                            gridSelect.forEach(item => {
                                const selectedRowBoundIndex = item.boundindex;
                                window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                            });
                            setGridSelect([]);
                        }
                        loadDetails(args.uld_number);
                        // console.log('args', args);
                        // const sendRowId = gridData[activeTab];
                        // console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
                        // setSelectedRowData(args);
                        // try {
                        //     const res = await withRFSToken.post(GETULDDETAILS, { uld_number: args.uld_number })
                        //         .then(function (res) {
                        //             const innerData = res.data.response_data;
                        //             // console.log("🚀 ~ .then ~ innerData:654", innerData)
                        //             setSelectedRowData(innerData);

                        //         });
                        // } catch (error) {
                        //     console.error('Error fetching data:', error);
                        // }
                    }
                });

                return () => {
                    window.$(gridRefs[activeTab].current).off('pagechanged', handlePageChanged);
                    // window.$(gridRefs[activeTab].current).off('cellclick', handleCellClick);
                    // if (window.$(gridRefs[activeTab].current).jqxGrid('destroy')) {
                    //     window.$(gridRefs[activeTab].current).jqxGrid('destroy');
                    //   }
                    // window.$(gridRefs[activeTab].current).on('pagesizechanged', handlePageSizeChanged);
                    if (gridRefs[activeTab].current) {
                        // Reinitialize or refresh grid data as necessary
                        window.$(gridRefs[activeTab].current).jqxGrid('updatebounddata');
                    }
                    // window.$(gridRef).jqxGrid('clearselection');
                    // window.$(gridRef).jqxGrid('destroy');
                };

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }

    }, [activeTab, gridData, selectedRowData, totalRows]);
    // START This is for irregular details open
    useEffect(() => {
        const gridRef = gridRefs[activeTab].current;
        if (gridRef) {
            // Reinitialize or refresh grid data as necessary
            window.$(gridRef).jqxGrid('updatebounddata');
        }
    }, [activeTab, gridData]);
    // This is for irregular details open END
    // console.log('101', gridData);
    const loadDetails = async (uldNumber) => {
        try {
            const res = await withRFSToken.post(GETULDDETAILS, { uld_number: uldNumber })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setSelectedRowData(innerData);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const showDownloadModal = () => {
        setDownloadOpen(true);
    };
    const closeDownloadModal = () => {
        setDownloadOpen(false);
    };
    // const [downloadData, setDownloadData] = useState({
    //     condition_code: [], status: '', origin: '', type_code: '', owner_code: '',
    // })
    // const downloadModalOpen = () => {
    //     setModalOpen(true);
    //     fetchTypeCode();
    //     fetchOwnerCode();
    //     fetchCondCode();
    //     fetchDestination();
    // }
    // const exportToExcel = async () => {
    //     try {
    //         const res = await withRFSToken.post(DOWNLOADULD, { tab_name: activeTab, fields: downloadData })
    //             .then(function (res) {
    //                 if (res.data.response_code == 200) {
    //                     const fileUrl = res.data.response_data;
    //                     // Create a temporary anchor element
    //                     const link = document.createElement('a');
    //                     link.href = fileUrl;

    //                     // Extract the filename from the URL if needed
    //                     const filename = fileUrl.split('/').pop(); // Gets the last part of the URL

    //                     // Set the download attribute with the filename
    //                     link.setAttribute('download', filename);

    //                     // Append the anchor to the body (necessary for the click to work in Firefox)
    //                     document.body.appendChild(link);

    //                     // Programmatically click the anchor to trigger the download
    //                     link.click();

    //                     // Clean up by removing the anchor from the DOM
    //                     document.body.removeChild(link);
    //                     setModalOpen(false);
    //                 } else {
    //                     toast.error(res.data.response_msg)
    //                 }

    //             });
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const columnsToExport = {
        // uld_number: 'ULD Number',
        type_code: 'Type Code',
        // serial_number: 'Serial Number',
        owner_code: 'Owner Code',
        condition_code: 'Condition Code',
        status: 'Status',
        origin: 'Origin',
        destination: 'Destination',
        // scm: 'SCM',
        // scm_date: 'SCM Date',
        // asset_type: 'Asset Type',
        // handler_name: 'Handler Name',
        // airline_name: 'Airline Name',
        // Add more columns here if needed
    };
    const handleColumnChange = (columnKey) => {
        setSelectedColumns(prevSelected =>
            prevSelected.includes(columnKey)
                ? prevSelected.filter(key => key !== columnKey)
                : [...prevSelected, columnKey]
        );
    };
    // const exportToExcel = async () => {

    //     // try {
    //     //     const res = await withRFSToken.post(DOWNLOADULD, { tab_name: activeTab, fields: selectedColumns })
    //     //         .then(function (res) {
    //     //             const innerData = res.data.response_data.ulds;
    //     //             console.log("🚀 ~ .then ~ innerData:987", innerData)
    //     //             // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
    //     //             // setGridData({ Active: innerData });
    //     //             // setTotalRecords(res.data.response_data.total_ulds);
    //     //         });


    //     // } catch (error) {
    //     //     console.error('Error fetching data:', error);
    //     // }
    //     if (gridRefs[activeTab].current) {
    //         const excelData = window.$(gridRefs[activeTab].current).jqxGrid('getrows');

    //         // const columnsToExport = ['uld_number', 'type_code']; // Add the column names you want to export
    //         // Filter the grid data to include only the specified columns
    //         // const filteredData = excelData.map(row => {
    //         //     const filteredRow = {};
    //         //     columnsToExport.forEach(col => {
    //         //         filteredRow[col] = row[col];
    //         //     });
    //         //     return filteredRow;
    //         // });
    //         // const columnsToExport = {
    //         //     uld_number: 'ULD Number',
    //         //     type_code: 'Type Code',
    //         //     serial_number: 'Serial Number',
    //         //     condition_code:'Condition Code',
    //         //     status:'Status',
    //         //     origin:'Origin',
    //         //     destination:'Destination',

    //         // };

    //         // Filter and transform the grid data to include only the specified columns with desired names
    //         const filteredData = excelData.map(row => {
    //             const filteredRow = {};
    //             // Object.keys(columnsToExport).forEach(key => {
    //             //     filteredRow[columnsToExport[key]] = row[key];
    //             // });
    //             selectedColumns.forEach(key => {
    //                 filteredRow[columnsToExport[key]] = row[key];
    //             });
    //             return filteredRow;
    //         });
    //         const worksheet = XLSX.utils.json_to_sheet(filteredData);

    //         // Create a new workbook and append the worksheet
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, 'GridData');

    //         // Write the workbook to a file and trigger a download
    //         XLSX.writeFile(workbook, 'GridData.xlsx');
    //         setSelectedColumns([]);
    //         setModalOpen(false);
    //     }
    // };



    // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider

    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFile('');
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image
    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [changeFunction, setchangeFunction] = useState(null);
    const [action, setAction] = useState(null);

    const handleFieldChange = (id, fieldName, event) => {
        const { value, files } = event.target;

        setGridSelect(prevFormData =>
            prevFormData.map(stack => {
                if (stack.uid === id) {
                    const updatedStack = { ...stack, [fieldName]: value };
                    return updatedStack;
                }
                return stack;
            })
        );
    };

    const handleDropdownItemClick = (pos, action) => {
        const keysToRemove = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
        keysToRemove.forEach(key => localStorage.removeItem(key));

        // Helper function to save data to localStorage and navigate to a URL
        const saveAndNavigate = (action, data) => {
            localStorage.setItem(action, JSON.stringify(data));
            localStorage.setItem('tab', 'assets');
            const fnPageUrl = '/ULDControl/ULDFunction';  // Replace with your dynamic path if needed
            window.location.href = fnPageUrl;
        };

        // Check if an action is already in operation
        const checkStatusAlreadyInOperation = (gridSelect, action) => {
            return gridSelect.some(item => item.status === action);
        };

        // Logic for handling 'details' position
        const handleDetailsAction = () => {
            if (['Repair In', 'Repair Out', 'SCM'].includes(action)) {
                const storageData = { "ULD": [selectedRowData], "Form": {} };
                saveAndNavigate(action, storageData);
            } else if (action === 'Lease Out') {
                if (selectedRowData.asset_type === 'Y') {
                    toast.error("ULDs with asset type Owned selected, this action is not possible.");
                } else {
                    const storageData = { "ULD": [selectedRowData], "Form": {} };
                    saveAndNavigate('Lease Out', storageData);
                }
            } else {
                if (action !== 'Condition') {
                    if (selectedRowData.status === action) {
                        toast.error('Status already in operation');
                    } else {
                        setAction(action);
                        setShowConfirmation(true);
                        setchangeFunction(() => changeStatus);
                    }
                } else {
                    setAction(action);
                    setShowConfirmation(true);
                    fetchDamageType();
                    setchangeFunction(() => changeStatus);
                }
            }
        };

        // Logic for handling 'top' position
        const handleTopAction = () => {
            if (gridSelect.length === 0) {
                if (['Repair In', 'Repair Out', 'SCM', 'Lease Out'].includes(action)) {
                    const storageData = { ULD: [], Form: {} };
                    saveAndNavigate(action, storageData);
                } else {
                    toast.error('Please select at least one stock to Operate');
                }
            } else {
                if (['Repair In', 'Repair Out', 'SCM'].includes(action)) {
                    const storageData = { ULD: gridSelect, Form: {} };
                    saveAndNavigate(action, storageData);
                } else if (action === 'Lease Out') {
                    const hasOwnedAssetType = gridSelect.some(item => item.asset_type === 'Y');
                    if (hasOwnedAssetType) {
                        toast.error("ULDs with asset type Owned selected, this action is not possible.");
                    } else {
                        const storageData = { ULD: gridSelect, Form: {} };
                        saveAndNavigate(action, storageData);
                    }
                } else {
                    if (action !== 'Condition') {
                        if (checkStatusAlreadyInOperation(gridSelect, action)) {
                            toast.error(`Status already ${action}`);
                        } else {
                            setAction(action);
                            setShowConfirmation(true);
                            setchangeFunction(() => changeStatus);
                        }
                    } else {
                        setAction(action);
                        setShowConfirmation(true);
                        fetchDamageType();
                        setchangeFunction(() => changeStatus);
                    }
                }
            }
        };

        // Main conditional logic based on the position (pos)
        if (pos === 'details') {
            handleDetailsAction();
        } else if (pos === 'top') {
            handleTopAction();
        }
    };


    const movementHistoryClick = () => {
        try {
            // Check if selectedRowData is defined
            if (!selectedRowData) {
                throw new Error('Selected row data is not available.');
            }

            localStorage.removeItem('MHistory');
            const storageData = {
                ULD: selectedRowData,  // ULD Details section
                Form: {}  // Empty form section
            };
            localStorage.setItem('MHistory', JSON.stringify(storageData));
            localStorage.setItem('tab', 'assets');
            window.location.href = '/ULDControl/MovementHistory'
        } catch (error) {
            // Log the error for debugging purposes
            console.error('Error during navigation:', error.message);
            alert('An error occurred: ' + error.message); // Optional user alert
        }
    }
    const handleCancelChange = () => {
        // setGridSelect([]);
        setShowConfirmation(false);
        setAction(null);
    };
    const handleConfirmChange = async (actionType) => {
        // deleteRow(gridSelect);
        if (actionType == 'In Operation') {
            const uldNumbers = selectedRowData ? selectedRowData.uld_number : gridSelect.map(item => item.uld_number);
            try {
                const res = await withRFSToken.post(UPDATESTATUS, { uld_codes: uldNumbers, status: 1 });
                if (res.data.response_code == 200) {
                    toast.success('Status changed to In Operation');
                    fetchDataGrid();
                    // unselect Grid
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                    //   Grid unselect and blank variable End
                    changeFunction(gridSelect)
                    setShowConfirmation(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (actionType == 'In Reserve') {
            const uldNumbers = selectedRowData ? selectedRowData.uld_number : gridSelect.map(item => item.uld_number);
            try {
                const res = await withRFSToken.post(UPDATESTATUS, { uld_codes: uldNumbers, status: 3 });
                if (res.data.response_code == 200) {
                    toast.success('Status changed to In Reserve');
                    fetchDataGrid();
                    setShowConfirmation(false);
                    // unselect Grid
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                    //   Grid unselect and blank variable End
                    changeFunction(gridSelect)

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (actionType == 'Condition') {
            // selectedRowData ? selectedRowData.uld_number : gridSelect.map(item => item.uld_number)
            const conditionType = conditionChange && conditionChange == 'Serviceable' ? 'SER' : (conditionChange == 'Damage' ? 'DAM' : 'DIS')
            if (conditionChange == 'Serviceable') {
                const uldNumbers = gridSelect && gridSelect.map(item => ({ code: item.uld_number }));
                try {
                    const res = await withRFSToken.post(UPDATECONDITION, { uld_codes: uldNumbers, condition_type: conditionType });
                    if (res.data.response_code == 200) {
                        toast.success('Condition Updated');
                        fetchDataGrid();
                        setShowConfirmation(false);
                        // unselect Grid
                        gridSelect.forEach(item => {
                            const selectedRowBoundIndex = item.boundindex;
                            window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                        });
                        setGridSelect([]);
                        //   Grid unselect and blank variable End
                        changeFunction(gridSelect)

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                const uldDam = gridSelect.map(item => ({ code: item.uld_number, damage_type: item.damage_type, desc: item.desc, files: item.files }));
                // console.log("🚀 ~ handleConfirmChange ~ uldDam:", uldDam)
                try {
                    const res = await withRFSToken.post(UPDATECONDITION, { uld_codes: uldDam, condition_type: conditionType });
                    if (res.data.response_code == 200) {
                        toast.success('Condition Updated');
                        fetchDataGrid();
                        setShowConfirmation(false);
                        // unselect Grid
                        gridSelect.forEach(item => {
                            const selectedRowBoundIndex = item.boundindex;
                            window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                        });
                        setGridSelect([]);
                        //   Grid unselect and blank variable End
                        changeFunction(gridSelect)

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

        }
    };

    //   +++++++++ single select station +++++++
    let [selected, setSelected] = useState([]);
    if (selected.length > 1) {
        selected = selected.slice(1);
    }
    const options = [
        { id: '1', label: 'option1', value: 'option1' },
        { id: '1', label: 'option2', value: 'option2' },
        { id: '1', label: 'option3', value: 'option3' },
        { id: '1', label: 'option4', value: 'option4' },
    ];
    const handleSelectChange = (selected) => {
        setSelected(selected);
    };
    //   +++++++++ single select station +++++++
    const handleRemove = (indexToRemove) => {
        const idToRemove = gridSelect[indexToRemove];
        const selectedRowBoundIndex = idToRemove.boundindex;
        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
        // setGridSelect(prevGridSelect => prevGridSelect.filter((id) => id.uid !== indexToRemove));
        const newGridSelect = [...gridSelect.slice(0, indexToRemove), ...gridSelect.slice(indexToRemove + 1)];
        setGridSelect(newGridSelect);
    };

    const changeStatus = async (selectedIds) => {
        // console.log("🚀 ~ deleteSelectedRows ~ selectedIds:", selectedIds)

        //console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // try {
        //   const res = await withToken.post(TRASH_ROW_IDS, {booking_ids: uniqueIds});
        //   console.log("responsehg==>", res);
        //   window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
        //   const updategrid = window.$(gridRef.current).jqxGrid();
        //   // setupGrid();
        //   reloadGrid();
        //   setGridSelect([]);
        //   window.$(gridRef.current).jqxGrid('clearselection');

        //   // setTimeout(() => {
        //   //    window.location.replace('/')  
        //   // },1000);

        // } catch (error) {
        //   console.error('Error sending request:', error);
        // }
    };

    const [conditionChange, setConditionChange] = useState('Serviceable');
    const [actArcModal, setActArcModal] = useState(false);

    const handleConditionRadioChange = (e) => {

        setConditionChange(e.target.value);
    };

    const handleMoveToActArc = () => {
        if (gridSelect.length <= 0) {
            toast.error('Please select grid data before send');
        } else {
            setActArcModal(true);
            // setModalAction(() => confirmMoveToActArc); // Set the action to be performed on confirmation
        }
    };
    const confirmMoveToActArc = async () => {
        // console.log('101', gridSelect);
        const sendAction = activeTab == 'Active' ? 'R' : 'A';
        const uldNumbers = gridSelect && gridSelect.map(num => num.uld_number)
        try {
            const res = await withRFSToken.post(MOVETOACTARC, { uld_numbers: uldNumbers, action: sendAction })
                .then(function (res) {
                    if (res.data.response_code == 200) {
                        gridSelect.forEach(item => {
                            const selectedRowBoundIndex = item.boundindex;
                            window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                        });
                        setGridSelect([]);
                        if (activeTab == 'Active') {
                            toast.success('ULD Data moved to Arhive');
                            fetchDataGrid()
                        } else {
                            toast.success('ULD Data moved to Active');
                            fetchDataGridR()
                        }
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    // upload Section
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [base64Files, setBase64Files] = useState([]);

    // const imageModalUpload = (id) => {
    //     setSelectedRowId(id);
    //     setShowModalUpload(true)
    //     setFiles([])
    // }
    const imageModalUpload = (id) => {
        const selectedRowFiles = gridSelect.find(item => item.uid === id)?.files || [];
        setFiles(selectedRowFiles);
        setSelectedRowId(id); // Store the ID of the selected row
        setShowModalUpload(true);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        getBase64(droppedFiles[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
        base64Files.splice(index, 1);
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    // const handleFileChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);
    //     const filesWithBase64 = [];
    //     selectedFiles.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             filesWithBase64.push({ file, base64: reader.result });
    //             if (filesWithBase64.length === selectedFiles.length) {
    //                 setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);

    //             }
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithBase64 = [];

        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                filesWithBase64.push({ file, base64: reader.result });

                if (filesWithBase64.length === selectedFiles.length) {
                    setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
                    setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const imageUpload = () => {
        setGridSelect(prevGridSelect =>
            prevGridSelect.map(item => {
                if (item.uid === selectedRowId) {
                    return { ...item, files: files, base64Files: base64Files };
                }
                return item;
            })
        );
        setShowModalUpload(false);
    };
    // upload forward
    const imageUploadRef = useRef(null);
    const browseClick = () => {
        if (imageUploadRef.current) {
            imageUploadRef.current.handleBrowseFiles();
        }
    };

    const handleDetailsClick = async(history) => {

        if (!selectedRowData) {
            throw new Error('Selected row data is not available.');
        }

        try {
            const res = await withRFSToken.post(MVTHDETAILS, { uld_id: selectedRowData.uld_id, uld_status_id: history.uld_status_id })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    localStorage.removeItem('MHistory');
                    localStorage.removeItem('MHDetails');
                    const storageData = {
                        ULD: selectedRowData,  // ULD Details section
                        Form: {}  // Empty form section
                    };
                    localStorage.setItem('MHistory', JSON.stringify(storageData));
                    const storageDataDetails = {
                        ULD: innerData.others_ulds,  // ULD Details section
                        Form: innerData.uld_movement_detail,
                        tab: 'All'  // Empty form section
                    };
                    localStorage.setItem('MHDetails', JSON.stringify(storageDataDetails));
                    // localStorage.setItem('tab', 'operations');
                    let detailsPageUrl;
                    const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
                    if (detailsType) {

                        detailsPageUrl = '/ULDControl/MovementHistorydetails';
                        window.location.href = detailsPageUrl;
                    } else {
                        console.log('No valid details type found');
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        // localStorage.removeItem('MHistory');
        // localStorage.removeItem('MHDetails');
        // const storageData = {
        //     ULD: selectedRowData,  // ULD Details section
        //     Form: {}  // Empty form section
        // };
        // localStorage.setItem('MHistory', JSON.stringify(storageData));
        // const storageDataDetails = {
        //     ULD: history,  // ULD Details section
        //     tab: 'All'  // Empty form section
        // };
        // localStorage.setItem('MHDetails', JSON.stringify(storageDataDetails));
        // localStorage.setItem('tab', 'assets');
        // let detailsPageUrl;
        // const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
        // if (detailsType) {

        //     detailsPageUrl = '/ULDControl/MovementHistorydetais';
        //     window.location.href = detailsPageUrl;
        // } else {
        //     console.log('No valid details type found');
        // }

    };
    return (
        <>
            {showEdit && <EditUld edit_data={editData} reloadEdit={activeTab == 'Active' ? fetchDataGrid : fetchDataGridR} onClose={handleCloseEditModal} />}
            {downloadOpen && <DownloadGrid download_sec={activeTab} onClose={closeDownloadModal} />}
            <div className="mb-3 nestedtabs rounded minh">
                {/* {console.log('999', files)} */}
                {/* {console.log('777', totalRows)} */}
                <div className='d-flex'>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='adduld'>
                            <FontAwesomeIcon icon={faPlus} /> Add ULD <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/ULDControl/AddStock">New Stock</Dropdown.Item>
                            <Dropdown.Item href="/ULDControl/NewCharter">New Charter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='action'>
                            <FontAwesomeIcon icon={faGear} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'In Operation')}>In Operation</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => handleDropdownItemClick('On Air')}>On Air</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair In')}>Repair In</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair Out')}>Repair Out</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'In Reserve')}>In Reserve</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Lease Out')}>Lease Out</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => handleDropdownItemClick('Lease In')}>Lease In</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'SCM')}>SCM</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button className="condition" variant="link" onClick={() => handleDropdownItemClick('top', 'Condition')}>
                        <span> <FontAwesomeIcon icon={faCircleCheck} /> Condition</span>
                    </button>
                    <span className="selected" >
                        Selected ULD <Badge>{gridSelect.length}</Badge>
                    </span>
                </div>
            </div>

            <div className='ulddown'>
                {activeTab == 'Active' ? (
                    <>
                        <button onClick={handleMoveToActArc}> <img title='' src={Arcv} /> Archive </button>
                        <button onClick={showDownloadModal}><img src={Export} /> Download</button>
                    </>
                ) : (
                    <>
                        <button onClick={handleMoveToActArc}> <img title='' src={Activ} /> Active </button>
                        <button onClick={showDownloadModal}><img src={Export} /> Download</button>
                    </>
                )
                }

            </div>

            <div className="maindiv">
                <div className='trashdonload'>
                    {/* {console.log('000', isLoading)} */}
                    <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                        <Tab eventKey="Active" title={<span>Active <Badge className='rabadge'>{totalRows.totalUlds}</Badge></span>} className='moveto'>
                            <Loader isLoading={isLoading} />
                            {!isLoading && <div ref={gridRefs.Active} className='bookinggrid' ></div>}
                        </Tab>
                        <Tab eventKey="Archived" title={<span>Archived <Badge className='rabadge'>{totalRowsR.totalUlds}</Badge></span>} className='moveto'>
                            <Loader isLoading={isLoading} />
                            {!isLoading && <div ref={gridRefs.Archived} className='bookinggrid' ></div>}
                        </Tab>
                    </Tabs>
                </div>
                {selectedRowData && (
                    <div className='SecondDiv mt-45'>
                        {/* {console.log('55', selectedRowData)} */}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.uld_number} &nbsp; <span>{selectedRowData.status}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={browseClick} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic" >
                                        <FontAwesomeIcon icon={faBolt} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'In Operation')}>In Operation</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair In')}>Repair In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair Out')}>Repair Out</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'In Reserve')}>In Reserve</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Lease Out')}>Lease Out</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'SCM')}>SCM</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Origin</p>
                                <span>{selectedRowData.origin}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.flight_date}</p>
                                <p><img src={Cargoplane} /></p>
                                <p>{selectedRowData.flight_number}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>Destination</p>
                                <span>{selectedRowData.destination}</span>
                            </div>
                        </div>
                        <div className='movment_history'>
                            <h3>Movement History</h3> <Link to='#' onClick={movementHistoryClick}>View All</Link>
                        </div>
                        <div className='movment_list'>
                            <ul>
                                {selectedRowData && selectedRowData.movement_history.slice(0, 5).map((history, index) => (
                                    <li key={index}>
                                        <span className='movem'>{history.operation_type}</span> <span className='mvdate'>{history.operation_date}</span>
                                        <Link to="#" onClick={() => handleDetailsClick(history)}>Details</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <ImageUpload
                            ref={imageUploadRef}
                            attachable_type={"uld"}
                            attachable_id={selectedRowData.uld_number}
                            attachments={selectedRowData.attachments}
                        />
                        <ActivityComments
                            commentable_type={"uld"}
                            commentable_id={selectedRowData.uld_number}
                            allactivities={selectedRowData.activities}
                            onReloadDetails={loadDetails}
                        />
                        {/* <div className='mt-4'>
                            
                            
                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>

                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>

                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>

            <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='type_work'>

                        <div className='ra_condition'>
                            <div
                                className='dropfile'
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <h3>Drag and drop files here</h3>
                                <p>Or</p>
                                <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
                                <span>Maximum file size 4 MB</span>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    multiple
                                />
                            </div>
                            <div className='uploadbox'>
                                {files.map((file, index) => (
                                    <div key={index} className='uploadfil'>
                                        {file.type.startsWith('image/') ? (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt="Preview"
                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                            />
                                        ) : (
                                            <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                        )}
                                        <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="primary" onClick={imageUpload}>
                            Upload
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='addfrom in_oparation' show={showConfirmation} onHide={() => setShowConfirmation(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{action}</Modal.Title>
                    <p>{action === 'Condition' ? 'Select Condition by selecting the option.' : "Do you want to change selected ULD's to " + action + '?'}</p>
                    {/* {['Repair', 'Lease Out', 'Condition'].includes(action) ? action : null} */}
                </Modal.Header>
                <Modal.Body>
                    {action == 'Condition' ?
                        (
                            <>
                                <Form.Group controlId="dropdown" className='mb-3 ck_condition'>
                                    <Form.Check
                                        label="Serviceable"
                                        name="condition_type"
                                        type='radio'
                                        id='Serviceable'
                                        value="Serviceable"
                                        checked={conditionChange === 'Serviceable'}
                                        onChange={handleConditionRadioChange}
                                    />
                                    <Form.Check
                                        label="Damaged"
                                        name="condition_type"
                                        type='radio'
                                        id='Damage'
                                        value="Damage"
                                        checked={conditionChange === 'Damage'}
                                        onChange={handleConditionRadioChange}
                                    />
                                    <Form.Check
                                        label="Discarded"
                                        name="condition_type"
                                        type='radio'
                                        id='Discarded'
                                        value="Discarded"
                                        checked={conditionChange === 'Discarded'}
                                        onChange={handleConditionRadioChange}
                                    />
                                </Form.Group>

                                <div className={conditionChange == 'Serviceable' ? 'd-flex flex-row flex-wrap bd-highlight inop serv_width' : 'd-flex flex-column bd-highlight inop '}>
                                    {gridSelect && gridSelect.map((num, index) => (
                                        <>
                                            {conditionChange == 'Serviceable' ? (
                                                <div key={index} className='inbox d-flex justify-content-between align-items-center'>
                                                    <span>{num.uld_number}</span>

                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ) : (conditionChange == 'Damage' ? (
                                                <div key={index} className='inbox d-flex justify-content-start align-items-center damage_w'>
                                                    <div><span>{num.uld_number}</span></div>
                                                    <div>
                                                        {/* <Form.Select name='damage_type' id={num.uid} onChange={(event) => handleFieldChange(num.uid, 'damage_type', event)}>
                                                            <option>Damag Type</option>
                                                            {damageType &&
                                                                Object.entries(damageType).map((option, index) =>
                                                                    <option key={index} value={option[0]}>{option[0]} - {option[1]}</option>
                                                                )
                                                            }
                                                        </Form.Select> */}
                                                        <Select
                                                            options={damageType}
                                                            onChange={(selected) => {
                                                                const updatedData = gridSelect.map(item => {
                                                                    if (item.uid === num.uid) {
                                                                        return { ...item, damage_type: selected.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                setGridSelect(updatedData);
                                                            }}
                                                            placeholder="Damage Type"
                                                            className='autoslct'
                                                            name="damage_type"
                                                        // value={damageType.find(option => option.value === num.damage_type)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Form.Control type="text" placeholder="Damage Description" name='desc' onChange={(event) => handleFieldChange(num.uid, 'desc', event)} />
                                                    </div>
                                                    <div>
                                                        <Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uid)}>Upload file</Button>
                                                    </div>
                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ) : (
                                                <div key={index} className='inbox d-flex justify-content-start align-items-center damage_w'>
                                                    <div><span>{num.uld_number}</span></div>
                                                    <div>
                                                        <Form.Control type="email" placeholder="Discarded Description" className='dis_des' name='desc' onChange={(event) => handleFieldChange(num.uid, 'desc', event)} />
                                                    </div>
                                                    <div>
                                                        <Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uld_number)}>Upload file</Button>
                                                    </div>
                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ))}

                                        </>
                                    ))}
                                </div>

                            </>
                        ) : (
                            <>
                                <div className='d-flex flex-wrap inop opara_w'>
                                    {selectedRowData ? (
                                        <div className='inbox'><span>{selectedRowData.uld_number}</span></div>
                                    ) : (
                                        <>{gridSelect && gridSelect.map((num, index) => (

                                            <div key={index} className='inbox'>
                                                <span>{num.uld_number}</span>
                                                <img src={Trash} alt='' onClick={() => handleRemove(index)} />
                                            </div>
                                        ))}
                                        </>
                                    )}

                                    {/* {selectedRowData && <div className='inbox'><span>{selectedRowData.uld_number}</span></div>} */}
                                </div>

                            </>
                        )}

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleCancelChange}> */}
                    {/* {['Repair', 'Lease In', 'Lease Out', 'Condition'].includes(action) ? 'Cancel' : 'No'} */}
                    {/* </Button> */}
                    <Button variant="primary" onClick={() => handleConfirmChange(action)}>
                        {['Repair', 'Lease Out', 'Condition', 'SCM'].includes(action) ? 'Update Status ' + action : 'Update Status'}
                    </Button>
                </Modal.Footer>
            </Modal>{console.log('357', gridSelect)}
            <Modal show={actArcModal} onHide={() => setActArcModal(false)} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3>Selected {gridSelect.length > 1 ? "ULD's" : 'ULD'} will move to {activeTab == 'Active' ? 'Archive' : 'Active'}</h3>
                    <p>Are you sure you want to move {gridSelect.length > 1 ? "ULD's" : 'ULD'} to {activeTab == 'Active' ? 'Archive' : 'Active'}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="secondary" onClick={() => setActArcModal(false)}>
                            No
                        </Button>
                        <Button variant="primary" onClick={confirmMoveToActArc}>
                            Yes
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Assets