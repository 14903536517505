import React, { useState, useEffect } from 'react';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import '../../App.css';
import "../../assets/css/bookingtruckschedule.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus, faChevronLeft, faChevronRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Header from "../../Componenets/Header/Header";
// import Stepper from '../../Componenets/Stepper/Stepper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TopMenu from "../../Componenets/TopMenu/TopMenu";
import LeftMenu from '../../Componenets/LeftMenu/LeftMenu';
import TruckActive from '../../Componenets/JGrid/TruckOrder/TruckActive';
import TruckTrash from '../../Componenets/JGrid/TruckOrder/TruckTrash';

import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Arcv from "../../assets/images/archive.svg";
import Trash from "../../assets/images/delete.svg";
import Export from "../../assets/images/export.svg";
import Exportw from "../../assets/images/exportw.png";
import firstImage from '../../assets/images/warehouse.png';
import secondImage from '../../assets/images/information.png';
import thirdImage from '../../assets/images/package.png';
import fourthImage from '../../assets/images/report.png';
import { TruckOrderOne, TruckOrderTwo, TruckOrderThree, TruckOrderFour } from "./TruckOrderForms";
import Completed from '../../assets/images/Completed.svg';

import { withRFSToken } from '../../Services/axios.js';
import { TRUCKORDERLIST,TRUCKORDERSAVE } from '../../Utils/conts';
import { forEach } from 'lodash';

const initialSuggestion = JSON.parse(localStorage.getItem('gridData')) || [];
const initialSuggestions = initialSuggestion.Active || [];

const CustomStepIcon = ({ icon }) => <span className='iconcer'>{icon}</span>;
const steps = [
    { label: (<>Step 1 <br />Type of Truck</>), icon: <img src={firstImage} /> },
    { label: (<>Step 2 <br />Truck Information</>), icon: <img src={secondImage} /> },
    { label: (<>Step 3 <br />Truck Booking</>), icon: <img src={thirdImage} /> },
    { label: (<>Step 4 <br />Adding Documents</>), icon: <img src={fourthImage} /> },
]; // Define your steps

const TruckOrder = () => {

    const [exportGrid, setExportGrid] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {

                const res = await withRFSToken.post(TRUCKORDERLIST, { type: 'A' })
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        setExportGrid(innerData.export);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // Call the fetchData function to fetch data from the API
        fetchData();

    }, []);


    // Date Function Start
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedDate, setSelectedDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleSelect = date => {
        setSelectedDate(date);
        setShowCalendar(false); // Close the calendar when a date is selected
    };

    const [currentDate, setCurrentDate] = useState(new Date());
    const daysToShow = 7;

    function handleNext() {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + daysToShow);
        setCurrentDate(newDate);
    }

    function handlePrevious() {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - daysToShow);
        setCurrentDate(newDate);
    }

    function isCurrentDate(date) {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    }

    function getDayName(date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
    }

    function getMonthAbbreviation(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[date.getMonth()];
    }
    // Date Function End
    // Grid Tab Function Start
    const [trashGridLoaded, setTrashGridLoaded] = useState(false);

    const handleTabSelect = (selectedTabKey) => {
        if (selectedTabKey === 'trash' && !trashGridLoaded) {
            setTrashGridLoaded(true);
        }
    };
    // Grid Tab Function End
    // Stepper Function Start
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        truck_type: {
            truck_type: 'booking_truck', bookingTruck_type: 'existing', existing_date: '', booking_list: '',schedule_date_id:'',
            hoc_movement_type: '', hoc_board_point: '', hoc_board_address: '', hoc_off_point: '', hoc_off_address: '', hoc_date: '', hoc_time: '',
            new_movement_type: '', new_board_point: '', new_board_address: '', new_off_point: '', new_off_address: '', new_date: '', new_time: '',
            new_board_point_name: '', new_board_address_name: '', new_off_point_name: '', new_off_address_name: '', hoc_board_point_name: '', hoc_board_address_name: '', hoc_off_point_name: '', hoc_off_address_name: '',
        },
        truck_information: {
            truck_no: '', truck_load: '', temperature: 'Y', drivers: '', adr: '', remarks: '', drivers_number: '', truck_inf_date: '',
            truck_inf_time: ''
        },
        truck_booking: { rate: '', rateId: '' },
        truck_documents: {files:[]},
    });
    const [completedData, setCompletedData] = useState([]);
    const [finishStep, setFinishStep] = useState(false);

    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);


    // api call for all data submit-------------
    const handleStepClick = (stepIndex) => {
        if (completedData[stepIndex]) {
            setActiveStep(stepIndex);
        }
    };

    const validateFormData = () => {
        let isValid = true;
        if (activeStep === 0) {
            if (!formData.handler_information.handler_address) {
                isValid = false;
            }

            if (!formData.handler_information.handler_werehouse) {
                isValid = false;
            }

        }
        if (activeStep === 1) {
            if (!formData.trip_information.airline_conserned[0]) {
                isValid = false;
            }
            if (!formData.trip_information.movement_type) {
                isValid = false;
            }
            if (!formData.trip_information.type_of_vehicle) {
                isValid = false;
            }
            if (!formData.trip_information.type_of_loading) {
                isValid = false;
            }

        }

        return isValid;
    };


    const stepNext = () => {
        // if (validateFormData()) {
        setCompletedData((prevCompletedData) => ({
            ...prevCompletedData,
            [activeStep]: formData,
        }));

        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //   }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        console.log("completedData");
        console.log(completedData);
        console.log("formData");
        console.log(formData);
    };
    const stepBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setFormData(completedData[activeStep - 1] || {});
    };


    const handleFinish = async () => {
        console.log(JSON.stringify(formData));
        //New
        var allfiles=[];
        if(formData.truck_documents){
            formData.truck_documents.forEach(element => {
                allfiles.push(element.base64)
            });
        }
        var boardingPoint=formData.truck_type.new_board_point || formData.truck_type.hoc_board_point;
        var boardingPointAdd=formData.truck_type.new_board_address || formData.truck_type.hoc_board_address;
        var offpoint=formData.truck_type.new_off_point || formData.truck_type.hoc_off_point;
        var offpointAdd=formData.truck_type.new_off_address || formData.truck_type.hoc_off_address;
        var gdate=formData.truck_type.new_date || formData.truck_type.hoc_date;
        var gtime=formData.truck_type.new_time || formData.truck_type.hoc_time;
        var bookingtruck_no="Adsdsa5"
        let updatedFormData={
            "type_of_truck":formData.truck_type.truck_type,
            "bookingTruck_type":formData.truck_type.bookingTruck_type,
            "truck_no":formData.truck_information.truck_no,
            "booking_date":formData.truck_information.truck_inf_date,
            "schedule_id":formData.truck_type.booking_list,
            "schedule_date_id":formData.truck_type.schedule_date_id,
            "booking_time":formData.truck_information.truck_inf_time,
            "truck_load":formData.truck_information.truck_load,
            "temperature":formData.truck_information.temperature,
            "driver_number":formData.truck_information.drivers_number,
            "adr":formData.truck_information.adr,
            "remarks":formData.truck_information.remarks,
            "file_paths":allfiles,
            "rate_type":formData.truck_booking.rate,
            "carriers":formData.truck_booking.rateId,
            "new_schedule_data":{
                "booking_type":formData.truck_type.new_movement_type,
                "bookingtruck_no":bookingtruck_no,
                "boardpoint":boardingPoint,
                "boardpoint_address":boardingPointAdd,
                "offpoint":offpoint,
                "offpoint_address":offpointAdd,
                "trip_type":"O",
                "date":gdate,
                "time":gtime,
            },
        }




        
        
        try {
            const res = await withRFSToken.post(TRUCKORDERSAVE, updatedFormData)
              .then(function (res) {
                setCompletedData((prevCompletedData) => ({
                    ...prevCompletedData,
                    [activeStep]: formData,
                }));
                setFinishStep(true);
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
              });
      
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    // Stepper Function End
    const handleFormDataChange = (formName, data) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [formName]: data,
        }));
        // console.log(formName);
        // console.log(data);
        console.log(formData);
    }

    return (
        <>
            <div className="">
                <Header />
                <div className="page-body-wrapper schedule">
                    <LeftMenu />
                    <div className="main-panel rounded">
                        <div className="mainTab">
                            <TopMenu />
                            <div className="">
                                <div className="allcontent-fl">
                                    <div className='d-flex justify-content-end mb-3 order_search'>
                                        <div className='search'>
                                            <div className="form-group has-search">
                                                <span className="form-control-feedback"> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                                <input type="text" className="form-control" placeholder="Search" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="lcontent">
                                        <div className="mb-3 nestedtabs rounded">

                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <button className="addtruck" variant="primary" onClick={handleShow}>
                                                        <span> Order Truck</span>
                                                    </button>
                                                </div>
                                                <div className='d-flex'>
                                                    <div>
                                                        <div style={{ position: 'relative' }}>
                                                            <div onClick={toggleCalendar} className='clendericon'>
                                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                            </div>
                                                            {showCalendar && (
                                                                <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 9999, }}>
                                                                    <DatePicker
                                                                        selected={selectedDate}
                                                                        onChange={handleSelect}
                                                                        inline
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="calendar-container schedule_calender">
                                                        <div className="scrollable-calendar">
                                                            <button className='btnleft' onClick={handlePrevious}> <FontAwesomeIcon icon={faChevronLeft} /> </button>
                                                            {[...Array(daysToShow)].map((_, index) => {
                                                                const date = new Date(currentDate);
                                                                date.setDate(currentDate.getDate() + index);
                                                                return (
                                                                    <div key={index} className={`day ${isCurrentDate(date) ? 'current-date' : ''}`}>
                                                                        <div className="day-name">{getDayName(date)}</div>
                                                                        <div className="date"> {getMonthAbbreviation(date)} {date.getDate()}</div>
                                                                    </div>
                                                                );
                                                            })}

                                                            <button className='btnright' onClick={stepNext}> <FontAwesomeIcon icon={faChevronRight} /> </button>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div></div>
                                            </div>

                                        </div>

                                        <Tabs defaultActiveKey="active" id="nested" className="booking_tab" onSelect={handleTabSelect}>
                                            <Tab eventKey="active" title={<span>Active</span>}>
                                                <TruckActive updateGridData={exportGrid} />
                                            </Tab>
                                            <Tab eventKey="trash" title={<span>Trash</span>}>
                                                <TruckTrash updateGridData={exportGrid} />
                                            </Tab>
                                        </Tabs>

                                        <Modal size="xl" show={show} onHide={handleClose} centered className='addfrom truck_order'>
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>

                                                <div className='st-f'>
                                                    <Stepper activeStep={activeStep} alternativeLabel className='rasteper'>
                                                        {steps.map(({ label, icon }, index) => (
                                                            <Step key={index} className={(completedData[index]) ? 'raroot done' : 'raroot'}>
                                                                <StepLabel StepIconComponent={() => <CustomStepIcon icon={icon} className="rastplabel" />} onClick={() => handleStepClick(index)}>{label}</StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                    <div className="stepper-right">
                                                        {/* {activeStep === steps.length  */}
                                                        {finishStep ? (
                                                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                                                <div className='complt'>
                                                                    <img src={Completed} alt='' />
                                                                    <h3>Thank you!</h3>
                                                                    <p>Submit was successful</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="stepper-frms">
                                                                    {/* <Typography>Step {activeStep + 1}</Typography> */}
                                                                    {/* Render different form components based on the active step */}
                                                                    {/* Example: */}
                                                                    {/* {activeStep === 0 && <TruckOrderOne formData ={formData.handler_information} onFormDataChange={(data) => handleFormDataChange('handler_information', data)} />}
                                    {activeStep === 1 && <TruckOrderTwo formData ={formData.trip_information} onFormDataChange2={(data) => handleFormDataChange('trip_information', data)} />}
                                    {activeStep === 2 && <TruckOrderThree formData ={formData.images} onFormDataChange6={(data) => handleFormDataChange('images', data)} />}
                                    {activeStep === 3 && <TruckOrderFour formData ={formData.driver_information} onFormDataChange5={(data) => handleFormDataChange('driver_information', data)} />} */}

                                                                    {activeStep === 0 && <TruckOrderOne formData={formData.truck_type} onFormDataChange={(data) => handleFormDataChange('truck_type', data)} />}
                                                                    {activeStep === 1 && <TruckOrderTwo formData={formData.truck_information} onFormDataChange2={(data) => handleFormDataChange('truck_information', data)} />}
                                                                    {activeStep === 2 && <TruckOrderThree formData={formData.truck_booking} onFormDataChange3={(data) => handleFormDataChange('truck_booking', data)} />}
                                                                    {activeStep === 3 && <TruckOrderFour formData={formData.truck_documents} onFormDataChange4={(data) => handleFormDataChange('truck_documents', data)} />}

                                                                    {/* {activeStep === 6 && <CreateReportFormOne />} */}
                                                                    <div className='nxtbtn'>
                                                                        <Button disabled={activeStep === 0} onClick={stepBack}>
                                                                            Back
                                                                        </Button>
                                                                        <Button className='trnext' variant="contained" onClick={activeStep === steps.length - 1 ? handleFinish : stepNext}>
                                                                            {activeStep === steps.length - 1 ? 'Send Request' : 'Next'}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className='stepper-frm-info'>
                                                                    {Object.entries(completedData).map(([step, data], index) => (
                                                                        <div key={step} variant="outlined" className='infoarm'>
                                                                            <div>
                                                                                {step === '0' && (
                                                                                    <div className='infobox'>
                                                                                        <span className='rastp'>Step 1</span>
                                                                                        <h2>Type of Truck</h2>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <span>Truck Type: </span>
                                                                                                <span> {completedData[step].truck_type.truck_type == 'B' ? 'Boocking Truck' : 'Ad Hoc'}</span>
                                                                                            </li>
                                                                                            {completedData[step].truck_type.truck_type == 'B' ? (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <span>Bookingtruck Type: </span>
                                                                                                        <span> {completedData[step].truck_type.bookingTruck_type == 'E' ? 'Existing Bookingtruck' : 'New Bookingtruck'}</span>
                                                                                                    </li>
                                                                                                    {completedData[step].truck_type.bookingTruck_type != 'E' ?
                                                                                                        <>
                                                                                                            <li>
                                                                                                                <span>Board Point: </span>
                                                                                                                <span> {completedData[step].truck_type.new_board_point_name}</span>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <span>Boardpoint Address: </span>
                                                                                                                <span> {completedData[step].truck_type.new_board_address_name}</span>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <span>Offpoint: </span>
                                                                                                                <span> {completedData[step].truck_type.new_off_point_name}</span>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <span>Offpoint Address: </span>
                                                                                                                <span> {completedData[step].truck_type.new_off_address_name}</span>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <span>Date: </span>
                                                                                                                <span> {completedData[step].truck_type.new_date}</span>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <span>Time: </span>
                                                                                                                <span> {completedData[step].truck_type.new_time}</span>
                                                                                                            </li>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <li>
                                                                                                                <span>Date: </span>
                                                                                                                <span> {completedData[step].truck_type.existing_date}</span>
                                                                                                            </li>

                                                                                                            <li>
                                                                                                                <span>Booking List: </span>
                                                                                                                <span> {completedData[step].truck_type.booking_list}</span>
                                                                                                            </li>
                                                                                                        </>
                                                                                                    }

                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <span>Hoc Movement Type: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_movement_type == 'E' ? 'E' : 'I'}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Board Point: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_board_point_name}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Boardpoint Address: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_board_address_name}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Offpoint: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_off_point_name}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Offpoint Address: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_off_address_name}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Date: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_date}</span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <span>Hoc Time: </span>
                                                                                                        <span> {completedData[step].truck_type.hoc_time}</span>
                                                                                                    </li>
                                                                                                </>
                                                                                            )

                                                                                            }

                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                                {step === '1' && (

                                                                                    <div className='infobox'>
                                                                                        <span className='rastp'>Step 2</span>
                                                                                        <h2>Truck Informaion</h2>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <span>Operational Truck No: </span>
                                                                                                <span>{completedData[step].truck_information.truck_no}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Date: </span>
                                                                                                <span>{completedData[step].truck_information.truck_inf_date}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Time: </span>
                                                                                                <span>{completedData[step].truck_information.truck_inf_time}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Truck Load: </span>
                                                                                                <span>{completedData[step].truck_information.truck_load == "F" ? 'FLT' : 'LTL'}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Temperature Controled: </span>
                                                                                                <span>{completedData[step].truck_information.temperature == "Y" ? 'YES' : 'NO'}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Number off Drivers: </span>
                                                                                                <span>{completedData[step].truck_information.drivers_number}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>ADR Required: </span>
                                                                                                <span>{completedData[step].truck_information.adr == "Y" ? 'YES' : 'NO'}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Remarks: </span>
                                                                                                <span>{completedData[step].truck_information.remarks}</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>

                                                                                )}
                                                                                {step === '2' && (

                                                                                    <div className='infobox'>
                                                                                        <span className='rastp'>Step 5</span>
                                                                                        <h2>Documents</h2>

                                                                                    </div>

                                                                                )}
                                                                                {step === '3' && (

                                                                                    <div className='infobox'>
                                                                                        <span className='rastp'>Step 5</span>
                                                                                        <h2>Documents</h2>

                                                                                    </div>

                                                                                )}


                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TruckOrder;