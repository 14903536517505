import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargotruck from "../../../assets/images/cargo-truck.png";
import Cuser from "../../../assets/images/cuser.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { UCMHISTORYGRID } from '../../../Utils/conts.js';

const UCM = () => {
    const isMounted = useRef(false);
    const [gridData, setGridData] = useState([]);
    const [totalRows, setTotalRows] = useState({});
    const fetchDataGrid = async () => {
        try {
            const res = await withRFSToken.post(UCMHISTORYGRID, {history_type: 'ucm'})
                .then(function (res) {
                    const innerData = res.data.response_data.ulds;
                    const innerDataCount = res.data.response_data.pagination;
                    setGridData(innerData);
                    setTotalRows({
                        pageSize: innerDataCount.page_size,
                        currPage: innerDataCount.current_page,
                        lastPage: innerDataCount.last_page,
                        totalUlds: innerDataCount.total
                    });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        // Call the fetchData function to fetch data from the API
        if (isMounted.current) return;
        fetchDataGrid();
        isMounted.current = true;
    }, [])
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {

        if (gridRef.current) {
            if (gridData) {
                // const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                // }));
                // console.log("🚀 ~ localData ~ localData:", localData)
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'operation_type', type: 'string' },
                        { name: 'timestamp', type: 'string' },
                        { name: 'flight_or_truck', type: 'string' },
                        { name: 'flight_number', type: 'string' },
                        { name: 'flight_date', type: 'string' },
                        { name: 'flight_reg_number', type: 'string' },
                        { name: 'origin', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'origin_handler', type: 'string' },
                        { name: 'destination_handler', type: 'string' },
                        { name: 'airline_name', type: 'string' },
                    ],
                    localdata: gridData,
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                console.log('localdata', source.localdata)
                const columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    // {
                    //     text: 'Actions',
                    //     cellsrenderer: function () {
                    //         return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                    //     },
                    //     width: '5%'
                    // },
                    { text: 'UCM In/Out', filtertype: 'textbox', datafield: 'operation_type', width: '6%', cellclassname: '', },
                    { text: 'Timestamp', filtertype: 'textbox', datafield: 'timestamp', width: '8%', cellclassname: '', },
                    { text: 'Flight/Truck', filtertype: 'textbox', datafield: 'flight_or_truck', width: '8%', cellclassname: '', },
                    { text: 'Flight Number', filtertype: 'textbox', datafield: 'flight_number', width: '8%', cellclassname: '', },
                    { text: 'Flight Date', filtertype: 'range', datafield: 'flight_date', width: '8%', cellclassname: '', },
                    { text: 'Flight Reg. Number', filtertype: 'textbox', datafield: 'flight_reg_number', width: '8%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '8%', cellclassname: '', },
                    { text: 'Origin Handler Name', filtertype: 'textbox', datafield: 'origin_handler', width: '10%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                    { text: 'Destination Handler Name', filtertype: 'textbox', datafield: 'destination_handler', width: '10%', cellclassname: '', },
                    { text: 'Airline Name', filtertype: 'textbox', datafield: 'airline_name', width: '10%', cellclassname: '', },
                ];

                // Nested ULD Start
                const nestedColumns = [
                    { text: 'ULD Number', datafield: 'uld_number', width: '12%' },
                    { text: 'Type Code', datafield: 'type_code', width: '12%' },
                    { text: 'Serial Number', datafield: 'serial_number', width: '12%' },
                    { text: 'Condition Code', datafield: 'condition_code', width: '12%' },
                    { text: 'Content Code', datafield: 'content_code', width: '12%' },
                    { text: 'ULD Asset', datafield: 'uld_asset', width: '12%' },
                    // Add more columns as needed
                ];
                const initrowdetails = (index, parentElement, gridElement, record) => {
                console.log("🚀 ~ initrowdetails ~ parentElement:", parentElement)

                    const nestedGridContainer = parentElement.children[0];

                    parentElement.addEventListener('click', (event) => {
                        const target = event.target;
                        console.log("🚀 ~ parentElement.addEventListener ~ target:", target)
                        
                        // Check if the click target is the nested arrow
                        if (window.$(target).hasClass('jqx-icon-arrow-right')) {
                            // Handle nested arrow click here
                            console.log('Nested arrow clicked:', record);
                            event.stopPropagation(); // Prevent event bubbling to the row details
                        }
                    });

                    const orders = [
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Non-Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Non-Registered'
                        },
                    ];

                    const nestedSource = {
                        datatype: 'json',
                        id: 'shipmentChildID',
                        datafields: [
                            { name: 'uld_number', type: 'string' },
                            { name: 'type_code', type: 'string' },
                            { name: 'serial_number', type: 'string' },
                            { name: 'condition_code', type: 'string' },
                            { name: 'content_code', type: 'string' },
                            { name: 'uld_asset', type: 'string' },
                        ],
                        localdata: orders,
                    }

                    const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);
                    console.log("🚀 ~ initrowdetails ~ nestedDataAdapter:", nestedDataAdapter);


                    const nestedGridOptions = {
                        width: '100%',
                        pageable: false,
                        sortable: false,
                        autoheight: true,
                        source: nestedDataAdapter,
                        filterable: false,
                        columns: nestedColumns,
                        rowdetails: true,
                        rowsheight: 45,
                    };
                    window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
                };
                // Nested End

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: true,
                    editable: false,
                    // selectionmode: 'checkbox',
                    initrowdetails: initrowdetails,
                    pageable: true,
                    pageSize: 10,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    rowdetails: true,
                    rowdetailstemplate: {
                        rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
                        rowdetailsheight: 15 * 45,
                        rowdetailshidden: true,
                    },

                };

                window.$(gridRef.current).jqxGrid(gridOptions);
                window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

                // window.$(gridRef.current).on('cellclick', (event) => {
                //     console.log('event', event);
                //     const args = event.args.row.bounddata;
                //     console.log('args', args);
                //     if (event.args.datafield == 'ucm_inout') {
                //     const sendRowId = gridData;
                //     console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
                //     setSelectedRowData({ args, station_id: args.id });
                //     }

                // });
                window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });
                // row selection by checkbox
                // window.$(gridRef.current).on('rowselect', (event) => {
                //     console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

                //     if (event.args.row) {

                //         const selectedRow = event.args.row.id;

                //         if (!gridSelect.includes(selectedRow)) {
                //             setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
                //         }
                //         setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
                //     } else {
                //         // Handle "Select All" case
                //         // const allIds = planData.map(row => row.booking_id);
                //         // console.log("All IDs:", allIds);
                //         // setGridSelect(allIds);
                //         console.log("Select All triggered");
                //     }
                // });
                // // console.log('ggg', gridSelect);
                // window.$(gridRef.current).on('rowunselect', (event) => {
                //     if (event.args.row) {
                //         const unselectedRowId = event.args.row.id;
                //         setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
                //     } else {
                //         setGridSelect([]);
                //     }
                // });
                // End row selection 

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
    }, [gridData, selectedRowData]);

    const exportToExcel = () => {
        if (gridRef.current) {
            window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
        }
    };

    // +++++++++++++++++++ div +++++++++++++++++++++++++

    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };


    // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload 

    const [html, setHtml] = useState('');

    function onChange(e) {
        setHtml(e.target.value);
    }

    // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider

    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFile('');
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image
    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    return (
        <>
            {/* <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex justify-content-between'>
                    
                </div>
            </div> */}

            {/* <div className='ulddown'>
                <button onClick={exportToExcel}><img src={Export} /> Download</button>
            </div> */}

            <div className="maindiv">
                <div className='trashdonload'>
                    
                    <div ref={gridRef} className='bookinggrid' ></div>
                </div>
                {selectedRowData && (
                    <div className='SecondDiv'>
                        {console.log('55', selectedRowData)}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.bookingtruck_no} <span>{selectedRowData.status === 'A' ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />}</span> <span>{selectedRowData.status === 'A' ? 'Planned' : 'To be planned'}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={handleBrowseFiles} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faBolt} /> Action
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Edit Bookingtruck</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Manage Shipments</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Order Truck</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Boardpoint</p>
                                <span>{selectedRowData.boardpoint}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.date}</p>
                                <p><img src={Cargotruck} /></p>
                                <p>{selectedRowData.time}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>Offpoint</p>
                                <span>{selectedRowData.offpoint}</span>
                            </div>
                        </div>
                        <div>
                            <h3>Operational Trucks</h3>

                            {/* {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                                <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                                    {console.log("🚀 ~ ExportActive ~ truck:", truck)}
                                    <div>
                                        <span>Truck No</span>
                                        <h4>{truck.truck_no}</h4>
                                    </div>
                                    <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                                        <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                                    </div>
                                    <div>
                                        <Link>Details</Link>
                                    </div>
                                </div>
                            ))} */}
                        </div>

                        <div className='my-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className='mb-0'>Attachments</h3>
                                <div className='d-flex rightdrop'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                                            <FontAwesomeIcon icon={faEllipsis} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Open gallery view</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Download all</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                    >
                                        <button className='raplas' onClick={handleBrowseFiles}><FontAwesomeIcon icon={faPlus} /></button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            multiple
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className='raslider'>
                                <div className="slider-container">
                                    {
                                        files.length > 2 ?
                                            <Slider {...settings}>
                                                {files.map((file, index) => (
                                                    <div key={index} className='slideimg'>
                                                        {file.type.startsWith('image/') ? (
                                                            <>
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt="Preview"
                                                                    style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                    onClick={() => handleFileClick(URL.createObjectURL(file))}
                                                                />
                                                                <span className='rafilename'>{file.name}</span>
                                                            </>
                                                        ) : (
                                                            <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                                        )}
                                                        <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                                                    </div>
                                                ))}
                                            </Slider>
                                            :
                                            <>
                                                <div className='nonslide'>
                                                    {files.map((file, index) => (
                                                        <div key={index} className='slideimg'>
                                                            {file.type.startsWith('image/') ? (
                                                                <>
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt="Preview"
                                                                        style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                        onClick={() => handleFileClick(URL.createObjectURL(file))}
                                                                    />
                                                                    <span className='rafilename'>{file.name}</span>
                                                                </>
                                                            ) : (
                                                                <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                                            )}
                                                            <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                    }


                                </div>

                                <Modal show={show} onHide={handleClose} centered className='imgmodal'>
                                    <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                                    <Modal.Body >
                                        {console.log(selectedFile)}
                                        <img src={selectedFile} className="img-fluid" alt="Selected" />
                                    </Modal.Body>
                                </Modal>

                            </div>

                        </div>

                        <div>
                            <h3>Activity</h3>

                            <div className='activity'>
                                <span>Show :</span>
                                <Button variant="link">All</Button>
                                <Button variant="link active">Comments</Button>
                                <Button variant="link">History</Button>
                            </div>
                            <div className='activity'>
                                <span>Truck :</span>
                                <Button variant="link">All</Button>
                                <Button variant="link">#123</Button>
                                <Button variant="link">#124</Button>
                                <Button variant="link">#125</Button>
                                <Button variant="link">#126</Button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div className='mb-3'>
                                <div className='d-flex comntbox'>
                                    <div>
                                        <span className='user_com'>RS</span>
                                    </div>
                                    <div className='edithtml'>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data="<p> Hello </p>"
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event) => {
                                                console.log(event);
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='commnt_save'>
                                    <Button variant="primary rasave">Save</Button>
                                    <Button variant="link racancel">Cancel</Button>
                                </div>
                            </div>
                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>

                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>

                            <div className='d-flex racomment'>
                                <div className='coment_img'><img src={Cuser} /></div>
                                <div>
                                    <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                                    <p>I have no reply, so i will take this ticket to next week</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default UCM