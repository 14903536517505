import React from 'react';
import '../../App.css';
import Header from "../../Componenets/Header/Header";
import LeftMenu from "../../Componenets/LeftMenu/LeftMenu";
import TopMenu from "../../Componenets/TopMenu/TopMenu";

const UldDamage = () => {
  return (
    <>
    <div className="container-scroller">
            <Header />
            <div className="container-fluid d-flex p-0 page-body-wrapper">
                <LeftMenu />
                <div className="main-panel rounded">
                    <div className="mainTab">
                        <TopMenu />
                    </div>
                </div>
            </div>
      </div>
    </>
  )
}

export default UldDamage;