import { faCircleCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../../App.css';
import Header from "../../Componenets/Header/Header";
import JGrid from "../../Componenets/JGrid/JGrid";
import ArchivedGrid from '../../Componenets/JGrid/ArchivedGrid.js';
import TrashGrid from '../../Componenets/JGrid/TrashGrid.js';
import { withToken } from '../../Services/axios.js';
import { retrievePosts } from "../../Services/store/actions/posts.js";
import { DASHBOARD } from '../../Utils/conts.js';
import Arcv from "../../assets/images/archive.svg";
import Trash from "../../assets/images/delete.svg";
import Export from "../../assets/images/export.svg";

const handleLinkClick = async () => {
 
    try {
      const response = await fetch('/your-server-endpoint', {
        method: 'POST', 
      });
  
     
      console.log(response);
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };

const PlanDashboard = () => {
 
  // using Async Await
  // const getMyPostData = async () => {
  //   try {
  //     const res = await withToken.get("/post");
  //     console.log("51 getMyPostData",res.data);
  //   } catch (error) {
  //     console.error('Error sending request:', error);
  //   }
  // };

  // api call
  // const dispatch = useDispatch();

  // const getDashboard = async () => {
  //   try {
  //     const res = await withToken.post(DASHBOARD, {});
  //     dispatch(retrievePosts(res.data));
  //     console.log("plan dashboard==>", res.data);
  //   } catch (error) {
  //     console.error('Error sending request:', error);
  //   }
  // };


  // token related code | start
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token");
    const localStorageData = localStorage.getItem('token');
    const history = useHistory();
    const location = useLocation();
    if(token != "" && token !=null){
      localStorage.setItem('token',token)
      queryParameters.delete('token');
        history.push({
          pathname: location.pathname,
        });    
        window.location.reload();
    }
    if(localStorageData == null && token ==null){
      window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL)
    }
  // token related code | end

 
    // NOTE:  calling the function
    useEffect(() => {
      // console.log("base url==>",process.env.REACT_APP_BASE_URL + DASHBOARD);
      // getDashboard();
    
    }, []);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

   console.log("test api==>",process.env.REACT_APP_BASE_URL);
   //return <Redirect to ='truckingprod.mydemowork.co.in'/>
  //  const [reloadStatus, setReloadStatus] = useState({
  //   active: false,
  //   archived: false,
  //   trash: false,
  // });

  // const handleTabSelect = (tabKey) => {
  //   setReloadStatus((prevStatus) => ({
  //     ...prevStatus,
  //     [tabKey]: true,
  //   }));
  // };
  
   
   useEffect(() => {
 
  //   getDashboard().then((res)=>{  
  //     console.log("46data==>",res.data);
  //     localStorage.setItem('jgrid',JSON.stringify(res.data));
  //  });
    // const token = getToken();
    // setWebToken(token); 
   

    // if (!token) {
    //   window.location.href = "http://truckingprod.mydemowork.co.in";
    // }
  }, []);

  // Render Redirect component if token exists
  // if (webToken) {
  //    <Redirect to="/" />;
  // }
  const [gridLoaded, setGridLoaded] = useState({
    incoming: true,
    archived: false,
    trash: false
});

const loadGrid = (tabKey) => {
  setGridLoaded((prevGridLoaded) => ({
      ...prevGridLoaded,
      [tabKey]: true
  }));
};


  const [archiveGridLoaded, setArchiveGridLoaded] = useState(false);
  const [trashGridLoaded, setTrashGridLoaded] = useState(false);

    const handleTabSelect = (selectedTabKey) => {
        if (selectedTabKey === 'archived' && !archiveGridLoaded) {
            setArchiveGridLoaded(true);
        } else if (selectedTabKey === 'trash' && !trashGridLoaded) {
            setTrashGridLoaded(true);
        }
    };

  
  return (
    <>
    <div className="">
            <Header />
            <div className="page-body-wrapper">
                
                <div className="main-panel rounded">
                    <div className="mainTab">
                        
                        <div className="">
                          <div className="allcontent-fl">
                              <div className="rcontent">
                                  <button onClick={handleShow}> <img src={Arcv}/> Archive</button>
                                  <button><img src={Trash}/> Move to Trash</button>
                                  <button><img src={Export}/> Download</button>

                              </div>
                              <div className="lcontent">
                                <button className="createplan" onClick={handleShow}>
                                    <FontAwesomeIcon icon={faPlus} /> Create Plan</button>
                                    {/* <ul className='mb-3 nestedtabs rounded nav nav-tabs'>
                                      <li className='nav-item'>
                                        <button className='nav-link'><span>Active</span></button>
                                      </li>
                                      <li className='nav-item'>
                                        <button className='nav-link'><Link to="/Archive"><span>Archive</span></Link></button>
                                      </li>
                                      <li className='nav-item'>
                                        <button className='nav-link'><span>Trash</span></button>
                                      </li>
                                    </ul> */}
                                    <Tabs defaultActiveKey="incoming" id="nested" className="mb-3 nestedtabs rounded"  onSelect={handleTabSelect}>
                                    <Tab eventKey="incoming" title={<span>Active</span>}>
                                        
                                        <div className="content rounded">
                                            {/* <JGrid onReloadArchive={() => handleReload('archive')} /> */}
                                            <JGrid />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="archived" title={<span>Archived</span>}>
                                        <div className="content rounded">
                                            {/* <ArchivedGrid reload={reloadStatus.archiveTab} /> */}
                                            {archiveGridLoaded && <ArchivedGrid />}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="trash" title={<span>Trash</span>}>
                                        <div className="content rounded">
                                            {/* <TrashGrid reload={reloadStatus.trashTab} /> */}
                                            {trashGridLoaded && <TrashGrid />}
                                        </div>
                                    </Tab>
                                </Tabs> 
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
      </div>
      <Modal dialogClassName="modal-100w" show={show} onHide={handleClose} centered size="lg" className='typemodal'>
            <Modal.Header closeButton onClick={handleClose}>
            {/* <Modal.Title>Please Select Your Type</Modal.Title> */}
            </Modal.Header>
            <Modal.Body closeButton>
                <h4>Please Select Your Type</h4>
            <div className="cr-pln-btns">
                <Link to="/ShipmentPlan"><FontAwesomeIcon icon={faCircleCheck} /><br />Shipment</Link>
                <Link to="/UldstackPlan" onClick={handleLinkClick}><FontAwesomeIcon icon={faCircleCheck} /><br />ULD Stack</Link>
            </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
        </Modal>
    </>
  )
}

export default PlanDashboard;