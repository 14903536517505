import React, { useEffect, useState } from 'react';
import '../../App.css';
import Header from "../../Componenets/Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faArrowLeft, faCaretDown, faSortUp, } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import "../../assets/css/shipment.css";
import { Link, useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { width } from '@mui/system';
import { withToken } from '../../Services/axios.js';
import { DASHBOARD } from '../../Utils/conts.js';
import { Modal } from 'react-bootstrap';
import Docmnt from '../../assets/images/google-docs.png';

export const GridDetails = () => {

  const [completedDetails, setCompletedDetails] = useState({});

  const [rowId, setRowId] = useState('');
  const [rowType, setRowType] = useState('');
  // const [bookBy, setBookBy] = useState('');
  // const [movementType, setMovementType] = useState('');
  const [type, setType] = useState('');
  // const [vehicleType, stVehicleType] = useState('');
  // const [handlerAddress, setHandlerAddress] = useState('');
  // const [handlerWarehouse, setHandlerWarehouse] = useState('');
  // const [loading, setLoading] = useState('');
  // const [airlineConcerned, setAirlineConcerned] = useState('');
  // const [refranceNumber, setRefranceNumber] = useState('');
  // const [driverName, setDriverName] = useState('');
  // const [trailerLicense, setTrailerLicense] = useState('');
  // const [truckLicense, setTruckLicense] = useState('');
  // const [sealNumber, setSelNumber] = useState('');
  // const [bookingTime, setBookingTime] = useState('');
  // const [booking_date, setBooking_date] = useState('');
  // const [booking_door, setBooking_door] = useState('');
  // const [shipment_nested, setShipment_nested] = useState([]);
  // const [file, setFiles] = useState([]);



  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFile('');
  };



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const itemId = queryParams.get('id');
    const plantype = queryParams.get('plantype');
    console.log('ID from URL:', itemId);
    setRowType(plantype);
    setRowId(itemId);
    getDashboard();
  }, [rowId]);


  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const history = useHistory();
  const EditPlan = () => {
    const itemId = rowId;
    const plantype = rowType;
    if (plantype == 'Shipment'){
      history.push(`/EditShipmentPlan?id=${itemId}&plantype=${rowType}`);
    } else if (plantype == 'ULD') {
      history.push(`/EditULDPlan?id=${itemId}&plantype=${rowType}`);
    }
    
  }

  const getDashboard = async () => {
    try {
      const res = await withToken.post(DASHBOARD, { booking_id: rowId, plantype: rowType });
      console.log("grid details==>", res.data);
      const udtateDetailsData = res.data.data;
      setCompletedDetails(udtateDetailsData);
      setType(res.data.data.type);
      // setHandlerAddress(res.data.data.handler_address);
      // setHandlerWarehouse(res.data.data.handler_warehouse);
      // setMovementType(res.data.data.movement);
      // setAirlineConcerned(res.data.data.aConcerned);
      // stVehicleType(res.data.data.vehicle);
      // setLoading(res.data.data.loading_unloading);
      // setDriverName(res.data.data.drivername);
      // setBooking_date(res.data.data.booking_date);
      // setBooking_door(res.data.data.door);
      // setBookingTime(res.data.data.booking_time);
      // setRefranceNumber(res.data.data.ref_number);
      // setTrailerLicense(res.data.data.trailer_license);
      // setTruckLicense(res.data.data.truck_license);
      // setSelNumber(res.data.data.seal_number);
      // setShipment_nested(res.data.data.shipment);
      // setBookBy(res.data.data.email);
      // setFiles(res.data.data.file_paths);
    } catch (error) {
      console.error('Error sending request:', error);
    }

    
  };

 console.log("******", completedDetails)

  return (
    <>
      <div>
        <Header />
        <div className="page-body-wrapper">

          <div className="main-panel">
            <Link className='backdetails' to="/"  > <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
            {completedDetails &&
            <>
            <div className="content rounded mb-3">
              <div className='d-flex justify-content-between px-4'>
                <div className='viewbook'>
                  <h4>Booking : {rowId} <span style={{ marginLeft: '10px', }}>{completedDetails.movement}</span> <span className='raopen'>Open</span></h4>
                  {/* <p>Booked By - {bookBy} </p> */}
                  <p><b>Booking Date :</b> {completedDetails.booking_date} <span style={{ marginLeft: '10px', marginRight: '10px', }}><b>Time Slot :</b> {completedDetails.booking_time} </span> <b>Door :</b> {completedDetails.door}</p>
                </div>
                <div className='detailsbtn'>
                  <button><FontAwesomeIcon icon={faTrashCan} /> Delete Order </button>
                  <button onClick={EditPlan}><FontAwesomeIcon icon={faPenToSquare} /> Edit Order</button>
                </div>

              </div>
            </div>

            <div className="content rounded">

              <div className='gird_details'>

                <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen>
                  <Accordion.Item eventKey="0" >
                    <Accordion.Header>Handler  / Trip Information </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <ul>
                          <li><span>Plan ID</span> : <span>{completedDetails.id}</span></li>
                          <li><span>Type </span> : <span>{completedDetails.type}</span></li>
                          <li><span>Handler Address</span> : <span>{completedDetails.handler_address}</span></li>
                          <li><span>Handler Warehouse</span> : <span>{completedDetails.handler_warehouse}</span></li>
                        </ul>
                        <ul>
                          <li><span>Movement Type</span> : <span>{completedDetails.movement}</span></li>
                          <li><span>Type of Vehicle</span> : <span>{completedDetails.vehicle}</span></li>
                          <li><span>loading/unloading</span> : <span>{completedDetails.loading_unloading}</span></li>
                          <li><span>Airline Concerned</span> : <span>{completedDetails.aConcerned}</span></li>
                          <li><span>Reference Number</span> : <span>{completedDetails.ref_number}</span></li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1" >
                    <Accordion.Header>Cargo Information
                      {/* <span className='mx-4'> Loose : 1</span> */}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='details_table'>
                        {completedDetails.type == 'Shipment' ? (
                          <>
                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th style={{ width: '10px' }}></th>
                              <th>Airway bill</th>
                              <th>Package</th>
                              <th>Pieces</th>
                              <th>Weight(KG)</th>
                              <th>Custom Code</th>
                              <th>Special Handling Code</th>
                              <th>Security Code</th>
                              <th>Shipment Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {completedDetails.shipment.map((nst, index) => (
                              <React.Fragment key={index}>
                                <tr onClick={toggleCollapse}>
                                  <td>
                                    {isCollapsed ? (
                                      <FontAwesomeIcon icon={faCaretDown} color='#BCBCBC' />
                                    ) : (
                                      <FontAwesomeIcon icon={faSortUp} color='#BCBCBC' />
                                    )}
                                  </td>
                                  <td>{nst.airway_bill}</td>
                                  <td>{nst.Pkg}</td>
                                  <td>{nst.Pcs}</td>
                                  <td>{nst.Wgt}</td>
                                  <td>{nst.CCode}</td>
                                  <td>{nst.Shcode}</td>
                                  <td>{nst.Securitycode}</td>
                                  <td>{nst.shipment_type}</td>
                                </tr>
                                {!isCollapsed && (
                                  <tr>
                                    <td></td>
                                    <td colSpan="4">
                                      <Table striped bordered>
                                        <thead>
                                          <tr>
                                            <th>House Airway Bill</th>
                                            <th>Pieces</th>
                                            <th>Weight (KG)</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {nst.hawBills.map((haw, i) => (
                                            <tr key={i}>
                                              <td>{haw.HABill}</td>
                                              <td>{haw.HPieces}</td>
                                              <td>{haw.HWgt}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </Table>
                        </>
                        ) : (completedDetails.type == 'ULD' ? (
                          <>
                          <Table striped bordered>
                          <thead>
                            <tr>
                              <th style={{ width: '10px' }}></th>
                              <th>Type Code</th>
                              <th>Serial Number</th>
                              <th>Owner Code</th>
                              <th>Airline Name</th>
                              <th>Condition Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {completedDetails.shipment.map((nst, index) => (
                              <React.Fragment key={index}>
                                <tr onClick={toggleCollapse}>
                                  <td>
                                    {isCollapsed ? (
                                      <FontAwesomeIcon icon={faCaretDown} color='#BCBCBC' />
                                    ) : (
                                      <FontAwesomeIcon icon={faSortUp} color='#BCBCBC' />
                                    )}
                                  </td>
                                  <td>{nst.type_code}</td>
                                  <td>{nst.serial_number}</td>
                                  <td>{nst.owner_code}</td>
                                  <td>{nst.airline_name}</td>
                                  <td>{nst.condition_code}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </Table>
                        </>
                        ) : '') }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Booking Slot</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <ul className='w-100'>
                          <li><span>Booking Year</span> : <span>{(new Date(completedDetails.booking_date)).getFullYear()}</span></li>
                          <li><span>Booking Date</span> : <span>{completedDetails.booking_date}</span></li>
                          <li><span>Time Slot</span> : <span>{completedDetails.booking_time}</span></li>
                          <li><span>Door</span> : <span>{completedDetails.door} </span></li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Driver/Truck Information</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <ul className='w-100'>
                          <li><span>Driver Name</span> : <span>{completedDetails.drivername}</span></li>
                          <li><span>Trailer License Plate Nr.</span> : <span>{completedDetails.trailer_license}</span></li>
                          <li><span>Truck License Plate Nr.</span> : <span>{completedDetails.truck_license}</span></li>
                          <li><span>Seal Number</span> : <span>{completedDetails.seal == 'yes' ? completedDetails.seal_no : 'No'}</span></li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Documents</Accordion.Header>
                    <Accordion.Body>
                      <div className='d-flex align-content-around flex-wrap ragap'>
                        {completedDetails.file_paths && completedDetails.file_paths.map((file, index) => (
                          <>
                            <div key={index} className='raimage_file'>
                            {console.log("🚀 ~ GridDetails ~ file:", file)}
                              {/* {file.type.startsWith('image/') ? (
                                                <>
                                                <img
                                                src={URL.createObjectURL(file)}
                                                alt="Preview"
                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                onClick={() => handleFileClick(URL.createObjectURL(file))}
                                                />
                                                <span className='rafilename'>{file.name}</span>
                                                </>
                                                ) : (
                                                <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                                )} */}


                              {/* {file.type.startsWith('image/') ? ( */}
                              <img
                                src={process.env.REACT_APP_BASE_URL_IMAGE + file.url}
                                alt="Preview"
                                style={{ maxWidth: '100px', maxHeight: '100px', }}
                                onClick={() => handleFileClick(process.env.REACT_APP_BASE_URL_IMAGE + file.url)}
                              />
                              {/* ) : (
                                                <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                              )} */}



                            </div>
                          </>
                        ))}

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>
            </div>
            </>
            }

            <Modal show={show} onHide={handleClose} centered className='imgmodal'>
              <Modal.Header closeButton onClick={handleClose}></Modal.Header>
              <Modal.Body >
                {console.log(selectedFile)}
                <img src={selectedFile} className="img-fluid" alt="Selected" />
              </Modal.Body>
            </Modal>

          </div>
        </div>
      </div>


    </>
  )
}

export default GridDetails;