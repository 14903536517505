import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPlus, faGear, faSquare, faXmark, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Fullsize from "../../../../../assets/images/full-size.svg";
import Trash from "../../../../../assets/images/trash.png";
import Docmnt from "../../../../../assets/images/file.svg";
import { Modal } from 'react-bootstrap';
import { withoutRFSToken, withRFSToken } from '../../../../../Services/axios.js';
import { GETAGENT, GETDESTINATION, GETEXTAGENT, GETINTAGENT, GETINTAGENTHAND, GETOWNERCODE, ULDADDRESSSAVE } from '../../../../../Utils/conts.js';
import { toast } from 'react-toastify';


const FormFuntion = () => {

  // const queryParameters = new URLSearchParams(window.location.search);
  // const type = queryParameters.get("action");
  // const uldids = queryParameters.get("ids");

  const actionTypes = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
  let keyType = null;


  // Loop through the actionTypes array
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the current key matches any of the action types
    if (actionTypes.includes(key)) {
      keyType = key;
      break;
    }
  };
  // All Origin and Destination Start
  const isMounted = useRef(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [getOrigin, setGetOrigin] = useState([]);
  const [getOwnerCodes, setGetOwnerCode] = useState([]);
  const [originHandler, setOriginHandler] = useState([]);
  const [selectedOriginId, setSelectedOriginId] = useState(null);
  const [selectedOriginHandler, setSelectedOriginHandler] = useState(null);
  const [getDestination, setGetDestination] = useState([]);
  const [destinationHandler, setDestinationHandler] = useState([]);
  const [selectedDestinationId, setSelectedDestinationId] = useState(null);
  const [selectedDestinationHandler, setSelectedDestinationHandler] = useState(null);
  const [addressModalShow, setAddressModalShow] = useState(false);
  const addressModalClose = () => setAddressModalShow(false);
  const [inputAddress, setInputAddress] = useState({
    id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
  });
  const [address, setAddress] = useState([]);
  const [formData, setFormData] = useState({ flight_or_truck: 'F' });
  // ADD ADDRESS
  const [addressFor, setAddressFor] = useState('');
  const addressModalOpen = (addressType) => {
    setAddressFor(addressType);
    setAddressModalShow(true);

  }
  useEffect(() => {
    if (['LUC In', 'LUC Out', 'Transfer'].includes(keyType)) {
      setInputAddress(prevState => ({
        ...prevState,
        agent_or_handler: 'A',
        agent_type: keyType == 'Transfer' ? 'I' : 'E'
      }));
    } else if (['Request'].includes(keyType)) {
      console.log('RRR', keyType);
      if (addressFor == 'origin') {
        console.log('RRR1', addressFor);
        setInputAddress(prevState => ({
          ...prevState,
          agent_or_handler: 'A',
          agent_type: 'E'
        }));
      } else {
        setInputAddress(prevState => ({
          ...prevState,
          agent_or_handler: '',
          agent_type: 'I'
        }));
      }

    } else {
      setInputAddress(prevState => ({
        ...prevState,
        agent_or_handler: 'H'
      }));
    }
  }, [keyType, addressFor]);

  let orgLabel;
  if (keyType == 'Request') {
    if (selectedOriginId) {
      const idMatch = getOrigin.find(option => option.station_id == selectedOriginId);
      // console.log("🚀 ~ FormFuntion ~ idMatch:0", idMatch)
      orgLabel = idMatch.label;
    }
  } else {
    if (selectedOriginId) {
      const idMatch = getDestination.find(option => option.station_id == selectedOriginId);
      // console.log("🚀 ~ FormFuntion ~ selectedOriginId:", selectedOriginId)
      // console.log("🚀 ~ FormFuntion ~ idMatch:1", idMatch)
      orgLabel = idMatch.label;
    }
  }

  let destLabel;
  if (selectedDestinationId) {
    const idMatch = getDestination.find(option => option.station_id == selectedDestinationId);
    // console.log("🚀 ~ FormFuntion ~ idMatch:", idMatch)
    destLabel = idMatch.label;
  }
  const handleAddressChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox' && name === 'regd') {
      setInputAddress({ ...inputAddress, [name]: checked ? '1' : '0' });
    } else {
      setInputAddress({ ...inputAddress, [name]: value });
    }
    console.log(inputAddress);
  };

  const validateAddressData = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate email
    const validateEmail = (email) => {
      return emailRegex.test(email);
    };
    if (!inputAddress.company_name) {
      console.log('Text input is required');
      toast.error('Company Name must not be blank');
      isValid = false;
    } else if (!inputAddress.agent_or_handler) {
      console.log('Text input is required');
      toast.error('Please select Agent or Handler');
      isValid = false;
    }
    else {
      return isValid;
    }
  };

  const [sendStationId, setSendStationId] = useState('');
  const openAddressModal = (id) => {
    setSendStationId(id);
    setAddressModalShow(true);
  }
  const addAddress = async (e) => {
    // console.log('444', inputAddress);
    if (validateAddressData()) {
      e.preventDefault();

      let stationid;
      if (addressFor == 'origin') {
        stationid = selectedOriginId;
      } else {
        stationid = selectedDestinationId;
      }

      const NewInputAddress = { ...inputAddress, station_id: stationid };
      // console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
      if (NewInputAddress) {
        try {
          const res = await withRFSToken.post(ULDADDRESSSAVE, NewInputAddress)
            .then(function (res) {
              // const innerData = res;
              // console.log("🚀 ~ .then ~ innerData:", innerData)
              if (res.data.response_code == 200) {
                const newAddress = res.data.response_data;
                setAddress(newAddress);
                fetchDestination();
                if (addressFor == 'origin') {
                  setOriginHandler(prevHandlerNames => [
                    ...prevHandlerNames,
                    newAddress
                  ]);
                } else {
                  setDestinationHandler(prevHandlerNames => [
                    ...prevHandlerNames,
                    newAddress
                  ]);
                }
                setInputAddress({
                  station_address_id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
                });
                console.log(address);
                addressModalClose();
              } else {
                toast.error(res.data.response_msg);
              }
            });

        } catch (error) {
          console.error('Error sending request:', error);
        }
      }
    }
  }
  // ADD ADDRESS END
  // All Origin and Destination End
  const fetchDestination = async () => {
    if (['LUC In', 'LUC Out'].includes(keyType)) {
      try {
        const res = await withRFSToken.post(GETAGENT, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
              address: item.address,
              station_id: item.station_id
            })) : [];
            setGetDestination(transformedData);
            setIsDataLoaded(true);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(false);
      }
    } else if (keyType == 'Transfer') {
      try {

        const res = await withRFSToken.post(GETINTAGENT, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
              address: item.address,
              station_id: item.station_id
            })) : [];
            setGetDestination(transformedData);
            setIsDataLoaded(true);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(false);
      }
    } else if (keyType == 'Request') {
      try {

        const res = await withRFSToken.post(GETEXTAGENT, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
              address: item.address,
              station_id: item.station_id
            })) : [];
            setGetOrigin(transformedData);
            setIsDataLoaded(true);
          });
        const resp = await withRFSToken.post(GETINTAGENTHAND, {})
          .then(function (resp) {
            const innerData = resp.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
              address: item.address,
              station_id: item.station_id
            })) : [];
            setGetDestination(transformedData);
            setIsDataLoaded(true);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(false);
      }
    } else {
      try {

        const res = await withRFSToken.post(GETDESTINATION, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
              address: item.address,
              station_id: item.station_id
            })) : [];
            setGetDestination(transformedData);
            setIsDataLoaded(true);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(false);
      }
    }

  };
  const fetchOwnerCode = async () => {
    try {

      const res = await withRFSToken.post(GETOWNERCODE, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const transformedData = innerData ? innerData.map(item => ({
            value: item.IATA,
            label: item.IATA
          })) : [];
          setGetOwnerCode(transformedData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDataFromLocalStorage = () => {
    const keys = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
    setFormData({});
    let storedData = null;
    // Iterate over the keys and retrieve the first non-null value from localStorage
    for (const key of keys) {
      storedData = localStorage.getItem(key);
      if (storedData) break;
    }
    const parsedData = storedData ? JSON.parse(storedData) : null;
    // Set the ULD data to the state
    if (parsedData && parsedData.Form) {
      if (keyType == 'UCM In') {
        setFormData(parsedData.Form);
        // const flightData = parsedData.Form.flight_number;
        // const [flight, flightNumber] = flightData ? flightData.split('-') : ['', ''];

        // setFormData(prevFormData => ({
        //   ...prevFormData,
        //   flight_or_truck: parsedData.Form.flight_or_truck || '',
        //   flight_code: flight || '',           // Set the flight code
        //   flight_number: flightNumber || '', // Set the flight number
        //   flight_date: parsedData.Form.flight_date || '',
        //   origin: parsedData.Form.origin || '',
        //   origin_handler: parsedData.Form.origin_handler || '',
        //   destination: parsedData.Form.destination || '',
        //   destination_handler: parsedData.Form.destination_handler || '',
        // }));
      }
      // } else if (keyType == 'UCM Out'){
      else {
        const data = parsedData.Form;
        const updatedFormData = {
          ...data,
          flight_or_truck: data.flight_or_truck || 'F',
        };
        setFormData(updatedFormData)
      }
    }

  };
  useEffect(() => {

    fetchDataFromLocalStorage();
    const handleLocalStorageUpdate = () => {
      fetchDataFromLocalStorage();
    };
    window.addEventListener('localStorageUpdated', handleLocalStorageUpdate);

    if (keyType == 'UCM In') {
      const keyData = JSON.parse(localStorage.getItem(keyType)) || { ULD: {} };
      setFormData(keyData.Form)
    } else if (keyType == 'UCM Out') {
      const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
      setFormData(prevFormData => ({
        ...prevFormData,
        flight_or_truck: keyData.flight_or_truck || 'F',
      }));
      if (keyData.Edit == 'onAir') {
        setFormData(keyData.Form);
      }
    } else {
      const keyData = JSON.parse(localStorage.getItem(keyType)) || { ULD: {} };
      console.log("🚀 ~ return ~ keyData:", keyData)
      setFormData(keyData.Form)
    }

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('localStorageUpdated', handleLocalStorageUpdate);


    };
    // if(keyType== 'UCM In'){
    //   const keyData = JSON.parse(localStorage.getItem(keyType)) || { ULD: {} };
    //   const flightData = keyData.ULD.map(item => item.flight_number)
    //   console.log('333',flightData);
    //   setFormData(keyData.ULD)
    // }
  }, [originHandler]);

  useEffect(() => {
    if (isMounted.current) return;
    fetchOwnerCode();
    fetchDestination();
    isMounted.current = true;
  }, []);

  useEffect(() => {

    if (isDataLoaded && formData.origin) {
      const selectedDestination = getDestination.find(option => option.value === formData.origin);
      if (selectedDestination) {
        console.log("🚀 ~ useEffect ~ selectedDestination (programmatic):", selectedDestination);
        setOriginHandler(selectedDestination.address);
        setSelectedOriginId(selectedDestination.station_id);
        if (formData.origin_handler) {
          const selectedOH = selectedDestination.address.find(option => option.id === formData.origin_handler)
          if (selectedOH) {
            setSelectedOriginHandler({ value: selectedOH.id, label: selectedOH.company_name })
          }
        }
        // setSelectedOriginHandler(null);
        // console.log("🚀 ~ useEffect ~ selectedDestination XXX", originHandler);
      } else {
        setOriginHandler([]);
        setSelectedOriginId(null);
        // setSelectedOriginHandler(null);
      }
    }
    if (formData.destination) {
      const selectedDestination = getDestination.find(option => option.value === formData.destination);
      if (selectedDestination) {
        setDestinationHandler(selectedDestination.address);
        setSelectedDestinationId(selectedDestination.station_id);
        if (formData.destination_handler) {
          const selectedOH = selectedDestination.address.find(option => option.id === formData.destination_handler)
          if (selectedOH) {
            setSelectedDestinationHandler({ value: selectedOH.id, label: selectedOH.company_name })
          }
        }
        // setSelectedDestinationHandler(null);
      } else {
        setDestinationHandler([]);
        setSelectedDestinationId(null);
        // setSelectedDestinationHandler(null);
      }
    }

    // transfer point
    if (isDataLoaded && formData.transfer_point) {
      const selectedDestination = getDestination.find(option => option.value === formData.transfer_point);
      if (selectedDestination) {
        console.log("🚀 ~ useEffect ~ selectedDestination (programmatic):", selectedDestination);
        setOriginHandler(selectedDestination.address);
        setSelectedOriginId(selectedDestination.station_id);
        if (formData.transferred_by) {
          const selectedOH = selectedDestination.address.find(option => option.id === formData.transferred_by)
          if (selectedOH) {
            setSelectedOriginHandler({ value: selectedOH.id, label: selectedOH.company_name })
          }
        }
        // setSelectedOriginHandler(null);
        // console.log("🚀 ~ useEffect ~ selectedDestination XXX", originHandler);
      } else {
        setOriginHandler([]);
        setSelectedOriginId(null);
        // setSelectedOriginHandler(null);
      }
    }
    if (formData.luc_final_dest) {
      const selectedDestination = getDestination.find(option => option.value === formData.luc_final_dest);
      if (selectedDestination) {
        setDestinationHandler(selectedDestination.address);
        setSelectedDestinationId(selectedDestination.station_id);
        if (formData.received_by) {
          const selectedOH = selectedDestination.address.find(option => option.id === formData.received_by)
          if (selectedOH) {
            setSelectedDestinationHandler({ value: selectedOH.id, label: selectedOH.company_name })
          }
        }
        // setSelectedDestinationHandler(null);
      } else {
        setDestinationHandler([]);
        setSelectedDestinationId(null);
        // setSelectedDestinationHandler(null);
      }
    }


  }, [formData, isDataLoaded, getDestination])

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevFormData => {
      const newFormData = { ...prevFormData, [name]: type === 'checkbox' ? checked : value };

      const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
      keyData.Form = newFormData;
      localStorage.setItem(keyType, JSON.stringify(keyData));

      return newFormData;
    });
  };

  // upload Section
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [base64Files, setBase64Files] = useState([]);

  // const imageModalUpload = (id) => {
  //     setSelectedRowId(id);
  //     setShowModalUpload(true)
  //     setFiles([])
  // }
  // const imageModalUpload = (id) => {
  //   console.log('aaaa', id);
  //   const selectedRowFiles = formData.find(item => item.uid === id)?.files || [];
  //   console.log("🚀 ~ imageModalUpload ~ selectedRowFiles:", selectedRowFiles)
  //   setFiles(selectedRowFiles);
  //   setSelectedRowId(id); // Store the ID of the selected row
  //   setShowModalUpload(true);
  // };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    getBase64(droppedFiles[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    base64Files.splice(index, 1);
  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithBase64 = [];

    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        filesWithBase64.push({ file, base64: reader.result });

        if (filesWithBase64.length === selectedFiles.length) {
          setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
          setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
          setFormData(prevFormData => {
            const newFormData = { ...prevFormData, files: files, base64Files: base64Files };
            const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
            keyData.Form = newFormData;
            localStorage.setItem(keyType, JSON.stringify(keyData));

            return newFormData;
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const imageUpload = () => {
    setFormData(prevFormData =>
      prevFormData.map(item => {
        if (item.uid === selectedRowId) {
          return { ...item, files: files, base64Files: base64Files };
        }
        return item;
      })
    );
    setShowModalUpload(false);
  };



  return (
    <>
      <div className={['Transfer', 'Request'].includes(keyType) ? 'mb-3 nestedtabs rounded' : 'mb-3 nestedtabs rounded h-100'}>
        {console.log('666', formData)}
        {/* {console.log('667', getOrigin)} */}
        <div className='charter_top'>
          <h3>{keyType == 'Transfer' ? 'Station ' : (keyType == 'Request' ? "Transfer " : '')} {keyType}</h3>
        </div>
        <div className={keyType == 'Transfer' && keyType == 'Request' ? 'trs_height' : ''}>
          <div className='charter_from from_width'>
            {keyType == 'Repair In' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Repair Name </Form.Label>
                    <Form.Control type="text" name='repair_name' onChange={handleInputChange} />
                  </Col>

                  <Col sm="6" >
                    <Form.Label>Repair Station <span>*</span></Form.Label>
                    {/* <Form.Control type="text" name='repair_station' onChange={handleInputChange} /> */}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, repair_station: selected.value };
                        // setFormData(updatedData);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, repair_station: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Station"
                      className='autoslct'
                      name="origin"
                      value={getDestination.find(option => option.value === formData.repair_station)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Repair In <span>*</span></Form.Label>
                    <Form.Control type="datetime-local" name='repair_in' onChange={handleInputChange} />
                  </Col>

                  {/* <Col sm="6" className='pe-0'>
                    <Form.Label>Repair Out </Form.Label>
                    <Form.Control type="text" />
                  </Col> */}


                </Form.Group>
              </>
            ) : (keyType == 'Repair Out' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Repair Name </Form.Label>
                    <Form.Control type="text" name='repair_name' onChange={handleInputChange} />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Repair Station <span>*</span></Form.Label>
                    {/* <Form.Control type="text" name='repair_station' onChange={handleInputChange} /> */}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, repair_station: selected.value };
                        // setFormData(updatedData);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, repair_station: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Station"
                      className='autoslct'
                      name="origin"
                      value={getDestination.find(option => option.value === formData.repair_station)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6">
                    <Form.Label>Repair Out <span>*</span></Form.Label>
                    <Form.Control type="datetime-local" name='repair_out' onChange={handleInputChange} />
                  </Col>

                  {/* <Col sm="6" className='pe-0'>
                    <Form.Label>Repair Out </Form.Label>
                    <Form.Control type="text" />
                  </Col> */}


                </Form.Group>
              </>
            ) : (keyType == 'Lease Out' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Station Out </Form.Label>{console.log('1000', formData)}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        const updatedData = { ...formData, destination: selected.value, handler_name: '' };

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Destination"
                      className='autoslct'
                      name="destination"
                      value={getDestination.find(option => option.value === formData.destination)}
                    />
                  </Col>
                  {/* <Col sm="6">
                    <label className="form-label">Handler Name {formData.destination ? <button onClick={() => setAddressModalShow(true)} className='raad_adrss'>+ Add</button> : ''}</label>
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination_handler: selected.label };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="destination_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col> */}
                </Form.Group>
              </>
            ) : (keyType == 'LUC In' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Return Date <span>*</span></Form.Label>
                    <Form.Control type="datetime-local" name='return_date' onChange={handleInputChange} />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Return To</Form.Label>
                    <Form.Control type="text" name='return_to' onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >

                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Point <span>*</span></Form.Label>
                    {/* <Form.Control type="text" name='transfer_point' onChange={handleInputChange} /> */}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transfer_point: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Transfer Point"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.transfer_point)}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Returned By <span>* </span> {formData.transfer_point ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" name='returned_by' onChange={handleInputChange} /> */}
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, returned_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent Name"
                      className='autoslct'
                      name="returned_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Control Receipt Number</Form.Label>
                    <Form.Control type="text" name='contorl_receipt_no' onChange={handleInputChange} />
                  </Col>
                </Form.Group>
              </>
            ) : (keyType == 'LUC Out' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Date <span>*</span></Form.Label>
                    <Form.Control type="datetime-local" name='transfer_date' value={formData.transfer_date || ''} onChange={handleInputChange} />
                  </Col>

                  <Col sm="6" >
                    <Form.Label>Control Receipt Number</Form.Label>
                    <Form.Control type="text" name='control_receipt_no' value={formData.control_receipt_no || ''} onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >

                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Point <span>*</span></Form.Label>
                    {/* <Form.Control type="text" name='transfer_point' onChange={handleInputChange} /> */}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transfer_point: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Transfer Point"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.transfer_point)}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Transferred By</Form.Label>
                    <Form.Control type="text" name='transferred_by' value={formData.transferred_by || ''} onChange={handleInputChange} />
                  </Col>


                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Final Destination <span>*</span></Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, luc_final_dest: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Final Destination"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.luc_final_dest)}
                    />
                  </Col>
                  <Col sm="6">
                    <Form.Label>Received By <span>* </span> {formData.transfer_point ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" name='received_by' onChange={handleInputChange} /> */}
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, received_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent Name"
                      className='autoslct'
                      name="received_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>

              </>
            ) : (keyType == 'SCM' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Date of Check </Form.Label>
                    <Form.Control type="date" name='date_of_check' onChange={handleInputChange} value={formData.date_of_check} />
                  </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Destination </Form.Label>
                    {/* <Form.Control type="text" name='destination' onChange={handleInputChange} /> */}
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        console.log("🚀 ~ FormFuntion ~ selectedDestination:", selectedDestination)
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);
                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Station"
                      className='autoslct'
                      name="destination"
                      value={getDestination.find(option => option.value === formData.destination)}
                    />
                  </Col>
                  <Col sm="6">
                    <label className="form-label">Destination Handler Name {formData.destination ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</label>
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination_handler: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="destination_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>
              </>
            ) : (keyType == 'UCM In' ? (
              <>
                <Form.Group className='sl_status'>
                  <Form.Check
                    label="Flight"
                    name="flight_or_truck"
                    type='radio'
                    id='Flight'
                    value="F"
                    checked={formData.flight_or_truck === 'F'}
                    onChange={handleInputChange}
                  />

                  <Form.Check
                    label="Truck"
                    name="flight_or_truck"
                    type='radio'
                    id='Truck'
                    value="T"
                    checked={formData.flight_or_truck === 'T'}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Flight Number <span>*</span></Form.Label>
                    <div className='d-flex'>
                      <Select
                        options={getOwnerCodes}
                        onChange={(selected) => {
                          // const updatedData = { ...formData, flight: selected.value };
                          // setFormData(updatedData);
                          setFormData(prevFormData => {
                            const newFormData = { ...prevFormData, flight_code: selected.value };
                            const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                            keyData.Form = newFormData;
                            localStorage.setItem(keyType, JSON.stringify(keyData));

                            return newFormData;
                          });
                        }}
                        placeholder="Flight"
                        className='autoslct'
                        name="flight_code"
                        value={getOwnerCodes.find(option => option.value === formData.flight_code)}
                      />
                      {/* <Form.Select aria-label="Default" name="flight" value={formData.flight} onChange={handleInputChange}>
                            <option>Please Select</option>
                            {getOwnerCodes &&
                              getOwnerCodes.map((option, index) =>
                                <option key={index} value={option.IATA}>{option.IATA}</option>
                              )
                            }
                          </Form.Select> */}
                      <span>-</span>
                      <Form.Control
                        type="text" className='ms-2'
                        name='flight_number'
                        maxLength='8' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }}
                        value={formData.flight_number} onChange={handleInputChange}
                      />

                    </div>
                  </Col>

                  <Col sm="6" >
                    <Form.Label>Flight Date <span>*</span></Form.Label>
                    <Form.Control type="date" name="flight_date" value={formData.flight_date} onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Origin <span>*</span></Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, origin: selected.value, handler_name: '' };
                        // setFormData(updatedData);
                        setOriginHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedOriginId(selectedDestination.station_id);
                        setSelectedOriginHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, origin: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Origin"
                      className='autoslct'
                      name="origin"
                      value={getDestination.find(option => option.value === formData.origin)}
                    />
                  </Col>

                  <Col sm="6">
                    <label className="form-label">Origin Handler Name {formData.origin ? <button onClick={() => addressModalOpen('origin')} className='raad_adrss'>+ Add</button> : ''}</label>
                    {/* <input className="form-control" id="ex2" name="handler_name" type="text" value={formData.handler_name} onChange={handleInputChange} /> */}
                    <Select
                      options={originHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, origin_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedOriginHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, origin_handler: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="origin_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedOriginHandler ? { value: selectedOriginHandler.value, label: selectedOriginHandler.label } : null}
                      isDisabled={!originHandler.length}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Destination <span>*</span></Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);
                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Destination"
                      className='autoslct'
                      name="destination"
                      value={getDestination.find(option => option.value === formData.destination)}
                    />
                  </Col>

                  <Col sm="6">
                    <label className="form-label">Destination Handler Name {formData.destination ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</label>
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination_handler: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="destination_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Registration Number </Form.Label>
                    <Form.Control type="text" name="flight_reg_number" value={formData.flight_reg_number} onChange={handleInputChange} />
                  </Col>

                </Form.Group>
              </>
            ) : (keyType == 'UCM Out' ? (
              <>
                <Form.Group className='sl_status'>
                  <Form.Check
                    label="Flight"
                    name="flight_or_truck"
                    type='radio'
                    id='Flight'
                    value="F"
                    checked={formData.flight_or_truck == 'F'}
                    onChange={handleInputChange}
                  />

                  <Form.Check
                    label="Truck"
                    name="flight_or_truck"
                    type='radio'
                    id='Truck'
                    value="T"
                    checked={formData.flight_or_truck == 'T'}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Flight Number <span>*</span></Form.Label>
                    <div className='d-flex'>
                      <Select
                        options={getOwnerCodes}
                        onChange={(selected) => {
                          // const updatedData = { ...formData, flight: selected.value };
                          // setFormData(updatedData);
                          setFormData(prevFormData => {
                            const newFormData = { ...prevFormData, flight_code: selected.value };
                            const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                            keyData.Form = newFormData;
                            localStorage.setItem(keyType, JSON.stringify(keyData));

                            return newFormData;
                          });
                        }}
                        placeholder="Flight"
                        className='autoslct'
                        name="flight_code"
                        value={getOwnerCodes.find(option => option.value === formData.flight_code)}
                      />
                      {/* <Form.Select aria-label="Default" name="flight" value={formData.flight} onChange={handleInputChange}>
                            <option>Please Select</option>
                            {getOwnerCodes &&
                              getOwnerCodes.map((option, index) =>
                                <option key={index} value={option.IATA}>{option.IATA}</option>
                              )
                            }
                          </Form.Select> */}
                      <span>-</span>
                      <Form.Control
                        type="text" className='ms-2'
                        name='flight_number'
                        maxLength='8' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }}
                        value={formData.flight_number} onChange={handleInputChange}
                      />

                    </div>
                  </Col>

                  <Col sm="6" >
                    <Form.Label>Flight Date <span>*</span></Form.Label>
                    <Form.Control type="date" name="flight_date" value={formData.flight_date} onChange={handleInputChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Origin <span>*</span></Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, origin: selected.value, handler_name: '' };
                        // setFormData(updatedData);
                        setOriginHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedOriginId(selectedDestination.station_id);
                        setSelectedOriginHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, origin: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Origin"
                      className='autoslct'
                      name="origin"
                      value={getDestination.find(option => option.value === formData.origin)}
                    />
                  </Col>

                  <Col sm="6">
                    <label className="form-label">Origin Handler Name {formData.origin ? <button onClick={() => addressModalOpen('origin')} className='raad_adrss'>+ Add</button> : ''}</label>
                    {/* <input className="form-control" id="ex2" name="handler_name" type="text" value={formData.handler_name} onChange={handleInputChange} /> */}
                    <Select
                      options={originHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, origin_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedOriginHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, origin_handler: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="origin_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedOriginHandler ? { value: selectedOriginHandler.value, label: selectedOriginHandler.label } : null}
                      isDisabled={!originHandler.length}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Destination <span>*</span></Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);
                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Destination"
                      className='autoslct'
                      name="destination"
                      value={getDestination.find(option => option.value === formData.destination)}
                    />
                  </Col>

                  <Col sm="6">
                    <label className="form-label">Destination   Handler Name {formData.destination ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</label>
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, destination_handler: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Handler Name"
                      className='autoslct'
                      name="destination_handler"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Registration Number </Form.Label>
                    <Form.Control type="text" name="flight_reg_number" value={formData.flight_reg_number} onChange={handleInputChange} />
                  </Col>

                </Form.Group>

              </>
            ) : (keyType == 'Transfer' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Date </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      onChange={handleInputChange}
                      name='transfer_date'
                      value={formData.transfer_date}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Control Receipt Number </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleInputChange}
                      name='control_receipt_no'
                      value={formData.control_receipt_no}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Point </Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setOriginHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedOriginId(selectedDestination.station_id);
                        setSelectedOriginHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transfer_point: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Transfer Point"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.transfer_point)}
                    />
                    {/* <Select
                      options={getOrigin}
                      onChange={(selected) => {
                        const selectedDestination = getOrigin.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setOriginHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedOriginId(selectedDestination.station_id);
                        setSelectedOriginHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transfer_point: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Transfer Point"
                      className='autoslct'
                      name="transfer_point"
                      value={getOrigin.find(option => option.value === formData.transfer_point)}
                    /> */}
                  </Col>

                  <Col sm="6">
                    <Form.Label>Transferred By {formData.transfer_point ? <button onClick={() => addressModalOpen('origin')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" /> */}
                    <Select
                      options={originHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, origin_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedOriginHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transferred_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent Name"
                      className='autoslct'
                      name="transferred_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedOriginHandler ? { value: selectedOriginHandler.value, label: selectedOriginHandler.label } : null}
                      isDisabled={!originHandler.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Final Destination</Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, final_dest: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Final Destination"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.final_dest)}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Received By {formData.final_dest ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" /> */}
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, received_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent Name"
                      className='autoslct'
                      name="received_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>
              </>
            ) : (keyType == 'Request' ? (
              <>
                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Date </Form.Label>
                    <Form.Control type="datetime-local" />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Control Receipt Number </Form.Label>
                    <Form.Control type="text" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Transfer Point </Form.Label>
                    <Select
                      options={getOrigin}
                      onChange={(selected) => {
                        const selectedDestination = getOrigin.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setOriginHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedOriginId(selectedDestination.station_id);
                        setSelectedOriginHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transfer_point: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Transfer Point"
                      className='autoslct'
                      name="transfer_point"
                      value={getOrigin.find(option => option.value === formData.transfer_point)}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Transferred By {formData.transfer_point ? <button onClick={() => addressModalOpen('origin')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" /> */}
                    <Select
                      options={originHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, origin_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedOriginHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, transferred_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent Name"
                      className='autoslct'
                      name="transferred_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedOriginHandler ? { value: selectedOriginHandler.value, label: selectedOriginHandler.label } : null}
                      isDisabled={!originHandler.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" >
                  <Col sm="6" className='pe-0'>
                    <Form.Label>Final Destination</Form.Label>
                    <Select
                      options={getDestination}
                      onChange={(selected) => {
                        const selectedDestination = getDestination.find(option => option.value === selected.value);
                        // const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                        // setFormData(updatedData);

                        setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                        setSelectedDestinationId(selectedDestination.station_id);
                        setSelectedDestinationHandler(null);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, final_dest: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Final Destination"
                      className='autoslct'
                      name="transfer_point"
                      value={getDestination.find(option => option.value === formData.final_dest)}
                    />
                  </Col>

                  <Col sm="6">
                    <Form.Label>Received By {formData.final_dest ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</Form.Label>
                    {/* <Form.Control type="text" /> */}
                    <Select
                      options={destinationHandler.map(handler => ({
                        value: handler.id,
                        label: handler.company_name
                      }))}
                      onChange={(selected) => {
                        // const updatedData = { ...formData, destination_handler: selected.label };
                        // setFormData(updatedData);
                        setSelectedDestinationHandler(selected);
                        setFormData(prevFormData => {
                          const newFormData = { ...prevFormData, received_by: selected.value };
                          const keyData = JSON.parse(localStorage.getItem(keyType)) || { Form: {} };
                          keyData.Form = newFormData;
                          localStorage.setItem(keyType, JSON.stringify(keyData));

                          return newFormData;
                        });
                      }}
                      placeholder="Agent/Handler Name"
                      className='autoslct'
                      name="received_by"
                      // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                      value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                      isDisabled={!destinationHandler.length}
                    />
                  </Col>
                </Form.Group>
              </>
            ) : (
              <></>
            )))
            )))))))}
            <Form.Group className="mb-1" >
              <Form.Label>Remarks </Form.Label>
              <Form.Control as="textarea" name='remarks' onChange={handleInputChange} />
            </Form.Group>

            <Form.Group >
              <div className='d-flex align-items-center'>
                <div>
                  <Form.Label>Upload Documents/Images </Form.Label>
                  <div
                    className="drop_new"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <h3>Drag and drop files here</h3>
                    <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
                    <span>Maximum file size 4 MB</span>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>
                <div className='d-flex upimg_repair'>
                  {files.map((file, index) => (
                    <div key={index} className='img-thumbnail'>
                      {file.type.startsWith('image/') ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Preview"
                          style={{ maxWidth: '40px', maxHeight: '40px', }}
                        />
                      ) : (
                        <span className='d-flex'><img src={Docmnt} className='fileimg' />  </span>
                      )}
                      <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      <Modal show={addressModalShow} onHide={addressModalClose} centered className='addfrom'>
        <Modal.Header closeButton onClick={addressModalClose}>
          <Modal.Title>Add Address - {addressFor == 'origin' ? orgLabel : destLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form.Group className='mb-2'>
            <Form.Label>Company Name</Form.Label>
            <Form.Control type="text" onChange={handleAddressChange} name='company_name' placeholder="Company Name" />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Agent or Handler</Form.Label>
            <Form.Check
              inline
              label="Agent"
              name="agent_or_handler"
              type='radio'
              id='export'
              value='A'
              checked={inputAddress.agent_or_handler === 'A'}
              onChange={handleAddressChange}
              disabled={['UCM In', 'UCM Out'].includes(keyType) ? 'disabled' : ''}
            />

            <Form.Check
              inline
              label="Handler"
              value="H"
              name="agent_or_handler"
              type='radio'
              id='import'
              checked={inputAddress.agent_or_handler === 'H'}
              onChange={handleAddressChange}
              disabled={['LUC In', 'LUC Out', 'Transfer'].includes(keyType) ? 'disabled' : (['Request'].includes(keyType) && addressFor == 'origin' ? 'disabled' : '')}
            />
          </Form.Group>
          {inputAddress.agent_or_handler == 'A' &&
            <>
              <Form.Group className='mb-2'>
                <Form.Check
                  inline
                  label="External"
                  name="agent_type"
                  type='radio'
                  id='external'
                  value='E'
                  checked={inputAddress.agent_type === 'E'}
                  onChange={handleAddressChange}
                  disabled={['Transfer'].includes(keyType) || ['Request'].includes(keyType) && addressFor == 'destination' ? 'disabled' : ''}
                />

                <Form.Check
                  inline
                  label="Internal"
                  value="I"
                  name="agent_type"
                  type='radio'
                  id='internal'
                  checked={inputAddress.agent_type === 'I'}
                  onChange={handleAddressChange}
                  disabled={['Request'].includes(keyType) && addressFor == 'origin' ? 'disabled' : ''}
                />

              </Form.Group>
            </>
          }
          <Form.Group className='mb-2'>
            <Form.Label>Address </Form.Label>
            <Form.Control type="text" onChange={handleAddressChange} name='address' placeholder="Address" />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Email ID</Form.Label>
            <Form.Control type="text" onChange={handleAddressChange} name='email' placeholder="E-mail" />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Telephone No</Form.Label>
            <Form.Control type="number" onChange={handleAddressChange} name='phone_number' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} placeholder="Number" />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addAddress}  >
            Add Address
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FormFuntion