import React, { useState } from 'react';
import Header from "../../../../Componenets/Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FormFuntion from './inc/FormFuntion';
import Process from './inc/Process';
import Overview from './inc/Overview';
import Details from './inc/Details';
// import { useHistory, useLocation } from 'react-router-dom';

const ULDFunction = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const action = queryParameters.get("action");
    const uldids = queryParameters.get("ids");
    const backTab = queryParameters.get("tab");

    const [actionType, setActionType] = useState(action);
    const [uldNumbers, setUldNumbers] = useState([uldids]);
    const actionTypes = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
    let storedName = null;


    // Loop through the actionTypes array
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        // Check if the current key matches any of the action types
        if (actionTypes.includes(key)) {
            storedName = key;
            break;
        }
    }
    // console.log("🚀 ~ ULDFunction ~ storedName:", storedName)
    // const history = useHistory();
    // const location = useLocation();
    // if (action){
    //     setActionType(action);
    //     setUldNumbers(uldids);
    //     queryParameters.delete('action');
    //     history.push({
    //         pathname: location.pathname,
    //       });
    //     window.location.replace('/ULDControl/ULDFunction')
    // }
    const [uldData, setUldData] = useState([]);

    const handleUldAdded = (newUldData) => {
        setUldData(newUldData);
        console.log('100',newUldData)
    };

    return (
        <>
            <div className="">
                <Header />

                <div className='main-panel'>
                    <Link to='/ULDControl' className='stk_back'> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
                    {console.log('001', storedName)}
                    {['Transfer', 'Request'].includes(storedName) ? (
                        <>
                            <div className='charter_flight'>
                                <Row className='mt-2'>
                                    <Col sm="6">
                                        <FormFuntion type={actionType} ids={uldNumbers} />
                                        <Details type={actionType} ids={uldNumbers} uldData={uldData} />
                                    </Col>
                                    <Col sm="6"><Overview onUldAdded={handleUldAdded} /></Col>
                                </Row>
                            </div>
                        </>
                    ) : (
                        <>
                            <Row className='mt-2'>
                                <Col sm="8">
                                    <Row className='mb-3'>
                                        <Col sm='6'><FormFuntion type={actionType} ids={uldNumbers} /></Col>
                                        <Col sm='6'><Process type={actionType} ids={uldNumbers} /></Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12'><Details type={actionType} ids={uldNumbers} uldData={uldData} /></Col>
                                    </Row>
                                </Col>
                                <Col sm="4"><Overview /></Col>
                            </Row>
                        </>
                    )}

                </div>


            </div>
        </>
    )
}

export default ULDFunction;