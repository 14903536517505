import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { MultiSelect } from 'react-multi-select-component';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Autosuggest from 'react-autosuggest';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faEllipsis, faTrashCan, faPencil, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { STATIONGRID, TRUCKERADDEMAIL, TRUCKEREDITEMAIL, TRUCKERDELEMAIL, TRUCKERSAVERATE, TRUCKERRATELIST } from '../../../Utils/conts.js';

// const getLocalEmail = () => {
//     const lists = localStorage.getItem("truckerEmail");

//     if (lists) {
//       return JSON.parse(lists);
//     } else {
//       return [];
//     }
//   };

const TruckerDetails = ({ rowData, stationID }) => {

    console.log('025', rowData);
    const truckerParent = rowData.args.row.bounddata;
    console.log("🚀 ~ TruckerDetails ~ truckerParent:", truckerParent)
    const [show, setShow] = useState(false);
    const modalShow = () => setShow(true)
    const modalClose = () => { setShow(false); setValueOffpoint(''); setValue(''); };

    const [tempShow, setTempShow] = useState(true);
    const TempYes = () => setTempShow(true);
    const TempNo = () => setTempShow(false);

    // const getLocalData = () => {
    //     const storedData = localStorage.getItem("truckerData");
    //     return storedData ? JSON.parse(storedData) : [];

    // }
    // useEffect(() => {
    // setAddress(getLocalData());
    // setItems(getLocalEmail());
    // }, []);

    const [inputAddress, setInputAddress] = useState({
        HandlerName: '', Address1: '', Address2: '', PostalCode: '', Phone: '', Email: ''
    });
    const [address, setAddress] = useState([]);

    const [value, setValue] = useState('');
    const [suggestionsBp, setSuggestionsBp] = useState([]);
    const [suggestionsOffpoint, setSuggestionsOffpoint] = useState([]);
    const [fetchStation, setFetchStation] = useState([]);
    const [ratechart, setRateChart] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await withRFSToken.post(STATIONGRID, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        const activeInnerData = res.data.response_data.Active;
                        console.log("🚀 ~ activeInnerData:", activeInnerData)
                        const transformedData = activeInnerData ? activeInnerData.map(item => ({
                            id: item.id,
                            value: item.station_id,
                            label: item.station_code
                        })) : []; // For multiselect
                        console.log("🚀 ~ transformedData ~ transformedData:", transformedData)
                        setstations(transformedData); // For multiselect
                        setFetchStation(innerData.Active);
                        setSuggestionsBp(innerData.Active);
                        setSuggestionsOffpoint(innerData.Active);
                        return activeInnerData
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // Call the fetchData function to fetch data from the API
        fetchData();

    }, []);

    const [formData, setFormData] = useState({
        board_point: '', off_point: '', truck_load: 'F',
        temperature: '1', driver_number: '1', adr: '1'
    })
    const onChange = (event, { newValue }) => {
        console.log('567', newValue);
        setValue(newValue);
        const stnId = fetchStation.filter((stn) => stn.station_code == newValue);
        if (stnId.length > 0) {
            const bpId = stnId[0]
            console.log("🚀 ~ onChange ~ stnId:", bpId.id)
            setFormData({
                ...formData,
                board_point: bpId.id
            });
        }

    };
    const inputProps = {
        placeholder: 'Type a Stations',
        value,
        onChange: onChange,
    };

    // console.log('688', stationData)
    // data from station component
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        setSuggestionsBp(
            inputLength === 0
                ? []
                : fetchStation.filter(
                    (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue
                    //&& fruit.station_code !== (selectedOffpoint && selectedOffpoint.station_code)
                )
        );

        // setShowAddButton(inputLength > 0 && suggestionsBp.length === 0); commented 30.04.24
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsBp([]);
        // setSelectedOffpoint(null); // TO CLEAN FIRST FIELD

        // setShowAddButton(false);
    };

    const getSuggestionValue = (suggestion) => suggestion.station_code;

    const renderSuggestion = (suggestion) => <div>{suggestion.station_code}</div>;

    const [valueOffpoint, setValueOffpoint] = useState('');
    const [selectedOffpoint, setSelectedOffpoint] = useState(null);
    const onChangeOffpoint = (event, { newValue }) => {
        setValueOffpoint(newValue);
        const stnId = fetchStation.filter((stn) => stn.station_code == newValue);
        if (stnId.length > 0) {
            const bpId = stnId[0]
            setFormData({
                ...formData,
                off_point: bpId.id
            });
        }

    };
    const onSuggestionsFetchRequestedOffpoint = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        setSuggestionsOffpoint(
            inputLength === 0
                ? []
                : fetchStation.filter(
                    (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue
                    //&& fruit.station_code !== (bpaddress && bpaddress.station_code)
                )
                    .filter(suggestion => suggestion.station_code !== (selectedOffpoint && selectedOffpoint.station_code))
        );
        // setShowAddButtonOffpoint(inputLength > 0 && suggestionsBp.length === 0);
    };

    const onSuggestionsClearRequestedOffpoint = () => {
        setSuggestionsOffpoint([]);
        // setBpaddress(null);
        // setShowAddButtonOffpoint(false);
    };

    const getSuggestionValueOffpoint = (suggestion) => suggestion.station_code;

    const renderSuggestionOffpoint = (suggestion) => <div>{suggestion.station_code}</div>;

    const onSelectOffpoint = (offpoint) => {
        // console.log("🚀 ~ onSelectOffpoint ~ offpoint:", offpoint)
        // const addressOptions = offpoint.station_addresses.map(address => (
        //     `${address.handler_name} - ${address.address_1}`
        // ));
        // setSelectedOffpoint(addressOptions);
        // if (offpoint) { setSelectedOffpoint(offpoint.station_addresses); }

        setFormData({
            ...formData,
            off_point: offpoint.id
        });
    };

    useEffect(() => {
        reloadRates();
    }, []);

    const handleFormOnchange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
        console.log('onc9', formData);
    }
    const addRates = async () => {
        console.log('addrates', formData);
        try {
            const res = await withRFSToken.post(TRUCKERSAVERATE, formData);
            // .then(function (res) {
            // const innerData = res;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            if (res.data.response_code == 200) {
                reloadRates();
                setValueOffpoint('');
                setValue('');
                modalClose();
            }
            // });

        } catch (error) {
            console.error('Error sending request:', error);
        }

    }
    const reloadRates = async () => {
        try {
            const res = await withRFSToken.post(TRUCKERRATELIST, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ .then ~ 2002:", innerData)
                    setRateChart(innerData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleChange = (e) => {
        setInputAddress({ ...inputAddress, [e.target.name]: e.target.value });
    };


    // Email Add Section Start
    // const getLocalEmail = () => {
    //     const lists = localStorage.getItem("truckerEmail");
    //     return lists ? JSON.parse(lists) : [];
    // }
    const emailArray = JSON.parse(truckerParent.trucker_request_emails);
    const [emailShow, setEmailShow] = useState(false);
    const inputEmailShow = () => setEmailShow(true);
    const [inputData, setInputData] = useState("");
    const [items, setItems] = useState(emailArray);
    const [editEmail, setEditEmail] = useState('');
    const [toggleButton, setToggleButton] = useState(false);

    const AddEmail = async () => {
        if (inputData == '') {
            // Alert('Please input Email');
            console.log("Please input Email");
            document.getElementById('addEmail').classList.add('error');
            toast.error('Email address must not be blank');
            setEmailShow(true);
        }
        else if (inputData && toggleButton) {
            // if (index){
            const NewItemData = {
                // id: new Date().getTime().toString(),
                trucker_id: truckerParent.id,
                email: inputData,
                email_key: editEmail,
            }
            // }
            try {
                const res = await withRFSToken.post(TRUCKERADDEMAIL, NewItemData)
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        console.log("🚀 ~ .then ~ innerData:", innerData)
                        setItems(innerData);
                        // setExportGrid(innerData.export);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // setItems(items.map((elem) => {
            //     if(elem.id === editEmail){
            //         return {...elem, name: inputData}
            //     }
            //     return elem;
            // }));
            setInputData('');
            setEditEmail(null);
            setToggleButton(false);
            setEmailShow(false);
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const validateEmail = (email) => {
                return emailRegex.test(email);
            };
            if (!validateEmail(inputData)) {
                toast.error('Invalid email address');
                setEmailShow(true);
            } else {
                const NewItemData = {
                    // id: new Date().getTime().toString(),
                    trucker_id: truckerParent.id,
                    email: inputData
                }
                try {
                    const res = await withRFSToken.post(TRUCKERADDEMAIL, NewItemData)
                        .then(function (res) {
                            const innerData = res.data.response_data;
                            console.log("🚀 ~ .then ~ innerData:", innerData)
                            setItems(innerData);
                            // setExportGrid(innerData.export);
                        });

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                // setItems([...items, NewItemData]);
                setEmailShow(false);
            }

            // localStorage.setItem('TruckerEmail', JSON.stringify(NewItemData));
        }
        setInputData('');
        // setEmailShow(false);

    }
    const handleKeyPress = () => { document.getElementById('addEmail').classList.remove('error'); }
    const deleteEmail = async (index) => {
        const updatedItem = items.filter((curElem) => {
            return curElem.id !== index;
        });
        try {
            const res = await withRFSToken.post(TRUCKERDELEMAIL, { trucker_id: truckerParent.id, email_key: index })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ .then ~ innerData:", innerData)
                    setItems(innerData);
                    // setExportGrid(innerData.export);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // setItems(updatedItem);
    }
    const EditEmail = (index) => {
        // const email_toedit = items.find((elem) => {
        //     return elem.id == index;
        // });
        setEmailShow(true);
        setToggleButton(true);
        setInputData(items[index]);
        setEditEmail(index);
    }
    // console.log("🚀 ~ EditEmail ~ setEditEmail:", editEmail)
    // useEffect(() => {
    //     localStorage.setItem('TruckerEmail', JSON.stringify(items));
    // }, [items])

    // +++++++++++++++++++ div +++++++++++++++++++++++++

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider


    const [imgShow, setImgShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setImgShow(true);
    };

    const handleClose = () => {
        setImgShow(false);
        setSelectedFile('');
    };

    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };


    // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload
    const [stations, setstations] = useState([]);
    let [selected, setSelected] = useState([]);
    let [selectedop, setSelectedop] = useState([]);
    if (selected.length > 1) {
        selected = selected.slice(1);
    }
    if (selectedop.length > 1) {
        selectedop = selectedop.slice(1);
    }
    const handleBPSelectChange = async (selected) => {
        const lastSelected = selected.slice(-1);
        setSelected(lastSelected);
        // setSelected(selected);
        if (selected && selectedop) {
            console.log('111', selected);
            try {
                const res = await withRFSToken.post(TRUCKERRATELIST, { boardpoint: selected, offpoint: selectedop })
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        setRateChart(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (selected && !selectedop) {
            console.log('222', selected);
            try {
                const res = await withRFSToken.post(TRUCKERRATELIST, { boardpoint: selected })
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        setRateChart(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {

        }

    };
    const handleOPSelectChange = async (selected) => {
        setSelectedop(selected)
        if (selectedop && selected) {
            try {
                const res = await withRFSToken.post(TRUCKERRATELIST, { boardpoint: selected, offpoint: selectedop })
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        setRateChart(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (selectedop && !selected) {
            try {
                const res = await withRFSToken.post(TRUCKERRATELIST, { offpoint: selected })
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        setRateChart(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {

        }
    };

    return (
        <>

            <Row className='mt-3'>
                <Col lg="5" className='pt-4  brd_rgt' >
                    <h4>Trucker Details</h4>
                    <p>Add truckers and manage truckers.</p>

                    <div className='truck_info'>
                        <h6>Trucker Information</h6>
                        <ul>
                            <li>Trucker Name : <span>{truckerParent.trucker_name}</span></li>
                            <li>Trucker Email : <span>{truckerParent.trucker_email}</span></li>
                            <li>Phone No. :<span>{truckerParent.trucker_phone}</span></li>
                        </ul>
                    </div>
                    <div className='d-flex justify-content-between py-2'>
                        <h6>Request Email Address</h6>
                        <div className='align-self-center'>
                            <button onClick={inputEmailShow} className='add_adres'>+ Add Email</button>
                        </div>

                    </div>
                    <div className='addresses'>
                        {emailShow &&
                            <>
                                <div className='add_email'>
                                    <input name='' value={inputData} onKeyDown={handleKeyPress} onChange={(e) => setInputData(e.target.value)} id='addEmail' type='text' placeholder='Email type here' />
                                    {toggleButton ? <button onClick={AddEmail}>Edit</button> : <button onClick={AddEmail}><FontAwesomeIcon icon={faPlus} /> Add</button>}
                                </div>
                            </>
                        }
                        <div className='showItem'>
                            {items && items.map((elem, index) => {
                                return (
                                    <div className='eachItem' key={index}>
                                        <h3><FontAwesomeIcon icon={faEnvelope} /> {elem}</h3>
                                        {/* <button>Edit</button> */}
                                        <div className='request_email'>
                                            <button onClick={() => EditEmail(index)}> <FontAwesomeIcon icon={faPencil} /> </button>
                                            <button onClick={() => deleteEmail(index)}> <FontAwesomeIcon icon={faTrashCan} /> </button>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='my-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className='mb-0'>Attachments</h3>
                                <div className='d-flex rightdrop'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                                            <FontAwesomeIcon icon={faEllipsis} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Open gallery view</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Download all</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                    >
                                        <button className='raplas' onClick={handleBrowseFiles}><FontAwesomeIcon icon={faPlus} /></button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            multiple
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className='raslider'>
                                <div className="slider-container">
                                    {
                                        files.length > 2 ?
                                            <Slider {...settings}>
                                                {files.map((file, index) => (
                                                    <div key={index} className='slideimg'>
                                                        {file.type.startsWith('image/') ? (
                                                            <>
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt="Preview"
                                                                    style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                    onClick={() => handleFileClick(URL.createObjectURL(file))}
                                                                />
                                                                <span className='rafilename'>{file.name}</span>
                                                            </>
                                                        ) : (
                                                            <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                                        )}
                                                        <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                                                    </div>
                                                ))}
                                            </Slider>
                                            :
                                            <>
                                                <div className='nonslide'>
                                                    {files.map((file, index) => (
                                                        <div key={index} className='slideimg'>
                                                            {file.type.startsWith('image/') ? (
                                                                <>
                                                                    <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt="Preview"
                                                                        style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                        onClick={() => handleFileClick(URL.createObjectURL(file))}
                                                                    />
                                                                    <span className='rafilename'>{file.name}</span>
                                                                </>
                                                            ) : (
                                                                <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                                            )}
                                                            <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                    }


                                </div>

                                <Modal show={imgShow} onHide={handleClose} centered className='imgmodal'>
                                    <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                                    <Modal.Body >
                                        {console.log(selectedFile)}
                                        <img src={selectedFile} className="img-fluid" alt="Selected" />
                                    </Modal.Body>
                                </Modal>

                            </div>

                        </div>

                    </div>

                </Col>
                <Col className='pt-4'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h4>Fixed Rates</h4>
                            <p>Add truckers and manage truckers.</p>
                        </div>
                        <div className='align-self-center'>
                            <button onClick={modalShow} className='add_adres'>+ Add Rates</button>
                        </div>

                    </div>
                    <div >
                        <Form.Group className='d-flex mb-3 rate_search' controlId="exampleForm.ControlInput1">
                            <div className='d-flex align-items-center'>
                                <Form.Label inline>Boardpoint</Form.Label>
                                <MultiSelect
                                    inline
                                    options={stations}
                                    isCreatable={true}
                                    hasSelectAll={false}
                                    showCheckbox={false}
                                    value={selected}
                                    selected={selected}
                                    name='boardpoint'
                                    onChange={handleBPSelectChange}
                                />
                            </div>

                            <div className='d-flex align-items-center'>
                                <Form.Label inline>Offpoint</Form.Label>
                                <MultiSelect
                                    inline
                                    options={stations}
                                    isCreatable={true}
                                    hasSelectAll={false}
                                    showCheckbox={false}
                                    value={selectedop}
                                    selected={selectedop}
                                    name='offpoint'
                                    onChange={handleOPSelectChange}
                                />

                            </div>

                        </Form.Group>

                    </div>

                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Boardpoint</th>
                                    <th>Offpoint</th>
                                    <th>Truck Load</th>
                                    <th>Temperature</th>
                                    <th>Drivers</th>
                                    <th>price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ratechart && ratechart.map(rates => (
                                    <tr>
                                        <td>{rates.boardpoint}</td>
                                        <td>{rates.offpoint}</td>
                                        <td>{rates.truck_load}</td>
                                        <td>{rates.temperature}</td>
                                        <td>{rates.drivers}</td>
                                        <td>${rates.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                </Col>
            </Row>


            <Modal show={show} onHide={modalClose} centered className='addfrom'>
                <Modal.Header closeButton onClick={modalClose}>
                    <Modal.Title>Add Rates</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form.Group as={Row} className='mb-2'>
                        <Col sm="6">
                            <Form.Label>Boardpoint</Form.Label>
                            <div className='autosugg'>
                                <Autosuggest
                                    suggestions={suggestionsBp}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                // onSuggestionSelected={(event, { suggestion }) => onSelectBpaddress(suggestion)}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <Form.Label>Offpoint</Form.Label>
                            <div className='autosugg'>
                                <Autosuggest
                                    suggestions={suggestionsOffpoint}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequestedOffpoint}
                                    onSuggestionsClearRequested={onSuggestionsClearRequestedOffpoint}
                                    getSuggestionValue={getSuggestionValueOffpoint}
                                    renderSuggestion={renderSuggestionOffpoint}
                                    inputProps={{ placeholder: 'Type a Stations', value: valueOffpoint, onChange: onChangeOffpoint }}
                                    onSuggestionSelected={(event, { suggestion }) => onSelectOffpoint(suggestion)}
                                />
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Truck Load</Form.Label>
                        <Row>
                            <Col sm="3">
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    id='truck_load'
                                    name='truck_load'
                                    label='FLT'
                                    value='F'
                                    checked={formData.truck_load === 'F'}
                                    onChange={handleFormOnchange}
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    id='truck_load1'
                                    name='truck_load'
                                    label='LTL'
                                    value='L'
                                    checked={formData.truck_load === 'L'}
                                    onChange={handleFormOnchange}
                                />
                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Temperature Controlled</Form.Label>
                        <Row>
                            <Col sm="3">
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    id='temperature'
                                    name='temperature'
                                    label='Yes'
                                    value='1'
                                    checked={formData.temperature === '1'}
                                    onChange={handleFormOnchange}
                                    onClick={TempYes}
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    id='temperature1'
                                    name='temperature'
                                    label='NO'
                                    value='0'
                                    checked={formData.temperature === '0'}
                                    onChange={handleFormOnchange}
                                    onClick={TempNo}
                                />
                            </Col>
                            <Col sm="3">{tempShow ? <div className='tempra'>18 - 21</div> : <div>NA</div>}</Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="6">
                                <Form.Label style={{ display: 'block' }}>Number of Drivers</Form.Label>
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    inline
                                    id='drivercount'
                                    name='driver_number'
                                    label='1'
                                    value='1'
                                    checked={formData.driver_number === '1'}
                                    onChange={handleFormOnchange}
                                />
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    inline
                                    id='drivercount1'
                                    name='driver_number'
                                    label='2'
                                    value='2'
                                    checked={formData.driver_number === '2'}
                                    onChange={handleFormOnchange}
                                />
                            </Col>
                            <Col sm="6">
                                <Form.Label style={{ display: 'block' }}>ADR Required</Form.Label>
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    inline
                                    id='adrreq'
                                    name='adr'
                                    label='Yes'
                                    value='1'
                                    checked={formData.adr === '1'}
                                    onChange={handleFormOnchange}
                                />
                                <Form.Check // prettier-ignore
                                    type='radio'
                                    inline
                                    id='adrreq1'
                                    name='adr'
                                    label='Yes'
                                    value='0'
                                    checked={formData.adr === '0'}
                                    onChange={handleFormOnchange}
                                />
                            </Col>
                        </Row>

                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addRates}  >
                        Add Rates
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TruckerDetails;