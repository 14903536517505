import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Cuser from "../../assets/images/cuser.png";
import User from "../../assets/images/user.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Link } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { COMMENTSAVE, SAVEATTACHMENTS, COMMENTDELETE, DELETEATTACHMENTS, COMMENTEDIT, IMAGETOBASE64 } from '../../Utils/conts.js';
import { withoutRFSToken, withRFSToken } from '../../Services/axios.js';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { forEach } from 'lodash';
const ActivityComments = ({ commentable_type, commentable_id, allactivities, onReloadDetails }) => {
    const [editorData, setEditorData] = useState('');
    const [visibleCount, setVisibleCount] = useState(2);
    const [filter, setFilter] = useState('comments');
    const [activities, setActivities] = useState([]);
    const [commentEditId, setCommentEditId] = useState();
    const [editedText, seteditedText] = useState();

    useEffect(() => {
        if (allactivities) {
            setActivities(allactivities.comments);
        }
    }, [allactivities]);
    const handleCancel = async () => {
        setEditorData('');
        setCommentEditId('');
    }
    const handleSave = async () => {
        var imageSources = [];
        if (editorData) {
            imageSources = extractImageSources(editorData);
        }
        let allImages = await convertImageUrlsToBase64(imageSources);

        if (commentEditId) {
            var imageIds = extractImageIds(editedText);
            var allData = {
                "attachment_ids": imageIds
            }
            const resimageIds = withRFSToken.post(DELETEATTACHMENTS, allData);
        }
        let resData = [];
        if (allImages.length > 0) {
            let imageAllData = {
                "attachable_id": commentable_id,
                "attachable_type": commentable_type,
                "files": allImages,
                "is_comment": 1
            }
            resData = await withRFSToken.post(SAVEATTACHMENTS, imageAllData);
        }
        if (resData.length == 0 || (resData && resData.data && resData.data.response_code == 200)) {
            let updatedEditorData = [];
            if (resData.length == 0) {
                updatedEditorData = editorData;
            } else {
                updatedEditorData = replaceBase64WithUrls(editorData, imageSources, resData.data.response_data);
            }
            var commentAllData = [];
            let res = [];
            if (commentEditId) {
                commentAllData = {
                    "comment_id": commentEditId,
                    "message": updatedEditorData,
                }
                res = await withRFSToken.post(COMMENTEDIT, commentAllData)
            } else {
                commentAllData = {
                    "commentable_id": commentable_id,
                    "commentable_type": commentable_type,
                    "message": updatedEditorData,
                }
                res = await withRFSToken.post(COMMENTSAVE, commentAllData)
            }

            if (res.data.response_code == 200) {
                setActivities(res.data.response_data);
                toast.success('Posted Successfully');
                setEditorData('');
                onReloadDetails(commentable_id);
            } else {
                toast.error(res.data.response_msg);
            }
        } else {
            toast.error(resData.data.response_msg);
        }
    };

    const extractImageSources = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgTags = doc.querySelectorAll('img');
        const srcArray = Array.from(imgTags).map(img => img.src);
        return srcArray;
    };

    const replaceBase64WithUrls = (html, base64Sources, serverResponseData) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgTags = doc.querySelectorAll('img');

        imgTags.forEach((img, index) => {
            if (base64Sources.includes(img.src) && serverResponseData[index]) {
                const serverUrl = serverResponseData[index].file_path;
                const imageId = serverResponseData[index].id;

                // Remove all attributes except src and imageId
                const src = img.src;
                while (img.attributes.length > 0) {
                    img.removeAttribute(img.attributes[0].name);
                }
                img.src = serverUrl;
                img.setAttribute('imageId', imageId);
            }
        });

        return new XMLSerializer().serializeToString(doc);
    };

    const handleActivityTypeClick = (type) => {
        setFilter(type);
        if (type == 'all') {
            const comments = allactivities.comments;
            const history = allactivities.history;
            const merged = comments.concat(history);
            merged.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            console.log(merged);
            setActivities(merged);
        } else if (type == 'comments') {
            setActivities(allactivities.comments);
        } else if (type == 'history') {
            setActivities(allactivities.history);
        }
    };

    const handleCommentEdit = (commentId) => {
        // let commentHtml = "";
        // activities.forEach(element => {
        //     if (element.comment_id == commentId) {
        //         commentHtml = element.message;
        //     }
        // });
        const commentToEdit = activities.find(element => element.comment_id === commentId);
        if (!commentToEdit) {
            console.error("Comment not found");
            return;
        }
        const commentHtml = commentToEdit.message;
        seteditedText(commentHtml);
        setEditorData(commentHtml);
        setCommentEditId(commentId);
    };

    const handleCommentDelete = async (commentId) => {
        const commentToDelete = activities.find(activity => activity.comment_id === commentId); // Added by mtinku
        const imageIds = commentToDelete ? extractImageIds(commentToDelete.message) : [];
        var allData = {
            "attachment_ids": imageIds
        }
        // const resimageIds = withRFSToken.post(DELETEATTACHMENTS, allData);
        await withRFSToken.post(DELETEATTACHMENTS, allData); // Added by mtinku

        var commentAllData = {
            "comment_id": commentId,
        }
        const res = await withRFSToken.post(COMMENTDELETE, commentAllData)
        if (res.data.response_code == 200) {
            toast.success('Comment Deleted Successfully');
            onReloadDetails(commentable_id); // Added by mtinku
        } else {
            toast.error(res.data.response_msg);
        }
        // setActivities(activities => activities.filter(comment => comment.comment_id !== commentId));
        setActivities(prevActivities => {
            const updatedActivities = prevActivities.filter(comment => comment.comment_id !== commentId);
            console.log("New activities state:", updatedActivities);
            return updatedActivities;
        });
    };

    const extractImageIds = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgTags = doc.querySelectorAll('img');
        const imageIds = Array.from(imgTags).map(img => img.getAttribute('imageid'));
        return imageIds.filter(id => id !== null); // Filter out any null values if imageid attribute is missing
    };

    const handleShowMore = () => {
        setVisibleCount(visibleCount + 2);
    };

    const uploadAdapter = (loader) => {
        return {
            upload: async () => {
                const file = await loader.file;
                const base64 = await getBase64(file);
                return {
                    default: base64,
                };
            },
        };
    };

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    // async function convertImageUrlsToBase64(images) {
    //     // Function to check if a string is a URL
    //     const isUrl = (string) => {
    //         try {
    //             new URL(string);
    //             return true;
    //         } catch (e) {
    //             return false;
    //         }
    //     };

    //     // Function to fetch an image and convert it to base64
    //     const toBase64 = async (url) => {
    //         const response = await fetch(url);
    //         const blob = await response.blob();
    //         return new Promise((resolve, reject) => {
    //             const reader = new FileReader();
    //             reader.onloadend = () => resolve(reader.result);
    //             reader.onerror = reject;
    //             reader.readAsDataURL(blob);
    //         });
    //     };

    //     // Iterate through the images array and convert URLs to base64
    //     const results = await Promise.all(images.map(async (image) => {
    //         if (isUrl(image)) {
    //             return await toBase64(image);
    //         } else {
    //             return image; // It's already a base64 string
    //         }
    //     }));

    //     return results;
    // }

    async function convertImageUrlsToBase64(images) {
        var allImages = [];
        const promises = images.map(async (element) => {
            try {
                const res = await withRFSToken.post(IMAGETOBASE64 + element);
                allImages.push(res.data); // Assuming the response has a data property containing the Base64 string
            } catch (error) {
                allImages.push(element);
            }
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        return allImages;
    }
    return (
        <>
            <div>
                <h3>Activity</h3>

                <div className='activity'>
                    <span>Show :</span>
                    <Button variant={filter === 'all' ? 'link active' : 'link'} onClick={() => handleActivityTypeClick('all')}>All</Button>
                    <Button variant={filter === 'comments' ? 'link active' : 'link'} onClick={() => handleActivityTypeClick('comments')}>Comments</Button>
                    <Button variant={filter === 'history' ? 'link active' : 'link'} onClick={() => handleActivityTypeClick('history')}>History</Button>
                </div>
                {commentable_type !== 'uld' ?
                    <div className='activity'>
                        <span>Truck :</span>
                        <Button variant="link">All</Button>
                        <Button variant="link">#123</Button>
                        <Button variant="link">#124</Button>
                        <Button variant="link">#125</Button>
                        <Button variant="link">#126</Button>
                    </div>
                    : ''
                }

            </div>
            <div className='mt-4'>
                {filter === 'comments' ?
                    <>
                        <div className='mb-3'>
                            <div className='d-flex comntbox'>
                                <div>
                                    <span className='user_com'>RS</span>
                                </div>
                                <div className='edithtml'>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        // data="<p> Hello </p>"
                                        data={editorData}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            console.log(event);
                                            const data = editor.getData();
                                            setEditorData(data);
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                            // editor.ui.view.toolbar.element.style.display = 'none';
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                            // editor.ui.view.toolbar.element.style.display = 'block';
                                        }}
                                        // config={{
                                        //     ckfinder:{uploadUrl: "/uplaodImageFromEditor"}
                                        // }}
                                        config={{
                                            extraPlugins: [uploadPlugin],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='commnt_save'>
                                <Button variant="primary rasave" onClick={handleSave}>Save</Button>
                                <Button variant="link racancel" onClick={handleCancel}>Cancel</Button>
                            </div>
                        </div>
                    </>
                    : <></>}
                {(activities && activities?.slice(0, visibleCount)).map((activity, index) => (
                    <div key={index} className='d-flex racomment'>
                        {activity.type_of_action === "COMMENT" ? (
                            <>
                                <div className='coment_img'><img src={User} alt="User" /></div>
                                <div>
                                    <div className='d-flex'>
                                        <h3>{activity.created_by}</h3>
                                        <span>{activity.created_at}</span>
                                    </div>
                                    {parse(activity.message)}

                                    {activity.is_editable == 1 ?
                                        <div className='imgedit'>
                                            <Link to={'#'} onClick={() => handleCommentEdit(activity.comment_id)}>Edit</Link>
                                            <Link to={'#'} onClick={() => handleCommentDelete(activity.comment_id)}>Delete</Link>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>


                            </>
                        ) : (
                            <>
                                <div className='coment_img'><img src={User} alt="User" /></div>
                                <div>
                                    <div className='d-flex'>
                                        <h3>{activity.created_by}</h3>
                                        <span>{activity.action} {activity.field_of_action} {activity.created_at}</span>
                                    </div>

                                </div>
                            </>
                        )}
                    </div>
                ))}
                {activities?.length > visibleCount && (
                    <p className='text-end'>
                        <a href='javascript:void(0)' className='text-primary' onClick={handleShowMore} style={{ fontSize: "12px" }}><u>Show more</u></a>
                    </p>
                )}
            </div>
        </>
    )
}

export default ActivityComments