import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const LoadingPage = () => {

  // token related code | start
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token");
  const localStorageData = localStorage.getItem('token');
  console.log("token", token);

  // const queryParametersRFS = new URLSearchParams(window.location.search)
  const tokenRFS = queryParameters.get("rfstoken");
  const localStorageDataRFS = localStorage.getItem('RFS');

  const tokenULD = queryParameters.get("uldtoken");
  const localStorageDataULD = localStorage.getItem('ULD');

  const history = useHistory();
  const location = useLocation();
  // alert("14");
  if (token != "" && token != null) {
    // alert("16");
    localStorage.setItem('token', token)
    queryParameters.delete('token');
    localStorage.removeItem('RFS')
    localStorage.removeItem('ULD')
    history.push({
      pathname: location.pathname,
    });
    window.location.replace(process.env.REACT_APP_PLANDASHBOARD_URL) // Redirect to planDashboard
    // navigate("/planDashboard");
    // return <Redirect to='/planDashboard'/>;
  } else if (localStorageData != "" && localStorageData != null) {
    window.location.replace(process.env.REACT_APP_PLANDASHBOARD_URL) // Redirect to planDashboard
  } else if (tokenRFS != "" && tokenRFS != null) {
    localStorage.setItem('RFS', tokenRFS)
    queryParameters.delete('rfstoken');
    localStorage.removeItem('token');
    localStorage.removeItem('ULD');
    history.push({
      pathname: location.pathname,
    });
    console.log('***', location.pathname);
    window.location.replace(process.env.REACT_APP_BOOKINGTRUCK_URL) // redirect to RFS dashboard
  } else if (tokenULD != "" && tokenULD != null) {
    localStorage.setItem('ULD', tokenULD)
    queryParameters.delete('uldtoken');
    localStorage.removeItem('token');
    localStorage.removeItem('RFS');
    history.push({
      pathname: location.pathname,
    });
    console.log('***', location.pathname);
    window.location.replace(process.env.REACT_APP_ULD_URL) // redirect to RFS dashboard
  }
  else if (localStorageDataRFS != "" && localStorageDataRFS != null) {
    window.location.replace(process.env.REACT_APP_BOOKINGTRUCK_URL) // redirect to RFS dashboard
  }else if (localStorageDataULD != "" && localStorageDataULD != null) {
    window.location.replace(process.env.REACT_APP_ULD_URL) // redirect to RFS dashboard
  } else {
    const baseUrl = new URL(process.env.REACT_APP_MAIN_URL);
    const currentUrl = new URL(window.location.href);
    if (currentUrl.pathname === baseUrl.pathname) {
      window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL); // Redirect to base URL
    } else {
      window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL + currentUrl.pathname); // Redirect to base URL with current path
    }
    window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL) // Back to loginpage
  }
}

export default LoadingPage;
