import React, { useState, useEffect, useRef } from 'react';
import Header from "../../../../Componenets/Header/Header";
import { MultiSelect } from 'react-multi-select-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import Fullsize from "../../../../assets/images/full-size.svg";
import Trash from "../../../../assets/images/trash.png";
import { faFileExport, faPlus, faGear, faSquare } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';

import { withoutRFSToken, withRFSToken } from '../../../../Services/axios.js';
import { GETTYPECODE, GETDESTINATION, GETOWNERCODE, GETCONDITIONCODE, MSGPROCESSCHARTER, SAVEULDCHARTER, ULDADDRESSSAVE, ULDEXIST } from '../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const NewCharter = () => {

  const isMounted = useRef(false);
  const [getTypeCodes, setGetTypeCodes] = useState([]);
  const [getDestination, setGetDestination] = useState([]);
  const [getOwnerCodes, setGetOwnerCode] = useState([]);
  const [getCondCodes, setGetCondCode] = useState([]);
  const [originHandler, setOriginHandler] = useState([]);
  const [selectedOriginId, setSelectedOriginId] = useState(null);
  const [selectedOriginHandler, setSelectedOriginHandler] = useState(null);
  const [destinationHandler, setDestinationHandler] = useState([]);
  const [selectedDestinationId, setSelectedDestinationId] = useState(null);
  const [selectedDestinationHandler, setSelectedDestinationHandler] = useState(null);
  const [addressModalShow, setAddressModalShow] = useState(false);
  const addressModalClose = () => {
    setAddressModalShow(false);
    setInputAddress({ id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: '' })
  };
  const [inputAddress, setInputAddress] = useState({
    id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
  });
  const [address, setAddress] = useState([]);

  const fetchData = async () => {
    try {
      const res = await withRFSToken.post(GETTYPECODE, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const transformedData = innerData ? innerData.map(item => ({
            value: item.uld_typecode,
            label: item.uld_typecode
          })) : [];
          setGetTypeCodes(transformedData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDestination = async () => {
    try {

      const res = await withRFSToken.post(GETDESTINATION, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const transformedData = innerData ? innerData.map(item => ({
            value: item.port_code,
            label: item.port_code,
            address: item.address,
            station_id: item.station_id
          })) : [];
          setGetDestination(transformedData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchOwnerCode = async () => {
    try {

      const res = await withRFSToken.post(GETOWNERCODE, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const transformedData = innerData ? innerData.map(item => ({
            value: item.IATA,
            label: item.IATA
          })) : [];
          setGetOwnerCode(transformedData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchCondCode = async () => {
    try {

      const res = await withRFSToken.post(GETCONDITIONCODE, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          setGetCondCode(innerData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (isMounted.current) return;
    // Call the fetchData function to fetch data from the API
    fetchData();
    fetchDestination();
    fetchOwnerCode();
    fetchCondCode();
    isMounted.current = true;
  }, []);

  const [formData, setFormData] = useState(
    {
      id: '1',
      charter_type: 'F', status: '4', flight: '', flight_number: '', flight_date: '', origin: '', handler_origin: '',
      destination: '', handler_destination: '', flight_reg_number: '', remarks: '', documents: [],
      uldInfo: [
        { id: '1', uld_number: '', type_code: '', reg: '', register: '', serial_number: '', owner_code: '', condition_code: 'SER', content_code: '', asset_type: 'L', }
      ]
    }
  );
  const [assetTypeDisabled, setAssetTypeDisabled] = useState(false)
  const addULDStack = () => {
    const lastId = formData.uldInfo.length > 0 ? parseInt(formData.uldInfo[formData.uldInfo.length - 1].id) : 0;
    const newId = (lastId + 1).toString();
    setFormData(prevFormData => ({
      ...prevFormData,
      uldInfo: [
        ...prevFormData.uldInfo,
        {
          // id: (prevFormData.uldStack.length + 1).toString(),
          id: newId,
          uld_number: '',
          type_code: '',
          serial_number: '',
          owner_code: '',
          reg: '',
          register: '',
          condition_code: 'SER',
          content_code: '',
          asset_type: true
        }
      ]
    }));
    // if (assetTypeDisabled) {
    //   setAssetTypeDisabled(prevDisabledRows => [...prevDisabledRows, false]);
    // }
  };
  const removeULDStack = (uldInfoId) => {
    setFormData(prevFormData => {
      // return {
      //   ...prevFormData,
      //   uldInfo: prevFormData.uldInfo.filter(stack => stack.id !== uldInfoId)
      // };
      const newUldInfo = prevFormData.uldInfo.filter(stack => stack.id !== uldInfoId);
      // if (assetTypeDisabled) {
      //   const updatedDisabledRows = assetTypeDisabled.filter((_, index) => prevFormData.uldInfo[index].id !== uldInfoId);
      //   setAssetTypeDisabled(updatedDisabledRows);
      // }

      return { ...prevFormData, uldInfo: newUldInfo };
    });
  };
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };
  // const handleULDInputChange = (uldInfoId, fieldName, value) => {
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     uldInfo: prevFormData.uldInfo.map(stack => {
  //       if (stack.id === uldInfoId) {
  //         const updatedStack = { ...stack, [fieldName]: value };
  //         // updatedStack.uld_number = `${updatedStack.type_code || ''}${updatedStack.serial_number || ''}${updatedStack.owner_code || ''}`;
  //         const { type_code, serial_number, owner_code } = updatedStack;
  //         if (type_code && serial_number && owner_code) {
  //           updatedStack.uld_number = `${type_code}${serial_number}${owner_code}`;

  //           // Call the API to check if the ULD number is registered
  //           checkULDNumberRegistration(updatedStack.uld_number, uldInfoId);
  //         }
  //         return updatedStack;
  //       }
  //       return stack;
  //     })
  //   }));
  // };
  const handleULDInputChange = (uldInfoId, fieldName, value) => {
    const updatedUldInfo = formData.uldInfo.map(stack => {
      if (stack.id === uldInfoId) {
        const updatedStack = { ...stack, [fieldName]: value };
        const { type_code, serial_number, owner_code } = updatedStack;
        if (type_code && serial_number && owner_code) {
          updatedStack.uld_number = `${type_code}${serial_number}${owner_code}`;
        } else {
          updatedStack.uld_number = '';
        }
        return updatedStack;
      }
      return stack;
    });
    setFormData({ ...formData, uldInfo: updatedUldInfo });
    const changedStack = updatedUldInfo.find(stack => stack.id === uldInfoId);
    if(fieldName!="condition_code" && fieldName!="asset_type"){
        if (changedStack.type_code && changedStack.serial_number && changedStack.owner_code) {
          checkULDNumberRegistration(changedStack.uld_number, uldInfoId);
        }
    }
  };

  const checkULDNumberRegistration = async (uldNumber, uldInfoId) => {

    try {
      const res = await withRFSToken.post(ULDEXIST, { uld_number: uldNumber });
      if (res.data.response_code == 200) {
        const innerData = res.data.response_data;
        const isRegistered = innerData.reg;

        // const updateData = formData.uldInfo.map(stack => {
        //   if (stack.id === uldInfoId) {
        //     return { ...stack, reg: innerData.reg, asset_type: innerData.asset_type == 'N' ? true : false};
        //   }
        //   return stack;
        // });  
        setFormData(prevFormData => ({
          ...prevFormData,
          uldInfo: prevFormData.uldInfo.map(stack => {
            if (stack.id === uldInfoId) {
              return { ...stack, reg: innerData.reg, register: innerData.register, asset_type: innerData.asset_type == 'N' ? true : false };
            }
            return stack;
          })
        }));
        const initialDisabledRows = isRegistered == 'R' ? true : false;
        console.log("🚀 ~ checkULDNumberRegistration ~ initialDisabledRows:", initialDisabledRows)
        setAssetTypeDisabled(initialDisabledRows);
      }
      // Update the 'reg' field in the specific uldInfo entry

    } catch (error) {
      console.error('Error checking ULD number registration:', error);
      // Handle the error appropriately, e.g., show a notification
    }
  };
  const validateFormData = () => {
    let isValid = true;

    if (!formData.flight || !formData.flight_number) {
      toast.error('Please set Flight Number');
      isValid = false;
    }
    else if (!formData.flight_date) {
      toast.error('Please select Flight Date');
      isValid = false;
    }
    else if (!formData.origin) {
      toast.error('Please select Origin');
      isValid = false;
    }
    else if (!formData.destination) {
      toast.error('Please select Destination');
      isValid = false;
    }
    // Validate uldInfo array
    else {
      for (let i = 0; i < formData.uldInfo.length; i++) {
        const uld = formData.uldInfo[i];
        if (!uld.type_code) {
          toast.error('Please set Type Code');
          isValid = false;
          break;
        } else if (!uld.serial_number) {
          toast.error('Please set Serial Number');
          isValid = false;
          break;
        } else if (!uld.owner_code) {
          toast.error('Please set Owner Code');
          isValid = false;
          break;
        } else if (!uld.condition_code) {
          toast.error('Please set Condition Code');
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  };
  const handleSave = async () => {
    if (validateFormData()) {
      const transformedData = {
        ...formData,
        uldInfo: formData.uldInfo.map(stack => ({
          ...stack,
          asset_type: stack.asset_type ? 'N' : 'Y' // N = Lease
        }))
      };
      if (formData.origin == formData.destination) {
        toast.error('Origin and Destination must not be same.');
      } else {

        console.log('Saving data:', transformedData);
        try {
          const res = await withRFSToken.post(SAVEULDCHARTER, transformedData);
          if (res.data.response_code == 200) {
            toast.success('New Stock Saved')
            // const innerData = res.data.response_data;
            // setGetOwnerCode(innerData);
            // setFormData({
            //     id: '1',
            //     status: '',
            //     destination: '', handler_name: '', condition_code: '',
            //     // type_code: '', serial_number: '', owner_code: '', airline_name: '', asset_type: false,
            //     uldInfo: [
            //         { id: '1', type_code: '', serial_number: '', owner_code: '', airline_name: '', asset_type: '', }
            //     ]
            // });
            window.location.href = '/ULDControl/';
          } else {
            toast.error(res.data.response_msg);
          }
          // .then(function (res) {

          // });

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
  }

  const [processText, setProcessText] = useState('');

  const handleTextareaChange = (e) => {
    setProcessText(e.target.value);
  };
  const handleProcessButtonClick = async () => {
    // Process the data in processText
    console.log('Processing FFM:', processText);
    try {
      const res = await withRFSToken.post(MSGPROCESSCHARTER, { ffm_message: processText });
      if (res.data.response_code == 200) {
        toast.success('FFM processed');
        const processedData = res.data.response_data;
        const transformedUldInfo = processedData.uldInfo.map((item) => ({
          ...item,
          asset_type: item.asset_type === 'Y' ? false : true
        }));
        const updatedFormData = {
          ...processedData,
          charter_type: processedData.charter_type || 'F', 
          status: processedData.status || '4',
          uldInfo: transformedUldInfo
        };
        setFormData(updatedFormData);
        const initialDisabledRows = processedData.uldInfo.map((reg) => reg.register == 'Y' ? true : false);
        setAssetTypeDisabled(initialDisabledRows);

      } else {
        toast.error(res.data.response_msg);
      }
      // .then(function (res) {

      // });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (formData.origin) {
      const selectedDestination = getDestination.find(option => option.value === formData.origin);
      if (selectedDestination) {
        console.log("🚀 ~ useEffect ~ selectedDestination (programmatic):", selectedDestination);
        setOriginHandler(selectedDestination.address);
        setSelectedOriginId(selectedDestination.station_id);
        // setSelectedOriginHandler(null);
      } else {
        // Reset or handle if the origin value doesn't match any destination
        setOriginHandler([]);
        setSelectedOriginId(null);
        // setSelectedOriginHandler(null);
      }
    }
    if (formData.destination) {
      const selectedDestination = getDestination.find(option => option.value === formData.destination);
      if (selectedDestination) {
        console.log("🚀 ~ useEffect ~ selectedDestination: jjj", selectedDestination);
        setDestinationHandler(selectedDestination.address);
        setSelectedDestinationId(selectedDestination.station_id);
        // setSelectedDestinationHandler(null);
      } else {
        // Reset or handle if the origin value doesn't match any destination
        setDestinationHandler([]);
        setSelectedDestinationId(null);
        // setSelectedDestinationHandler(null);
      }
    }
  }, [formData])
  // Modal for Asset Type and Cancel
  const [showProcess, setShowProcess] = useState(false);
  const handleCloseProcess = () => { setShowProcess(false); };
  const handleFullsizeClick = (event) => {
    event.preventDefault();
    setShowProcess(true);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalRadioValue, setModalRadioValue] = useState('');
  const [modalContent, setModalContent] = useState('');
  const handleModalClose = () => { setShowModal(false); setModalRadioValue('') };

  const handleModalOpen = (contentType) => {
    setModalContent(contentType);
    setShowModal(true);
  };
  const handleConfirmation = (confirm) => {
    if (confirm) {
      setShowModal(false);
      window.location.href = '/ULDControl'
    } else {
      setShowModal(false);
    }
  }

  const handleModalRadioChange = (event) => {
    const { name, value } = event.target;
    setModalRadioValue({ [name]: value });
  };
  const handleModalUpdate = () => {
    const covertAssetType = modalRadioValue.assetType === 'O' ? false : true;
    const updatedAssetType = !assetTypeDisabled ? covertAssetType : '';
    const updatedFormData = {
      ...formData,
      uldInfo: formData.uldInfo.map((stack, index) => {
        // ...stack,
        // asset_type: updatedAssetType
        if (!assetTypeDisabled[index]) {
          return {
            ...stack,
            asset_type: covertAssetType
          };
        }
        return stack;
      })
    };
    setFormData(updatedFormData);
    setModalRadioValue('')
    setShowModal(false);
  };

  // ADD ADDRESS
  const [addressFor, setAddressFor] = useState('');
  const addressModalOpen = (addressType) => {
    setAddressFor(addressType);
    setAddressModalShow(true)
  }
  const handleAddressChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox' && name === 'regd') {
      setInputAddress({ ...inputAddress, [name]: checked ? '1' : '0' });
    } else {
      setInputAddress({ ...inputAddress, [name]: value });
    }
    console.log(inputAddress);
  };
  let orgLabel;
  if (selectedOriginId) {
    const idMatch = getDestination.find(option => option.station_id == selectedOriginId);
    // console.log("🚀 ~ FormFuntion ~ idMatch:", idMatch)
    orgLabel = idMatch.label;
  }
  let destLabel;
  if (selectedDestinationId) {
    const idMatch = getDestination.find(option => option.station_id == selectedDestinationId);
    // console.log("🚀 ~ FormFuntion ~ idMatch:", idMatch)
    destLabel = idMatch.label;
  }

  const validateAddressData = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate email
    const validateEmail = (email) => {
      return emailRegex.test(email);
    };
    if (!inputAddress.company_name) {
      console.log('Text input is required');
      toast.error('Company Name must not be blank');
      isValid = false;
    } else if (!inputAddress.agent_or_handler) {
      console.log('Text input is required');
      toast.error('Please select Agent or Handler');
      isValid = false;
    }
    // else if (!inputAddress.address) {
    //   console.log('Text input is required');
    //   toast.error('Address must not be blank');
    //   isValid = false;
    // }
    //  else if (!inputAddress.phone_number) {
    //   console.log('Text input is required');
    //   toast.error('Phone Number must not be blank');
    //   isValid = false;
    // } 
    // else if (!/^\d{10}$/.test(inputAddress.phone_number)) {
    //   console.log('10');
    //   toast.error('Phone Number must be 10 Digits');
    //   isValid = false;
    // } else if (!inputAddress.email) {
    //   console.log('Text input is required');
    //   toast.error('Email must not be blank');
    //   isValid = false;
    // }
    //  else if (!validateEmail(inputAddress.email)) {
    //   console.log('Invalid email address');
    //   toast.error('Invalid email address');
    //   isValid = false;
    // }
    else {
      return isValid;
    }
  };

  const addAddress = async (e) => {
    console.log('444', inputAddress);
    if (validateAddressData()) {
      e.preventDefault();
      // const newAddressID = Math.floor(Math.random() * 1000000);

      let stationid;
      if (addressFor == 'origin') {
        stationid = selectedOriginId;
      } else {
        stationid = selectedDestinationId;
      }
      const NewInputAddress = { ...inputAddress, station_id: stationid };
      console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
      if (NewInputAddress) {
        try {
          const res = await withRFSToken.post(ULDADDRESSSAVE, NewInputAddress)
            .then(function (res) {
              // const innerData = res;
              // console.log("🚀 ~ .then ~ innerData:", innerData)
              if (res.data.response_code == 200) {
                const newAddress = res.data.response_data;
                setAddress(newAddress);
                fetchDestination();
                if (addressFor == 'origin') {
                  setOriginHandler(prevHandlerNames => [
                    ...prevHandlerNames,
                    newAddress
                  ]);
                } else {
                  setDestinationHandler(prevHandlerNames => [
                    ...prevHandlerNames,
                    newAddress
                  ]);
                }
                setInputAddress({
                  station_address_id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
                });
                console.log(address);
                addressModalClose();
              } else {
                toast.error(res.data.response_msg);
              }
            });

        } catch (error) {
          console.error('Error sending request:', error);
        }
      }

    }
  }
  // ADD ADDRESS END

  return (
    <>
      <div className="">
        <Header />{console.log('100', formData)}

        <div className='main-panel'>
          <Link to="/ULDControl" className='stk_back'> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
          <div className='charter_flight'>
            <Row className='mb-3'>
              <Col xs={6}>
                <div className='flbg h-100'>
                  <div className='d-flex justify-content-between charter_top'>
                    <h3>Add Charter Flight</h3>
                  </div>
                  <div className='charter_from from_width'>
                    <Form.Group className='sl_status'>
                      <Form.Check
                        label="Flight"
                        name="charter_type"
                        type='radio'
                        id='Flight'
                        value="F"
                        checked={formData.charter_type === 'F'}
                        onChange={handleInputChange}
                      />
                      <Form.Check
                        label="Truck"
                        name="charter_type"
                        type='radio'
                        id='Truck'
                        value="T"
                        checked={formData.charter_type === 'T'}
                        onChange={handleInputChange}
                      />

                    </Form.Group>

                    <Form.Group className='sl_status'>
                      <Form.Label>Select Status</Form.Label>
                      <Form.Check
                        label="On Air"
                        name="status"
                        type='radio'
                        id='Onair'
                        value="4"
                        checked={formData.status === '4'}
                        onChange={handleInputChange}
                      />

                      <Form.Check
                        label="In Operation"
                        name="status"
                        type='radio'
                        id='Operation'
                        value="1"
                        checked={formData.status === '1'}
                        onChange={handleInputChange}
                      />

                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" >
                      <Col sm="6" className='pe-0'>
                        <Form.Label>Flight Number <span>*</span></Form.Label>
                        <div className='d-flex'>
                          <Select
                            options={getOwnerCodes}
                            onChange={(selected) => {
                              const updatedData = { ...formData, flight: selected.value };
                              setFormData(updatedData);
                            }}
                            placeholder="Flight"
                            className='autoslct'
                            name="flight"
                            value={getOwnerCodes.find(option => option.value === formData.flight)}
                          />
                          {/* <Form.Select aria-label="Default" name="flight" value={formData.flight} onChange={handleInputChange}>
                            <option>Please Select</option>
                            {getOwnerCodes &&
                              getOwnerCodes.map((option, index) =>
                                <option key={index} value={option.IATA}>{option.IATA}</option>
                              )
                            }
                          </Form.Select> */}
                          <span>-</span>
                          <Form.Control
                            type="text" className='ms-2'
                            name='flight_number'
                            maxLength='8' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }}
                            value={formData.flight_number} onChange={handleInputChange}
                          />

                        </div>
                      </Col>

                      <Col sm="6" >
                        <Form.Label>Flight Date <span>*</span></Form.Label>
                        <Form.Control type="date" name="flight_date" value={formData.flight_date} onChange={handleInputChange} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" >
                      <Col sm="6" className='pe-0'>
                        <Form.Label>Origin <span>*</span></Form.Label>
                        {/* <Form.Control type="text" name="origin" value={formData.origin} onChange={handleInputChange} /> */}
                        {/* <select className="form-select w-100" name="origin" value={formData.origin} onChange={handleInputChange}>
                          <option>Please Select</option>
                          {getDestination &&
                            getDestination.map((option, index) =>
                              <option key={index} value={option.port_code}>{option.port_code}</option>
                            )
                          }
                        </select> */}
                        <Select
                          options={getDestination}
                          onChange={(selected) => {
                            const selectedDestination = getDestination.find(option => option.value === selected.value);
                            console.log("🚀 ~ NewCharter ~ selectedDestination:", selectedDestination)
                            const updatedData = { ...formData, origin: selected.value, handler_name: '' };
                            setFormData(updatedData);
                            setOriginHandler(selectedDestination ? selectedDestination.address : []);
                            setSelectedOriginId(selectedDestination.station_id);
                            setSelectedOriginHandler(null);
                          }}
                          placeholder="Origin"
                          className='autoslct'
                          name="origin"
                          value={getDestination.find(option => option.value === formData.origin)}
                        />
                      </Col>

                      <Col sm="6">
                        <label className="form-label">Origin Handler Name {formData.origin ? <button onClick={() => addressModalOpen('origin')} className='raad_adrss'>+ Add</button> : ''}</label>
                        {/* <input className="form-control" id="ex2" name="handler_name" type="text" value={formData.handler_name} onChange={handleInputChange} /> */}
                        <Select
                          options={originHandler.map(handler => ({
                            value: handler.id,
                            label: handler.company_name
                          }))}
                          onChange={(selected) => {
                            const updatedData = { ...formData, handler_origin: selected.value };
                            setFormData(updatedData);
                            setSelectedOriginHandler(selected);
                          }}
                          placeholder="Handler Name"
                          className='autoslct'
                          name="handler_origin"
                          // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                          value={selectedOriginHandler ? { value: selectedOriginHandler.value, label: selectedOriginHandler.label } : null}
                          isDisabled={!originHandler.length}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" >
                      <Col sm="6" className='pe-0'>
                        <Form.Label>Destination <span>*</span></Form.Label>
                        {/* <Form.Control type="text" name="destination" value={formData.destination} onChange={handleInputChange} /> */}
                        {/* <Form.Select className="form-select w-100" name="destination" value={formData.destination} onChange={handleInputChange}>
                          <option>Please Select</option>
                          {getDestination &&
                            getDestination.map((option, index) =>
                              <option key={index} value={option.port_code}>{option.port_code}</option>
                            )
                          }
                        </Form.Select> */}
                        <Select
                          options={getDestination}
                          onChange={(selected) => {
                            const selectedDestination = getDestination.find(option => option.value === selected.value);
                            const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                            setFormData(updatedData);
                            setDestinationHandler(selectedDestination ? selectedDestination.address : []);
                            setSelectedDestinationId(selectedDestination.station_id);
                            setSelectedDestinationHandler(null);
                            setFormData(updatedData);
                          }}
                          placeholder="Destination"
                          className='autoslct'
                          name="destination"
                          value={getDestination.find(option => option.value === formData.destination)}
                        />
                      </Col>

                      <Col sm="6">
                        <label className="form-label">Destination Handler Name {formData.destination ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</label>
                        {/* <input className="form-control" id="ex2" name="handler_name" type="text" value={formData.handler_name} onChange={handleInputChange} /> */}
                        <Select
                          options={destinationHandler.map(handler => ({
                            value: handler.id,
                            label: handler.company_name
                          }))}
                          onChange={(selected) => {
                            const updatedData = { ...formData, handler_destination: selected.value };
                            setFormData(updatedData);
                            setSelectedDestinationHandler(selected);
                          }}
                          placeholder="Handler Name"
                          className='autoslct'
                          name="handler_destination"
                          // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                          value={selectedDestinationHandler ? { value: selectedDestinationHandler.value, label: selectedDestinationHandler.label } : null}
                          isDisabled={!destinationHandler.length}
                        />{console.log('654', selectedDestinationHandler)}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" >
                      <Col sm="6" className='pe-0'>
                        <Form.Label>Registration Number </Form.Label>
                        <Form.Control type="text" name="flight_reg_number" value={formData.flight_reg_number} onChange={handleInputChange} />
                      </Col>

                    </Form.Group>

                    <Form.Group className="mb-1" >
                      <Form.Label>Remarks </Form.Label>
                      <Form.Control as="textarea" name="remarks" value={formData.remarks} onChange={handleInputChange} />
                    </Form.Group>

                    {/* <Form.Group >
                      <Form.Label>Upload Documents/Images </Form.Label>
                      <div className="drop_new">
                        <h3>Drag and drop files here</h3>
                        <span>Maximum file size 4 MB</span>
                      </div>

                    </Form.Group> */}

                  </div>

                </div>
              </Col>
              <Col xs={6}>
                <div className='flbg'>
                  <div className='d-flex justify-content-between charter_top '>
                    <h3>Automated FFM processing</h3>
                    <a href='#' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                  </div>

                  <div className='au_proses'>
                    <Form.Group >
                      <Form.Control
                        as="textarea" name='process' aria-label="With textarea" type="text" style={{ resize: 'none' }}
                        value={processText}
                        onChange={handleTextareaChange} />
                    </Form.Group>
                    <div className='text-center mt-3'><Button variant="primary" className='process_btn' onClick={handleProcessButtonClick}> Process FFM </Button></div>
                  </div>
                </div>
              </Col>
              <Modal show={showProcess} className='addfrom' onHide={handleCloseProcess} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Automated FFM processing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='au_proses'>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        name='process'
                        aria-label="With textarea"
                        type="text"
                        style={{ resize: 'none' }}
                        value={processText}
                        onChange={handleTextareaChange}
                      />
                    </Form.Group>
                    <div className='text-center mt-3 mb-3'>
                      <Button variant="primary" className='process_btn' onClick={handleProcessButtonClick}> Process FFM </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Row>

            <Row>
              <Col>
                <div className='flbg'>
                  <div className='d-flex justify-content-between charter_top'>
                    <h3>ULD Details <span className='selected'>Selected ULD's <Badge>{formData.uldInfo.length}</Badge> </span> </h3>
                    <div className='alltype'>
                      <button type="button" className="btn btn-outline-secondary" onClick={() => handleModalOpen('assetTypeChange')}> <FontAwesomeIcon icon={faGear} /> All Asset Type </button>
                    </div>
                  </div>

                  <div className='mt-2'>
                    <Table className='uld_details ra_newchart'>
                      <thead>
                        <tr>
                          <th>ULD Number</th>
                          <th>Type Code</th>
                          <th>Serial Number</th>
                          <th>Owner Code</th>
                          <th>Condition Code</th>
                          <th>Asset Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.uldInfo && formData.uldInfo.map((section, index) => {
                          return (
                            <tr key={index}>
                              <td><Form.Control type="text" name="uld_number" readOnly value={section.uld_number} /></td>
                              <td>
                                {/* <Form.Control type="text" name="type_code" value={section.type_code} onChange={(e) => handleULDInputChange(section.id, 'type_code', e.target.value)} /> */}
                                {/* <select className="form-select" name="type_code" value={section.type_code} onChange={(e) => handleULDInputChange(section.id, 'type_code', e.target.value)}>
                                  <option selected>Type Code</option>
                                  {getTypeCodes &&
                                    getTypeCodes.map((option, index) =>
                                      <option key={index} value={option.uld_typecode}>{option.uld_typecode}</option>
                                    )
                                  }
                                </select> */}
                                <Select
                                  options={getTypeCodes}
                                  // onChange={(selected) => {
                                  //   const updatedData = formData.uldInfo.map(item => {
                                  //     if (item.id === section.id) {
                                  //       const updateSec = { ...item, type_code: selected.value };
                                  //       updateSec.uld_number = `${updateSec.type_code || ''}${updateSec.serial_number || ''}${updateSec.owner_code || ''}`
                                  //       return updateSec;
                                  //     }
                                  //     return item;
                                  //   });
                                  //   setFormData({ ...formData, uldInfo: updatedData });
                                  // }}
                                  onChange={(selected) => {
                                    handleULDInputChange(section.id, 'type_code', selected.value);
                                  }}
                                  placeholder="Type Code"
                                  className='autoslct'
                                  name="type_code"
                                  value={getTypeCodes.find(option => option.value === section.type_code)}
                                  isDisabled={section.register == 'Y' ? true : false}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name="serial_number"
                                  value={section.serial_number}
                                  onChange={(e) => handleULDInputChange(section.id, 'serial_number', e.target.value)}
                                  disabled={section.register == 'Y' ? 'disabled' : ''}
                                />
                              </td>
                              <td>
                                {/* <Form.Control type="text" name="owner_code" value={section.owner_code} onChange={(e) => handleULDInputChange(section.id, 'owner_code', e.target.value)} /> */}
                                {/* <select className="form-select" name="owner_code" value={section.owner_code} onChange={(e) => handleULDInputChange(section.id, 'owner_code', e.target.value)}>
                                  <option selected>Owner Code</option>
                                  {getOwnerCodes &&
                                    getOwnerCodes.map((option, index) =>
                                      <option key={index} value={option.IATA}>{option.IATA}</option>
                                    )
                                  }
                                </select> */}
                                <Select
                                  options={getOwnerCodes}
                                  // onChange={(selected) => {
                                  //   const updatedData = formData.uldInfo.map(item => {
                                  //     if (item.id === section.id) {
                                  //       const updateSec = { ...item, owner_code: selected.value };
                                  //       updateSec.uld_number = `${updateSec.type_code || ''}${updateSec.serial_number || ''}${updateSec.owner_code || ''}`
                                  //       return updateSec;
                                  //     }
                                  //     return item;
                                  //   });
                                  //   setFormData({ ...formData, uldInfo: updatedData });
                                  // }}
                                  onChange={(selected) => {
                                    handleULDInputChange(section.id, 'owner_code', selected.value);
                                  }}
                                  placeholder="Owner Code"
                                  className='autoslct'
                                  name="owner_code"
                                  value={getOwnerCodes.find(option => option.value === section.owner_code)}
                                  isDisabled={section.register == 'Y' ? true : false}
                                />
                              </td>
                              <td>
                                <select className="form-select" name="condition_code" value={section.condition_code} onChange={(e) => handleULDInputChange(section.id, 'condition_code', e.target.value)}>
                                  {getCondCodes &&
                                    Object.entries(getCondCodes).map((option, index) =>
                                      <option key={index} value={option[0]}>{option[1]}</option>
                                    )
                                  }
                                </select>
                              </td>

                              <td>
                                <div>

                                  <div className='chart_switch'>
                                    <div className={section.register=="Y" ? 'switch6 dis' : 'switch6'}>
                                      <label className={section.register=="Y" ? 'switch6-light dis' : 'switch6-light'}>
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          // disabled={assetTypeDisabled[index]}
                                          disabled={section.register=="Y" ? true : false}
                                          onChange={(e) => handleULDInputChange(section.id, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>

                                </div>

                              </td>
                              <td>
                                {formData.uldInfo.length > 1 && (
                                  <img src={Trash} onClick={() => removeULDStack(section.id)} />
                                )}
                              </td>
                            </tr>
                          )
                        })}


                      </tbody>
                    </Table>

                    <div className='text-end'><Button variant="primary" className='stk_add' onClick={addULDStack}> <FontAwesomeIcon icon={faPlus} />Add ULD</Button></div>

                    <div className='text-center mt-5'>
                      <Button variant="light" className='stk_cancel' onClick={() => handleModalOpen('cancelForm')}>CANCEL</Button>
                      <Button variant="primary" className='stk_save' onClick={handleSave}>UPDATE</Button>
                    </div>

                  </div>

                </div>
              </Col>
            </Row>

          </div>

        </div>

        <Modal show={showModal} onHide={handleModalClose} className='addfrom change_type' centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {modalContent === 'assetTypeChange' && (
              <>
                <h3>All Asset Type Changes</h3>
                <p>Are sure you want to change all asset type?</p>
                <div className='asset_midle'>
                  <Form.Check
                    type='radio'
                    label='Owned'
                    name='assetType'
                    id='own'
                    value='O'
                    checked={modalRadioValue.assetType === 'O'}
                    onChange={handleModalRadioChange}
                  />
                  <Form.Check
                    type='radio'
                    label='Lease'
                    name='assetType'
                    id='lease'
                    value='L'
                    checked={modalRadioValue.assetType === 'L'}
                    onChange={handleModalRadioChange}
                  />
                </div>
              </>
            )}
            {modalContent === 'cancelForm' && (
              <>
                <h3>All changes will be destroyed.</h3>
                <p>If you proceed with the change, all data in this section will be removed.
                  Are you sure you want to continue?</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {modalContent === 'assetTypeChange' ?
              (
                <>
                  <Button variant='primary' onClick={handleModalUpdate}>
                    Update Asset Type
                  </Button>
                </>
              ) : (
                <>
                  <div className='d-flex w-50 mx-auto are_bottom'>
                    <Button variant="secondary" onClick={() => handleConfirmation(false)}>
                      No
                    </Button>
                    <Button variant="primary" onClick={() => handleConfirmation(true)}>
                      Yes
                    </Button>
                  </div>
                </>
              )}
          </Modal.Footer>
        </Modal>
        <Modal show={addressModalShow} onHide={addressModalClose} centered className='addfrom'>
          <Modal.Header closeButton onClick={addressModalClose}>
            <Modal.Title>Add Address - {addressFor == 'origin' ? orgLabel : destLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Form.Group className='mb-2'>
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" onChange={handleAddressChange} name='company_name' placeholder="Company Name" />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>Agent or Handler</Form.Label>
              <Form.Check
                inline
                label="Agent"
                name="agent_or_handler"
                type='radio'
                id='export'
                value='A'
                checked={inputAddress.agent_or_handler === 'A'}
                onChange={handleAddressChange}
                disabled
              />

              <Form.Check
                inline
                label="Handler"
                value="H"
                name="agent_or_handler"
                type='radio'
                id='import'
                checked={inputAddress.agent_or_handler === 'H'}
                onChange={handleAddressChange}
              />
            </Form.Group>
            {inputAddress.agent_or_handler == 'A' &&
              <>
                <Form.Group className='mb-2'>
                  <Form.Check
                    inline
                    label="External"
                    name="agent_type"
                    type='radio'
                    id='external'
                    value='E'
                    checked={inputAddress.agent_type === 'E'}
                    onChange={handleAddressChange}
                  />

                  <Form.Check
                    inline
                    label="Internal"
                    value="I"
                    name="agent_type"
                    type='radio'
                    id='internal'
                    checked={inputAddress.agent_type === 'I'}
                    onChange={handleAddressChange}
                  />

                </Form.Group>
              </>
            }
            <Form.Group className='mb-2'>
              <Form.Label>Address </Form.Label>
              <Form.Control type="text" onChange={handleAddressChange} name='address' placeholder="Address" />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>Email ID</Form.Label>
              <Form.Control type="text" onChange={handleAddressChange} name='email' placeholder="E-mail" />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>Telephone No</Form.Label>
              <Form.Control type="number" onChange={handleAddressChange} name='phone_number' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} placeholder="Number" />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={addAddress}  >
              Add Address
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default NewCharter;