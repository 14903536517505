import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import "../../assets/css/bookingtruckschedule.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus, faChevronLeft, faChevronRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Header from "../../Componenets/Header/Header";
import Stepper from '../../Componenets/Stepper/Stepper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportActive from '../../Componenets/JGrid/bookingTruck/ExportActive';
import ExportTrash from '../../Componenets/JGrid/bookingTruck/ExportTrash';
import TopMenu from "../../Componenets/TopMenu/TopMenu";
import LeftMenu from '../../Componenets/LeftMenu/LeftMenu';
import { BtsExport } from './BtsExport';
import { BtsImport } from './BtsImport';
// import Button from 'react-bootstrap/Button';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autosuggest from 'react-autosuggest';
import Arcv from "../../assets/images/archive.svg";
import Trash from "../../assets/images/delete.svg";
import Export from "../../assets/images/export.svg";
import Exportw from "../../assets/images/exportw.png";
import AddStation from '../../Componenets/common/AddStation';
import AddStationAddress from '../../Componenets/common/AddStationAddress';
import { MultiSelect } from 'react-multi-select-component';

import { withoutRFSToken, withRFSToken } from '../../Services/axios.js';
import { STATIONGRID, BOOKINGTRUCKGRID, ADDTRUCKSCHEDULE } from '../../Utils/conts.js';
import { toast } from 'react-toastify';


// const initialSuggestion = JSON.parse(localStorage.getItem('stationData')) || [];
// const initialSuggestions = initialSuggestion.Active || [];

const BookingtruckSchedule = () => {

    const childRef = useRef();

    const [exportGrid, setExportGrid] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {

                const res = await withRFSToken.post(BOOKINGTRUCKGRID, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        setExportGrid(innerData.export);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // Call the fetchData function to fetch data from the API
        fetchData();

    }, []);

    // const dispatch = useDispatch();
    // const apigridData = useSelector(state => state.stationGrid);
    // const apiBtruckgridData = useSelector(state => state.btruckGrid.data?.response_data);
    // const apiBtruckgridData = fetchData();
    // let exportGrid =[];
    // let exportTrash =[];
    // if (apiBtruckgridData){
    //     exportGrid = apiBtruckgridData.export;
    // }
    // console.log("🚀 ~ BookingtruckSchedule ~ apiBtruckgridData:", apiBtruckgridData)
    // console.log("🚀 ~ BookingtruckSchedule ~ apiBtruckgridData:", apiBtruckgridData)
    // const apigridDatas = apigridData && apigridData.map((item) => {return {code:item.station_code}})
    // console.log("🚀 ~ BookingtruckSchedule ~ apigridData:xxx", apigridData)

    // useEffect(() => {
    //     dispatch(fetchStationGridData());
    //     dispatch(fetchBtruckGridData());
    //   }, [dispatch]);

    const [value, setValue] = useState('');
    const [suggestionsBp, setSuggestionsBp] = useState([]);
    // console.log("🚀 ~ BookingtruckSchedule ~ suggestionsBp:", suggestionsBp)
    const [boardpoint, setBoardpoint] = useState([]);
    const [bpaddress, setBpaddress] = useState(null);

    const [showAddButton, setShowAddButton] = useState(false);
    const [showAddAddressButton, setShowAddAddressButton] = useState(false);
    const [showAddAddressButton2, setShowAddAddressButton2] = useState(false);
    const [valueOffpoint, setValueOffpoint] = useState('');
    const [suggestionsOffpoint, setSuggestionsOffpoint] = useState([]);
    const [selectedOffpoint, setSelectedOffpoint] = useState(null);

    const [showAddButtonOffpoint, setShowAddButtonOffpoint] = useState(false);
    const [selectedBoardpoint, setSelectedBoardpoint] = useState(null);
    const [selectedOffpointId, setSelectedOffpointId] = useState(null);
    // useEffect(() => {
    //     if (apigridData) {
    //         const activeData = apigridData.Active;
    //         console.log("🚀 ~ useEffect ~ activeData:", activeData)
    //         setSuggestionsBp(activeData);
    //         setSuggestionsOffpoint(activeData);
    //     }
    //   }, [apigridData]);
    //   console.log("🚀 ~ BookingtruckSchedule ~ suggestionsBp", suggestionsBp)
    const [fetchStation, setFetchStation] = useState([]);
    // useEffect(() => {
    const fetchData = async () => {
        try {
            const res = await withRFSToken.post(STATIONGRID, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const activeInnerData = res.data.response_data.Active;
                    console.log("🚀 ~ activeInnerData:", activeInnerData)
                    // const transformedData = activeInnerData ? activeInnerData.map(item => ({
                    //     id: item.id,
                    //     value: item.station_id,
                    //     label: item.station_code
                    // })) : []; // For multiselect
                    //   console.log("🚀 ~ transformedData ~ transformedData:", transformedData)
                    //   setBoardpoint(transformedData); // For multiselect
                    setFetchStation(innerData.Active);
                    setSuggestionsBp(innerData.Active);
                    setSuggestionsOffpoint(innerData.Active);
                    return activeInnerData
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // Call the fetchData function to fetch data from the API
    // fetchData();

    // }, []);
    // console.log("🚀 ~ .then ~ setSuggestionsBp:", suggestionsBp)

    const [formData, setFormData] = useState({
        booking_type: '',
        bookingtruck_no: '',
        boardpoint: '',
        boardpoint_address: '',
        offpoint: '',
        offpoint_address: '',
        trip_type: 'O',
        date: '',
        time: '',
        selected_days: [],
        freq_date: '',
        freq_time: '',
        start_date: '',
        end_date: ''
    })

    const [stationData, setStationData] = useState({});
    const [addressType, setAddressType] = useState('');
    const handleReloadAddress = async () => {
        console.log('prop1');
        try {
            const res = await withRFSToken.post(STATIONGRID, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const activeInnerData = res.data.response_data.Active;
                    // console.log("🚀 ~ activeInnerData:", activeInnerData)

                    // console.log('prop2',addressType);
                    if (selectedBoardpoint !== null) { }
                    setFetchStation(innerData.Active);
                    setSuggestionsBp(innerData.Active);
                    setSuggestionsOffpoint(innerData.Active);
                    if (addressType == 'bp') {
                        // console.log('address Type', addressType);
                        if (innerData) {
                            const station = innerData.Active.find(station => station.station_id === stationData.station_id)
                            if (station.station_addresses) {
                                setBpaddress(station.station_addresses);
                            } else {
                                setBpaddress([]);
                            }
                        } else {
                            setBpaddress([]);
                        }
                    } else {
                        // console.log('address Type', addressType);
                        if (innerData) {
                            const station = innerData.Active.find(station => station.station_id === stationData.station_id)
                            if (station.station_addresses) {
                                setSelectedOffpoint(station.station_addresses);
                            } else {
                                setSelectedOffpoint([]);
                            }
                        } else {
                            setSelectedOffpoint([]);
                        }
                    }

                    setShowAddStation(false);
                    setShowAddStationAddress(false);
                    return activeInnerData
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // const stationAddress = suggestionsBp.find(station => getSuggestionValue(station).toLowerCase() === fetchStation.station_code.toLowerCase());
    }
    // useEffect(() =>{
    //     handleReloadAddress();
    // }, [])
    const onChange = (event, { newValue }) => {
        // console.log('567', newValue);
        setValue(newValue);
        setShowAddButton(false);

        if (!newValue) {
            setBpaddress(null);
        } else {
            // Filter suggestionsBp based on the input value
            const station = suggestionsBp.find(station => getSuggestionValue(station).toLowerCase() === newValue.toLowerCase());
            // If a matching station is found, set bpaddress to its station_addresses data
            if (station) {
                console.log('567', station);
                setBpaddress(station.station_addresses);
                setShowAddAddressButton(true);
                setShowAddButton(false);
                // setStationData(station);
            } else {
                setBpaddress(null); // Set bpaddress to null if no matching station is found
                setShowAddAddressButton(false);
                setShowAddButton(true);
            }
        }

    };
    // console.log('688', stationData)
    // data from station component
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        setSuggestionsBp(
            inputLength === 0
                ? []
                : fetchStation.filter(
                    (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue &&
                        fruit.station_code !== (selectedOffpoint && selectedOffpoint.station_code)
                )
        );

        // setShowAddButton(inputLength > 0 && suggestionsBp.length === 0); commented 30.04.24
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsBp([]);
        setSelectedOffpoint(null); // TO CLEAN FIRST FIELD

        // setShowAddButton(false);
    };

    const getSuggestionValue = (suggestion) => suggestion.station_code;

    const renderSuggestion = (suggestion) => <div>{suggestion.station_code}</div>;
    const onSelectBpaddress = (bpadd) => {
        // console.log("🚀 ~ onSelectBpaddress ~ 777:", bpadd)

        // const addressOptions = bpadd.station_addresses.map(address => (
        //     `${address.handler_name} - ${address.address_1}`
        // ));
        // setBpaddress(addressOptions);
        // if (bpadd) { setBpaddress(bpadd.station_addresses);} comment 30.04.24
        if (bpadd) {
            if (bpadd.station_addresses) {
                setBpaddress(bpadd.station_addresses);
            } else {
                setBpaddress([]);
            }
        } else {
            setBpaddress([]);
        }

        setFormData({
            ...formData,
            boardpoint: bpadd.id
        });
    }

    const inputProps = {
        placeholder: 'Type a Stations',
        value,
        onChange: onChange,
    };
    // console.log("🚀 ~ BookingtruckSchedule ~ inputProps:", inputProps)

    // Offpoint Start
    const onChangeOffpoint = (event, { newValue }) => {
        setValueOffpoint(newValue);
        setShowAddButtonOffpoint(false);

        if (!newValue) {
            setBpaddress(null);
        } else {
            // Filter suggestionsBp based on the input value
            const station = suggestionsOffpoint.find(station => getSuggestionValueOffpoint(station).toLowerCase() === newValue.toLowerCase());
            // If a matching station is found, set bpaddress to its station_addresses data
            if (station) {
                setSelectedOffpoint(station.station_addresses);
                setShowAddAddressButton2(true);
                setShowAddButtonOffpoint(false);
                // setStationData(station);
            } else {
                setSelectedOffpoint(null); // Set bpaddress to null if no matching station is found
                setShowAddButtonOffpoint(true);
                setShowAddAddressButton2(false);
            }
        }
        // setFormData({
        //     ...formData,
        //     offpoint: newValue
        // });
    };

    const onSuggestionsFetchRequestedOffpoint = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        setSuggestionsOffpoint(
            inputLength === 0
                ? []
                : fetchStation.filter(
                    (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue &&
                        fruit.station_code !== (bpaddress && bpaddress.station_code)
                )
                    .filter(suggestion => suggestion.station_code !== (selectedOffpoint && selectedOffpoint.station_code))
        );
        // setShowAddButtonOffpoint(inputLength > 0 && suggestionsBp.length === 0);
    };

    const onSuggestionsClearRequestedOffpoint = () => {
        setSuggestionsOffpoint([]);
        // setBpaddress(null);
        // setShowAddButtonOffpoint(false);
    };

    const getSuggestionValueOffpoint = (suggestion) => suggestion.station_code;

    const renderSuggestionOffpoint = (suggestion) => <div>{suggestion.station_code}</div>;

    const onSelectOffpoint = (offpoint) => {
        // console.log("🚀 ~ onSelectOffpoint ~ offpoint:", offpoint)
        // const addressOptions = offpoint.station_addresses.map(address => (
        //     `${address.handler_name} - ${address.address_1}`
        // ));
        // setSelectedOffpoint(addressOptions);
        if (offpoint) { setSelectedOffpoint(offpoint.station_addresses); }

        setFormData({
            ...formData,
            offpoint: offpoint.id
        });
    };

    // const inputOffpointProps = {
    //   placeholder: 'Type a X',
    //   valueOffpoint,
    //   onChange: onChangeOffpoint,
    // };

    // ++++++++++++++++ Autosuggest End ++++++++++++++++++++++

    const [showAddStation, setShowAddStation] = useState(false)
    const onAddButtonClick = (autosuggestId) => {
        // AddStation
        console.log("Add button clicked", autosuggestId);
        // setShowAddButton(true);
        setShowAddButton(false);
        setShowAddButtonOffpoint(false);
        setShowAddStation(true);
        // console.log(showAddStation);
        if (autosuggestId == 'op') {
            setValueOffpoint('');
        } else {
            setValue('');
        }


    };
    const handleCloseModal = () => {
        setShowAddStation(false);
        setShowAddStationAddress(false);
        // setFormData({
        //     ...formData,
        //     station: {},
        // });
    };

    const handleBPAddressChange = (event) => {
        const selectedValue = event.target.value;
        // console.log("🚀 ~ handleBPAddressChange ~ selectedValue:", selectedValue)
        if (selectedValue === "AddAddress") {
            // handleAddAddressClick();
        } else {
            // Handle other selections
        }
        setFormData({
            ...formData,
            boardpoint_address: selectedValue
        });
    };
    const handleOPAddressChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "AddAddress") {
            // handleAddAddressClick();
        } else {
            // Handle other selections
        }
        setFormData({
            ...formData,
            offpoint_address: selectedValue
        });
    };
    const [showAddStationAddress, setShowAddStationAddress] = useState(false)
    const handleAddAddressClick = (addressType, stationId) => {
        // console.log('M55', addressType, stationId);
        setStationData(stationId);
        setShowAddStationAddress(true);
        setAddressType(addressType);
        // handleReloadAddress(addressType);
    }

    const handleFormOnchange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
        // console.log("🚀 ~ handleFormOnchange ~ formData:", updatedData);
        // console.log("🚀 ~ handleFormOnchange ~ formData:159", formData)
    }
    // Validation
    const validateFormData = () => {
        let isValid = true;
        if (!formData.booking_type) {
            console.log('Text input is required');
            toast.error('Please set Booking Type');
            isValid = false;
        } else
            if (!formData.bookingtruck_no) {
                console.log('Text input is required');
                toast.error('Bookingtruck Number must not be blank');
                isValid = false;
            } else
                if (!formData.boardpoint) {
                    console.log('Text input is required');
                    toast.error('Boardpoint must not be blank');
                    isValid = false;
                } else
                    if (!formData.boardpoint_address) {
                        console.log('Text input is required');
                        toast.error('Boardpoint Address must not be blank');
                        isValid = false;
                    } else
                        if (!formData.offpoint) {
                            console.log('Text input is required');
                            toast.error('Offpoint must not be blank');
                            isValid = false;
                        } else
                            if (!formData.offpoint_address) {
                                console.log('Text input is required');
                                toast.error('Offpoint Address must not be blank');
                                isValid = false;
                            } else
                                if (formData.trip_type == 'O') {
                                    if (!formData.date) {
                                        console.log('Text input is required');
                                        toast.error('Date must not be blank');
                                        isValid = false;
                                    } else if (!formData.time) {
                                        console.log('Text input is required');
                                        toast.error('Time must not be blank');
                                        isValid = false;
                                    }
                                    else {
                                        return isValid;
                                    }
                                } else
                                    if (formData.trip_type == 'F') {
                                        if (!formData.selected_days) {
                                            console.log('Text input is required');
                                            toast.error('Please Select Days');
                                            isValid = false;
                                        } else if (!formData.start_date) {
                                            console.log('Text input is required');
                                            toast.error('Start Date must not be blank');
                                            isValid = false;
                                        } else if (!formData.end_date) {
                                            console.log('Text input is required');
                                            toast.error('End Date must not be blank');
                                            isValid = false;
                                        } else if (!formData.freq_time) {
                                            console.log('Text input is required');
                                            toast.error('Time must not be blank');
                                            isValid = false;
                                        }
                                        else {
                                            return isValid;
                                        }
                                    }
                                    else {
                                        return isValid;
                                    }
    };
    const handleAddBookingtruck = async () => {
        // Here you can access all the form data from the formData state variable
        console.log('160', formData);
        // dispatch(addBookingTruckData(formData));
        if (validateFormData()) {
            try {
                const res = await withRFSToken.post(ADDTRUCKSCHEDULE, formData);
                // .then(function (res) {
                // const innerData = res;
                // console.log("🚀 ~ .then ~ innerData:", innerData)
                if (res.data.response_code == 200) {
                    reloadGrid();
                }
                // });

            } catch (error) {
                console.error('Error sending request:', error);
            }
            setShow(false);
            onSuggestionsClearRequested();

            setFormData({
                booking_type: '',
                bookingtruck_no: '',
                boardpoint: '',
                boardpoint_address: '',
                offpoint: '',
                offpoint_address: '',
                trip_type: 'O',
                date: '',
                time: '',
                selected_days: [],
                freq_date: '',
                freq_time: '',
                start_date: '',
                end_date: ''
            })
        }
        // You can perform any further actions with the form data here
    };

    const handleEdit = async (data) => {
        console.log("🚀 ~ handleEdit ~ data:", data)
        // Here you can handle the edit logic
        setShow(true);
        let fetchstations = [];
        try {
            const res = await withRFSToken.post(STATIONGRID, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const activeInnerData = res.data.response_data.Active;
                    fetchstations = innerData.Active;
                    setFetchStation(innerData.Active);
                    setSuggestionsBp(innerData.Active);
                    setSuggestionsOffpoint(innerData.Active);
                    console.log('station:XXY', suggestionsBp);
                    return activeInnerData
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // console.log("🚀 ~ handleEdit ~ stations:", stations)

        const station = fetchstations.find(station => station.station_code === data.boardpoint);
        // const stationAddress = station.station_addresses.find(address => address.station_code === data.boardpoint_address);
        const stationOp = fetchstations.find(station => station.station_code === data.offpoint);

        console.log("🚀 ~ handleEdit ~ station:XXX", station)

        if (data) {
            setValue(data.boardpoint); // Set the input value to the station code
            setBpaddress(station.station_addresses);
            onSelectBpaddress(station);

            setValueOffpoint(data.offpoint);
            onSelectOffpoint(stationOp)
            // setSelectedValueBpaddress(data.boardpoint_address);
        } else {
            setValue('');
            setBpaddress([]);
        }

        const transformData = {
            booking_type: data.booking_type,
            bookingtruck_id: data.bookingtruck_id,
            bookingtruck_no: data.bookingtruck_no,
            boardpoint: station.id,
            boardpoint_address: data.boardpoint_address,
            offpoint: stationOp.id,
            offpoint_address: data.offpoint_address,
            trip_type: data.trip_type,
            date: data.data,
            time: data.trip_type,
            selected_days: data.selected_days,
            freq_date: data.freq_date,
            freq_time: data.freq_time,
            start_date: data.start_date,
            end_date: data.end_date
        }
        // console.log('Data to edit:', data);
        setFormData(transformData);
        // Perform your edit operation, like making an API call to update the data
    };

    const reloadGrid = async () => {
        try {
            const res = await withRFSToken.post(BOOKINGTRUCKGRID, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ .then ~ innerData:", innerData)
                    setExportGrid(innerData.export);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // ++++++++++++++++ Autosuggest End ++++++++++++++++++++++

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setFormData({
            booking_type: '',
            bookingtruck_no: '',
            boardpoint: '',
            boardpoint_address: '',
            offpoint: '',
            offpoint_address: '',
            trip_type: 'O',
            date: '',
            time: '',
            selected_days: [],
            freq_date: '',
            freq_time: '',
            start_date: '',
            end_date: ''
        });
        setValueOffpoint('');
        setValue('');
        setShowAddAddressButton(false);
        setShowAddAddressButton2(false);
        setShowAddButton(false);
        setShow(false)
    };
    const handleShow = () => { setShow(true); fetchData(); };

    const [selectedDate, setSelectedDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleSelect = date => {
        setSelectedDate(date);
        setShowCalendar(false); // Close the calendar when a date is selected
    };

    const [currentDate, setCurrentDate] = useState(new Date());
    const daysToShow = 7;

    function handleNext() {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + daysToShow);
        setCurrentDate(newDate);
    }

    function handlePrevious() {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - daysToShow);
        setCurrentDate(newDate);
    }

    function isCurrentDate(date) {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    }

    function getDayName(date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
    }

    function getMonthAbbreviation(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[date.getMonth()];
    }

    const handleDayClick = async (date, event) => {

        // console.log('date');
        // console.log("🚀 ~ handleDayClick ~ date:", date)

        const calendar = document.querySelector('.scrollable-calendar');
        const days = calendar.querySelectorAll('.day');
        days.forEach(day => {
            day.classList.remove('active');
        });
        event.currentTarget.classList.add('active');
        // if (!apiData) {
        //   console.error('API data is not available');
        //   return;
        // }
        // Convert the date to the format "YYYY-MM-DD"
        const formattedDate = date.toISOString().split('T')[0];
        const dateObject = new Date(date);
        const datePart = dateObject.toISOString().split("T")[0];
        console.log("🚀 ~ handleDayClick ~ datePart:", datePart)

        try {
            const res = await withRFSToken.post(BOOKINGTRUCKGRID, { date: datePart })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ .then ~ innerData:", innerData)
                    setExportGrid(innerData.export);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setSelectedDate(date);  // Store the selected date
        if (childRef.current) {
            childRef.current.handleCloseClick();
        }
    };

    const [selectedButton, setSelectedButton] = useState('export');
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    // Frequency function Section Start
    const [frequentBooking, setFrequentBooking] = useState('O');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // const updatedData = { ...frequentBooking, [name]: value };
        const updatedData = e.target.value;
        setFrequentBooking(updatedData);
        setFormData({
            ...formData,
            trip_type: updatedData
        });
    };
    // frequency Function End

    const [selected, setSelected] = useState([]);

    const handleClick = (day) => {
        if (selected.includes(day)) {
            setSelected(selected.filter((d) => d !== day));
        } else {
            setSelected([...selected, day]);
        }
        const selectedDays = formData.selected_days.includes(day) ? formData.selected_days.filter(item => item !== day) : [...formData.selected_days, day];
        setFormData({
            ...formData,
            selected_days: selectedDays
        });
    };

    //   +++++++++++++++++++++++++++++ Frequent select
    // Tabs Start
    const [trashGridLoaded, setTrashGridLoaded] = useState(false);

    const handleTabSelect = (selectedTabKey) => {
        if (selectedTabKey === 'trash' && !trashGridLoaded) {
            setTrashGridLoaded(true);
        }
    };
    // Tabs End


    return (
        <div className="">
            <Header />
            <div className="page-body-wrapper schedule">
                <LeftMenu />
                <div className="main-panel rounded">
                    <div className="mainTab">
                        <TopMenu />
                        <div className="">
                            <div className="allcontent-fl">
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <button className={selectedButton === 'export' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('export')}>
                                            <img src={selectedButton === 'export' ? Exportw : Export} /> <span>Export</span>
                                        </button>
                                        <button className={selectedButton === 'import' ? 'exportbtn active' : 'exportbtn'} onClick={() => handleButtonClick('import')}>
                                            <img src={selectedButton === 'import' ? Exportw : Export} /> <span>Import</span>
                                        </button>
                                    </div>
                                    <div className='search'>
                                        <div className="form-group has-search">
                                            <span className="form-control-feedback"> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                            <input type="text" className="form-control" placeholder="Search" />
                                        </div>

                                    </div>
                                </div>
                                <div className="lcontent">
                                    <div className="mb-3 nestedtabs rounded">

                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <button className="addtruck" variant="primary" onClick={handleShow}>
                                                    <span> <FontAwesomeIcon icon={faPlus} /> Add Bookingtruck</span>
                                                </button>
                                            </div>
                                            <div className='d-flex'>
                                                <div>
                                                    <div style={{ position: 'relative' }}>
                                                        <div onClick={toggleCalendar} className='clendericon'>
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>
                                                        {showCalendar && (
                                                            <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 9999, }}>
                                                                <DatePicker
                                                                    selected={selectedDate}
                                                                    onChange={handleSelect}
                                                                    inline
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="calendar-container schedule_calender">
                                                    <div className="scrollable-calendar">
                                                        <button className='btnleft' onClick={handlePrevious}> <FontAwesomeIcon icon={faChevronLeft} /> </button>
                                                        {[...Array(daysToShow)].map((_, index) => {
                                                            const date = new Date(currentDate);
                                                            date.setDate(currentDate.getDate() + index);
                                                            return (
                                                                <div key={index} className={`day ${isCurrentDate(date) ? 'current-date' : ''}`} onClick={(event) => handleDayClick(date, event)}>
                                                                    <div className="day-name">{getDayName(date)}</div>
                                                                    <div className="date"> {getMonthAbbreviation(date)} {date.getDate()}</div>
                                                                </div>
                                                            );
                                                        })}

                                                        <button className='btnright' onClick={handleNext}> <FontAwesomeIcon icon={faChevronRight} /> </button>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className='mvownload'></div>
                                        </div>

                                    </div>
                                    {console.log('kkk', formData)}
                                    {showAddStationAddress && <AddStationAddress reloadAddress={handleReloadAddress} stationId={stationData} onClose={handleCloseModal} />}
                                    {showAddStation && <AddStation reloadStation={handleReloadAddress} onClose={handleCloseModal} />}

                                    <Modal show={show} onHide={handleClose} centered className='addfrom book_sdl'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Bookingtruck</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <Form>
                                                {/* {['radio'].map((type) => ( */}
                                                <div className="mb-2">
                                                    <Form.Check
                                                        inline
                                                        label="Export"
                                                        name="booking_type"
                                                        type='radio'
                                                        id='export'
                                                        value='E'
                                                        checked={formData.booking_type === 'E'}
                                                        onChange={handleFormOnchange}
                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="Import"
                                                        value="I"
                                                        name="booking_type"
                                                        type='radio'
                                                        id='import'
                                                        checked={formData.booking_type === 'I'}
                                                        onChange={handleFormOnchange}
                                                    />
                                                </div>
                                                {/* ))} */}

                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Bookingtruck No</Form.Label>
                                                    <Form.Control type="email" placeholder="#12134567" name='bookingtruck_no' value={formData.bookingtruck_no} onChange={handleFormOnchange} />
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-2">
                                                    <Col sm="6">
                                                        <Form.Label>Boardpoint</Form.Label>
                                                        {/* {console.log('rty', boardpoint)} */}
                                                        <div className='autosugg'>
                                                            {/* <MultiSelect
                                                            options={boardpoint}
                                                            name='airline_conserned'
                                                            value=''
                                                            // multiple={false}
                                                            optionLabel="orgName"
                                                            optionValue="id"
                                                            // onChange={(selected) => {
                                                            //     const updatedData = { ...localFormData, airline_conserned: selected };
                                                            //     setLocalFormData(updatedData);
                                                            //     onFormDataChange2(updatedData);
                                                            // }}
                                                            labelledBy="Select"
                                                            /> */}
                                                            <Autosuggest
                                                                suggestions={suggestionsBp}
                                                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                                getSuggestionValue={getSuggestionValue}
                                                                renderSuggestion={renderSuggestion}
                                                                inputProps={inputProps}
                                                                onSuggestionSelected={(event, { suggestion }) => { onSelectBpaddress(suggestion); setSelectedBoardpoint(suggestion); }}
                                                            />
                                                            {showAddButton && <Button onClick={() => onAddButtonClick("bp")}> + Add Stations </Button>}
                                                            {/* {showAddStation && <AddStation />} */}

                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Form.Label>Boardpoint Address {showAddAddressButton && <Button className='raad_adrss' onClick={() => handleAddAddressClick('bp', selectedBoardpoint)}>+ Add</Button>}</Form.Label> {/* > */}
                                                        <Form.Select aria-label="Default select example" value={formData.boardpoint_address || ''} onChange={handleBPAddressChange}>
                                                            {/* {console.log('bpaddress',bpaddress)} */}
                                                            <option>Select Address</option>
                                                            {bpaddress &&
                                                                // console.log(suggestions.name)
                                                                bpaddress.map((option, index) => (
                                                                    <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                                                                ))
                                                            }
                                                            {/* {bpaddress && <option value="AddAddress" onClick={handleAddAddressClick} className="add-address-option">+ Add Address</option>} */}
                                                        </Form.Select>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-2">
                                                    <Col sm="6">
                                                        <Form.Label>Offpoint</Form.Label>
                                                        {/* <Form.Control type="email" placeholder="Select Offpoint" /> */}
                                                        <div className='autosugg'>
                                                            <Autosuggest
                                                                suggestions={suggestionsOffpoint}
                                                                onSuggestionsFetchRequested={onSuggestionsFetchRequestedOffpoint}
                                                                onSuggestionsClearRequested={onSuggestionsClearRequestedOffpoint}
                                                                getSuggestionValue={getSuggestionValueOffpoint}
                                                                renderSuggestion={renderSuggestionOffpoint}
                                                                inputProps={{ placeholder: 'Type a Stations', value: valueOffpoint, onChange: onChangeOffpoint }}
                                                                onSuggestionSelected={(event, { suggestion }) => { onSelectOffpoint(suggestion); setSelectedOffpointId(suggestion); }}
                                                            />
                                                            {showAddButtonOffpoint && <Button onClick={() => onAddButtonClick("op")}> + Add Stations </Button>}
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Form.Label>Offpoint Address  {showAddAddressButton2 && <Button className='raad_adrss' onClick={() => handleAddAddressClick('op', selectedOffpointId)}>+ Add</Button>}</Form.Label>
                                                        <Form.Select aria-label="Default select example" value={formData.offpoint_address || ''} onChange={handleOPAddressChange}>
                                                            <option>Select Address</option>
                                                            {selectedOffpoint &&
                                                                selectedOffpoint.map((option, index) =>
                                                                    <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                    </Col>
                                                </Form.Group>
                                                <hr />

                                                {/* {['radio'].map((type) => ( */}
                                                <div className="mb-2">
                                                    <Form.Check
                                                        inline
                                                        label="One-Off"
                                                        name="trip_type"
                                                        type='radio'
                                                        id='one-off'
                                                        value="O"
                                                        checked={frequentBooking === 'O'}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Frequent"
                                                        name="trip_type"
                                                        type='radio'
                                                        id='Frequent'
                                                        value="F"
                                                        checked={frequentBooking === 'F'}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                {/* ))} */}

                                                {frequentBooking === 'O' && (
                                                    <>
                                                        <Form.Group as={Row}>
                                                            <Form.Label>Date and time</Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control type="date" name='date' value={formData.date} onChange={handleFormOnchange} />
                                                            </Col>
                                                            <Col sm="6">
                                                                <Form.Control type="time" name='time' value={formData.time} onChange={handleFormOnchange} />
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )}
                                                {frequentBooking === 'F' && (
                                                    <>
                                                        <Form.Label>Select Weekdays</Form.Label>

                                                        <ul className='freq_week mb-3'>
                                                            <li className={selected.includes(0) ? 'selected' : ''} onClick={() => handleClick(0)}>Su</li>
                                                            <li className={selected.includes(1) ? 'selected' : ''} onClick={() => handleClick(1)}>Mo</li>
                                                            <li className={selected.includes(2) ? 'selected' : ''} onClick={() => handleClick(2)}>Tu</li>
                                                            <li className={selected.includes(3) ? 'selected' : ''} onClick={() => handleClick(3)}>We</li>
                                                            <li className={selected.includes(4) ? 'selected' : ''} onClick={() => handleClick(4)}>Th</li>
                                                            <li className={selected.includes(5) ? 'selected' : ''} onClick={() => handleClick(5)}>Fr</li>
                                                            <li className={selected.includes(6) ? 'selected' : ''} onClick={() => handleClick(6)}>Sa</li>
                                                        </ul>


                                                        <Form.Group as={Row} className='mb-2'>
                                                            <Form.Label>Start Date & End Date</Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control type="date" name='start_date' value={formData.start_date} onChange={handleFormOnchange} />
                                                            </Col>
                                                            <Col sm="6">
                                                                <Form.Control type="date" name='end_date' value={formData.end_date} onChange={handleFormOnchange} />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} >
                                                            <Form.Label>Select Time</Form.Label>
                                                            {/* <Col sm="6">
                                                                <Form.Control type="date" name='freq_date' value={formData.freq_date} onChange={handleFormOnchange} />
                                                            </Col> */}
                                                            <Col sm="6">
                                                                <Form.Control type="time" name='freq_time' value={formData.freq_time} onChange={handleFormOnchange} />
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )}

                                            </Form>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={handleAddBookingtruck}  >
                                                {formData.booking_type == 'E' ? 'Edit' : 'Add'} Bookingtruck
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {selectedButton === 'export' &&
                                        <Tabs defaultActiveKey="incoming" id="nested" className="booking_tab" onSelect={handleTabSelect}>
                                            <Tab eventKey="incoming" title={<span>Active</span>}>
                                                <ExportActive onEdit={handleEdit} ref={childRef} updateGridData={exportGrid} />
                                            </Tab>
                                            <Tab eventKey="trash" title={<span>Trash</span>}>
                                                <ExportTrash onEdit={handleEdit} ref={childRef} updateGridData={exportGrid} />
                                            </Tab>
                                        </Tabs>
                                    }
                                    {selectedButton === 'import' &&
                                        <div>BtsImport</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingtruckSchedule