import React  from "react";
import '../../App.css';
import Header from "../../Componenets/Header/Header";
import LeftMenu from "../../Componenets/LeftMenu/LeftMenu";
import Crud from "../../Componenets/CRUD/Crud";
// import JGrid from "../../Componenets/JGrid/JGrid";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBoxArchive } from '@fortawesome/free-solid-svg-icons';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import JGrid from "../../Componenets/JGrid/JGrid";
// import JGrid from './JGrid';



const Stations = () =>{

    


    return(
        <>
        <div className="container-scroller dashboard">
            <Header />
            <div className="container-fluid d-flex p-0 page-body-wrapper">
                <LeftMenu />
                <div className="main-panel rounded">
                    <div className="mainTab">
                        <Crud />
                                                   
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}
export default Stations;