import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import { withoutRFSToken, withRFSToken } from '../../../../Services/axios.js';
import { GETTYPECODE, GETOWNERCODE, GETCONDITIONCODE, EDITULDSTOCK } from '../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const EditUld = ({ onClose, reloadEdit, edit_data }) => {
    const [modalShow, setModalShow] = useState(true);
    const [editFormData, setEditFormData] = useState({});

    const [getTypeCodes, setGetTypeCodes] = useState([]);
    const [getOwnerCodes, setGetOwnerCode] = useState([]);
    const [getCondCodes, setGetCondCode] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {

                const res = await withRFSToken.post(GETTYPECODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        const transformedData = innerData ? innerData.map(item => ({
                            value: item.uld_typecode,
                            label: item.uld_typecode
                        })) : [];
                        setGetTypeCodes(transformedData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchOwnerCode = async () => {
            try {
                const res = await withRFSToken.post(GETOWNERCODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        const transformedData = innerData ? innerData.map(item => ({
                            value: item.IATA,
                            label: item.IATA
                        })) : [];
                        setGetOwnerCode(transformedData);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchCondCode = async () => {
            try {

                const res = await withRFSToken.post(GETCONDITIONCODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        setGetCondCode(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        fetchOwnerCode();
        fetchCondCode();
        setEditFormData(edit_data)
    }, []);

    const handleEditChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    const handleEditSave = async () => {
        const dataToSend = { ...editFormData, asset_type: editFormData.asset_type == true ? 'N' : 'Y' };
        try {
            const res = await withRFSToken.post(EDITULDSTOCK, dataToSend)
                .then(function (res) {
                    if (res.data.response_code == 200) {
                        toast.success(res.data.response_msg)
                        reloadEdit();
                        handleModalClose();
                    } else {
                        toast.error(res.data.response_msg);
                    }
                });

        } catch (error) {
            console.error('Error sending request:', error);
        }
    }

    const handleModalClose = () => {
        setModalShow(false);
        setEditFormData({});
        onClose();
    };
    return (
        <>{console.log('222', editFormData)}
            <Modal show={modalShow} onHide={handleModalClose} className='addfrom change_type editculm' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit ULD - <span>{editFormData.uld_number}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm='12' className='mb-2'>
                            <Form.Label className='form-label'>Type Code</Form.Label>
                            <Select
                                options={getTypeCodes}
                                onChange={(selected) => {
                                    const updatedData = { ...editFormData, type_code: selected.value };
                                    setEditFormData(updatedData);
                                }}
                                placeholder="Type Code"
                                className='autoslct'
                                name="type_code"
                                value={getTypeCodes.find(option => option.value === editFormData.type_code)}
                            />
                        </Col>
                        <Col sm='12' className='mb-2'>
                            <Form.Label className='form-label'>Serial Number </Form.Label>
                            <Form.Control type="text" value={editFormData.serial_number} name='serial_number' onChange={handleEditChange} />
                        </Col>
                    </Row>
                    <Row >
                        <Col sm='12' className='mb-2'>
                            <Form.Label className='form-label'>Owner Code</Form.Label>
                            <Select
                                options={getOwnerCodes}
                                onChange={(selected) => {
                                    const updatedData = { ...editFormData, owner_code: selected.value };
                                    setEditFormData(updatedData);
                                }}
                                placeholder="Owner Code"
                                className='autoslct'
                                name="owner_code"
                                value={getOwnerCodes.find(option => option.value === editFormData.owner_code)}
                            />
                        </Col>
                        {/* <Col sm='12' className='mb-2'>
                            <Form.Label className='form-label'>Condition Code</Form.Label>
                            <Form.Select aria-label="Default" value={editFormData.condition_code} name="condition_code" onChange={handleEditChange}>
                                {getCondCodes &&
                                    Object.entries(getCondCodes).map((option, index) =>
                                        <option key={index} value={option[0]}>{option[1]}</option>
                                    )
                                }
                            </Form.Select>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col sm='12'>
                        <Form.Label className='form-label'>Asset Type</Form.Label>
                            <div className='chart_switch'>
                                <div className="switch6">
                                    <label className="switch6-light">
                                        <input type="checkbox"
                                            name="asset_type"
                                            checked={editFormData.asset_type}
                                            onChange={handleEditChange} />
                                        <span>
                                            <span>Owned</span>
                                            <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="primary" onClick={handleEditSave}>
                            Update ULD
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditUld;