import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargotruck from "../../../assets/images/cargo-truck.png";
import Cuser from "../../../assets/images/cuser.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { UCMHISTORYGRID } from '../../../Utils/conts.js';

const SCM = () => {
    const isMounted = useRef(false);
    const [gridData, setGridData] = useState([]);
    const [totalRows, setTotalRows] = useState({});
    const fetchDataGrid = async () => {
        try {
            const res = await withRFSToken.post(UCMHISTORYGRID, {history_type: 'scm'})
                .then(function (res) {
                    const innerData = res.data.response_data.ulds;
                    const innerDataCount = res.data.response_data.pagination;
                    setGridData(innerData);
                    setTotalRows({
                        pageSize: innerDataCount.page_size,
                        currPage: innerDataCount.current_page,
                        lastPage: innerDataCount.last_page,
                        totalUlds: innerDataCount.total
                    });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        // Call the fetchData function to fetch data from the API
        if (isMounted.current) return;
        fetchDataGrid();
        isMounted.current = true;
    }, [])
    //     [
    //         {
    //             mvt_type: 'O', timestamp: '14:45', flight_truck: 'flight', flight_number: 'ZZZ-111', flight_date: '14-05-2024',
    //             flight_reg_number: '21325548', origin: 'ACC', destination: 'AGF', handler_name: 'DemoHandler', airline_name: 'Campus Airline',
    //             uld_info: [
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' }
    //             ]
    //         },
    //         {
    //             mvt_type: 'O', timestamp: '14:45', flight_truck: 'flight', flight_number: 'ZZZ-111', flight_date: '14-05-2024',
    //             flight_reg_number: '21325548', origin: 'ACC', destination: 'AGF', handler_name: 'DemoHandler', airline_name: 'Campus Airline',
    //             uld_info: [
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' }
    //             ]
    //         }, {
    //             mvt_type: 'O', timestamp: '14:45', flight_truck: 'flight', flight_number: 'ZZZ-111', flight_date: '14-05-2024',
    //             flight_reg_number: '21325548', origin: 'ACC', destination: 'AGF', handler_name: 'DemoHandler', airline_name: 'Campus Airline',
    //             uld_info: [
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' }
    //             ]
    //         }, {
    //             mvt_type: 'O', timestamp: '14:45', flight_truck: 'flight', flight_number: 'ZZZ-111', flight_date: '14-05-2024',
    //             flight_reg_number: '21325548', origin: 'ACC', destination: 'AGF', handler_name: 'DemoHandler', airline_name: 'Campus Airline',
    //             uld_info: [
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' }
    //             ]
    //         }, {
    //             mvt_type: 'O', timestamp: '14:45', flight_truck: 'flight', flight_number: 'ZZZ-111', flight_date: '14-05-2024',
    //             flight_reg_number: '21325548', origin: 'ACC', destination: 'AGF', handler_name: 'DemoHandler', airline_name: 'Campus Airline',
    //             uld_info: [
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'R' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' },
    //                 { uld_number: 'AA41231235ZZZ', type_code: 'AA4', serial_number: '1231235', condition_code: 'C-General', content_code: 'Damage', uld_asser: 'N' }
    //             ]
    //         },
    //     ]

    // );
    const [selectedRowData, setSelectedRowData] = useState(null);
    // const [gridSelect, setGridSelect] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {

        if (gridRef.current) {
            if (gridData) {
                // const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                // }));
                // console.log("🚀 ~ localData ~ localData:", localData)
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'timestamp', type: 'date' },
                        { name: 'destination', type: 'string' },
                        { name: 'destination_handler', type: 'string' },
                    ],
                    localdata: gridData,
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                console.log('localdata', source.localdata)
                const columns = [
                    { text: 'Date Check', filtertype: 'date', cellsformat: 'yyyy-MM-dd', datafield: 'timestamp', width: '11%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '10%', cellclassname: '', },
                    { text: 'Destination Handler Name', filtertype: 'textbox', datafield: 'destination_handler', width: '10%', cellclassname: '', },
                ];

                // Nested ULD Start
                const nestedColumns = [
                    { text: 'ULD Number', datafield: 'uld_number', width: '12%' },
                    { text: 'Type Code', datafield: 'type_code', width: '12%' },
                    { text: 'Serial Number', datafield: 'serial_number', width: '12%' },
                    { text: 'Owner Code', datafield: 'owner_code', width: '12%' },
                    { text: 'Condition Code', datafield: 'condition_code', width: '12%' },
                    { text: 'Final Destination', datafield: 'final_dest', width: '12%' },
                    { text: 'ULD Asset', datafield: 'uld_asset', width: '12%' },
                    // Add more columns as needed
                ];
                const initrowdetails = (index, parentElement, gridElement, record) => {
                    console.log("🚀 ~ initrowdetails ~ parentElement:", parentElement)

                    const nestedGridContainer = parentElement.children[0];

                    parentElement.addEventListener('click', (event) => {
                        const target = event.target;
                        console.log("🚀 ~ parentElement.addEventListener ~ target:", target)

                        // Check if the click target is the nested arrow
                        if (window.$(target).hasClass('jqx-icon-arrow-right')) {
                            // Handle nested arrow click here
                            console.log('Nested arrow clicked:', record);
                            event.stopPropagation(); // Prevent event bubbling to the row details
                        }
                    });

                    const orders = [
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Non-Registered'
                        },
                        {
                            uld_number: 'AKE1746XY', type_code: 'AKE', serial_number: '1746',
                            condition_code: 'Serviceable', content_code: 'C-General', uld_asset: 'Non-Registered'
                        },
                    ];

                    const nestedSource = {
                        datatype: 'json',
                        id: 'shipmentChildID',
                        datafields: [
                            { name: 'uld_number', type: 'string' },
                            { name: 'type_code', type: 'string' },
                            { name: 'serial_number', type: 'string' },
                            { name: 'owner_code', type: 'string' },
                            { name: 'condition_code', type: 'string' },
                            { name: 'final_dest', type: 'string' },
                            { name: 'uld_asset', type: 'string' },
                        ],
                        localdata: orders,
                    }

                    const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);
                    console.log("🚀 ~ initrowdetails ~ nestedDataAdapter:", nestedDataAdapter);


                    const nestedGridOptions = {
                        width: '100%',
                        pageable: false,
                        sortable: false,
                        autoheight: true,
                        height: (15 * 45),
                        source: nestedDataAdapter,
                        filterable: false,
                        columns: nestedColumns,
                        rowdetails: true,
                        rowsheight: 45,
                    };
                    window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
                };
                // Nested End

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: true,
                    editable: false,
                    // selectionmode: 'checkbox',
                    initrowdetails: initrowdetails,
                    pageable: true,
                    pageSize: 10,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    rowdetails: true,
                    rowdetailstemplate: {
                        rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
                        rowdetailsheight: 300,
                        rowdetailshidden: true,
                    },

                };

                window.$(gridRef.current).jqxGrid(gridOptions);
                window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

                window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
    }, [gridData]);

    const exportToExcel = () => {
        if (gridRef.current) {
            window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
        }
    };

    return (
        <>
            {/* <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex justify-content-between'>

                </div>
            </div>
            <div className='ulddown'>
                <button onClick=''> <img src={Arcv} /> Archive</button>
                <button onClick={exportToExcel}><img src={Export} /> Download</button>
            </div> */}

            <div className="maindiv">
                <div className='trashdonload'>
                    <div ref={gridRef} className='bookinggrid' ></div>
                </div>
            </div>
        </>
    )
}

export default SCM