import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { CreateReportFormOne, CreateReportFormTwo, CreateReportFormThree } from './CreateReportForm';

const steps = ['Airway Bill & Flight Information', 'Damage Information', 'Investigation Responsibility', 'Shipment Information', 'Root Cause', 'Upload Pictures & Documents', 'Done']; // Define your steps

const FormStepper = () => {

    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        // const selElm = document.querySelector('span.Mui-active');
        const activeStepElement = document.querySelector('.Mui-active');
        if(activeStepElement){
            // selElm.parentElement.parentElement.classList.add('Mui-active');
            activeStepElement.closest('.MuiStep-root').classList.add('stp-active');
        }
        
    }); 

  return (
    <>
    <div className='st-f'>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>All steps completed</Typography>
          </div>
        ) : (
          <div>
            <Typography>Step {activeStep + 1}</Typography>
            {/* Render different form components based on the active step */}
            {/* Example: */}
            {activeStep === 0 && <CreateReportFormOne />}
            {activeStep === 1 && <CreateReportFormTwo />}
            {activeStep === 2 && <CreateReportFormThree />}
            {activeStep === 3 && <CreateReportFormOne />}
            {activeStep === 4 && <CreateReportFormTwo />}
            {activeStep === 5 && <CreateReportFormThree />}
            {activeStep === 6 && <CreateReportFormOne />}
            {/* End of example */}
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}

export default FormStepper;