import React, { useState } from 'react';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRotateRight, faFilePdf, faFileExcel} from '@fortawesome/free-solid-svg-icons';
import Header from "../../Componenets/Header/Header";
import LeftMenu from "../../Componenets/LeftMenu/LeftMenu";
import TopMenu from "../../Componenets/TopMenu/TopMenu";
import Stepper from '../../Componenets/Stepper/Stepper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import JGrid from "../../Componenets/JGrid/JGrid";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const NestedTabs = () => {

  return (
      <>
      <Tabs defaultActiveKey="incoming" id="nested" className="mb-3 nestedtabs">
          <Tab eventKey="incoming" title={<span>Incoming<span>157</span></span>}>
              
              <div className="content rounded">
                  <JGrid />
              </div>
          </Tab>
          <Tab eventKey="reports" title={<span>Reports<span>17</span></span>}>
              <div className="content rounded">
                  <p> this is admin dashboard 2</p>
              </div>
          </Tab>
          <Tab eventKey="internal" title={<span>Internal<span>0</span></span>}>
              <div className="content rounded">
                  <p> this is admin dashboard 3</p>
              </div>
          </Tab>
          <Tab eventKey="archived" title={<span>Archived<span>0</span></span>}>
              <div className="content rounded">
                  <p> this is admin dashboard 4</p>
              </div>
          </Tab>
          <Tab eventKey="trash" title={<span>Trash<span>0</span></span>}>
              <div className="content rounded">
                  <p> this is admin dashboard 4</p>
              </div>
          </Tab>
      </Tabs> 
      </>
  );
};

const CargoDamage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <div className="container-scroller">
            <Header />
            <div className="container-fluid d-flex p-0 page-body-wrapper">
                <LeftMenu />
                <div className="main-panel rounded">
                    <div className="mainTab">
                        <TopMenu />
                        <div className="">
                          <div className="allcontent-fl">
                              <div className="rcontent">
                                  <button onClick={handleShow}><FontAwesomeIcon icon={faPlus} /> Create Report</button>
                                  <select>
                                      <option value="">Action</option>
                                      <option value=""> Send</option>
                                      <option value=""> On Hold</option>
                                      <option value=""> Archive</option>
                                      <option value=""> Delete</option>
                                  </select>
                                  <button><FontAwesomeIcon icon={faRotateRight} />Refresh</button>
                                  <button><FontAwesomeIcon icon={faFilePdf} /></button>
                                  <button><FontAwesomeIcon icon={faFileExcel} /></button>

                              </div>
                              <div className="lcontent"><NestedTabs /></div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg">
            {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
            <Modal.Body closeButton><Stepper /></Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
        </Modal>
    </>
  )
}

export default CargoDamage;