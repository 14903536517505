import React, { useState, useEffect, useRef } from 'react';
import 'jqwidgets-framework/jqwidgets/jqxcore';
import 'jqwidgets-framework/jqwidgets/jqxdata';
import 'jqwidgets-framework/jqwidgets/jqxbuttons';
import 'jqwidgets-framework/jqwidgets/jqxscrollbar';
import 'jqwidgets-framework/jqwidgets/jqxmenu';
import 'jqwidgets-framework/jqwidgets/jqxgrid';
import 'jqwidgets-framework/jqwidgets/jqxgrid.pager';
import 'jqwidgets-framework/jqwidgets/jqxgrid.sort';
import 'jqwidgets-framework/jqwidgets/jqxgrid.edit';
import 'jqwidgets-framework/jqwidgets/jqxgrid.filter';
import 'jqwidgets-framework/jqwidgets/jqxgrid.selection';
import 'jqwidgets-framework/jqwidgets/jqxgrid.columnsresize';
import 'jqwidgets-framework/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-framework/jqwidgets/styles/jqx.dark.css';
import $ from 'jqwidgets-framework/jqwidgets/jqx-all';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faXmark, faCircleCheck, faBolt, faPaperclip, faCircleXmark, faPlus, faEllipsis, faChevronLeft, faChevronRight, faLink } from '@fortawesome/free-solid-svg-icons';

import JqxTreeGrid from 'jqwidgets-framework/jqwidgets-react-tsx/jqxtreegrid';
import 'jqwidgets-scripts/jqwidgets/jqxtreegrid';
import Trash from "../../../assets/images/delete.svg";
import Export from "../../../assets/images/export.svg";
import Cargotruck from "../../../assets/images/cargo-truck.png";
import Cuser from "../../../assets/images/cuser.png";

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Editor from 'react-simple-wysiwyg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TRUCKORDERLIST } from '../../../Utils/conts';
import { withRFSToken } from '../../../Services/axios';



// import jsPDF from 'jspdf';


const TruckActive = ({ updateGridData }) => {

  // const myGridRef = useRef(null);
  // let gridElement = null;


  const [showSecondDiv, setShowSecondDiv] = useState(false);
  const toggleSecondDiv = () => {
    setShowSecondDiv(!showSecondDiv);
  };

  // +++++++++++++++++++ div +++++++++++++++++++++++++

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload 

  // const [html, setHtml] = useState('');

  // function onChange(e) {
  //   setHtml(e.target.value);
  // }



  // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,

  };

  // +++++++++++++++++++++++++++++++++++ Silk Slider


  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFile('');
  };

  // ++++++++++++++++++++++++++++++++++++ Zoom Image


  const gridRef = useRef(null);
  // const treeGridRef = useRef(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  // new data for dynamic
  // const [gridData, setGridData] = useState([
  //   {
  //     OTN: '#2154', BTN: '#123546', Status: 'r', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#223546', Status: 'o', Date: '14-11-2024', Time: '16:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#323546', Status: 'b', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#423546', Status: 'o', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#523546', Status: 'r', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#623546', Status: 'r', Date: '14-11-2024', Time: '15:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#723546', Status: 'b', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#723546', Status: 'b', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#723546', Status: 'b', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  //   {
  //     OTN: '#2154', BTN: '#723546', Status: 'b', Date: '14-11-2024', Time: '14:00',
  //     Boardpoint: 'BRU', Offpoint: 'AMS'
  //   },
  // ]);

  // useEffect(() => {      ++++++++++ uncomment after api works
  //   if (updateGridData) {
  //     setGridData(updateGridData.active);
  //   }
  //   else {
  //     console.log('No Data');
  //   }
  // }, [updateGridData]);

  //   console.log("NNN", gridData);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    console.log("🚀 ~ ExportActive ~ gridData:", gridData)
    truckOrderList();
    if (gridRef.current) {

      const source = {
        datatype: 'json',
        datafields: [
          // { name: 'selected', type: 'bool' },
          { name: 'OTN', type: 'string' },
          { name: 'BTN', type: 'string' },
          { name: 'Status', type: 'string' },
          { name: 'RStatus', type: 'string' },
          { name: 'Date', type: 'string' },
          { name: 'Time', type: 'string' },
          { name: 'Boardpoint', type: 'string' },
          { name: 'Offpoint', type: 'string' },
        ],
        localdata: gridData,
      };
      const dataAdapter = new window.$.jqx.dataAdapter(source);

      const columns = [
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },filtertype: 'bool',
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },
        { text: 'Operational Truck No.', filtertype: 'textbox', datafield: 'OTN', width: '18%' },
        { text: 'Bookingtruck No.', filtertype: 'textbox', datafield: 'BTN', width: '16%' },
        // { text: 'Status', filtertype: 'textbox', datafield: 'Status', width: '17%', },
        {
          text: 'Status',
          filtertype: 'textbox',
          datafield: 'Status',
          width: '14%',
          cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
            // Check the value of the 'Status' field
            if (rowdata.Status === 'r') {
              // Return HTML with class 'inactive' for 'i' status
              return '<div class="jqx-grid-cell-left-align bookingstatus requested">Requested</div>';
            } else if (rowdata.Status === 'o') {
              // Return HTML with class 'active' for 'a' status
              return '<div class="jqx-grid-cell-left-align bookingstatus offered">Offer Received</div>';
            } else if (rowdata.Status === 'b') {
              // Return HTML with class 'active' for 'a' status
              return '<div class="jqx-grid-cell-left-align bookingstatus booked">Booked</div>';
            } else {
              // Return default HTML
              return defaulthtml;
            }
          }
        },
        { text: 'Date', filtertype: 'textbox', datafield: 'Date', width: '12%' },
        { text: 'Time', filtertype: 'textbox', datafield: 'Time', width: '12%' },
        { text: 'Boardpoint', filtertype: 'textbox', datafield: 'Boardpoint', width: '12%' },
        { text: 'Offpoint', filtertype: 'textbox', datafield: 'Offpoint', width: '12%' },
      ];

      const gridOptions = {
        width: '100%',
        source: dataAdapter,
        columns: columns,
        autoheight: true,
        sortable: true,
        altrows: false,
        enabletooltips: true,
        editable: false,
        selectionmode: 'checkbox',
        pageable: true,
        pageSize: 9,
        pagerButtonsCount: 5,
        pagerMode: 'advanced',
        filterable: true,
        filterrowheight: 45,
        showfilterrow: true,
        rowsheight: 50,
        columnsheight: 45,
      };


      window.$(gridRef.current).jqxGrid(gridOptions);
      window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

      window.$(gridRef.current).on('rowclick', (event) => {
        // console.log('event', event.args.row.bounddata);
        const args = event.args.row.bounddata;

        // console.log('args', args);
        // const rowData = dataAdapter.records[args.index];
        // console.log('rowdata', rowData);
        setSelectedRowData(args);
        window.$(gridRef.current).jqxGrid('autoresizecolumns');
      });

    }

  }, [selectedRowData]);
  const truckOrderList = async () => {
    try {

      const res = await withRFSToken.post(TRUCKORDERLIST, { type: 'A' })
        .then(function (res) {
          const innerData = res.data.response_data;
          setGridData(innerData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCloseClick = () => {
    setSelectedRowData(null);
  };
  console.log("🚀 ~ handleCloseClick ~ setSelectedRowData:", selectedRowData)

  // const reloadGrid = async () => {
  //   try {
  //       const res = await axios.post(process.env.REACT_APP_BASE_URL + DASHBOARD, {}, {
  //           headers: {
  //               'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //               'Content-Type': 'application/json'
  //           }
  //       });
  //       const innerData = res.data.data;
  //       setPlanData(innerData);
  //   } catch (error) {
  //       console.error('Error reloading data:', error);
  //   }
  // };

  const exportToExcel = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
    }
  };
  const exportToPDF = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'pdf', 'GridData');
    }
  };

  return (
    <>
      <div className='exp-btn'>
        <button onClick={exportToExcel}><img src={Trash} /> Move to Trash</button>
        <button onClick={exportToPDF}><img src={Export} /> Download</button>
      </div>
      <div className="maindiv">

        <div className='trashdonload'>

          <div ref={gridRef} className='bookinggrid'></div>
        </div>

        {/* Display the selected row data */}
        {selectedRowData && (
          <div className='SecondDiv'>
            <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
            <div className='girddtals_header'>
              <h3>
                {selectedRowData.BTN}
                <span className='left_rqst'>
                  {
                    selectedRowData.Status == 'r' ? (
                      <label class="bookingstatus requested">Requested</label>
                    ) : selectedRowData.Status == 'o' ? (
                      <label class="bookingstatus offered">Offer Received</label>
                    ) : selectedRowData.Status == 'b' ? (
                      <label class="bookingstatus booked">Booked</label>
                    ) : ''
                  }
                </span>
              </h3>
              <div className='d-flex actbtn'>

                <Button variant="light" onClick={handleBrowseFiles} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBolt} /> Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Edit Bookingtruck</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Manage Shipments</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Order Truck</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>

            </div>
            <div className='d-flex justify-content-between rabord'>
              <div className='firstbrd'>
                <p>Boardpoint</p>
                <span>{selectedRowData.Boardpoint}</span>
              </div>
              <div className='middlebrd'>
                <p>{selectedRowData.Date}</p>
                <p><img src={Cargotruck} /></p>
                <p>{selectedRowData.Time}</p>
              </div>
              <div className='lastbrd'>
                <p>Offpoint</p>
                <span>{selectedRowData.Offpoint}</span>
              </div>
            </div>
            <div>
              {/* <h3>Operational Trucks</h3> */}
              <div className='opttrauck mb-3'>
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Truck Informaion</Accordion.Header>
                    <Accordion.Body>
                      <ul className='truck_info'>
                        <li><span>Type :</span> <span>Booking Truck</span></li>
                        <li><span>Truck Load  :</span> <span>FLT</span></li>
                        <li><span>Temperature  :</span> <span>17°C - 19°C</span></li>
                        <li><span>Remarks :</span> <span>Remarks</span></li>
                        <li><span>ADR Required  :</span> <span>YES</span></li>
                        <li><span>Number off Drivers  :</span> <span>1</span></li>
                        <li><span> Boardpoint Address  :</span> <span>Address</span></li>
                        <li><span>Type work :</span> <span>Export</span></li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="1">
        <Accordion.Header>Trucks No : #12345679</Accordion.Header>
        <Accordion.Body>
        <ul className='truck_info'> 
            <li><span>Truck Load :</span> <span>FLT</span></li>
            <li><span>Temperature :</span> <span>17°C - 19°C</span></li>
            <li><span>Number off Drivers :</span> <span>1</span></li>
            <li><span>ADR Required :</span> <span>YES</span></li>
            <li><span>Remarks :</span> <span>Remarks</span></li>
          </ul> 
        </Accordion.Body>
      </Accordion.Item> */}
                </Accordion>
              </div>
            </div>

            <div className='mb-3'>
              <div className='d-flex justify-content-between align-items-center mb-2'>
                <h3 className='mb-0'>Truck Request</h3>
                <h4 className='manag_offer'> Manage Offers <span>3 </span></h4>
              </div>

              <div class="d-flex justify-content-between align-items-center offerbg">
                <div><span>Trucker Name</span><h4>Trucker 1</h4></div>
                <div className='received'><span>Offer Received</span></div>
                <div></div>
              </div>

            </div>

            <div className='mb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3 className='mb-0'>Attachments</h3>
                <div className='d-flex rightdrop'>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Open gallery view</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Download all</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <button className='raplas' onClick={handleBrowseFiles}><FontAwesomeIcon icon={faPlus} /></button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      multiple
                    />


                  </div>
                </div>
              </div>

              <div className='raslider'>
                <div className="slider-container">
                  {
                    files.length > 3 ?
                      <Slider {...settings}>
                        {files.map((file, index) => (
                          <div key={index} className='slideimg'>
                            {file.type.startsWith('image/') ? (
                              <>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt="Preview"
                                  style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                  onClick={() => handleFileClick(URL.createObjectURL(file))}
                                />
                                <span className='rafilename'>{file.name}</span>
                              </>
                            ) : (
                              <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                            )}
                            <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                          </div>
                        ))}
                      </Slider>
                      :
                      <>
                        <div className='nonslide'>
                          {files.map((file, index) => (
                            <div key={index} className='slideimg'>
                              {file.type.startsWith('image/') ? (
                                <>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt="Preview"
                                    style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                    onClick={() => handleFileClick(URL.createObjectURL(file))}
                                  />
                                  <span className='rafilename'>{file.name}</span>
                                </>
                              ) : (
                                <span className='d-flex rafilename'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                              )}
                              <button className='slide_dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                            </div>
                          ))}
                        </div>
                      </>
                  }


                </div>

                <Modal show={show} onHide={handleClose} centered className='imgmodal'>
                  <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                  <Modal.Body >
                    {console.log(selectedFile)}
                    <img src={selectedFile} className="img-fluid" alt="Selected" />
                  </Modal.Body>
                </Modal>

              </div>

            </div>

            <div>
              <h3>Activity</h3>

              <div className='activity'>
                <span>Show :</span>
                <Button variant="link">All</Button>
                <Button variant="link active">Comments</Button>
                <Button variant="link">History</Button>
              </div>
              <div className='activity'>
                <span>Truck :</span>
                <Button variant="link">All</Button>
                <Button variant="link">#123</Button>
                <Button variant="link">#124</Button>
                <Button variant="link">#125</Button>
                <Button variant="link">#126</Button>
              </div>
            </div>
            <div className='mt-4'>
              <div>
                <div className='d-flex comntbox'>
                  <div>
                    <span className='user_com'>RS</span>
                  </div>
                  <div className='edithtml'>
                    <CKEditor
                      editor={ClassicEditor}
                      data="<p> Hello </p>"
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event) => {
                        console.log(event);
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    />
                  </div>
                </div>
                <div className='commnt_save'>
                  <Button variant="primary rasave">Save</Button>
                  <Button variant="link racancel">Cancel</Button>
                </div>
              </div>
              <div className='d-flex racomment'>
                <div className='coment_img'><img src={Cuser} /></div>
                <div>
                  <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> </div>
                  <p>I have no reply, so i will take this ticket to next week</p>
                </div>
              </div>

              <div className='d-flex racomment'>
                <div className='coment_img'><img src={Cuser} /></div>
                <div>
                  <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                  <p>I have no reply, so i will take this ticket to next week</p>
                </div>
              </div>

              <div className='d-flex racomment'>
                <div className='coment_img'><img src={Cuser} /></div>
                <div>
                  <div className='d-flex'> <h3>Patricia Modispacher </h3> <span>3 days ago</span> <Button variant="link"><FontAwesomeIcon icon={faLink} /></Button> </div>
                  <p>I have no reply, so i will take this ticket to next week</p>
                </div>
              </div>

            </div>

          </div>




        )}
      </div>


    </>
  );
}
export default TruckActive;
// export {JGrid as default, exportToPDF}