import React, { useState, useEffect, useRef } from 'react';
import 'jqwidgets-framework/jqwidgets/jqxcore';
import 'jqwidgets-framework/jqwidgets/jqxdata';
import 'jqwidgets-framework/jqwidgets/jqxbuttons';
import 'jqwidgets-framework/jqwidgets/jqxscrollbar';
import 'jqwidgets-framework/jqwidgets/jqxmenu';
import 'jqwidgets-framework/jqwidgets/jqxgrid';
import 'jqwidgets-framework/jqwidgets/jqxgrid.pager';
import 'jqwidgets-framework/jqwidgets/jqxgrid.sort';
import 'jqwidgets-framework/jqwidgets/jqxgrid.edit';
import 'jqwidgets-framework/jqwidgets/jqxgrid.filter';
import 'jqwidgets-framework/jqwidgets/jqxgrid.selection';
import 'jqwidgets-framework/jqwidgets/jqxgrid.columnsresize';
import 'jqwidgets-framework/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-framework/jqwidgets/styles/jqx.dark.css';
import $ from 'jqwidgets-framework/jqwidgets/jqx-all';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faEdit } from '@fortawesome/free-solid-svg-icons';
import Trash from "../../assets/images/delete.svg";
import Arcv from "../../assets/images/archive.svg";
import Export from "../../assets/images/export.svg";
import Rapdf from "../../assets/images/pdf.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from "axios";


import { withoutToken, withToken } from '../../Services/axios.js';
import { DASHBOARD } from '../../Utils/conts.js';
import { ARCHIVE_ROW_IDS } from '../../Utils/conts.js';
import { TRASH_ROW_IDS, GRID_DATETIME_UPDATE } from '../../Utils/conts.js';




const JGrid = ({ onReloadArchive, onReloadTrash }) => {





  const gridRef = useRef(null);

  const [planData, setPlanData] = useState([]);
  const [gridSelect, setGridSelect] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState(null);
  const [cellEdit, setCellEdit] = useState(false);
  const [cellEditData, setCellEditData] = useState({})


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        // const token = "jwtToken";
        // const response = await fetch('http://truckingprod.mydemowork.co.in/Forms/cargohubApis/dashBoard/dashboard', {
        //   method: 'POST',
        //   headers: {
        //     'Authorization': `Bearer ${token}`,
        //     'Content-Type': 'application/json'
        //   },
        // });
        // const data = await response.json();
        // const res = await withToken.post(DASHBOARD, {});
        // // Update the localData state with the fetched data
        //       const innerData = res.data.data;
        // console.log("🚀  fetchData  res:", innerData)
        // // Update the localData state with the fetched data
        // setPlanData(innerData);

        const res = axios(process.env.REACT_APP_BASE_URL + DASHBOARD, {
          method: 'POST',
          body: {},
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }).then(function (res) {

          const innerData = res.data.data;
          setPlanData(innerData);
        });





      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function to fetch data from the API
    fetchData();

    // console.log('Load data ', planData.data);
  }, []); // Empty dependency array ensures this effect runs only once, after initial render
  // import jsPDF from 'jspdf';
  // console.log('LdDAgTA===1', planData);

  useEffect(() => {

    const setupGrid = () => {

      const getDisplayResolution = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width >= 1365) {
          return '32'; // Small screen resolution
        } else if (width >= 1920) {
          return '42'; // Medium screen resolution
        } else {
          return 'large'; // Large screen resolution
        }
      };
      const dynamicHeight = getDisplayResolution();
      console.log("🚀 ~ setupGrid ~ dynamicHeight:", dynamicHeight)

      const source = {
        datatype: 'json',
        id: 'shipmentID',
        datafields: [
          // { name: 'selected', type: 'bool' },
          { name: 'booking_id', type: 'string' },
          { name: 'file_paths', type: 'string' },
          { name: 'Type', type: 'string' },
          { name: 'handler_address', type: 'string' },
          { name: 'handler_warehouse', type: 'string' },
          { name: 'Movement', type: 'string' },
          { name: 'Vehicle', type: 'string' },
          { name: 'LoadUnload', type: 'string' },
          { name: 'AConcerned', type: 'string' },
          { name: 'RNumber', type: 'string' },
          { name: 'Booking', type: 'string' },
          { name: 'DName', type: 'string' },
          { name: 'time_slot', type: 'string' },
          { name: 'door', type: 'string' },
          { name: 'warehouse_out', type: 'string' },
          { name: 'handler_in', type: 'string' },
          { name: 'warehouse_in', type: 'string' },
          { name: 'handler_out', type: 'string' },
          { name: 'dock', type: 'string' },
        ],
        // localdata: localData.data,
        localdata: planData,

      };
      const dataAdapter = new window.$.jqx.dataAdapter(source);
      // console.log("🚀 ~ setupGrid ~ dataAdapter:", dataAdapter)

      // const celledit = (rowIndex, dataField, cellValue, defaultHtml, column, data) =>{
      //   return '<div class="set_datetime">Set</div>';
      //  }

      const columns = [
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },filtertype: 'bool',
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },
        // { text: 'Action', datafield: 'action',  width: '6%' },
        {
          text: 'Actions',
          cellsrenderer: function () {
            return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /><img src="' + trash + '" class="row_delete" /></div>';
          },
          width: '5%'
        },

        { text: 'Booking Number', filtertype: 'textbox', datafield: 'booking_id', width: '8%' },
        // { text: 'Receipt',  datafield: 'file_paths', width: '5%' },
        // {
        //   text: 'Receipt',
        //   datafield: 'file_paths',
        //   cellsrenderer: function (cellValue) {
        //     if(cellValue){
        //     return  '<div style="text-align: center;line-height: 38px;"><a target="_blank" href=" '+ process.env.REACT_APP_BASE_URL_IMAGE+"ShipmentPlan/"+cellValue +' ">  <img style="width: 22px;" src="'+Rapdf+'"/> </a></div>';
        //     }
        //   },
        //   width: '5%'
        // },
        {
          text: 'Receipt',
          // filtertype: 'textbox',
          width: '5%',
          datafield: 'file_paths',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            // console.log("🚀 ~ setupGrid ~ data:333333", data)
            if (cellValue) {
              if (data.Type == 'Shipment') {
                defaultHtml = "<div style='text-align: center;line-height: 38px;'><a target='_blank' href=' " + process.env.REACT_APP_BASE_URL_IMAGE + 'ShipmentPlan/' + cellValue + " '>  <img style='width: 25px;' src='" + Rapdf + "'/> </a></div>";
              } else {
                defaultHtml = "<div style='text-align: center;line-height: 38px;'><a target='_blank' href=' " + process.env.REACT_APP_BASE_URL_MAIN + '' + cellValue + " '>  <img style='width: 25px;' src='" + Rapdf + "'/> </a></div>";
              }

            }
            return (
              defaultHtml
            );
          },
        },
        { text: 'Type', filtertype: 'list', datafield: 'Type', width: '7%' },
        { text: 'Movement Type', filtertype: 'list', datafield: 'Movement', width: '10%' },
        { text: 'Handler', filtertype: 'textbox', datafield: 'handler_address', width: '10%' },
        { text: 'Handler Warehouse', filtertype: 'textbox', datafield: 'handler_warehouse', width: '12%' },
        { text: 'Driver Name', filtertype: 'textbox', datafield: 'DName', width: '8%' },
        { text: 'Booking Date', filtertype: 'textbox', datafield: 'Booking', width: '8%' },
        { text: 'Time Slot', filtertype: 'textbox', datafield: 'time_slot', width: '8%' },
        { text: 'Door', filtertype: 'textbox', datafield: 'door', width: '5%' },

        {
          text: 'Warehouse Out', datafield: 'warehouse_out',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
          }, width: '10%'
        },
        {
          text: 'Handler In', datafield: 'handler_in',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
          },
          width: '10%'
        },
        {
          text: 'Handler Out', datafield: 'handler_out',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
          },
          width: '10%'
        },
        {
          text: 'Warehouse In', datafield: 'warehouse_in',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
          },
          width: '10%'
        },
        {
          text: 'Dock', datafield: 'dock',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            return '<div class="set_dock" id=' + dataField + '>' + cellValue + '</div>';
          },
          width: '5%'
        },


      ];

      // 2ND LEVEL DATA START
      // Nested Shipment Start
      const nestedColumns = [
        { text: 'Airway bill', datafield: 'ABill', width: '12%' },
        { text: 'Package', datafield: 'Pkg', width: '12%' },
        { text: 'Pieces', datafield: 'Pcs', width: '12%' },
        { text: 'Weight(KG)', datafield: 'Wgt', width: '12%' },
        { text: 'Custom Code', datafield: 'CCode', width: '12%' },
        { text: 'Special Handling Code', datafield: 'Shcode', width: '12%' },
        { text: 'Security Code', datafield: 'Securitycode', width: '12%' },
        // Add more columns as needed
      ];
      // const snend = [];
      const initrowdetails = (index, parentElement, gridElement, record) => {
        console.log('record', record);
        const id = record.uid.toString();

        const nestedGridContainer = parentElement.children[0];


        // this.nestedGrids[index] = nestedGridContainer;
        let filtergroup = new window.$.jqx.filter();
        let filter_or_operator = 1;
        let filtervalue = id;
        let filtercondition = 'equal';
        let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

        let orders = dataAdapter.loadedData[id].shipment;
        console.log("🚀 ~ initrowdetails ~ orders:", orders)
        // let ordersbyid = [];
        // for (let i = 0; i < orders.length; i++) {
        //     let result = filter.evaluate(orders[i]['shipmentID']);
        //     if (result)
        //         ordersbyid.push(orders[i]);
        // }
        console.log('oddqata', orders)
        // const Hnddata = localData.data[id].shipment;
        // snend.push({
        //   id:Hnddata});
        // console.log('this.dataAdapter', this.dataAdapter);
        // const nestdata = this.dataAdapter;
        // console.log('second Data', Hnddata);
        // console.log('snend Data', snend);


        const nestedSource = {
          datatype: 'json',
          id: 'shipmentChildID',
          datafields: [
            { name: 'shipmentID', type: 'string' },
            { name: 'ABill', type: 'string' },
            { name: 'Pkg', type: 'string' },
            { name: 'Pcs', type: 'string' },
            { name: 'Wgt', type: 'string' },
            { name: 'CCode', type: 'string' },
            { name: 'Shcode', type: 'string' },
            { name: 'Securitycode', type: 'string' },
          ],
          localdata: orders,
        }

        const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);
        console.log("🚀 ~ initrowdetails ~ nestedDataAdapter:", nestedDataAdapter);

        // 3RD LEVEL DATA START

        const nestedHawbColumns = [
          { text: 'House Airway bill', datafield: 'HABill', width: '12%' },
          { text: 'Pieces', datafield: 'HPieces', width: '12%' },
          { text: 'Weight(KG)', datafield: 'HWgt', width: '12%' },
          // Add more columns as needed
        ];

        const initrowhawbdetails = (index, parentElement, gridElement, record) => {
          const id = record.uid.toString();
          console.log("🚀 ~ initrowhawbdetails ~ id:", id)

          const nestedGridContainer = parentElement.children[0];

          let orderssecond = nestedDataAdapter.loadedData[id].hawBills;
          console.log("🚀 ~ initrowhawbdetails ~ orderssecond:", orderssecond)
          // const thrd = snend
          console.log('orderssecond', orderssecond);
          const nestedSource = {
            datatype: 'json',
            datafields: [
              { name: 'HABill', type: 'string' },
              { name: 'HPieces', type: 'string' },
              { name: 'HWgt', type: 'string' },
            ],
            localdata: orderssecond,
            // [
            //   { HABill: '255 3964', HPieces: '22', HWgt: '105', },
            //   { HABill: '200 3965', HPieces: '20', HWgt: '305', },
            // ]
          }

          const nestedhawbDataAdapter = new window.$.jqx.dataAdapter(nestedSource);

          const nestedGridOptions = {
            width: '100%',
            pageable: false,
            sortable: false,
            autoheight: true,
            source: nestedhawbDataAdapter,
            filterable: false,
            columns: nestedHawbColumns,
            rowsheight: 45,
          };
          window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
        };
        // 3RD LEVEL DATA END
        const nestedGridOptions = {
          width: '100%',
          pageable: false,
          sortable: false,
          autoheight: true,
          source: nestedDataAdapter,
          filterable: false,
          columns: nestedColumns,
          initrowdetails: initrowhawbdetails, // add initrowdetails callback
          rowdetails: true,
          rowsheight: 45,
          rowdetailstemplate: {
            rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
            // rowdetailsheight: 300,
            rowdetailshidden: true,

          },
        };
        window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
      };
      // Nested Shipment End
      // Nested ULD Start
      const nestedColumnsULD = [
        { text: 'Type Code', datafield: 'type_code', width: '12%' },
        { text: 'Serial Number', datafield: 'serial_number', width: '12%' },
        { text: 'Owner Code', datafield: 'owner_code', width: '12%' },
        { text: 'Airline Name', datafield: 'airline_name', width: '12%' },
        { text: 'Condition Code', datafield: 'condition_code', width: '12%' },
        // Add more columns as needed
      ];
      const initrowdetailsuld = (index, parentElement, gridElement, record) => {
        console.log('record', record);
        const id = record.uid.toString();

        const nestedGridContainer = parentElement.children[0];


        // this.nestedGrids[index] = nestedGridContainer;
        let filtergroup = new window.$.jqx.filter();
        let filter_or_operator = 1;
        let filtervalue = id;
        let filtercondition = 'equal';
        let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

        let orders = dataAdapter.loadedData[id].uldDetials;
        console.log("🚀 ~ initrowdetails ~ orders:++", orders)
        // let ordersbyid = [];
        // for (let i = 0; i < orders.length; i++) {
        //     let result = filter.evaluate(orders[i]['shipmentID']);
        //     if (result)
        //         ordersbyid.push(orders[i]);
        // }
        console.log('oddqata', orders)
        // const Hnddata = localData.data[id].shipment;
        // snend.push({
        //   id:Hnddata});
        // console.log('this.dataAdapter', this.dataAdapter);
        // const nestdata = this.dataAdapter;
        // console.log('second Data', Hnddata);
        // console.log('snend Data', snend);


        const nestedSource = {
          datatype: 'json',
          id: 'shipmentChildID',
          datafields: [
            { name: 'type_code', type: 'string' },
            { name: 'serial_number', type: 'string' },
            { name: 'owner_code', type: 'string' },
            { name: 'airline_name', type: 'string' },
            { name: 'condition_code', type: 'string' },
          ],
          localdata: orders,
        }

        const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);
        console.log("🚀 ~ initrowdetails ~ nestedDataAdapter:", nestedDataAdapter);

        // 3RD LEVEL DATA END
        const nestedGridOptions = {
          width: '100%',
          pageable: false,
          sortable: false,
          autoheight: true,
          source: nestedDataAdapter,
          filterable: false,
          columns: nestedColumnsULD,
          rowdetails: true,
          rowsheight: 45,
        };
        window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
      };
      // Nested ULD End

      // 2ND LEVEL DATA END
      const gridOptions = {
        width: '100%',
        source: dataAdapter,
        columns: columns,
        autoheight: true,
        sortable: true,
        altrows: false,
        enabletooltips: true,
        editable: false,
        selectionmode: 'checkbox',
        pageable: true, // Enable pagination
        pageSize: 10, // Set the page size
        pagerButtonsCount: 5, // Set the number of pager buttons
        pagerMode: 'advanced',
        filterable: true, // Enable filter row
        filterrowheight: 45,
        showfilterrow: true,
        rowsheight: 42,
        // nested start
        // initrowdetails: initrowdetails, // add initrowdetails callback
        initrowdetails: (index, parentElement, gridElement, record) => {
          console.log("🚀 ~ setupGrid ~ record:00", record)

          if (record.Type === 'Shipment') {
            initrowdetails(index, parentElement, gridElement, record);
          } else if (record.Type === 'ULD') {
            console.log("🚀 ~ setupGrid ~ record:111", record)
            initrowdetailsuld(index, parentElement, gridElement, record);
          }

        },
        rowdetails: true,
        rowdetailstemplate: {
          rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
          // rowdetailsheight: 300,
          rowdetailshidden: true,
        },
      };

      if (gridRef.current && planData && planData.length > 0) {

        window.$(gridRef.current).jqxGrid(gridOptions);


      }
      window.$(gridRef.current).on('click', '.set_datetime', function () {
        // console.log("🚀 ~ event:01010", event)

        const rowDiv = window.$(this).closest('[role="row"]');
        const cellDiv = this.id;
        // console.log("🚀 ~ cellDiv:", cellDiv)

        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        // console.log("🚀 ~ rowDiv:", rowIndex)

        if (rowData.hasOwnProperty(cellDiv)) {
          const cellValue = rowData[cellDiv];
          if (cellValue == "Set") {
            const currentDate = new Date().toISOString().slice(0, -5);
            // const formattedDateTime = new Date(cellEditData.cell_value).toISOString().slice(0, -5);

            const combineData = {
              ...rowData, // Spread existing rowData
              field_check: 'time', // Add the static string field
              data_field: cellDiv,
              cell_value: currentDate // Add the cell value to combineData
            };

            // Now combineData has the cell value included
            // You can proceed with further actions using combineData
            setCellEditData(combineData);
            setCellEdit(true);
          } else {
            const combineData = {
              ...rowData, // Spread existing rowData
              field_check: 'time', // Add the static string field
              data_field: cellDiv,
              cell_value: cellValue // Add the cell value to combineData
            };

            // Now combineData has the cell value included
            // You can proceed with further actions using combineData
            setCellEditData(combineData);
            setCellEdit(true);
          }

          // Create combineData object with cellValue included

        } else {
          console.log("Cell not found in rowData");
        }

      });
      window.$(gridRef.current).on('click', '.set_dock', function () {
        // console.log("🚀 ~ event:01010", dataField)

        const rowDiv = window.$(this).closest('[role="row"]');
        const cellDiv = this.id;

        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        console.log("🚀 ~ rowDiv:", rowData)

        if (rowData.hasOwnProperty(cellDiv)) {
          const cellValue = rowData[cellDiv];
          if (cellValue == "Set") {

            const combineData = {
              ...rowData, // Spread existing rowData
              field_check: 'dock', // Add the static string field
              data_field: cellDiv,
              cell_value: '' // Add the cell value to combineData
            };
            setCellEditData(combineData);
            setCellEdit(true);
          } else {
            const combineData = {
              ...rowData, // Spread existing rowData
              field_check: 'dock', // Add the static string field
              data_field: cellDiv,
              cell_value: cellValue // Add the cell value to combineData
            };
            setCellEditData(combineData);
            setCellEdit(true);
          }

          // const combineData = {
          //   ...rowData, // Spread existing rowData
          //   field_check: 'dock' // Add the static string field
        };
        // setCellEditData(combineData);
        // setCellEdit(true);

      });
      // window.$(gridRef.current).jqxGrid(gridOptions);
      window.$(gridRef.current).on('click', '.row_edit', function (row) {

        const rowDiv = window.$(this).closest('[role="row"]');

        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        const rowBookingId = rowData.booking_id;
        const rowType = rowData.Type;
        let detailsPageUrl = '';
        if (rowType == 'Shipment') {
          detailsPageUrl = `/EditShipmentPlan?id=${rowBookingId}&plantype=${rowType}`;  // ${itemId} Change "/details/" to the actual path of your details page
        } else if (rowType == 'ULD') {
          detailsPageUrl = `/EditULDPlan?id=${rowBookingId}&plantype=${rowType}`;
        }

        // Navigate to the details page
        window.location.href = detailsPageUrl;

      });

      window.$(gridRef.current).on('click', '.row_delete', function () {
        const rowDiv = window.$(this).closest('[role="row"]');

        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        console.log("🚀 ~ rowData:PPP", rowData)
        const rowBookingId = rowData.booking_id;
        const rowBookingType = rowData.Type;
        console.log("🚀 ~ rowBookingId:", rowBookingId);
        // if (!gridSelect.includes(rowBookingId)) {
        // gridSelect.push(rowBookingId);
        // }
        let updatedGridSelect = [];
        if (gridSelect.includes(rowBookingId)) {
          // Clear the existing bookingId
          updatedGridSelect = gridSelect.filter(id => id !== rowBookingId);
        } else {
          // Add the new bookingId to the selection
          updatedGridSelect = [...gridSelect, { booking_id: rowBookingId, type: rowBookingType }];
        }
        setGridSelect(updatedGridSelect);
        handleShowConfirmation(deleteRow);
        // deleteRow(gridSelect);
        // Handle click for button 2
        console.log('Button 2 clicked');
      });



      window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

    };


    window.$(gridRef.current).on('cellclick', (event) => {
      console.log("🚀 ~ window.$ ~ event:", event)
      if (event.args.datafield == 'booking_id') {
        const args = event.args.row.bounddata;
        console.log("🚀 ~ window.$ ~ args:", args)
        // Assuming you have a unique identifier in the row data to construct the URL
        const itemId = args.booking_id; // Change "id" to the actual property name in your row data
        console.log("Detailspage===>", itemId);
        const rowType = args.Type;
        // Construct the URL for the details page
        const detailsPageUrl = `/GridDetails?id=${itemId}&plantype=${rowType}`;  // ${itemId} Change "/details/" to the actual path of your details page
        // Navigate to the details page
        window.location.href = detailsPageUrl;
      }

    });
    // window.$(gridRef.current).on('rowselect', (event) => {
    //   console.log("🚀 ~ window.$ ~ event:1111111", event.args.row.booking_id)
    //   const selectedRows = event.args.row.booking_id;
    //   setGridSelect(...gridSelect, selectedRows);
    // // const bookingIds = selectedRows.map(row => row);
    // // console.log("Selected Booking IDs:", bookingIds);
    // });

    setupGrid();



    // if (gridRef.current) {
    //   setupGrid();
    // }

  }, [planData]);
  //   window.$(gridRef.current).on('rowselect', (event) => {
  //     const selectedRowId = event.args.row.booking_id;
  //     setGridSelect(prevState => {
  //         if (!prevState.includes(selectedRowId)) {
  //             return [...prevState, selectedRowId];
  //         } else {
  //             return prevState;
  //         }
  //     });
  // });
  window.$(gridRef.current).on('rowselect', (event) => {
    console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

    // var args = event.args;                
    // var rowBoundIndex = args.rowindex;               
    // var rowData = args.row;
    // setTimeout(function () { window.$(gridRef.current).jqxGrid('unselectrow', rowBoundIndex); }, 100);

    if (event.args.row) {

      const selectedRow = event.args.row.booking_id;
      const selectedRowType = event.args.row.Type;
      if (!gridSelect.includes(selectedRow)) {
        setGridSelect((prevGridSelect) => [...prevGridSelect, { booking_id: selectedRow, type: selectedRowType }]);
      }
      // setGridSelect([...gridSelect, {booking_id:selectedRow, type:selectedRowType}]);
    } else {
      // Handle "Select All" case
      const allIds = planData.map(row => row.booking_id);
      console.log("All IDs:", allIds);
      setGridSelect(allIds);
      console.log("Select All triggered");
    }
  });
  // console.log('ggg', gridSelect);
  window.$(gridRef.current).on('rowunselect', (event) => {
    if (event.args.row) {
      const unselectedRowId = event.args.row.booking_id;
      setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
    } else {
      // console.log('00000000000000000000');
      setGridSelect([]);
    }
  });
  // console.log("🚀 ~ window.$ ~ gridSelect:", gridSelect)

  const handleArchiveButtonClick = () => {
    archiveSelectedRows(gridSelect);
  };
  const archiveSelectedRows = async (selectedIds) => {
    // Perform deletion logic here using the selected IDs
    console.log("Archive selected IDs:", selectedIds);
    const uniqueIds = Array.from(new Set(selectedIds));
    // Now you can send the selected IDs to your API or perform any other action
    try {
      const res = await withToken.post(ARCHIVE_ROW_IDS, { booking_ids: uniqueIds });
      console.log("responsehg==>", res);
      window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
      const updategrid = window.$(gridRef.current).jqxGrid();
      // setupGrid();
      reloadGrid();
      window.$(gridRef.current).jqxGrid('clearselection');
      // onReloadArchive();


      // setTimeout(() => {
      //    window.location.replace('/')  
      // },1000);

    } catch (error) {
      console.error('Error sending request:', error);
    }
  };
  const handleDeleteButtonClick = () => {
    // deleteSelectedRows(gridSelect);
    if (gridSelect) {
      handleShowConfirmation(deleteSelectedRows);
    }
  };
  const deleteSelectedRows = async (selectedIds) => {
    console.log("🚀 ~ deleteSelectedRows ~ selectedIds:", selectedIds)
    const uniqueIds = Array.from(new Set(selectedIds));
    try {
      const res = await withToken.post(TRASH_ROW_IDS, { booking_ids: uniqueIds });
      console.log("responsehg==>", res);
      window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
      const updategrid = window.$(gridRef.current).jqxGrid();
      // setupGrid();
      reloadGrid();
      window.$(gridRef.current).jqxGrid('clearselection');
      // onReloadTrash();
      // onReloadChildComponents();


      // setTimeout(() => {
      //    window.location.replace('/')  
      // },1000);

    } catch (error) {
      console.error('Error sending request:', error);
    }
  };

  const deleteRow = async (selectedId) => {

    try {
      const res = await withToken.post(TRASH_ROW_IDS, { booking_ids: selectedId });
      console.log("responsehg=000=>", res);

      // setupGrid();
      reloadGrid();
      // onReloadChildComponents();         
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };



  useEffect(() => {
    if (gridRef.current && planData) {
      const gridElement = gridRef.current;
      // Initialize jqxGrid with planData
    }
  }, [planData]);


  const exportToExcel = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
    }
  };
  const exportToPDF = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'pdf', 'GridData');
    }
  };

  const handleShowConfirmation = (deleteFunction) => {
    setShowConfirmation(true);
    setDeleteFunction(() => deleteFunction);
  };
  const handleConfirmDelete = () => {
    // deleteRow(gridSelect);
    deleteFunction(gridSelect)
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setGridSelect([]);
    setShowConfirmation(false);
  };

  const handleDateTimeChange = (event) => {
    const newValue = event.target.value;
    setCellEditData({
      ...cellEditData,
      cell_value: newValue
    });
  };

  const handleCellEdit = async () => {
    const { Type, booking_id, data_field, cell_value } = cellEditData;

    // Create a new object with only the desired fields
    const updatedData = {
      booking_no: booking_id,
      shipment_type: Type,
      filed_type: data_field,
      field_value: cell_value
    };
    console.log("🚀 ~ handleCellEdit ~ updatedData:", updatedData)
    try {
      const res = await withToken.post(GRID_DATETIME_UPDATE, updatedData);
      console.log("response==>", res);

    } catch (error) {
      console.error('Error sending request:', error);
    }
    setCellEditData({});
    reloadGrid();
    setCellEdit(false);
  }

  const reloadGrid = async () => {
    try {
      const res = await axios.post(process.env.REACT_APP_BASE_URL + DASHBOARD, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      const innerData = res.data.data;
      setPlanData(innerData);
      setGridSelect([]);
    } catch (error) {
      console.error('Error reloading data:', error);
    }
  };


  return (
    <>
      <div>
        <div className='shipment_grid'>
          <button onClick={handleArchiveButtonClick}> <img src={Arcv} /> Archive</button>
          <button onClick={handleDeleteButtonClick}><img src={Trash} /> Move to Trash</button>
          <button onClick={exportToPDF}><img src={Export} /> Download</button>

        </div>

        <div ref={gridRef}></div>
      </div>
      <Modal className='raintrac' show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h2>Confirm Delete</h2>
          <p>If you proceed with the change, all Booking PLan data in this section will be moved to Trash.
            Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className='raintrac set_booking' show={cellEdit} onHide={() => setCellEdit(false)} centered>
        <Modal.Header closeButton>
          {cellEditData &&
            <h2>Booking Id: {cellEditData.booking_id}</h2>
          }
        </Modal.Header>
        <Modal.Body>
          {cellEditData &&
            cellEditData.field_check == 'time' ? (
            <>
              <Form.Group className='set_midle'>
                <Form.Label>Date and time</Form.Label>
                <Form.Control type="dateTime-local" name='cell_value' value={cellEditData.cell_value} onChange={handleDateTimeChange} />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className='set_midle'>
                <Form.Label>Dock</Form.Label>
                <Form.Control type="number" name='cell_value' value={cellEditData.cell_value} onChange={handleDateTimeChange} />
              </Form.Group>
            </>
          )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCellEdit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setCellEdit(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default JGrid;
// export {JGrid as default, exportToPDF}