import React, { useState, useEffect } from 'react';

const Loader = ({ isLoading }) => {
    // State to handle the final spin
    const [showLoader, setShowLoader] = useState(false);
    const [finalSpin, setFinalSpin] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setShowLoader(true); // Show loader immediately when loading starts
        } else {
            // // Trigger the final spin when loading completes
            // setFinalSpin(true);

            // // Hide loader after the final spin completes
            // const timer = setTimeout(() => {
            //     setFinalSpin(false);
            //     setShowLoader(false); // Hide the loader
            // }, 400); // Duration of the final spin

            // // Clean up the timer if the component unmounts or isLoading changes
            // return () => clearTimeout(timer);
            setShowLoader(false);
        }
    }, [isLoading]);

    return (
        <>
            {showLoader && (
                <div className='loader-container'>
                    {/* <span className={`loader ${finalSpin ? 'final-spin' : ''}`}></span> */}
                    <span className='loader'></span>
                </div>
            )}
        </>
    );
};

export default Loader;