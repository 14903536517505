import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Header from "../../../Componenets/Header/Header";
import {LeftSettings} from '../LeftSettings';
import TruckerDetails from './TruckerDetails';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Trash from "../../../assets/images/delete.svg";
import Arcv from "../../../assets/images/archive.svg";
import Active from "../../../assets/images/active.svg";
import {  toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faBoxArchive } from '@fortawesome/free-solid-svg-icons';

import  {withoutRFSToken, withRFSToken}   from '../../../Services/axios.js';
import { ADDTRUCKER, TRUCKERGRID, TRUCKERACTIVE, TRUCKERARCHIVE, TRUCKERTRASH } from '../../../Utils/conts.js';

const TruckerSettings = () => {
    
  
  
    const gridRef = useRef(null);
    const gridRefs = {
      Active: useRef(null),
      Archived: useRef(null),
      Trash: useRef(null),
    };
    const [truckerData, setTruckerData] = useState({
      Active: [],
      Archived: [],
      Trash: []
    });
    // const [gridData, setTruckerData] = useState([{
    //     name: "aaaaaaaa",
    //     email: "444",
    //     number: "sdasadsad"
    // }]);
    useEffect(() => {
      const fetchDataGrid = async () => {
        try {
          const res = await withRFSToken.post(TRUCKERGRID, {status:activeTab})
          .then(function(res) {
            const innerData = res.data.response_data; 
            console.log("🚀 ~ .then ~ innerData:654", innerData)
            setTruckerData({innerData});
          });
          
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
        // Call the fetchData function to fetch data from the API
        fetchDataGrid();
        
      }, []);
    const [modalShow, setModalShow] = useState(false);

    const [formData, setFormData] = useState({
        truckerName: '',
    truckerEmail: '',
    TruckerPhone: ''
      });
  
    const [activeTab, setActiveTab] = useState('Active');
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [gridSelect, setGridSelect] = useState([]);
  
    const handleRowClick = (rowData) => {
      setSelectedRowData(rowData);
    };
    const handleDetailsBackClick = (rowData) => {
      // setSelectedRowData(rowData);
      setShowDetails(false);
    };
  
    const handleAddButtonClick = () => {
      setModalShow(true);
    };
  
    const handleModalClose = () => {
      setModalShow(false);
    };
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
      };
      // validation
      const validateFormData = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Function to validate email
        const validateEmail = (email) => {
        return emailRegex.test(email);
        };
            if (!formData.truckerName) {
                console.log('Text input is required');
                toast.error('Trucker Name must not be blank');
                isValid = false;
              } else
              if (!formData.TruckerPhone) {
                console.log('Text input is required');
                toast.error('Phone Number must not be blank');
                isValid = false;
              } else
              if (!/^\d{10}$/.test(formData.TruckerPhone)) {
                console.log('10');
                toast.error('Phone Number must be 10 Digits');
                isValid = false;
              } else
              if (!formData.truckerEmail) {
                console.log('Text input is required');
                toast.error('Email must not be blank');
                isValid = false;
              } else if (!validateEmail(formData.truckerEmail)) {
                console.log('Invalid email address');
                toast.error('Invalid email address');
                isValid = false;
              }
              else{
                return isValid;
              }
      };
      const handleAddCarrier = async() => {
        if (validateFormData()) {
        const newData = {
            TruckerName: formData.truckerName,
            TruckerEmail: formData.truckerEmail,
            TruckerPhone: formData.TruckerPhone
          };

          try {
            const res = await withRFSToken.post(ADDTRUCKER, formData)
            .then(function(res) {
              // const innerData = res;
              // console.log("🚀 ~ .then ~ innerData:", innerData)
              if(res.data.response_code == 200){
                setTruckerData(res.data.response_data)
                // reloadGrid();
              }
            });
           
          } catch (error) {
            console.error('Error sending request:', error);
          }
      
          // setTruckerData(prevData => [...prevData, newData]);
          // localStorage.setItem('truckerData', JSON.stringify([...truckerData, newData]));
      
          setFormData({
            truckerName: '',
            truckerEmail: '',
            TruckerPhone: ''
          });
            setModalShow(false);
        }
      };

      // useEffect(() => {
      //   localStorage.setItem('truckerData', JSON.stringify(truckerData));
      // }, [truckerData]);

      const handleTabChange = (tab) => {
        setActiveTab(tab);
        reloadGrid(tab);
      };


    useEffect(() => {
      if (gridRefs[activeTab] && !showDetails) {
        const localData = truckerData; 
        // && truckerData.map(item => ({
        //     TruckerName: item.TruckerName,
        //     TruckerEmail: item.TruckerEmail,
        //     TruckerPhone: item.TruckerPhone,
            
        // }));
        
        const source = {
          datatype: 'json',
          datafields: [
            { name: 'id', type: 'string' },
            { name: 'trucker_request_emails', type: 'string' },
            { name: 'trucker_name', type: 'string' },
            { name: 'trucker_email', type: 'string' },
            { name: 'trucker_phone', type: 'string' },
          ],
          localdata: localData,
        };
  
        const dataAdapter = new window.$.jqx.dataAdapter(source);
        console.log('localdata',source.localdata)
        const columns = [
          { text: 'Trucker Name', filtertype: 'textbox', datafield: 'trucker_name', width: '31%' },
          { text: 'Email', filtertype: 'textbox', datafield: 'trucker_email', width: '31%' },
          { text: 'Phone Number', filtertype: 'textbox', datafield: 'trucker_phone', width: '31%' },
        ];
  
        const gridOptions = {
          width: '100%',
          source: dataAdapter,
          columns: columns,
          autoheight: true,
          sortable: true,
          altrows: false,
          enabletooltips: true,
          editable: false,
          selectionmode: 'checkbox',
          pageable: false,
          pageSize: 10,
          pagerButtonsCount: 5,
          pagerMode: 'advanced',
          filterable: true,
          filterrowheight: 40,
          showfilterrow: true,
          rowsheight: 42,
        };
  
        window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
        window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');
  
        window.$(gridRefs[activeTab].current).on('rowclick', (event) => {
          console.log('event', event.args.row.bounddata);
          const args = event.args.row.bounddata;
          console.log('args', args);
          setSelectedRowData(event);
          setShowDetails(true);
        });

        window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
          console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
          
            if (event.args.row) {
                
                const selectedRow = event.args.row.id;
                console.log("🚀 ~ window.$ ~ selectedRow:", selectedRow)
                
                if (!gridSelect.includes(selectedRow)) {
                      setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
                  }
                  // setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
            } else {
                // Handle "Select All" case
                // const allIds = planData.map(row => row.booking_id);
                  // console.log("All IDs:", allIds);
                  // setGridSelect(allIds);
                console.log("Select All triggered");
            }
          }); 
          // console.log('ggg', gridSelect);
          window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
            if (event.args.row) {
            const unselectedRowId = event.args.row.id;
            setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
            }else{
              setGridSelect([]);
            }
          });
      }
    }, [truckerData, showDetails]);

    const handleArchiveButtonClick = () => {
      archiveSelectedRows(gridSelect);
    };
    const archiveSelectedRows = async(selectedIds) => {
      // Perform deletion logic here using the selected IDs
      console.log("Archive selected IDs:", selectedIds);
      const uniqueIds = Array.from(new Set(selectedIds));
      console.log("🚀 ~ archiveSelectedRows ~ uniqueIds:", uniqueIds)
      // Now you can send the selected IDs to your API or perform any other action
      if (selectedIds.length > 0) {
        // dispatch(addStationArchiveData(selectedIds)); // Pass sendStationData to addData thunk
        try {
        
          // Make API call here await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
          // Example:
          const response = await withRFSToken.post(TRUCKERARCHIVE, {trucker_ids:uniqueIds});
          console.log("🚀 ~ addData ~ response:", response)
          if (!response.ok) {
            throw new Error('Failed to add data');
          }
          // dispatch(addDataSuccess(data));
          // dispatch(fetchStationGridData());
          reloadGrid(activeTab);
        } catch (error) {
          // dispatch(addDataFailure(error.message));
          console.error('Error fetching data:', error);
        }
      } else{
        toast.error('Select At least one row.')
      }
      window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
      window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
      // dispatch(fetchStationGridData());
      reloadGrid();
    };
    
    const handleActiveButtonClick = () => {
      activeSelectedRows(gridSelect);
    };
    const activeSelectedRows = async(selectedIds) => {
      // Perform deletion logic here using the selected IDs
      console.log("Archive selected IDs:", selectedIds);
      const uniqueIds = Array.from(new Set(selectedIds));
      // Now you can send the selected IDs to your API or perform any other action
      if (selectedIds.length > 0) {
        // dispatch(addStationArchiveData(selectedIds)); // Pass sendStationData to addData thunk
        try {
        
          // Make API call here await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
          // Example:
          const response = await withRFSToken.post(TRUCKERACTIVE, {trucker_ids:uniqueIds});
          if (!response.ok) {
            throw new Error('Failed to add data');
          }
          // dispatch(addDataSuccess(data));
          // dispatch(fetchStationGridData());
          reloadGrid();
        } catch (error) {
          // dispatch(addDataFailure(error.message));
          console.error('Error fetching data:', error);
        }
      }
      window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
      window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
      // dispatch(fetchStationGridData());
      reloadGrid(activeTab);
    };
    
    const handleDeleteButtonClick = () => {
      deleteSelectedRows(gridSelect);
      // if (gridSelect){
      //   handleShowConfirmation(deleteSelectedRows);
      // }
    };
    const deleteSelectedRows = async(selectedIds) => {
      // Perform deletion logic here using the selected IDs
      console.log("Archive selected IDs:", selectedIds);
      const uniqueIds = Array.from(new Set(selectedIds));
      // Now you can send the selected IDs to your API or perform any other action
      if (selectedIds.length > 0) {
        // dispatch(addStationRemoveData(selectedIds)); // Pass sendStationData to addData thunk
        try {
          const response = await withRFSToken.post(TRUCKERTRASH, {trucker_ids:uniqueIds});
          console.log("🚀 ~ addData ~ response:", response)
          if (!response.ok) {
            throw new Error('Failed to add data');
          }
          reloadGrid();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
      window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
      // dispatch(fetchStationGridData());
      reloadGrid(activeTab);
    };
  

    const reloadGrid = async (tab) => {
      try {
        const res = await withRFSToken.post(TRUCKERGRID, {status:tab})
        .then(function(res) {
          const innerData = res.data.response_data; 
          setTruckerData(innerData);
        });
    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
  
  
    return (
      <>
        <div className="">
          <Header />
          <div className="main-panel">
            <div className="row">
              <LeftSettings />
              <div className='col-9 col-xxl-9 stting_right'>
                <div className='settingbg_right'>
                {!showDetails && 
                  <>    
                  <h4>Trucker Settings</h4>
                  <p>Add Carrier</p>
  
                  <div className='row'>
  
                    <div className='col-6'>
  
                      <div>
                        <Button variant="primary createplan" onClick={handleAddButtonClick}>
                          <FontAwesomeIcon icon={faPlus} /> Add Trucker
                        </Button>
                      </div>
                      {/* <div ref={gridRef} className='staion_grid' ></div> */}
                      <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="station_grid">
                        <Tab eventKey="Active" title={<span>Active</span>} className='moveto'> 
                          <div className='moveto_btn'>
                            <button onClick={handleArchiveButtonClick}> <img src={Arcv}/></button>
                            <button onClick={handleDeleteButtonClick}><img src={Trash} /></button>
                          </div>
                          <div ref={gridRefs.Active} className='staion_grid' ></div>
                        </Tab>
                        <Tab eventKey="Archived" title={<span>Archived</span>} className='moveto'>
                          <div className='moveto_btn'>
                            <button onCanPlay={handleActiveButtonClick}> <img title='' src={Active}/></button>
                            <button onClick={handleDeleteButtonClick}><img src={Trash} /></button>
                          </div>
                          <div ref={gridRefs.Archived} className='staion_grid' ></div>
                        </Tab>
                        <Tab eventKey="Trash" title={<span>Trash</span>} className='moveto'>
                          <div className='moveto_btn'>
                            <button onClick={handleActiveButtonClick}> <img src={Active}/></button>
                          </div>
                          <div ref={gridRefs.Trash} className='staion_grid' ></div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
  
                  <Modal show={modalShow} centered onHide={handleModalClose} className='addfrom' >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Trucker</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
  
                    <Form.Group controlId="dropdown" className='mb-3'>
                        <Form.Label>Trucker Name</Form.Label>
                        <Form.Control type="text" placeholder="Trucker Name" name='truckerName' value={formData.truckerName} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="dropdown" className='mb-3'>
                        <Form.Label>Telephone No</Form.Label>
                        <Form.Control type="number" placeholder="Enter Telephone No"maxLength='10' onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10); }} name='TruckerPhone' value={formData.TruckerPhone} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="dropdown" className='mb-3'>
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email ID" name='truckerEmail' value={formData.truckerEmail} onChange={handleChange} />
                    </Form.Group>
  
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleAddCarrier}>
                        Add Trucker 
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  </>
                  }
                  {showDetails && (
                    <>
                    <button className='backbtn' onClick={handleDetailsBackClick}> <FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <TruckerDetails rowData={selectedRowData} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

export default TruckerSettings;