import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Fullsize from "../../../../../assets/images/full-size.svg";
import { Modal } from 'react-bootstrap';
import { withoutRFSToken, withRFSToken } from '../../../../../Services/axios.js';
import { MSGPROCESSMVT } from '../../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const Process = () => {

  // const queryParameters = new URLSearchParams(window.location.search);
  // const type = queryParameters.get("action");
  // const uldids = queryParameters.get("ids");

  const actionTypes = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
  let keyType = null;
  // Loop through the actionTypes array
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    // Check if the current key matches any of the action types
    if (actionTypes.includes(key)) {
      keyType = key;
      break;
    }
  }

  const [activeTab, setActiveTab] = useState('UCM');
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [showProcess, setShowProcess] = useState(false);
  const [processText, setProcessText] = useState('');
  const [ucmOutText, setUcmOutText] = useState('');
  const [ffmText, setFfmText] = useState('');
  const [cpmText, setCpmText] = useState('');
  const handleCloseProcess = () => { setShowProcess(false); };
  const handleFullsizeClick = (event) => {
    event.preventDefault();
    setShowProcess(true);
  };

  // const handleTextareaChange = (e) => {
  //   setProcessText(e.target.value);
  //   setUcmOutText(e.target.value);
  //   setFfmText(e.target.value);
  //   setCpmText(e.target.value);
  // };
  const handleTextareaChange = (e) => {
    const { name, value } = e.target;

    // Update state based on the name of the textarea
    if (name === 'ucm_out') {
      setUcmOutText(value);
    } else if (name === 'ffm') {
      setFfmText(value);
    } else if (name === 'cpm') {
      setCpmText(value);
    } else if (name === 'process') {
      setProcessText(value);
    }
  };

  const handleProcessButtonClick = async (msgFor) => {
    // Process the data in processText
    let keyTypeMapping;
    // if (!keyType == 'UCM Out'){
    keyTypeMapping = {
      'Lease Out': 'LEO',
      'SCM': 'SCM',
      // 'UCM Out': 'UO',
      'UCM In': 'UI',
      'LUC In': 'LI',
      'LUC Out': 'LO',
    };
    // } else {
    let keyTypeMappingUO;
    if (msgFor == 'ucm_out') {
      keyTypeMappingUO = 'UO'
    } else if (msgFor == 'ffm') {
      keyTypeMappingUO = 'FFM'
    } else if (msgFor == 'cpm') {
      keyTypeMappingUO = 'CPM'
    }
    // keyTypeMappingUO = msgFor == 'ucm_out' ? 'UO' : msgFor == 'ffm' ? 'FFM' : 'CPM';
    // }

    const actiontype = keyType !== 'UCM Out' ? keyTypeMapping[keyType] : keyTypeMappingUO;
    const msgType = msgFor == 'ucm_out' ? ucmOutText : msgFor == 'ffm' ? ffmText : msgFor == 'cpm' ? cpmText : processText;
    try {
      const res = await withRFSToken.post(MSGPROCESSMVT, { message_type: actiontype, message: msgType });
      if (res.data.response_code == 200) {
        toast.success('Message processed');
        // setFormData(res.data.response_data);
        console.log('100', res.data.response_data);
        const localStorageKey = keyType;
        const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        const updatedData = {
          // ...existingData,
          Form: res.data.response_data.Form,
          ULD: res.data.response_data.uldInfo
        };
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        const event = new Event('localStorageUpdated');
        window.dispatchEvent(event);
      } else {
        toast.error(res.data.response_msg);
      }
      // .then(function (res) {

      // });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <div className='mb-3 nestedtabs rounded h-100'>
        {keyType == 'UCM Out' ? (
          <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="autometd">
            <Tab eventKey="UCM" title={<span>UCM Out</span>} className='moveto'>
              <div className='mt-3'>
                <div className='d-flex justify-content-between align-items-center charter_top '>
                  <h3 className='mb-0' >Automated {keyType} processing</h3>
                  <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                </div>

                <div className='au_proses ucmproses big_ucm'>
                  <Form.Group >
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      type="text" style={{ resize: 'none' }}
                      name='ucm_out'
                      value={ucmOutText}
                      onChange={handleTextareaChange}
                    />
                  </Form.Group>
                  <div className='text-center mt-3'>
                    <Button variant="primary" className='process_btn' onClick={() => handleProcessButtonClick('ucm_out')}> Process {keyType} </Button>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="FFM" title={<span>FFM</span>} className='moveto'>
              <div className='mt-3'>
                <div className='d-flex justify-content-between align-items-center charter_top '>
                  <h3 className='mb-0'>Automated {activeTab} processing</h3>
                  <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                </div>

                <div className='au_proses ucmproses big_ucm'>
                  <Form.Group >
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      type="text"
                      style={{ resize: 'none' }}
                      name='ffm'
                      value={ffmText}
                      onChange={handleTextareaChange}
                    />
                  </Form.Group>
                  <div className='text-center mt-3'>
                    <Button variant="primary" className='process_btn' onClick={() => handleProcessButtonClick('ffm')}> Process {activeTab} </Button>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="CPM" title={<span>CPM</span>} className='moveto'>
              <div className='mt-3'>
                <div className='d-flex justify-content-between align-items-center charter_top '>
                  <h3 className='mb-0'>Automated {activeTab} processing</h3>
                  <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                </div>

                <div className='au_proses ucmproses big_ucm'>
                  <Form.Group >
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      type="text"
                      style={{ resize: 'none' }}
                      name='cpm'
                      value={cpmText}
                      onChange={handleTextareaChange}
                    />
                  </Form.Group>
                  <div className='text-center mt-3'>
                    <Button variant="primary" className='process_btn' onClick={() => handleProcessButtonClick('cpm')}> Process {activeTab} </Button>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <div>
            <div className='d-flex justify-content-between align-items-center charter_top '>
              <h3 className='mb-0'>Automated {keyType} processing</h3>
              <a href='#' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
            </div>
            <div className={['UCM In'].includes(keyType) ? 'au_proses ucmproses big_ucm' : (['LUC Out', 'LUC In'].includes(keyType) ? 'au_proses ucmproses luc_area' : 'au_proses ucmproses')}>
              <Form.Group >
                <Form.Group >
                  <Form.Control
                    as="textarea"
                    name='process'
                    aria-label="With textarea"
                    type="text"
                    style={{ resize: 'none' }}
                    value={processText}
                    onChange={handleTextareaChange}
                  />
                </Form.Group>
              </Form.Group>
              <div className='text-center mt-3'><Button variant="primary" className='process_btn' onClick={() => handleProcessButtonClick('msg')}> Process {keyType} </Button></div>
            </div>
          </div>
        )}
      </div>
      <Modal show={showProcess} className={keyType == 'UCM Out' ? 'addfrom zoomcm' : 'addfrom'} onHide={handleCloseProcess} size="xl" centered>
        <Modal.Header closeButton>
          {keyType !== 'UCM Out' ? (<Modal.Title>Automated FFM processing</Modal.Title>) : ''}
        </Modal.Header>
        <Modal.Body>
          {keyType == 'UCM Out' ? (
            <>
              <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="autometd">
                <Tab eventKey="UCM" title={<span>UCM Out</span>} className='moveto'>
                  <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-center charter_top '>
                      <h3 className='mb-0' >Automated {keyType} processing</h3>
                      <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                    </div>

                    <div className='au_proses ucmproses bigpros'>
                      <Form.Group >
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          type="text" style={{ resize: 'none' }}
                          name='ucm_out'
                          value={ucmOutText}
                          onChange={handleTextareaChange}
                        />
                      </Form.Group>
                      <div className='text-center mt-3'><Button variant="primary" className='process_btn'> Process {keyType} </Button></div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="FFM" title={<span>FFM</span>} className='moveto'>
                  <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-center charter_top '>
                      <h3 className='mb-0'>Automated {keyType} processing</h3>
                      <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                    </div>

                    <div className='au_proses ucmproses'>
                      <Form.Group >
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          type="text"
                          style={{ resize: 'none' }}
                          name='ffm'
                          value={ffmText}
                          onChange={handleTextareaChange}
                        />
                      </Form.Group>
                      <div className='text-center mt-3'><Button variant="primary" className='process_btn'> Process {keyType} </Button></div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="CMP" title={<span>CPM</span>} className='moveto'>
                  <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-center charter_top '>
                      <h3 className='mb-0'>Automated {keyType} processing</h3>
                      <a href='#' className='zoom_up' onClick={handleFullsizeClick}>  <img src={Fullsize} /> </a>
                    </div>

                    <div className='au_proses ucmproses'>
                      <Form.Group >
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          type="text"
                          style={{ resize: 'none' }}
                          name='cpm'
                          value={cpmText}
                          onChange={handleTextareaChange}
                        />
                      </Form.Group>
                      <div className='text-center mt-3'><Button variant="primary" className='process_btn'> Process {keyType} </Button></div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </>
          ) : (
            <>
              <div className='au_proses'>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name='process'
                    aria-label="With textarea"
                    type="text"
                    style={{ resize: 'none' }}
                    value={processText}
                    onChange={handleTextareaChange}
                  />
                </Form.Group>
                <div className='text-center mt-3 mb-3'>
                  <Button variant="primary" className='process_btn' onClick={handleProcessButtonClick}>  Process {keyType} </Button>
                </div>
              </div>
            </>
          )
          }

        </Modal.Body>
      </Modal>
    </>
  )
}

export default Process