import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargoplane from "../../../assets/images/plane.svg";
import Cuser from "../../../assets/images/cuser.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Badge from 'react-bootstrap/Badge';
import ImageUpload from '../../../Componenets/common/ImageUpload';
import ActivityComments from '../../../Componenets/common/ActivityComments';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDONAIRGRID, EDITONAIR } from '../../../Utils/conts.js';
import DownloadGrid from './components/DownloadGrid.js';

const Onair = () => {
    const isMounted = useRef(false);
    const [totalRows, setTotalRows] = useState({});
    const [gridData, setGridData] = useState([]);
    const fetchDataGrid = async () => {
        try {
            const res = await withRFSToken.post(ULDONAIRGRID, {})
                .then(function (res) {
                    const innerData = res.data.response_data.flights;
                    const innerDataCount = res.data.response_data.pagination;
                    setGridData(innerData);
                    setTotalRows({
                        pageSize: innerDataCount.page_size,
                        currPage: innerDataCount.current_page,
                        lastPage: innerDataCount.last_page,
                        totalUlds: innerDataCount.total
                    });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        // Call the fetchData function to fetch data from the API
        if (isMounted.current) return;
        fetchDataGrid();
        isMounted.current = true;
    }, [])
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const [gridNestedSelect, setGridNestedSelect] = useState([]);
    const gridRef = useRef(null);

    const pageSize = totalRows.pageSize;
    const totalRowsCount = totalRows.totalUlds;
    const initCurrentPage = totalRows.currPage;
    useEffect(() => {

        if (gridRef.current) {
            if (gridData) {
                // const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                // }));
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'uld_count', type: 'string' },
                        { name: 'flight_or_truck', type: 'string' },
                        { name: 'flight_number', type: 'string' },
                        { name: 'flight_date', type: 'date' },
                        { name: 'flight_reg_number', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'origin', type: 'string' },
                        { name: 'origin_handler', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'destination_handler', type: 'string' },
                        { name: 'airline_name', type: 'string' },
                    ],
                    localdata: gridData,
                    totalrecords: totalRowsCount,
                    addrow: function (rowid, rowdata, position, commit) {
                        // Customize the row ID assignment to start from 0
                        rowdata.uid = rowid;
                        commit(true);
                    }
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                // console.log('localdata', source.localdata);
                // let columns;
                // if (activeTab === 'Flight') {
                const columns = [
                    {
                        text: 'Actions',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                        },
                        width: '5%', filterable: false,
                    },
                    { text: 'Flight/truck', filtertype: 'textbox', datafield: 'flight_or_truck', width: '8%', cellclassname: '', },
                    { text: 'Flight Number', filtertype: 'textbox', datafield: 'flight_number', width: '10%', cellclassname: '', },
                    { text: 'Flight date', filtertype: 'range', cellsformat: 'dd-MM-yyyy', datafield: 'flight_date', width: '10%', cellclassname: '', },
                    { text: 'Flight Reg. Number', filtertype: 'textbox', datafield: 'flight_reg_number', width: '10%', cellclassname: '', },
                    { text: "Totals ULD's", filtertype: 'textbox', datafield: 'uld_count', width: '8%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                    { text: 'Origin Handler Name', filtertype: 'textbox', datafield: 'origin_handler', width: '12%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                    { text: 'Destination Handler Name', filtertype: 'textbox', datafield: 'destination_handler', width: '12%', cellclassname: '', },
                    { text: 'Airline Name', filtertype: 'textbox', datafield: 'airline_name', width: '12%', cellclassname: '', },
                ];
                // } else if (activeTab === 'ULD') {
                //     columns = [
                //         {
                //             text: 'Actions',
                //             cellsrenderer: function () {
                //                 return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                //             },
                //             width: '5%'
                //         },
                //         { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
                //         { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '10%', cellclassname: '', },
                //         { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '10%', cellclassname: '', },
                //         { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition_code', width: '10%', cellclassname: '', },
                //         { text: 'Status', filtertype: 'textbox', datafield: 'status', width: '10%', cellclassname: '', },
                //         { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                //         { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                //         { text: 'SCM', filtertype: 'textbox', datafield: 'scm', width: '6%', cellclassname: '', },
                //         { text: 'SCM Date', filtertype: 'textbox', datafield: 'scm_date', width: '6%', cellclassname: '', },
                //         { text: 'Asset Type', filtertype: 'textbox', datafield: 'asset_type', width: '6%', cellclassname: '', },
                //         { text: 'Handler Name', filtertype: 'textbox', datafield: 'handler_name', width: '6%', cellclassname: '', },
                //         { text: 'Repair Name', filtertype: 'textbox', datafield: 'repair_name', width: '6%', cellclassname: '', },
                //         { text: 'Repair Station', filtertype: 'textbox', datafield: 'repair_station', width: '6%', cellclassname: '', },
                //     ];
                // }

                // Nested ULD Start
                const nestedColumns = [
                    { text: 'ULD Number', datafield: 'uld_number', width: '8%' },
                    { text: 'Type Code', datafield: 'type_code', width: '8%' },
                    { text: 'Serial Number', datafield: 'serial_number', width: '8%' },
                    { text: 'Owner Code', datafield: 'owner_code', width: '8%' },
                    { text: 'Condition Code', datafield: 'condition', width: '8%' },
                    { text: 'Content Code', datafield: 'content', width: '8%' },
                    { text: 'Final Dest.', datafield: 'final_dest', width: '8%' },
                    {
                        text: 'ULD Asset',
                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                            if (rowdata.register === 'Y') {
                                // Return HTML with class 'inactive' for 'i' status
                                return '<div class="jqx-grid-cell-left-align set_dock ">Registered</div>';
                            } else {
                                // Return HTML with class 'active' for 'a' status
                                return '<div class="jqx-grid-cell-left-align set_dock ">Non-registered</div>';
                            }
                        },
                        datafield: 'register', width: '8%'
                    },
                    {
                        text: 'Asset Type',
                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                            if (rowdata.asset_type === 'Y') {
                                // Return HTML with class 'inactive' for 'i' status
                                return '<div class="jqx-grid-cell-left-align set_dock  ">Owned</div>';
                            } else {
                                // Return HTML with class 'active' for 'a' status
                                return '<div class="jqx-grid-cell-left-align set_dock  ">Lease</div>';
                            }
                        },
                        datafield: 'asset_type', width: '8%'
                    },
                    // Add more columns as needed
                ];
                const initrowdetails = (index, parentElement, gridElement, record) => {
                    const nestedGridContainer = parentElement.children[0];

                    parentElement.addEventListener('click', (event) => {
                        const target = event.target;
                        // Check if the click target is the nested arrow
                        if (window.$(target).hasClass('jqx-icon-arrow-right')) {
                            // Handle nested arrow click here
                            // console.log('Nested arrow clicked:', record);
                            event.stopPropagation(); // Prevent event bubbling to the row details
                        }
                    });
                    const id = record.uid.toString();
                    const uldGrid = dataAdapter.loadedData[id].uld_level;

                    const nestedSource = {
                        datatype: 'json',
                        id: 'shipmentChildID',
                        datafields: [
                            { name: 'uld_number', type: 'string' },
                            { name: 'type_code', type: 'string' },
                            { name: 'serial_number', type: 'string' },
                            { name: 'owner_code', type: 'string' },
                            { name: 'condition', type: 'string' },
                            { name: 'condition_code', type: 'string' },
                            { name: 'content', type: 'string' },
                            { name: 'content_code', type: 'string' },
                            { name: 'final_dest', type: 'string' },
                            { name: 'register', type: 'string' },
                            { name: 'asset_type', type: 'string' },
                        ],
                        localdata: uldGrid,
                    }

                    const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);

                    const nestedGridOptions = {
                        width: '100%',
                        pageable: false,
                        sortable: false,
                        // pageSize: 15,
                        autoheight: false,
                        height: (15 * 45),
                        selectionmode: 'checkbox',
                        source: nestedDataAdapter,
                        filterable: false,
                        columns: nestedColumns,
                        rowdetails: true,
                        rowsheight: 45,
                        showemptyrow: false,
                        ready: function () {
                            // Manually set row IDs starting from 0
                            const rows = window.$(nestedGridContainer).jqxGrid('getrows');
                            for (let i = 0; i < rows.length; i++) {
                                const rowid = i; // Use index as the new row ID
                                const row = rows[i];
                                window.$(nestedGridContainer).jqxGrid('setcellvalue', row.uid, 'uid', rowid);
                                window.$(nestedGridContainer).jqxGrid('updaterow', rowid, row);
                                window.$(nestedGridContainer).find(`[role="row"][row-id="${row.uid}"]`).attr('row-id', rowid);
                            }
                        },
                    };
                    window.$(nestedGridContainer).jqxGrid(nestedGridOptions);

                    


                    window.$(gridElement).on('rowselect', function (event) {
                        // console.log("🚀 ~ event:xxxxxxxx", event)
                        const selectedRowIndex = event.args.rowindex;
                        console.log("🚀 ~ selectedRowIndex:", selectedRowIndex)
                        const rowDiv = window.$(this).closest('[role="row"]');
                        const rowIndex = window.$(rowDiv).attr('row-id');
                        console.log("🚀 ~ rowDiv:", rowDiv)

                        if (selectedRowIndex === index) {
                            // const nestedGridContainer = window.$(gridRef.current).jqxGrid('getcellwidget', selectedRowIndex, 'nestedGridColumn');
                            // console.log("🚀 ~ nestedGridContainer:", nestedGridContainer)
                            if (nestedGridContainer) {
                                console.log("🚀 ~ nestedGridContainer:", nestedGridContainer)
                                // const nestedGridContainerId = window.$(nestedGridContainer).find('[id^="nestedGrid"]');

                                // const nestedGridId = nestedGridContainerId.attr('id');
                                // Select all rows in the nested grid
                                const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                                console.log("🚀 ~ nestedRows:", nestedRows)
                                for (let i = 0; i < nestedRows.length; i++) {
                                    window.$(nestedGridContainer).jqxGrid('selectrow', i);
                                    setGridNestedSelect(nestedRows);
                                }
                            }
                        }
                    });
                    window.$(gridElement).on('rowunselect', function (event) {
                        // console.log("🚀 ~ event:yyyyyy", event)
                        if (event.args.row) {
                            const unselectedRowId = event.args.row.uid;
                            // console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                            setGridNestedSelect(prevSelection => prevSelection.filter(row => row.uid !== unselectedRowId));
                        }
                        const unselectedRowIndex = event.args.rowindex;
                        if (unselectedRowIndex === index) {
                            if (nestedGridContainer) {
                                // Deselect all rows in the nested grid
                                
                                const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                                for (let i = 0; i < nestedRows.length; i++) {
                                    window.$(nestedGridContainer).jqxGrid('unselectrow', i);
                                }
                            }
                        }
                    });

                    // const isSelected = window.$(gridElement).jqxGrid('getselectedrowindexes').indexOf(index) !== -1;
                    // if (isSelected && nestedGridContainer) {
                    //     const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                    //     for (let i = 0; i < nestedRows.length; i++) {
                    //         window.$(nestedGridContainer).jqxGrid('selectrow', i);
                    //     }
                    // }
                };
                // Nested End

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: false,
                    editable: false,
                    selectionmode: 'checkbox',

                    initrowdetails: initrowdetails,
                    pageable: true,
                    pageSize: pageSize,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    rowdetails: true,
                    rowdetailstemplate: {
                        rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
                        rowdetailsheight: (15 * 45),
                        rowdetailshidden: true,
                    },

                };
                // const handleRowSelectUnselect = (event, gridElement) => {
                //     const rowIndex = event.args.rowindex;
                //     const isSelected = event.type === 'rowselect';

                //     // Check if the row details are already expanded and the nested grid is initialized
                //     const rowDetailsElement = window.$(gridElement).jqxGrid('getrowdetails', rowIndex);
                //     if (rowDetailsElement && rowDetailsElement.firstChild) {
                //         const nestedGrid = rowDetailsElement.firstChild;
                //         const nestedRows = window.$(nestedGrid).jqxGrid('getrows');
                //         for (let i = 0; i < nestedRows.length; i++) {
                //             if (isSelected) {
                //                 window.$(nestedGrid).jqxGrid('selectrow', i);
                //             } else {
                //                 window.$(nestedGrid).jqxGrid('unselectrow', i);
                //             }
                //         }
                //     }
                // };
                window.$(gridRef.current).off('click');
                window.$(gridRef.current).on('click', '.row_edit', function () {
                    const keysToRemove = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
                    keysToRemove.forEach(key => {
                        localStorage.removeItem(key);
                    });
                    // console.log("🚀 ~ event:01010", event)
                    const rowDiv = window.$(this).closest('[role="row"]');
                    // const cellDiv = this.id;
                    // console.log("🚀 ~ cellDiv:", rowDiv)
                    const rowIndex = window.$(rowDiv).attr('row-id');
                    const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
                    onAirEdit(rowData.flight_number, rowData.flight_date);

                    // const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                    // console.log("🚀 ~ event:01010", rowData)

                    // const storageData = {
                    //     "ULD": [],  // ULD Details section
                    //     "Form": rowData  // Empty form section
                    // };
                    // localStorage.setItem('UCM Out', JSON.stringify(storageData));
                    // localStorage.setItem('tab', 'onair');
                    // // console.log("🚀 ~ handleDropdownItemClick ~ uldId:", uldId)
                    // const fnPageUrl = '/ULDControl/ULDFunction';  // ${itemId} Change "/details/" to the actual path of your details page
                    // // Navigate to the details page
                    // window.location.href = fnPageUrl;
                });

                window.$(gridRef.current).jqxGrid(gridOptions);
                window.$(gridRef.current).jqxGrid('gotopage', initCurrentPage - 1);
                window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

                const handlePageChanged = (event) => {
                    const args = event.args;
                    console.log("🚀 ~ handlePageChanged ~ args:", args)
                    const pageNumber = args.pagenum;
                    const pageSize = args.pagesize;

                    if (pageSize !== totalRows.pageSize) {
                        fetchDataGrid(pageNumber, pageSize);
                    } else {
                        fetchDataGrid(pageNumber + 1, pageSize);
                    }
                };
                window.$(gridRef.current).on('pagechanged', handlePageChanged);

                window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });

                // row selection by checkbox
                // window.$(gridRef.current).on('rowselect', (event) => {
                //     console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
                //     // handleRowSelectUnselect(event, gridRef.current)
                //     if (event.args.row) {
                //         const selectedRow = event.args.row;
                //         console.log("🚀 ~ window.$ ~ selectedRow:", selectedRow)
                //         const selectedRowId = selectedRow.uid;
                //         if (!gridSelect.some(row => row.uid === selectedRowId)) {
                //             setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                //         }

                //         const details = window.$(gridRef.current).jqxGrid('getrowdetails', event.args.rowindex);
                //         console.log("🚀 ~ window.$ ~ details:", details)
                //         if (details && details.rowdetails && details.rowdetails.children && details.rowdetails.children.length > 0) {
                //             const nestedGridContainer = details.rowdetails.children[0];
                //             if (nestedGridContainer) {
                //                 const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                //                 for (let i = 0; i < nestedRows.length; i++) {
                //                     window.$(nestedGridContainer).jqxGrid('selectrow', i);
                //                 }
                //                 // Update gridNestedSelect with nested rows data
                //                 setGridNestedSelect(nestedRows);
                //             }
                //         }
                //     } else {
                //         if (event.args.rowindex.length > 0) {
                //             const allIds = gridData;
                //             setGridSelect(allIds);
                //         } else {
                //             setGridSelect([]);
                //         }
                //     }
                // });
                // window.$(gridRef.current).on('rowunselect', (event) => {
                //     console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                //     // handleRowSelectUnselect(event, gridRef.current)
                //     if (event.args.row) {
                //         const unselectedRowId = event.args.row.flight_number;
                //         console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                //         setGridSelect(prevSelection => prevSelection.filter(row => row.flight_number !== unselectedRowId));

                //         const details = window.$(gridRef.current).jqxGrid('getrowdetails', event.args.rowindex);
                //         if (details && details.rowdetails && details.rowdetails.children && details.rowdetails.children.length > 0) {
                //             const nestedGridContainer = details.rowdetails.children[0];
                //             if (nestedGridContainer) {
                //                 // Make sure to operate on the specific nested grid for the unselected parent row
                //                 const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                //                 for (let i = 0; i < nestedRows.length; i++) {
                //                     window.$(nestedGridContainer).jqxGrid('unselectrow', i);
                //                 }
                //                 // Clear the relevant nested rows from gridNestedSelect
                //                 setGridNestedSelect(prev => prev.filter(row => !nestedRows.some(nestedRow => nestedRow.uld_number === row.uld_number)));
                //             }
                //         }
                //     }
                // });
                // window.$(gridRef.current).on('rowselect', (event) => {
                //     const { rowindex, row } = event.args;
                //     const selectedRowIndex = event.args.rowindex;
                //     const nestedGridContainerId = `#nestedGrid${selectedRowIndex}`;
                //     const nestedGridContainer = window.$(nestedGridContainerId);
                //     const nestedRows = nestedGridContainer.jqxGrid('getrows');
                //     for (let i = 0; i < nestedRows.length; i++) {
                //         window.$(nestedGridContainer).jqxGrid('selectrow', i);
                //     }
                // });
                // window.$(gridRef.current).on('rowunselect', (event) => {
                //     const { rowindex, row } = event.args;
                //     // Find the nested grid for the unselected parent row
                //     // const nestedGridContainerId = `#contenttablenestedGrid${rowindex}`; // Generate the ID dynamically based on the index
                //     // const nestedGridContainer = window.$(nestedGridContainerId);
                //     const selectedRowIndex = event.args.rowindex;
                //     const nestedGridContainerId = `#nestedGrid${selectedRowIndex}`;
                //     const nestedGridContainer = window.$(nestedGridContainerId);
                //     const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                //     for (let i = 0; i < nestedRows.length; i++) {
                //         window.$(nestedGridContainer).jqxGrid('unselectrow', i);
                //     }
                // });

                // window.$(gridRef.current).on('rowselect', (event) => {
                //     if (event.args.row) {
                //         const selectedRow = event.args.row;
                //         const selectedRowId = selectedRow.uid;

                //         // Update parent selection state
                //         setGridSelect(prevGridSelect => [...prevGridSelect, selectedRow]);

                //         // Get nested grid details
                //         const details = window.$(gridRef.current).jqxGrid('getrowdetails', event.args.rowindex);
                //         if (details && details.rowdetails && details.rowdetails.children && details.rowdetails.children.length > 0) {
                //             const nestedGridContainer = details.rowdetails.children[0];
                //             if (nestedGridContainer) {
                //                 // Clear previously selected nested rows
                //                 const prevSelectedNestedRows = gridNestedSelect.filter(row => row.parentId !== selectedRowId);
                //                 setGridNestedSelect(prevSelectedNestedRows);

                //                 // Select current nested rows
                //                 const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                //                 for (let i = 0; i < nestedRows.length; i++) {
                //                     window.$(nestedGridContainer).jqxGrid('selectrow', i);
                //                 }
                //                 // Update nested selection state
                //                 setGridNestedSelect(prev => [...prev, ...nestedRows.map(row => ({ ...row, parentId: selectedRowId }))]);
                //             }
                //         }
                //     }
                // });
                // row selection by checkbox
                window.$(gridRef.current).on('rowselect', (event) => {
                    console.log("🚀 ~ window.$ ~ event:00000000", event)
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        if ('flight_number' in selectedRow) {
                            const selectedRowId = selectedRow.uid;
                            if (!gridSelect.some(row => row.uid === selectedRowId)) {
                                setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                            }
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData;
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRef.current).on('rowunselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.uid;
                        console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                        setGridSelect(prevSelection => prevSelection.filter(row => row.uid !== unselectedRowId));
                        // setGridNestedSelect([]);
                    }
                });
                // End row selection 
                // window.$(gridRef.current).on('rowunselect', (event) => {
                //     if (event.args.row) {
                //         const unselectedRow = event.args.row;
                //         const unselectedRowId = unselectedRow.uid;

                //         // Update parent selection state
                //         setGridSelect(prevSelection => prevSelection.filter(row => row.uid !== unselectedRowId));

                //         // Get nested grid details
                //         const details = window.$(gridRef.current).jqxGrid('getrowdetails', event.args.rowindex);
                //         if (details && details.rowdetails && details.rowdetails.children && details.rowdetails.children.length > 0) {
                //             const nestedGridContainer = details.rowdetails.children[0];
                //             if (nestedGridContainer) {
                //                 // Unselect all rows in the nested grid
                //                 const nestedRows = window.$(nestedGridContainer).jqxGrid('getrows');
                //                 for (let i = 0; i < nestedRows.length; i++) {
                //                     window.$(nestedGridContainer).jqxGrid('unselectrow', i);
                //                 }
                //                 // Clear the relevant nested rows from gridNestedSelect
                //                 setGridNestedSelect(prev => prev.filter(row => !nestedRows.some(nestedRow => nestedRow.uld_number === row.uld_number)));
                //             }
                //         }
                //     }
                // });
                // End row selection 
                return () => {
                    window.$(gridRef.current).off('pagechanged', handlePageChanged);
                };

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
        window.$(gridRef.current).on('cellclick', (event) => {
            // console.log('event', event);
            const args = event.args.row.bounddata;
            if (event.args.datafield == 'flight_truck') {
                // console.log('args', args);
                const sendRowId = gridData;
                setSelectedRowData(args);
            }
        });
    }, [gridData, selectedRowData]);

    const onAirEdit = async (number, date) => {
        try {
            const res = await withRFSToken.post(EDITONAIR, { flight_number: number, flight_date: date })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ innerData:", innerData)
                    // const [flight_code, flight_number] = innerData.Form.flight_number.split('-');

                    // const updatedForm = {
                    //     ...innerData.Form,
                    //     flight_code,
                    //     flight_number
                    // };
                    const storageData = {
                        "ULD": innerData.uld_level,  // ULD Details section
                        "Form": innerData.Form,  // Empty form section
                        "Edit": 'onAir'
                    };
                    localStorage.setItem('UCM Out', JSON.stringify(storageData));
                    localStorage.setItem('tab', 'onair');
                    const fnPageUrl = '/ULDControl/ULDFunction';  // ${itemId} Change "/details/" to the actual path of your details page
                    // Navigate to the details page
                    window.location.href = fnPageUrl;
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [downloadOpen, setDownloadOpen] = useState(false);
    const showDownloadModal = () => {
        setDownloadOpen(true);
    };
    const closeDownloadModal = () => {
        setDownloadOpen(false);
    };
    // const [selectedColumns, setSelectedColumns] = useState([]);
    // const [isModalOpen, setModalOpen] = useState(false);
    // const columnsToExport = {
    //     // uld_number: 'ULD Number',
    //     // type_code: 'Type Code',
    //     flight_date: 'Flight Date',
    //     // owner_code: 'Owner Code',
    //     // condition_code: 'Condition Code',
    //     // status: 'Status',
    //     origin: 'Origin',
    //     destination: 'Destination',
    //     // scm: 'SCM',
    //     // scm_date: 'SCM Date',
    //     // asset_type: 'Asset Type',
    //     // handler_name: 'Handler Name',
    //     // airline_name: 'Airline Name',
    //     // Add more columns here if needed
    // };
    // const handleColumnChange = (columnKey) => {
    //     setSelectedColumns(prevSelected =>
    //         prevSelected.includes(columnKey)
    //             ? prevSelected.filter(key => key !== columnKey)
    //             : [...prevSelected, columnKey]
    //     );
    // };
    // const exportToExcel = async () => {

    //     // try {
    //     //     const res = await withRFSToken.post(DOWNLOADULD, { tab_name: activeTab, fields: selectedColumns })
    //     //         .then(function (res) {
    //     //             const innerData = res.data.response_data.ulds;
    //     //             console.log("🚀 ~ .then ~ innerData:987", innerData)
    //     //             // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
    //     //             // setGridData({ Active: innerData });
    //     //             // setTotalRecords(res.data.response_data.total_ulds);
    //     //         });


    //     // } catch (error) {
    //     //     console.error('Error fetching data:', error);
    //     // }
    //     if (gridRef.current) {
    //         const excelData = window.$(gridRef.current).jqxGrid('getrows');

    //         // Filter and transform the grid data to include only the specified columns with desired names
    //         const filteredData = excelData.map(row => {
    //             const filteredRow = {};
    //             // Object.keys(columnsToExport).forEach(key => {
    //             //     filteredRow[columnsToExport[key]] = row[key];
    //             // });
    //             selectedColumns.forEach(key => {
    //                 filteredRow[columnsToExport[key]] = row[key];
    //             });
    //             return filteredRow;
    //         });
    //         const worksheet = XLSX.utils.json_to_sheet(filteredData);

    //         // Create a new workbook and append the worksheet
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, 'GridData');

    //         // Write the workbook to a file and trigger a download
    //         XLSX.writeFile(workbook, 'GridData.xlsx');
    //         setSelectedColumns([]);
    //         setModalOpen(false);
    //     }
    // };

    // +++++++++++++++++++ div +++++++++++++++++++++++++

    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };


    // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload 

    const [html, setHtml] = useState('');

    function onChange(e) {
        setHtml(e.target.value);
    }

    // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider

    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFile('');
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image
    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    const handleDropdownItemClick = async (action) => {
        // console.log("Event:", action);
        // event.preventDefault();
        // setShowConfirmation(true);
        const keysToRemove = ['Repair', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
        keysToRemove.forEach(key => {
            localStorage.removeItem(key);
        });

        if (selectedRowData) {
            const storageData = {
                "ULD": selectedRowData,  // ULD Details section
                "Form": {}  // Empty form section
            };
            localStorage.setItem(action, JSON.stringify(storageData));
            localStorage.setItem('tab', 'onair');

            const uldId = selectedRowData.uld_number;
            const fnPageUrl = `/ULDControl/ULDFunction?tab=onair&ids=${uldId}&action=${action}`;  // ${itemId} Change "/details/" to the actual path of your details page
            // Navigate to the details page
            window.location.href = fnPageUrl;
        } else if (gridSelect.length === 0) {
            toast.error('Please select atleast one flight to Operate')
        } else {
            // if (['Repair', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out'].includes(action)){
            console.log("🚀 ~ handleDropdownItemClick ~ gridSelect:", gridSelect)
            const storageData = {
                "ULD": gridSelect,  // ULD Details section
                "Form": {}  // Empty form section
            };
            localStorage.setItem(action, JSON.stringify(storageData));
            localStorage.setItem('tab', 'onair');

            const uldIds = gridSelect.map(item => item.flight_number);
            if (uldIds.length > 1) {
                toast.error('You Can process only one flight at a time')
            } else {
                const keysToRemove = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
                keysToRemove.forEach(key => {
                    localStorage.removeItem(key);
                });
                try {
                    const res = await withRFSToken.post(EDITONAIR, { flight_number: gridSelect[0].flight_number, flight_date: gridSelect[0].flight_date })
                        .then(function (res) {
                            const innerData = res.data.response_data;
                            console.log("🚀 ~ innerData:", innerData)
                            const storageData = {
                                "ULD": innerData.uld_level,  // ULD Details section
                                "Form": innerData.Form,  // Empty form section
                                "Edit": 'onAir'
                            };
                            localStorage.setItem('UCM In', JSON.stringify(storageData));
                            localStorage.setItem('tab', 'onair');
                            const fnPageUrl = '/ULDControl/ULDFunction';  // ${itemId} Change "/details/" to the actual path of your details page
                            // Navigate to the details page
                            window.location.href = fnPageUrl;
                        });
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                // const fnPageUrl = `/ULDControl/ULDFunction?tab=onair&ids=${uldIds}&action=${action}`;  // ${itemId} Change "/details/" to the actual path of your details page
                // Navigate to the details page
                // window.location.href = fnPageUrl;
            }

            // } 

        }
    }
    

    return (
        <>
            {downloadOpen && <DownloadGrid download_sec='oa' onClose={closeDownloadModal} />}
            <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex'>
                    {console.log('5578', gridSelect)}
                    {console.log('5579', gridNestedSelect)}
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='action'>
                            <FontAwesomeIcon icon={faGear} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('UCM In')}>UCM In</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='ulddown'>
                <button onClick={showDownloadModal}><img src={Export} /> Download</button>
            </div>

            <div className="maindiv">
                <div className='trashdonload'>
                    <div ref={gridRef} className='bookinggrid' ></div>
                    {/* <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                        <Tab eventKey="Flight" title={<span>Flight Level <Badge className='rabadge'>{gridData[activeTab].length}</Badge></span>} className='moveto'>
                            
                            <div ref={gridRefs.Flight} className='bookinggrid' ></div>
                        </Tab>
                        <Tab eventKey="ULD" title={<span>ULD Level <Badge className='rabadge'>{gridData[activeTab].length}</Badge></span>} className='moveto'>
                            <div ref={gridRefs.ULD} className='bookinggrid' ></div>
                        </Tab>

                    </Tabs> */}
                </div>
                {selectedRowData && (
                    <div className='SecondDiv'>
                        {/* {console.log('55', selectedRowData)} */}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.bookingtruck_no} <span>{selectedRowData.status === 'A' ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />}</span> <span>{selectedRowData.status === 'A' ? 'Planned' : 'To be planned'}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={handleBrowseFiles} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faBolt} /> Action
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('UCM In')}>UCM In</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Origin</p>
                                <span>{selectedRowData.origin}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.date}</p>
                                <p><img src={Cargoplane} /></p>
                                <p>{selectedRowData.time}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>destination</p>
                                <span>{selectedRowData.destination}</span>
                            </div>
                        </div>
                        <div>
                            <h3>Operational Trucks</h3>

                            {/* {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                               <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                                   <div>
                                       <span>Truck No</span>
                                       <h4>{truck.truck_no}</h4>
                                   </div>
                                   <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                                       <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                                   </div>
                                   <div>
                                       <Link>Details</Link>
                                   </div>
                               </div>
                           ))} */}
                        </div>

                        <ImageUpload attachable_type={"uld"} attachable_id={selectedRowData.bookingtruck_id} attachments={selectedRowData.attachments} />
                        <div>
                            <ActivityComments commentable_type={"uld"} commentable_id={selectedRowData.bookingtruck_id} allactivities={selectedRowData.activities} />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Onair