import React, { useState, useEffect, useRef } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import Header from "../../../../Componenets/Header/Header";
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
import Export from "../../../../assets/images/export.svg";
import View from "../../../../assets/images/view.svg";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import Cargoplane from "../../../../assets/images/plane.svg";
import Cuser from "../../../../assets/images/cuser.png";
import { Link } from 'react-router-dom';
import ActivityComments from '../../../../Componenets/common/ActivityComments';
import ImageUpload from '../../../../Componenets/common/ImageUpload';
import { MVTHDETAILS } from '../../../../Utils/conts';
import { withRFSToken } from '../../../../Services/axios';
// import { useHistory, useLocation } from 'react-router-dom';

const MovementHistory = () => {

    // const queryParameters = new URLSearchParams(window.location.search);
    // const Uldid = queryParameters.get("uld_number");
    // const initialTab = queryParameters.get("tab") || 'All';

    let storedData = localStorage.getItem('MHistory');

    if (storedData) {
        storedData = JSON.parse(storedData)
    }
    console.log("🚀 ~ MovementHistory ~ storedData:", storedData)
    const initialTab = storedData.tab || 'All';
    const Uldid = storedData.ULD.uld_number
    const UldAct = storedData.ULD.activities


    // const history = useHistory();
    // const location = useLocation();

    // if (Uldid){
    //     setUldNumber(Uldid);
    //     queryParameters.delete('uld_number');
    //     history.push({
    //         pathname: location.pathname,
    //       });
    //     window.location.replace('/ULDControl/MovementHistory')
    // }
    const [uldNumber, setUldNumber] = useState(Uldid);

    const [gridData, setGridData] = useState({
        All: [],
        UCM: [],

    });
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const [activeTab, setActiveTab] = useState(initialTab);
    const gridRefs = {
        All: useRef(null),
        UCM: useRef(null),
        LUC: useRef(null),
        Repair: useRef(null),
        Lease: useRef(null),
        SCM: useRef(null),
        Damaged: useRef(null),
    };
    useEffect(() => {
        setActiveTab(initialTab);
        let storedData = localStorage.getItem('MHistory');

        if (storedData) {
            storedData = JSON.parse(storedData)
        }
        if (storedData) {
            const allUldHistory = storedData.ULD.movement_history
            const hasLUC = allUldHistory.some(item => item.operation_type && item.operation_type.includes('LUC'));
            const ucmRows = allUldHistory.filter(item => item.operation_type && item.operation_type.includes('UCM'));
            const repairRows = allUldHistory.filter(item => item.operation_type && item.operation_type.includes('Repair'));
            const leaseRows = allUldHistory.filter(item => item.operation_type && item.operation_type.includes('Lease'));
            const scmRows = allUldHistory.filter(item => item.operation_type && item.operation_type.includes('SCM'));
            // const damRows = allUldHistory.filter(item => item.condition_code && item.condition_code.includes('DAM'));
            const damRows = allUldHistory.filter(item => item.condition_code && item.condition.includes('Damaged')).map(item => {
                const [date, time] = item.operation_date.split(' ');
                return {
                    ...item,
                    date_check: date,
                    time_check: time
                };
            });
            const lucRows = allUldHistory.filter(item => item.operation_type && item.operation_type.includes('LUC')).map(item => {
                const [date, time] = item.operation_date.split(' ');
                return {
                    ...item,
                    transfer_date: date,
                    transfer_time: time
                };
            });
            // console.log("🚀 ~ useEffect ~ hasLUC:", hasLUC)
            setGridData({ All: allUldHistory || '', UCM: ucmRows || '', LUC: lucRows || '', Repair: repairRows || '', Lease: leaseRows || '', SCM: scmRows || '', Damaged: damRows || '' })
        }

    }, [initialTab]);
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (gridRefs[activeTab].current) {
            // if (gridData[activeTab]) {
            const source = {
                datatype: 'json',
                id: '',
                datafields: [
                    { name: 'id', type: 'string' },
                    { name: 'uld_status_id', type: 'string' },
                    { name: 'operation_type', type: 'string' },
                    { name: 'operation_date', type: 'string' },
                    { name: 'origin', type: 'string' },
                    { name: 'destination', type: 'string' },

                    { name: 'ucm_inout', type: 'string' },
                    { name: 'flight_number', type: 'string' },
                    { name: 'flight_date', type: 'string' },
                    { name: 'flight_reg_number', type: 'string' },
                    { name: 'content_code', type: 'string' },
                    { name: 'condition_code', type: 'string' },

                    // { name: 'luc_inout', type: 'string' },
                    { name: 'transfer_date', type: 'string' },
                    { name: 'transfer_time', type: 'string' },
                    { name: 'transferred_by', type: 'string' },
                    { name: 'received_by', type: 'string' },
                    { name: 'transfer_point', type: 'string' },
                    { name: 'control_receipt', type: 'string' },
                    { name: 'luc_final_dest', type: 'string' },

                    { name: 'repair_inout', type: 'string' },
                    { name: 'repair_name', type: 'string' },
                    { name: 'repair_station', type: 'string' },

                    { name: 'lease_inout', type: 'string' },

                    // { name: 'repair_station', type: 'string' },
                    { name: 'date_check', type: 'string' },
                    { name: 'time_check', type: 'string' },
                ],
                localdata: gridData[activeTab],
            };

            const dataAdapter = new window.$.jqx.dataAdapter(source);
            console.log('localdata', source.localdata)
            let columns;
            if (activeTab === 'All') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'Movement Type', filtertype: 'textbox', datafield: 'operation_type', width: '11%', cellclassname: '', },
                    { text: 'Timestamp', filtertype: 'textbox', datafield: 'operation_date', width: '12%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '10%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '10%', cellclassname: '', },

                ];
            } else if (activeTab === 'UCM') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'UCM In/Out', filtertype: 'textbox', datafield: 'operation_type', width: '11%', cellclassname: '', },
                    { text: 'Timestamp', filtertype: 'textbox', datafield: 'operation_date', width: '10%', cellclassname: '', },
                    { text: 'Flight Number', filtertype: 'textbox', datafield: 'flight_number', width: '10%', cellclassname: '', },
                    { text: 'Flight Date', filtertype: 'textbox', datafield: 'flight_date', width: '10%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '10%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '10%', cellclassname: '', },
                    { text: 'Registration Number', filtertype: 'textbox', datafield: 'flight_reg_number', width: '8%', cellclassname: '', },
                    { text: 'Content Code', filtertype: 'textbox', datafield: 'content_code', width: '6%', cellclassname: '', },
                    { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition_code', width: '6%', cellclassname: '', },

                ];
            } else if (activeTab === 'LUC') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'LUC In/Out', filtertype: 'textbox', datafield: 'operation_type', width: '11%', cellclassname: '', },
                    { text: 'Date of Transfer', filtertype: 'textbox', datafield: 'transfer_date', width: '10%', cellclassname: '', },
                    { text: 'Time of Transfer', filtertype: 'textbox', datafield: 'transfer_time', width: '10%', cellclassname: '', },
                    { text: 'Transferred By', filtertype: 'textbox', datafield: 'transferred_by', width: '10%', cellclassname: '', },
                    { text: 'Received By', filtertype: 'textbox', datafield: 'received_by', width: '10%', cellclassname: '', },
                    { text: 'Transfer Point', filtertype: 'textbox', datafield: 'transfer_point', width: '6%', cellclassname: '', },
                    { text: 'Control Receipt No.', filtertype: 'textbox', datafield: 'control_receipt', width: '8%', cellclassname: '', },
                    { text: 'Final DSestination', filtertype: 'textbox', datafield: 'luc_final_dest', width: '6%', cellclassname: '', },
                    { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition_code', width: '6%', cellclassname: '', },

                ];
            } else if (activeTab === 'Repair') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'Repair In/Out', filtertype: 'textbox', datafield: 'operation_type', width: '11%', cellclassname: '', },
                    { text: 'Timestamp', filtertype: 'textbox', datafield: 'operation_date', width: '10%', cellclassname: '', },
                    { text: 'Repair Name', filtertype: 'textbox', datafield: 'repair_name', width: '10%', cellclassname: '', },
                    { text: 'Repair Station', filtertype: 'textbox', datafield: 'repair_station', width: '10%', cellclassname: '', },
                ];
            } else if (activeTab === 'Lease') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'Lease In/Out', filtertype: 'textbox', datafield: 'operation_type', width: '11%', cellclassname: '', },
                    { text: 'Timestamp', filtertype: 'textbox', datafield: 'operation_date', width: '10%', cellclassname: '', },

                ];
            } else if (activeTab === 'SCM') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'Station', filtertype: 'textbox', datafield: 'station', width: '11%', cellclassname: '', },
                    { text: 'Date of Check', filtertype: 'textbox', datafield: 'date_check', width: '10%', cellclassname: '', },
                    { text: 'Time of Check', filtertype: 'textbox', datafield: 'time_check', width: '10%', cellclassname: '', },

                ];
            } else if (activeTab === 'Damaged') {
                columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'View',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + View + '" class="view_details" /></div>';
                        },
                        width: '5%'
                    },
                    { text: 'Station', filtertype: 'textbox', datafield: 'repair_station', width: '11%', cellclassname: '', },
                    { text: 'Date of Check', filtertype: 'textbox', datafield: 'date_check', width: '10%', cellclassname: '', },
                    { text: 'Time of Check', filtertype: 'textbox', datafield: 'time_check', width: '10%', cellclassname: '', },

                ];
            }

            const gridOptions = {
                width: '100%',
                source: dataAdapter,
                columns: columns,
                autoheight: true,
                sortable: true,
                altrows: true,
                enabletooltips: true,
                editable: false,
                selectionmode: 'multiplecellsadvanced',
                columnsreorder: true, // Enable column reordering
                columnsresize: true,  // Enable column resizing
                // selectionmode: 'checkbox',
                pageable: true,
                pageSize: 20,
                pagerButtonsCount: 5,
                pagerMode: 'advanced',
                filterable: true,
                filterrowheight: 45,
                showfilterrow: true,
                rowsheight: 42,
                columnsheight: 45,

            };

            window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
            window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

            window.$(gridRefs[activeTab].current).on('click', '.view_details', function (row) {
                console.log('ggggg');
                // const uldId = queryParameters.get("uld_number");

                const uldId = storedData.ULD.uld_number;

                const rowDiv = window.$(this).closest('[role="row"]');
                const rowIndex = window.$(rowDiv).attr('row-id');
                const rowData = window.$(gridRefs[activeTab].current).jqxGrid('getrowdata', rowIndex);
                console.log("🚀 ~ rowData:", rowData);
                handleDetailsClick(rowData);
                // localStorage.removeItem('MHDetails');
                // const storageData = {
                //     ULD: rowData,  // ULD Details section
                //     tab: activeTab  // Empty form section
                // };
                // localStorage.setItem('MHDetails', JSON.stringify(storageData));
                // let detailsPageUrl;
                // const detailsType = rowData.operation_type || rowData.ucm_inout || rowData.luc_inout || rowData.repair_inout || rowData.lease_inout || rowData.station;
                // if (detailsType) {

                //     detailsPageUrl = '/ULDControl/MovementHistorydetais';
                //     window.location.href = detailsPageUrl;
                // } else {
                //     console.log('No valid details type found');
                // }
            });
            // window.$(gridRefs[activeTab].current).on('rowclick', (event) => {
            //     // console.log('event', event);
            //     const args = event.args.row.bounddata;
            //     // console.log('args', args);
            //     const sendRowId = gridData;
            //     // console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
            //     setSelectedRowData({ args, station_id: args.id });

            // });
            window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
                window.$(row).on('mouseover', () => {
                    window.$(row).addClass('row-hover');
                });
                window.$(row).on('mouseout', () => {
                    window.$(row).removeClass('row-hover');
                });
            });
            // row selection by checkbox
            // window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
            //     console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

            //     if (event.args.row) {

            //         const selectedRow = event.args.row.id;

            //         if (!gridSelect.includes(selectedRow)) {
            //             setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
            //         }
            //         setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
            //     } else {
            //         // Handle "Select All" case
            //         // const allIds = planData.map(row => row.booking_id);
            //         // console.log("All IDs:", allIds);
            //         // setGridSelect(allIds);
            //         console.log("Select All triggered");
            //     }
            // });
            // console.log('ggg', gridSelect);
            // window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
            //     if (event.args.row) {
            //         const unselectedRowId = event.args.row.id;
            //         setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
            //     } else {
            //         setGridSelect([]);
            //     }
            // });
            // End row selection 

            // } else {
            //     const columns = [
            //         { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
            //         { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
            //     ];
            // }
        }
    }, [activeTab, gridData, selectedRowData]);

    // window.$(gridRefs[activeTab].current).on('click', '.view_details', function (row) {
    //     console.log('ggggg');

    //     // const uldId = queryParameters.get("uld_number");

    //     const uldId = storedData.ULD.uld_number;


    //     const rowDiv = window.$(this).closest('[role="row"]');
    //     const rowIndex = window.$(rowDiv).attr('row-id');
    //     const rowData = window.$(gridRefs[activeTab].current).jqxGrid('getrowdata', rowIndex);
    //     console.log("🚀 ~ rowData:", rowData);
    //     const storageData = {
    //         ULD: rowData,  // ULD Details section
    //         tab: activeTab  // Empty form section
    //     };
    //     localStorage.setItem('MHDetails', JSON.stringify(storageData));
    //     let detailsPageUrl;
    //     const detailsType = rowData.movement_type || rowData.ucm_inout || rowData.luc_inout || rowData.repair_inout || rowData.lease_inout || rowData.station;
    //     if (detailsType) {

    //         detailsPageUrl = `/ULDControl/MovementHistorydetais`;
    //         window.location.href = detailsPageUrl;
    //     } else {
    //         console.log('No valid details type found');
    //     }
    // });
    const handleDetailsClick = async (history) => {

        try {
            const res = await withRFSToken.post(MVTHDETAILS, { uld_id: storedData.ULD.uld_id, uld_status_id: history.uld_status_id })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    localStorage.removeItem('MHDetails');
                    const storageData = {
                        ULD: innerData.others_ulds,  // ULD Details section
                        Form: innerData.uld_movement_detail,
                        tab: activeTab
                    };
                    localStorage.setItem('MHDetails', JSON.stringify(storageData));
                    // localStorage.setItem('tab', activeTab);
                    let detailsPageUrl;
                    const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
                    if (detailsType) {

                        detailsPageUrl = '/ULDControl/MovementHistorydetails';
                        window.location.href = detailsPageUrl;
                    } else {
                        console.log('No valid details type found');
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }



    };

    return (
        <>
            <div className="">
                <Header />


                <div className='main-panel'>
                    <Link to="/ULDControl" className='stk_back'> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
                    <div className='maindiv'>
                        <div className='SecondDiv mt-3'>
                            {/* <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button> */}
                            <div className='girddtals_header'>
                                <h3>
                                    {storedData.ULD.uld_number} <span>{storedData.ULD.status} </span>
                                </h3>
                                <div className='d-flex actbtn'>

                                    <Button variant="light" onClick='' > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                            <FontAwesomeIcon icon={faBolt} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Edit Bookingtruck</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Manage Shipments</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Order Truck</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>

                            </div>
                            <div className='d-flex justify-content-between rabord'>
                                <div className='firstbrd'>
                                    <p>Origin</p>
                                    <span>{storedData.ULD.origin} </span>
                                </div>
                                <div className='middlebrd'>
                                    <p>{storedData.ULD.flight_date}</p>
                                    <p><img src={Cargoplane} /></p>
                                    <p>{storedData.ULD.flight_number}</p>
                                </div>
                                <div className='lastbrd'>
                                    <p>Destination</p>
                                    <span>{storedData.ULD.destination} </span>
                                </div>
                            </div>

                            <div className='movment_history'>
                                <h3>Movement History</h3>
                            </div>
                            <div className='movment_list'>
                                <ul className='mh'>
                                    {storedData.ULD && storedData.ULD.movement_history.slice(0, 5).map(history => (
                                        <li>{console.log('glll', history)}
                                            <span className='movem'>{history.operation_type}</span> <span className='mvdate'>{history.operation_date}</span>
                                            <Link to="#" onClick={() => handleDetailsClick(history)}>Details</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <ImageUpload attachable_type={"uld"} attachable_id={Uldid} attachments={storedData.ULD.attachments} />
                            <ActivityComments commentable_type={"uld"} commentable_id={Uldid} allactivities={UldAct} />
                        </div>
                        <div className='trashdonload'>
                            {/* {console.log('000', gridRefs.Active)} */}
                            <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                                <Tab eventKey="All" title={<span>All</span>} className='moveto'>
                                    <div ref={gridRefs.All} className='bookinggrid' ></div>
                                </Tab>
                                <Tab eventKey="UCM" title={<span>UCM</span>} className='moveto'>

                                    <div ref={gridRefs.UCM} className='bookinggrid' ></div>
                                </Tab>

                                <Tab eventKey="LUC" title={<span>LUC</span>} className='moveto'>
                                    <div ref={gridRefs.LUC} className='bookinggrid' ></div>
                                </Tab>
                                <Tab eventKey="Repair" title={<span>Repair</span>} className='moveto'>
                                    <div ref={gridRefs.Repair} className='bookinggrid' ></div>
                                </Tab>
                                <Tab eventKey="Lease" title={<span>Lease</span>} className='moveto'>
                                    <div ref={gridRefs.Lease} className='bookinggrid' ></div>
                                </Tab>
                                <Tab eventKey="SCM" title={<span>SCM</span>} className='moveto'>
                                    <div ref={gridRefs.SCM} className='bookinggrid' ></div>
                                </Tab>
                                <Tab eventKey="Damaged" title={<span>Damaged</span>} className='moveto'>
                                    <div ref={gridRefs.Damaged} className='bookinggrid' ></div>
                                </Tab>
                            </Tabs>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default MovementHistory;