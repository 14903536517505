import React from "react";
import '../../App.css';
import logo from '../../assets/images/logo.png';

const Forgotpassword = () =>{


    return(
        <section className="vh-100">
            <div className="container-fluid">
                <div className="row d-flex justify-content-between align-items-center vh-100">
                    <div className="loginform-left vh-100 justify-content-around align-items-center d-flex">
                        <span><img src={logo} /></span>
                    </div>
                    <div className="logform">
                        <form>
                            <p>Login</p>
                            <h2>Welcome Back :)</h2>
                            <div className="form-outline mb-4">
                                <input type="email" id="form3Example3" className="form-control form-control-lg"
                                placeholder="" />
                                <label className="form-label" htmlFor="form3Example3">Email address</label>
                            </div>
                            <div className="form-outline mb-3">
                                <input type="password" id="form3Example4" className="form-control form-control-lg"
                                placeholder="" />
                                <label className="form-label" htmlFor="form3Example4">Password</label>
                            </div>
                            <div className="d-flex justify-content-space align-items-center">
                                <div className="form-check mb-0">
                                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                                    <label className="form-check-label" htmlFor="form2Example3">
                                        Remember me
                                    </label>
                                </div>
                                <a href="#!" className="text-forgot">Forgot password?</a>
                            </div>
                            <div className="text-center text-lg-start mt-4 pt-2">
                                <button type="button" className="btn btn-primary btn-lg"
                                style={{paddingLeft: 1.5 + 'rem', paddingRight: 1.5 + 'rem'}}>Login Now</button>
                                <p className="small mt-2 pt-1 mb-0">Don't have an account? <a href="/registerPage"
                                    className="link-danger">Create Now</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Forgotpassword;