import { faBars, faClose, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';

import { DropdownHeader } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Acontrol from '../../assets/images/access-control.png';
import Air from '../../assets/images/air.png';
import Awb from '../../assets/images/awb.png';
import bell from '../../assets/images/bell.png';
import logo from '../../assets/images/cargohub-logo.png';
import Profile from "../../assets/images/fds.png";
import Filter from '../../assets/images/filter.png';
import grid from '../../assets/images/grid.png';
import Parcels from '../../assets/images/parcels.png';
import Rfstruck from '../../assets/images/rfstruck.png';
import Setting from '../../assets/images/setting.png';
import Settings from '../../assets/images/settings.png';
import Driver from '../../assets/images/shipment.png';
import Travel from '../../assets/images/travel.png';
import { useAuth, useAuthRFS, useAuthULD } from '../../useAuth/useAuth';
const Header = () => {
    const isAuthenticated = useAuth();
    const isAuthenticatedRFS = useAuthRFS();
    const isAuthenticatedULD = useAuthULD();

    const toggleBodyClass = () => {
        document.body.classList.toggle('sidebar-icon-only');
    };

    const [isIconClosed, setIsIconClosed] = useState(false);

    const handleIconClick = () => {
        setIsIconClosed(!isIconClosed);
        document.body.classList.toggle('sidebar-icon-only');
        // Add your additional logic or toggle state as needed
    };

    const isSidebarIconOnly = document.body.classList.contains('sidebar-icon-only');

     const logOut=()=>{
       // const token = useAuth();
       console.log("logout==>",localStorage.getItem('token'));
       localStorage.clear();
    //    localStorage.removeItem("token");
    //    localStorage.removeItem("RFS");
       window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL)
    //    if(!token) {
    //     window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL)
    //     return null;
    // }


    //    localStorage.clear();
    //    const detailsPageUrl = `/`; 
    //   window.location.href = detailsPageUrl;
       
     }

     const handleNavigation = (path) => {
        const uldValue = localStorage.getItem('ULD');
        if (uldValue) {
            localStorage.setItem('RFS', uldValue);
            localStorage.removeItem('ULD');
            window.location.href = path;
        } else {
            // Handle the case where 'ULD' is not in localStorage
            console.log('ULD value not found in localStorage');
        }
    };
    const handleNavigationULD = (path) => {
        const uldValue = localStorage.getItem('RFS');
        if (uldValue) {
            localStorage.setItem('ULD', uldValue);
            localStorage.removeItem('RFS');
            window.location.href = path;
        } else {
            // Handle the case where 'ULD' is not in localStorage
            console.log('ULD value not found in localStorage');
        }
    };

    return (
        <>
            <div className="d-flex header-main">
            

                <div className="left-section">

                <div className="hmenu">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={handleIconClick}>
                    {/* {console.log('bar', document.body.classList.value)} */}
                    <FontAwesomeIcon icon={isSidebarIconOnly ? faClose : faBars} />
                </button>
            </div>
            
                    <a href={isAuthenticated ? '/planDashboard' : (isAuthenticatedRFS ? '/BookingtruckSchedule' : '/ULDControl')} className="logo-main"><img alt='CARGOHUB' src={logo} /></a>
                </div>
                <div className="right-section">
                    <ul className="navbar-nav">
                        <Link className="ralink" to={isAuthenticated ? '/UserSettings' : (isAuthenticatedRFS ? '/NotificationSettings' : '/GeneralSettings')} ><img alt='' src={Setting} /></Link>

                        <Dropdown className="switch">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <img alt='' src={bell} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-start" >
                                <DropdownHeader>
                                    <div className="d-flex justify-content-between align-items-center notify">
                                        <h2>Notifications</h2>
                                        <button type="button" class="btn btn-primary">5 New </button>
                                    </div>
                                </DropdownHeader>

                                <Dropdown.Item href="#/action-1">
                                    <div className="d-flex align-items-center">
                                        <div className="notifyimg"><img alt='' src={Profile} /></div>
                                        <div className="notifytxt">
                                            <p><b>Adam Nolan</b> mentioned you in UX</p>
                                            <p><small>9h ago . Note</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-1">
                                    <div className="d-flex align-items-center">
                                        <div className="notifyimg"><img alt='' src={Profile} /></div>
                                        <div className="notifytxt">
                                            <p><b>Adam Nolan</b> mentioned you in UX</p>
                                            <p><small>9h ago . Note</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-1">
                                    <div className="d-flex align-items-center">
                                        <div className="notifyimg"><img alt='' src={Profile} /></div>
                                        <div className="notifytxt">
                                            <p><b>Adam Nolan</b> mentioned you in UX</p>
                                            <p><small>9h ago . Note</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Item>

                                <Dropdown.Item href="#/action-1">
                                    <div className="d-flex align-items-center">
                                        <div className="notifyimg"><img alt='' src={Profile} /></div>
                                        <div className="notifytxt">
                                            <p><b>Adam Nolan</b> mentioned you in UX</p>
                                            <p><small>9h ago . Note</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Item>


                                <button type="button" class="btn btn-outline-primary seall">See All Notifications</button>
                            </Dropdown.Menu>
                        </Dropdown>
                        {isAuthenticatedRFS && 
                        <Dropdown className="allmodule">
                            <Dropdown.Toggle variant="link" id="dropdown-basic"> <img alt='' src={grid} /> </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <div className="allmodule_fl">
                                    <Dropdown.Item href="/">
                                        <img alt='' src={Driver} />
                                        <p>Driver Planning</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Air} />
                                        <p>Planned Trips</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Parcels} />
                                        <p>Shipments</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/ManageUsers">
                                        <img alt='' src={Settings} />
                                        <p>Setting</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Acontrol} />
                                        <p>Access</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/BookingtruckSchedule">
                                        <img alt='' src={Rfstruck} />
                                        <p>RFStrcking</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleNavigationULD('/ULDControl')}>
                                        <img alt='' src={Filter} />
                                        <p>ULD Control</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Awb} />
                                        <p>AWB Monitor</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Travel} />
                                        <p>Flight Monitor</p>
                                    </Dropdown.Item>
                                </div>

                            </Dropdown.Menu>
                        </Dropdown>
                        }
                        {isAuthenticatedULD && 
                        <Dropdown className="allmodule">
                            <Dropdown.Toggle variant="link" id="dropdown-basic"> <img alt='' src={grid} /> </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <div className="allmodule_fl">
                                    <Dropdown.Item href="/">
                                        <img alt='' src={Driver} />
                                        <p>Driver Planning</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Air} />
                                        <p>Planned Trips</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Parcels} />
                                        <p>Shipments</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/UserSettings">
                                        <img alt='' src={Settings} />
                                        <p>Setting</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Acontrol} />
                                        <p>Access</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleNavigation('/BookingtruckSchedule')}>
                                        <img alt='' src={Rfstruck} />
                                        <p>RFStrcking</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/ULDControl">
                                        <img alt='' src={Filter} />
                                        <p>ULD Control</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Awb} />
                                        <p>AWB Monitor</p>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                        <img alt='' src={Travel} />
                                        <p>Flight Monitor</p>
                                    </Dropdown.Item>
                                </div>

                            </Dropdown.Menu>
                        </Dropdown>
                        }
                        <Dropdown className="nav-profile">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <span>
                                    <span className="success">Authorised</span>
                                    {/* <br />Daniel Mckee */}
                                </span>
                                <span className="prof-img"><FontAwesomeIcon icon={faUser} /></span>
                                {/* <img alt='' src={face} alt="profile"/> */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div className="dropdown-item preview-item no-cursor">
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject font-weight-normal">Airlines (Admin)</h6>
                                            <p className="font-weight-light small-text mb-0 text-muted">
                                                Welcome Campus Airlines
                                            </p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                    <i className="ti-user text-primary"></i>
                                    My Account
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3" onClick={logOut}>
                                    <i className="ti-power-off text-primary"></i>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        
                    </ul>
                </div>
            </div>
            
        </>
    );
}
export default Header;