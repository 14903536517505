import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faBoxArchive, faTrashCan, faPencil, faEnvelope, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Prev } from 'react-bootstrap/esm/PageItem';
import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ADDSTATIONADDRESS } from '../../../Utils/conts.js';
import {  toast } from 'react-toastify';


const StationDetails = ({ rowData, stationID }) => {

    console.log('999', rowData);
    const [show, setShow] = useState(false);
    const modalShow = () => setShow(true)
    const modalClose = () => setShow(false);
    const storedData = rowData.args.Address;
    console.log("🚀 ~ StationDetails ~ storedData:", storedData)
    // const getLocalData = () => {
    //     // const storedData = localStorage.getItem("StationAddress");
    //     const storedData = rowData.args.Address;
    //     const Saddress = storedData ? JSON.parse(storedData) : [];
    //     const uaddress =  Saddress.map(addr => ({ ...addr, selected: false }));
    //     return uaddress;
    // }
    // useEffect(() => {
    //     setAddress(getLocalData());
    // }, []);

    const [inputAddress, setInputAddress] = useState({
        station_address_id: '', handler_name: '', address_1: '', address_2: '', postal_code: '', phone_number: '', email: ''
    });
    const [address, setAddress] = useState(storedData);

    console.log("🚀 ~ StationDetails ~ address:", address)
    // const dispatch = useDispatch();
    // Validation 
    const validateFormData = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Function to validate email
        const validateEmail = (email) => {
        return emailRegex.test(email);
        };
            if (!inputAddress.handler_name) {
                console.log('Text input is required');
                toast.error('Handler Name must not be blank');
                isValid = false;
              } else
              if (!inputAddress.address_1) {
                console.log('Text input is required');
                toast.error('Address must not be blank');
                isValid = false;
              } else
              if (!inputAddress.postal_code) {
                console.log('Text input is required');
                toast.error('Postal Code must not be blank');
                isValid = false;
              } else
              if (!inputAddress.phone_number) {
                console.log('Text input is required');
                toast.error('Phone Number must not be blank');
                isValid = false;
              } else
              if (!/^\d{10}$/.test(inputAddress.phone_number)) {
                console.log('10');
                toast.error('Phone Number must be 10 Digits');
                isValid = false;
              } else
              if (!inputAddress.email) {
                console.log('Text input is required');
                toast.error('Email must not be blank');
                isValid = false;
              } else if (!validateEmail(inputAddress.email)) {
                console.log('Invalid email address');
                toast.error('Invalid email address');
                isValid = false;
              }
              else{
                return isValid;
              }
      };
    

    const addAddress = async (e) => {
        if (validateFormData()) {
        e.preventDefault();
        // const newAddressID = Math.floor(Math.random() * 1000000);
        const stationid = rowData.station_id;
        const NewInputAddress = { ...inputAddress, station_id: stationid };
        console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
        const updatedAddress = [...address, NewInputAddress];
        // if (updatedAddress) {
        //     dispatch(addAddressData(NewInputAddress)); // Pass sendStationData to addData thunk
        //   }
        if (NewInputAddress) {
            try {
                const res = await withRFSToken.post(ADDSTATIONADDRESS, NewInputAddress)
                    .then(function (res) {
                        // const innerData = res;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        if (res.data.response_code == 200) {
                            // reloadGrid();
                            setAddress(res.data.response_data);
                        }
                    });

            } catch (error) {
                console.error('Error sending request:', error);
            }
            // Dispatch the action to add address data
            // dispatch(addAddressData(NewInputAddress))
            //     .then((response) => {
            //         // Check the response_code and update ready state if needed
            //         // const { response_code, response_data } = response;
            //         console.log("🚀 ~ .then ~ response_data:", response)
            //         // if (response_code === 200) {
            //         //     // Update ready state or perform any other actions with response_data
            //         //     setAddress([...address, response_data]);
            //         // } else {
            //         //     // Handle other response codes if necessary
            //         // }
            //     })
            //     .catch((error) => {
            //         // Handle errors if the API call fails
            //         console.error('Error adding address data:', error);
            //     });
        }
        // setAddress(updatedAddress);
        // localStorage.setItem('stationAddresses', JSON.stringify(updatedAddress));
        // setAddress([...address, NewInputAddress]);
        setInputAddress({
            handler_name: '', address_1: '', address_2: '', postal_code: '', phone_number: '', email: ''
        });
        console.log(address);
        modalClose();
    }
    }
    const handleChange = (e) => {
        setInputAddress({ ...inputAddress, [e.target.name]: e.target.value });
    };
    console.log("🚀 ~ handleChange ~ inputAddress:", inputAddress)

    useEffect(() => {
        // localStorage.setItem("StationAddress", JSON.stringify(address));
    }, [rowData]);

    const [selectAddress, setSelectAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const addressDetails = (addressID) => {
        // Find the selected address by its addressID
        const updatedSelects = address.map(ads => ({ ...ads, selected: false }));
        const updatedSelect = updatedSelects.find(ads => ads.id === addressID);

        if (updatedSelect) {
            updatedSelect.selected = true;
        }
        setAddress(updatedSelects);
        // setSelectAddress(!selectAddress);
        const selected = address.find((addr) => addr.id === addressID);
        setSelectedAddress(selected);
    };
    //   EDIT SECTION
    const [showEdit, setShowEdit] = useState(false);
    const modalEditShow = () => setShowEdit(true)
    const modalEditClose = () => setShowEdit(false);

    const handleEditChange = (e) => {
        setSelectedAddress(Prev => ({ ...Prev, [e.target.name]: e.target.value }));
    };
    const editAddress = async (e) => {
        // if (validateFormData()) {
        e.preventDefault();
        // const newAddressID = Math.floor(Math.random() * 1000000);
        // const stationid = rowData.station_id;
        // const NewInputAddress = {...inputAddress, station_id: stationid};
        // console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
        // const updatedAddress = [...address, NewInputAddress];
        // if (updatedAddress) {
        //     dispatch(addAddressData(NewInputAddress)); // Pass sendStationData to addData thunk
        //   }
        const stationid = rowData.station_id;
        const NewInputAddress = { ...selectedAddress, station_id: stationid };
        if (NewInputAddress) {
            try {
                const res = await withRFSToken.post(ADDSTATIONADDRESS, NewInputAddress)
                    .then(function (res) {
                        // const innerData = res;
                        // console.log("🚀 ~ .then ~ innerData:", innerData)
                        if (res.data.response_code == 200) {
                            // reloadGrid();
                            setAddress(res.data.response_data);
                            setSelectedAddress(res.data.response_data);
                        }
                    });

            } catch (error) {
                console.error('Error sending request:', error);
            }
            console.log(address);
            modalEditClose();
        }
    // }
    }


    return (
        <>

            <Row className='mt-3'>
                <Col className='pt-4 px-4 brd_rgt'>
                    <h4>Station Details</h4>
                    <p>Add stations and manage stations by selecting the station.</p>
                    <h3> {rowData.args.STN} </h3>
                    {/* {console.log('456',rowData.args)} */}
                    <div className='d-flex justify-content-between pt-3'>
                        <div>
                            <h4>Manage Address </h4>
                            <p>Manage Address and add address</p>
                        </div>
                        <div className='align-self-center'>
                            <button onClick={modalShow} className='add_adres'>+ Add Address</button>
                        </div>

                    </div>
                    <div className='addresses'>

                        {address && address.map((ads, index) => (
                            <>
                                {/* {rowData.args.id === ads.id && (
        <> */}
                                <div key={ads.id} className={`addres_box ${ads.selected ? 'selectbx' : ''} `} onClick={() => addressDetails(ads.id)}>
                                    <ul key={index}>
                                        <h3>{ads.handler_name}</h3>
                                       
                                        <li><span>Address </span> : <span>{ads.address_1}</span></li>
                                        <li><span>Address 2 </span> : <span>{ads.address_2}</span></li>
                                        <li><span>Postal code </span> : <span>{ads.postal_code}</span></li>
                                        <li><span>Email </span> : <span>{ads.email}</span></li>
                                        <li><span>Phone No </span> : <span>{ads.phone_number}</span></li>
                                    </ul>
                                    <div className='confrm'><FontAwesomeIcon icon={faCircleCheck} /></div>
                                </div>
                                {/* </>
    )} */}
                            </>
                        ))}



                    </div>
                    {console.log('666', selectedAddress)}
                </Col>
                <Col className='pt-4 px-4'>
                    {selectedAddress && (
                        <>
                            <div className='handler_name'>
                                <div className='raedit'><Button variant="link" onClick={modalEditShow}><FontAwesomeIcon icon={faPencil} /> Edit</Button></div>
                                <h2>{selectedAddress.handler_name}</h2>
                             
                                <p>Details</p>

                                <div className='handler_info'>
                                    <ul>
                                        <h3>Handler Information</h3>
                                        <li><span>Address </span> : <span>{selectedAddress.address_1}, {selectedAddress.address_2} </span></li>
                                        <li><span>Postal code</span> : <span>{selectedAddress.postal_code}</span></li>
                                        <li><span>Email </span> : <span>{selectedAddress.email}</span></li>
                                        <li><span>Phone No </span> : <span>{selectedAddress.phone_number}</span></li>
                                    </ul>
                                </div>

                                <div className='mt-3'>
                                    <h3>Attachments</h3>
                                </div>
                            </div>
                            <Modal show={showEdit} onHide={modalEditClose} centered className='addfrom'>
                                <Modal.Header closeButton onClick={modalEditClose}>
                                    <Modal.Title>Edit Address</Modal.Title>
                                </Modal.Header>
                                <Modal.Body >
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Handler Name</Form.Label>
                                        <Form.Control type="text" onChange={handleEditChange} value={selectedAddress.handler_name} name='handler_name' placeholder="Handler Name" />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" onChange={handleEditChange} value={selectedAddress.address_1} name='address_1' placeholder="Address" />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" onChange={handleEditChange} value={selectedAddress.address_2} name='address_2' placeholder="Address" />
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-2'>
                                        <Col sm="6">
                                            <Form.Label>Postal Code</Form.Label>
                                            <Form.Control type="text" onChange={handleEditChange} value={selectedAddress.postal_code} name='postal_code' placeholder="Zip" />
                                        </Col>
                                        <Col sm="6">
                                            <Form.Label>Telephone No</Form.Label>
                                            <Form.Control type="number" onChange={handleEditChange} value={selectedAddress.phone_number} maxLength='12' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} name='phone_number' placeholder="Number" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control type="text" onChange={handleEditChange} value={selectedAddress.email} name='email' placeholder="E-mail" />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={editAddress}  >
                                        Update Address
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </Col>
            </Row>



            <Modal show={show} onHide={modalClose} centered className='addfrom'>
                <Modal.Header closeButton onClick={modalClose}>
                    <Modal.Title>Add Address</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form.Group className='mb-2'>
                        <Form.Label>Handler Name</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='handler_name' placeholder="Handler Name" />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='address_1' placeholder="Address" />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='address_2' placeholder="Address" />
                    </Form.Group>
                    <Form.Group as={Row} className='mb-2'>
                        <Col sm="6">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" onChange={handleChange} name='postal_code' placeholder="Zip" />
                        </Col>
                        <Col sm="6">
                            <Form.Label>Telephone No</Form.Label>
                            <Form.Control type="number" onChange={handleChange} name='phone_number' maxLength='12' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} placeholder="Number" />
                        </Col>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='email' placeholder="E-mail" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addAddress}  >
                        Add Address
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StationDetails;