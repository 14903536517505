import React from 'react';
import Header from "../../../Componenets/Header/Header";
import { LeftSettings } from '../LeftSettings';

export const ReportSettings = () => {
  return (
    <>
      <div className="">
        <Header />
        <div className="main-panel">

          <div className="row">

            <LeftSettings />
            <div className='col-9 col-xxl-9 stting_right'>
              <div className='settingbg_right'>
                <h4>Report Settings</h4>
                <p>Set your notification preferences for when you are in or away from the dashboard.</p>

              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default ReportSettings