import React, { useState, useEffect, useRef } from 'react';
import Header from "../../../Componenets/Header/Header";
import LeftMenu from '../../../Componenets/LeftMenu/LeftMenu';
import TopMenu from '../common/TopMenu';

import Export from "../../../assets/images/export.svg";
import Exportw from "../../../assets/images/exportw.png";
import AssetsIcon from '../../../assets/images/assets.svg';
import OperationIcon from '../../../assets/images/oparation.svg';
import OnAirIcon from '../../../assets/images/onair.svg';
import RepairIcon from '../../../assets/images/repair.svg';
import OnLoanIcon from '../../../assets/images/onloan.svg';
import AssetsIconA from '../../../assets/images/assets_a.svg';
import OperationIconA from '../../../assets/images/oparation_a.svg';
import OnAirIconA from '../../../assets/images/onair_a.svg';
import RepairIconA from '../../../assets/images/repair_a.svg';
import OnLoanIconA from '../../../assets/images/onloan_a.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Assets from './Assets';
import Operations from './Operations';
import Onair from './Onair';
import Repair from './Repair';
import Onloan from './Onloan';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDSEARCH, ULDSEARCHDETAILS } from '../../../Utils/conts.js';

const ULDControl = () => {

    // const queryParameters = new URLSearchParams(window.location.search);
    // const action = queryParameters.get("tab");
    // const initialTab = queryParameters.get("tab") || 'assets';
    const initialTab = localStorage.getItem("tab") || 'assets';
    const isMounted = useRef(false);
    const [selectedButton, setSelectedButton] = useState(initialTab);

    useEffect(() => {
        setSelectedButton(initialTab);
    }, [initialTab]);
    // console.log('555', selectedButton);
    const handleButtonClick = (button) => {
        setSelectedButton(button);
        localStorage.setItem('tab', button);
    };
    // autocomplete
    const [searchItems, setSearchItems] = useState([])
    const fetchDataGrid = async () => {
        try {
            const res = await withRFSToken.post(ULDSEARCH, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData.map((name, index) => ({
                        id: index,
                        name: name
                    }));
                    setSearchItems(transformedData);

                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (isMounted.current) return;
        // Call the fetchData function to fetch data from the API
        fetchDataGrid();
        isMounted.current = true;
    }, []);

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = async (item) => {
        // the item selected
        console.log(item)
        try {
            const res = await withRFSToken.post(ULDSEARCHDETAILS, { uld_number: item.name });
            if (res.data.response_code == 200) {
                localStorage.removeItem('MHistory');
                const storageData = {
                    ULD: res.data.response_data,  // ULD Details section
                    Form: {}  // Empty form section
                };
                localStorage.setItem('MHistory', JSON.stringify(storageData));
                localStorage.setItem('tab', initialTab);
                window.location.href = '/ULDControl/MovementHistory'
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }
    return (
        <>
            <div className="">
                <Header />
                <div className="page-body-wrapper schedule">
                    <LeftMenu />
                    <div className="main-panel rounded">
                        <div className="mainTab">
                            <TopMenu />
                            <div className="">
                                <div className="allcontent-fl">
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div>
                                            <button className={selectedButton === 'assets' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('assets')}>
                                                <img src={selectedButton === 'assets' ? AssetsIconA : AssetsIcon} /> <span>Assets</span>
                                            </button>
                                            <button className={selectedButton === 'operations' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('operations')}>
                                                <img src={selectedButton === 'operations' ? OperationIconA : OperationIcon} /> <span>Operations</span>
                                            </button>
                                            <button className={selectedButton === 'onair' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('onair')}>
                                                <img src={selectedButton === 'onair' ? OnAirIconA : OnAirIcon} /> <span>On Air</span>
                                            </button>
                                            <button className={selectedButton === 'repair' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('repair')}>
                                                <img src={selectedButton === 'repair' ? RepairIconA : RepairIcon} /> <span>Repair</span>
                                            </button>
                                            <button className={selectedButton === 'onloan' ? 'exportbtn active' : 'exportbtn'} onClick={() => handleButtonClick('onloan')}>
                                                <img src={selectedButton === 'onloan' ? OnLoanIconA : OnLoanIcon} /> <span>On Loan</span>
                                            </button>
                                        </div>
                                        <div className='search'>
                                            <div className="form-group has-search">
                                            
                                                <ReactSearchAutocomplete
                                                    items={searchItems}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    onSelect={handleOnSelect}
                                                    onFocus={handleOnFocus}
                                                    autoFocus
                                                    placeholder="Search ULD"
                                                // formatResult={formatResult}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lcontent">
                                        {selectedButton === 'assets' && <Assets />}
                                        {selectedButton === 'operations' && <Operations />}
                                        {selectedButton === 'onair' && <Onair />}
                                        {selectedButton === 'repair' && <Repair />}
                                        {selectedButton === 'onloan' && <Onloan />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ULDControl