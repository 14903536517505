import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Profile from "../../assets/images/fds.png";
import Sprofile from "../../assets/images/profile.png";
import Sbell from "../../assets/images/sbell.png";
import Userview from "../../assets/images/useer_view.png";
// import Saccount from "../../assets/images/account-settings.png";
import Smanagement from "../../assets/images/management.png";
import Filter from "../../assets/images/uldsetting.png";
import Acsetting from "../../assets/images/account-settings.png";
import Uprofile from "../../assets/images/profile.png";
import Rsetting from "../../assets/images/rsettings.png";
import Nsetting from "../../assets/images/sbell.png";
import Offline from "../../assets/images/offline.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';


import { useAuth, useAuthRFS, useAuthULD } from '../../useAuth/useAuth';

export const LeftSettings = () => {
    const isAuthenticated = useAuth();
    const isAuthenticatedRFS = useAuthRFS();
    const isAuthenticatedULD = useAuthULD();
    const location = useLocation();
    const navItems = [{ name: 'User Settings', path: '/UserSettings', image: Sprofile }]
    const navItemsRFS = [
        { name: 'Notification Settings', path: '/NotificationSettings', image: Sbell },
        // { name: 'Accessibility Settings', path: '/AccessibilitySettings', image: Saccount  },
        // { name: 'Carrier Settings', path: '/CarrierSettings', image: Smanagement  },
        { name: 'Trucker Settings', path: '/TruckerSettings', image: Smanagement },
        { name: 'Station Settings', path: '/StationSettings', image: Smanagement },
        // { name: 'Security Settings', path: '/SecuritySettings', image: Rsettings  },
        // { name: 'Terms & Conditions', path: '/TermsnConditions', image: Contract  },
        // Add more items with respective paths as needed
    ];
    const navItemsULD = [
        { name: 'General Settings', path: '/GeneralSettings', image: Rsetting },
        { name: 'Region Settings', path: '/RegionSettings', image: Uprofile },
        { name: 'Access Rights Settings', path: '/AccessRightsSettings', image: Acsetting },
        { name: 'Notification Settings', path: '/NotificationSettings', image: Nsetting },
        { name: 'Station Settings', path: '/StationSettings', image: Offline },
        { name: 'Report Settings', path: '/ReportSettings', image: Smanagement },
    ];

    //   const navItemsForAuth = navItems.filter(item => item.path == '/UserSettings');

    //   const navItemsForRFS = navItems.filter(item => item.path !== '/UserSettings');

    return (
        <>
            <div className='col-3 col-xxl-3 stting_left'>
                <div className='settingbg'>
                    {isAuthenticated &&
                        <div className='profile'>
                            <img src={Profile} />
                            <h3>Free Account</h3>
                            <p>Settings</p>
                        </div>
                    }
                    {isAuthenticatedRFS &&
                        <div className='profile'>
                            <img src={Profile} />
                            <h3> Driver Planning</h3>
                            <p>Module Settings</p>
                        </div>
                    }
                    {isAuthenticatedULD &&
                    <>
                         <div className='main_setting'> <Link to="/UserSettings"><FontAwesomeIcon icon={faGear} /> Org Settings</Link>  </div>
                        <div className='profile mt-4'>
                            <img src={Filter} />
                            <h3> ULD Control</h3>
                            <p>Module Settings</p>
                        </div>

                        </>
                    }
                    <ul>
                        {isAuthenticated && navItems.map(item => (
                            <li key={item.path} className={`${location.pathname === item.path ? 'active' : ''}`}>
                                <Link to={item.path}>
                                    <img src={item.image} alt={item.name} />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                        {isAuthenticatedRFS && navItemsRFS.map(item => (
                            <li key={item.path} className={`${location.pathname === item.path ? 'active' : ''}`}>
                                <Link to={item.path}>
                                    <img src={item.image} alt={item.name} />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                        {isAuthenticatedULD && navItemsULD.map(item => (
                            <li key={item.path} className={`${location.pathname === item.path ? 'active' : ''}`}>
                                <Link to={item.path}>
                                    <img src={item.image} alt={item.name} />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                        {/* {navItems.map((item) => (
                <li key={item.name}  className={`${location.pathname === item.path ? 'active' : ''}`}>
                    <Link to={item.path}>
                    <span><img src={item.image} /></span>{item.name}
                    </Link>
                </li>
            ))} */}
                        {/* <li className={selectedButton === 'user' ? 'active' : ''}>
                <Link to='/settings' onClick={() => handleButtonClick('user')}><span><img src={Sprofile} /></span> User Settings</Link >
            </li>
            <li className={selectedButton === 'notification' ? 'active' : ''}>
                <Link><span><img src={Sbell} /></span> Notification Settings</Link>
            </li>
            <li><Link><span><img src={Saccount} /></span> Accessibility Settings</Link></li>
            <li><Link><span><img src={Smanagement} /></span> Document Settings</Link></li>
            <li className={selectedButton === 'station' ? 'active' : ''}>
                <Link to='/settings'  onClick={() => handleButtonClick('station')}><span><img src={Smanagement} /></span> Station Settings</Link>
            </li>
            <li><Link><span><img src={Rsettings} /></span> Security Settings</Link></li>
            <li><Link><span><img src={Contract} /></span> Term & Conditions</Link></li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export const LeftccountSettings = () => {
    const location = useLocation();
    const isAuthenticatedRFS = useAuthRFS();
    const isAuthenticatedULD = useAuthULD();
    const navItems = [
        { name: 'Manage Users', path: '/ManageUsers', image: Userview },
        { name: 'Accessibility Settings', path: '/AccessibilitySettings', image: Sbell },
        { name: 'User Specific Settings', path: '/UserSpecificSettings', image: Sprofile },
    ];
    const navItemsULD = [
        { name: 'User Settings', path: '/UserSettings', image: Userview },
        { name: 'Access Rights', path: '/AccessRights', image: Sbell },
    ];

    return (
        <>
            <div className='col-3 col-xxl-3 stting_left'>
                <div className='settingbg'>
                    <div className='profile'>
                        {isAuthenticatedRFS &&
                            <>
                                <img src={Profile} />
                                <h3> Account Setting</h3>
                                <p>Settings</p>
                            </>
                        }
                        {isAuthenticatedULD &&
                            <>
                                <img src={Profile} />
                                <h3> ULD Account Setting</h3>
                                <p>Settings</p>
                            </>
                        }
                    </div>
                    {isAuthenticatedRFS &&
                        <ul>
                            {navItems.map((item) => (
                                <li key={item.name} className={`${location.pathname === item.path ? 'active' : ''}`}>
                                    <Link to={item.path}>
                                        <span><img src={item.image} /></span>{item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    }
                    {isAuthenticatedULD &&
                        <ul>
                            {navItemsULD.map((item) => (
                                <li key={item.name} className={`${location.pathname === item.path ? 'active' : ''}`}>
                                    <Link to={item.path}>
                                        <span><img src={item.image} /></span>{item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </div>
        </>
    )
}

// export  {LeftccountSettings};