import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faCircleXmark, faTrashCan, faPencil, faEnvelope, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Prev } from 'react-bootstrap/esm/PageItem';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Arcv from "../../../assets/images/archive.svg";
import Active from "../../../assets/images/active.svg";
import ImageUpload from '../../../Componenets/common/ImageUpload.js';
import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDADDRESSSAVE, ULDSTATIONDETAILS, ULDADDRESSACTARC, ULDADDRESSRECMAIL } from '../../../Utils/conts.js';
import { toast } from 'react-toastify';

const StationDetails = ({ rowData, stationID }) => {

    console.log('999', rowData);
    const [show, setShow] = useState(false);
    const modalShow = () => setShow(true)
    const modalClose = () => setShow(false);
    const storedData = rowData.args.Address;

    const [inputAddress, setInputAddress] = useState({
        id: '', company_name: '', agent_or_handler: '', agent_type: 'E', address: '', email: '', phone_number: ''
    });
    const [address, setAddress] = useState(storedData);

    console.log("🚀 ~ StationDetails ~ address:", address)
    // const dispatch = useDispatch();
    // Validation 
    const validateFormData = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Function to validate email
        const validateEmail = (email) => {
            return emailRegex.test(email);
        };
        if (!inputAddress.company_name) {
            console.log('Text input is required');
            toast.error('Company Name must not be blank');
            isValid = false;
        } else if (!inputAddress.agent_or_handler) {
            console.log('Text input is required');
            toast.error('Please select Agent or Handler');
            isValid = false;
        } 
        // else if (!inputAddress.address) {
        //     console.log('Text input is required');
        //     toast.error('Address must not be blank');
        //     isValid = false;
        // } else if (!inputAddress.phone_number) {
        //     console.log('Text input is required');
        //     toast.error('Phone Number must not be blank');
        //     isValid = false;
        // } else if (!/^\d{10}$/.test(inputAddress.phone_number)) {
        //     console.log('10');
        //     toast.error('Phone Number must be 10 Digits');
        //     isValid = false;
        // } else if (!inputAddress.email) {
        //     console.log('Text input is required');
        //     toast.error('Email must not be blank');
        //     isValid = false;
        // } else if (!validateEmail(inputAddress.email)) {
        //     console.log('Invalid email address');
        //     toast.error('Invalid email address');
        //     isValid = false;
        // }
        else {
            return isValid;
        }
    };


    const addAddress = async (e) => {
        // console.log('444', inputAddress);
        if (validateFormData()) {
            e.preventDefault();
            // const newAddressID = Math.floor(Math.random() * 1000000);
            const stationid = rowData.station_id;
            const NewInputAddress = { ...inputAddress, station_id: stationid };

            const dataToSend = {
                station_id: stationid,
                company_name: inputAddress.company_name,
                agent_or_handler: inputAddress.agent_or_handler,
                address: inputAddress.address,
                email: inputAddress.email,
                phone_number: inputAddress.phone_number
            };
            // console.log("🚀 ~ addAddress ~ dataToSend:", dataToSend)

            if (inputAddress.agent_or_handler === 'A') {
                dataToSend.agent_type = inputAddress.agent_type;
            }
            
            // const updatedAddress = [...address, NewInputAddress];
            // if (updatedAddress) {
            //     dispatch(addAddressData(NewInputAddress)); // Pass sendStationData to addData thunk
            //   }
            if (NewInputAddress) {
                try {
                    const res = await withRFSToken.post(ULDADDRESSSAVE, dataToSend)
                        .then(function (res) {
                            // const innerData = res;
                            // console.log("🚀 ~ .then ~ innerData:", innerData)
                            if (res.data.response_code == 200) {
                                fetchDataGrid();
                                // setAddress(res.data.response_data);
                                setInputAddress({
                                    station_address_id: '', company_name: '', agent_or_handler: '', agent_type: 'E', address: '', email: '', phone_number: ''
                                });
                                modalClose();
                            } else {
                                toast.error(res.data.response_msg);
                            }
                        });

                } catch (error) {
                    console.error('Error sending request:', error);
                }
            }
            
        }
    }
    // const handleChange = (e) => {
    //     setInputAddress({ ...inputAddress, [e.target.name]: e.target.value });
    // };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'regd') {
            setInputAddress({ ...inputAddress, [name]: checked ? '1' : '0' });
        } else {
            setInputAddress({ ...inputAddress, [name]: value });
        }
        console.log(inputAddress);
    };

    useEffect(() => {
        // localStorage.setItem("StationAddress", JSON.stringify(address));
    }, [rowData]);

    // const [selectAddress, setSelectAddress] = useState(false);
    // const [selectedAddress, setSelectedAddress] = useState(null);

    // const addressDetails = (addressID) => {
    //     // Find the selected address by its addressID
    //     const updatedSelects = address.map(ads => ({ ...ads, selected: false }));
    //     const updatedSelect = updatedSelects.find(ads => ads.id === addressID);

    //     if (updatedSelect) {
    //         updatedSelect.selected = true;
    //     }
    //     setAddress(updatedSelects);
    //     // setSelectAddress(!selectAddress);
    //     const selected = address.find((addr) => addr.id === addressID);
    //     setSelectedAddress(selected);
    // };
    // Grid Start
    const [gridData, setGridData] = useState({
        Active: [],
        Archived: []

    });
    const fetchDataGrid = async () => {
        try {
            const res = await withRFSToken.post(ULDSTATIONDETAILS, { station_id: rowData.args.id })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    setGridData({ Active: innerData.active_address, Archived: innerData.archive_address });
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {

        // Call the fetchData function to fetch data from the API
        fetchDataGrid();

    }, []);
    const [activeTab, setActiveTab] = useState('Active');
    const [gridSelect, setGridSelect] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    const gridRefs = {
        Active: useRef(null),
        Archived: useRef(null)
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const handleDetailsBackClick = (rowData) => {
        // setSelectedRowData(rowData);
        setShowDetails(false);
    };

    useEffect(() => {

        if (gridRefs[activeTab].current && !showDetails) {

            if (gridData[activeTab]) {
                //   const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                //   }));    
                // console.log("🚀 ~ localData ~ localData:", localData)
                const source = {
                    datatype: 'json',
                    id: `${activeTab}-`,
                    datafields: [
                        { name: 'address_id', type: 'string' },
                        { name: 'company_name', type: 'string' },
                        { name: 'agent_or_handler', type: 'string' },
                        { name: 'regd', type: 'string' },
                        { name: 'contact_email', type: 'string' },
                        { name: 'recovery_emails', type: 'string' },
                        { name: 'phone_number', type: 'string' },
                        { name: 'attachments', type: 'string' },
                    ],
                    localdata: gridData[activeTab],
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                // console.log('localdata', source.localdata)
                const columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    { text: 'Company Name', filtertype: 'textbox', datafield: 'company_name', width: '20%', cellclassname: '', },
                    { text: 'Agent/Handler', filtertype: 'textbox', datafield: 'agent_or_handler',
                        cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                            if (cellValue === 'A') {
                                return '<div class="s_register"> Agent </div>';
                            } else {
                                return '<div class="s_nonregister"> Handler </div>';
                            }
                        },
                        width: '25%', cellclassname: 'frcl', },
                    {
                        text: 'External/Internal', filtertype: 'textbox', datafield: 'regd',
                        cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                            if (cellValue === 'E') {
                                return '<div class="s_register"> External </div>';
                            } else if (cellValue === 'I') {
                                return '<div class="s_nonregister"> Internal </div>';
                            } else {
                                return '<div class="s_nonregister"> </div>';
                            }
                        },
                        width: '28%', cellclassname: 'frcl',
                    },
                ];

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: true,
                    editable: false,
                    selectionmode: 'checkbox',
                    pageable: false,
                    pageSize: 10,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,

                };

                window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
                window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

                window.$(gridRefs[activeTab].current).on('rowclick', (event) => {
                    console.log('event', event);
                    const args = event.args.row.bounddata;
                    console.log('args', args);
                    const sendRowId = gridData[activeTab];
                    console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
                    setSelectedRowData(args, { station_id: args.uid });
                    setItems(args.recovery_emails)
                    setShowDetails(true);
                });
                window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });
                // row selection by checkbox
                window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        const selectedRowId = selectedRow.uid;
                        if (!gridSelect.some(row => row.uid === selectedRowId)) {
                            setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData[activeTab];
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.address_id;
                        setGridSelect(prevSelection => prevSelection.filter(row => row.address_id !== unselectedRowId));
                    }
                });
            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
    }, [activeTab, gridData, showDetails]);
    console.log('101', gridSelect);
    // row click function start
    const handleMoveToActArc = async () => {
        console.log('101', gridSelect);
        const sendAction = activeTab == 'Active' ? '0' : '1';
        if (gridSelect.length <= 0) {
            toast.error('Please select grid data before send');
        } else {
            const uldNumbers = gridSelect && gridSelect.map(num => num.address_id)
            try {
                const res = await withRFSToken.post(ULDADDRESSACTARC, { address_ids: uldNumbers, type: sendAction })
                    .then(function (res) {
                        if (res.data.response_code == 200) {
                            gridSelect.forEach(item => {
                                const selectedRowBoundIndex = item.boundindex;
                                window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                            });
                            setGridSelect([]);
                            if (activeTab == 'Active') {
                                toast.success('ULD Data moved to Arhive');
                                fetchDataGrid()
                            } else {
                                toast.success('ULD Data moved to Active');
                                fetchDataGrid()
                            }
                        }
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    // Email section
    console.log('2111', selectedRowData);
    const emailArray = selectedRowData && selectedRowData.recovery_emails;
    console.log("🚀 ~ StationDetails ~ emailArray:", emailArray)
    const [emailShow, setEmailShow] = useState(false);
    const inputEmailShow = () => setEmailShow(true);
    const [inputData, setInputData] = useState("");
    const [items, setItems] = useState([]);
    const [editEmail, setEditEmail] = useState('');
    const [toggleButton, setToggleButton] = useState(false);

    const AddEmail = async () => {
        if (inputData == '') {
            // Alert('Please input Email');
            console.log("Please input Email");
            document.getElementById('addEmail').classList.add('error');
            toast.error('Email address must not be blank');
            setEmailShow(true);
        }
        else if (inputData && toggleButton) {
            // if (index){
            const NewItemData = {
                // id: new Date().getTime().toString(),
                address_id: selectedRowData.address_id,
                recovery_email: inputData,
                email_key: editEmail,
            }
            // }
            try {
                const res = await withRFSToken.post(ULDADDRESSRECMAIL, NewItemData)
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        console.log("🚀 ~ .then ~ innerData:", innerData)
                        setItems(innerData);
                        // setExportGrid(innerData.export);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // setItems(items.map((elem) => {
            //     if(elem.id === editEmail){
            //         return {...elem, name: inputData}
            //     }
            //     return elem;
            // }));
            setInputData('');
            setEditEmail(null);
            setToggleButton(false);
            setEmailShow(false);
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const validateEmail = (email) => {
                return emailRegex.test(email);
            };
            if (!validateEmail(inputData)) {
                toast.error('Invalid email address');
                setEmailShow(true);
            } else {
                const NewItemData = {
                    // id: new Date().getTime().toString(),
                    address_id: selectedRowData.address_id,
                    recovery_email: inputData
                }
                try {
                    const res = await withRFSToken.post(ULDADDRESSRECMAIL, NewItemData)
                        .then(function (res) {
                            const innerData = res.data.response_data;
                            console.log("🚀 ~ .then ~ innerData:", innerData)
                            setItems(innerData);
                            // setExportGrid(innerData.export);
                        });

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                // setItems([...items, NewItemData]);
                setEmailShow(false);
            }

            // localStorage.setItem('TruckerEmail', JSON.stringify(NewItemData));
        }
        setInputData('');
        // setEmailShow(false);

    }
    const handleKeyPress = () => { document.getElementById('addEmail').classList.remove('error'); }
    const deleteEmail = async (index) => {
        const updatedItem = items.filter((curElem) => {
            return curElem.id !== index;
        });
        // try {
        //     const res = await withRFSToken.post(TRUCKERDELEMAIL, { trucker_id: truckerParent.id, email_key: index })
        //         .then(function (res) {
        //             const innerData = res.data.response_data;
        //             console.log("🚀 ~ .then ~ innerData:", innerData)
        //             setItems(innerData);
        //             // setExportGrid(innerData.export);
        //         });

        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
        // setItems(updatedItem);
    }
    const EditEmail = (index) => {
        // const email_toedit = items.find((elem) => {
        //     return elem.id == index;
        // });
        setEmailShow(true);
        setToggleButton(true);
        setInputData(items[index]);
        setEditEmail(index);
    }
    // Email section End


    return (
        <>

            <Row className='mt-3'>
                <Col className='pt-4 px-4 brd_rgt' lg="6">
                    <h4>Station Details</h4>
                    <p>Add stations and manage stations by selecting the station.</p>
                    <h3> {rowData.args.STN} </h3>
                    {/* {console.log('456',rowData.args)} */}
                    <div className='d-flex justify-content-between pt-3'>
                        <div>
                            <h4>Manage Address </h4>
                            <p>Manage Address and add address</p>
                        </div>
                        <div className='align-self-center'>
                            <button onClick={modalShow} className='add_adres'>+ Add Address</button>
                        </div>

                    </div>{console.log('999', gridData)}
                    <div className='addresses'>
                        <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="station_grid">
                            <Tab eventKey="Active" title={<span>Active</span>} className='moveto'>
                                <div className='moveto_btn'>
                                    <button onClick={handleMoveToActArc}> <img src={Arcv} /></button>
                                </div>
                                <div ref={gridRefs.Active} className='staion_grid' ></div>
                            </Tab>
                            <Tab eventKey="Archived" title={<span>Archived</span>} className='moveto'>
                                <div className='moveto_btn'>
                                    <button onClick={handleMoveToActArc}> <img title='' src={Active} /></button>
                                </div>
                                <div ref={gridRefs.Archived} className='staion_grid' ></div>
                            </Tab>
                        </Tabs>
                        {/* <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                            <Tab eventKey="Active" title={<span>Active</span>} className='moveto'>
                                <div ref={gridRefs.Active} className='bookinggrid' ></div>
                            </Tab>
                            <Tab eventKey="Archived" title={<span>Archived</span>} className='moveto'>

                                <div ref={gridRefs.Archived} className='bookinggrid' ></div>
                            </Tab>
                        </Tabs> */}
                        {/* {address && address.map((ads, index) => ( */}
                        <>
                            {/* {rowData.args.id === ads.id && (
        <> */}
                            {/* <div key={ads.id} className={`addres_box ${ads.selected ? 'selectbx' : ''} `} onClick={() => addressDetails(ads.id)}>
                                    <ul key={index}>
                                        <h3>{ads.handler_name}</h3>

                                        <li><span>Address </span> : <span>{ads.address_1}</span></li>
                                        <li><span>Address 2 </span> : <span>{ads.address_2}</span></li>
                                        <li><span>Postal code </span> : <span>{ads.postal_code}</span></li>
                                        <li><span>Email </span> : <span>{ads.email}</span></li>
                                        <li><span>Phone No </span> : <span>{ads.phone_number}</span></li>
                                    </ul>
                                    <div className='confrm'><FontAwesomeIcon icon={faCircleCheck} /></div>
                                </div> */}
                            {/* </>
    )} */}
                        </>
                        {/* ))} */}



                    </div>
                    {console.log('666', selectedRowData)}
                </Col>
                <Col className='pt-4 px-4' lg="6">
                    {selectedRowData && (
                        <>
                            <div className='handler_name'>
                                {/* <div className='raedit'><Button variant="link" onClick={modalEditShow}><FontAwesomeIcon icon={faPencil} /> Edit</Button></div> */}
                                <h2>{selectedRowData.company_name}</h2>

                                <div className='handler_info'>
                                    <ul>
                                        <h3>Handler Information</h3>
                                        <li><span>Agent/Handler </span> : <span>{selectedRowData.agent_or_handler == 'A'? 'Agent' : 'Handler'}, {selectedRowData.address_2} </span></li>
                                        <li><span>Registered Hub</span> : <span>{selectedRowData.regd == '1' ? 'Registered' : 'Non Registered'}</span></li>
                                        <li><span>Contact Email </span> : <span>{selectedRowData.contact_email}</span></li>
                                        <li><span>Phone No </span> : <span>{selectedRowData.phone_number}</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='mt-3 '>
                                <div className='d-flex justify-content-between py-2'>
                                    <h6>Recovery Email Address</h6>
                                    <div className='align-self-center'>
                                        <button onClick={inputEmailShow} className='add_adres'>+ Add Email</button>
                                    </div>

                                </div>
                                <div className='addresses'>
                                    {emailShow &&
                                        <>
                                            <div className='add_email'>
                                                <input name='' value={inputData} onKeyDown={handleKeyPress} onChange={(e) => setInputData(e.target.value)} id='addEmail' type='text' placeholder='Email type here' />
                                                {toggleButton ? <button onClick={AddEmail}>Edit</button> : <button onClick={AddEmail}><FontAwesomeIcon icon={faPlus} /> Add</button>}
                                            </div>
                                        </>
                                    }
                                    <div className='showItem'>{console.log('555', items)}
                                        {items && items.map((elem, index) => {
                                            return (
                                                <div className='eachItem' key={index}>
                                                    <h3><FontAwesomeIcon icon={faEnvelope} /> {elem}</h3>
                                                    {/* <button>Edit</button> */}
                                                    <div className='request_email'>
                                                        <button onClick={() => EditEmail(index)}> <FontAwesomeIcon icon={faPencil} /> </button>
                                                        <button onClick={() => deleteEmail(index)}> <FontAwesomeIcon icon={faTrashCan} /> </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>

                            <ImageUpload attachable_type={"uld_s_address"} attachable_id={selectedRowData.address_id} attachments={selectedRowData.attachments} />
                        </>
                    )}
                </Col>
            </Row>



            <Modal show={show} onHide={modalClose} centered className='addfrom'>
                <Modal.Header closeButton onClick={modalClose}>
                    <Modal.Title>Add Address - {rowData.args.STN}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form.Group className='mb-2'>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='company_name' placeholder="Company Name" />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Agent or Handler</Form.Label>
                        <Form.Check
                            inline
                            label="Agent"
                            name="agent_or_handler"
                            type='radio'
                            id='export'
                            value='A'
                            checked={inputAddress.agent_or_handler === 'A'}
                            onChange={handleChange}
                        />

                        <Form.Check
                            inline
                            label="Handler"
                            value="H"
                            name="agent_or_handler"
                            type='radio'
                            id='import'
                            checked={inputAddress.agent_or_handler === 'H'}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    {inputAddress.agent_or_handler == 'A' &&
                        <>
                            <Form.Group className='mb-2'>
                                <Form.Check
                                    inline
                                    label="External"
                                    name="agent_type"
                                    type='radio'
                                    id='external'
                                    value='E'
                                    checked={inputAddress.agent_type === 'E'}
                                    onChange={handleChange}
                                />

                                <Form.Check
                                    inline
                                    label="Internal"
                                    value="I"
                                    name="agent_type"
                                    type='radio'
                                    id='internal'
                                    checked={inputAddress.agent_type === 'I'}
                                    onChange={handleChange}
                                />

                            </Form.Group>
                        </>
                    }
                    <Form.Group className='mb-2'>
                        <Form.Label>Address </Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='address' placeholder="Address" />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control type="text" onChange={handleChange} name='email' placeholder="E-mail" />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Telephone No</Form.Label>
                        <Form.Control type="number" onChange={handleChange} name='phone_number' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} placeholder="Number" />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addAddress}  >
                        Add Address
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StationDetails;