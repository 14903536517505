import { faChevronLeft, faChevronRight, faCircleXmark, faPlus, faTrashCan, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Docmnt from '../../assets/images/google-docs.png';
import { MultiSelect } from 'react-multi-select-component';
import { Modal, Button } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import { withRFSToken } from '../../Services/axios.js';
import { STATIONGRID, GETSCHEDULEDTRUCKS, TRUCKERRATELIST } from '../../Utils/conts';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import AddStation from '../../Componenets/common/AddStation';
import AddStationAddress from '../../Componenets/common/AddStationAddress';
import Select from 'react-select';
const TruckOrderOne = ({ formData, onFormDataChange }) => {
  const [bookingTruck, setBookingTruck] = useState({ bookingTruck_type: 'existing' });
  const [bookingTruckList, setBookingTruckList] = useState();
  const [value, setValue] = useState('');
  const [suggestionsBp, setSuggestionsBp] = useState([]);
  const [bpaddress, setBpaddress] = useState(null);
  const [stationData, setStationData] = useState({});
  const [addressType, setAddressType] = useState('');
  const [showAddButton, setShowAddButton] = useState(false);
  const [showAddAddressButton, setShowAddAddressButton] = useState(false);
  const [showAddAddressButton2, setShowAddAddressButton2] = useState(false);
  const [valueOffpoint, setValueOffpoint] = useState('');
  const [suggestionsOffpoint, setSuggestionsOffpoint] = useState([]);
  const [selectedOffpoint, setSelectedOffpoint] = useState(null);
  const [showAddButtonOffpoint, setShowAddButtonOffpoint] = useState(false);
  const [selectedBoardpoint, setSelectedBoardpoint] = useState(null);
  const [selectedOffpointId, setSelectedOffpointId] = useState(null);
  const [fetchStation, setFetchStation] = useState([]);
  const [boardpoint, setBoardpoint] = useState([]);
  const [bpAddress, setBpAddress] = useState([]);
  const [offpoint, setOffpoint] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [showAddStation, setShowAddStation] = useState(false)

  const [localFormData, setLocalFormData] = useState({
    truck_type: 'B',
    bookingTruck_type: 'E',
    existing_date: '',
    booking_list: '',
    hoc_movement_type: '',    
    hoc_date: '',
    hoc_time: '',
    new_movement_type: '',

    new_board_point: '',
    new_board_address: '',
    new_off_point: '',
    new_off_address: '',
    hoc_board_point: '',
    hoc_board_address: '',
    hoc_off_point: '',
    hoc_off_address: '',

    new_board_point_name: '',
    new_board_address_name: '',
    new_off_point_name: '',
    new_off_address_name: '',
    hoc_board_point_name: '',
    hoc_board_address_name: '',
    hoc_off_point_name: '',
    hoc_off_address_name: '',

    new_date: '',
    new_time: ''
  });

  useEffect(() => {
    setLocalFormData(formData);
    // truckOrderList();
    stationDataApi();
    fetchData();
    fetchTruckRateList();
    const dateToday=dateYMD(new Date());    
    setLocalFormData({...localFormData, existing_date: dateToday});
    onFormDataChange({...localFormData, existing_date: dateToday});
  }, []);
  const getSuggestionValue = (suggestion) => suggestion.station_code;


  const fetchData = async () => {
    try {
      const res = await withRFSToken.post(STATIONGRID, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const activeInnerData = res.data.response_data.Active;
          // const transformedData = activeInnerData ? activeInnerData.map(item => ({
          //     id: item.id,
          //     value: item.station_id,
          //     label: item.station_code
          // })) : []; // For multiselect
          //   setBoardpoint(transformedData); // For multiselect
          setFetchStation(innerData.Active);
          setSuggestionsBp(innerData.Active);
          setSuggestionsOffpoint(innerData.Active);
          return activeInnerData
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const stationDataApi = async () => {
    try {
      const res = await withRFSToken.post(STATIONGRID, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const transformData = innerData.Active ? innerData.Active.map((data) => ({
            ...data,
            value: data.id,
            label: data.station_code,
          })) : [];
          setBoardpoint(transformData);
          setOffpoint(transformData);
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const fetchTruckRateList = async (alldata) => {
    try {
      const res = await withRFSToken.post(GETSCHEDULEDTRUCKS, alldata)
        .then(function (res) {
          const innerData = res.data.response_data;
          setBookingTruckList(innerData);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setShowAddButton(false);
    if (!newValue) {
      setBpaddress(null);
    } else {
      const station = suggestionsBp.find(station => getSuggestionValue(station).toLowerCase() === newValue.toLowerCase());
      if (station) {
        setBpaddress(station.station_addresses);
        setShowAddAddressButton(true);
        setShowAddButton(false);
      } else {
        setBpaddress(null);
        setShowAddAddressButton(false);
        setShowAddButton(true);
      }
    }

  };
  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    setSuggestionsBp(
      inputLength === 0
        ? []
        : fetchStation.filter(
          (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue &&
            fruit.station_code !== (selectedOffpoint && selectedOffpoint.station_code)
        )
    );

    // setShowAddButton(inputLength > 0 && suggestionsBp.length === 0); commented 30.04.24
  };
  const onSuggestionsClearRequested = () => {
    setSuggestionsBp([]);
    setSelectedOffpoint(null); // TO CLEAN FIRST FIELD

    // setShowAddButton(false);
  };
  const renderSuggestion = (suggestion) => <div>{suggestion.station_code}</div>;
  const onSelectBpaddress = (bpadd,type) => {
    if (bpadd) {
      if (bpadd.station_addresses) {
        setBpaddress(bpadd.station_addresses);
      } else {
        setBpaddress([]);
      }
    } else {
      setBpaddress([]);
    }
    let updatedData=[];
    if(type=="New"){
      updatedData = { ...localFormData, "new_board_point": bpadd.id, "new_board_point_name": bpadd.station_code };
    }else{
      updatedData = { ...localFormData, "hoc_board_point": bpadd.id, "hoc_board_point_name": bpadd.station_code };
    }
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
  }
  const inputProps = {
    placeholder: 'Type a Stations',
    value,
    onChange: onChange,
  };
  const onChangeOffpoint = (event, { newValue }) => {
    setValueOffpoint(newValue);
    setShowAddButtonOffpoint(false);

    if (!newValue) {
      setBpaddress(null);
    } else {
      // Filter suggestionsBp based on the input value
      const station = suggestionsOffpoint.find(station => getSuggestionValueOffpoint(station).toLowerCase() === newValue.toLowerCase());
      // If a matching station is found, set bpaddress to its station_addresses data
      if (station) {
        setSelectedOffpoint(station.station_addresses);
        setShowAddAddressButton2(true);
        setShowAddButtonOffpoint(false);
        // setStationData(station);
      } else {
        setSelectedOffpoint(null); // Set bpaddress to null if no matching station is found
        setShowAddButtonOffpoint(true);
        setShowAddAddressButton2(false);
      }
    }
  };
  const onSuggestionsFetchRequestedOffpoint = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    setSuggestionsOffpoint(
      inputLength === 0
        ? []
        : fetchStation.filter(
          (fruit) => fruit.station_code.toLowerCase().slice(0, inputLength) === inputValue &&
            fruit.station_code !== (bpaddress && bpaddress.station_code)
        )
          .filter(suggestion => suggestion.station_code !== (selectedOffpoint && selectedOffpoint.station_code))
    );
    // setShowAddButtonOffpoint(inputLength > 0 && suggestionsBp.length === 0);
  };
  const onSuggestionsClearRequestedOffpoint = () => {
    setSuggestionsOffpoint([]);
    // setBpaddress(null);
    // setShowAddButtonOffpoint(false);
  };
  const getSuggestionValueOffpoint = (suggestion) => suggestion.station_code;
  const renderSuggestionOffpoint = (suggestion) => <div>{suggestion.station_code}</div>;
  const onSelectOffpoint = (offpoint,type) => {
    if (offpoint) { setSelectedOffpoint(offpoint.station_addresses); }
    let updatedData=[];
    if(type=="New"){
      updatedData = { ...localFormData, "new_off_point": offpoint.id, "new_off_point_name": offpoint.station_code };
    }else{
      updatedData = { ...localFormData, "hoc_off_point": offpoint.id, "hoc_off_point_name": offpoint.station_code };
    }
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
  };
  const onAddButtonClick = (autosuggestId) => {
    // AddStation
    // setShowAddButton(true);
    setShowAddButton(false);
    setShowAddButtonOffpoint(false);
    setShowAddStation(true);
    if (autosuggestId == 'op') {
      setValueOffpoint('');
    } else {
      setValue('');
    }


  };
  const handleCloseModal = () => {
    setShowAddStation(false);
    setShowAddStationAddress(false);
  };
  const handleBPAddressChange = (event,type) => {
    const selectedValue = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text;
    let updatedData=[];
    if(type=="New"){
      updatedData = { ...localFormData, "new_board_address": selectedValue, "new_board_address_name": selectedText };
    }else{
      updatedData = { ...localFormData, "hoc_board_address": selectedValue, "hoc_board_address_name": selectedText };
    }
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
  };
  const handleOPAddressChange = (event,type) => {
    const selectedValue = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text;
    let updatedData=[];
    if(type=="New"){
      updatedData = { ...localFormData, "new_off_address": selectedValue, "new_off_address_name": selectedText};
    }else{
      updatedData = { ...localFormData, "hoc_off_address": selectedValue, "hoc_off_address_name": selectedText};
    }
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
    // setFormData({
    //   ...formData,
    //   offpoint_address: selectedValue
    // });
  };
  const [showAddStationAddress, setShowAddStationAddress] = useState(false)
  const handleAddAddressClick = (addressType, stationId) => {
    setStationData(stationId);
    setShowAddStationAddress(true);
    setAddressType(addressType);
    // handleReloadAddress(addressType);
  }
  const handleFormOnchange = (e) => {
    const { name, value } = e.target;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...localFormData, [name]: value };
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
    fetchTruckRateList({ 'date': (e.target.value) })
  };
  const bookingTruckChange = (bookingNumber,schedule_date_id) => {
    setLocalFormData({...localFormData, booking_list: bookingNumber,schedule_date_id:schedule_date_id});
    onFormDataChange({...localFormData, booking_list: bookingNumber,schedule_date_id:schedule_date_id});
  };
  const handleReloadAddress = async () => {
    try {
      const res = await withRFSToken.post(STATIONGRID, {})
        .then(function (res) {
          const innerData = res.data.response_data;
          const activeInnerData = res.data.response_data.Active;
          if (selectedBoardpoint !== null) { }
          setFetchStation(innerData.Active);
          setSuggestionsBp(innerData.Active);
          setSuggestionsOffpoint(innerData.Active);
          if (addressType == 'bp') {
            if (innerData) {
              const station = innerData.Active.find(station => station.id === stationData.id)
              if (station.station_addresses) {
                setBpaddress(station.station_addresses);
              } else {
                setBpaddress([]);
              }
            } else {
              setBpaddress([]);
            }
          } else {
            if (innerData) {
              const station = innerData.Active.find(station => station.id === stationData.id)
              if (station.station_addresses) {
                setSelectedOffpoint(station.station_addresses);
              } else {
                setSelectedOffpoint([]);
              }
            } else {
              setSelectedOffpoint([]);
            }
          }

          setShowAddStation(false);
          setShowAddStationAddress(false);
          return activeInnerData
        });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // const stationAddress = suggestionsBp.find(station => getSuggestionValue(station).toLowerCase() === fetchStation.station_code.toLowerCase());
  }
  function dateYMD(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  return (
    <div>

      <h4 className='subhead'>STEP 1</h4>
      <h2>Type of Truck</h2>
      <Form>
        <div className='type_work'>
          <div className="mb-3">
            <Form.Check
              inline
              label="Booking Truck"
              name="truck_type"
              type='radio'
              id='booking_truck'
              value="B"
              checked={localFormData.truck_type === 'B'}
              onChange={handleInputChange}
            />
            <Form.Check
              inline
              label="Ad Hoc"
              name="truck_type"
              type='radio'
              id='ad-hoc'
              value="A"
              checked={localFormData.truck_type === 'A'}
              onChange={handleInputChange}
            />
          </div>

          {localFormData.truck_type == 'B' && (
            <>
              {/* {['radio'].map((type) => ( */}
              <div className="mb-2">
                <Form.Check
                  inline
                  label="Existing Bookingtruck"
                  name="bookingTruck_type"
                  type='radio'
                  value='E'
                  id="existing_booking_truck"
                  checked={localFormData.bookingTruck_type === 'E'}
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="New Bookingtruck"
                  name="bookingTruck_type"
                  type='radio'
                  value='N'
                  id="new_booking_truck"
                  checked={localFormData.bookingTruck_type === 'N'}
                  onChange={handleInputChange}
                />
              </div>
              {/* ))} */}

              {localFormData.bookingTruck_type === 'E' && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label>Select Date</Form.Label>
                    <Col sm="6">
                      <Form.Control type="date" name='existing_date' value={localFormData.existing_date} onChange={handleInputChange} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="10">
                      <Form.Label>List of Bookingtruck</Form.Label>

                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Bookingtruck No</th>
                            <th>Date</th>
                            <th>Boardpoint</th>
                            <th>Offpoint</th>
                          </tr>
                        </thead>
                        <tbody>

                          {bookingTruckList?.map((value, index) => (
                            <tr key={index} onClick={() => bookingTruckChange(value.schedule_id,value.schedule_date_id)}>
                              <td>{value.bookingtruck_no}</td>
                              <td>{value.date}</td>
                              <td>{value.board_point}</td>
                              <td>{value.off_point}</td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>
                    </Col>
                  </Form.Group>
                </>
              )}

              {localFormData.bookingTruck_type === 'N' && (
                <>
                  {/* {['radio'].map((type) => ( */}
                  <div className="mb-2 pt-2">
                    <Form.Check
                      inline
                      label="Export"
                      name="new_movement_type"
                      type='radio'
                      id="export_movement_type"
                      value="E"
                      checked={localFormData.new_movement_type === 'E'}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      label="Import"
                      name="new_movement_type"
                      type='radio'
                      id="import_movement_type"
                      value="I"
                      checked={localFormData.new_movement_type === 'I'}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* ))} */}

                  <Form.Group as={Row}>
                    <Col sm="4">
                      <Form.Label>Boardpoint</Form.Label>
                      <div className='autosugg'>
                        <Autosuggest
                          suggestions={suggestionsBp}
                          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={onSuggestionsClearRequested}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                          onSuggestionSelected={(event, { suggestion }) => { onSelectBpaddress(suggestion,"New"); setSelectedBoardpoint(suggestion); }}
                        />
                        {showAddButton && <Button onClick={() => onAddButtonClick("bp")}> + Add Stations </Button>}
                      </div>


                    </Col>
                    <Col sm="4">
                      <Form.Label>Boardpoint Address ss {showAddAddressButton && <Button className='raad_adrss' onClick={() => handleAddAddressClick('bp', selectedBoardpoint)}>+ Add</Button>}</Form.Label> {/* > */}
                      <Form.Select aria-label="Default select example" value={formData.new_board_address || ''} onChange={event=>handleBPAddressChange(event,"New")}>
                        <option>Select Address</option>
                        {bpaddress &&
                          bpaddress.map((option, index) => (
                            <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                          ))
                        }
                        {/* {bpaddress && <option value="AddAddress" onClick={handleAddAddressClick} className="add-address-option">+ Add Address</option>} */}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col sm="4">
                      <Form.Label>Offpointss</Form.Label>
                      {/* <Form.Control type="email" placeholder="Select Offpoint" /> */}
                      <div className='autosugg'>
                        <Autosuggest
                          suggestions={suggestionsOffpoint}
                          onSuggestionsFetchRequested={onSuggestionsFetchRequestedOffpoint}
                          onSuggestionsClearRequested={onSuggestionsClearRequestedOffpoint}
                          getSuggestionValue={getSuggestionValueOffpoint}
                          renderSuggestion={renderSuggestionOffpoint}
                          inputProps={{ placeholder: 'Type a Stations', value: valueOffpoint, onChange: onChangeOffpoint }}
                          onSuggestionSelected={(event, { suggestion }) => { onSelectOffpoint(suggestion,"New"); setSelectedOffpointId(suggestion); }}
                        />
                        {showAddButtonOffpoint && <Button onClick={() => onAddButtonClick("op")}> + Add Stations </Button>}
                      </div>
                    </Col>
                    <Col sm="4">
                      <Form.Label>Offpoint Address  {showAddAddressButton2 && <Button className='raad_adrss' onClick={() => handleAddAddressClick('op', selectedOffpointId)}>+ Add</Button>}</Form.Label>
                      <Form.Select aria-label="Default select example" value={formData.new_off_address || ''} onChange={event=>handleOPAddressChange(event,"New")}>
                        <option>Select Address</option>
                        {selectedOffpoint &&
                          selectedOffpoint.map((option, index) =>
                            <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                          )
                        }
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  {showAddStationAddress && <AddStationAddress reloadAddress={handleReloadAddress} stationId={stationData} onClose={handleCloseModal} />}
                  {showAddStation && <AddStation reloadStation={handleReloadAddress} onClose={handleCloseModal} />}
                  <Form.Group as={Row}>
                    <Form.Label>Date and time</Form.Label>
                    <Col sm="4">
                      <Form.Control type="date" name='new_date' value={localFormData.new_date} onChange={handleInputChange} />
                    </Col>
                    <Col sm="4">
                      <Form.Control type="time" name='new_time' value={localFormData.new_time} onChange={handleInputChange} />
                    </Col>
                  </Form.Group>
                </>
              )}

            </>
          )}
          {localFormData.truck_type === 'A' && (
            <>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-2">
                  <Form.Check
                    inline
                    label="Export"
                    name="hoc_movement_type"
                    type={type}
                    id={`inline-${type}-1`}
                    value="E"
                    checked={localFormData.hoc_movement_type === 'E'}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    inline
                    label="Import"
                    name="hoc_movement_type"
                    type={type}
                    id={`inline-${type}-2`}
                    value="I"
                    checked={localFormData.hoc_movement_type === 'I'}
                    onChange={handleInputChange}
                  />
                </div>
              ))}

              <Form.Group as={Row}>
                <Col sm="4">
                  <Form.Label>Boardpoint</Form.Label>
                  <div className='autosugg'>
                    <Autosuggest
                      suggestions={suggestionsBp}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                      onSuggestionSelected={(event, { suggestion }) => { onSelectBpaddress(suggestion,"HOC"); setSelectedBoardpoint(suggestion); }}
                    />
                    {showAddButton && <Button onClick={() => onAddButtonClick("bp")}> + Add Stations </Button>}
                  </div>


                </Col>
                <Col sm="4">
                  <Form.Label>Boardpoint Address {showAddAddressButton && <Button className='raad_adrss' onClick={() => handleAddAddressClick('bp', selectedBoardpoint)}>+ Add</Button>}</Form.Label> {/* > */}
                  <Form.Select aria-label="Default select example" value={formData.hoc_board_address || ''} onChange={event=>handleBPAddressChange(event,"HOC")}>
                    <option>Select Address</option>
                    {bpaddress &&
                      bpaddress.map((option, index) => (
                        <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                      ))
                    }
                    {/* {bpaddress && <option value="AddAddress" onClick={handleAddAddressClick} className="add-address-option">+ Add Address</option>} */}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4">
                  <Form.Label>Offpoint</Form.Label>
                  {/* <Form.Control type="email" placeholder="Select Offpoint" /> */}
                  <div className='autosugg'>
                    <Autosuggest
                      suggestions={suggestionsOffpoint}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequestedOffpoint}
                      onSuggestionsClearRequested={onSuggestionsClearRequestedOffpoint}
                      getSuggestionValue={getSuggestionValueOffpoint}
                      renderSuggestion={renderSuggestionOffpoint}
                      inputProps={{ placeholder: 'Type a Stations', value: valueOffpoint, onChange: onChangeOffpoint }}
                      onSuggestionSelected={(event, { suggestion }) => { onSelectOffpoint(suggestion,"HOC"); setSelectedOffpointId(suggestion); }}
                    />
                    {showAddButtonOffpoint && <Button onClick={() => onAddButtonClick("op")}> + Add Stations </Button>}
                  </div>
                </Col>
                <Col sm="4">
                  <Form.Label>Offpoint Address  {showAddAddressButton2 && <Button className='raad_adrss' onClick={() => handleAddAddressClick('op', selectedOffpointId)}>+ Add</Button>}</Form.Label>
                  <Form.Select aria-label="Default select example" value={formData.hoc_off_address || ''} onChange={event=>handleOPAddressChange(event,"HOC")}>
                    <option>Select Address</option>
                    {selectedOffpoint &&
                      selectedOffpoint.map((option, index) =>
                        <option key={index} value={option.id}>{option.handler_name} - {option.address_1}</option>
                      )
                    }
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label>Date and time</Form.Label>
                <Col sm="4">
                  <Form.Control type="date" name='hoc_date' value={localFormData.hoc_date} onChange={handleInputChange} />
                </Col>
                <Col sm="4">
                  <Form.Control type="time" name='hoc_time' value={localFormData.hoc_time} onChange={handleInputChange} />
                </Col>
              </Form.Group>




            </>
          )}


        </div>
      </Form>

    </div>
  )
}

const TruckOrderTwo = ({ formData, onFormDataChange2 }) => {

  const [localFormData, setLocalFormData] = useState({
    truck_no: '', truck_load: '', temperature: 'Y', drivers: '', adr: '', remarks: '',drivers_number:'',truck_inf_date:'',
    truck_inf_time:'',
  });
  useEffect(() => {
    setLocalFormData(formData);
  }, []);
  const [tempShow, setTempShow] = useState();
  const TempYes = (e) => { setTempShow(true); const value = e.target.value; setLocalFormData({ ...localFormData, temperature: value }); }
  const TempNo = () => setTempShow(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...localFormData, [name]: value };
    setLocalFormData(updatedData);
    onFormDataChange2(updatedData);
  };

 

  return (
    <div>

      <h4 className='subhead'>STEP 2</h4>
      <h2>Truck Informaion</h2>
      <Form>
        <div className='type_work'>

          <Form.Group as={Row}>
            <Form.Label>Operational Truck No </Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="#123456" name='truck_no' value={localFormData.truck_no} onChange={handleInputChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Date and time</Form.Label>
            <Col sm="4">
              <Form.Control type="date" name="truck_inf_date" onChange={handleInputChange} />
            </Col>
            <Col sm="4">
              <Form.Control type="time" name="truck_inf_time" onChange={handleInputChange} />
            </Col>
          </Form.Group>
          <div>
            <Form.Label>Truck Load</Form.Label>
            {['radio'].map((type) => (
              <div key={`inline-${type}`} className="mb-2">
                <Form.Check
                  inline
                  label="FLT"
                  name="truck_load"
                  type={type}
                  id={`inline-${type}-1`}
                  value="F"
                  checked={localFormData.truck_load === 'F'}
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="LTL"
                  name="truck_load"
                  type={type}
                  id={`inline-${type}-2`}
                  value="L"
                  checked={localFormData.truck_load === 'L'}
                  onChange={handleInputChange}
                />
              </div>
            ))}
          </div>
          <div>
            <Form.Label>Temperature Controled</Form.Label>
            {['radio'].map((type) => (
              <>
                <div key={`inline-${type}`} className="mb-2">
                  <Form.Check
                    inline
                    label="YES"
                    name="temperature"
                    type={type}
                    id={`inline-${type}-1t`}
                    onChange={handleInputChange}
                    checked={localFormData.temperature === '1'}
                    value='1'
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="temperature"
                    type={type}
                    id={`inline-${type}-2t`}
                    checked={localFormData.temperature === '0'}
                    onChange={handleInputChange}
                    value='0'
                  />
                  {localFormData.temperature == 'Y' ? <span>18 - 21</span> : <span>NA</span>}
                </div>
              </>
            ))}
          </div>
          <Form.Group as={Row}>

            <Col sm="5">
              <Form.Label>Number off Drivers</Form.Label>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-2">
                  <Form.Check
                    inline
                    label="1"
                    name="drivers_number"
                    type={type}
                    id={`inline-${type}-1d`}
                    value="1"
                    checked={localFormData.drivers_number === '1'}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    inline
                    label="2"
                    name="drivers_number"
                    type={type}
                    id={`inline-${type}-2d`}
                    value="2"
                    checked={localFormData.drivers_number === '2'}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
            </Col>
            <Col sm="5">
              <Form.Label>ADR Required</Form.Label>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-2">
                  <Form.Check
                    inline
                    label="YES"
                    name="adr"
                    type={type}
                    id={`inline-${type}-1a`}
                    value="1"
                    checked={localFormData.adr === '1'}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="adr"
                    type={type}
                    id={`inline-${type}-2a`}
                    value="0"
                    checked={localFormData.adr === '0'}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
            </Col>

          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label>Remarks</Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="Enter Remarks" name='remarks' value={localFormData.remarks} onChange={handleInputChange} />
            </Col>
          </Form.Group>



        </div>
      </Form>

    </div>
  )
}

const TruckOrderThree = ({ formData, onFormDataChange3 }) => {
  const [rateChart, setRateChart] = useState();
  const [selectedOption, setSelectedOption] = useState('FR');
  const [FixedRate, setfixedRate] = useState('');
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [localFormData, setLocalFormData] = useState({
    rate:'',rateId:''
  });
  
  useEffect(() => {
    const reloadRates = async () => {
      try {
          const res = await withRFSToken.post(TRUCKERRATELIST, {})
              .then(function (res) {
                  const innerData = res.data.response_data;
                  console.log("🚀 ~ .then ~ 2002:", innerData)
                  setRateChart(innerData);
              });
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }
    const fetchData = async () => {
      try {
        const res = await withRFSToken.post(STATIONGRID, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            const activeInnerData = res.data.response_data.Active;
            const transformedData = activeInnerData ? activeInnerData.map(item => ({
                id: item.id,
                value: item.station_id,
                label: item.station_code
            })) : []; // For multiselect
            setDropdownData(transformedData); // For multiselect
            
            return activeInnerData
          });
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    reloadRates();
    fetchData();
    setLocalFormData(formData);
  }, []);


  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    const updatedData = { ...localFormData, rate: event.target.value };
    setLocalFormData(updatedData);
    onFormDataChange3(updatedData);
  };
  const handleFixedRateChange = (event) => {
    setfixedRate(event.target.value);
    const updatedData = { ...localFormData, rateId: event.target.value };
    setLocalFormData(updatedData);
    onFormDataChange3(updatedData);
  };
  const handleDropdownChange = (event) => {
    const updatedData = { ...localFormData, rateId: event.id };
    setLocalFormData(updatedData);
    onFormDataChange3(updatedData);
  };


  return (
    <div>
      <form>
        <h4 className='subhead'>STEP 3</h4>
        <h2>Adding Documents</h2>
        <div className='type_work'>
          <Form.Check
            inline
            label={"Fixed Rate"}
            type="radio"
            name="radioGroup"
            value={"FR"}
            checked={selectedOption === "FR"}
            onChange={handleRadioChange}
          />
          <Form.Check
            inline
            label={"Free Market"}
            type="radio"
            name="radioGroup"
            value={"FM"}
            checked={selectedOption === "FM"}
            onChange={handleRadioChange}
          />
          {/* {['Fixed Rate', 'Free Market'].map((label, index) => (
            <div key={`radio-${index}`} className="d-inline">
              <Form.Check
                inline
                label={label}
                type="radio"
                name="radioGroup"
                value={label}
                checked={selectedOption === label}
                onChange={handleRadioChange}
              />
            </div>
          ))} */}

          {selectedOption === 'FR' && (
            <div class="plans mt-3">
              {rateChart && rateChart.map((rates,index) => (                    
                <>
                  <label class="plan basic-plan" for={"rate"+index}>
                    <input type="radio" name="plan" id={"rate"+index} value={rates.id} onChange={handleFixedRateChange}/>
                    <div class="plan-content">
                      <ul>
                        <li><span>Boardpoint: </span> <span>{rates.boardpoint}</span></li>
                        <li><span>Truck Load: </span> <span>{rates.truck_load}</span></li>
                        <li><span>Temperature: </span> <span>{rates.temperature}</span></li>
                        <li><span>Drivers: </span> <span>{rates.drivers}</span></li>
                        <li><span>Price: </span> <span>{rates.price}</span></li>
                      </ul>
                    </div>
                  </label>
                </>
              ))}
            </div>
          )}

          {selectedOption === 'FM' && (
            <div className='multis'>
              <Form.Label className='mt-2'>List of Carriers</Form.Label>
              <Form.Group as={Row}>
                <Col sm="6">
                  <Select
                      options={dropdownData}
                      onChange={handleDropdownChange}
                      placeholder="Station"
                      className='autoslct'
                      name="origin"
                      value={dropdownData.find(option => option.value === formData.repair_station)}
                    />
                </Col>
              </Form.Group>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

const TruckOrderFour = ({ formData, onFormDataChange4 }) => {


  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [base64Files, setBase64Files] = useState([]);
  const [localFormData, setLocalFormData] = useState({
    files:''
  });
  useEffect(() => {
    setLocalFormData(formData);
  }, []);
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    getBase64(droppedFiles[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    base64Files.splice(index, 1);

  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
    };
    reader.onerror = function (error) {
      
    };
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithBase64 = [];
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        filesWithBase64.push({ file, base64: reader.result });
        if (filesWithBase64.length === selectedFiles.length) {
          setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
          onFormDataChange4(filesWithBase64);
          setLocalFormData(filesWithBase64);
        }
      };
      reader.readAsDataURL(file);
    });
  }
  return (
    <div>

      <h4 className='subhead'>STEP 4</h4>
      <h2>Adding Documents</h2>
      <Form>
        <div className='type_work'>

          <div>
            <div
              className='dropfile'
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <h3>Drag and drop files here</h3>
              <p>Or</p>
              <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
              <span>Maximum file size 4 MB</span>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
              />


            </div>


            <div className='uploadbox'>
              {files.map((file, index) => (
                <div key={index} className='uploadfil'>
                  {file.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Preview"
                      style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                    />
                  ) : (
                    <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                  )}
                  <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                </div>
              ))}

            </div>
          </div>

        </div>
      </Form>

    </div>
  )
}





export { TruckOrderOne, TruckOrderTwo, TruckOrderThree, TruckOrderFour };