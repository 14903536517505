import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

const Sample = () => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[^\d]/g, ''); // Remove any non-numeric characters
    let formattedValue = '';

    // Add a hyphen after the third character
    if (val.length > 3) {
      formattedValue += val.substr(0, 3) + '-';
      val = val.substr(3);
    }

    // Add a space after the eighth character
    if (val.length > 4) {
      formattedValue += val.substr(0, 4) + ' ';
      val = val.substr(4);
    }

    // Append the rest of the value
    formattedValue += val;

    // Set the formatted value and ensure it doesn't exceed 13 characters
    setValue(formattedValue.substr(0, 13));
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      maxLength={13}
    />
  );
};
export default Sample;