import { faChevronLeft, faChevronRight, faCircleXmark, faPlus, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { withToken } from '../../Services/axios.js';
import { DASHBOARD } from '../../Utils/conts.js';
import { AIRLINECONCERNED, BOOKINGSLOTS, CUSTOMCODE, HANDLER_ADDRESS, SECURITYCODE, SPECIALHANDLINGCODE } from '../../Utils/conts.js';
import Docmnt from '../../assets/images/google-docs.png';
import {  toast } from 'react-toastify';



const ShipmentFormsOne = ({ formData, onFormDataChange, completedData }) => {
  // console.log("🚀 ~ ShipmentFormsOne ~ completedData:", completedData)
  // const [rowId, setRowId] = useState('');

  const [localFormData, setLocalFormData] = useState({ 
    andler_address: '', handler_werehouse: '', 
    handler_address_val: '', handler_werehouse_val: ''
  });
  // const [localFormData, setLocalFormData] = useState(() => {
  //   return editedData ? {andler_address: editedData.andler_address, handler_werehouse: editedData.handler_werehouse} : {andler_address: '', handler_werehouse: ''};
  // });

  const [handlerAddress, setHandlerAddress] = useState([]);
  const [handlerWarehouse, setHandlerWarehouse] = useState([]);

  // useEffect(() => {
  //   setLocalFormData(formData);
  //   getHandlerAddress();

  // }, []);//formData

  useEffect(() => {
    if (completedData) {
      console.log("🚀 ~ useEffect ~ rrr:", completedData)
      // Extract editedData
      const completedDataKeys = Object.keys(completedData);
      const firstKey = completedDataKeys.length > 0 ? completedDataKeys[0] : null;
      console.log("🚀 ~ useEffect ~ firstKey:", firstKey)
      const editedData = firstKey && completedData[firstKey].handler_information ? completedData[firstKey].handler_information : null;
      console.log("🚀 ~ useEffect ~ editedData:", editedData)
      // console.log("🚀 ~ useEffect ~ completedData:", firstKey)
      
      // Update localFormData with editedData
      if (editedData) {
        setLocalFormData({
          handler_address: editedData.handler_address || '',
          handler_werehouse: editedData.handler_werehouse || '',
          handler_address_val: completedData[0].handler_information.handler_address_val || '',
          handler_werehouse_val: completedData[0].handler_information.handler_werehouse_val || '',
          
        });
      }
    }
  }, [completedData]);

  useEffect(() => {
    // setLocalFormData(formData);
    getHandlerAddress();

  }, [localFormData.handler_address]);//formData

  console.log('jj', localFormData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log('e.target', e.target.value);
    // setHandlerWarehouse([]);
    const filterHandlerWarehouse = handlerAddress.filter((item) => item.id == e.target.value);
    console.log("🚀 ~ handleInputChange ~ filterHandlerWarehouse:", filterHandlerWarehouse[0]?.wareHouses[0])
    let updatedData = {
      ...localFormData, [name]: value,
      // handler_address_val: filterHandlerWarehouse[0]?.orgName
    };
    if (filterHandlerWarehouse[0]?.wareHouses.length > 0) {
      setHandlerWarehouse(filterHandlerWarehouse[0]?.wareHouses);
      if (filterHandlerWarehouse[0]?.wareHouses.length == 1) {
        const gethandler_werehouse = filterHandlerWarehouse[0]?.wareHouses[0].wareHouse_id;
        toast.info('There is only one Warehose Address found');
        console.log("🚀 ~ handleInputChange ~ gethandler_werehouse:", gethandler_werehouse)
        // setLocalFormData(prevFormData => ({ ...prevFormData, handler_werehouse: filterHandlerWarehouse[0]?.wareHouses[0] }));
        // setHandlerWarehouse([filterHandlerWarehouse[0]?.wareHouses[0]])
        updatedData = {
          ...localFormData, [name]: value,
          handler_werehouse: gethandler_werehouse,
          handler_werehouse_val: filterHandlerWarehouse[0]?.wareHouses[0].wareHouse_location,
          handler_address_val: filterHandlerWarehouse[0]?.orgName
        };
      }
    }


    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
    // getHandlerAddress();
  };

  const handleChangeWarehouse = (e) => {
    const { name, value } = e.target;
    // If warehouse multiple
    // const findHandlerAddress = handlerAddress.filter((item) => item.id == localFormData.handler_address);
    // const findHandlerWarehouse = findHandlerAddress[0]?.wareHouses.find((item) => item.wareHouse_id == e.target.value);
    // end If
    const updatedData = { 
      ...localFormData, [name]: value,
      // handler_werehouse_val: findHandlerWarehouse.wareHouse_location || '',
    };
    setLocalFormData(updatedData);
    onFormDataChange(updatedData);
  };

  const getHandlerAddress = async () => {
    try {
      const res = await withToken.post(HANDLER_ADDRESS, {});
      console.log("handler address===>", res.data.data.handler);
      setHandlerAddress(res.data.data.handler);
      const getwarehouses = res.data.data.handler.flatMap(item => item.wareHouses.map(warehouse => ({ id: item.id, ...warehouse })));
      // console.log("🚀 ~ getHandlerAddress ~ getwarehouses:", getwarehouses)

      // console.log("🚀 ~ getHandlerAddress ~ handler_address:", handlerAddress)
      // setHandlerWarehouse(getwarehouses);
      // onFormDataChange(getwarehouses);

      if (!localFormData.handler_address == '') {
        const backwarehouseId = getwarehouses.find(warehouse => warehouse.id == localFormData.handler_address);
        console.log("🚀 ~ getHandlerAddress ~ backwarehouseId:", backwarehouseId)
        // setHandlerWarehouseAll({...handlerWarehouseAll, backwarehouseId})
        setHandlerWarehouse([backwarehouseId]);
        // onFormDataChange({
        //   handler_address_val: '',
        //   handler_werehouse_val:backwarehouseId.wareHouse_location});
      }
    } catch (error) {
      console.error('Error sending request:', error);
    }


  };

  useEffect(() => {
    setLocalFormData(formData);
    // getHandlerAddress();

  }, [formData]);//formData



  return (
    <div>
      <form>
        <h4 className='subhead'>Shipments</h4>
        <h2>Handler Information</h2>
        <div className="row">
          <div className="col">
            <label className="form-label">Handler Address <span>*</span></label>
            <select className="form-select" name="handler_address" value={localFormData.handler_address} onChange={handleInputChange}>
            {!localFormData.handler_address && <option>Please Select</option>}
              {handlerAddress.map(option => (
                <option value={option.id} key={option.id}>{option.orgName} | {option.address}</option>
              ))}
            </select>
          </div>

          <div className="col">
            <label className="form-label">Handler Warehouse <span>*</span></label>
            <select className="form-select" name="handler_werehouse" value={localFormData.handler_werehouse} onChange={handleChangeWarehouse}>
            {!localFormData.handler_werehouse && <option>Please Select</option>}
              {handlerWarehouse.map((option, index) => (<option value={option.wareHouse_id} key={index}>{option.wareHouse_location}</option>))}
            </select>


          </div>
        </div>
      </form>
    </div>
  )
}

const ShipmentFormsTwo = ({ formData, onFormDataChange2, completedData }) => {
  const [localFormData, setLocalFormData] = useState({ movement_type: '', type_of_vehicle: '', type_of_loading: '', airline_conserned: [], refrance_number: '' });
  const [airlineConserned, setAirlineConserned] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setLocalFormData(formData);
    getAirlineConcerned();
   }, []);


  useEffect(() => {
    console.log("completedData:", completedData);
    if (completedData) {
      const completedDataKeys = Object.keys(completedData);
      const secondKey = completedDataKeys.length > 0 ? completedDataKeys[1] : null;
      // console.log("Second Key:", secondKey);
      const editedData = completedData[secondKey].trip_information || {};
      console.log("editedData:ggg", editedData);
      
      if (editedData){
      setLocalFormData({
        movement_type: editedData.movement_type || '',
        type_of_vehicle: editedData.type_of_vehicle || '',
        type_of_loading: editedData.type_of_loading || '',
        airline_conserned: editedData.airline_conserned || [],
        refrance_number: editedData.refrance_number || ''
      });
    }
  }
  }, [completedData]);
  
  // console.log("localFormData:QQQ", localFormData);
  

  const handleSelectionChange = (selectedValues) => {
    if (selectedDropdownValue) {
      const selectedData = airlineConserned.find((item) => item.value === selectedDropdownValue);
      const newData = { id: selectedData.value, address: selectedData.address, };
      console.log("Selected value(s):", selectedValues);

      // Update field2_1 in localFormData with the selected values
      const uypdateData = (prevState => ({

        ...prevState,
        airline_conserned: selectedValues.map(value => ({ id: value.id, name: value.orgName })) // Adjust the structure as needed
      }));
      console.log("🚀 ~ uypdateData ~ uypdateData:", uypdateData);
      setLocalFormData(prevState => ({
        ...prevState,
        airline_conserned: selectedValues.map(value => ({ id: value.id, name: value.orgName })) // Adjust the structure as needed
      }));
      onFormDataChange2(prevState => ({
        ...prevState,
        airline_conserned: selectedValues.map(value => ({ id: value.id, name: value.orgName })) // Adjust the structure as needed
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("line no 94==>", e.target.value);

    if (name === 'refrance_number') {
      // Check if the value consists of only numeric characters
      if (/^\d+$/.test(value) || value === '') {
        // Update the state with the new value if it's valid
        // setLocalFormData((prevData) => ({ ...prevData, [name]: value }));
        // onFormDataChange2((prevData) => ({ ...prevData, [name]: value }));
        const updatedData = { ...localFormData, [name]: value };
        setLocalFormData(updatedData);
        onFormDataChange2(updatedData);
      }
    } else {
      // For other fields, update the state directly
      // setLocalFormData((prevData) => ({ ...prevData, [name]: value }));
      const updatedData = { ...localFormData, [name]: value };
      setLocalFormData(updatedData);
      onFormDataChange2(updatedData);
    }
    // const filter = res.filter((item) => item.id === e.target.value);
    // setProductList(filter);



  };

  const getAirlineConcerned = async () => {
    try {
      const res = await withToken.post(AIRLINECONCERNED, {});
      // console.log("Airline==>", res.data.data);
      setAirlineConserned(res.data.data);

    } catch (error) {
      console.error('Error sending request:', error);
    }

  };



  return (
    <div>
      <form>
        <h4 className='subhead'>Shipments</h4>
        <h2>Trip Information</h2>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label">Movement Type <span>*</span></label>

            <div className='d-flex'>
              <div className="form-check">
               
                <input type="radio" className="form-check-input" id="radio1" name="movement_type" value='0'  checked={localFormData.movement_type === '0'}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="radio1">Export (Delivery)</label>
              </div>
              <div className="form-check">
                
                <input type="radio" className="form-check-input" id="radio2" name="movement_type" value='1' checked={localFormData.movement_type === '1'}
                  onChange={handleInputChange} />
                <label className="form-check-label" htmlFor="radio2">Import (Pickup)</label>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label className="form-label">Type of Vehicle <span>*</span></label>
            <div className='d-flex'>
              <div className="form-check">
              
                <input type="radio" className="form-check-input" id="radio3" name="type_of_vehicle" value="0" checked={localFormData.type_of_vehicle === '0'}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="radio3">Van</label>
              </div>
              <div className="form-check">
               
                <input type="radio" className="form-check-input" id="radio4" name="type_of_vehicle" value="1" checked={localFormData.type_of_vehicle === '1'}
                  onChange={handleInputChange} />
                <label className="form-check-label" htmlFor="radio4">Truck</label>
              </div>

            </div>

          </div>
        </div>
        <div className="col-6">
          <div className='mb-3'>
            <label className="form-label">Type of loading/unloading <span>*</span></label>
            <div className='d-flex'>
              <div className="form-check">
               
                <input type="radio" className="form-check-input" id="radio5" name="type_of_loading" value="0" checked={localFormData.type_of_loading === '0'}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="radio5">Back of Trailer</label>
              </div>
              <div className="form-check">
               
                <input type="radio" className="form-check-input" id="radio6" name="type_of_loading" value="1" checked={localFormData.type_of_loading === '1'}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="radio6">Side of Trailer</label>
              </div>

            </div>
          </div>
          <div className='mb-3'>
            <label className="form-label">Airline Concerned <span>*</span></label>
            <MultiSelect
              options={airlineConserned}
              name='airline_conserned'
              value={localFormData.airline_conserned}
              optionLabel="orgName"
              optionValue="id"
              onChange={(selected) => {
                const updatedData = { ...localFormData, airline_conserned: selected };
                setLocalFormData(updatedData);
                onFormDataChange2(updatedData);
              }}
              labelledBy="Select"
            />
          </div>
          <div>
            <label className="form-label">Reference Number</label>
            <input className="form-control" type="text" maxLength='8' onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }} placeholder='#1234567' name="refrance_number" value={localFormData.refrance_number} onChange={handleInputChange} />
          </div>
        </div>
      </form>
    </div>
  )
}

const ShipmentFormsThree = ({ formData, onFormDataChange3, completedData }) => {
  const [addShipment, setAddShipment] = useState([
    {
      id: '1',
      type_code: '', serial_number: '',
      owner_code: '', airline_name: '', condition_code: '',
    }
  ]);

  const [customCode, setcustomCode] = useState([]);
  const [specialHandlingCode, setSpecialHandlingCode] = useState([]);
  const [securityCode, setSecurityCode] = useState([]);

  

  useEffect(() => {
    console.log("completedData:", completedData);
    if (completedData) {
      const completedDataKeys = Object.keys(completedData);
      const thirdKey = completedDataKeys.length > 0 ? completedDataKeys[2] : null;
      console.log("Second Key:", thirdKey);
      const editedData = completedData[thirdKey].cargo_information;
      console.log("editedData:", editedData);

      if (thirdKey && completedData[thirdKey].cargo_information) {
        const cargoInfoObject = completedData[thirdKey].cargo_information;
        console.log("Cargo Information:", cargoInfoObject);
        
        if (Array.isArray(cargoInfoObject)) {
          setAddShipment([...cargoInfoObject]); // If formData is already an array, spread it
        } else if (cargoInfoObject) {
          setAddShipment([cargoInfoObject]); // If formData is an object, wrap it in an array
        }
        
        // Update state with the cargo information
        // setAddShipment(prevShipment => {
        //   // Map over each cargo information key
        //   return Object.keys(cargoInfoObject).map(key => {
        //     const cargoInfo = cargoInfoObject[key];
        //     // Find the item in prevShipment with the matching ID
        //     const matchingItemIndex = prevShipment.findIndex(item => item.id === cargoInfo.id);
        //     // If a matching item is found, merge the cargo information into it
        //     if (matchingItemIndex !== -1) {
        //       const updatedItem = { ...prevShipment[matchingItemIndex], ...cargoInfo };
        //       prevShipment.splice(matchingItemIndex, 1, updatedItem); // Replace the item in the array
        //       return updatedItem;
        //     } else {
        //       // If no matching item is found, add the cargo information as a new item
        //       return cargoInfo;
        //     }
        //   });
        // });
      }
  }
  }, [completedData]);
        console.log("🚀 ~ useEffect ~ setAddShipment:", addShipment)

  
  // console.log("🚀 ~ ShipmentFormsThree ~ formData:", formData)
  useEffect(() => {
    if (Array.isArray(formData)) {
      setAddShipment([...formData]); // If formData is already an array, spread it
    } else if (formData) {
      setAddShipment([formData]); // If formData is an object, wrap it in an array
    }
  }, [formData]);

  const addShpmts = () => {
    // const newShpId = (addShipment.length + 1).toString();
    const lastId = addShipment.length > 0 ? parseInt(addShipment[addShipment.length - 1].id) : 0;
    const newId = (lastId + 1).toString();
    setAddShipment((prev) => [
      ...prev,
      {
        // id: (prev.length + 1).toString(),
        id: newId,

        type_code: '', serial_number: '',
        owner_code: '', airline_name: '', condition_code: '',

      }
    ]);
  }
  const deleteShpmts = (id) => {
    const updatedSections = addShipment.filter((section) => section.id !== id);
    setAddShipment(updatedSections);
    onFormDataChange3(updatedSections);
  };

  useEffect(() => {
    getCustomCode();
    getSpecialHandling();
    getSecurity();
  }, []);

  const handleSectionChange = (e, sectionId, fieldName) => {
    const { value } = e.target;

      const updatedSections = addShipment.map((section) => {
        if (section.id === sectionId) {
          return {...section, [fieldName]: value};
        }
        return section;
      });
      setAddShipment(updatedSections);
      onFormDataChange3(updatedSections);

  };


  // const [selected, setSelected] = useState("loose");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const handleOptionChange = (e, sectionId) => {
    // Show confirmation modal
    setShowConfirmation(true);
    setSelectedSectionId(sectionId);
  };
  const handleConfirmation = (confirm) => {
    if (confirm) {
      // User clicked "Yes," toggle the field values
      const updatedSections = addShipment.map((section) => {
        if (section.id === selectedSectionId) {
          // Toggle between 'intact' and 'loose'
          section.info_type = section.info_type === 'loose' ? 'intact' : 'loose';

          // If switching to "Loose," remove additional MawB sections
          if (section.info_type === 'loose') {
            section.mawBills = [section.mawBills[0]];
          }
        }
        return section;
      });
      setAddShipment(updatedSections);
    }

    // Hide confirmation modal
    setShowConfirmation(false);
    setSelectedSectionId(null);
  };


  const getCustomCode = async () => {
    try {
      const res = await withToken.post(CUSTOMCODE, {});
      // console.log("custome code==>", res.data.data.customCodes);
      setcustomCode(res.data.data.customCodes);

    } catch (error) {
      console.error('Error sending request:', error);
    }

  };

  const getSpecialHandling = async () => {
    try {
      const res = await withToken.post(SPECIALHANDLINGCODE, {});
      // console.log("spl handle code==>", res.data.data.specialHandlingcodes);
      setSpecialHandlingCode(res.data.data.specialHandlingcodes);

    } catch (error) {
      console.error('Error sending request:', error);
    }

  };

  const getSecurity = async () => {
    try {
      const res = await withToken.post(SECURITYCODE, {});
      console.log(" security code==>", res.data.data.securityCode);
      setSecurityCode(res.data.data.securityCode);

    } catch (error) {
      console.error('Error sending request:', error);
    }

  };

useEffect(() =>{

}, [addShipment]);
  console.log(" 656 addShipment==> ", addShipment);

  return (
    <div>
      <form>
        <h4 className='subhead'>ULD Stack</h4>
        <h2>Cargo Information</h2>

        <div className='rascroll'>

          {addShipment.map((section) => {
            return (

              <div className='addshipment' key={section.id} id={section.id}>
                <div className='d-flex align-items-center mb-3'>

                  {addShipment.length !== 1 && (
                    <div className='raclose'><button onClick={() => deleteShpmts(section.id)}> <FontAwesomeIcon icon={faXmark} color='#f99494' /></button></div>
                  )}
                </div>

                <div className='mawb'>

                  <div className='d-flex airbill mb-3 rauldstack'>
                    <div>
                      <label className="form-label">Type Code <span className='important'>*</span></label>
                      <span className='d-flex'>
                        <input className="form-control" id="ex2" type="text" style={{ width: '40px', marginRight: '5px' }} value={section.type_code} onChange={(e) => handleSectionChange(e, section.id, 'type_code')} />
                      </span>
                    </div>
                    <div>
                      <label className="form-label">Serial Number <span className='important'>*</span></label>
                      <input className="form-control" id="ex2" type="text" style={{ width: '55px' }} value={section.serial_number} onChange={(e) => handleSectionChange(e, section.id, 'serial_number')} />
                    </div>
                    <div>
                      <label className="form-label">Owner Code <span className='important'>*</span></label>
                      <input className="form-control" id="ex2" type="text" style={{ width: '55px' }} value={section.owner_code} onChange={(e) => handleSectionChange(e, section.id, 'owner_code')} />
                    </div>
                    <div>
                      <label className="form-label">Airline Name</label>
                      <input className="form-control" id="ex2" type="text" style={{ width: '70px' }} value={section.airline_name} onChange={(e) => handleSectionChange(e, section.id, 'airline_name')} />
                    </div>
                    <div>
                      <label className="form-label">Condition Code</label>
                      <select className="form-select" style={{ width: '85px' }} value={section.condition_code}  onChange={(e) => handleSectionChange(e, section.id, 'condition_code')}>
                        <option>Select</option>
                        {customCode.map(option => (
                          <option key={option.id}>{option.code}</option>
                        ))}
                      </select>
                    </div>


                  </div>
                </div>

              </div>
            )
          })}

          <div className='text-end'><button type="button" onClick={addShpmts} className="btn btn-primary addshipbtn"><FontAwesomeIcon icon={faPlus} /> Add ULD </button></div>

        </div>
      </form>

      <Modal className='raintrac' show={showConfirmation} onHide={() => handleConfirmation(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h2>Confirm Change</h2>
          <p>If you proceed with the change, all data in this section will be removed.
            Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleConfirmation(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleConfirmation(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

const ShipmentFormsFour = ({ formData, onFormDataChange4, completedData }) => {

  const [localFormData, setLocalFormData] = useState({ bookingslot_date: '', bookingslot_time: '' });
  useEffect(() => {
    setLocalFormData(formData);
    // getHandlerAddress();

  }, []);//formData

  const [currentDate, setCurrentDate] = useState(new Date());
  const daysToShow = 7;



  function handleNext() {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + daysToShow);
    setCurrentDate(newDate);
  }

  function handlePrevious() {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - daysToShow);
    setCurrentDate(newDate);
  }

  function handleDateChange(event) {
    const [year, month] = event.target.value.split('-').map(Number);
    const newDate = new Date(year, month - 1, 1);
    setCurrentDate(newDate);
  }

  const [apiData, setApiData] = useState([]);
  const [showBookingSlots, setShowBookingSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await withToken.post(BOOKINGSLOTS, {});
        // console.log("🚀 ~ fetchData ~ res:", res)
        const newSlots = res.data.data.availableTimingSlot;
        // console.log("🚀 ~ fetchData ~ res:", newSlots)
        setApiData(newSlots);

        const formattedDate = currentDate.toISOString().split('T')[0];
        const slotsForDate = newSlots[formattedDate] || [];
        setShowBookingSlots(slotsForDate);

      } catch (error) {
        console.error('Error fetching booking slots:', error);
      }
    };
    fetchData();
    const formattedDate = selectedDate.toISOString().split('T')[0];
    const slotsForDate = apiData[formattedDate] || [];
    // console.log("🚀 ~ useEffect ~ slotsForDate:", slotsForDate)
    setShowBookingSlots(slotsForDate);
  }, []);

  useEffect(() => {
    if (completedData) {
      // Extract editedData
      const completedDataKeys = Object.keys(completedData);
      const fourthKey = completedDataKeys.length > 0 ? completedDataKeys[3] : null;
      const editedData = fourthKey && completedData[fourthKey].booking_slot ? completedData[fourthKey].booking_slot : null;
      // console.log("🚀 ~ useEffect ~ completedData:", fourthKey)
      
      // Update localFormData with editedData
      if (editedData) {
        setLocalFormData({
          bookingslot_date: editedData.bookingslot_date || '',
          bookingslot_time: editedData.bookingslot_time || ''
        });
      }
    }
  }, [completedData]);

  // console.log("🚀 ~ useEffect ~~~~ setShowBookingSlots:", setShowBookingSlots)
  const countAvailableSlots = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    const slotsForDate = apiData[formattedDate] || [];
    console.log("🚀 ~ countAvailableSlots ~ slotsForDate:", slotsForDate)
    const availableSlotsCount = slotsForDate.filter(slot => slot.status === 1).length;
    return availableSlotsCount;
  };

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const handleDayClick = (date) => {
    if (!apiData) {
      console.error('API data is not available');
      return;
    }
    // Convert the date to the format "YYYY-MM-DD"
    const formattedDate = date.toISOString().split('T')[0];

    // Retrieve the slots for the clicked date from the apiData object
    const slotsForDate = apiData[formattedDate] || [];

    // Update the state with the fetched slots
    setShowBookingSlots(slotsForDate);
    setSelectedDate(date);  // Store the selected date
  };


  const handleSlotClick = (time) => {

    const updatedSlots = showBookingSlots.map(slot => ({ ...slot, selected: false }));
    const updatedSlot = updatedSlots.find(slot => slot.time === time && slot.status === 1);
    if (updatedSlot) {
      updatedSlot.selected = true;
    }
    setShowBookingSlots(updatedSlots);


    const formattedDate = selectedDate.toISOString().split('T')[0];
    setLocalFormData({ bookingslot_date: formattedDate, bookingslot_time: time });
    onFormDataChange4({ bookingslot_date: formattedDate, bookingslot_time: time });
  };

  function getDayName(date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[date.getDay()];
  }
  
  function getMonthYearOptions() {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentYear = new Date().getFullYear();
    const options = [];
    const selectedYear = localFormData.bookingslot_date ? new Date(localFormData.bookingslot_date).getFullYear() : null;
  const selectedMonth = localFormData.bookingslot_date ? new Date(localFormData.bookingslot_date).getMonth() + 1 : null;
    for (let year = currentYear - 1; year <= currentYear + 1; year++) {
      for (let month = 1; month <= 12; month++) {
        options.push(
        <option key={`${year}-${month}`} value={`${year}-${month}`} 
        selected={(selectedYear === year && selectedMonth === month) ? 'selected' : ''}
        >
          {months[month - 1]} {year}
        </option>
        );
      }
    }
    return options;
  }
  
  function isCurrentDate(date) {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }
  // To make the current date satys in center
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - Math.floor(daysToShow / 2));
  // To make the current date satys in center
  


  return (
    <div>
      <h4 className='subhead'>Shipments</h4>
      <h2>Booking Slot</h2>

      <div className="calendar-container">
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '200px' }}>
            <select className='form-select' value={`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`} onChange={handleDateChange}>
              {getMonthYearOptions()}
            </select>
          </div>
          <div>

            <button className='btnleft' onClick={handlePrevious}> <FontAwesomeIcon icon={faChevronLeft} /> </button>
            <button className='btnright' onClick={handleNext}> <FontAwesomeIcon icon={faChevronRight} /> </button>

          </div>

        </div>
        <div className="scrollable-calendar mb-4 mt-4">
          {[...Array(daysToShow)].map((_, index) => {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + index);
            const selDate = localFormData.bookingslot_date;

            const formatDate = (date) => {
              const year = date.getFullYear();
              const month = (date.getMonth() + 1).toString().padStart(2, '0');
              const day = date.getDate().toString().padStart(2, '0');
              return `${year}-${month}-${day}`;
            };
            const isActive = localFormData.bookingslot_date 
  ? isSameDay(date, new Date(localFormData.bookingslot_date)) 
  : (selectedDate ? isSameDay(date, selectedDate) : isCurrentDate(date));

            return (
              <div key={index} className={`day ${isCurrentDate(date) ? 'current-date' : ''}  ${isActive ? 'active' : ''} `} onClick={() => handleDayClick(date)}>
                <div className="day-name">{getDayName(date)}</div>
                <div className="date">{date.getDate()}</div>
                <div className='slot'> <span></span> {countAvailableSlots(date)} slots </div>

              </div>
            );
          })}
        </div>
        <div className='avalable'>
          <h2>Available Time Slot</h2>
          {showBookingSlots.length > 0 ? (
            <div className="timeslot">
              {showBookingSlots.map((slot, index) => (
                <div key={index} className={`${slot.status === 1 ? 'available time' : 'unavailable time'} ${slot.selected ? 'selected' : ''} ${localFormData.bookingslot_time === slot.time ? 'selected' : ''}`} onClick={() => handleSlotClick(slot.time)}>{slot.time}</div>
              ))}
            </div>
          ) : (
            <div>No Slots Available</div>
          )}
          {/* <div className='timeslot'>
            <div className='time'>7:00 AM</div>
            <div className='time'>7:30 AM</div>
            <div className='time selected'>7:50 AM</div>
            <div className='time'>8:00 AM</div>
            <div className='time'>8:30 AM</div>
            <div className='time'>9:30 AM</div>
          </div> */}
        </div>

      </div>

    </div>
  )
}

const ShipmentFormsFive = ({ formData, onFormDataChange5, completedData }) => {
  const [localFormData, setLocalFormData] = useState({ drivername: '', tr_lc: '', tr_lc_pl: '', seal: '', seal_no: '' });

  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (completedData) {
      // Extract editedData
      const completedDataKeys = Object.keys(completedData);
      const fifthKey = completedDataKeys.length > 4 ? completedDataKeys[4] : null;
      const editedData = fifthKey && completedData[fifthKey].driver_information ? completedData[fifthKey].driver_information : null;
      console.log("🚀 ~ useEffect ~ completedData:", fifthKey)
      
      // Update localFormData with editedData
      if (editedData) {
        setLocalFormData({
          drivername: editedData.drivername || '', 
          tr_lc: editedData.tr_lc || '', 
          tr_lc_pl: editedData.tr_lc_pl || '', 
          seal: editedData.seal || '', 
          seal_no: editedData.seal_no || '',
        });
      }
    }
  }, [completedData]);

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    // setRadioValue(value);
    const updatedData = { ...localFormData, [name]: value };
    setShowDiv(value === 'yes');
    setLocalFormData(updatedData);
    onFormDataChange5(updatedData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...localFormData, [name]: value };
    
    setLocalFormData(updatedData);
    onFormDataChange5(updatedData);
  };



  const [showDiv, setShowDiv] = useState(false);

  const handleYesClick = () => {
    setShowDiv(true);
  };

  const handleNoClick = () => {
    setShowDiv(false);
  };

  return (
    <div>
      <form>
        <h4 className='subhead'>Shipments</h4>
        <h2>Driver/Truck Information</h2>
        <div>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Driver Name</label>
              <input className="form-control" type="text" placeholder="Driver Name" name="drivername" value={localFormData.drivername} onChange={handleInputChange} />
            </div>
            <div className='col'>
              <label className='form-label'>Trailer License Plate Nr.</label>
              <input className="form-control" type="text" maxLength='8' onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }} placeholder='License Plate Nr' name='tr_lc' value={localFormData.tr_lc} onChange={handleInputChange} />
            </div>
          </div>
          <div className='col-6'>
            <div>
              <label className='form-label'>Truck License Plate Nr. <span>*</span></label>
              <input className="form-control" type="text" maxLength='8' onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }} placeholder='Truck License Plate Nr' name='tr_lc_pl' value={localFormData.tr_lc_pl} onChange={handleInputChange} />
            </div>
            <div>
              <label className='form-label'>Seal Number Present?</label>
              <div className='d-flex'>

                <div className="form-check">
                  <input className="form-check-input" type="radio" name='seal' value="yes" checked={localFormData.seal === 'yes'} onChange={handleRadioChange} />
                  <label className="form-check-label" >Yes</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name='seal' value="no" checked={localFormData.seal === 'no'} onChange={handleRadioChange} />
                  <label className="form-check-label" >No</label>
                </div>
              </div>
              {(localFormData.seal === 'yes' || showDiv) && ( <div>
                <label className='form-label'>Seal Number <span>*</span></label>
                <input className="form-control" type="text" maxLength='8' onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '').slice(0, 8); }} placeholder='SL123654789' name='seal_no' value={localFormData.seal_no} onChange={handleInputChange} />
              </div>)}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
// Upload Pictures & Documents | start
const ShipmentFormsSix = ({ formData, onFormDataChange6 }) => {

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [base64Files, setBase64Files] = useState([]);


  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    getBase64(droppedFiles[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    base64Files.splice(index, 1);

  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithBase64 = [];
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        filesWithBase64.push({ file, base64: reader.result });
        if (filesWithBase64.length === selectedFiles.length) {
          setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
          onFormDataChange6(filesWithBase64);
        }
      };
      reader.readAsDataURL(file);
    });
  }

  // const handleFileChange = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  //   getBase64(selectedFiles[0]);
  //  // onFormDataChange6(base64Files);
  // };
  console.log("base64Files ==> 1195 ", base64Files);
  localStorage.setItem("img", JSON.stringify(base64Files));
  return (
    <div>
      <form>
        <h4 className='subhead'>Shipments</h4>
        <h2>Upload Images/Documents</h2>
        <div>
          <div
            className='dropfile'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <h3>Drag and drop files here</h3>
            <p>Or</p>
            <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
            <span>Maximum file size 4 MB</span>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              multiple
            />


          </div>


          <div className='uploadbox'>
            {files.map((file, index) => (
              <div key={index} className='uploadfil'>
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                  />
                ) : (
                  <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                )}
                <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
              </div>
            ))}

          </div>
        </div>
      </form>
    </div>
  )
}
// Upload Pictures & Documents | end

export { ShipmentFormsFive, ShipmentFormsFour, ShipmentFormsOne, ShipmentFormsSix, ShipmentFormsThree, ShipmentFormsTwo };
