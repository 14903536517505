import React, { useState } from "react"; 
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faInfoCircle, faCog } from '@fortawesome/free-solid-svg-icons';
import schart from '../../assets/images/schart.png';
import shome from '../../assets/images/shome.png';
// import { useLocation } from "react-router-dom";

const LeftMenu = ()=>{

    const location = useLocation();
    const navItems = [
    { name: 'Home', path: '/BookingtruckSchedule', image: shome  },
    { name: 'Charts', path: '/kpicharts', image: schart  },
    // Add more items with respective paths as needed
  ];

    return(
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {navItems.map((item) => (
                    <li key={item.name}>
                        {/* {console.log("img", item.image)} */}
                    <Link to={item.path} className={`nav-link ${location.pathname === item.path ? 'active' : ''} rounded`}>
                        <img src={item.image} /><span>{item.name}</span><span className="act-sign"></span>
                    </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}
export default LeftMenu;