import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withoutRFSToken, withRFSToken } from '../../Services/axios.js';
import { ADDSTATIONADDRESS } from '../../Utils/conts.js';
import {  toast } from 'react-toastify';

const AddStationAddress = ({ onClose, stationId, reloadAddress }) => {

  const [show, setShow] = useState(true);
  const modalShow = () => setShow(true)
  const modalClose = () => {setShow(false); onClose();};

  const storedData = stationId.id;
    console.log("🚀 ~ StationDetails ~ storedData:", storedData)

//   const getLocalData = () => {
//     const storedData = localStorage.getItem("StationAddress");
//     const Saddress = storedData ? JSON.parse(storedData) : [];
//     const uaddress =  Saddress.map(addr => ({ ...addr, selected: false }));
//     return uaddress;
// }
// useEffect(() => {
//     setAddress(getLocalData());
// }, []);

  const [inputAddress, setInputAddress] = useState({
    handler_name: '', address_1: '', address_2: '', postal_code: '', phone_number: '', email: ''
  });
  const [address, setAddress] = useState(storedData);

  const validateFormData = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate email
    const validateEmail = (email) => {
    return emailRegex.test(email);
    };
        if (!inputAddress.handler_name) {
            console.log('Text input is required');
            toast.error('Handler Name must not be blank');
            isValid = false;
          } else
          if (!inputAddress.address_1) {
            console.log('Text input is required');
            toast.error('Address must not be blank');
            isValid = false;
          } else
          if (!inputAddress.postal_code) {
            console.log('Text input is required');
            toast.error('Postal Code must not be blank');
            isValid = false;
          } else
          if (!inputAddress.phone_number) {
            console.log('Text input is required');
            toast.error('Phone Number must not be blank');
            isValid = false;
          } else
          if (!/^\d{10}$/.test(inputAddress.phone_number)) {
            console.log('10');
            toast.error('Phone Number must be 10 Digits');
            isValid = false;
          } else
          if (!inputAddress.email) {
            console.log('Text input is required');
            toast.error('Email must not be blank');
            isValid = false;
          } else if (!validateEmail(inputAddress.email)) {
            console.log('Invalid email address');
            toast.error('Invalid email address');
            isValid = false;
          }
          else{
            return isValid;
          }
  };

  const addAddress = async (e) => {
    if (validateFormData()) {
    e.preventDefault();
    // const newAddressID = Math.floor(Math.random() * 1000000);
    const stationid = stationId.id;
    console.log("🚀 ~ addAddress ~ stationid:", stationid)
    const NewInputAddress = { ...inputAddress, station_id: stationid };
    // console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
    // const updatedAddress = [...address, NewInputAddress];
    // if (updatedAddress) {
    //     dispatch(addAddressData(NewInputAddress)); // Pass sendStationData to addData thunk
    //   }
    if (NewInputAddress) {
        try {
            const res = await withRFSToken.post(ADDSTATIONADDRESS, NewInputAddress)
                .then(function (res) {
                    // const innerData = res;
                    // console.log("🚀 ~ .then ~ innerData:", innerData)
                    if (res.data.response_code == 200) {
                        reloadAddress();
                        console.log('reload');
                        setAddress(res.data.response_data);
                    }
                });

        } catch (error) {
            console.error('Error sending request:', error);
        }
    }
    setInputAddress({
        handler_name: '', address_1: '', address_2: '', postal_code: '', phone_number: '', email: ''
    });
    console.log(address);
    modalClose();
}
}
  const handleChange = (e) => {
    setInputAddress({ ...inputAddress, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    localStorage.setItem("StationAddress", JSON.stringify(address));
  }, [address]);

  return (
    <>
      <Modal show={show} onHide={modalClose} centered className='addfrom'>
        <Modal.Header closeButton>
          <Modal.Title>Add Address</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form.Group className='mb-2'>
            <Form.Label>Handler Name</Form.Label>
            <Form.Control type="text" onChange={handleChange} name='handler_name' placeholder="Handler Name" />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control type="text" onChange={handleChange} name='address_1' placeholder="Address" />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control type="text" onChange={handleChange} name='address_2' placeholder="Address" />
          </Form.Group>
          <Form.Group as={Row} className='mb-2'>
            <Col sm="6">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control type="text" onChange={handleChange} name='postal_code' placeholder="Zip" />
            </Col>
            <Col sm="6">
              <Form.Label>Telephone No</Form.Label>
              <Form.Control type="text" onChange={handleChange} name='phone_number' placeholder="Number" />
            </Col>
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label>Email ID</Form.Label>
            <Form.Control type="text" onChange={handleChange} name='email' placeholder="E-mail" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addAddress}  >
            Add Address
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddStationAddress