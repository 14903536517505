import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import 'jqwidgets-framework/jqwidgets/jqxcore';
import 'jqwidgets-framework/jqwidgets/jqxdata';
import 'jqwidgets-framework/jqwidgets/jqxbuttons';
import 'jqwidgets-framework/jqwidgets/jqxscrollbar';
import 'jqwidgets-framework/jqwidgets/jqxmenu';
import 'jqwidgets-framework/jqwidgets/jqxgrid';
import 'jqwidgets-framework/jqwidgets/jqxgrid.pager';
import 'jqwidgets-framework/jqwidgets/jqxgrid.sort';
import 'jqwidgets-framework/jqwidgets/jqxgrid.edit';
import 'jqwidgets-framework/jqwidgets/jqxgrid.filter';
import 'jqwidgets-framework/jqwidgets/jqxgrid.selection';
import 'jqwidgets-framework/jqwidgets/jqxgrid.columnsresize';
import 'jqwidgets-framework/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-framework/jqwidgets/styles/jqx.dark.css';
import $ from 'jqwidgets-framework/jqwidgets/jqx-all';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faXmark, faCircleCheck, faBolt, faPaperclip, faCircle, faPlus, faEllipsis, faChevronLeft, faChevronRight, faLink, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import JqxTreeGrid from 'jqwidgets-framework/jqwidgets-react-tsx/jqxtreegrid';
import 'jqwidgets-scripts/jqwidgets/jqxtreegrid';
import Trash from "../../../assets/images/delete.svg";
import Export from "../../../assets/images/export.svg";
import Cargotruck from "../../../assets/images/cargo-truck.png";
import Cuser from "../../../assets/images/cuser.png";

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Editor from 'react-simple-wysiwyg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ActivityComments from '../../common/ActivityComments.js';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { BOOKINGTRUCKDETAILS, BOOKINGTRUCKMOVE } from '../../../Utils/conts.js';
import ImageUpload from '../../common/ImageUpload.js';


// import jsPDF from 'jspdf';


export const ExportActive = forwardRef(({ updateGridData, onEdit }, ref) => {
  // console.log("🚀 ~ ExportActive ~ updateGridData:", updateGridData)
  // const myGridRef = useRef(null);
  // let gridElement = null;


  const [showSecondDiv, setShowSecondDiv] = useState(false);
  const toggleSecondDiv = () => {
    setShowSecondDiv(!showSecondDiv);
  };

  // +++++++++++++++++++ div +++++++++++++++++++++++++

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload 

  const [html, setHtml] = useState('');

  function onChange(e) {
    setHtml(e.target.value);
  }

  // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,

  };

  // +++++++++++++++++++++++++++++++++++ Silk Slider


  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFile('');
  };

  // ++++++++++++++++++++++++++++++++++++ Zoom Image

  const [gridSelect, setGridSelect] = useState([]);

  const gridRef = useRef(null);
  // const treeGridRef = useRef(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  // new data for dynamic
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (updateGridData) {
      setGridData(updateGridData.active);
    }
    else {
      console.log('No Data');
    }
  }, [updateGridData]);

  // console.log("NNN", gridData);
  useEffect(() => {
    // console.log("🚀 ~ ExportActive ~ PPP:", gridData)
    if (gridRef.current) {

      const source = {
        datatype: 'json',
        datafields: [
          // { name: 'selected', type: 'bool' },
          { name: 'bookingtruck_no', type: 'string' },
          { name: 'status', type: 'string' },
          { name: 'RStatus', type: 'string' },
          { name: 'date', type: 'string' },
          { name: 'time', type: 'string' },
          { name: 'board_point', type: 'string' },
          { name: 'off_point', type: 'string' },
        ],
        localdata: gridData,
      };
      const dataAdapter = new window.$.jqx.dataAdapter(source);

      const columns = [
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },filtertype: 'bool',
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },
        { text: 'Bookingtruck No.', filtertype: 'textbox', datafield: 'bookingtruck_no', width: '17%', cellclassname: 'firstcl', },
        // { text: 'Status', filtertype: 'textbox', datafield: 'Status', width: '17%', },
        {
          text: 'Status',
          filtertype: 'textbox',
          datafield: 'status',
          width: '17%',
          cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
            // Check the value of the 'Status' field
            console.log('12', rowdata);

            if (rowdata.status === 'I') {
              // Return HTML with class 'inactive' for 'i' status
              return '<div class="jqx-grid-cell-left-align bookingstatus inactive">To be planned</div>';
            } else if (rowdata.status === 'A') {
              // Return HTML with class 'active' for 'a' status
              return '<div class="jqx-grid-cell-left-align bookingstatus active">Planned</div>';
            } else {
              // Return default HTML
              return defaulthtml;
            }
          }
        },
        { text: 'Date', filtertype: 'textbox', datafield: 'date', width: '17%' },
        { text: 'Time', filtertype: 'textbox', datafield: 'time', width: '17%' },
        { text: 'Boardpoint', filtertype: 'textbox', datafield: 'board_point', width: '14%' },
        { text: 'Offpoint', filtertype: 'textbox', datafield: 'off_point', width: '14%' },
      ];

      const gridOptions = {
        width: '100%',
        source: dataAdapter,
        columns: columns,
        autoheight: true,
        sortable: true,
        altrows: false,
        enabletooltips: true,
        editable: false,
        selectionmode: 'checkbox',
        pageable: true,
        pageSize: 9,
        pagerButtonsCount: 5,
        pagerMode: 'advanced',
        filterable: true,
        filterrowheight: 45,
        showfilterrow: true,
        rowsheight: 50,
        columnsheight: 45,
      };


      window.$(gridRef.current).jqxGrid(gridOptions);
      window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');
      window.$(gridRef.current).off('rowclick');
      window.$(gridRef.current).on('rowclick', async (event) => {
        // console.log('event', event.args.row.bounddata);
        const args = event.args.row.bounddata;

        console.log('args', args);
        // const rowData = dataAdapter.records[args.index];
        // console.log('rowdata', rowData);
        try {
          const res = await withRFSToken.post(BOOKINGTRUCKDETAILS, { bookingtruck_no: args.bookingtruck_no, booking_date: args.date })
            .then(function (res) {
              const innerData = res;
              console.log("🚀 ~ .then ~ innerData:", innerData)
              if (res.data.response_code == 200) {
                setSelectedRowData(res.data.response_data);
              }
            });

        } catch (error) {
          console.error('Error sending request:', error);
        }

        window.$(gridRef.current).jqxGrid('autoresizecolumns');
      });

    }

  }, [gridData, selectedRowData]);

  // row selection by checkbox
  window.$(gridRef.current).on('rowselect', (event) => {
    console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

    if (event.args.row) {

      const selectedRow = event.args.row.bookingtruck_no;

      if (!gridSelect.includes(selectedRow)) {
        setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
      }
      // setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
    } else {
      // Handle "Select All" case
      const allIds = gridData.map(row => row.bookingtruck_no);
      console.log("All IDs:", allIds);
      setGridSelect(allIds);
      console.log("Select All triggered");
    }
  });
  console.log('ggg', gridSelect);
  window.$(gridRef.current).on('rowunselect', (event) => {
    if (event.args.row) {
      const unselectedRowId = event.args.row.id;
      setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
    } else {
      setGridSelect([]);
    }
  });
  // End row selection 

  // const deleteSelectedRows = async(selectedIds) => {
  //   // Perform deletion logic here using the selected IDs
  //   console.log("Archive selected IDs:", selectedIds);
  //   // Now you can send the selected IDs to your API or perform any other action
  //   if (selectedIds.length > 0) {
  //     dispatch(addStationRemoveData(selectedIds)); // Pass sendStationData to addData thunk
  //   }
  //   window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
  //   window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
  //   dispatch(fetchStationGridData());
  // };
  // useEffect(() => {
  //   dispatch(fetchStationGridData());
  // }, [dispatch]);

  useImperativeHandle(ref, () => ({
    handleCloseClick,
  }));

  const handleCloseClick = () => {
    setSelectedRowData(null);
  };
  const handleDeleteButtonClick = () => {
    deleteSelectedRows(gridSelect);
    // if (gridSelect){
    //   handleShowConfirmation(deleteSelectedRows);
    // }
  };
  const deleteSelectedRows = async (selectedIds) => {
    // Perform deletion logic here using the selected IDs
    console.log("Archive selected IDs:", selectedIds);
    const uniqueIds = Array.from(new Set(selectedIds));
    // Now you can send the selected IDs to your API or perform any other action
    if (selectedIds.length > 0) {
      try {
        const response = await withRFSToken.post(BOOKINGTRUCKMOVE, { bookingtruck_nos: uniqueIds, action: 'D' });
        console.log("🚀 ~ addData ~ response:", response)
        if (!response.ok) {
          throw new Error('Failed to add data');
        }
        // reloadGrid();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
    window.$(gridRef.current).jqxGrid('clearselection');
    // dispatch(fetchStationGridData());
    // reloadGrid();
  };
  // const reloadGrid = async () => {
  //   try {
  //       const res = await axios.post(process.env.REACT_APP_BASE_URL + DASHBOARD, {}, {
  //           headers: {
  //               'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //               'Content-Type': 'application/json'
  //           }
  //       });
  //       const innerData = res.data.data;
  //       setPlanData(innerData);
  //   } catch (error) {
  //       console.error('Error reloading data:', error);
  //   }
  // };

  const exportToExcel = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
    }
  };
  const exportToPDF = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'pdf', 'GridData');
    }
  };

  const handleEditClick = () => {
    // Extract the 10 fields you need from selectedRowData
    const dataToSend = {
      booking_type: 'E',
      bookingtruck_no: selectedRowData.bookingtruck_no,
      boardpoint: selectedRowData.boardpoint,
      boardpoint_address: selectedRowData.boardpoint_address,
      offpoint: selectedRowData.offpoint,
      offpoint_address: selectedRowData.offpoint_address,
      trip_type: selectedRowData.trip_type,
      date: selectedRowData.data,
      time: selectedRowData.trip_type,
      selected_days: selectedRowData.selected_days,
      freq_date: selectedRowData.freq_date,
      freq_time: selectedRowData.freq_time,
      start_date: selectedRowData.start_date,
      end_date: selectedRowData.end_date
    };
    console.log("🚀 ~ handleEditClick ~ dataToSend:", dataToSend)

    // Call the parent's edit function
    const datas = { ...selectedRowData, booking_type: 'E' };
    onEdit(datas);
  };

  return (
    <>
      <div className='exp-btn'>
        <button onClick={handleDeleteButtonClick}><img src={Trash} /> Move to Trash</button>
        <button onClick={exportToExcel}><img src={Export} /> Download</button>
      </div>
      <div className="maindiv">

        <div className='trashdonload'>

          <div ref={gridRef} className='bookinggrid'></div>
        </div>

        {/* Display the selected row data */}
        {selectedRowData && (
          <div className='SecondDiv'>
            {console.log('555', selectedRowData)}
            <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
            <div className='girddtals_header'>
              <h3>
                {selectedRowData.bookingtruck_no} <span>{selectedRowData.status === 'A' ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />}</span> <span>{selectedRowData.status === 'A' ? 'Planned' : 'To be planned'}</span>
              </h3>
              <div className='d-flex actbtn'>

                <Button variant="light" onClick={handleBrowseFiles} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <FontAwesomeIcon icon={faBolt} /> Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleEditClick}>Edit Bookingtruck</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Manage Shipments</Dropdown.Item>
                    <Dropdown.Item href="/TruckOrder">Order Truck</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='d-flex justify-content-between rabord'>
              <div className='firstbrd'>
                <p>Boardpoint</p>
                <span>{selectedRowData.boardpoint}</span>
              </div>
              <div className='middlebrd'>
                <p>{selectedRowData.date}</p>
                <p><img src={Cargotruck} /></p>
                <p>{selectedRowData.time}</p>
              </div>
              <div className='lastbrd'>
                <p>Offpoint</p>
                <span>{selectedRowData.offpoint}</span>
              </div>
            </div>
            <div>
              <h3>Operational Trucks</h3>

              {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                  {console.log("🚀 ~ ExportActive ~ truck:", truck)}
                  <div>
                    <span>Truck No</span>
                    <h4>{truck.truck_no}</h4>
                  </div>
                  <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                    <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                  </div>
                  <div>
                    <Link>Details</Link>
                  </div>
                </div>
              ))}

            </div>
              <ImageUpload attachable_type={"booking_schedule"} attachable_id={selectedRowData.bookingtruck_id} attachments={selectedRowData.attachments} />
            <div>
              <ActivityComments commentable_type={"booking_schedule"} commentable_id={selectedRowData.bookingtruck_id} allactivities={selectedRowData.activities} />             
            </div>
          </div>
        )}
      </div>


    </>
  );
});
export default ExportActive;
// export {JGrid as default, exportToPDF}