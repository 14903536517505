import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faLink } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../../../Componenets/Header/Header";
import {LeftccountSettings} from '../LeftSettings';
import edit from "../../../assets/images/edit.png";
import trash from "../../../assets/images/trash.png";
import Userview from "../../../assets/images/useer_view.png";
import { toast } from 'react-toastify';

import  {withoutToken, withToken}   from '../../../Services/axios.js';
import { USERSTATIONS, USERLISTS, SUBMITLIST, USERDETAILS, USERDELETE } from '../../../Utils/conts.js';

const ManageUsers = () => {
    const [modalShow, setModalShow] = useState(false);
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteId] =useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteFunction, setDeleteFunction] = useState(null);
    const handleAddButtonClick = () => {
  
      setModalShow(true);
    };
    const handleEditButtonClick = (userId) => {
      setEditId(userId);
      setModalShow(true, {id:userId});
    };
    const handleModalClose = () => {
      setModalShow(false);
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        station_code: '',
        portal_access: {
          C: '',
          R: '',
          U: '',
          A: ''
        }
      });
      setEditId('');
  
    };
  
    const [gridData, setGridData] = useState([]);
    //   { first_name: 'Campus', last_name: 'Admin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Admin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Admin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Admin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Admin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    //   { first_name: 'Campus', last_name: 'Idmin', email: 'admin@gmail.com', role: 'Employee', status: 'A', city: 'AMS', country: 'NL', posatl_code: '1188LE' },
    // ]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await withToken.post(USERLISTS, {})
          .then(function(res) {
            const innerData = res.data.data;
            console.log("🚀 ~ .then ~ innerData:", innerData)
            // const transformedData = innerData ? innerData.map(item => ({
            //   value: item.code,
            //   label: item.code +'-'+ item.name
            // })) : [];    
            setGridData(innerData);
          });
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
        // Call the fetchData function to fetch data from the API
        fetchData();
        
      }, []);
  
      const fetchDataDetails = async (id) => {
        try {
          const res = await withToken.post(USERDETAILS, {user_id:id})
          .then(function(res) {
            const innerData = res.data.data;
            console.log("🚀 ~ .then ~ innerData:xxx", innerData)
            const userId = innerData.user_id;
  
            const portalAccess = innerData.portal_access;
            const portalAccessObject = {};
            if(portalAccess){
              portalAccess.forEach((key) => {
                portalAccessObject[key] = true;
            });
            setFormData({...innerData,
              station_code: innerData.port_code,
              portal_access: portalAccessObject
            });
            handleEditButtonClick(userId);
            console.log("🚀 ~ .then ~ portalAccessObject:", portalAccessObject)
            }
            
            return portalAccessObject;
            // const transformedData = innerData ? innerData.map(item => ({
            //   value: item.code,
            //   label: item.code +'-'+ item.name
            // })) : [];    
            // setStationList(innerData);
          });
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    const gridRef = useRef(null);
    useEffect(() => {
      // console.log("🚀 ~ ExportActive ~ PPP:", gridData)
      if (gridRef.current) {
  
        const source = {
          datatype: 'json',
          datafields: [
            { name: 'user_id', type: 'string' },
            { name: 'first_name', type: 'string' },
            { name: 'last_name', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'role', type: 'string' },
            { name: 'status', type: 'string' },
            { name: 'city', type: 'string' },
            { name: 'country', type: 'string' },
            { name: 'postal_code', type: 'string' },
          ],
          localdata: gridData,
        };
        const dataAdapter = new window.$.jqx.dataAdapter(source);
  
        const columns = [
          // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },filtertype: 'bool',
          // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },
          {
            text: 'Activities',
            cellsrenderer: function () {
              return '<div class="grid_actions"><img src="' + Userview + '" class="row_settings" /><img src="' + edit + '" class="row_edit" /><img src="' + trash + '" class="row_delete" /></div>';
            },
            width: '10%'
          },
          { text: 'First Name', filtertype: 'textbox', datafield: 'first_name', width: '10%', cellclassname: 'firstcl', },
          { text: 'Last Name', filtertype: 'textbox', datafield: 'last_name', width: '10%', cellclassname: 'firstcl', },
          { text: 'Email Id', filtertype: 'textbox', datafield: 'email', width: '15%', cellclassname: 'firstcl', },
          { text: 'Role', filtertype: 'textbox', datafield: 'role', width: '10%', cellclassname: 'firstcl', },
          // { text: 'Status', filtertype: 'textbox', datafield: 'Status', width: '17%', },
          {
            text: 'Status',
            filtertype: 'textbox',
            datafield: 'status',
            width: '9%',
            cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
              // Check the value of the 'Status' field
              // console.log('12', rowdata);
  
              if (rowdata.status === 'I') {
                // Return HTML with class 'inactive' for 'i' status
                return '<div class="jqx-grid-cell-left-align bookingstatus inactive">To be planned</div>';
              } else if (rowdata.status === 'A') {
                // Return HTML with class 'active' for 'a' status
                return '<div class="jqx-grid-cell-left-align bookingstatus active">Active</div>';
              } else {
                // Return default HTML
                return defaulthtml;
              }
            }
          },
          { text: 'City', filtertype: 'textbox', datafield: 'city', width: '10%' },
          { text: 'Country', filtertype: 'textbox', datafield: 'country', width: '10  %' },
          { text: 'Postal Code', filtertype: 'textbox', datafield: 'postal_code', width: '14%' },
        ];
  
        const gridOptions = {
          width: '100%',
          source: dataAdapter,
          columns: columns,
          autoheight: true,
          sortable: true,
          altrows: false,
          enabletooltips: true,
          editable: false,
          // selectionmode: 'checkbox',
          pageable: true,
          pageSize: 9,
          pagerButtonsCount: 5,
          pagerMode: 'advanced',
          filterable: true,
          filterrowheight: 45,
          showfilterrow: true,
          rowsheight: 50,
          columnsheight: 45,
        };
  
  
        window.$(gridRef.current).jqxGrid(gridOptions);
        window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');
  
        window.$(gridRef.current).on('click', '.row_edit', function (row) {
          
          const rowDiv = window.$(this).closest('[role="row"]');
         
          
          const rowIndex = window.$(rowDiv).attr('row-id');
          const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
          const id = rowData.user_id
          console.log("🚀 ~ rowDiv:", rowData)
          fetchDataDetails(id);
          const rowBookingId = rowData.booking_id;
  
          
        });
        window.$(gridRef.current).on('click', '.row_delete', function (row) {
          
          const rowDiv = window.$(this).closest('[role="row"]');
         
          
          const rowIndex = window.$(rowDiv).attr('row-id');
          const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
          const id = rowData.user_id
          setDeleteId(id);
          console.log("🚀 ~ rowDiv:", rowData)
  
          handleShowConfirmation(deleteRow);
          const rowBookingId = rowData.booking_id;
  
          
        });
  
      }
  
    }, [gridData]);
  
    const [formData, setFormData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      station_code: '',
      portal_access: {
        C: '',
        R: '',
        U: '',
        A: ''
      }
    });
  
    const [stationList, setStationList] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await withToken.post(USERSTATIONS, {})
          .then(function(res) {
            const innerData = res.data.data;
            // console.log("🚀 ~ .then ~ innerData:", innerData)
            // const transformedData = innerData ? innerData.map(item => ({
            //   value: item.code,
            //   label: item.code +'-'+ item.name
            // })) : [];    
            setStationList(innerData);
          });
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
        // Call the fetchData function to fetch data from the API
        fetchData();
        
      }, []);
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          portal_access: {
            ...prevData.portal_access,
            [name]: checked
          }
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      }
      // setFormData((prevData) => ({
      //   ...prevData,
      //   // [name]: type == 'checkbox' ? { ...prevData, [value]: checked } : value
      // }));
      console.log('202',formData);
    };
    const validateFormData = () => {
      let isValid = true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      // Function to validate email
      const validateEmail = (email) => {
        return emailRegex.test(email);
      };
      if (!formData.first_name) {
        console.log('Text input is required');
        toast.error('First Name must not be blank');
        isValid = false;
      } else if (!formData.last_name) {
        console.log('Text input is required');
        toast.error('Last Name must not be blank');
        isValid = false;
      } else if (!formData.email) {
        console.log('Text input is required');
        toast.error('Email must not be blank');
        isValid = false;
      } else if (!validateEmail(formData.email)) {
        console.log('Invalid email address');
        toast.error('Invalid email address');
        isValid = false;
      }
      else if (!formData.phone_number) {
        console.log('Text input is required');
        toast.error('Phone Number must not be blank');
        isValid = false;
      } else
        if (!/^\d{10}$/.test(formData.phone_number)) {
          console.log('10');
          toast.error('Phone Number must be 10 Digits');
          isValid = false;
        } else {
          return isValid;
        }
    };
    const handleSubmit = async() => {
      if (validateFormData()) {
        // You can send formData to your API endpoint here
      //   const selectedAccess = Object.entries(formData.portalAccess)
      // .filter(([key, value]) => value)
      // .map(([key]) => key);
      const stationcode = stationList.find(lst => lst.port_code== formData.station_code);
      // console.log("🚀 ~ handleSubmit ~ stationcode:", stationcode)
      const portalAccessArray = Object.keys(formData.portal_access)
      .filter(key => formData.portal_access[key]);
  
        // Create the final data object for submission
        const finalData = {
          ...formData,
          port_address: stationcode.port_address,
          portal_access: portalAccessArray
        };
        console.log('fdaa', finalData);
        try {
          const res = await withToken.post(SUBMITLIST, finalData);
          console.log("response==>", res);
          if (res.data.status === 400){
            console.log('www', res.data.message)
            toast.error(res.data.message);
          } else{
            setFormData({
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              station_code: '',
              portal_access: {
                C: '',
                R: '',
                U: '',
                A: ''
              }
            })
            reloadGrid();
            handleModalClose();
            setEditId('');
          }
         
        } catch (error) {
          console.error('Error sending request:', error);
        }
        
      }
    };
    const handleEditSubmit = async() => {
      if (validateFormData()) {
        // You can send formData to your API endpoint here
      //   const selectedAccess = Object.entries(formData.portalAccess)
      // .filter(([key, value]) => value)
      // .map(([key]) => key);
      const stationcode = stationList.find(lst => lst.port_code== formData.station_code);
      // console.log("🚀 ~ handleSubmit ~ stationcode:", stationcode)
      const portalAccessArray = Object.keys(formData.portal_access)
      .filter(key => formData.portal_access[key]);
  
        // Create the final data object for submission
        const finalData = {
          ...formData,
          user_id:editId,
          port_address: stationcode.port_address,
          portal_access: portalAccessArray
        };
        console.log('fdaa', finalData);
        try {
          const res = await withToken.post(SUBMITLIST, finalData);
          console.log("response==>", res.data);
          if (res.data.status === 400){
            console.log('www', res.data.message)
            toast.error(res.data.message);
          } else{
            setFormData({
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              station_code: '',
              portal_access: {
                C: '',
                R: '',
                U: '',
                A: ''
              }
            })
            reloadGrid();
            handleModalClose();
            setEditId('');
          }
  
        } catch (error) {
          console.error('Error sending request:', error);
        }
        
      }
      
    };
    const handleShowConfirmation = (deleteFunction) => {
      setShowConfirmation(true);
      setDeleteFunction(() => deleteFunction);
  };
    const handleConfirmDelete = () => {
      // deleteRow(gridSelect);
      deleteFunction(deleteId)
      setShowConfirmation(false);
    };
  
    const handleCancelDelete = () => {
      setDeleteId('')
      setShowConfirmation(false);
    };
    const deleteRow = async(selectedId) => {
          
      try {
        const res = await withToken.post(USERDELETE, {user_ids: [selectedId]});
        console.log("responsehg=000=>", res);
        
        // setupGrid();
        reloadGrid();
        // onReloadChildComponents();         
      } catch (error) {
        console.error('Error sending request:', error);
      }
    };
  
    const reloadGrid = async () => {
      try {
        const res = await withToken.post(USERLISTS, {})
          .then(function(res) {
            const innerData = res.data.data;
            console.log("🚀 ~ .then ~ innerData:", innerData)
            setGridData(innerData);
          });
    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    return (
      <>
        <div className="">
          <Header />
          <div className="main-panel">
  
            <div className="row">
  
              <LeftccountSettings />
              <div className='col-9 col-xxl-9 stting_right'>
                <div className='settingbg_right'>
                  <h4>Manage Users</h4>
                  <p>Set your notification preferences for when you are in or away from the dashboard.</p>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <Button variant="primary createplan" onClick={handleAddButtonClick}>
                        <FontAwesomeIcon icon={faPlus} /> Add User
                      </Button>
                    </div>
                    <div className='rastatus'>
                      <span>Status : </span>
                      <Form.Select>
                        <option>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
  
                  </div>
                  <div ref={gridRef} className='bookinggrid userstting'></div>
                </div>
              </div>
            </div>
  
  
            <Modal show={modalShow} centered onHide={handleModalClose} className='addfrom' >
              <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
  
                <Form.Group as={Row} className='mb-2'>
                  <Col sm="6">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" name='first_name' placeholder="First Name" value={formData.first_name} onChange={handleChange} />
                  </Col>
                  <Col sm="6">
  
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" name='last_name' value={formData.last_name} onChange={handleChange} />
  
                  </Col>
                </Form.Group>
  
                <Form.Group as={Row} className='mb-2'>
                  <Col sm="6">
                    <Form.Label>Email id</Form.Label>
                    <Form.Control type="text" name='email' placeholder="Email id" value={formData.email} onChange={handleChange} />
                  </Col>
                  <Col sm="6">
                    <Form.Label>Telephone No</Form.Label>
                    <Form.Control type="number" maxLength='12' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} name='phone_number' value={formData.phone_number} onChange={handleChange} placeholder="Number" />
  
                  </Col>
                </Form.Group>
  
                <Form.Group controlId="dropdown" className='mb-3'>
                  <Form.Label>Select Station</Form.Label>
                  <Form.Select aria-label="Default select example" name="station_code" value={formData.station_code} onChange={handleChange}>
                    <option>Select Station</option>
                    {console.log('ddd',stationList)}
                    {stationList && stationList.map((list, index) => (
                      <option key={index} value={list.port_code}>{list.port_name}</option>
                    ))}
                    {/* <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option> */}
                  </Form.Select>
                </Form.Group>
  
                <Form.Group controlId="portal_access" className='mb-3'>
                  <Form.Label>Portal Access</Form.Label>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="C" value="C" checked={formData.portal_access.C} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="inlineCheckbox1">CCLP</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="R" value="R" checked={formData.portal_access.R} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="RFSControl">RFS Control</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="U"  value="U" checked={formData.portal_access.U} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="ULDControl">ULD Control</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="A" value="A" checked={formData.portal_access.A} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="AWBMonitor">AWB Monitor</label>
                  </div>
                </Form.Group>
  
  
              </Modal.Body>
              <Modal.Footer>
                {editId &&
                !editId == '' ? (
                  <Button variant="primary" onClick={handleEditSubmit}>
                    Update User
                  </Button>
                ) :(
                  <Button variant="primary" onClick={handleSubmit}>
                    Add User
                  </Button>
                )
                }
                
              </Modal.Footer>
            </Modal>
            <Modal className='raintrac' show={showConfirmation} onHide={() => setShowConfirmation(false)}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <h2>Confirm Delete</h2>
                <p>If you proceed with the change, user data will be removed.
                  Are you sure you want to continue?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancelDelete}>
                  No
                </Button>
                <Button variant="primary" onClick={handleConfirmDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    )
  }

export default ManageUsers