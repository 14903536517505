import { Button, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Accordion, CardBody, CardHeader } from 'react-bootstrap';
import {  toast } from 'react-toastify';

import { useSelector } from "react-redux";
import Completed from '../../assets/images/Completed.svg';
import sixthImage from '../../assets/images/file.png';
import secondImage from '../../assets/images/information.png';
import thirdImage from '../../assets/images/package.png';
import fourthImage from '../../assets/images/report.png';
import fifthImage from '../../assets/images/truck.png';
import firstImage from '../../assets/images/warehouse.png';

import Header from "../Header/Header.js";
import { withToken } from '../../Services/axios.js';
import { SUBMIT_SHIPMENT_FORM } from '../../Utils/conts.js';
import {
    ShipmentFormsFive,
    ShipmentFormsFour,
    ShipmentFormsOne,
    ShipmentFormsSix,
    ShipmentFormsThree,
    ShipmentFormsTwo
} from './UldstackForms.js';
const CustomStepIcon = ({ icon }) => <span className='iconcer'>{icon}</span>;
const steps = [
    { label: (<>Step 1 <br />Handler Information</>), icon: <img src={firstImage} /> },
    { label: (<>Step 2 <br />Trip Information</>), icon: <img src={secondImage} /> },
    { label: (<>Step 3 <br />Cargo Information</>), icon: <img src={thirdImage} /> },
    { label: (<>Step 4 <br />Booking Slot</>), icon: <img src={fourthImage} /> },
    { label: (<>Step 5 <br />Driver/Truck Information</>), icon: <img src={fifthImage} /> },
    { label: (<>Step 6 <br />Upload Pictures & Documents</>), icon: <img src={sixthImage} /> }
]; // Define your steps

const UldstackPlan = () => {

    const posts = useSelector(state => state.posts);
    // test commit

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        type: 'ULDStack',
        handler_information: { handler_address: '', handler_address_val: '', handler_werehouse:  '', handler_werehouse_val: '' },
        trip_information: { movement_type: '', type_of_vehicle: '', type_of_loading: '', airline_conserned: [], refrance_number: '' },
        cargo_information: { 
            id: '1', 
            
            type_code: '', serial_number: '',
            owner_code: '', airline_name: '', condition_code: '',
            },
            booking_slot: {bookingslot_date: '', bookingslot_time: ''},
            driver_information: { drivername: '', tr_lc: '', tr_lc_pl: '', seal: '', seal_no: '' },
            images : JSON.parse(localStorage.getItem('img'))

    });
    //console.log('formData', formData)
    const [completedData, setCompletedData] = useState([]);
    const [finishStep, setFinishStep] = useState(false);

    useEffect(() => {
        console.log("demo data===>",completedData);
        localStorage.setItem('formData', JSON.stringify(formData));
      }, [formData]);
      console.log("🚀 ~~~~~~ formData:", formData)


      // api call for all data submit-------------
      const handleStepClick = (stepIndex) => {
        if (completedData[stepIndex]) {
          setActiveStep(stepIndex);
        }
      };
      const validateFormData = () => {
        let isValid = true;
        console.log('formData.movement_type', formData.trip_information.movement_type);
        if (activeStep === 0) {
            if (!formData.handler_information.handler_address) {
              console.log('Text input is required');
              toast.error('Handler Address must not be blank');
              isValid = false;
            } else
        
            if (!formData.handler_information.handler_werehouse) {
              console.log('Select an option from the dropdown');
              toast.error('Handler Warehouse must not be blank');
              isValid = false;
            }
            
          }
          if (activeStep === 1) {
            console.log('formData.trip_information.movement_type', formData.trip_information.movement_type);
            
            if (!formData.trip_information.movement_type) {
                console.log('Text input is required');
                toast.error('Movement Type must not be blank');
                isValid = false;
              } else
              if (!formData.trip_information.type_of_vehicle) {
                console.log('Text input is required');
                toast.error('Type of vahicle must not be blank');
                isValid = false;
              } else
              if (!formData.trip_information.type_of_loading) {
                console.log('Text input is required');
                toast.error('Type of Loading must not be blank');
                isValid = false;
              } else
              if (!formData.trip_information.airline_conserned[0]) {
                console.log('Text input is required');
                toast.error('Airline Concerned must not be blank');
                isValid = false;
              } 
            
          }
          if (activeStep === 4) {
            if(!formData.driver_information.tr_lc_pl){
                console.log('Text input is required');
                toast.error('Truck LicensePlate Number must not be blank');
                isValid = false;
            } else
            if (formData.driver_information.seal == 'yes') {
                if(!formData.driver_information.seal_no){
                    console.log('Text input is required');
                    toast.error('Seal Number must not be blank');
                    isValid = false;
                }
                
              }
          }

        return isValid;
      };
    const handleNext = () => {
        console.log('Form Data 1:', formData);
        if (validateFormData()) {
            setCompletedData((prevCompletedData) => ({
                ...prevCompletedData,
                [activeStep]: formData,
              }));
              
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setFormData(completedData[activeStep - 1] || {});
    };


    const handleFinish = async() => {
        console.log('form finish', formData);
        setCompletedData((prevCompletedData) => ({
            ...prevCompletedData,
            [activeStep]: formData,
          }));
          setFinishStep(true);

        //   let payload={
        //     shipment_Details:formData
        //   }
           console.log("🚀 ~ submitForm ~ payload:", formData)
          // return false;
          try {
            const res = await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
            console.log("response==>", res);

            setTimeout(() => {
               window.location.replace('/')  
            },500);
           
          } catch (error) {
            console.error('Error sending request:', error);
          }
    }


    // const handleFormChange = (event) => {
    //     const { name, value, type } = event.target;
    //     // const updatedValue = type === 'radio' && !formData[name] ? 'option1' : value;
    //     setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    // };

    // 20.02.24
    // const [formData1, setFormData1] = useState({
    //     form1: { field1: '', field2: '' },
    //     form2: { optradio1: '', optradio2: '', optradio3: '', field2_1: '', field2_2: '' },
    //     form3: { 
    //         id: '1', awbill: '',awbill1: '',
    //         package: '', mpieces: '',
    //         mweight: '', ccode: '',
    //         sphcode: '', seccode: '',
    //         hawBills: [{ id: '1', hab: '', pieces: '', weight: '' }] },
    // });
    const handleFormData1 = (formName, data) => {
        // setFormData(data);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [formName]: data,
          }));
    };
    const handleFormDataChange = (formName, data) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [formName]: data,
        }));
    };


  const [activeKey, setActiveKey] = useState('0');

  const handleSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);;
  };
  const [activeMawbKey, setActiveMawbKey] = useState('0');

  const handleMawbSelect = (key) => {
    setActiveKey(key === activeMawbKey ? null : key);
  };

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid p-0 create-plan">
                <div className='st-f'>
                    <Stepper activeStep={activeStep} alternativeLabel className='rasteper'>
                        {steps.map(({ label, icon }, index) => (
                            <Step key={index} className={(completedData[index]) ? 'raroot done' : 'raroot'}>
                                <StepLabel StepIconComponent={() => <CustomStepIcon icon={icon} className="rastplabel" />} onClick={() => handleStepClick(index)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className="stepper-right">
                        {/* {activeStep === steps.length  */}
                        {finishStep ? (
                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                <div className='complt'>
                               <img src={Completed} alt=''/>
                               <h3>Thank you!</h3>
                               <p>Submit was successful</p>
                               </div>
                            </div>
                        ) : (
                            <>
                                <div className="stepper-frms">
                                    {/* <Typography>Step {activeStep + 1}</Typography> */}
                                    {/* Render different form components based on the active step */}
                                    {/* Example: */}
                                    {activeStep === 0 && <ShipmentFormsOne formData ={formData.handler_information} onFormDataChange={(data) => handleFormDataChange('handler_information', data)} />}
                                    {activeStep === 1 && <ShipmentFormsTwo formData ={formData.trip_information} onFormDataChange2={(data) => handleFormDataChange('trip_information', data)} />}
                                    {activeStep === 2 && <ShipmentFormsThree formData ={formData.cargo_information} onFormDataChange3={(data) => handleFormDataChange('cargo_information', data)} />}
                                    {activeStep === 3 && <ShipmentFormsFour formData ={formData.booking_slot} onFormDataChange4={(data) => handleFormDataChange('booking_slot', data)} />}
                                    {activeStep === 4 && <ShipmentFormsFive formData ={formData.driver_information} onFormDataChange5={(data) => handleFormDataChange('driver_information', data)} />}
                                    {activeStep === 5 && <ShipmentFormsSix formData ={formData.images} onFormDataChange6={(data) => handleFormDataChange('images', data)} />}
                                    {/* {activeStep === 6 && <CreateReportFormOne />} */}
                                    <div className='nxtbtn'>
                                        <Button disabled={activeStep === 0} onClick={handleBack}>
                                            Back
                                        </Button>
                                        <Button variant="contained" onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                                <div className='stepper-frm-info'>
                                {Object.entries(completedData).map(([step, data], index) => (
                                    <div key={step} variant="outlined" className='infoarm'>
                                    <div>
                                    {console.log('Step:', step)}
                                    {console.log('Data:', data)}
                                        {step === '0' && (
                                            <div className='infobox'>
                                            <h2>Handler Information</h2>
                                            <ul>
                                                <li> <span>Handler Address </span> : <span>{completedData[step].handler_information.handler_address_val}</span></li>
                                                <li> <span>Handler Warehouse </span> : <span>{completedData[step].handler_information.handler_werehouse_val}</span></li>
                                            </ul>
                                            </div>
                                        )}
                                        {step === '1' && (

                                            <div className='infobox'>
                                            <h2>Trip Information</h2>
                                            <ul>
                                            <li> <span>Movement Type </span> : <span>{completedData[step].trip_information.movement_type == 0 ? 'Export (Delivery)' : 'Import (Pickup)'}</span></li>
                                            <li> <span>Type of Vehicle </span> : <span>{completedData[step].trip_information.type_of_vehicle == 0 ? 'Van' : 'Truck'}</span></li>
                                            <li> <span>Type of loading/unloading </span> : <span>{completedData[step].trip_information.type_of_loading == 0 ? 'Back of Trailer' : 'Side of Trailer'}</span></li>
                                            <li> <span>Airline Concerned </span> : <span>
                                                {completedData[step].trip_information.airline_conserned.map((item, index) => (
                                                    <div key={index}>{item.label}</div>
                                                ))}</span></li>
                                            <li> <span>Reference Number </span> : <span>{completedData[step].trip_information.refrance_number}</span></li>
                                            </ul>
                                            </div>
                                            
                                        )}
                                        {/* {step === '2' && Array.isArray(data.form3) && ( */}
                                        {step === '2' && ( 
                                            <>
                                            {/* {console.log('Step 2 Data:', data.cargo_information)} */}
                                            <div className='infobox' key={index}>
                                            <h2>Cargo Information</h2>
                                            {/* {data.form3.map((section, form3Index) => (  This is working*/}
                                            {data.cargo_information && Array.isArray(data.cargo_information) && data.cargo_information.map((section, form3Index) => (
                                            <>
                                            {console.log('Step 2 Data:', data.cargo_information)}
                                            {console.log('radio', section)}

                                            <div>

                                                
                                                <Accordion activeKey={activeKey} onSelect={handleSelect}>
                                                <Accordion.Item  key={form3Index} eventKey={form3Index.toString()}>
                                                <Accordion.Header>ULD Stack</Accordion.Header>
                                                <Accordion.Body>
                                                <ul className='rainfobox'>
                                                    <li> <span>Type Code </span> : <span>{section.type_code}</span></li>
                                                    <li> <span>Serial Number </span> : <span>{section.serial_number}</span></li>
                                                    <li> <span>Owner Code </span> : <span>{section.owner_code} </span></li>
                                                    <li> <span>Airline Name </span> : <span>{section.airline_name} </span></li>
                                                    <li> <span>Condition Code </span> : <span>{section.condition_code}  </span></li>

                                                    
                                           
                                            </ul>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                
                                                </Accordion>



                                                
                                                                                              
                                            </div>

                                            
                                            </>
                                            ))}
                                            {!data.cargo_information && <p>No Form 3 Information available.</p>}
                                            </div>
                                            
                                            </>
                                            
                                            
                                        )}

                                            {step === '3' && (
                                                <>
                                                <div className='infobox'>
                                            <h2>Booking Slot</h2>
                                            <ul>
                                                <li> <span>Date </span> : <span>{completedData[step].booking_slot.bookingslot_date}</span></li>
                                                <li> <span>Time </span> : <span>{completedData[step].booking_slot.bookingslot_time}</span></li>
                                            </ul>
                                            </div>
                                                </>

                                            )}

                                            {step === '4' && (

                                            <div className='infobox'>
                                            <h2>Driver/Truck Information</h2>
                                            <ul>
                                            <li> <span>Driver Name </span> : <span>{completedData[step].driver_information.drivername}</span></li>
                                            <li> <span>Trailer License Plate Nr. </span> : <span>{completedData[step].driver_information.tr_lc}</span></li>
                                            <li> <span>Truck License Plate Nr. </span> : <span>{completedData[step].driver_information.tr_lc_pl}</span></li>
                                            <li> <span>Seal Number </span> : <span>{completedData[step].driver_information.seal === 'yes' ? completedData[step].driver_information.seal_no : "NA"}</span></li>
                                            </ul>
                                            </div>

                                            )}

                                        
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UldstackPlan;