import React from 'react'

const CreateReportFormOne = () => {
  return (
    <div>
        <form>
            <label>name</label><input type='text' />
            <label>address</label><input type='text' />
        </form>
    </div>
  )
}

const CreateReportFormTwo = () => {
    return (
      <div>CreateReportForm 2</div>
    )
  }

  const CreateReportFormThree = () => {
    return (
      <div>CreateReportForm 3</div>
    )
  }

export { CreateReportFormOne, CreateReportFormTwo, CreateReportFormThree };