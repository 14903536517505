
export const useAuth = () => {
    //getting token from local storage
    const user = localStorage.getItem('token')
    //checking whether token is preset or not
    if (user) {
        return true;
    } else {
        return false
    }
};

export const useAuthRFS = () => {
    //getting token from local storage
    const RFSuser = localStorage.getItem('RFS');
    const ULDuser = localStorage.getItem('ULD');
    //checking whether token is preset or not
    if (RFSuser && !ULDuser) {
        return true;
    } else {
        return false
    }
};

export const useAuthULD = () => {
    //getting token from local storage
    // const RFSuser = localStorage.getItem('RFS');
    const ULDuser = localStorage.getItem('ULD');
    //checking whether token is preset or not
    if (ULDuser) {
        return true;
    } else {
        return false
    }
};