import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { withoutRFSToken, withRFSToken } from '../../Services/axios.js';
import { STATIONS, ADDSTATION, STATIONGRID } from '../../Utils/conts.js';

const AddStation = ({ onClose, reloadStation, handleAddToGrid: propHandleAddToGrid }) => {
  const [modalShow, setModalShow] = useState(true);
  const [dropdownData, setDropdownData] = useState([]);
  //   { value: 'AMS', label: 'Amsterdam', address: ['AMSaddress1', 'AMSaddress2', 'AMSaddress3',] },
  //   { value: 'BRU', label: 'Brussels', address: ['BRUaddress1', 'BRUaddress2', 'BRUaddress3',] },
  //   { value: 'CDG', label: 'Charles de Gaulle', address: ['CDGaddress1', 'CDGaddress2'] },
  //   { value: 'FRA', label: 'Frankfurt', address: ['FRAaddress1', 'FRAaddress2', 'FRAaddress3',] },
  // ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await withRFSToken.post(STATIONS, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            const transformedData = innerData ? innerData.map(item => ({
              id: item.port_Id,
              value: item.code,
              label: item.code + '-' + item.name
            })) : [];
            setDropdownData(transformedData);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // Call the fetchData function to fetch data from the API
    fetchData();

  }, []);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const [stationData, setStationData] = useState();
  const [formData, setFormData] = useState({
    port_Id: '',
    code: ''
  });

  const handleAddToGrid = async () => {
    if (selectedDropdownValue.length > 0) {
      const sendStationData = selectedDropdownValue.map(selectedStation => {
        const selectedData = dropdownData.find(item => item.value === selectedStation.value);
        return {
          port_Id: selectedData.id,
          code: selectedData.value,
          // Taddrs: selectedData.address.length.toString(),
          // address: selectedData.address,
        };
      });
      console.log("🚀 ~ sendStationData ~ sendStationData:", sendStationData)

      // setFormData(sendStationData);
      if (sendStationData.length > 0) {
        // dispatch(addData(sendStationData)); // Pass sendStationData to addData thunk
        try {
          const res = await withRFSToken.post(ADDSTATION, { addData: sendStationData })
            .then(function (res) {
              // const innerData = res;
              // console.log("🚀 ~ .then ~ innerData:", innerData)
              if (res.data.response_code == 200) {
                reloadStation();
              }
            });

        } catch (error) {
          console.error('Error sending request:', error);
        }
      }

      // dispatch(addData(formData));
      // dispatch(fetchStationGridData());
      setSelectedDropdownValue([]);
      setModalShow(false);
    }
    console.log('65', formData);
  };

  const handleModalClose = () => {
    setModalShow(false);
    onClose();
  };

  return (
    <>
      <Modal show={modalShow} centered onHide={handleModalClose} className='addfrom staion_add' >
        <Modal.Header closeButton>
          <Modal.Title>Add Stations</Modal.Title>
          <p>Add stations by selecting the station.</p>
        </Modal.Header>
        <Modal.Body>

          <Form.Group controlId="dropdown">
            <MultiSelect
              options={dropdownData}
              value={selectedDropdownValue}
              onChange={setSelectedDropdownValue}
              labelledBy="Select "
              className='multiselect'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddToGrid}>
            Add Stations
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddStation
