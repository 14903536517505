import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck, faChevronDown, faGear, faMoneyBillTransfer, faReceipt } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Cargoplane from "../../../assets/images/plane.svg";
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargotruck from "../../../assets/images/cargo-truck.png";
import Cuser from "../../../assets/images/cuser.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import ImageUpload from '../../../Componenets/common/ImageUpload';
import ActivityComments from '../../../Componenets/common/ActivityComments';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDONLOANGRID, MVTONLOAN, FINALIZETR, GETULDDETAILS, MVTHDETAILS } from '../../../Utils/conts.js';
import DownloadGrid from './components/DownloadGrid.js';

const Onloan = () => {
    const isMounted = useRef(false);
    const [gridData, setGridData] = useState({
        Loan: [],
        Transfer: [],

    });
    const fetchDataGridL = async () => {
        try {
            const res = await withRFSToken.post(ULDONLOANGRID, { type: 'L' })
                .then(function (res) {
                    const innerData = res.data.response_data.loan_data;
                    console.log('inn', innerData);
                    setGridData({ Loan: innerData });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDataGridT = async () => {
        try {
            const res = await withRFSToken.post(ULDONLOANGRID, { type: 'T' })
                .then(function (res) {
                    const innerData = res.data.response_data.loan_data;
                    console.log('inn', innerData);
                    setGridData({ Transfer: innerData });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (isMounted.current) return;
        // Call the fetchData function to fetch data from the API
        fetchDataGridL();
        fetchDataGridT();
        isMounted.current = true;
    }, [])
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    const [activeTab, setActiveTab] = useState('Loan');
    const [isFinalize, setIsFinalize] = useState(false);
    const [finalizeData, setFinalizeData] = useState('');
    const gridRefs = {
        Loan: useRef(null),
        Transfer: useRef(null),
    };
    const handleTabChange = async (tab) => {
        setActiveTab(tab);
        if (selectedRowData) {
            setSelectedRowData(null)
        }
        let tabType = '';
        if (tab == 'On Loan') { tabType = 'L' } else { tabType = 'T' }
        try {
            const res = await withRFSToken.post(ULDONLOANGRID, { type: tabType })
                .then(function (res) {
                    const innerData = res.data.response_data.loan_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    // const transformData = innerData ? innerData.map(data => ({...data, reg: 'R' })) : '';
                    if (tabType == 'L') {
                        setGridData({ Loan: innerData });
                    } else {
                        setGridData({ Transfer: innerData });
                    }
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {

        if (gridRefs[activeTab].current) {
            if (gridData[activeTab]) {
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'uld_number', type: 'string' },
                        { name: 'type_code', type: 'string' },
                        { name: 'serial_number', type: 'string' },
                        { name: 'condition', type: 'string' },
                        { name: 'condition_code', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'origin', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'scm', type: 'string' },
                        { name: 'scm_date', type: 'string' },
                        { name: 'asset_type', type: 'string' },
                        { name: 'handler_name', type: 'string' },
                        { name: 'repair_name', type: 'string' },
                        { name: 'repair_station', type: 'string' },
                        { name: 'repair_out', type: 'string' },
                        { name: 'repair_in', type: 'string' },
                        { name: 'airline_name', type: 'string' },
                        { name: 'owner_code', type: 'string' },


                        { name: 'control_receipt_no', type: 'string' },
                        { name: 'ucr_pdf', type: 'string' },
                        { name: 'transfer_type', type: 'string' },
                        { name: 'transfer_date', type: 'string' },
                        { name: 'transfer_point', type: 'string' },
                        { name: 'transferred_by', type: 'string' },
                        { name: 'transferred_by_name', type: 'string' },


                        { name: 'luc_final_dest', type: 'string' },
                        { name: 'received_by', type: 'string' },
                        { name: 'received_by_name', type: 'string' },
                        { name: 'luc_in', type: 'string' },
                        { name: 'luc_out', type: 'string' },
                        { name: 'return_station', type: 'string' },
                        { name: 'batch_no', type: 'string' },
                        { name: 'register', type: 'string' },

                    ],
                    localdata: gridData,
                };

                const dataAdapter = new window.$.jqx.dataAdapter(source);
                // console.log('localdata', source.localdata)
                let columns;
                if (activeTab === 'Loan') {
                    columns = [
                        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                        {
                            text: 'Actions',
                            cellsrenderer: function () {
                                return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                            },
                            width: '5%', filterable: false,
                        },
                        { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
                        { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '10%', cellclassname: '', },
                        { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '10%', cellclassname: '', },
                        { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '10%', cellclassname: '', },
                        { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition', width: '10%', cellclassname: '', },

                        { text: 'LUC Out', filtertype: 'textbox', datafield: 'luc_out', width: '10%', cellclassname: '', },
                        //  { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                        { text: 'UCR No.', filtertype: 'textbox', datafield: 'control_receipt_no', width: '8%', cellclassname: '', },
                        { text: 'Transferred By', filtertype: 'textbox', datafield: 'transferred_by_name', width: '8%', cellclassname: '', },
                        { text: 'Transfer Point', filtertype: 'textbox', datafield: 'transfer_point', width: '8%', cellclassname: '', },
                        { text: 'Received By', filtertype: 'textbox', datafield: 'received_by_name', width: '8%', cellclassname: '', },
                        { text: 'Final Destination', filtertype: 'textbox', datafield: 'luc_final_dest', width: '8%', cellclassname: '', },
                        {
                            text: 'LUC In', filtertype: 'textbox', datafield: 'luc_in',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                return '<div class="set_datetime" id=' + dataField + '>' + cellValue + '</div>';
                            },
                            width: '6%', cellclassname: 'frcl',
                        },
                        { text: 'Return Station', filtertype: 'textbox', datafield: 'return_station', width: '6%', cellclassname: '', },
                    ];
                } else if (activeTab === 'Transfer') {
                    columns = [
                        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                        {
                            text: 'Actions',
                            cellsrenderer: function () {
                                return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                            },
                            width: '5%', filterable: false,
                        },
                        {
                            text: 'Finalize',
                            // filtertype: 'textbox',
                            filterable: false,
                            width: '10%',
                            datafield: 'file_paths',
                            cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
                                // console.log("🚀 ~ setupGrid ~ data:333333", data)
                                return '<button class="finalize">Finalize</button>';
                            },
                        },
                        { text: 'Type', filtertype: 'textbox', datafield: 'transfer_type', width: '10%', cellclassname: '', },
                        { text: 'Transfer Date', filtertype: 'textbox', datafield: 'transfer_date', width: '10%', cellclassname: '', },
                        { text: 'UCR No.', filtertype: 'textbox', datafield: 'control_receipt_no', width: '10%', cellclassname: '', },
                        { text: 'UCR Pdf.', filtertype: 'textbox', datafield: 'ucr_pdf', width: '8%', cellclassname: '', },
                        { text: 'Transferred By', filtertype: 'textbox', datafield: 'transferred_by_name', width: '10%', cellclassname: '', },
                        { text: 'Transfer Point', filtertype: 'textbox', datafield: 'transfer_point', width: '10%', cellclassname: '', },
                        { text: 'Received By', filtertype: 'textbox', datafield: 'received_by_name', width: '10%', cellclassname: '', },
                        { text: 'Final Destination', filtertype: 'textbox', datafield: 'luc_final_dest', width: '10%', cellclassname: '', },
                    ];
                }
                // Nested Start
                const nestedColumns = [
                    { text: 'ULD Number', datafield: 'uld_number', width: '12%' },
                    { text: 'Type Code', datafield: 'type_code', width: '10%' },
                    { text: 'Serial Number', datafield: 'serial_number', width: '10%' },
                    { text: 'Owner Code', datafield: 'owner_code', width: '10%' },

                    // Add more columns as needed
                ];
                const initrowdetails = (index, parentElement, gridElement, record) => {
                    const nestedGridContainer = parentElement.children[0];

                    parentElement.addEventListener('click', (event) => {
                        const target = event.target;
                        // Check if the click target is the nested arrow
                        if (window.$(target).hasClass('jqx-icon-arrow-right')) {
                            // Handle nested arrow click here
                            // console.log('Nested arrow clicked:', record);
                            event.stopPropagation(); // Prevent event bubbling to the row details
                        }
                    });
                    const id = record.uid.toString();
                    const uldGrid = dataAdapter.loadedData.Transfer[id].uldInfo;

                    const nestedSource = {
                        datatype: 'json',
                        id: 'shipmentChildID',
                        datafields: [
                            { name: 'uld_number', type: 'string' },
                            { name: 'type_code', type: 'string' },
                            { name: 'serial_number', type: 'string' },
                            { name: 'owner_code', type: 'string' },
                            { name: 'pending_transfer_id', type: 'string' },
                            { name: 'uld_id', type: 'string' },
                        ],
                        localdata: uldGrid,
                    }
                    const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);

                    const nestedGridOptions = {
                        width: '100%',
                        pageable: false,
                        sortable: false,
                        // pageSize: 15,
                        autoheight: false,
                        height: (10 * 45),
                        // selectionmode: 'checkbox',
                        source: nestedDataAdapter,
                        filterable: false,
                        columns: nestedColumns,
                        rowdetails: true,
                        rowsheight: 45,
                        showemptyrow: false,
                        ready: function () {
                            // Manually set row IDs starting from 0
                            const rows = window.$(nestedGridContainer).jqxGrid('getrows');
                            for (let i = 0; i < rows.length; i++) {
                                const rowid = i; // Use index as the new row ID
                                const row = rows[i];
                                window.$(nestedGridContainer).jqxGrid('setcellvalue', row.uid, 'uid', rowid);
                                window.$(nestedGridContainer).jqxGrid('updaterow', rowid, row);
                                window.$(nestedGridContainer).find(`[role="row"][row-id="${row.uid}"]`).attr('row-id', rowid);
                            }
                        },
                    };
                    window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
                };
                // Nested End

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: true,
                    sortable: true,
                    altrows: false,
                    enabletooltips: true,
                    editable: false,
                    selectionmode: activeTab === 'Loan' ? 'checkbox' : 'none',
                    pageable: false,
                    initrowdetails: activeTab === 'Transfer' ? initrowdetails : '',
                    pageSize: 10,
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    rowdetails: activeTab === 'Transfer' ? true : false,
                    rowdetailstemplate: activeTab === 'Transfer' ? {
                        rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
                        rowdetailsheight: (15 * 45),
                        rowdetailshidden: true,
                    } : '',

                };

                window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
                window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

                window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });
                // row selection by checkbox
                window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        const selectedRowId = selectedRow.uid;
                        if (!gridSelect.some(row => row.uid === selectedRowId)) {
                            setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData[activeTab];
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
                    // console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.uld_number;
                        console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                        setGridSelect(prevSelection => prevSelection.filter(row => row.uld_number !== unselectedRowId));
                    }
                });
                // End row selection 
                window.$(gridRefs[activeTab].current).on('click', '.finalize', function () {
                    // console.log("🚀 ~ event:01010", event)
                    const rowDiv = window.$(this).closest('[role="row"]');
                    // console.log("🚀 ~ cellDiv:", rowDiv)
                    const rowIndex = window.$(rowDiv).attr('row-id');
                    const rowData = window.$(gridRefs[activeTab].current).jqxGrid('getrowdata', rowIndex);

                    setFinalizeData(rowData.batch_no);
                    setIsFinalize(true);
                    // setEditData(dataToSend);
                    // setShowEdit(true);

                });

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }
        window.$(gridRefs[activeTab].current).off('cellclick');
        window.$(gridRefs[activeTab].current).on('cellclick', (event) => {
            const args = event.args.row.bounddata;
            if (event.args.columnindex > 1) {
                // For Remove Checkbox Selection
                if (gridSelect) {
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                }
                // For Remove Checkbox Selection
                loadDetails(args.uld_number);
            }
        });
    }, [activeTab, gridData, selectedRowData]);

    const loadDetails = async (uldNumber) => {
        try {
            const res = await withRFSToken.post(GETULDDETAILS, { uld_number: uldNumber })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setSelectedRowData(innerData);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const confirmFinalize = async () => {
        // console.log('101', gridSelect);
        // const sendAction = activeTab == 'Active' ? 'R' : 'A';
        // const uldNumbers = gridSelect && gridSelect.map(num => num.uld_number)
        try {
            const res = await withRFSToken.post(FINALIZETR, { batch_no: finalizeData })
                .then(function (res) {
                    if (res.data.response_code == 200) {
                        toast.success('Finalized Success');
                        setFinalizeData('');
                        setIsFinalize(false);
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [downloadOpen, setDownloadOpen] = useState(false);
    const showDownloadModal = () => {
        setDownloadOpen(true);
    };
    const closeDownloadModal = () => {
        setDownloadOpen(false);
    };

    // +++++++++++++++++++ div +++++++++++++++++++++++++

    // upload Section
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [base64Files, setBase64Files] = useState([]);

    // const imageModalUpload = (id) => {
    //     setSelectedRowId(id);
    //     setShowModalUpload(true)
    //     setFiles([])
    // }
    const imageModalUpload = (id) => {
        const selectedRowFiles = gridSelect.find(item => item.uid === id)?.files || [];
        setFiles(selectedRowFiles);
        setSelectedRowId(id); // Store the ID of the selected row
        setShowModalUpload(true);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        getBase64(droppedFiles[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
        base64Files.splice(index, 1);
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithBase64 = [];

        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                filesWithBase64.push({ file, base64: reader.result });

                if (filesWithBase64.length === selectedFiles.length) {
                    setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
                    setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const imageUpload = () => {
        setGridSelect(prevGridSelect =>
            prevGridSelect.map(item => {
                if (item.uid === selectedRowId) {
                    return { ...item, files: files, base64Files: base64Files };
                }
                return item;
            })
        );
        setShowModalUpload(false);
    };

    const movementHistoryClick = () => {
        localStorage.removeItem('MHistory');
        const storageData = {
            ULD: selectedRowData,  // ULD Details section
            Form: {}  // Empty form section
        };
        localStorage.setItem('MHistory', JSON.stringify(storageData));
        // localStorage.setItem('tab', 'operations');
        window.location.href = '/ULDControl/MovementHistory'
    };
    // upload forward
    const imageUploadRef = useRef(null);
    const browseClick = () => {
        if (imageUploadRef.current) {
            imageUploadRef.current.handleBrowseFiles();
        }
    };
    // History Details
    const handleDetailsClick = async (history) => {
        console.log("🚀 ~ handleDetailsClick ~ history:", history)

        if (!selectedRowData) {
            throw new Error('Selected row data is not available.');
        }

        try {
            const res = await withRFSToken.post(MVTHDETAILS, { uld_id: selectedRowData.uld_id, uld_status_id: history.uld_status_id })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    localStorage.removeItem('MHistory');
                    localStorage.removeItem('MHDetails');
                    const storageData = {
                        ULD: selectedRowData,  // ULD Details section
                        Form: {}  // Empty form section
                    };
                    localStorage.setItem('MHistory', JSON.stringify(storageData));
                    const storageDataDetails = {
                        ULD: innerData.others_ulds,  // ULD Details section
                        Form: innerData.uld_movement_detail,
                        tab: 'All'  // Empty form section
                    };
                    localStorage.setItem('MHDetails', JSON.stringify(storageDataDetails));
                    // localStorage.setItem('tab', 'operations');
                    let detailsPageUrl;
                    const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
                    if (detailsType) {

                        detailsPageUrl = '/ULDControl/MovementHistorydetails';
                        window.location.href = detailsPageUrl;
                    } else {
                        console.log('No valid details type found');
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // +++++++++++++++++++++++++++++++++++++++++++++++++++++ file upload 


    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    const handleDropdownItemClick = async (pos, action) => {
        const keysToRemove = [
            'Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out',
            'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'
        ];

        // Remove keys from localStorage
        keysToRemove.forEach(key => localStorage.removeItem(key));

        // Helper function to save data and navigate to the given URL
        const saveAndNavigate = (action, data, url = '/ULDControl/ULDFunction') => {
            localStorage.setItem(action, JSON.stringify(data));
            localStorage.setItem('tab', 'onloan');
            window.location.href = url;
        };

        // Helper function to create storage data for ULD
        const createStorageData = (ULDData, additionalForm = {}) => {
            // const form = {
            //     flight_or_truck: ULDData.flight_or_truck === 'Flight' ? 'F' : 'T',
            //     flight_number: ULDData.flight_number,
            //     flight_date: ULDData.flight_date,
            //     origin: ULDData.origin,
            //     destination: ULDData.destination,
            //     ...additionalForm
            // };
            return { ULD: [ULDData], Form: additionalForm };
        };

        const handleDetails = async () => {
            const storageData = { ULD: [selectedRowData], Form: {} };
            saveAndNavigate(action, storageData);
        };

        // Handle operations for 'top' position
        const handleTop = async () => {
            if (gridSelect.length === 0) {
                if (['LUC In', 'Transfer', 'Request'].includes(action)) {
                    const storageData = { ULD: [], Form: {} };
                    saveAndNavigate(action, storageData);
                }
            } else {
                if (!action == 'LUC In') {
                    const actionType = action == 'Transfer' ? 'ST' : (action == 'Request' ? 'TR' : 'LI')
                    try {
                        const response = await withRFSToken.post(MVTONLOAN, { action_type: actionType, ulds: gridSelect.map(item => item.uld_number) });
                        const innerData = response.data;
                        const { response_code, response_data } = response.data;
                        if (response_code === 200) {
                            // if (response_data.logic_type === 1) {
                            const storageData = {
                                ULD: response_data.uldInfo,
                                Form: response_data.Form
                            };
                            saveAndNavigate(action, storageData);
                            // } else {
                            //     setResponseAction(response_data);
                            //     setShowConfirmation(true);
                            // }
                        }
                        else { toast.error(response.data.response_msg) }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                } else {
                    const storageData = { ULD: gridSelect, Form: {} };
                    saveAndNavigate(action, storageData);
                }

            }
        };

        // Main conditional logic based on position
        if (pos === 'details') {
            await handleDetails();
        } else if (pos === 'top') {
            await handleTop();
        }
    };



    return (
        <>
            {downloadOpen && <DownloadGrid download_sec='Loan' onClose={closeDownloadModal} />}
            <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex'>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='action'>
                            <FontAwesomeIcon icon={faGear} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'LUC In')}>LUC In</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button className="transfer" variant="link" onClick={() => handleDropdownItemClick('top', 'Transfer')}>
                        <span> <FontAwesomeIcon icon={faMoneyBillTransfer} /> Station Transfer</span>
                    </button>
                    <button className="receive" variant="link" onClick={() => handleDropdownItemClick('top', 'Request')}>
                        <span> <FontAwesomeIcon icon={faReceipt} /> Transfer Request</span>
                    </button>
                </div>
            </div>

            <div className='ulddown'>
                <button onClick={showDownloadModal}><img src={Export} /> Download</button>
            </div>

            <div className="maindiv">
                <div className='trashdonload'>
                    <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
                        <Tab eventKey="Loan" title={<span>On Loan <Badge className='rabadge'>{ }</Badge></span>} className='moveto'>

                            <div ref={gridRefs.Loan} className='bookinggrid' ></div>
                        </Tab>
                        <Tab eventKey="Transfer" title={<span>Pending Transfer <Badge className='rabadge'>{ }</Badge></span>} className='moveto'>
                            {/* <div className='moveto_btn ulddown'>
                                
                                <button onClick={exportToExcel}><img src={Export} /> Download</button>
                            </div> */}
                            <div ref={gridRefs.Transfer} className='bookinggrid' ></div>
                        </Tab>

                    </Tabs>

                    {/* <div className='moveto_btn'>
                    <button onClick={exportToExcel}><img src={Export} /> Download</button>
                </div>
               <div ref={gridRef} className='staion_grid' ></div> */}
                </div>
                {selectedRowData && (
                    <div className='SecondDiv mt-45'>
                        {/* {console.log('55', selectedRowData)} */}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.uld_number} &nbsp; <span>{selectedRowData.status}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={browseClick} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faBolt} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'LUC In')}>LUC In</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Origin</p>
                                <span>{selectedRowData.origin}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.flight_date}</p>
                                <p><img src={Cargoplane} /></p>
                                <p>{selectedRowData.flight_number}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>Destination</p>
                                <span>{selectedRowData.destination}</span>
                            </div>
                        </div>
                        <div className='movment_history'>
                            <h3>Movement History</h3> <Link to='#' onClick={movementHistoryClick}>View All</Link>
                        </div>
                        <div className='movment_list'>
                            <ul>
                                {selectedRowData && selectedRowData.movement_history.slice(0, 5).map((history, index) => (
                                    <li key={index}>
                                        <span className='movem'>{history.operation_type}</span>
                                        <span className='mvdate'>{history.operation_date}</span>
                                        <Link to='#' onClick={() => handleDetailsClick(history)}>Details</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                                <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                                    {console.log("🚀 ~ ExportActive ~ truck:", truck)}
                                    <div>
                                        <span>Truck No</span>
                                        <h4>{truck.truck_no}</h4>
                                    </div>
                                    <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                                        <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                                    </div>
                                    <div>
                                        <Link>Details</Link>
                                    </div>
                                </div>
                            ))} */}
                        <ImageUpload
                            ref={imageUploadRef}
                            attachable_type={"uld"}
                            attachable_id={selectedRowData.uld_number}
                            attachments={selectedRowData.attachments}
                        />
                        <ActivityComments
                            commentable_type={"uld"}
                            commentable_id={selectedRowData.uld_number}
                            allactivities={selectedRowData.activities}
                            onReloadDetails={loadDetails}
                        />
                    </div>
                )}

                <Modal show={isFinalize} onHide={() => setIsFinalize(false)} className='addfrom change_type' centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Finalize Pending Transfer</h3>
                        <p>Are you sure you want to Finalize?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex w-50 mx-auto are_bottom'>
                            <Button variant="secondary" onClick={() => setIsFinalize(false)}>
                                No
                            </Button>
                            <Button variant="primary" onClick={confirmFinalize}>
                                Yes
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Onloan