export const DASHBOARD ="dashBoard/dashboard"
export const EDITSHIPMENT ="dashBoard/dashboard?edit"
export const ARCHIVEGRID ="dashBoard/dashboard?archive"
export const TRASHGRID ="dashBoard/dashboard?trash"
export const ARCHIVE_ROW_IDS ="dashBoard/dashboard?archiveIds"
export const TRASH_ROW_IDS ="dashBoard/dashboard?trashIds"
export const HANDLER_ADDRESS="ShipmentPlan/formApis?handlerAddress"
export const AIRLINECONCERNED="ShipmentPlan/formApis?airlineConcerned"
export const CUSTOMCODE="ShipmentPlan/formApis?customCode"
export const SPECIALHANDLINGCODE="ShipmentPlan/formApis?specialHandlingcode"
export const SECURITYCODE="ShipmentPlan/formApis?securityCode" 
export const SUBMIT_SHIPMENT_FORM="ShipmentPlan/formSubmit"
export const GRID_DATETIME_UPDATE="ShipmentPlan/editShipment?dateTimeUpdate"
export const BOOKINGSLOTS="ShipmentPlan/formApis?availableTimingSlot"
export const ULDTYPECODES ="ShipmentPlan/formApis?getTypeCodes"
export const ULDOWNERCODES ="ShipmentPlan/formApis?getOwnerCode"
export const USERSTATIONS ="users/usersInfo?orgStations"
export const USERLISTS ="users/usersInfo?userList"
export const SUBMITLIST ="users/usersInfo?saveUser"
export const USERDETAILS ="users/usersInfo?userDetails"
export const USERDELETE ="users/usersInfo?deleteUser"

export const STATIONS ="get-all-stations"
export const ADDSTATION ="add-trucker-station"
export const STATIONGRID ="get-trucker-stations"
export const ADDTRUCKER ="trucker-save"
export const TRUCKERGRID ="trucker-list"
export const TRUCKERACTIVE ="trucker-active"
export const TRUCKERARCHIVE ="trucker-archive"
export const TRUCKERTRASH ="trucker-trash"
export const TRUCKERADDEMAIL ="trucker-save-email"
export const TRUCKEREDITEMAIL ="trucker-save-email"
export const TRUCKERDELEMAIL ="trucker-delete-email"
export const TRUCKERSAVERATE ="trucker-save-rate"
export const TRUCKERRATELIST ="trucker-rates-list"

export const TRUCKORDERLIST ="truck-order-list"

export const ACTSTATIONGRID ="active-station"
export const ARCSTATIONGRID ="archive-station"
export const REMSTATIONGRID ="delete-station"
export const ADDSTATIONADDRESS ="add-station-address"
export const ADDTRUCKSCHEDULE ="add-truck-schedule"
export const BOOKINGTRUCKGRID ="booking-truck"
export const BOOKINGTRUCKMOVE ="truck-schedule-move-to-trash"
export const BOOKINGTRUCKDETAILS ="get-booking-truck-schedule"
export const SAVEATTACHMENTS ="attachment-save"

export const GETTYPECODE ="uld-forms/get-type-code"
export const GETDESTINATION ="uld-forms/get-destinations"
export const GETAGENT ="uld-forms/get-agents"
export const GETINTAGENT ="uld-forms/get-internal-agents"
export const GETEXTAGENT ="uld-forms/get-external-agents"
export const GETINTAGENTHAND ="uld-forms/get-internal-handler-agents"
export const GETOWNERCODE ="uld-forms/get-owner-code"
export const GETCONTENTCODE ="uld-forms/get-content-code"
export const GETCONDITIONCODE ="uld-forms/get-condition-code"
export const GETDAMAGETYPE ="uld-forms/damage-type"
export const UPDATESTATUS ="uld-action/update-uld-status"
export const UPDATECONDITION ="uld-action/condition-change"
export const IMPORTEXCEL ="uld-forms/import-uld-excel"
export const SAVEULDSTOCK ="save-uld-stock"
export const EDITULDSTOCK ="edit-uld"
export const EDITONAIR ="uld-action/on-air-edit"
export const ULDASSETSGRID ="uld-control/assets"
export const MOVETOACTARC ="uld-action/move-active-archive"
export const ULDSEARCH ="search-uld"
export const ULDSEARCHDETAILS ="get-uld-details"
export const GETULDDETAILS ="get-uld-details"
export const ULDOPERATIONGRID ="uld-control/operations"
export const ULDONAIRGRID ="uld-control/onair"
export const ULDREPAIRGRID ="uld-control/repair"
export const ULDONLOANGRID ="uld-control/onloan"
export const ULDTRANSFERGRID ="uld-control/agents-overview"
export const ULDREPAIRUPDATE ="uld-action/moveTo-repair"
export const ULDALLUPDATE ="uld-action/update-mvt"
export const STTRUPDATE ="uld-action/save-station-transfer"
export const MSGPROCESSCHARTER ="uld-forms/ffm-message-processing"
export const MSGPROCESSMVT ="uld-forms/message-processing"
export const ULDEXIST ="is-exists-uld"
export const SAVEULDCHARTER ="save-uld-charter"
export const DOWNLOADULD ="download-ulds"
export const MVTUPDATE ="uld-action/mvt-update-logic"
export const FINALIZETR ="uld-action/finalize-pending-transfer"
export const MVTONLOAN ="uld-action/transfer-logic"
export const MVTHDETAILS ="uld-history/movement-history-details"

export const UCMHISTORYGRID ="uld-history/get-movement-history"

export const ULDSETTINGSTATION ="uld-settings/stations"
export const ULDSTATIONDETAILS ="uld-settings/station-address"
export const ULDADDRESSSAVE ="uld-settings/save-address"
export const ULDADDRESSACTARC ="uld-settings/move-active-archive"
export const ULDADDRESSRECMAIL ="uld-settings/save-recovery-email"

export const DELETEATTACHMENTS ="attachment-delete"
export const COMMENTSAVE ="comment-submit"
export const TRUCKORDERSAVE ="truck-order-save"
export const GETSCHEDULEDTRUCKS ="get-scheduled-trucks"
export const COMMENTDELETE ="comment-delete"
export const COMMENTEDIT ="comment-edit"
export const IMAGETOBASE64 ="uld-action/convert-imageToBase64?url="
