
import React from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { useAuth, useAuthRFS, useAuthULD } from './useAuth'

function PrivateRoutes({children}) {
    const token = useAuth();
    const tokenRFS = useAuth();
    const tokenULD = useAuth();
    if(!token || !tokenRFS || !tokenULD) {
        window.location.replace(process.env.REACT_APP_ITS_2_BASE_URL)
        return null;
    }
    return children;
}
export default PrivateRoutes