import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import { withoutRFSToken, withRFSToken } from '../../../../Services/axios.js';
import { DOWNLOADULD, GETTYPECODE, GETDESTINATION, GETCONDITIONCODE, GETOWNERCODE, GETDAMAGETYPE } from '../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const DownloadGrid = ({ onClose, download_sec }) => {
    const [getTypeCodes, setGetTypeCodes] = useState([]);
    const [getOwnerCodes, setGetOwnerCode] = useState([]);
    const [getCondCodes, setGetCondCode] = useState([]);
    const [getDestination, setGetDestination] = useState([]);
    const [damageType, setDamageType] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [downloadData, setDownloadData] = useState({
        condition_code: [], status: [], origin: [], type_code: [], owner_code: [],
    });
    const options = [];

    const fetchTypeCode = async () => {
        try {

            const res = await withRFSToken.post(GETTYPECODE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData ? innerData.map(item => ({
                        value: item.uld_typecode,
                        label: item.uld_typecode
                    })) : [];
                    setGetTypeCodes(transformedData);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchOwnerCode = async () => {
        try {
            const res = await withRFSToken.post(GETOWNERCODE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData ? innerData.map(item => ({
                        value: item.IATA,
                        label: item.IATA
                    })) : [];
                    setGetOwnerCode(transformedData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchCondCode = async () => {
        try {
            const res = await withRFSToken.post(GETCONDITIONCODE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData ? Object.entries(innerData).map(item => ({
                        value: item[0],
                        label: item[1]
                    })) : [];
                    setGetCondCode(transformedData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDestination = async () => {
        try {

            const res = await withRFSToken.post(GETDESTINATION, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData ? innerData.map(item => ({
                        value: item.port_code,
                        label: item.port_code,
                    })) : [];
                    setGetDestination(transformedData);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDamageType = async () => {
        try {
            const res = await withRFSToken.post(GETDAMAGETYPE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setDamageType(innerData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchTypeCode();
        fetchOwnerCode();
        fetchCondCode();
        fetchDamageType();
        fetchDestination();
    }, []);

    const exportToExcel = async () => {
        try {
            const res = await withRFSToken.post(DOWNLOADULD, { tab_name: download_sec, fields: downloadData })
                .then(function (res) {
                    if (res.data.response_code == 200) {
                        const fileUrl = res.data.response_data;
                        // Create a temporary anchor element
                        const link = document.createElement('a');
                        link.href = fileUrl;

                        // Extract the filename from the URL if needed
                        const filename = fileUrl.split('/').pop(); // Gets the last part of the URL

                        // Set the download attribute with the filename
                        link.setAttribute('download', filename);

                        // Append the anchor to the body (necessary for the click to work in Firefox)
                        document.body.appendChild(link);

                        // Programmatically click the anchor to trigger the download
                        link.click();

                        // Clean up by removing the anchor from the DOM
                        document.body.removeChild(link);
                        handleModalClose();
                    } else {
                        toast.error(res.data.response_msg)
                    }

                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        onClose();
    };
    return (
        <>{console.log('222', getCondCodes)}
            <Modal show={isModalOpen} onHide={handleModalClose} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3>Download Filter</h3>
                    <p>Download the document as a selected option</p>
                    <div className='my-4 '>
                        {['Active', 'Archived', 'operations', 'Loan'].includes(download_sec) ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12" className='mb-2'>
                                        <Form.Label>Condition Code</Form.Label>
                                        <Select
                                            options={getCondCodes}
                                            placeholder="Condition Code"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, condition_code: selectedValues });
                                            }}
                                            isMulti
                                            name="condition_code"
                                            value={options.value}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {['Active', 'Archived', 'operations', 'Damaged'].includes(download_sec) ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>Status</Form.Label>
                                        <Select
                                            options={[
                                                { value: '1', label: 'In Operation' },
                                                { value: '3', label: 'In Reserve' },
                                                { value: '4', label: 'On Air' },
                                                { value: '2', label: 'Repair' },
                                            ]}
                                            placeholder="Status"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, status: selectedValues });
                                                // setDownloadData({ ...downloadData, status: selected.value });
                                            }}
                                            isMulti
                                            name="status"
                                            value={options.value}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {download_sec !== 'oa' ?
                            <>
                                <Form.Group as={Row} >
                                    <Col sm="12" className='mb-2'>
                                        <Form.Label>Type Code</Form.Label>
                                        <Select
                                            options={getTypeCodes}
                                            // onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                                            placeholder="Type Code"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, type_code: selectedValues });
                                                // setDownloadData({ ...downloadData, type_code: selected.value });
                                            }}
                                            isMulti
                                            name="type_code"
                                            value={options.value}
                                        // isDisabled={section.reg == 'R' ? true : false}
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <Form.Label>Owner Code</Form.Label>
                                        <Select
                                            options={getOwnerCodes}
                                            // onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                                            placeholder="Owner Code"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, owner_code: selectedValues });
                                                // setDownloadData({ ...downloadData, owner_code: selected.value });
                                            }}
                                            isMulti
                                            name="owner_code"
                                            value={options.value}
                                        // isDisabled={section.reg == 'R' ? true : false}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {download_sec == 'oa' ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>Flight Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name='flight_date'
                                            onChange={(e) => setDownloadData({ ...downloadData, flight_date: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {['Active', 'Archived', 'operations', 'oa', 'Repair', 'Damaged'].includes(download_sec) ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12" className='mb-2'>
                                        <Form.Label>Origin</Form.Label>
                                        <Select
                                            options={getDestination}
                                            // onChange={(selected) => {
                                            //     const selectedDestination = getDestination.find(option => option.value === selected.value);

                                            // }}
                                            placeholder="Origin"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, origin: selectedValues });
                                                // setDownloadData({ ...downloadData, origin: selected.value });
                                            }}
                                            isMulti
                                            name="origin"
                                            value={options.value}
                                        />
                                    </Col>
                                    <Col sm="12" >
                                        <Form.Label>Destination</Form.Label>
                                        <Select
                                            options={getDestination}
                                            // onChange={(selected) => {
                                            //     const selectedDestination = getDestination.find(option => option.value === selected.value);

                                            // }}
                                            placeholder="Destination"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, destination: selectedValues });
                                                // setDownloadData({ ...downloadData, destination: selected.value });
                                            }}
                                            isMulti
                                            name="destination"
                                            value={options.value}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {download_sec == 'Repair' ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>Repair In</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name='repair_in'
                                            onChange={(e) => setDownloadData({ ...downloadData, repair_in: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {['Repair', 'Damaged'].includes(download_sec) ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>Damage Type</Form.Label>
                                        <Select
                                            options={damageType}
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, damage_type: selectedValues });
                                                // setDownloadData({ ...downloadData, destination: selected.value });
                                            }}
                                            placeholder="Damage Type"
                                            className='down_selct'
                                            name="damage_type"
                                            value={options.value}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {download_sec == 'Discarded' ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>Discarded Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name='repair_in'
                                            onChange={(e) => setDownloadData({ ...downloadData, repair_in: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }
                        {download_sec == 'Loan' ?
                            <>
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm="12">
                                        <Form.Label>LUC Out</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name='luc_out'
                                            onChange={(e) => setDownloadData({ ...downloadData, luc_out: e.target.value })}
                                        />
                                    </Col>
                                    <Col sm="12" className='mb-2'>
                                        <Form.Label>Transfer Point</Form.Label>
                                        <Select
                                            options={getDestination}
                                            // onChange={(selected) => {
                                            //     const selectedDestination = getDestination.find(option => option.value === selected.value);

                                            // }}
                                            placeholder="Transfer Point"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, transfer_point: selectedValues });
                                                // setDownloadData({ ...downloadData, origin: selected.value });
                                            }}
                                            isMulti
                                            name="transfer_point"
                                            value={options.value}
                                        />
                                    </Col>
                                    <Col sm="12" className='mb-2'>
                                        <Form.Label>Final Destination</Form.Label>
                                        <Select
                                            options={getDestination}
                                            // onChange={(selected) => {
                                            //     const selectedDestination = getDestination.find(option => option.value === selected.value);

                                            // }}
                                            placeholder="Final Destination"
                                            className='down_selct'
                                            onChange={(selected) => {
                                                const selectedValues = selected ? selected.map(option => option.value) : [];
                                                setDownloadData({ ...downloadData, final_dest: selectedValues });
                                                // setDownloadData({ ...downloadData, origin: selected.value });
                                            }}
                                            isMulti
                                            name="final_dest"
                                            value={options.value}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                            : ''
                        }







                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={exportToExcel}>
                        Download
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DownloadGrid;