import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Struck from "../../../assets/images/struck.png";
import {faBoxArchive} from '@fortawesome/free-solid-svg-icons';

const TopMenu = () => {
    const location = useLocation();
    
    const navItems = [
        { name: 'ULD Control', path: '/ULDControl', icon: faBoxArchive  },
        { name: 'History', path: '/History', icon: faBoxArchive  },
        { name: 'Consumables', path: '/', icon: faBoxArchive  },
        { name: 'Recovery', path: '/', icon: faBoxArchive  },
        
        // Add more items with respective paths as needed
      ];

      // const navItemsCharts = [
      //   { name: 'Cargo \nDamage', path: '/CargoDamage', icon: faBoxArchive  },
      //   { name: 'ULD \nDamage', path: '/UldDamage', icon: faBoxArchive },
      //   { name: 'Cargo \nClaim', path: '/CargoClaim', icon: faBoxArchive  },
      //   { name: 'Servic \nRecovery', path: '/ServiceRecovery', icon: faBoxArchive },
      //   { name: 'Email \nSettings', path: '/EmailSettings', icon: faBoxArchive },
        
        
      //   // Add more items with respective paths as needed
      // ];
      // console.log("🚀 ~ TopMenu ~ location.pathname:", location.pathname);

      const renderTopNavItems = () => {
        // if (location.pathname === '/BookingtruckSchedule') {
          return navItems.map((item) => (
            <li key={item.name} className="nav-item">
              {/* {console.log("🚀 ~ TopMenu ~ item.path:", item.path)} */}
            <Link
              to={item.path}
              className={`nav-link ${location.pathname === item.path ? 'active' : ''} nav-link`}
            >
              <span>
                <img src={Struck} alt='' />
              </span>
              <span>
                {item.name}
                {/* {item.name.split('\n').map((text, i) => (
                  <span key={i}>
                    {text}
                     {i !== item.name.split('\n').length - 1 && <br />} 
                  </span>
                ))} */}
              </span>
            </Link>
          </li>
          ));
        // } else if (location.pathname === '/kpicharts') {
        //   return navItemsCharts.map((item) => (
        //     <li key={item.name} className="nav-item">
        //     <Link
        //         to={item.path}
        //         className={`nav-link ${location.pathname === item.path ? 'active' : ''} nav-link`}
        //     >
        //         <span>
        //         <FontAwesomeIcon icon={item.icon} />
        //         </span>
        //         <span>
        //         {item.name.split('\n').map((text, i) => (
        //             <span key={i}>
        //             {text}
        //             {i !== item.name.split('\n').length - 1 && <br />}
        //             </span>
        //         ))}
        //         </span>
        //     </Link>
        //     </li>
        //   ));
        // }
        // Add more conditions for different left menu pages if needed
    
        // Default return if the current route doesn't match any conditions
        // return null;
      };

  return (
    <>
    <ul className="mb-3 nav nav-tabs top-nav">
    {renderTopNavItems()}
    {/* {navItems.map((item) => (
        <li key={item.name} className="nav-item">
            <Link to={item.path} className={`nav-link ${location.pathname === item.path ? 'active' : ''} nav-link`}>
            
            <span><FontAwesomeIcon icon={item.icon} /></span>
            <span>
            
            {item.name.split('\n').map((text, i) => (
            <span key={i}>
                {text}{i !== item.name.split('\n').length - 1 && <br />}
                </span>
                ))}
                </span>
            
            </Link>
        </li>
    ))} */}
    </ul>
    </>
  )
}

export default TopMenu;