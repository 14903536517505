import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Trash from "../../../../../assets/images/trash.png";
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPlus, faGear, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Docmnt from "../../../../../assets/images/file.svg";

import { withoutRFSToken, withRFSToken } from '../../../../../Services/axios.js';
import { GETTYPECODE, GETOWNERCODE, GETCONDITIONCODE, GETDAMAGETYPE, GETDESTINATION, ULDREPAIRUPDATE, ULDALLUPDATE, GETCONTENTCODE, STTRUPDATE } from '../../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const Details = ({ uldData }) => {

  // const queryParameters = new URLSearchParams(window.location.search);
  // const type = queryParameters.get("action");
  // const uldids = queryParameters.get("ids");

  const actionTypes = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
  let keyType = null;
  // Loop through the actionTypes array
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    // Check if the current key matches any of the action keyTypes
    if (actionTypes.includes(key)) {
      keyType = key;
      break;
    }
  }
  const isMounted = useRef(false);
  const [getTypeCodes, setGetTypeCodes] = useState([]);
  const [getOwnerCodes, setGetOwnerCode] = useState([]);
  const [getContentCodes, setGetContentCode] = useState([]);
  const [getCondCodes, setGetCondCode] = useState([]);
  const [damageType, setDamageType] = useState([]);
  const [getDestination, setGetDestination] = useState([]);

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    // Retrieve the data from localStorage ON LOAD
    const keys = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];

    let storedData = null;
    // Iterate over the keys and retrieve the first non-null value from localStorage
    for (const key of keys) {
      storedData = localStorage.getItem(key);
      if (storedData) break;
    }
    const parsedData = storedData ? JSON.parse(storedData) : null;

    // Set the ULD data to the state
    if (parsedData && parsedData.ULD) {
      setFormData(parsedData.ULD);
    }


  }, []);
  const fetchDataFromLocalStorage = () => {
    const keys = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];

    let storedData = null;
    // Iterate over the keys and retrieve the first non-null value from localStorage
    for (const key of keys) {
      storedData = localStorage.getItem(key);
      if (storedData) break;
    }
    const parsedData = storedData ? JSON.parse(storedData) : null;
    console.log('888', parsedData);
    // Set the ULD data to the state
    if (parsedData && parsedData.ULD) {
      setFormData(parsedData.ULD);
    }
  };
  useEffect(() => {
    // Initial fetch of data
    // fetchDataFromLocalStorage();
    // Event listener for localStorage updates
    const handleLocalStorageUpdate = () => {
      fetchDataFromLocalStorage();
    };

    window.addEventListener('localStorageUpdated', handleLocalStorageUpdate);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('localStorageUpdated', handleLocalStorageUpdate);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await withRFSToken.post(GETTYPECODE, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            const transformedData = innerData ? innerData.map(item => ({
              value: item.uld_typecode,
              label: item.uld_typecode
            })) : [];
            setGetTypeCodes(transformedData);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchContentCode = async () => {
      try {
        const res = await withRFSToken.post(GETCONTENTCODE, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // const transformedData = innerData ? innerData.map(item => ({
            //   value: item.IATA,
            //   label: item.IATA
            // })) : [];
            setGetContentCode(innerData);
          });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchOwnerCode = async () => {
      try {
        const res = await withRFSToken.post(GETOWNERCODE, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            const transformedData = innerData ? innerData.map(item => ({
              value: item.IATA,
              label: item.IATA
            })) : [];
            setGetOwnerCode(transformedData);
          });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchCondCode = async () => {
      try {

        const res = await withRFSToken.post(GETCONDITIONCODE, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            setGetCondCode(innerData);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchDamageType = async () => {
      try {
        const res = await withRFSToken.post(GETDAMAGETYPE, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            // console.log("🚀 ~ .then ~ innerData:654", innerData)
            setDamageType(innerData);
          });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    const fetchDestination = async () => {
      try {

        const res = await withRFSToken.post(GETDESTINATION, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            const transformedData = innerData ? innerData.map(item => ({
              value: item.port_code,
              label: item.port_code,
            })) : [];
            setGetDestination(transformedData);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // Call the fetchData function to fetch data from the API
    if (isMounted.current) return;
    fetchData();
    fetchOwnerCode();
    fetchContentCode();
    fetchCondCode();
    fetchDamageType();
    fetchDestination();
    isMounted.current = true;
  }, []);

  const removeDetails = (id) => {
    setFormData(prevFormData => prevFormData.filter(stack => stack.uld_number !== id));
    const scmData = JSON.parse(localStorage.getItem(keyType)) || { ULD: [] };
    scmData.ULD = scmData.ULD.filter(item => item.uld_number !== id);
    localStorage.setItem(keyType, JSON.stringify(scmData));
  }

  // const handleDetailsChange = (id, field, value) => {
  //   setFormData(prevFormData =>
  //     prevFormData.map(stack =>
  //       stack.uld_number === id ? { ...stack, [field]: value } : stack
  //     )
  //   );
  //   // working 24.05.23
  // };
  const handleDetailsChange = (id, fieldName, value) => {
    console.log("🚀 ~ handleDetailsChange ~ id:", id)
    setFormData(prevFormData =>
      prevFormData.map(stack => {
        if (stack.uld_number === id) {
          const updatedStack = { ...stack, [fieldName]: value };
          updatedStack.uld_number = `${updatedStack.type_code || ''}${updatedStack.serial_number || ''}${updatedStack.owner_code || ''}`;

          const keyData = JSON.parse(localStorage.getItem(keyType)) || { ULD: {} };
          keyData.ULD = keyData.ULD.map(item => item.uld_number === id ? updatedStack : item);
          localStorage.setItem(keyType, JSON.stringify(keyData));

          return updatedStack;
        }
        return stack;
      })
    );
    console.log('123', formData);
  };

  const [showModal, setShowModal] = useState(false);
  const [modalRadioValue, setModalRadioValue] = useState({ assetType: '' });
  const [modalContent, setModalContent] = useState('');
  const handleModalClose = () => { setShowModal(false); setModalRadioValue('') };

  const handleModalOpen = (contentType) => {
    setModalContent(contentType);
    setShowModal(true);
  };
  const handleConfirmation = (confirm) => {
    if (confirm) {
      setShowModal(false);
      window.location.href = '/ULDControl'
    } else {
      setShowModal(false);
    }
  }
  const handleModalRadioChange = (event) => {
    const { name, value } = event.target;
    setModalRadioValue({ [name]: value });
  };

  const handleModalUpdate = () => {
    const updatedAssetType = modalRadioValue.assetType === 'O' ? false : true;
    // const updatedFormData = formData.map(stack => ({
    //     ...stack,
    //     asset_type: updatedAssetType
    //   }))
    const updatedFormData = formData.map(stack => {
      console.log("🚀 ~ updatedFormData ~ stack:", stack)
      if (stack.regd == 'R') {
        return {
          ...stack,
          asset_type: updatedAssetType
        };
      }
      return stack; // Return unchanged rows
    });

    setFormData(updatedFormData);
    const keyData = JSON.parse(localStorage.getItem(keyType)) || { ULD: {} };
    keyData.ULD = updatedFormData;
    localStorage.setItem(keyType, JSON.stringify(keyData));
    setModalRadioValue('')
    setShowModal(false);
  };

  const validateFormData = () => {
    let isValid = true;

    // Retrieve data from local storage
    const storedData = JSON.parse(localStorage.getItem(keyType));

    if (!storedData) {
      toast.error('No data found');
      return false;
    }

    // Validate Form fields
    if (keyType == 'Repair In') {
      const form = storedData.Form;
      if (!form.repair_station) {

        toast.error('Please set Repair Station');
        isValid = false;
      } else if (!form.repair_in) {
        toast.error('Please set Repair In');
        isValid = false;
      }
    } else if (keyType == 'Repair Out') {
      const form = storedData.Form;
      if (!form.repair_station) {

        toast.error('Please set Repair Station');
        isValid = false;
      } else if (!form.repair_out) {
        toast.error('Please set Repair Out');
        isValid = false;
      }
    } else if (keyType == 'UCM In' || keyType == 'UCM Out') {
      const form = storedData.Form;
      if (!form.flight_code) {

        toast.error('Please set Flight Number');
        isValid = false;
      } else if (!form.flight_number) {

        toast.error('Please set Flight Number');
        isValid = false;
      } else if (!form.flight_date) {

        toast.error('Please set Flight Date');
        isValid = false;
      } else if (!form.origin) {

        toast.error('Please set Origin');
        isValid = false;
      } else if (!form.destination) {

        toast.error('Please set Destination');
        isValid = false;
      }
    }

    // Validate ULD array
    // const ulds = storedData.ULD;
    // if (ulds && ulds.length > 0) {
    //   for (let i = 0; i < ulds.length; i++) {
    //     const uld = ulds[i];
    //     if (!uld.type_code) {
    //       toast.error('Please set Type Code');
    //       isValid = false;
    //       break;
    //     }

    //     if (!uld.owner_code) {
    //       toast.error('Please set Owner Code');
    //       isValid = false;
    //       break;
    //     }
    //     if (!uld.condition_code) {
    //       toast.error('Please set Condition Code');
    //       isValid = false;
    //       break;
    //     }
    //   }
    // } else {
    //   toast.error('ULD data is missing or empty');
    //   isValid = false;
    // }
    return isValid;
  };

  const updateData = async () => {
    if (validateFormData()) {

      try {
        // Retrieve data from local storage
        const mvtData = JSON.parse(localStorage.getItem(keyType));
        if (mvtData) {
          if (['Repair In', 'Repair Out'].includes(keyType)) {
            const actiontype = keyType == 'Repair In' ? 'repair_in' : (keyType == 'Repair Out' ? 'repair_out' : keyType);
            const dataToSend = { ...mvtData, action: actiontype };
            const res = await withRFSToken.post(ULDREPAIRUPDATE, JSON.stringify(dataToSend));

            if (res.data.response_code == 200) {
              localStorage.removeItem(keyType);
              const backUrl = '/ULDControl';
              window.location.href = backUrl;

            } else {
              toast.error(res.data.response_msg);
            }

          } else if (['Transfer', 'Request'].includes(keyType)) {
            const keyTypeMapping = {
              'Transfer': 'ST',
              'Request': 'TR',
            };
            const actiontype = keyTypeMapping[keyType] || keyType;
            const dataToSend = { ...mvtData, transfer_type: actiontype };
            const res = await withRFSToken.post(STTRUPDATE, JSON.stringify(dataToSend));

            if (res.data.response_code == 200) {
              localStorage.removeItem(keyType);
              const backUrl = '/ULDControl';
              window.location.href = backUrl;

            } else {
              toast.error(res.data.response_msg);
            }
          } else {
            const keyTypeMapping = {
              'Lease Out': 'LEO',
              'SCM': 'SCM',
              'UCM Out': 'UO',
              'UCM In': 'UI',
              'LUC In': 'LI',
              'LUC Out': 'LO',
            };
            const actiontype = keyTypeMapping[keyType] || keyType;
            // const actiontype = keyType == 'Repair In' ? 'repair_in' : (keyType == 'Repair Out' ? 'repair_out' : keyType);
            const dataToSend = { ...mvtData, action: actiontype };
            console.log("🚀 ~ updateData ~ dataToSend:", dataToSend)
            const res = await withRFSToken.post(ULDALLUPDATE, JSON.stringify(dataToSend));

            if (res.data.response_code == 200) {
              localStorage.removeItem(keyType);
              const backUrl = '/ULDControl';
              window.location.href = backUrl;

            } else {
              toast.error(res.data.response_msg);
            }

          }

        }
      } catch (error) {
        console.error('Error while sending data to the API:', error);
      }
    }
  }

  // upload Section
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [base64Files, setBase64Files] = useState([]);

  // const imageModalUpload = (id) => {
  //     setSelectedRowId(id);
  //     setShowModalUpload(true)
  //     setFiles([])
  // }
  const imageModalUpload = (id) => {
    console.log('aaaa', id);
    const selectedRowFiles = formData.find(item => item.uld_number === id)?.files || [];
    console.log("🚀 ~ imageModalUpload ~ selectedRowFiles:", selectedRowFiles)
    setFiles(selectedRowFiles);
    setSelectedRowId(id); // Store the ID of the selected row
    setShowModalUpload(true);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    getBase64(droppedFiles[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    base64Files.splice(index, 1);
  };

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithBase64 = [];

    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        filesWithBase64.push({ file, base64: reader.result });

        if (filesWithBase64.length === selectedFiles.length) {
          setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
          setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const imageUpload = () => {
    setFormData(prevFormData =>
      prevFormData.map(item => {
        if (item.uld_number === selectedRowId) {
          return { ...item, files: files, base64Files: base64Files };
        }
        return item;
      })
    );
    setShowModalUpload(false);
  };

  return (
    <>{console.log('2000', formData)}
      <div className='mb-3 nestedtabs rounded details_h'>
        <div className='d-flex justify-content-between align-items-center charter_top'>
          <div className='d-flex align-items-center'><h3 className='mb-0'>ULD Details</h3>  <span className='selected'>Selected ULD's <Badge>{formData.length}</Badge> </span></div>
          <div className='alltype'>
            {keyType !== 'Repair In' && keyType !== 'Repair Out' ?
              (<><button keyType="button" className="btn btn-outline-secondary" onClick={() => handleModalOpen('assetTypeChange')}> <FontAwesomeIcon icon={faGear} /> All Asset Type </button></>) : (<></>)}
          </div>
        </div>
        {keyType == 'Repair In' || keyType == 'Repair Out' ? (
          <>
            {console.log('555', formData)}
            <div className='table-responsive repar_h'>
              <Table className='uld_details ra_repair'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial Number</th>
                    <th>Owner Code</th>
                    {/* <th>Condition</th> */}
                    <th className='wdht'>Damage Type</th>
                    <th>Damage Desc.</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.flatMap((section, index) => {
                    return (
                      <tr key={index}>{console.log('666', section)}
                        <td><Form.Control type="text" readOnly value={section.uld_number} onChange={(e) => handleDetailsChange(section.uld_number, 'uld_number', e.target.value)} /></td>
                        <td>
                          {/* <Form.Select aria-label="Default" value={section.type_code}>
                            {getTypeCodes &&
                              getTypeCodes.map((option, index) =>
                                <option key={index} value={option.uld_typecode}>{option.uld_typecode}</option>
                              )
                            }
                          </Form.Select> */}
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={section.reg == 'R' ? true : false}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} onChange={(e) => handleDetailsChange(section.uld_number, 'serial_number', e.target.value)} /></td>
                        <td>
                          {/* <Form.Select aria-label="Default" value={section.owner_code}>
                            {getOwnerCodes &&
                              getOwnerCodes.map((option, index) =>
                                <option key={index} value={option.IATA}>{option.IATA}</option>
                              )
                            }
                          </Form.Select> */}
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={section.reg == 'R' ? true : false}
                          />
                        </td>
                        {/* <td>
                          <Form.Select 
                          aria-label="Default" 
                          value={section.condition_code == 'Damaged' ? 'DAM' : (section.condition_code == 'Discarded' ? 'DIS' : 'SER')} 
                          onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}
                          >
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td> */}
                        <td>
                          {/* <Form.Select aria-label="Default">
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select> */}
                          <Select
                            options={damageType}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'damage_type', e.value)}
                            placeholder="Damage Type"
                            className='autoslct'
                            name="damage_type"
                            value={damageType.find(option => option.value === section.damage_type)}
                          />
                        </td>
                        <td><Form.Control type="text" name='damage_desc' value={section.damage_desc} onChange={(e) => handleDetailsChange(section.uld_number, 'damage_desc', e.target.value)} /></td>
                        <td className='inbox text-center'><Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(section.uld_number)}>Upload file</Button></td>
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </>
        ) : (keyType == 'Lease Out' ? (
          <>
            <div className='table-responsive repar_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner Code</th>
                    {/* <th>Content</th>
                    <th>Final Dest.</th> */}
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} onChange={(e) => handleDetailsChange(section.uld_number, 'uld_number', e.target.value)} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={section.reg == 'R' ? true : false}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} onChange={(e) => handleDetailsChange(section.uld_number, 'serial_number', e.target.value)} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={section.reg == 'R' ? true : false}
                          />
                        </td>
                        {/* <td>
                          <Form.Control type="text" value={section.content_code} name="content_code" onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.target.value)} />
                        </td>
                        <td>
                          <Select
                            options={getDestination}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'destination', e.value)}
                            placeholder="Destination"
                            className='autoslct'
                            name="destination"
                            value={getTypeCodes.find(option => option.value === section.destination)}
                          />
                        </td> */}
                        <td>
                          <Form.Select
                            aria-label="Default"
                            value={section.condition_code == 'Damaged' ? 'DAM' : (section.condition_code == 'Discarded' ? 'DIS' : 'SER')}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}
                          >
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {section.reg && section.reg === 'R' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className='chart_switch'>
                                  <div className="switch6">
                                    <label className="switch6-light">
                                      <input type="checkbox"
                                        name="asset_type"
                                        checked={section.asset_type}
                                        onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                      <span>
                                        <span>Owned</span>
                                        <span>Lease</span>
                                      </span>
                                      <a className="btn btn-primary"></a>
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </>
                          )
                        }

                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </>
        ) : (keyType == 'LUC In' ? (
          <>
            <div className='table-responsive luc_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner Code</th>
                    {/* <th>Content</th> */}
                    {/* <th>Final Dest.</th> */}
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>

                        <td>
                          <Form.Select aria-label="Default" value={section.condition_code} name="condition_code" onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}>
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {section.reg === 'R' || section.register == 'Y' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' || section.register == 'Y' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {section.regd == 'R' &&
                                  <div className='chart_switch'>
                                    <div className="switch6">
                                      <label className="switch6-light">
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>
                                }
                              </td>
                            </>
                          )
                        }

                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>

              {/* <div className='text-center mt-5'>
                <Button variant="light" className='stk_cancel'>CANCEL</Button>
                <Button variant="primary" className='stk_save'>UPDATE</Button>
              </div> */}
            </div>
          </>
        ) : (keyType == 'LUC Out' ? (
          <>
            <div className='table-responsive luc_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner Code</th>
                    {/* <th>Content</th> */}
                    {/* <th>Final Dest.</th> */}
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          {/* <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                          /> */}
                          <Select
                            options={getTypeCodes}
                            onChange={(selected) => {
                              const updatedData = formData.uldInfo.map(item => {
                                if (item.id === section.id) {
                                  return { ...item, type_code: selected.value };
                                }
                                return item;
                              });
                              setFormData({ ...formData, uldInfo: updatedData });
                            }}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          {/* <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                          /> */}
                          <Select
                            options={getOwnerCodes}
                            onChange={(selected) => {
                              const updatedData = formData.uldInfo.map(item => {
                                if (item.id === section.id) {
                                  return { ...item, owner_code: selected.value };
                                }
                                return item;
                              });
                              setFormData({ ...formData, uldInfo: updatedData });
                            }}
                            placeholder="Owner"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>

                        {/* <td>
                          <Form.Control type="text" value={section.content_code} name="content_code" onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.target.value)} />
                        </td> */}
                        {/* <td>
                          <Select
                            options={getDestination}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'luc_final_dest', e.value)}
                            placeholder="Destination"
                            className='autoslct'
                            name="luc_final_dest"
                            value={getDestination.find(option => option.value === section.luc_final_dest)}
                          />
                        </td> */}
                        <td>
                          <Form.Select aria-label="Default" value={section.condition_code} name="condition_code" onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}>
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {/* <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td> */}
                        {/* <td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered </td>
                    <td className='rayes'>

                      <div className="can-toggle">
                        <input id="a" type="checkbox" />
                        <label htmlFor="a">
                          <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                        </label>
                      </div>
                    </td> */}
                        {/* {section.reg && section.reg === 'R' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                            </>
                          )
                        }
                        <td>
                          <div className='chart_switch'>
                            <div className="switch6">
                              <label className="switch6-light">
                                <input type="checkbox"
                                  name="asset_type" />
                                <span>
                                  <span>Owned</span>
                                  <span>Lease</span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                          </div>
                        </td> */}
                        {section.reg === 'R' || section.register == 'Y' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' || section.register == 'Y' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {section.regd == 'R' &&
                                  <div className='chart_switch'>
                                    <div className="switch6">
                                      <label className="switch6-light">
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>
                                }
                              </td>
                            </>
                          )
                        }
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>

              {/* <div className='text-center mt-5'>
                <Button variant="light" className='stk_cancel'>CANCEL</Button>
                <Button variant="primary" className='stk_save'>UPDATE</Button>
              </div> */}
            </div>
          </>
        ) : (keyType == 'SCM' ? (
          <>
            <div className='table-responsive repar_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner</th>
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <Form.Select
                            aria-label="Default"
                            value={section.condition_code == 'Damaged' ? 'DAM' : (section.condition_code == 'Discarded' ? 'DIS' : 'SER')}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}
                          >
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {/* <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td> */}
                        {section.reg === 'R' || section.register == 'Y' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' || section.register == 'Y' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {section.regd == 'R' &&
                                  <div className='chart_switch'>
                                    <div className="switch6">
                                      <label className="switch6-light">
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>
                                }
                              </td>
                            </>
                          )
                        }
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>

              {/* <div className='text-center mt-5'>
                <Button variant="light" className='stk_cancel'>CANCEL</Button>
                <Button variant="primary" className='stk_save'>UPDATE</Button>
              </div> */}
            </div>
          </>
        ) : (keyType == 'UCM In' ? (
          <>
            <div className='table-responsive ucm_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner</th>
                    <th>Content</th>
                    {/* <th>Airline Name</th> */}
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    console.log("🚀 ~ {formData&&formData.map ~ section:", section)
                    return (
                      <tr>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <Select
                            options={getContentCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.value)}
                            placeholder="Content"
                            className='autoslct'
                            name="content_code"
                            value={getContentCodes.find(option => option.value === section.content_code)}
                          // isDisabled={true}
                          />
                          {/* <Form.Control type="text" name='content_code' onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.target.value)} /> */}
                        </td>
                        {/* <td>
                          <Form.Control type="text" readOnly />
                        </td> */}
                        <td>
                          <Form.Select
                            aria-label="Default"
                            value={section.condition_code} // == 'Damaged' ? 'DAM' : (section.condition_code == 'Discarded' ? 'DIS' : 'SER')}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}
                          >
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {section.reg === 'R' || section.register == 'Y' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' || section.register == 'Y' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {section.regd == 'R' &&
                                  <div className='chart_switch'>
                                    <div className="switch6">
                                      <label className="switch6-light">
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>
                                }
                              </td>
                            </>
                          )
                        }
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>

              {/* <div className='text-center mt-5'>
                <Button variant="light" className='stk_cancel'>CANCEL</Button>
                <Button variant="primary" className='stk_save'>UPDATE</Button>
              </div> */}
            </div>
          </>
        ) : (keyType == 'UCM Out' ? (
          <>
            <div className='table-responsive ucm_h'>
              <Table className='uld_details'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner</th>
                    <th>Content</th>
                    <th>Final Dest.</th>
                    <th>Condition</th>
                    <th className='non_reg'>ULD Asset</th>
                    <th>Register</th>
                    <th>Asset Type </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(selected) => {
                              const updatedData = formData.uldInfo.map(item => {
                                if (item.id === section.id) {
                                  return { ...item, type_code: selected.value };
                                }
                                return item;
                              });
                              setFormData({ ...formData, uldInfo: updatedData });
                            }}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(selected) => {
                              const updatedData = formData.uldInfo.map(item => {
                                if (item.id === section.id) {
                                  return { ...item, owner_code: selected.value };
                                }
                                return item;
                              });
                              setFormData({ ...formData, uldInfo: updatedData });
                            }}
                            placeholder="Owner"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <Select
                            options={getContentCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.value)}
                            placeholder="Content"
                            className='autoslct'
                            name="content_code"
                            value={getContentCodes.find(option => option.value === section.content_code)}
                          // isDisabled={true}
                          />
                          {/* <Form.Control type="text" name='content_code' onChange={(e) => handleDetailsChange(section.uld_number, 'content_code', e.target.value)} /> */}
                        </td>
                        <td>
                          <Select
                            options={getDestination}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'final_dest', e.value)}
                            placeholder="Destination"
                            className='autoslct'
                            name="final_dest"
                            value={getDestination.find(option => option.value === section.final_dest)}
                          />
                        </td>
                        <td>
                          <Form.Select aria-label="Default" value={section.condition_code} onChange={(e) => handleDetailsChange(section.uld_number, 'condition_code', e.target.value)}>
                            {getCondCodes &&
                              Object.entries(getCondCodes).map((option, index) =>
                                <option key={index} value={option[0]}>{option[1]}</option>
                              )
                            }
                          </Form.Select>
                        </td>
                        {section.reg === 'R' || section.register == 'Y' ?
                          (
                            <>
                              <td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td>
                              <td className='rayes'>

                                {/* <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_d`} type="checkbox"
                                    disabled
                                    // ={section.reg && section.reg === 'R'} 
                                    checked
                                  // ={section.reg && section.reg === 'R'} 
                                  />
                                  <label htmlFor={`a${section.uld_number}_d`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div> */}
                              </td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              {section.regd == 'R' || section.register == 'Y' ?
                                (<><td className='register'> <FontAwesomeIcon icon={faCircleCheck} /> Registered </td></>
                                ) : (<><td className='non_register'><FontAwesomeIcon icon={faCircleXmark} /> Non-Registered</td></>)}
                              <td className='rayes'>
                                <div className="can-toggle">
                                  <input
                                    id={`a${section.uld_number}_${index}`}
                                    type="checkbox"
                                    checked={section.regd === 'R'}
                                    onChange={(e) => handleDetailsChange(section.uld_number, 'regd', e.target.checked ? 'R' : false)} />
                                  <label htmlFor={`a${section.uld_number}_${index}`}>
                                    <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {section.regd == 'R' &&
                                  <div className='chart_switch'>
                                    <div className="switch6">
                                      <label className="switch6-light">
                                        <input type="checkbox"
                                          name="asset_type"
                                          checked={section.asset_type}
                                          onChange={(e) => handleDetailsChange(section.uld_number, 'asset_type', e.target.checked)} />
                                        <span>
                                          <span>Owned</span>
                                          <span>Lease</span>
                                        </span>
                                        <a className="btn btn-primary"></a>
                                      </label>
                                    </div>
                                  </div>
                                }
                              </td>
                            </>
                          )
                        }
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>

              {/* <div className='text-center mt-5'>
                <Button variant="light" className='stk_cancel'>CANCEL</Button>
                <Button variant="primary" className='stk_save'>UPDATE</Button>
              </div> */}
            </div>
          </>
        ) : (keyType == 'Transfer' ? (
          <>
            <div className='table-responsive luc_h'>
              <Table className='uld_details transer_tble'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
            </div>
          </>
        ) : (keyType == 'Request' ? (
          <>
            <div className='table-responsive luc_h'>
              <Table className='uld_details transer_tble'>
                <thead>
                  <tr>
                    <th>ULD Number</th>
                    <th>Type Code</th>
                    <th>Serial No</th>
                    <th>Owner Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formData && formData.map((section, index) => {
                    return (
                      <tr key={index}>
                        <td><Form.Control type="text" readOnly value={section.uld_number} /></td>
                        <td>
                          <Select
                            options={getTypeCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'type_code', e.value)}
                            placeholder="Type Code"
                            className='autoslct'
                            name="type_code"
                            value={getTypeCodes.find(option => option.value === section.type_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td><Form.Control type="text" readOnly value={section.serial_number} /></td>
                        <td>
                          <Select
                            options={getOwnerCodes}
                            onChange={(e) => handleDetailsChange(section.uld_number, 'owner_code', e.value)}
                            placeholder="Owner Code"
                            className='autoslct'
                            name="owner_code"
                            value={getOwnerCodes.find(option => option.value === section.owner_code)}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <img src={Trash} onClick={() => removeDetails(section.uld_number)} />
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <></>
        )))))))))}
        <div className='text-center mt-5'>
          <Button variant="light" className='stk_cancel' onClick={() => handleModalOpen('cancelForm')}>CANCEL</Button>
          <Button variant="primary" className='stk_save' onClick={updateData}>UPDATE</Button>
        </div>
      </div>
      <Modal show={showModal} onHide={handleModalClose} className='addfrom change_type' centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {modalContent === 'assetTypeChange' && (
            <>
              <h3>All Asset Type Changes</h3>
              <p>Are sure you want to change all asset type?</p>
              <div className='asset_midle'>
                <Form.Check
                  type='radio'
                  label='Owned'
                  name='assetType'
                  id='own'
                  value='O'
                  checked={modalRadioValue.assetType === 'O'}
                  onChange={handleModalRadioChange}
                />
                <Form.Check
                  type='radio'
                  label='Lease'
                  name='assetType'
                  id='lease'
                  value='L'
                  checked={modalRadioValue.assetType === 'L'}
                  onChange={handleModalRadioChange}
                />
              </div>
            </>
          )}
          {modalContent === 'cancelForm' && (
            <>
              <h3>All changes will be destroyed</h3>
              <p>If you proceed with the change, all data in this section will be removed.
                Are you sure you want to continue?</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {modalContent === 'assetTypeChange' ?
            (
              <>
                <Button variant='primary' onClick={handleModalUpdate}>
                  Update Asset Type
                </Button>
              </>
            ) : (
              <>
                <div className='d-flex w-50 mx-auto are_bottom'>
                  <Button variant="secondary" onClick={() => handleConfirmation(false)}>
                    No
                  </Button>
                  <Button variant="primary" onClick={() => handleConfirmation(true)}>
                    Yes
                  </Button>
                </div>
              </>
            )}
        </Modal.Footer>
      </Modal>
      <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)} className='addfrom change_type' centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className='type_work'>

            <div className='ra_condition'>
              <div
                className='dropfile'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <h3>Drag and drop files here</h3>
                <p>Or</p>
                <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
                <span>Maximum file size 4 MB</span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  multiple
                />
              </div>
              <div className='uploadbox'>
                {files.map((file, index) => (
                  <div key={index} className='uploadfil'>
                    {file.type.startsWith('image/') ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                      />
                    ) : (
                      <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                    )}
                    <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex w-50 mx-auto are_bottom'>
            <Button variant="primary" onClick={imageUpload}>
              Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Details