import React, { useState, useEffect, useRef } from 'react';
import Header from "../../../../Componenets/Header/Header";
import { MultiSelect } from 'react-multi-select-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import XLSFILE from '../../../../assets/Add_ULD.xlsx'
import Trash from "../../../../assets/images/trash.png";
import { faFileExport, faPlus, faGear, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import * as XLSX from 'xlsx';

import { withoutRFSToken, withRFSToken } from '../../../../Services/axios.js';
import { GETTYPECODE, GETDESTINATION, GETOWNERCODE, GETCONDITIONCODE, ULDADDRESSSAVE, SAVEULDSTOCK } from '../../../../Utils/conts.js';
import { toast } from 'react-toastify';

const NewStock = () => {


    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ];

    // const handleChange = (event, selectedOption) => {
    //     console.log(`Option selected:`, event, selectedOption);
    // };
    const isMounted = useRef(false);
    const [getTypeCodes, setGetTypeCodes] = useState([]);
    const [getDestination, setGetDestination] = useState([]);
    const [getOwnerCodes, setGetOwnerCode] = useState([]);
    const [getCondCodes, setGetCondCode] = useState([]);

    const [handlerNames, setHandlerNames] = useState([]);
    const [selectedHandler, setSelectedHandler] = useState(null);
    const [addressModalShow, setAddressModalShow] = useState(false);
    const addressModalClose = () => setAddressModalShow(false);
    const [inputAddress, setInputAddress] = useState({
        id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
    });
    const [address, setAddress] = useState([]);
    const [selectedStationId, setSelectedStationId] = useState(null)

    const fetchDestination = async () => {
        try {

            const res = await withRFSToken.post(GETDESTINATION, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    const transformedData = innerData ? innerData.map(item => ({
                        value: item.port_code,
                        label: item.port_code,
                        address: item.address,
                        station_id: item.station_id
                    })) : [];
                    setGetDestination(transformedData);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const res = await withRFSToken.post(GETTYPECODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        const transformedData = innerData ? innerData.map(item => ({
                            value: item.uld_typecode,
                            label: item.uld_typecode
                        })) : [];
                        setGetTypeCodes(transformedData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchOwnerCode = async () => {
            try {
                const res = await withRFSToken.post(GETOWNERCODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        const transformedData = innerData ? innerData.map(item => ({
                            value: item.IATA,
                            label: item.IATA
                        })) : [];
                        setGetOwnerCode(transformedData);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchCondCode = async () => {
            try {

                const res = await withRFSToken.post(GETCONDITIONCODE, {})
                    .then(function (res) {
                        const innerData = res.data.response_data;
                        setGetCondCode(innerData);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // Call the fetchData function to fetch data from the API
        if (isMounted.current) return;
        fetchData();
        fetchDestination();
        fetchOwnerCode();
        fetchCondCode();
        isMounted.current = true;
    }, []);



    const [showULDStack, setShowULDStack] = useState(false);
    const [formData, setFormData] = useState(
        {
            id: '1',
            status: '',
            destination: '', handler_name: '',
            // condition_code: '', type_code: '', serial_number: '', owner_code: '', airline_name: '', asset_type: false,
            uldInfo: [
                { id: '1', type_code: '', serial_number: '', owner_code: '', condition_code: 'SER', airline_name: '', asset_type: '', }
            ]
        }
    );

    const addULDStack = () => {
        const lastId = formData.uldInfo.length > 0 ? parseInt(formData.uldInfo[formData.uldInfo.length - 1].id) : 0;
        const newId = (lastId + 1).toString();
        setFormData(prevFormData => ({
            ...prevFormData,
            uldInfo: [
                ...prevFormData.uldInfo,
                {
                    // id: (prevFormData.uldStack.length + 1).toString(),
                    id: newId,
                    type_code: '',
                    serial_number: '',
                    owner_code: '',
                    condition_code: 'SER',
                    airline_name: '',
                    asset_type: false
                }
            ]
        }));
    };
    const removeULDStack = (uldInfoId) => {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                uldInfo: prevFormData.uldInfo.filter(stack => stack.id !== uldInfoId)
            };
        });
    };
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [name]: type === 'checkbox' ? checked : value };

            // If the field being changed is owner_code, update airline_name accordingly
            if (name === 'destination') {
                const selectedOwner = getDestination.find(owner => owner.port_code === value);
                if (selectedOwner) {
                    newFormData.airline_name = selectedOwner.carrierName;
                } else {
                    newFormData.airline_name = '';
                }
            }

            return newFormData;
        });
    };
    const handleULDInputChange = (uldInfoId, fieldName, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            uldInfo: prevFormData.uldInfo.map(stack => {
                if (stack.id === uldInfoId) {
                    const updatedStack = { ...stack, [fieldName]: value };
                    // If the field being changed is owner_code, update airline_name accordingly
                    // if (fieldName === 'owner_code') {
                    //     const selectedOwner = getOwnerCodes.find(owner => owner.IATA === value);
                    //     if (selectedOwner) {
                    //         updatedStack.airline_name = selectedOwner.carrierName;
                    //     } else {
                    //         updatedStack.airline_name = '';
                    //     }
                    // }

                    return updatedStack;
                }
                return stack;
            })
        }));
    };
    // console.log('0123', formData);
    // const validateFormData = () => {
    //     let isValid = true;
    //     if (!formData.status) {
    //         toast.error('Please set Status');
    //         isValid = false;
    //     } else if (!formData.destination) {
    //         toast.error('Please select Destination');
    //         isValid = false;
    //     } else if (!formData.boardpoint) {
    //         console.log('Text input is required');
    //         toast.error('Boardpoint must not be blank');
    //         isValid = false;
    //     } else {
    //         return isValid;
    //     }
    // };
    // ADD ADDRESS
    const [addressFor, setAddressFor] = useState('');
    const addressModalOpen = (addressType) => {
        setAddressFor(addressType);
        setAddressModalShow(true)
    }
    const handleAddressChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'regd') {
            setInputAddress({ ...inputAddress, [name]: checked ? '1' : '0' });
        } else {
            setInputAddress({ ...inputAddress, [name]: value });
        }
        console.log(inputAddress);
    };
    let destLabel;
    if (selectedStationId) {
        const idMatch = getDestination.find(option => option.station_id == selectedStationId);
        // console.log("🚀 ~ FormFuntion ~ idMatch:", idMatch)
        destLabel = idMatch.label;
    }

    const validateAddressData = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Function to validate email
        const validateEmail = (email) => {
            return emailRegex.test(email);
        };
        if (!inputAddress.company_name) {
            console.log('Text input is required');
            toast.error('Company Name must not be blank');
            isValid = false;
        } else if (!inputAddress.agent_or_handler) {
            console.log('Text input is required');
            toast.error('Please select Agent or Handler');
            isValid = false;
        }
        // else if (!inputAddress.address) {
        //     console.log('Text input is required');
        //     toast.error('Address must not be blank');
        //     isValid = false;
        // } else if (!inputAddress.phone_number) {
        //     console.log('Text input is required');
        //     toast.error('Phone Number must not be blank');
        //     isValid = false;
        // } else if (!/^\d{10}$/.test(inputAddress.phone_number)) {
        //     console.log('10');
        //     toast.error('Phone Number must be 10 Digits');
        //     isValid = false;
        // } else if (!inputAddress.email) {
        //     console.log('Text input is required');
        //     toast.error('Email must not be blank');
        //     isValid = false;
        // } else if (!validateEmail(inputAddress.email)) {
        //     console.log('Invalid email address');
        //     toast.error('Invalid email address');
        //     isValid = false;
        // }
        else {
            return isValid;
        }
    };

    const addAddress = async (e) => {
        console.log('444', inputAddress);
        if (validateAddressData()) {
            e.preventDefault();
            // const newAddressID = Math.floor(Math.random() * 1000000);
            const stationid = selectedStationId;
            const NewInputAddress = { ...inputAddress, station_id: stationid };
            console.log("🚀 ~ addAddress ~ NewInputAddress:", NewInputAddress)
            // const updatedAddress = [...address, NewInputAddress];
            // if (updatedAddress) {
            //     dispatch(addAddressData(NewInputAddress)); // Pass sendStationData to addData thunk
            //   }
            if (NewInputAddress) {
                try {
                    const res = await withRFSToken.post(ULDADDRESSSAVE, NewInputAddress)
                        .then(function (res) {
                            // const innerData = res;
                            // console.log("🚀 ~ .then ~ innerData:", innerData)
                            if (res.data.response_code == 200) {
                                const newAddress = res.data.response_data;
                                setAddress(newAddress);
                                fetchDestination();
                                setHandlerNames(prevHandlerNames => [
                                    ...prevHandlerNames,
                                    newAddress
                                ]);
                                setInputAddress({
                                    station_address_id: '', company_name: '', agent_or_handler: 'H', address: '', email: '', phone_number: ''
                                });
                                console.log(address);
                                addressModalClose();
                            } else {
                                toast.error(res.data.response_msg);
                            }
                        });

                } catch (error) {
                    console.error('Error sending request:', error);
                }
            }

        }
    }
    console.log('6665', handlerNames);
    // ADD ADDRESS END
    const validateFormData = () => {
        let isValid = true;

        // Validate status
        if (!formData.status) {
            toast.error('Please set Status');
            isValid = false;
        }
        // Validate destination
        else if (!formData.destination) {
            toast.error('Please select Destination');
            isValid = false;
        }
        // Validate uldInfo array
        else {
            for (let i = 0; i < formData.uldInfo.length; i++) {
                const uld = formData.uldInfo[i];
                if (!uld.type_code) {
                    toast.error('Please set Type Code');
                    isValid = false;
                    break;
                }
                if (!uld.serial_number) {
                    toast.error('Please set Serial Number');
                    isValid = false;
                    break;
                }
                if (!uld.owner_code) {
                    toast.error('Please set Owner Code');
                    isValid = false;
                    break;
                }
                if (!uld.condition_code) {
                    toast.error('Please set Condition Code');
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    };

    const handleSave = async () => {
        if (validateFormData()) {
            const transformedData = {
                ...formData,
                asset_type: formData.asset_type ? 'L' : 'O',
                uldInfo: formData.uldInfo.map(stack => ({
                    ...stack,
                    asset_type: stack.asset_type ? 'L' : 'O'
                }))
            };
            console.log('Saving data:', transformedData);
            try {
                const res = await withRFSToken.post(SAVEULDSTOCK, transformedData);
                if (res.data.response_code == 200) {
                    toast.success('New Stock Saved')
                    const innerData = res.data.response_data;
                    // setGetOwnerCode(innerData);
                    // setFormData({
                    //     id: '1',
                    //     status: '',
                    //     destination: '', handler_name: '', condition_code: '',
                    //     // type_code: '', serial_number: '', owner_code: '', airline_name: '', asset_type: false,
                    //     uldInfo: [
                    //         { id: '1', type_code: '', serial_number: '', owner_code: '', airline_name: '', asset_type: '', }
                    //     ]
                    // });
                    window.location.href = '/ULDControl/';
                } else {
                    toast.error(res.data.response_msg);
                }
                // .then(function (res) {

                // });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    // Modal for Asset Type and Cancel
    const [showModal, setShowModal] = useState(false);
    const [modalRadioValue, setModalRadioValue] = useState('');
    const [modalContent, setModalContent] = useState('');
    const handleModalClose = () => { setShowModal(false); setModalRadioValue('') };

    const handleModalOpen = (contentType) => {
        setModalContent(contentType);
        setShowModal(true);
    };
    const handleConfirmation = (confirm) => {
        if (confirm) {
            setShowModal(false);
            window.location.href = '/ULDControl'
        } else {
            setShowModal(false);
        }
    }

    const handleModalRadioChange = (event) => {
        const { name, value } = event.target;
        setModalRadioValue({ [name]: value });
    };

    const handleModalUpdate = () => {
        const updatedAssetType = modalRadioValue.assetType === 'O' ? false : true;
        const updatedFormData = {
            ...formData,
            asset_type: updatedAssetType,
            uldInfo: formData.uldInfo.map(stack => ({
                ...stack,
                asset_type: updatedAssetType
            }))
        };
        setFormData(updatedFormData);
        setModalRadioValue('')
        setShowModal(false);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    // const [uploading, setUploading] = useState(false);

    // const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        // console.log("🚀 ~ handleFileChange ~ event:", event)
        setSelectedFile(event.target.files[0]);

        const input = event.target;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            setFileName(file.name);
            setSelectedFile(file);

            // const reader = new FileReader();
            // reader.onload = (e) => {
            //     setFileData(e.target.result);
            // };
            // reader.readAsText(file); // Use readAsText to read the content of the file
        }
    };
    // console.log('200', selectedFile);
    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error("Please select a file first!");
            return;
        }
        // setUploading(true);
        // console.log('300', selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log("🚀 ~ handleUpload ~ json:", json)
                setFormData(prevFormData => {
                    const startId = prevFormData.uldInfo.length + 1;

                    const transformedData = json.map((item, index, value) => {
                        let transformedConditionCode;
                        switch (item["Condition Code"]?.toString().trim().toLowerCase()) {
                            case "serviceable":
                                transformedConditionCode = "SER";
                                break;
                            case "damaged":
                                transformedConditionCode = "DAM";
                                break;
                            case "discarded":
                                transformedConditionCode = "DIS";
                                break;
                            default:
                                transformedConditionCode = item["Condition Code"]?.toString().trim() || ""; // Keep original if not matched
                        }
                        return {
                            id: startId + index, // Assign ID
                            type_code: item["Type Code"]?.toString().trim() || "",
                            serial_number: item["Serial Number"]?.toString().trim() || "",
                            owner_code: item["Owner Code"]?.toString().trim() || "",
                            condition_code: transformedConditionCode,
                            asset_type: item["Asset Type"]?.toString().trim() === 'Owned' ? false : true
                        }
                    });

                    console.log("🚀 ~ transformedData ~ transformedData:", transformedData);
                    const shouldKeepPreviousData = formData.uldInfo.some(
                        section => section.type_code || section.serial_number || section.owner_code
                    );

                    return {
                        ...prevFormData,
                        uldInfo: shouldKeepPreviousData ? [...prevFormData.uldInfo, ...transformedData] : [...transformedData]
                    };
                });
                setSelectedFile(null);
                setFileName('');
                // setExceljsondata(json);
                setShowModal(false);
            };
            reader.readAsBinaryString(selectedFile);
        } else {
            toast.error("All Fields Are Mandatory", { autoClose: 1000 });
        }

        // const xlFileData = new FormData();
        // xlFileData.append('uld_file', selectedFile);
        // const formData = new FormData();
        // formData.append('uld_file', selectedFile, fileName);


        // try {
        //     const res = await withRFSToken.post(IMPORTEXCEL, {uld_file:fileName} );
        //     if (res.data.response_code == 200) {
        //         toast.success('File uploaded successfully!');
        //     }

        // } catch (error) {
        //     console.error("There was an error uploading the file!", error);
        // } finally {
        //     setUploading(false);
        // }
    };

    const handleDownloadClick = () => {
        const a = document.createElement('a');
        a.href = XLSFILE;
        a.download = 'Add_ULD.xlsx'; // Name for the downloaded file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    // console.log('0123', formData);
    return (
        <>
            <div className="">
                <Header />
                <div className='main-panel'>
                    <Link to="/ULDControl" className='stk_back'> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
                    <div className='addstock'>
                        <div className='d-flex justify-content-between adds_top'>
                            <h3>Add Stock</h3>
                            <Button variant="outline-info" onClick={() => handleModalOpen('import')}> <FontAwesomeIcon icon={faFileExport} /> Import List</Button>
                        </div>
                        <div className='ms-3'>
                            <Form.Group controlId="dropdown" className='sl_status mb-2'>
                                <Form.Label>Select Status <span>*</span></Form.Label>
                                <Form.Check
                                    label="In Operation"
                                    name="status"
                                    type='radio'
                                    id='Operation'
                                    value="1"
                                    checked={formData.status === '1'}
                                    onChange={handleInputChange}
                                />
                                <Form.Check
                                    label="In Reserve"
                                    name="status"
                                    type='radio'
                                    id='Reserve'
                                    value="3"
                                    checked={formData.status === '3'}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-3 pb-3 ms-3 separate'>
                            <div className='d-flex'>
                                <div>
                                    <label className="form-label">Destination <span>*</span></label>
                                    <Select
                                        options={getDestination}
                                        onChange={(selected) => {
                                            const selectedDestination = getDestination.find(option => option.value === selected.value);
                                            console.log("🚀 ~ NewStock ~ selectedDestination:", selectedDestination)
                                            const updatedData = { ...formData, destination: selected.value, handler_name: '' };
                                            setFormData(updatedData);
                                            setHandlerNames(selectedDestination ? selectedDestination.address : []);
                                            setSelectedStationId(selectedDestination.station_id);
                                            setSelectedHandler(null);
                                        }}
                                        placeholder="Destination"
                                        className='autoslct'
                                        name="destination"
                                        value={getDestination.find(option => option.value === formData.destination)}
                                    />
                                    {/* <select className="form-select" name="destination" value={formData.destination} onChange={handleInputChange}>
                                        <option selected>Destination</option>
                                        {getDestination &&
                                            getDestination.map((option, index) =>
                                                <option key={index} value={option.port_code}>{option.portCode}</option>
                                            )
                                        }
                                    </select> */}
                                </div>
                                <div>
                                    <label className="form-label">Handler Name <span>*</span>{formData.destination ? <button onClick={() => addressModalOpen('destination')} className='raad_adrss'>+ Add</button> : ''}</label>{console.log('654', handlerNames)}
                                    {/* <input className="form-control" id="ex2" name="handler_name" type="text" value={formData.handler_name} onChange={handleInputChange} /> */}
                                    <Select
                                        options={handlerNames.map(handler => ({
                                            value: handler.id,
                                            label: handler.company_name
                                        }))}
                                        onChange={(selected) => {
                                            const updatedData = { ...formData, handler_name: selected.value };
                                            setFormData(updatedData);
                                            setSelectedHandler(selected);
                                        }}
                                        placeholder="Handler Name"
                                        className='autoslct m-0'
                                        name="handler_name"
                                        // value={handlerNames.find(option => option.value === (selectedHandler ? selectedHandler.value : ''))}
                                        value={selectedHandler ? { value: selectedHandler.value, label: selectedHandler.label } : null}
                                        isDisabled={!handlerNames.length}
                                    />
                                </div>
                            </div>
                            <div className='alltype'> <button type="button" className="btn btn-outline-secondary" onClick={() => handleModalOpen('assetTypeChange')}> <FontAwesomeIcon icon={faGear} /> All Asset Type </button> </div>
                        </div>
                        <div className='stck_middle'>
                            {formData.uldInfo && formData.uldInfo.map((section, index) => {
                                return (
                                    <div className='d-flex mb-3 sub_add' key={index}>
                                        {/* {console.log('100', section)} */}
                                        <div>
                                            <Form.Label>Type Code <span>*</span></Form.Label>
                                            <Select
                                                options={getTypeCodes}
                                                onChange={(e) => handleULDInputChange(section.id, 'type_code', e.value)}
                                                placeholder="Type Code"
                                                className='autoslct'
                                                name="type_code"
                                                value={getTypeCodes.find(option => option.value === section.type_code)}
                                            />
                                            {/* <select className="form-select" name="type_code" value={section.type_code} onChange={(e) => handleULDInputChange(section.id, 'type_code', e.target.value)}>
                                                <option selected>Type Code</option>
                                                {getTypeCodes &&
                                                    getTypeCodes.map((option, index) =>
                                                        <option key={index} value={option.uld_typecode}>{option.uld_typecode}</option>
                                                    )
                                                }
                                            </select> */}
                                        </div>

                                        <div>
                                            <Form.Label>Serial Number <span>*</span></Form.Label>
                                            <Form.Control type="text"
                                                name='serial_number'
                                                value={section.serial_number}
                                                // maxLength='5' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5); }}
                                                onChange={(e) => handleULDInputChange(section.id, 'serial_number', e.target.value)} />
                                        </div>

                                        <div>
                                            <Form.Label>Owner Code <span>*</span></Form.Label>
                                            {/* <Form.Control type="text"
                                                name='owner_code'
                                                value={section.owner_code}
                                                onChange={(e) => handleULDInputChange(section.id, 'owner_code', e.target.value)} /> */}
                                            <Select
                                                options={getOwnerCodes}
                                                onChange={(e) => handleULDInputChange(section.id, 'owner_code', e.value)}
                                                placeholder="Owner Code"
                                                className='autoslct'
                                                name="owner_code"
                                                value={getOwnerCodes.find(option => option.value === section.owner_code)}
                                            />
                                            {/* <select className="form-select" name="owner_code" value={section.owner_code} onChange={(e) => handleULDInputChange(section.id, 'owner_code', e.target.value)} >
                                                <option selected>Owner Code</option>
                                                {getOwnerCodes &&
                                                    getOwnerCodes.map((option, index) =>
                                                        <option key={index} value={option.IATA}>{option.IATA}</option>
                                                    )
                                                }
                                            </select> */}
                                        </div>

                                        <div>
                                            <label className="form-label">Condition Code <span>*</span></label>
                                            <select className="form-select" name="condition_code" value={section.condition_code} onChange={(e) => handleULDInputChange(section.id, 'condition_code', e.target.value)}>
                                                {getCondCodes &&
                                                    Object.entries(getCondCodes).map((option, index) =>
                                                        <option key={index} value={option[0]}>{option[1]}</option>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className='nswitch'>
                                            <Form.Label> Asset Type </Form.Label>

                                            {/* <div className="form-check form-switch asset_type">
                                                <span>Owned</span>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name='asset_type'
                                                    checked={section.asset_type}
                                                    onChange={(e) => handleULDInputChange(section.id, 'asset_type', e.target.checked)} />
                                                <label className="form-check-label">Lease</label>
                                            </div> */}

                                            <div className="switch6">
                                                <label className="switch6-light">
                                                    <input type="checkbox"
                                                        name="asset_type"
                                                        checked={section.asset_type}
                                                        onChange={(e) => handleULDInputChange(section.id, 'asset_type', e.target.checked)} />
                                                    <span>
                                                        <span>Owned</span>
                                                        <span>Lease</span>
                                                    </span>
                                                    <a className="btn btn-primary"></a>
                                                </label>
                                            </div>


                                        </div>
                                        <div className='imag_trash'>
                                            {formData.uldInfo.length > 1 && (
                                                <img src={Trash} alt='' onClick={() => removeULDStack(section.id)} />
                                            )}
                                        </div>

                                    </div>
                                )
                            })}

                            <div className='text-end'><Button variant="primary" className='stk_add' onClick={addULDStack}> <FontAwesomeIcon icon={faPlus} />Add ULD</Button></div>
                        </div>

                        <div className='text-center mt-5'>
                            <Button variant="light" className='stk_cancel' onClick={() => handleModalOpen('cancelForm')}>CANCEL</Button>
                            <Button variant="primary" className='stk_save' onClick={handleSave}>SAVE</Button>
                        </div>
                    </div>

                </div>
                <Modal show={showModal} onHide={handleModalClose} className='addfrom change_type' centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {modalContent === 'import' && (
                            <>
                                <h3>Import Excel</h3>
                                <p>Upload the filled in template to add ULDs.</p>
                                <div className='asset_midle'>

                                    <div className='upload_tmpl'>
                                        <button onClick={handleDownloadClick}>
                                            <FontAwesomeIcon icon={faDownload} />
                                            <span>Download Template (Excel) </span>
                                        </button>
                                        <div className="form-group inputDnD">
                                            <FontAwesomeIcon icon={faUpload} />
                                            <input
                                                type="file" className="form-control-file "
                                                id="inputFile"
                                                // accept=".xlsx, .xls, .csv"
                                                onChange={handleFileChange}
                                                data-title="Upload your file (Excel)" />

                                            {/* {fileData && (
                                                <div>
                                                    <h4>File Data:</h4>
                                                    <pre>{fileData}</pre>
                                                </div>
                                            )} */}
                                        </div>
                                    </div>

                                    {fileName && (
                                        <div className='rafilname'>
                                            <p>File Name: {fileName}</p>
                                        </div>
                                    )}


                                    {/* <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        accept=".xlsx, .xls, .csv"
                                    /> */}
                                </div>
                            </>
                        )}
                        {modalContent === 'assetTypeChange' && (
                            <>
                                <h3>All Asset Type Changes</h3>
                                <p>Are sure you want to change all asset type?</p>
                                <div className='asset_midle'>
                                    <Form.Check
                                        type='radio'
                                        label='Owned'
                                        name='assetType'
                                        id='own'
                                        value='O'
                                        checked={modalRadioValue.assetType === 'O'}
                                        onChange={handleModalRadioChange}
                                    />
                                    <Form.Check
                                        type='radio'
                                        label='Lease'
                                        name='assetType'
                                        id='lease'
                                        value='L'
                                        checked={modalRadioValue.assetType === 'L'}
                                        onChange={handleModalRadioChange}
                                    />
                                </div>
                            </>
                        )}
                        {modalContent === 'cancelForm' && (
                            <>
                                <h3>All changes will be destroyed. </h3>
                                <p>If you proceed with the change, all data in this section will be removed.
                                    Are you sure you want to continue?</p>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {modalContent === 'assetTypeChange' ?
                            (
                                <>
                                    <Button variant='primary' onClick={handleModalUpdate}>
                                        Update Asset Type
                                    </Button>
                                </>
                            ) : (modalContent === 'import' ? (
                                <>
                                    <div className='d-flex w-50 mx-auto are_bottom'>
                                        <Button variant="secondary" onClick={() => handleConfirmation(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={handleUpload}>
                                            Upload
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='d-flex w-50 mx-auto are_bottom'>
                                        <Button variant="secondary" onClick={() => handleConfirmation(false)}>
                                            No
                                        </Button>
                                        <Button variant="primary" onClick={() => handleConfirmation(true)}>
                                            Yes
                                        </Button>
                                    </div>
                                </>
                            ))}
                    </Modal.Footer>
                </Modal>

                <Modal show={addressModalShow} onHide={addressModalClose} centered className='addfrom'>
                    <Modal.Header closeButton onClick={addressModalClose}>
                        <Modal.Title>Add Address  - {destLabel}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form.Group className='mb-2'>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" onChange={handleAddressChange} name='company_name' placeholder="Company Name" />
                        </Form.Group>
                        <Form.Group className='mb-2'>
                            <Form.Label>Agent or Handler</Form.Label>
                            <Form.Check
                                inline
                                label="Agent"
                                name="agent_or_handler"
                                type='radio'
                                id='export'
                                value='A'
                                checked={inputAddress.agent_or_handler === 'A'}
                                onChange={handleAddressChange}
                                disabled
                            />

                            <Form.Check
                                inline
                                label="Handler"
                                value="H"
                                name="agent_or_handler"
                                type='radio'
                                id='import'
                                checked={inputAddress.agent_or_handler === 'H'}
                                onChange={handleAddressChange}
                            />
                        </Form.Group>
                        {inputAddress.agent_or_handler == 'A' &&
                            <>
                                <Form.Group className='mb-2'>
                                    <Form.Check
                                        inline
                                        label="External"
                                        name="agent_type"
                                        type='radio'
                                        id='external'
                                        value='E'
                                        checked={inputAddress.agent_type === 'E'}
                                        onChange={handleAddressChange}
                                    />

                                    <Form.Check
                                        inline
                                        label="Internal"
                                        value="I"
                                        name="agent_type"
                                        type='radio'
                                        id='internal'
                                        checked={inputAddress.agent_type === 'I'}
                                        onChange={handleAddressChange}
                                    />

                                </Form.Group>
                            </>
                        }
                        <Form.Group className='mb-2'>
                            <Form.Label>Address </Form.Label>
                            <Form.Control type="text" onChange={handleAddressChange} name='address' placeholder="Address" />
                        </Form.Group>
                        <Form.Group className='mb-2'>
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control type="text" onChange={handleAddressChange} name='email' placeholder="E-mail" />
                        </Form.Group>
                        <Form.Group className='mb-2'>
                            <Form.Label>Telephone No</Form.Label>
                            <Form.Control type="number" onChange={handleAddressChange} name='phone_number' onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12); }} placeholder="Number" />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={addAddress}  >
                            Add Address
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default NewStock