import React from "react";
import '../../App.css';
import Header from "../../Componenets/Header/Header";
import LeftMenu from "../../Componenets/LeftMenu/LeftMenu";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import TopMenu from "../../Componenets/TopMenu/TopMenu";

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement )



const Kpicharts = () => {


      // Data and options for the bar chart
      const respData = {
        labels: ['External', 'Internal'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19],
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 0,
            cutout: '70%',
            // borderRadius: 10,
          },
        ],
      };
    
      const respOptions = {
        indexAxis: 'y',
        plugins: {
          // beforeDraw: function (chart) {
          //   RoundedDoughnutPlugin.beforeDraw(chart);
          //   console.log(chart);
          // },
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            align: 'start',
            text: 'Responsibility',
          },
        },
      };

      const contefctData = {
        labels: ['Yes', 'Unlikely', 'Possibly'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 14],
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const contefctOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Content Affected',
          },
        },
      };

      const ClassificationData = {
        labels: ['Major', 'Life Threat', 'Minor', 'Moderate'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 14, 15],
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const ClassificationOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Classification',
          },
        },
      };
      
      const DRMData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const DRMOptions = {
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Damage Reports per Month',
          },
        },
      };
      
      const DRSData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const DRSOptions = {
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Damage Reports by Station',
          },
        },
      };

      const DRAData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const DRAOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Damage Report by Airline',
          },
        },
      };

      const DRDData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const DRDOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Damage Report by Airline',
          },
        },
      };

      const BehaviourData = {
        labels: ['Unknown', 'Distraction'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [2, 11],
            backgroundColor: [
              '#ad8cf3','#80d1ff','#ff809a','#f4dd8a','#9ce3bc','#9ea9e0',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const BehaviourOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Behaviour',
          },
        },
      };

      const RulesData = {
        labels: ['Unknown', 'Distraction'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [2, 11],
            backgroundColor: [
              '#ad8cf3','#80d1ff','#ff809a','#f4dd8a','#9ce3bc','#9ea9e0',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(103, 64, 185, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const RulesOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Rules',
          },
        },
      };

      const EquipmentData = {
        labels: ['Unknown'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [ 11],
            backgroundColor: [
              '#9ce3bc','#ff809a','#80d1ff','#ad8cf3','#f4dd8a','#9ea9e0',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(103, 64, 185, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const EquipmentOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Equipment',
          },
        },
      };

      const OrganisationData = {
        labels: ['Unknown'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [ 11],
            backgroundColor: [
              '#9ea9e0','#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(103, 64, 185, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const OrganisationOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Organisation',
          },
        },
      };

      const CapaTypeData = {
        labels: ['Unknown'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [ 11],
            backgroundColor: [
              '#f4dd8a','#80d1ff','#ff809a','#ad8cf3','#9ce3bc','#9ea9e0',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(103, 64, 185, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const CapaTypeOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'CapaType',
          },
        },
      };

      const InternalAreaData = {
        labels: ['Unknown', 'Terminal'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [ 11 ,3],
            backgroundColor: [
              '#80d1ff','#ff809a','#ad8cf3','#f4dd8a','#9ce3bc','#9ea9e0',
              '#FD7070','#FD9270','#FDAB70','#D0FD70','#AEFD70','#7BFD70',
              '#70FDB9','#70FDF4','#70E3FD','#70B1FD','#7097FD','#7075FD',
              '#8370FD','#9D70FD','#BF70FD','#FD70D5','#D64D6E'
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(103, 64, 185, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
            cutout: '70%',
            borderRadius: 0,
          },
        ],
      };
    
      const InternalAreaOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          title: {
            display: true,
            text: 'Internal Area',
          },
        },
      };

      const ConditionContentData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const ConditionContentOptions = {
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Condition Content',
          },
        },
      };
      
      const MethodPackingData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: 'Bar Chart Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            barThickness: 20,
            borderWidth: 1,
            cutout: '20%',
            borderRadius: 10,
          },
        ],
      };
    
      const MethodPackingOptions = {
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
            text: 'Method Packing',
          },
        },
      };


    return(
        <>
        <div className="container-scroller dashboard">
            <Header />
            <div className="container-fluid d-flex p-0 page-body-wrapper">
                <LeftMenu />
                <div className="chart-panel">
                <TopMenu />
                <div className="rounded">
                    <div className="row">
                        <div className="col-md-4">
                        <div className="cartbox">
                          <Doughnut id="rounded-doughnut-chart" data={respData} options={respOptions}
                          plugins={ [
                            {
                              id: 'drawСircles',
                              afterUpdate(chart) {
                                const arcs = chart.getDatasetMeta(0).data;
                    
                                arcs.forEach((arc) => {
                                  arc.round = {
                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                    backgroundColor: arc.options.backgroundColor,
                                  };
                                });
                              },
                              afterDraw: (chart) => {
                                const {
                                  ctx,
                                } = chart;
                    
                                chart.getDatasetMeta(0).data.forEach((arc) => {
                                  const endAngle = Math.PI / 2 - arc.endAngle;
                    
                                  ctx.save();
                                  ctx.translate(arc.round.x, arc.round.y);
                                  ctx.fillStyle = arc.options.backgroundColor;
                                  ctx.beginPath();
                                  ctx.arc(
                                    arc.round.radius * Math.sin(endAngle),
                                    arc.round.radius * Math.cos(endAngle),
                                    arc.round.thickness,
                    
                                    0,
                    
                                    2 * Math.PI,
                                  );
                                  ctx.closePath();
                                  ctx.fill();
                                  ctx.restore();
                                });
                              },
                            },
                          ] }
                          /></div>
                        </div>
                        <div className="col-md-4">
                        <div className="cartbox">
                          <Doughnut data={contefctData} options={contefctOptions}
                          plugins={ [
                            {
                              id: 'drawСircles',
                              afterUpdate(chart) {
                                const arcs = chart.getDatasetMeta(0).data;
                    
                                arcs.forEach((arc) => {
                                  arc.round = {
                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                    backgroundColor: arc.options.backgroundColor,
                                  };
                                });
                              },
                              afterDraw: (chart) => {
                                const {
                                  ctx,
                                } = chart;
                    
                                chart.getDatasetMeta(0).data.forEach((arc) => {
                                  const endAngle = Math.PI / 2 - arc.endAngle;
                    
                                  ctx.save();
                                  ctx.translate(arc.round.x, arc.round.y);
                                  ctx.fillStyle = arc.options.backgroundColor;
                                  ctx.beginPath();
                                  ctx.arc(
                                    arc.round.radius * Math.sin(endAngle),
                                    arc.round.radius * Math.cos(endAngle),
                                    arc.round.thickness,
                    
                                    0,
                    
                                    2 * Math.PI,
                                  );
                                  ctx.closePath();
                                  ctx.fill();
                                  ctx.restore();
                                });
                              },
                            },
                          ] }
                          /></div>
                        </div>
                        <div className="col-md-4">
                        <div className="cartbox">
                          <Doughnut data={ClassificationData} options={ClassificationOptions}
                          plugins={ [
                            {
                              id: 'drawСircles',
                              afterUpdate(chart) {
                                const arcs = chart.getDatasetMeta(0).data;
                    
                                arcs.forEach((arc) => {
                                  arc.round = {
                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                    backgroundColor: arc.options.backgroundColor,
                                  };
                                });
                              },
                              afterDraw: (chart) => {
                                const {
                                  ctx,
                                } = chart;
                    
                                chart.getDatasetMeta(0).data.forEach((arc) => {
                                  const endAngle = Math.PI / 2 - arc.endAngle;
                    
                                  ctx.save();
                                  ctx.translate(arc.round.x, arc.round.y);
                                  ctx.fillStyle = arc.options.backgroundColor;
                                  ctx.beginPath();
                                  ctx.arc(
                                    arc.round.radius * Math.sin(endAngle),
                                    arc.round.radius * Math.cos(endAngle),
                                    arc.round.thickness,
                    
                                    0,
                    
                                    2 * Math.PI,
                                  );
                                  ctx.closePath();
                                  ctx.fill();
                                  ctx.restore();
                                });
                              },
                            },
                          ] }
                          /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="cartbox">
                            <div className="cartbox"><Bar data={DRMData} options={DRMOptions} /></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cartbox"><Bar data={DRSData} options={DRSOptions} /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="cartbox"><Bar data={DRAData} options={DRAOptions} /></div>
                        </div>
                        <div className="col-md-6">
                          <div className="cartbox"><Bar data={DRDData} options={DRDOptions} /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={BehaviourData} options={BehaviourOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={RulesData} options={RulesOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={EquipmentData} options={EquipmentOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={OrganisationData} options={OrganisationOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={CapaTypeData} options={CapaTypeOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                        <div className="col-md-4">
                          <div className="cartbox">
                            <Doughnut data={InternalAreaData} options={InternalAreaOptions}
                            plugins={ [
                              {
                                id: 'drawСircles',
                                afterUpdate(chart) {
                                  const arcs = chart.getDatasetMeta(0).data;
                      
                                  arcs.forEach((arc) => {
                                    arc.round = {
                                      x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                      y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                      radius: (arc.outerRadius + arc.innerRadius) / 2,
                                      thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                      backgroundColor: arc.options.backgroundColor,
                                    };
                                  });
                                },
                                afterDraw: (chart) => {
                                  const {
                                    ctx,
                                  } = chart;
                      
                                  chart.getDatasetMeta(0).data.forEach((arc) => {
                                    const endAngle = Math.PI / 2 - arc.endAngle;
                      
                                    ctx.save();
                                    ctx.translate(arc.round.x, arc.round.y);
                                    ctx.fillStyle = arc.options.backgroundColor;
                                    ctx.beginPath();
                                    ctx.arc(
                                      arc.round.radius * Math.sin(endAngle),
                                      arc.round.radius * Math.cos(endAngle),
                                      arc.round.thickness,
                      
                                      0,
                      
                                      2 * Math.PI,
                                    );
                                    ctx.closePath();
                                    ctx.fill();
                                    ctx.restore();
                                  });
                                },
                              },
                            ] }
                            /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="cartbox">
                            <div className="cartbox"><Bar data={ConditionContentData} options={ConditionContentOptions} /></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cartbox"><Bar data={MethodPackingData} options={MethodPackingOptions} /></div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    );

}
export default Kpicharts;