import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginPage from './Pages/Auth/LoginPage';
import RegisterComponenet from './Pages/Auth/RegisterComponent';
import Forgotpassword from './Pages/Auth/ForgotPassword';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import Layout from './Componenets/Layouts/Layout';
import JGrid from './Componenets/JGrid/JGrid';
import PlanDashboard from './Pages/PlanDashboard/PlanDashboard';
import AdminDashboard from './Pages/Dashboard/AdminDashboard';
import CargoDamage from './Pages/CargoDamage/CargoDamage';
import Stations from './Pages/stations/stations';
import Kpicharts from './Pages/Kpi-Charts/Kpicharts';
import TruckOrder from './Pages/TruckOrder/TruckOrder';
import ServiceRecovery from './Pages/ServiceRecovery/ServiceRecovery';
import UldDamage from './Pages/ULDDamage/UldDamage';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../src/assets/css/responsive.css";

import ShipmentPlan from './Componenets/CreatePlan/ShipmentPlan';
import EditShipmentPlan from './Componenets/EditPlan/EditShipmentPlan';
import EditULDPlan from './Componenets/EditPlan/EditULDPlan';
import BookingtruckSchedule from './Pages/BookingtruckSchedule/BookingtruckSchedule';
import { UserSettings } from './Pages/Settings/UserSettings';
import { NotificationSettings } from './Pages/Settings/RFS/NotificationSettings';
import { StationSettings } from './Pages/Settings/RFS/StationSettings';
import { CarrierSettings } from './Pages/Settings/CarrierSettings';
import TruckerSettings from './Pages/Settings/RFS/TruckerSettings';
import ManageUsers from './Pages/Settings/RFS/ManageUsers';
import ManageUserULD from './Pages/Settings/ULD/ManageUsers';
import AccessibilitySettings from './Pages/Settings/RFS/AccessibilitySettings';
import UserSpecificSettings from './Pages/Settings/RFS/UserSpecificSettings';
import AccessRights from './Pages/Settings/ULD/AccessRights';

import { GridDetails } from './Pages/PlanDashboard/GridDetails';
import { LoadingPage } from './Pages/LoadingPage/LoadingPage';
import ArchiveGrid from './Componenets/JGrid/ArchivedGrid'

import UldstackPlan from './Componenets/UldstackPlan/UldstackPlan';
import Crud from './Componenets/CRUD/Crud';
import Sample from './Pages/Sample';

import ULDControl from './Pages/ULD/ULDControl/ULDControl';
import NewStock from './Pages/ULD/ULDControl/components/NewStock';
import NewCharter from './Pages/ULD/ULDControl/components/NewCharter';
import ULDFunction from './Pages/ULD/ULDControl/components/ULDFunction';
import MovementHistory from './Pages/ULD/ULDControl/components/MovementHistory';
import MHDetails from './Pages/ULD/ULDControl/components/MHDetails';
import History from './Pages/ULD/History/History';

import {GeneralSettings}         from './Pages/Settings/ULD/GeneralSettings';
import RegionSettings          from './Pages/Settings/ULD/RegionSettings';
import AccessRightsSettings    from './Pages/Settings/ULD/AccessRightsSettings';
import ULDNotificationSettings from './Pages/Settings/ULD/NotificationSettings';
import ULDStationSettings      from './Pages/Settings/ULD/StationSettings';
import ReportSettings          from './Pages/Settings/ULD/ReportSettings';


import PrivateRoutes from "./useAuth/privateRoutes";
import { useAuth, useAuthRFS, useAuthULD } from './useAuth/useAuth';

// import PublicRoutes from "./useAuth/publicRoutes";


const App = () => {
  const isAuthenticated = useAuth();
  const isAuthenticatedRFS = useAuthRFS();
  const isAuthenticatedULD = useAuthULD();
  // localStorage.setItem("authToken", "Bearer jwtToken");
  return (
    <>
      <Switch>
        {/* Public Routes */}
        <Route exact path="/registerPage" component={RegisterComponenet} />
        <Route exact path="/" component={LoadingPage} />
        {/* Public Routes */}

        {/* Private Routes */}

        {isAuthenticated && (
          <>
            <Route exact path="/planDashboard" component={PlanDashboard} />
            <Route exact path="/Archive" component={ArchiveGrid} />
            <Route exact path="/ShipmentPlan" component={ShipmentPlan} />
            <Route exact path="/EditShipmentPlan" component={EditShipmentPlan} />
            <Route exact path="/UldstackPlan" component={UldstackPlan} />
            <Route exact path="/EditULDPlan" component={EditULDPlan} />
            <Route exact path="/jgrid" component={JGrid} />
            <Route exact path="/gridDetails" component={GridDetails} />
            <Route exact path="/UserSettings" component={UserSettings} />
          </>
        )}
        {isAuthenticatedRFS && (
          <>
            <Route exact path="/BookingtruckSchedule" component={BookingtruckSchedule} />
            <Route exact path="/TruckOrder" component={TruckOrder} />
            <Route exact path="/kpicharts" component={Kpicharts} />
            <Route exact path="/stations" component={Stations} />
            <Route exact path="/NotificationSettings" component={NotificationSettings} />
            <Route exact path="/StationSettings" component={StationSettings} />
            <Route exact path="/CarrierSettings" component={CarrierSettings} />
            <Route exact path="/TruckerSettings" component={TruckerSettings} />

            <Route exact path="/ULDControl" component={ULDControl} />
            <Route exact path="/ULDControl/AddStock" component={NewStock} />
            <Route exact path="/ULDControl/NewCharter" component={NewCharter} />
            <Route exact path="/ULDControl/ULDFunction" component={ULDFunction} />
            <Route exact path="/ULDControl/StationTransfer" component={ULDFunction} />
            <Route exact path="/ULDControl/ULDReceived" component={ULDFunction} />
            <Route exact path="/ULDControl/MovementHistory" component={MovementHistory} />
            <Route exact path="/ULDControl/MovementHistorydetais" component={MHDetails} />
            <Route exact path="/History" component={History} />

            <Route exact path="/ManageUsers" component={ManageUsers} />
            <Route exact path="/AccessibilitySettings" component={AccessibilitySettings} />
            <Route exact path="/UserSpecificSettings" component={UserSpecificSettings} />
          </>
        )}
        {isAuthenticatedULD && (
          <>
            <Route exact path="/ULDControl" component={ULDControl} />
            <Route exact path="/ULDControl/AddStock" component={NewStock} />
            <Route exact path="/ULDControl/NewCharter" component={NewCharter} />
            <Route exact path="/ULDControl/ULDFunction" component={ULDFunction} />
            <Route exact path="/ULDControl/StationTransfer" component={ULDFunction} />
            <Route exact path="/ULDControl/ULDReceived" component={ULDFunction} />
            <Route exact path="/ULDControl/MovementHistory" component={MovementHistory} />
            <Route exact path="/ULDControl/MovementHistorydetails" component={MHDetails} />
            <Route exact path="/History" component={History} />

            <Route exact path="/UserSettings" component={ManageUserULD} />
            <Route exact path="/AccessRights" component={AccessRights} />
            <Route exact path="/GeneralSettings" component={GeneralSettings} />
            <Route exact path="/RegionSettings" component={RegionSettings} />
            <Route exact path="/AccessRightsSettings" component={AccessRightsSettings} />
            <Route exact path="/NotificationSettings" component={ULDNotificationSettings} />
            <Route exact path="/StationSettings" component={ULDStationSettings} />
            <Route exact path="/ReportSettings" component={ReportSettings} />
          </>
        )}




        <Route exact path="/Crud" component={Crud} />
        <Route exact path="/Sample" component={Sample} />

        <Route exact path="/forgotpassword" component={Forgotpassword} />
        {/* <Route exact path = "/dashboard" component={AdminDashboard}/> */}


        <Route exact path="/CargoDamage" component={CargoDamage} />
        <Route exact path="/UldDamage" component={UldDamage} />
        <Route exact path="/ServiceRecovery" component={ServiceRecovery} />




        {/* </PrivateRoutes> */}

        {/* Private Routes */}

      </Switch>
    </>
  );
}

export default App;