import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Modal, Table } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faCircleXmark, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Docmnt from '../../../assets/images/google-docs.png';
import Arcv from "../../../assets/images/archive.svg";
import Export from "../../../assets/images/export.svg";
import edit from "../../../assets/images/edit.png";
import Cargoplane from "../../../assets/images/plane.svg";
import Cuser from "../../../assets/images/cuser.png";
import Trash from '../../../assets/images/trash.png';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageUpload from '../../../Componenets/common/ImageUpload';
import ActivityComments from '../../../Componenets/common/ActivityComments';
import EditUld from './components/EditUld.js';
import Badge from 'react-bootstrap/Badge';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { withoutRFSToken, withRFSToken } from '../../../Services/axios.js';
import { ULDOPERATIONGRID, GETDAMAGETYPE, UPDATECONDITION, GETULDDETAILS, MVTUPDATE, MVTHDETAILS } from '../../../Utils/conts.js';
import Loader from '../../../Componenets/common/Loader.js';
import DownloadGrid from './components/DownloadGrid.js';

const Operations = () => {
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [gridData, setGridData] = useState([]);
    const [totalRows, setTotalRows] = useState({});
    const [damageType, setDamageType] = useState([]);
    const [responseAction, setResponseAction] = useState();
    const fetchDataGrid = async (pageNumber, psize) => {
        try {
            console.log("🚀 ~ fetchDataGrid ~ pageNumber:", pageNumber)
            const page_number = pageNumber ? pageNumber : 1;
            const pageSize = psize ? psize : 20;
            setIsLoading(true);
            const res = await withRFSToken.post(ULDOPERATIONGRID, { page: page_number, page_size: pageSize });
            // .then(function (res) {
            if (res.data.response_code == 200) {
                setIsLoading(false);
                const innerData = res.data.response_data.operationData;
                const innerDataCount = res.data.response_data.pagination;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
                setGridData(innerData);
                setTotalRows({
                    pageSize: innerDataCount.page_size,
                    currPage: innerDataCount.current_page,
                    lastPage: innerDataCount.last_page,
                    totalUlds: innerDataCount.total
                });
                // window.$(gridRef.current).jqxGrid('pagesize', innerDataCount.page_size);
            }
            // });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };
    const loadDetails = async (uldNumber) => {
        try {
            const res = await withRFSToken.post(GETULDDETAILS, { uld_number: uldNumber })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    // console.log("🚀 ~ .then ~ innerData:654", innerData)
                    setSelectedRowData(innerData);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if (isMounted.current) return;
        // Call the fetchData function to fetch data from the API
        fetchDataGrid();
        isMounted.current = true;
    }, []);
    const fetchDamageType = async () => {
        try {
            const res = await withRFSToken.post(GETDAMAGETYPE, {})
                .then(function (res) {
                    const innerData = res.data.response_data;
                    console.log("🚀 ~ .then ~ innerData:650", innerData)
                    setDamageType(innerData);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [gridSelect, setGridSelect] = useState([]);
    // const [activeTab, setActiveTab] = useState('Active');
    const gridRef = useRef(null);
    // Edit Uld
    const [showEdit, setShowEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const handleCloseEditModal = () => {
        setShowEdit(false);
        setEditData({});
    };
    // Edit Uld
    const pageSize = totalRows.pageSize;
    const totalRowsCount = totalRows.totalUlds;
    const initCurrentPage = totalRows.currPage;
    console.log('HHH', pageSize);
    useEffect(() => {

        if (gridRef.current) {
            if (gridData) {
                // const localData = gridData[activeTab].map(item => ({

                //     id: item.id,
                //     STN: item.station_code,
                //     Taddrs: item.station_addresses.length,
                //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
                // }));
                // console.log("🚀 ~ localData ~ localData:", localData)
                const source = {
                    datatype: 'json',
                    id: '',
                    datafields: [
                        { name: 'id', type: 'string' },
                        { name: 'uld_number', type: 'string' },
                        { name: 'type_code', type: 'string' },
                        { name: 'serial_number', type: 'string' },
                        { name: 'owner_code', type: 'string' },
                        { name: 'condition_code', type: 'string' },
                        { name: 'condition', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'flight_or_truck', type: 'string' },
                        { name: 'flight_number', type: 'string' },
                        { name: 'flight_date', type: 'date' },
                        { name: 'origin', type: 'string' },
                        { name: 'destination', type: 'string' },
                        { name: 'scm', type: 'string' },
                        { name: 'scm_date', type: 'date' },
                        { name: 'on_loan', type: 'string' },
                        { name: 'luc_out', type: 'string' },
                        { name: 'luc_final_dest', type: 'string' },
                        { name: 'luc_in', type: 'string' },
                        { name: 'return_station', type: 'string' },
                        { name: 'asset_type', type: 'string' },
                        { name: 'damage_type', type: 'string' },
                        { name: 'damage_desc', type: 'string' },

                        { name: 'reg', type: 'string' },
                        { name: 'register', type: 'string' },
                        { name: 'origin_handler_id', type: 'string' },
                        { name: 'destination_handler_id', type: 'string' },
                    ],
                    localdata: gridData,
                    totalrecords: totalRowsCount,
                    filter: function () {
                        // fetchDataGridFilter();
                        window.$(gridRef.current).jqxGrid('updatebounddata', 'filter');
                    },
                    sort: function () {
                        // update the grid and send a request to the server.
                        window.$(gridRef.current).jqxGrid('updatebounddata', 'sort')
                    },
                    beforeprocessing: function (data) {
                        if (data != null && data.length > 0) {
                            source.totalrecords = totalRowsCount // data[0].TotalRows
                        }
                    },
                    pager: function (pagenum, pagesize, oldpagenum) {
                        window.$(gridRef.current).jqxGrid('updatebounddata', 'data')
                        // callback called when a page or page size is changed.
                    },
                    // beforeprocessing: function (data) {
                    //     source.totalrecords = data[0].TotalRows;
                    // },
                    // sort: function () {
                    //     // update the grid and send a request to the server.
                    //     window.$(gridRef.current).jqxGrid('updatebounddata', 'sort');
                    // },
                    // processdata: function (dataAdapterOptions) {
                    //     // This is where you can process data, filter it manually if needed
                    //     const filters = window.$(gridRef.current).jqxGrid('getfilterinformation');
                    //     console.log("🚀 ~ useEffect ~ filters:", filters)
                    //     if (filters.length > 0) {
                    //         dataAdapterOptions.data = applyFilters(gridData, filters);
                    //     } else {
                    //         dataAdapterOptions.data = gridData;
                    //     }
                    // }
                };

                // const dataAdapter = new window.$.jqx.dataAdapter(source);
                const dataAdapter = new window.$.jqx.dataAdapter(source, {
                    autoBind: true, // Ensures data is loaded initially
                    beforeLoadComplete: function (records) {
                        // You can process records here before they are loaded into the grid
                        return records;
                    }
                });
                //     , {
                //     loadComplete: function (data) {
                //         // This is called after the data is loaded
                //     },
                //     loadError: function (xhr, status, error) {
                //         console.error('Data loading error:', error);
                //     },
                // });
                // console.log('localdata', source.localdata)
                const addfilter = () => {
                    var filtergroup = new window.$.jqx.filter();
                    var filter_or_operator = 0;
                    var filtervalue = '';
                    var filtercondition = 'contains';
                    var filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
                    filtergroup.addfilter(filter_or_operator, filter1);
                    window.$(gridRef.current).jqxGrid('addfilter', 'status', filtergroup);
                    window.$(gridRef.current).jqxGrid('applyfilters'); 
                }

                const columns = [
                    // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
                    {
                        text: 'Actions',
                        cellsrenderer: function () {
                            return '<div class="grid_actions"><img src="' + edit + '" class="row_edit" /></div>';
                        },
                        width: '5%', filterable: false,
                    },
                    { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
                    { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '8%', cellclassname: '', },
                    { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '10%', cellclassname: '', },
                    { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '8%', cellclassname: '', },
                    // { text: 'Condition Code', filtertype: 'textbox', datafield: 'condition_code', width: '10%', cellclassname: '', },
                    { text: 'Condition Code', filtertype: 'checkedlist', filterable: true,
                        // filteritems: [
                        //     { label: 'Serviceable', value: 'SER' },
                        //     { label: 'Damaged', value: 'DAM' },
                        //     { label: 'Discarded', value: 'DIS' }
                        // ],
                        // cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                        //     if (rowdata.condition_code === 'SER') {
                        //         return '<div class="jqx-grid-cell-left-align set_dock ">Servicable</div>';
                        //     } else if (rowdata.condition_code === 'DAM') {
                        //         return '<div class="jqx-grid-cell-left-align set_dock">Damaged</div>';
                        //     } else {
                        //         return '<div class="jqx-grid-cell-left-align set_dock">Discarded</div>';
                        //     }
                        // },
                        datafield: 'condition', width: '8%', cellclassname: '',
                    },
                    { text: 'Status', filtertype: 'checkedlist', datafield: 'status', width: '10%', cellclassname: '', },
                    { text: 'Flight Number', filtertype: 'textbox', datafield: 'flight_number', width: '10%', cellclassname: '', },
                    { text: 'Flight Date', filtertype: 'range', cellsformat: 'dd-MM-yyyy', datafield: 'flight_date', width: '10%', cellclassname: '', },
                    { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '6%', cellclassname: '', },
                    { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '8%', cellclassname: '', },
                    { text: 'SCM', filtertype: 'textbox', datafield: 'scm', width: '6%', cellclassname: '', },
                    { text: 'SCM Date', filtertype: 'date', cellsformat: 'dd-MM-yyyy', width: '6%', cellclassname: '', },
                    {
                        text: 'On Loan', filtertype: 'textbox', datafield: 'on_loan', width: '10%',
                        cellclassname: (row, column, value) => {
                            // Extract the number of days from the on_loan string
                            const daysMatch = value.match(/(\d+)\s*day\(s\)/);
                            if (daysMatch) {
                                const days = parseInt(daysMatch[1], 10);
                                if (days === 0) {
                                    return ''; // No class if the value is 0
                                }
                                return days < 10 ? 'lucinorange' : 'lucinred';
                            }
                            return ''; // Default class if format is unexpected
                        }
                    },
                    { text: 'LUC Out', filtertype: 'textbox', datafield: 'luc_out', width: '11%', cellclassname: '', },
                    { text: 'Final Dest.', filtertype: 'textbox', datafield: 'luc_final_dest', width: '11%', cellclassname: '', },
                    { text: 'LUC In', filtertype: 'textbox', datafield: 'luc_in', width: '11%', cellclassname: '', },
                    { text: 'Return Station', filtertype: 'textbox', datafield: 'return_station', width: '11%', cellclassname: '', },
                ];

                const gridOptions = {
                    width: '100%',
                    source: dataAdapter,
                    columns: columns,
                    autoheight: false,
                    height: 1040,
                    sortable: true,
                    altrows: false,
                    enablebrowserselection: true,
                    columnsresize: true,
                    enabletooltips: false,
                    editable: false,
                    selectionmode: 'checkbox',
                    pageable: true,
                    pageSize: pageSize,
                    pagesizeoptions: ['20', '50', '100', '200', '500'],
                    pagerButtonsCount: 5,
                    pagerMode: 'advanced',
                    filterable: true,
                    filterrowheight: 45,
                    showfilterrow: true,
                    rowsheight: 42,
                    columnsheight: 45,
                    virtualmode: true, // Enable virtual mode for large data sets
                    rendergridrows: function (params) {
                        // This is crucial for virtual mode to handle the total number of rows correctly
                        // console.log("🚀 ~ useEffect ~ gridOptions.params:", params)
                        return params.data;
                        // const data = dataAdapter.records;
                        // return data.slice(params.startindex, params.endindex);
                    },
                    ready: function () {
                        addfilter()
                    }

                };

                window.$(gridRef.current).jqxGrid(gridOptions);
                window.$(gridRef.current).jqxGrid('gotopage', initCurrentPage - 1);
                window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

                // const updatePagerDetails = (pageNumber, pageSize) => {
                //     const gridElement = gridRef.current;

                //     if (gridElement) {
                //         const pagingInfo = window.$(gridElement).jqxGrid('getpaginginformation');
                //         console.log("Paging Information:", pagingInfo);
                //         const totalRecords = totalRows.totalUlds || 0;
                //         const startRecord = pageNumber * pageSize + 1;
                //         const endRecord = Math.min((pageNumber + 1) * pageSize, totalRecords);

                //         // Update the pagination display (if necessary)
                //         console.log(`Displaying records ${startRecord}-${endRecord} of ${totalRecords}`);
                //     }
                //     window.$(gridRef.current).jqxGrid('updatebounddata', 'cells');
                // };
                const handlePageChanged = (event) => {
                    const args = event.args;
                    console.log("🚀 ~ handlePageChanged ~ args:", args)
                    const pageNumber = args.pagenum;
                    const pageSize = args.pagesize;

                    if (pageSize !== totalRows.pageSize) {
                        fetchDataGrid(pageNumber, pageSize);
                    } else {
                        fetchDataGrid(pageNumber + 1, pageSize);
                    }

                    // setTotalRows(prevState => ({
                    //     ...prevState,
                    //     currPage: pageNumber + 1,
                    // }));
                    // updatePagerDetails(pageNumber, pageSize);
                };
                // const handlePageSizeChanged = (event) => {
                //     const args = event.args;
                //     console.log("🚀 ~ handlePageSizeChanged ~ args:", args)
                //     const pageNumber = args.pagenum;
                //     const pageSize = args.pagesize;
                //     fetchDataGrid(pageNumber + 1, pageSize);
                //     // setTotalRows(prevState => ({
                //     //     ...prevState,
                //     //     pageSize: pageSize,
                //     //     currPage: pageNumber + 1,
                //     // }));
                // };
                window.$(gridRef.current).on('pagechanged', handlePageChanged)
                // window.$(gridRef.current).on('pagesizechanged', handlePageSizeChanged);

                // window.$(gridRef.current).on('pagechanged', function (event) {
                //     const args = event.args;
                //     console.log("🚀 ~ args:zsss", args)
                //     const pageNumber = args.pagenum; // + 1;
                //     const pageSize = args.pagesize;
                //     fetchDataGrid(pageNumber, pageSize);
                // });

                window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
                    window.$(row).on('mouseover', () => {
                        window.$(row).addClass('row-hover');
                    });
                    window.$(row).on('mouseout', () => {
                        window.$(row).removeClass('row-hover');
                    });
                });

                // row selection by checkbox
                window.$(gridRef.current).on('rowselect', (event) => {
                    console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
                    if (event.args.row) {
                        const selectedRow = event.args.row;
                        const selectedRowId = selectedRow.uid;
                        if (!gridSelect.some(row => row.uid === selectedRowId)) {
                            setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
                        }
                    } else {
                        if (event.args.rowindex.length > 0) {
                            const allIds = gridData;
                            setGridSelect(allIds);
                        } else {
                            setGridSelect([]);
                        }
                    }
                });
                window.$(gridRef.current).on('rowunselect', (event) => {
                    console.log("🚀 ~ window.$ ~ event:1111", event.args.row)
                    if (event.args.row) {
                        const unselectedRowId = event.args.row.uld_number;
                        console.log("🚀 ~ window.$ ~ unselectedRowId:", unselectedRowId)
                        setGridSelect(prevSelection => prevSelection.filter(row => row.uld_number !== unselectedRowId));
                    }
                });
                window.$(gridRef.current).on('click', '.row_edit', function () {
                    // console.log("🚀 ~ event:01010", event)
                    const rowDiv = window.$(this).closest('[role="row"]');
                    // const cellDiv = this.id;
                    // console.log("🚀 ~ cellDiv:", rowDiv)
                    const rowIndex = window.$(rowDiv).attr('row-id');
                    const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
                    const dataToSend = {
                        uld_number: rowData.uld_number,
                        type_code: rowData.type_code,
                        serial_number: rowData.serial_number,
                        owner_code: rowData.owner_code,
                        condition_code: rowData.condition_code,
                        asset_type: rowData.asset_type
                    };
                    setEditData(dataToSend);
                    setShowEdit(true);

                });
                // End row selection 
                window.$(gridRef.current).off('cellclick');
                window.$(gridRef.current).on('cellclick', (event) => {
                    console.log('event', event);
                    const args = event.args.row.bounddata;
                    if (event.args.columnindex > 1) {

                        if (gridSelect) {
                            gridSelect.forEach(item => {
                                const selectedRowBoundIndex = item.boundindex;
                                window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
                            });
                            setGridSelect([]);
                        }
                        loadDetails(args.uld_number);
                    }
                });
                return () => {
                    window.$(gridRef.current).off('pagechanged', handlePageChanged);
                    // window.$(gridRef.current).on('pagesizechanged', handlePageSizeChanged);
                };

            } else {
                const columns = [
                    { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
                    { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
                ];
            }
        }

        // window.$(gridRef.current).on('cellclick', async (event) => {
        //     const args = event.args.row.bounddata;
        //     if (event.args.datafield == 'uld_number') {
        //         if (gridSelect) {
        //             gridSelect.forEach(item => {
        //                 const selectedRowBoundIndex = item.boundindex;
        //                 window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
        //             });
        //             setGridSelect([]);
        //         }
        //         // const sendRowId = gridData;
        //         loadDetails(args.uld_number);
        //         // try {
        //         //     const res = await withRFSToken.post(GETULDDETAILS, { uld_number: args.uld_number })
        //         //         .then(function (res) {
        //         //             const innerData = res.data.response_data;
        //         //             // console.log("🚀 ~ .then ~ innerData:654", innerData)
        //         //             setSelectedRowData(innerData);

        //         //         });
        //         // } catch (error) {
        //         //     console.error('Error fetching data:', error);
        //         // }
        //     }
        // });
    }, [gridData, selectedRowData, totalRows]);
    // console.log('101', gridSelect);
    // const [selectedColumns, setSelectedColumns] = useState([]);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const showDownloadModal = () => {
        setDownloadOpen(true);
    };
    const closeDownloadModal = () => {
        setDownloadOpen(false);
    };
    const fetchDataGridFilter = async (pageNumber, psize) => {
        try {
            console.log("🚀 ~ fetchDataGrid ~ pageNumber:", pageNumber)
            const page_number = pageNumber ? pageNumber : 1;
            const pageSize = psize ? psize : 20;
            const res = await withRFSToken.post(ULDOPERATIONGRID, { page: page_number, page_size: pageSize });
            // .then(function (res) {
            if (res.data.response_code == 200) {
                const innerData = res.data.response_data.operationData;
                const innerDataCount = res.data.response_data.pagination;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
                setGridData(innerData);
                setTotalRows({
                    pageSize: innerDataCount.page_size,
                    currPage: innerDataCount.current_page,
                    lastPage: innerDataCount.last_page,
                    totalUlds: innerDataCount.total
                });
                // window.$(gridRef.current).jqxGrid('pagesize', innerDataCount.page_size);
            }
            // });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // const columnsToExport = {
    //     // uld_number: 'ULD Number',
    //     type_code: 'Type Code',
    //     // serial_number: 'Serial Number',
    //     owner_code: 'Owner Code',
    //     condition_code: 'Condition Code',
    //     status: 'Status',
    //     origin: 'Origin',
    //     destination: 'Destination',
    //     // scm: 'SCM',
    //     // scm_date: 'SCM Date',
    //     // asset_type: 'Asset Type',
    //     // handler_name: 'Handler Name',
    //     // airline_name: 'Airline Name',
    //     // Add more columns here if needed
    // };
    // const handleColumnChange = (columnKey) => {
    //     setSelectedColumns(prevSelected =>
    //         prevSelected.includes(columnKey)
    //             ? prevSelected.filter(key => key !== columnKey)
    //             : [...prevSelected, columnKey]
    //     );
    // };
    // const exportToExcel = async () => {

    //     // try {
    //     //     const res = await withRFSToken.post(DOWNLOADULD, { tab_name: activeTab, fields: selectedColumns })
    //     //         .then(function (res) {
    //     //             const innerData = res.data.response_data.ulds;
    //     //             console.log("🚀 ~ .then ~ innerData:987", innerData)
    //     //             // const transformData = innerData ? innerData.map(data => ({ ...data, reg: 'R' })) : '';
    //     //             // setGridData({ Active: innerData });
    //     //             // setTotalRecords(res.data.response_data.total_ulds);
    //     //         });


    //     // } catch (error) {
    //     //     console.error('Error fetching data:', error);
    //     // }
    //     if (gridRef.current) {
    //         const excelData = window.$(gridRef.current).jqxGrid('getrows');

    //         // Filter and transform the grid data to include only the specified columns with desired names
    //         const filteredData = excelData.map(row => {
    //             const filteredRow = {};
    //             // Object.keys(columnsToExport).forEach(key => {
    //             //     filteredRow[columnsToExport[key]] = row[key];
    //             // });
    //             selectedColumns.forEach(key => {
    //                 filteredRow[columnsToExport[key]] = row[key];
    //             });
    //             return filteredRow;
    //         });
    //         const worksheet = XLSX.utils.json_to_sheet(filteredData);

    //         // Create a new workbook and append the worksheet
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, 'GridData');

    //         // Write the workbook to a file and trigger a download
    //         XLSX.writeFile(workbook, 'GridData.xlsx');
    //         setSelectedColumns([]);
    //         setModalOpen(false);
    //     }
    // };


    // ++++++++++++++++++++++++++++++++++++++++++++ HTML EDITOR

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,

    };

    // +++++++++++++++++++++++++++++++++++ Silk Slider

    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedFile('');
    };

    // ++++++++++++++++++++++++++++++++++++ Zoom Image
    const handleCloseClick = () => {
        setSelectedRowData(null);
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [changeFunction, setchangeFunction] = useState(null);
    const [action, setAction] = useState(null);

    const handleFieldChange = (id, fieldName, event) => {
        const { value, files } = event.target;

        setGridSelect(prevFormData =>
            prevFormData.map(stack => {
                if (stack.uid === id) {
                    const updatedStack = { ...stack, [fieldName]: value };
                    return updatedStack;
                }
                return stack;
            })
        );
    };

    const handleDropdownItemClick = async (pos, action) => {
        const keysToRemove = [
            'Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out',
            'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'
        ];

        // Remove keys from localStorage
        keysToRemove.forEach(key => localStorage.removeItem(key));

        // Helper function to save data and navigate to the given URL
        const saveAndNavigate = (action, data, url = '/ULDControl/ULDFunction') => {
            localStorage.setItem(action, JSON.stringify(data));
            localStorage.setItem('tab', 'operations');
            window.location.href = url;
        };

        // Helper function to create storage data for ULD
        const createStorageData = (ULDData, additionalForm = {}) => {
            const form = {
                flight_or_truck: ULDData.flight_or_truck === 'Flight' ? 'F' : 'T',
                flight_number: ULDData.flight_number,
                flight_date: ULDData.flight_date,
                origin: ULDData.origin,
                destination: ULDData.destination,
                ...additionalForm
            };
            return { ULD: [ULDData], Form: form };
        };

        // Handle operations for 'details' position
        const handleDetails = async () => {
            if (['Repair In', 'Repair Out', 'SCM', 'UCM Out', 'LUC In', 'LUC Out'].includes(action)) {
                const storageData = createStorageData(selectedRowData);
                saveAndNavigate(action, storageData);
            } else if (action === 'Lease Out') {
                if (selectedRowData.asset_type === 'Y') {
                    toast.error("ULDs with Asset Type Owned cannot go for Lease Out");
                } else {
                    const storageData = createStorageData(selectedRowData);
                    saveAndNavigate(action, storageData);
                }
            } else if (action === 'UCM In') {
                await handleUCMInAction(selectedRowData.uld_number);
            } else {
                setAction(action);
                setShowConfirmation(true);
                setchangeFunction(() => changeStatus);
            }
        };

        // Handle UCM In action with API call
        const handleUCMInAction = async (uldNumbers) => {
            try {
                const response = await withRFSToken.post(MVTUPDATE, { action_type: 'UI', ulds: uldNumbers });
                const { response_code, response_data } = response.data;
                if (response_code === 200) {
                    if (response_data.logic_type === 1) {
                        const storageData = {
                            ULD: response_data.ulds,
                            Form: response_data.Form
                        };
                        saveAndNavigate('UCM In', storageData);
                    } else {
                        setResponseAction(response_data);
                        setShowConfirmation(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Handle operations for 'top' position
        const handleTop = async () => {
            if (gridSelect.length === 0) {
                if (['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM In', 'UCM Out', 'LUC In', 'LUC Out'].includes(action)) {
                    const storageData = { ULD: [], Form: {} };
                    saveAndNavigate(action, storageData);
                } else {
                    toast.error('Please select at least one stock to operate.');
                }
            } else {
                if (['Repair In', 'Repair Out', 'SCM', 'LUC In', 'LUC Out'].includes(action)) {
                    const storageData = { ULD: gridSelect, Form: {} };
                    saveAndNavigate(action, storageData);
                } else if (action === 'Lease Out') {
                    const hasOwnedAssetType = gridSelect.some(item => item.asset_type === 'Y');
                    if (hasOwnedAssetType) {
                        toast.error("ULDs with asset type Owned selected, this action is not possible.");
                    } else {
                        const storageData = { ULD: gridSelect, Form: {} };
                        saveAndNavigate(action, storageData);
                    }
                } else if (action === 'UCM Out') {
                    // const flightData = gridSelect.flight_number;
                    // const [flight, flightNumber] = flightData.split('-') || ['', ''];
                    // const updateFormData = {
                    //     flight_or_truck: gridSelect.flight_or_truck || '',
                    //     flight_code: flight || '',
                    //     flight_number: flightNumber,
                    //     flight_date: gridSelect.flight_date || '',
                    //     origin: gridSelect.origin || '',
                    //     origin_handler: gridSelect.origin_handler || '',
                    //     destination: gridSelect.destination || '',
                    //     destination_handler: gridSelect.destination_handler || '',
                    //     flight_reg_number: gridSelect.flight_reg_number || ''
                    // }
                    const storageData = { ULD: gridSelect, Form: {} };
                    saveAndNavigate(action, storageData);
                } else if (action === 'UCM In') {
                    await handleUCMInAction(gridSelect.map(item => item.uld_number));
                } else {
                    if (gridSelect.some(item => item.status === action)) {
                        toast.error(`Status already ${action}`);
                    } else {
                        setAction(action);
                        setShowConfirmation(true);
                        if (action === 'Condition') fetchDamageType();
                        setchangeFunction(() => changeStatus);
                    }
                }
            }
        };

        // Main conditional logic based on position
        if (pos === 'details') {
            await handleDetails();
        } else if (pos === 'top') {
            await handleTop();
        }
    };

    const changeStatus = async (selectedIds) => {
        // console.log("🚀 ~ deleteSelectedRows ~ selectedIds:", selectedIds)

        // deleteRow(gridSelect);
        changeFunction(gridSelect)
        setShowConfirmation(false);


        //console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // console.log("🚀 ~ changeStatus ~ uniqueIds:", uniqueIds)
        // try {
        //   const res = await withToken.post(TRASH_ROW_IDS, {booking_ids: uniqueIds});
        //   console.log("responsehg==>", res);
        //   window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
        //   const updategrid = window.$(gridRef.current).jqxGrid();
        //   // setupGrid();
        //   reloadGrid();
        //   setGridSelect([]);
        //   window.$(gridRef.current).jqxGrid('clearselection');

        //   // setTimeout(() => {
        //   //    window.location.replace('/')  
        //   // },1000);

        // } catch (error) {
        //   console.error('Error sending request:', error);
        // }
    };

    const [conditionChange, setConditionChange] = useState('Serviceable');

    const handleConditionRadioChange = (e) => {
        setConditionChange(e.target.value);
    };
    const handleConfirmChange = async (actionType) => {
        // deleteRow(gridSelect);
        // selectedRowData ? selectedRowData.uld_number : gridSelect.map(item => item.uld_number)
        const conditionType = conditionChange && conditionChange == 'Serviceable' ? 'SER' : (conditionChange == 'Damage' ? 'DAM' : 'DIS')
        if (conditionChange == 'Serviceable') {
            const uldNumbers = gridSelect && gridSelect.map(item => ({ code: item.uld_number }));
            try {
                const res = await withRFSToken.post(UPDATECONDITION, { uld_codes: uldNumbers, condition_type: conditionType });
                if (res.data.response_code == 200) {
                    toast.success('Condition Updated');
                    fetchDataGrid();
                    setShowConfirmation(false);
                    // unselect Grid
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                    //   Grid unselect and blank variable End
                    // changeFunction(gridSelect)

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            const uldDam = gridSelect.map(item => ({ code: item.uld_number, damage_type: item.damage_type, desc: item.desc, files: item.files }));
            // console.log("🚀 ~ handleConfirmChange ~ uldDam:", uldDam)
            try {
                const res = await withRFSToken.post(UPDATECONDITION, { uld_codes: uldDam, condition_type: conditionType });
                if (res.data.response_code == 200) {
                    toast.success('Condition Updated');
                    fetchDataGrid();
                    setShowConfirmation(false);
                    // unselect Grid
                    gridSelect.forEach(item => {
                        const selectedRowBoundIndex = item.boundindex;
                        window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
                    });
                    setGridSelect([]);
                    //   Grid unselect and blank variable End
                    // changeFunction(gridSelect)

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        // changeFunction(gridSelect)
        // setShowConfirmation(false);
    };
    const handleActionConfirm = (logicType) => {
        if (logicType == 2) {
            const storageData = {
                "ULD": responseAction.ulds,  // ULD Details section
                "Form": {}  // Empty form section
            };
            localStorage.setItem('UCM In', JSON.stringify(storageData));
            localStorage.setItem('tab', 'operations');
            const fnPageUrl = '/ULDControl/ULDFunction'
            window.location.href = fnPageUrl;
        } else if (logicType == 3) {
            const storageData = {
                "ULD": responseAction.ulds,  // ULD Details section
                "Form": responseAction.Form  // Empty form section
            };
            localStorage.setItem('UCM In', JSON.stringify(storageData));
            localStorage.setItem('tab', 'operations');
            const fnPageUrl = '/ULDControl/ULDFunction'
            window.location.href = fnPageUrl;
        } else if (logicType == 4) {
            const storageData = {
                "ULD": responseAction.ulds,  // ULD Details section
                "Form": {}  // Empty form section
            };
            localStorage.setItem('UCM In', JSON.stringify(storageData));
            localStorage.setItem('tab', 'operations');
            const fnPageUrl = '/ULDControl/ULDFunction'
            window.location.href = fnPageUrl;
        } else if (logicType == 5) {
            const storageData = {
                "ULD": responseAction.ulds,  // ULD Details section
                "Form": responseAction.Form  // Empty form section
            };
            localStorage.setItem('UCM In', JSON.stringify(storageData));
            localStorage.setItem('tab', 'operations');
            const fnPageUrl = '/ULDControl/ULDFunction'
            window.location.href = fnPageUrl;
        } else if (logicType == 6) {
            const storageData = {
                "ULD": responseAction.ulds,  // ULD Details section
                "Form": responseAction.Form  // Empty form section
            };
            localStorage.setItem('UCM In', JSON.stringify(storageData));
            localStorage.setItem('tab', 'operations');
            const fnPageUrl = '/ULDControl/ULDFunction'
            window.location.href = fnPageUrl;
        }
    }
    const handleActionNoConfirm = (logicType) => {
        if (logicType == 2) {
            setShowConfirmation(false);
        } else if (logicType == 3) {
            setShowConfirmation(false);
        } else if (logicType == 4) {
            setShowConfirmation(false);
        } else if (logicType == 5) {
            setShowConfirmation(false);
        } else if (logicType == 6) {
            if (gridSelect) {
                const flightData = gridSelect.flight_number;
                const [flight, flightNumber] = flightData ? flightData.split('-') : ['', ''];
                const flight_date = gridSelect.flight_date ? new Date(gridSelect.flight_date).toISOString().split('T')[0] : '';
                const form_data = {
                    flight_or_truck: gridSelect.flight_or_truck || '',
                    flight: flight || '',           // Set the flight code
                    flight_number: flightNumber || '', // Set the flight number
                    flight_date: flight_date || '',
                    origin: gridSelect.origin || '',
                    origin_handler: gridSelect.origin_handler_id || '',
                    destination: gridSelect.destination || '',
                    destination_handler: gridSelect.destination_handler_id || '',
                }
                const storageData = {
                    "ULD": gridSelect,  // ULD Details section
                    // "Form": gridSelect.length > 0 ? gridSelect[0] : {}  // Empty form section
                    "Form": responseAction.Form  // Empty form section
                };
                localStorage.setItem('UCM In', JSON.stringify(storageData));
                localStorage.setItem('tab', 'operations');
                const fnPageUrl = '/ULDControl/ULDFunction'
                window.location.href = fnPageUrl;
            }

        }

    }

    const handleRemove = (indexToRemove) => {
        const idToRemove = gridSelect[indexToRemove];
        const selectedRowBoundIndex = idToRemove.boundindex;
        window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
        // setGridSelect(prevGridSelect => prevGridSelect.filter((_, index) => index !== indexToRemove));
        const newGridSelect = [...gridSelect.slice(0, indexToRemove), ...gridSelect.slice(indexToRemove + 1)];
        setGridSelect(newGridSelect);
    };

    // upload Section
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [base64Files, setBase64Files] = useState([]);

    // const imageModalUpload = (id) => {
    //     setSelectedRowId(id);
    //     setShowModalUpload(true)
    //     setFiles([])
    // }
    const imageModalUpload = (id) => {
        const selectedRowFiles = gridSelect.find(item => item.uid === id)?.files || [];
        setFiles(selectedRowFiles);
        setSelectedRowId(id); // Store the ID of the selected row
        setShowModalUpload(true);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        getBase64(droppedFiles[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
        base64Files.splice(index, 1);
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64Files((prevBase64Files) => [...prevBase64Files, reader.result]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithBase64 = [];

        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                filesWithBase64.push({ file, base64: reader.result });

                if (filesWithBase64.length === selectedFiles.length) {
                    setFiles((prevFiles) => [...prevFiles, ...filesWithBase64.map((obj) => obj.file)]);
                    setBase64Files((prevBase64Files) => [...prevBase64Files, ...filesWithBase64.map((obj) => obj.base64)]);
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const imageUpload = () => {
        setGridSelect(prevGridSelect =>
            prevGridSelect.map(item => {
                if (item.uid === selectedRowId) {
                    return { ...item, files: files, base64Files: base64Files };
                }
                return item;
            })
        );
        setShowModalUpload(false);
    };

    const movementHistoryClick = () => {
        localStorage.removeItem('MHistory');
        const storageData = {
            ULD: selectedRowData,  // ULD Details section
            Form: {}  // Empty form section
        };
        localStorage.setItem('MHistory', JSON.stringify(storageData));
        // localStorage.setItem('tab', 'operations');
        window.location.href = '/ULDControl/MovementHistory'
    };
    // upload forward
    const imageUploadRef = useRef(null);
    const browseClick = () => {
        if (imageUploadRef.current) {
            imageUploadRef.current.handleBrowseFiles();
        }
    };
    // History Details
    const handleDetailsClick = async (history) => {
        console.log("🚀 ~ handleDetailsClick ~ history:", history)

        if (!selectedRowData) {
            throw new Error('Selected row data is not available.');
        }

        try {
            const res = await withRFSToken.post(MVTHDETAILS, { uld_id: selectedRowData.uld_id, uld_status_id: history.uld_status_id })
                .then(function (res) {
                    const innerData = res.data.response_data;
                    localStorage.removeItem('MHistory');
                    localStorage.removeItem('MHDetails');
                    const storageData = {
                        ULD: selectedRowData,  // ULD Details section
                        Form: {}  // Empty form section
                    };
                    localStorage.setItem('MHistory', JSON.stringify(storageData));
                    const storageDataDetails = {
                        ULD: innerData.others_ulds,  // ULD Details section
                        Form: innerData.uld_movement_detail,
                        tab: 'All'  // Empty form section
                    };
                    localStorage.setItem('MHDetails', JSON.stringify(storageDataDetails));
                    // localStorage.setItem('tab', 'operations');
                    let detailsPageUrl;
                    const detailsType = history.operation_type || history.ucm_inout || history.luc_inout || history.repair_inout || history.lease_inout || history.station;
                    if (detailsType) {

                        detailsPageUrl = '/ULDControl/MovementHistorydetails';
                        window.location.href = detailsPageUrl;
                    } else {
                        console.log('No valid details type found');
                    }
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }



    };

    return (
        <>
            {showEdit && <EditUld edit_data={editData} reloadEdit={fetchDataGrid} onClose={handleCloseEditModal} />}
            {downloadOpen && <DownloadGrid download_sec='operations' onClose={closeDownloadModal} />}
            <div className="mb-3 nestedtabs rounded minh">
                <div className='d-flex'>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='adduld'>
                            <FontAwesomeIcon icon={faPlus} /> Add ULD <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/ULDControl/AddStock">New Stock</Dropdown.Item>
                            <Dropdown.Item href="/ULDControl/NewCharter">New Charter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className='action acmvt'>
                            <FontAwesomeIcon icon={faGear} /> MVT <FontAwesomeIcon icon={faChevronDown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'UCM Out')}>UCM Out</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'UCM In')}>UCM In</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'LUC Out')}>LUC Out</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'LUC In')}>LUC In</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair In')}>Repair In</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Repair Out')}>Repair Out</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'Lease Out')}>Lease Out</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDropdownItemClick('top', 'SCM')}>SCM</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button className="condition" variant="link" onClick={() => handleDropdownItemClick('top', 'Condition')}>
                        <span> <FontAwesomeIcon icon={faCircleCheck} /> Condition</span>
                    </button>
                    <button className="selected" variant="link" onClick={() => void (0)}>
                        Selected ULD <Badge>{gridSelect.length}</Badge>
                    </button>
                </div>
            </div>
            <div className='ulddown'>
                <button onClick={showDownloadModal}><img src={Export} /> Download</button>
            </div>

            <div className="maindiv mt-4">
                <div className='trashdonload'>
                    <Loader isLoading={isLoading} />
                    {!isLoading && <div ref={gridRef} className='bookinggrid' ></div>}
                </div>
                {selectedRowData && (
                    <div className='SecondDiv'>
                        {/* {console.log('55', selectedRowData)} */}
                        <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button>
                        <div className='girddtals_header'>
                            <h3>
                                {selectedRowData.uld_number} &nbsp; <span>{selectedRowData.status}</span>
                            </h3>
                            <div className='d-flex actbtn'>

                                <Button variant="light" onClick={browseClick} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faGear} /> MVT <FontAwesomeIcon icon={faChevronDown} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'UCM Out')}>UCM Out</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'UCM In')}>UCM In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'LUC Out')}>LUC Out</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'LUC In')}>LUC In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair In')}>Repair In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Repair Out')}>Repair In</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'Lease Out')}>Lease Out</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropdownItemClick('details', 'SCM')}>SCM</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className='d-flex justify-content-between rabord'>
                            <div className='firstbrd'>
                                <p>Origin</p>
                                <span>{selectedRowData.origin}</span>
                            </div>
                            <div className='middlebrd'>
                                <p>{selectedRowData.flight_date}</p>
                                <p><img src={Cargoplane} /></p>
                                <p>{selectedRowData.flight_number}</p>
                            </div>
                            <div className='lastbrd'>
                                <p>Destination</p>
                                <span>{selectedRowData.destination}</span>
                            </div>
                        </div>
                        <div className='movment_history'>
                            <h3>Movement History</h3> <Link to='#' onClick={movementHistoryClick}>View All</Link>
                        </div>
                        <div className='movment_list'>
                            <ul>
                                {selectedRowData && selectedRowData.movement_history.slice(0, 5).map((history, index) => (
                                    <li key={index}>
                                        <span className='movem'>{history.operation_type}</span>
                                        <span className='mvdate'>{history.operation_date}</span>
                                        <Link to='#' onClick={() => handleDetailsClick(history)}>Details</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* {selectedRowData && selectedRowData.operational_trucks.map((truck) => (
                                <div key={truck.truck_id} className='d-flex justify-content-between align-items-center opt_truck'>
                                    {console.log("🚀 ~ ExportActive ~ truck:", truck)}
                                    <div>
                                        <span>Truck No</span>
                                        <h4>{truck.truck_no}</h4>
                                    </div>
                                    <div className={truck.request_status == 'O' ? 'offer_reecv' : (truck.request_status == 'B' ? 'booked' : 'offer_rqst')}>
                                        <span>{truck.request_status == 'O' ? 'Offer Received' : (truck.request_status == 'B' ? 'Booked' : 'Requested')}</span>
                                    </div>
                                    <div>
                                        <Link>Details</Link>
                                    </div>
                                </div>
                            ))} */}
                        <ImageUpload
                            ref={imageUploadRef}
                            attachable_type={"uld"}
                            attachable_id={selectedRowData.uld_number}
                            attachments={selectedRowData.attachments}
                        />
                        <ActivityComments
                            commentable_type={"uld"}
                            commentable_id={selectedRowData.uld_number}
                            allactivities={selectedRowData.activities}
                            onReloadDetails={loadDetails}
                        />
                    </div>
                )}
            </div>
            {/* <Modal show={isModalOpen} onHide={() => setModalOpen(false)} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Download Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='downloadmd'>
                        {Object.keys(columnsToExport).map(columnKey => (
                            <label key={columnKey}>
                                <input
                                    type="checkbox"
                                    checked={selectedColumns.includes(columnKey)}
                                    onChange={() => handleColumnChange(columnKey)}
                                /> &nbsp;
                                {columnsToExport[columnKey]}
                            </label>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="primary" onClick={exportToExcel}>
                            Export
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal> */}
            <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)} className='addfrom change_type' centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='type_work'>

                        <div className='ra_condition'>
                            <div
                                className='dropfile'
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <h3>Drag and drop files here</h3>
                                <p>Or</p>
                                <button className='btn btn-primary drpbtn' onClick={handleBrowseFiles}>Browse Files</button>
                                <span>Maximum file size 4 MB</span>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    multiple
                                />
                            </div>
                            <div className='uploadbox'>
                                {files.map((file, index) => (
                                    <div key={index} className='uploadfil'>
                                        {file.type.startsWith('image/') ? (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt="Preview"
                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                            />
                                        ) : (
                                            <span className='d-flex'><img src={Docmnt} className='fileimg' /> {file.name}</span>
                                        )}
                                        <button className='dltbtn' type="button" onClick={() => handleDeleteFile(index)}> <FontAwesomeIcon icon={faCircleXmark} color='#656565' /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50 mx-auto are_bottom'>
                        <Button variant="primary" onClick={imageUpload}>
                            Upload
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className={action === 'Condition' ? 'addfrom in_oparation' : 'addfrom change_type'} show={showConfirmation} onHide={() => setShowConfirmation(false)} centered size={action === 'Condition' ? 'lg' : 'md'}>
                <Modal.Header closeButton>
                    {action === 'Condition' ? (
                        <>
                            <Modal.Title>{action}</Modal.Title>
                            <p>Select Condition by selecting the option.</p>
                        </>
                    ) : ''
                    }
                    {/* {['Repair', 'Lease Out', 'Condition'].includes(action) ? action : null} */}
                </Modal.Header>
                <Modal.Body>
                    {action == 'Condition' ?
                        (
                            <>
                                <Form.Group controlId="dropdown" className='mb-3 ck_condition'>
                                    <Form.Check
                                        label="Serviceable"
                                        name="condition_type"
                                        type='radio'
                                        id='Serviceable'
                                        value="Serviceable"
                                        checked={conditionChange === 'Serviceable'}
                                        onChange={handleConditionRadioChange}
                                    />
                                    <Form.Check
                                        label="Damaged"
                                        name="condition_type"
                                        type='radio'
                                        id='Damage'
                                        value="Damage"
                                        checked={conditionChange === 'Damage'}
                                        onChange={handleConditionRadioChange}
                                    />
                                    <Form.Check
                                        label="Discarded"
                                        name="condition_type"
                                        type='radio'
                                        id='Discarded'
                                        value="Discarded"
                                        checked={conditionChange === 'Discarded'}
                                        onChange={handleConditionRadioChange}
                                    />
                                </Form.Group>

                                <div className={conditionChange == 'Serviceable' ? 'd-flex flex-row bd-highlight inop serv_width' : 'd-flex flex-column bd-highlight inop'}>
                                    {gridSelect && gridSelect.map((num, index) => (
                                        <>
                                            {conditionChange == 'Serviceable' ? (
                                                <div key={index} className='inbox d-flex justify-content-between align-items-center'>
                                                    <span>{num.uld_number}</span>

                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ) : (conditionChange == 'Damage' ? (
                                                <div key={index} className='inbox d-flex justify-content-between align-items-center damage_w'>
                                                    <div><span>{num.uld_number}</span></div>
                                                    <div>
                                                        {/* <Form.Select name='damage_type' id={num.uid} onChange={(event) => handleFieldChange(num.uid, 'damage_type', event)}>
                                                            <option>Damag Type</option>
                                                            {damageType &&
                                                                Object.entries(damageType).map((option, index) =>
                                                                    <option key={index} value={option[0]}>{option[0]} - {option[1]}</option>
                                                                )
                                                            }
                                                        </Form.Select> */}
                                                        <Select
                                                            options={damageType}
                                                            onChange={(selected) => {
                                                                const updatedData = gridSelect.map(item => {
                                                                    if (item.uid === num.uid) {
                                                                        return { ...item, damage_type: selected.value };
                                                                    }
                                                                    return item;
                                                                });
                                                                setGridSelect(updatedData);
                                                            }}
                                                            placeholder="Damage Type"
                                                            className='autoslct'
                                                            name="damage_type"
                                                        // value={damageType.find(option => option.value === num.damage_type)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Form.Control type="text" placeholder="Damage Description" name='desc' onChange={(event) => handleFieldChange(num.uid, 'desc', event)} />
                                                    </div>
                                                    <div>
                                                        <Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uid)}>Upload file</Button>
                                                    </div>

                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ) : (
                                                <div key={index} className='inbox d-flex justify-content-between align-items-center damage_w'>

                                                    <div><span>{num.uld_number}</span></div>

                                                    <div><Form.Control type="email" placeholder="Discarded Description" className='dis_des' name='desc' onChange={(event) => handleFieldChange(num.uid, 'desc', event)} /></div>

                                                    <div><Button variant="outline-primary" className='upfile' onClick={() => imageModalUpload(num.uid)}>Upload file</Button></div>

                                                    <div><img src={Trash} alt='' onClick={() => handleRemove(index)} /></div>
                                                </div>
                                            ))}

                                        </>
                                    ))}
                                </div>

                            </>
                        ) : (
                            <>
                                <div className='d-flex flex-wrap'>
                                    <h3>{responseAction ? responseAction.logic_msg : ''}</h3>
                                    {/* <p>Are you sure you want to continue?</p> */}
                                    <Table striped className='uldlist'>
                                        <thead>
                                            <tr>
                                                <th>ULD Number</th>
                                                <th>Flight Number</th>
                                                <th>Flight Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {responseAction && responseAction.ulds.map((value, index) => (
                                                <tr key={index}>
                                                    <td>{value.uld_number}</td>
                                                    <td>{value.flight_number}</td>
                                                    <td>{value.flight_date}</td>
                                                    <td>{value.uld_status}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                    {/* {gridSelect && gridSelect.map((num, index) => (
                                        <div key={index} className='inbox'>
                                            <span>{num.uld_number}</span>
                                            <img src={Trash} alt='' onClick={() => handleRemove(index)} />
                                        </div>
                                    ))}
                                    {selectedRowData && <div className='inbox'><span>{selectedRowData.uld_number}</span></div>} */}
                                </div>

                            </>
                        )}

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleCancelChange}> */}
                    {/* {['Repair', 'Lease In', 'Lease Out', 'Condition'].includes(action) ? 'Cancel' : 'No'} */}
                    {/* </Button> */}
                    {action == 'Condition' ? (
                        <>
                            <Button variant="primary" onClick={handleConfirmChange}>
                                Update Status {action}  'Update Status'
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className='d-flex w-50 mx-auto are_bottom'>
                                <Button variant="secondary" onClick={() => handleActionNoConfirm(responseAction.logic_type)}>
                                    No
                                </Button>
                                <Button variant="primary" onClick={() => handleActionConfirm(responseAction.logic_type)}>
                                    Yes
                                </Button>
                            </div>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Operations