import React, { useState, useEffect, useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import { withoutRFSToken, withRFSToken } from '../../../../../Services/axios.js';
import { ULDASSETSGRID, ULDOPERATIONGRID, ULDONAIRGRID, EDITONAIR, ULDTRANSFERGRID, MVTONLOAN } from '../../../../../Utils/conts.js';

const Overview = () => {

  // const queryParameters = new URLSearchParams(window.location.search);
  // const type = queryParameters.get("action");
  // const uldids = queryParameters.get("ids");
  const initialTab = localStorage.getItem("tab");

  const actionTypes = ['Repair In', 'Repair Out', 'SCM', 'Lease Out', 'UCM Out', 'UCM In', 'LUC In', 'LUC Out', 'Transfer', 'Request'];
  let type = null;


  // Loop through the actionTypes array
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the current key matches any of the action types
    if (actionTypes.includes(key)) {
      type = key;
      break;
    }
  }
  const isMounted = useRef(false);
  const [gridSelect, setGridSelect] = useState([]);
  const [totalRows, setTotalRows] = useState({});
  const [totalRowsR, setTotalRowsR] = useState({});
  const gridRef = useRef(null);
  const [activeTab, setActiveTab] = useState('Flight');
  const gridRefs = {
    Flight: useRef(null),
    ULD: useRef(null),
  };

  const flightAdd = async () => {
    try {
      const res = await withRFSToken.post(EDITONAIR, { flight_number: gridSelect[0].flight_number, flight_date: gridSelect[0].flight_date })
        .then(function (res) {
          const innerData = res.data.response_data;
          const localStorageKey = type;
          const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
          const storageData = {
            "ULD": innerData.uld_level,  // ULD Details section
            "Form": innerData.Form,  // Empty form section
          };
          localStorage.setItem(localStorageKey, JSON.stringify(storageData));
          toast.success('Flight added successfully');
          const event = new Event('localStorageUpdated');
          window.dispatchEvent(event);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleAddUld = async(tabb) => {
    if (tabb == 'noTab' || tabb == 'ULD') {
      const localStorageKey = type;
      const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
      const checkAssetType = gridSelect.map(ast => ast.asset_type)
      if (localStorageKey == 'Lease Out' && checkAssetType == 'Y') {
        toast.error('ULDs with asset type Owned selected, this action is not possible.')
      } else if (['Transfer','Request'].includes(localStorageKey)) {
        const actionType = localStorageKey == 'Transfer' ? 'ST' : 'TR'
        try {
          const response = await withRFSToken.post(MVTONLOAN, { action_type: actionType, ulds: gridSelect.map(item => item.uld_number) });
          const innerData = response.data;
          const { response_code, response_data } = response.data;
          if (response_code === 200) {
            // if (response_data.logic_type === 1) {
            const storageData = {
              ULD: response_data.uldInfo,
              Form: response_data.Form
            };
            localStorage.setItem(localStorageKey, JSON.stringify(storageData));
          toast.success('ULDs added successfully');
          const event = new Event('localStorageUpdated');
          window.dispatchEvent(event);
            
          }
          else { toast.error(response.data.response_msg) }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        const existingULDs = existingData.ULD || [];
        const existingUldNumbers = new Set(existingULDs.map(uld => uld.uld_number));
        const duplicateUld = gridSelect.filter(newUld => existingUldNumbers.has(newUld.uld_number));

        const { duplicates, nonDuplicates } = gridSelect.reduce((acc, newUld) => {
          if (existingUldNumbers.has(newUld.uld_number)) {
            acc.duplicates.push(newUld);
          } else {
            acc.nonDuplicates.push(newUld);
          }
          return acc;
        }, { duplicates: [], nonDuplicates: [] });
        if (nonDuplicates.length > 0) {
          const updatedULDArray = [...existingULDs, ...nonDuplicates];
          const updatedData = {
            ...existingData,
            ULD: updatedULDArray
          };
          // if (duplicateUld.length > 0) {
          //   // Show error toast if duplicates are found
          //   toast.error(`ULD numbers already exist: ${duplicateUld.map(uld => uld.uld_number).join(', ')}`);
          // } else {
          // const updatedULDArray = [...existingData.ULD, ...gridSelect];
          // const updatedData = {
          //   ...existingData,
          //   ULD: updatedULDArray
          // };
          localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
          toast.success('ULDs added successfully');
          const event = new Event('localStorageUpdated');
          window.dispatchEvent(event);
        }
      }

    } else {
      const existingFlightNumbers = gridSelect.map(flt => flt.flight_number);
      if (existingFlightNumbers.length > 1) {
        toast.error('You Can process only one flight at a time');
      } else {
        flightAdd();
      }
      // const localStorageKey = type;
      // const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || [];

      // const existingFlightNumbers = gridSelect.map(flt => flt.flight_number);
      // const selectedFlightData = gridSelect[0];
      // if (existingFlightNumbers.length > 1) {
      //   // Show error toast if duplicates are found
      //   toast.error('Only One Flight Can Be Added');
      // } else {
      //   // const updatedULDArray = {...existingData.Form, ...gridSelect};
      //   const updatedData = {
      //     ...existingData,
      //     Form: selectedFlightData
      //   };
      //   localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
      //   toast.success('Flight added successfully');
      //   const event = new Event('localStorageUpdated');
      //   window.dispatchEvent(event);
      // }
    }

    if (tabb == 'noTab') {
      gridSelect.forEach(selectedItem => {
        const selectedRowBoundIndex = selectedItem.boundindex;
        if (selectedRowBoundIndex !== undefined && selectedRowBoundIndex !== null) {
          // console.log(`Unselecting row with boundindex: ${selectedRowBoundIndex} from ${gridName}`);
          window.$(gridRef.current).jqxGrid('unselectrow', selectedRowBoundIndex);
        } else {
          // console.warn(`Invalid boundindex for selected item in ${gridName}:`, selectedItem);
        }
      });
    } else {
      gridSelect.forEach(selectedItem => {
        const selectedRowBoundIndex = selectedItem.boundindex;
        if (selectedRowBoundIndex !== undefined && selectedRowBoundIndex !== null) {
          // console.log(`Unselecting row with boundindex: ${selectedRowBoundIndex} from ${gridName}`);
          window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
        } else {
          // console.warn(`Invalid boundindex for selected item in ${gridName}:`, selectedItem);
        }
      });
    }

  }
  const [singleGrid, setSingleGrid] = useState([]);
  const [tabGrid, setTabGrid] = useState({
    Flight: [],
    ULD: []
  });

  const fetchDataGrid = async (pageNumber, psize) => {
    if (['Repair In', 'Repair Out', 'Lease Out'].includes(type)) {
      const page_number = pageNumber ? pageNumber : 1;
      const pageSize = psize ? psize : 20;
      try {
        const res = await withRFSToken.post(ULDASSETSGRID, { page: page_number, type: 'A', page_size: pageSize });
        if (res.data.response_code == 200) {
          const innerData = res.data.response_data.ulds;
          setSingleGrid(innerData);
          setTotalRows({
            pageSize: res.data.response_data.page_size,
            currPage: res.data.response_data.current_page,
            lastPage: res.data.response_data.last_page,
            totalUlds: res.data.response_data.total_ulds
          });
        } else { toast.error(res.data.response_msg) }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (['SCM', 'UCM Out', 'LUC In', 'LUC Out'].includes(type)) {
      try {
        const res = await withRFSToken.post(ULDOPERATIONGRID, {});
        if (res.data.response_code == 200) {
          const innerData = res.data.response_data.operationData;
          const innerDataCount = res.data.response_data.pagination;
          setSingleGrid(innerData);
          setTotalRows({
            pageSize: innerDataCount.page_size,
            currPage: innerDataCount.current_page,
            lastPage: innerDataCount.last_page,
            totalUlds: innerDataCount.total
          });
        } else { toast.error(res.data.response_msg) }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (type == 'UCM In') {
      try {
        const res = await withRFSToken.post(ULDONAIRGRID, {});
        if (res.data.response_code == 200) {
          const innerData = res.data.response_data.flights;
          // const uldData = innerData && innerData.flatMap(uld => uld.uld_level);
          setTabGrid({ ...tabGrid, Flight: innerData });
        } else { toast.error(res.data.response_msg) }



      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (['Transfer', 'Request'].includes(type)) {
      const agentType = type == 'Transfer' ? 'I' : 'E';
      try {
        const res = await withRFSToken.post(ULDTRANSFERGRID, { agent_type: agentType });
        if (res.data.response_code == 200) {
          const innerData = res.data.response_data.agents_data;
          const innerDataCount = res.data.response_data.pagination;
          // const uldData = innerData && innerData.flatMap(uld => uld.uld_level);
          setSingleGrid(innerData);
          setTotalRows({
            pageSize: innerDataCount.page_size,
            currPage: innerDataCount.current_page,
            lastPage: innerDataCount.last_page,
            totalUlds: innerDataCount.total
          });
        } else { toast.error(res.data.response_msg) }



      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  useEffect(() => {
    if (isMounted.current) return;
    fetchDataGrid();
    // Call the fetchData function to fetch data from the API
    isMounted.current = true;
  }, []);
  useEffect(() => { }, [singleGrid])
  // useEffect(() => {
  //   const fetchDataGrid = async () => {
  //     try {
  //       const res = await withRFSToken.post(ULDASSETSGRID, {type: 'A'})
  //         .then(function (res) {
  //           // if (res.response_code == 200) {
  //           const innerData = res.data.response_data; 
  //           setSingleGrid(innerData);
  //           // }

  //         });

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   // Call the fetchData function to fetch data from the API
  //   fetchDataGrid();
  // }, [])

  const fetchTabULD = async () => {
    try {
      const res = await withRFSToken.post(ULDOPERATIONGRID, {});
      if (res.data.response_code == 200) {
        const innerData = res.data.response_data.operationData;
        const innerDataCount = res.data.response_data.pagination;
        setTabGrid({ ...tabGrid, ULD: innerData });
        setTotalRows({
          pageSize: innerDataCount.page_size,
          currPage: innerDataCount.current_page,
          lastPage: innerDataCount.last_page,
          totalUlds: innerDataCount.total
        });
      } else { toast.error(res.data.response_msg) }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab == 'ULD') {
      fetchTabULD();
    }
    gridSelect.forEach(selectedItem => {
      const selectedRowBoundIndex = selectedItem.boundindex;
      if (selectedRowBoundIndex !== undefined && selectedRowBoundIndex !== null) {
        // console.log(`Unselecting row with boundindex: ${selectedRowBoundIndex} from ${gridName}`);
        window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedRowBoundIndex);
      } else {
        // console.warn(`Invalid boundindex for selected item in ${gridName}:`, selectedItem);
      }
    });
  };
  let pageSize;
  let totalRowsCount;
  let initCurrentPage;
  if (type !== 'UCM In') {
    pageSize = totalRows.pageSize;
    totalRowsCount = totalRows.totalUlds;
    initCurrentPage = totalRows.currPage;
  } else {
    pageSize = activeTab == 'Active' ? totalRows.pageSize : totalRowsR.pageSize;
    totalRowsCount = activeTab == 'Active' ? totalRows.totalUlds : totalRowsR.totalUlds;
    initCurrentPage = activeTab == 'Active' ? totalRows.currPage : totalRowsR.currPage;
  }




  useEffect(() => {
    if (type !== 'UCM In') {
      if (gridRef.current) {
        if (singleGrid) {

          const source = {
            datatype: 'json',
            id: '',
            datafields: [
              { name: 'id', type: 'string' },
              { name: 'uld_number', type: 'string' },
              { name: 'type_code', type: 'string' },
              { name: 'serial_number', type: 'string' },
              { name: 'owner_code', type: 'string' },
              { name: 'condition_code', type: 'string' },
              { name: 'condition', type: 'string' },
              { name: 'content_code', type: 'string' },
              { name: 'content', type: 'string' },
              { name: 'destination', type: 'string' },
              { name: 'damage_type', type: 'string' },
              { name: 'damage_desc', type: 'string' },
              { name: 'reg', type: 'string' },
              { name: 'asset_type', type: 'string' },
              { name: 'final_dest', type: 'string' },
              { name: 'luc_final_dest', type: 'string' },
              { name: 'received_by_name', type: 'string' },
            ],
            localdata: singleGrid,
            totalrecords: totalRowsCount
          };
          const dataAdapter = new window.$.jqx.dataAdapter(source);

          let columns;
          if (['Transfer', 'Request'].includes(type)){
            columns = [
              { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '22%', cellclassname: '', },
              { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '15%', cellclassname: '', },
              { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '20%', cellclassname: '', },
              { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '20%', cellclassname: '', },
              // { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '15%', cellclassname: '', },
              { text: 'Final Destination', filtertype: 'textbox', datafield: 'luc_final_dest', width: '15%', cellclassname: '', },
              { text: 'Received By', filtertype: 'textbox', datafield: 'received_by_name', width: '15%', cellclassname: '', },
            ];
          } else {
            columns = [
            { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '22%', cellclassname: '', },
            { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '15%', cellclassname: '', },
            { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '20%', cellclassname: '', },
            { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '20%', cellclassname: '', },
            { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '15%', cellclassname: '', },
          ];

          }
          
          const gridOptions = {
            width: '100%',
            source: dataAdapter,
            columns: columns,
            autoheight: false,
            height: 750,
            sortable: true,
            altrows: false,
            enabletooltips: true,
            editable: false,
            selectionmode: 'checkbox',
            pageable: true,
            pageSize: pageSize,
            pagesizeoptions: ['20', '50', '100', '200', '500'],
            pagerButtonsCount: 5,
            pagerMode: 'advanced',
            filterable: true,
            filterrowheight: 45,
            showfilterrow: true,
            rowsheight: 42,
            columnsheight: 45,
            virtualmode: true,
            rendergridrows: function (params) {
              // This is crucial for virtual mode to handle the total number of rows correctly
              return params.data;
            }
          };
          window.$(gridRef.current).jqxGrid(gridOptions);
          window.$(gridRef.current).jqxGrid('gotopage', initCurrentPage - 1);
          window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');
          // row selection by checkbox
          window.$(gridRef.current).on('rowselect', (event) => {
            if (event.args.row) {

              const selectedRow = event.args.row;
              const selectedRowId = selectedRow.id;
              // const uniqueIds = Array.from(new Set(selectedRow));
              if (!gridSelect.some(row => row.id === selectedRowId)) {
                setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
              }
              // setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
            } else {
              // Handle "Select All" case
              const allIds = singleGrid.map(row => row);
              // console.log("All IDs:", allIds);
              setGridSelect(allIds);
              // console.log("Select All triggered");
            }
          });
          // console.log('ggg', gridSelect);
          window.$(gridRef.current).on('rowunselect', (event) => {
            // console.log('UNS', event);
            if (event.args.row) {
              const unselectedRowId = event.args.row.id;
              setGridSelect(prevSelection => prevSelection.filter(row => row.id !== unselectedRowId));
            } else {
              // console.log('Deselect All');
              setGridSelect([]);
            }
          });
          // End row selection
          const handlePageChanged = (event) => {
            const args = event.args;
            const pageNumber = args.pagenum;
            const pageSize = args.pagesize;

            if (pageSize !== totalRows.pageSize) {
              fetchDataGrid(pageNumber, pageSize);
            } else {
              fetchDataGrid(pageNumber + 1, pageSize);
            }
          };
          window.$(gridRef.current).on('pagechanged', handlePageChanged);
          return () => {
            window.$(gridRef.current).off('pagechanged', handlePageChanged);
          };
        };
      }
    } else {
      if (gridRefs[activeTab]) {
        // if (tabGrid[activeTab]) {

        const source = {
          datatype: 'json',
          id: '',
          datafields: [
            { name: 'id', type: 'string' },
            { name: 'flight_or_truck', type: 'string' },
            { name: 'flight_number', type: 'string' },
            { name: 'flight_date', type: 'string' },
            { name: 'origin', type: 'string' },

            { name: 'uld_number', type: 'string' },
            { name: 'type_code', type: 'string' },
            { name: 'serial_number', type: 'string' },
            { name: 'owner_code', type: 'string' },
            { name: 'condition_code', type: 'string' },
            { name: 'destination', type: 'string' },
            { name: 'damage_type', type: 'string' },
            { name: 'damage_desc', type: 'string' },
            { name: 'reg', type: 'string' },
          ],
          localdata: tabGrid[activeTab],
        };
        const dataAdapter = new window.$.jqx.dataAdapter(source);

        let columns;
        if (activeTab === 'Flight') {
          columns = [
            { text: 'Flight Truck', filtertype: 'textbox', datafield: 'flight_or_truck', width: '22%', cellclassname: '', },
            { text: 'Flight Number', filtertype: 'textbox', datafield: 'flight_number', width: '15%', cellclassname: '', },
            { text: 'Flight Date', filtertype: 'textbox', datafield: 'flight_date', width: '20%', cellclassname: '', },
            { text: 'Origin', filtertype: 'textbox', datafield: 'origin', width: '20%', cellclassname: '', },
            { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '15%', cellclassname: '', },
          ];
        } else if (activeTab === 'ULD') {
          columns = [
            { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '22%', cellclassname: '', },
            { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '15%', cellclassname: '', },
            { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '20%', cellclassname: '', },
            { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '20%', cellclassname: '', },
            { text: 'Destination', filtertype: 'textbox', datafield: 'destination', width: '15%', cellclassname: '', },
          ];
        }
        const gridOptions = {
          width: '100%',
          source: dataAdapter,
          columns: columns,
          autoheight: false,
          height: 750,
          sortable: true,
          altrows: false,
          enabletooltips: true,
          editable: false,
          selectionmode: 'checkbox',
          pageable: false,
          pageSize: pageSize,
          pagesizeoptions: ['20', '50', '100', '200', '500'],
          pagerButtonsCount: 5,
          pagerMode: 'advanced',
          filterable: true,
          filterrowheight: 45,
          showfilterrow: true,
          rowsheight: 42,
          columnsheight: 45,
          virtualmode: true,
          rendergridrows: function (params) {
            // This is crucial for virtual mode to handle the total number of rows correctly
            return params.data;
          }
        };
        window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
        window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');
        // };
        // row selection by checkbox
        window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
          if (event.args.row) {

            const selectedRow = event.args.row;
            const selectedRowId = selectedRow.id;
            // const uniqueIds = Array.from(new Set(selectedRow));
            if (!gridSelect.some(row => row.id === selectedRowId)) {
              setGridSelect(prevGridSelect => Array.from(new Set([...prevGridSelect, selectedRow])));
            }
            // setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
          } else {
            // Handle "Select All" case
            const allIds = tabGrid[activeTab].map(row => row);
            // console.log("All IDs:", allIds);
            setGridSelect(allIds);
            // console.log("Select All triggered");
          }
        });
        // console.log('ggg', gridSelect);
        window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
          // console.log('UNS', event);
          if (event.args.row) {
            const unselectedRowId = event.args.row.id;
            setGridSelect(prevSelection => prevSelection.filter(row => row.id !== unselectedRowId));
          } else {
            // console.log('Deselect All');
            setGridSelect([]);
          }
        });
        // End row selection
      }
    }
  }, [type, activeTab, singleGrid, tabGrid, totalRows]);

  return (
    <> {console.log('2000', singleGrid)}
      <div className='mb-3 nestedtabs rounded'>
        <div className='d-flex justify-content-between charter_top'>
          <h3>ULD Overview</h3>
        </div>
        {type !== 'UCM In' ? (
          <>
            <div ref={gridRef} className='ovgrid' ></div>
            <div className='text-center mt-3'><Button variant="primary" className='aduld' onClick={() => handleAddUld('noTab')}>  Add to {type}  </Button></div>
          </>
        ) : (
          <div className='trashdonload'>
            {/* {console.log('000', activeTab)} */}
            <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="booking_tab">
              <Tab eventKey="Flight" title={<span>Flight Overview</span>} className='moveto'>
                <div ref={gridRefs.Flight} className='ovgrid' ></div>
                <div className='text-center mt-3'><Button variant="primary" className='aduld' onClick={() => handleAddUld(activeTab)}>  Add to {type}  </Button></div>
              </Tab>
              <Tab eventKey="ULD" title={<span>ULD Overview</span>} className='moveto'>
                <div ref={gridRefs.ULD} className='ovgrid' ></div>
                <div className='text-center mt-3'><Button variant="primary" className='aduld' onClick={() => handleAddUld(activeTab)}>  Add to {type}  </Button></div>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    </>
  )
}

export default Overview