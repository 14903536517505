import React, { useEffect, useState } from 'react'


const Crud = () => {

    const getLocalData = () => {
        const lists = localStorage.getItem("mytodolist");
        try {
            return lists ? JSON.parse(lists) : [];
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    }

    const [inputData, setInputData] = useState("");
    const [items, setItems] = useState(getLocalData());
    const [toggleButton, setToggleButton] = useState();

    const addItem = () => {
        if(!inputData){
            alert("Enter a text");
        }
        // else if(InputData && toggleButton){
        //     setItems(
        //         items.map((curElem) => {
        //             return curElem;
        //         }
                
        //     ));
        // }
        else{
            const newItemData = {
                id: new Date().getTime().toString(),
                name: inputData
            }  
            setItems([...items, newItemData]);
        }
        setInputData("");
    }
    

    const deleteItem = (index) =>{
        const updatedItem = items.filter((curElem) =>{
            return curElem.id !== index;
        });
        setItems(updatedItem);
    }

    const removeAll = () =>{
        setItems([]);
    }

    useEffect(() => {
        localStorage.setItem("mytodolist", JSON.stringify(items));
    }, [items]);
    console.log("🚀 ~ Crud ~ items:", items)

  return (
    <>
    <div className='addItem'>
        <input name='' value={inputData} onChange={(event) => setInputData(event.target.value)}  />
        <button onClick={addItem}>+</button>
    </div>
    
    
    <div className='showItem'>
    {items && items.map((curElem) => {
              return (
        <div className='eachItem' key={curElem.id}>
            {console.log(curElem)}
            <h3>{curElem.name}</h3>
            <button>Edit</button>
            <button onClick={() => deleteItem(curElem.id)}>del</button>
        </div>
              )}
    )}
    </div>
    <div className=''><button onClick={removeAll}>Remove All</button></div>
    
    </>
  )
}

export default Crud;