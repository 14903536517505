import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faEllipsis, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import Slider from "react-slick";
import Docmnt from '../../assets/images/google-docs.png';
import { Modal, Button, Alert } from 'react-bootstrap';
import { SAVEATTACHMENTS, DELETEATTACHMENTS } from '../../Utils/conts.js';

import { withoutRFSToken, withRFSToken } from '../../Services/axios.js';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
// const ImageUpload = ({ attachable_type, attachable_id, attachments }) => { // OLD
const ImageUpload = forwardRef(({ attachable_type, attachable_id, attachments }, ref) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState('');
    const [imgShow, setImgShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedDelFile, setSelectedDelFile] = useState({ index: null, id: null });

    useEffect(() => {
        if (attachments) {
            setFiles(attachments);
        }
    }, [attachments]);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleBrowseFiles = (e) => {
        if (e) {
            e.preventDefault();
        }
        fileInputRef.current.click();
    };
    useImperativeHandle(ref, () => ({
        handleBrowseFiles,
    }));
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithBase64 = [];
        const allBase64 = [];
        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                filesWithBase64.push({ file, base64: reader.result });
                allBase64.push(reader.result);
                if (filesWithBase64.length === selectedFiles.length) {
                    let allData = {
                        "attachable_id": attachable_id,
                        "attachable_type": attachable_type,
                        "files": allBase64
                    }
                    const res = await withRFSToken.post(SAVEATTACHMENTS, allData);
                    // .then(function (res) {
                    if (res.data.response_code == 200) {
                        const innerData = res.data.response_data;
                        setFiles((prevFiles) => [...prevFiles, ...innerData]);
                    } else {
                        toast.error(res.data.response_msg);
                    }

                    // });
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleFileClick = (file) => {
        setSelectedFile(file);
        setImgShow(true);
    };
    const handleDeleteFile = async (index, id) => {
        var allData = {
            "attachment_id": selectedDelFile.id
        }
        const res = await withRFSToken.post(DELETEATTACHMENTS, allData)
            .then(function (res) {
                if (res.data.response_code == 200) {
                    toast.success('Deleted Successfully');
                    setFiles((prevFiles) => {
                        const newFiles = [...prevFiles];
                        newFiles.splice(index, 1);
                        return newFiles;
                    });
                } else {
                    toast.error(res.data.response_msg);
                }

            });

    };
    const handleClose = () => {
        setImgShow(false);
        setSelectedFile('');
    };
    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
    };
    const handleDeleteImage = (index, id) => {
        setSelectedDelFile({ index, id });
        setShowConfirmation(true);
    };
    const closeConfirm = () => {
        setShowConfirmation(false);
        setSelectedDelFile({ index: null, id: null });
    };
    const handleConfirmation = (confirm) => {
        if (confirm) {
            handleDeleteFile(selectedDelFile.index, selectedDelFile.id);
            setShowConfirmation(false);
        } else {
            closeConfirm();
        }
    };
    const handleDownload = (filepath) => {
        let imageName = filepath.substring(filepath.lastIndexOf('/') + 1);
        saveAs(filepath, imageName)
    }

    
    return (
        <>
            <div className='my-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='mb-0'>Attachments ({files.length || 0})</h3>
                    <div className='d-flex rightdrop'>
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Open gallery view</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Download all</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div onDrop={handleDrop} onDragOver={handleDragOver} >
                            <button className='raplas' onClick={handleBrowseFiles}><FontAwesomeIcon icon={faPlus} /></button>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />


                        </div>
                    </div>
                </div>

                <div className='raslider'>
                    <div className="slider-container">
                        {files.length > 2 ? (
                            <>
                                <Slider {...settings}>
                                    {files.map((file, index) => {
                                        const fileName = file.file_path.match(/[^/]+$/)[0];
                                        const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
                                        const extension = fileName.split('.').pop();
                                        return (
                                            <>
                                                <div key={index} className='slideimg'>
                                                    {['jpeg', 'jpg', 'png', 'webp'].includes((file.file_path.split('.')[file.file_path.split('.').length - 1])) ? (
                                                        <>
                                                            <img src={file.file_path} alt="Preview"
                                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                // onClick={() => handleFileClick(URL.createObjectURL(file))} 
                                                                onClick={() => handleFileClick(file.file_path)}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className='d-flex rafilename'>
                                                                <img src={Docmnt} className='fileimg' />
                                                            </span>
                                                        </>
                                                    )
                                                    }
                                                    <span className='rafilename'>{baseName.length <= 7 ? fileName : `${baseName.substring(0, 7)}... .${extension}`}</span>
                                                    <a className='slide_dwnldbtn' href={file.file_path} download={1}><FontAwesomeIcon icon={faDownload} /></a>
                                                    {/* <button onClick={handleDownloadImage}>Download Image</button> */}
                                                    {/* {1 === 1 && ( */}
                                                    {file.is_comment !== 1 && file.is_editable === 1 && (
                                                        <button className='slide_dltbtn' type="button" onClick={() => handleDeleteImage(index, file.id)}> <FontAwesomeIcon icon={faXmark} color='#656565' /></button>
                                                    )}
                                                </div>
                                            </>
                                        );
                                    })}
                                </Slider>
                            </>
                        ) : (
                            <>
                                <div className='nonslide'>
                                    {files.map((file, index) => {
                                        const fileName = file.file_path.match(/[^/]+$/)[0];
                                        const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
                                        const extension = fileName.split('.').pop();
                                        return (
                                            <>
                                                <div key={index} className='slideimg'>
                                                    {['jpeg', 'jpg', 'png', 'webp'].includes((file.file_path.split('.')[file.file_path.split('.').length - 1])) ?
                                                        <>
                                                            <img src={file.file_path} alt="Preview"
                                                                style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }}
                                                                // onClick={() => handleFileClick(URL.createObjectURL(file))} 
                                                                onClick={() => handleFileClick(file.file_path)}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <span className='d-flex rafilename'>
                                                                <img src={Docmnt} className='fileimg' />
                                                            </span>
                                                        </>
                                                    }
                                                    <span className='rafilename'>{baseName.length <= 7 ? fileName : `${baseName.substring(0, 7)}... .${extension}`}</span>
                                                    <span className='slide_dwnldbtn' onClick={() => handleDownload(file.file_path)}><FontAwesomeIcon icon={faDownload} /></span>
                                                    
                                                    {/* {1 === 1 && ( */}
                                                    {file.is_comment !== 1 && file.is_editable === 1 && (
                                                        <button className='slide_dltbtn' type="button" onClick={() => handleDeleteImage(index, file.id)}>
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </button>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        )
                        }


                    </div>

                    <Modal show={imgShow} onHide={handleClose} centered className='imgmodal'>
                        <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                        <Modal.Body >
                            {console.log(selectedFile)}
                            <img src={selectedFile} className="img-fluid" alt="Selected" />
                        </Modal.Body>
                    </Modal>
                    <Modal show={showConfirmation} onHide={closeConfirm} className='addfrom change_type' centered>
                        <Modal.Header closeButton onClick={closeConfirm}></Modal.Header>
                        <Modal.Body >
                            <h3>The Attachment will be destroyed. </h3>
                            <p>Are you sure you want to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='d-flex w-50 mx-auto are_bottom'>
                                <Button variant="secondary" onClick={() => handleConfirmation(false)}>
                                    No
                                </Button>
                                <Button variant="primary" onClick={() => handleConfirmation(true)}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
        </>
    )
});


export default ImageUpload