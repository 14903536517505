import React from 'react';
import Header from "../../../Componenets/Header/Header";
import { LeftSettings } from '../LeftSettings';

export const NotificationSettings = () => {
  return (
    <>
      <div className="">
        <Header />
        <div className="main-panel">

          <div className="row">

            <LeftSettings />
            <div className='col-9 col-xxl-9 stting_right'>
              <div className='settingbg_right'>
                <h4>Notification & actions</h4>
                <p>Set your notification preferences for when you are in or away from the dashboard.</p>

                <div className="card mb-3 w-75">
                  <div className="card-header d-flex justify-content-between">
                    <div> Show notification from Thunder Coffee </div>
                    <div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                        <label claclassNamess="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                        <label className="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label className="form-check-label" for="flexCheckDefault">
                        Order and purchase update
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                      <label className="form-check-label" for="flexCheckChecked">
                        Coffee subscription notification
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                      <label className="form-check-label" for="flexCheckChecked">
                        Exclusive offers and announcements
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                      <label className="form-check-label" for="flexCheckChecked">
                        Surveys
                      </label>
                    </div>
                  </div>
                </div>

                <div className="card mb-3 w-75">
                  <div className="card-header">
                    Email notifications from Thunder Coffee
                  </div>
                  <div className="card-body">
                    <p className="card-text">Get notification simply by checking the box to recive email from Thunder Coffee.
                      You can easily cancle at any time by selecting the unsubscribe option within any email.</p>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                      <label className="form-check-label" for="flexCheckChecked">
                        Newsletters  Announcement, sales and exclusive offers
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                      <label className="form-check-label" for="flexCheckChecked">
                        Order Details Reciept, invoice, and delivery status
                      </label>
                    </div>
                  </div>
                </div>

                <div className='w-75'>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='setting_switch'>
                      <h3>Floating Notifications</h3>
                      <p>Allow floating notification</p>
                    </div>
                    <div className='raswitch'>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='setting_switch'>
                      <h3>Lock screen Notifications</h3>
                      <p>Allow Lock screen notification</p>
                    </div>
                    <div className='raswitch'>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default NotificationSettings;