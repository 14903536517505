import React from 'react';
import Header from "../../../Componenets/Header/Header";
import {LeftccountSettings} from '../LeftSettings';

const UserSpecificSettings = () => {
  return (
    <>
    <div className="">
          <Header />
          <div className="main-panel">
  
            <div className="row">
  
              <LeftccountSettings />
              <div className='col-9 col-xxl-9 stting_right'>
                <div className='settingbg_right'>
                  <h4>User Specific Settings</h4>
                  <p>Set your notification preferences for when you are in or away from the dashboard.</p>
                  <div className='d-flex justify-content-between'>
                    
  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default UserSpecificSettings