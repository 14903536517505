import React, { useState, useEffect } from 'react';
import Header from "../../../Componenets/Header/Header";
import LeftMenu from '../../../Componenets/LeftMenu/LeftMenu';
import TopMenu from '../common/TopMenu';
import Export from "../../../assets/images/export.svg";
import Exportw from "../../../assets/images/exportw.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import UCM from './UCM';
import LUC from './LUC';
import SCM from './SCM';
import MessageLog from './MessageLog';

const History = () => {
    const initialTab = localStorage.getItem("Htab") || 'ucm';
    const [selectedButton, setSelectedButton] = useState(initialTab);
    useEffect(() => {
        setSelectedButton(initialTab);
    }, [initialTab]);
    const handleButtonClick = (button) => {
        setSelectedButton(button);
        localStorage.setItem('Htab', button);
    };
    return (
        <>
            <div className="">
                <Header />
                <div className="page-body-wrapper schedule">
                    <LeftMenu />
                    <div className="main-panel rounded">
                        <div className="mainTab">
                            <TopMenu />
                            <div className="">
                                <div className="allcontent-fl">
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div>
                                            <button className={selectedButton === 'ucm' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('ucm')}>
                                                <img src={selectedButton === 'ucm' ? Exportw : Export} /> <span>UCM</span>
                                            </button>
                                            <button className={selectedButton === 'luc' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('luc')}>
                                                <img src={selectedButton === 'luc' ? Exportw : Export} /> <span>LUC</span>
                                            </button>
                                            <button className={selectedButton === 'scm' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('scm')}>
                                                <img src={selectedButton === 'scm' ? Exportw : Export} /> <span>SCM</span>
                                            </button>
                                            <button className={selectedButton === 'msg_log' ? 'exportbtn me-3 active' : 'exportbtn me-3'} onClick={() => handleButtonClick('msg_log')}>
                                                <img src={selectedButton === 'msg_log' ? Exportw : Export} /> <span>Message Log</span>
                                            </button>
                                        </div>
                                        <div className='search'>
                                            <div className="form-group has-search">
                                                <span className="form-control-feedback"> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                                <input type="text" className="form-control" placeholder="Search ULD" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lcontent">
                                        {selectedButton === 'ucm' &&
                                            <UCM />
                                        }
                                        {selectedButton === 'luc' &&
                                            <LUC />
                                        }
                                        {selectedButton === 'scm' &&
                                            <SCM />
                                        }
                                        {selectedButton === 'msg_log' &&
                                            <MessageLog />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default History