import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Header from "../../../Componenets/Header/Header";
import { LeftSettings } from '../LeftSettings';
import { MultiSelect } from 'react-multi-select-component';
import StationDetails from './StationDetails';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Trash from "../../../assets/images/delete.svg";
import Arcv from "../../../assets/images/archive.svg";
import Active from "../../../assets/images/active.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faLink } from '@fortawesome/free-solid-svg-icons';

import { withoutRFSToken, withRFSToken, } from '../../../Services/axios.js';
import { ULDSETTINGSTATION, STATIONS, ADDSTATION, STATIONGRID, ADDTRUCKSCHEDULE, ARCSTATIONGRID, ACTSTATIONGRID, REMSTATIONGRID } from '../../../Utils/conts.js';


export const StationSettings = ({ onGridDataUpdate }) => {


  const [selected, setSelected] = useState([]);

  const handleChange = (selected) => {
    setSelected(selected);
  };

  // +++++++++++++++++++++++++++++++ Multiselect dropdown

  const gridRef = useRef(null);

  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    const fetchDataGrid = async () => {
      try {
        const res = await withRFSToken.post(ULDSETTINGSTATION, {})
          .then(function (res) {
            const innerData = res.data.response_data;
            setGridData(innerData);
          });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // Call the fetchData function to fetch data from the API
    fetchDataGrid();

  }, []);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [gridSelect, setGridSelect] = useState([]);

  const [formData, setFormData] = useState({
    port_Id: '',
    code: ''
  });

  // const handleRowClick = (rowData) => {
  //   setSelectedRowData(rowData);
  // };
  const handleDetailsBackClick = (rowData) => {
    // setSelectedRowData(rowData);
    setShowDetails(false);
  };

  useEffect(() => {

    if (gridRef.current && !showDetails) {

      if (gridData) {
        const localData = gridData.map((item, index) => ({
          id: item.station_id,
          STN: item.station_code,
          Taddrs: item.address_count, // item.station_addresses.length
          // Address: item.station_addresses // ?.join(', ')Join the address array into a string
        }));
        console.log("🚀 ~ localData ~ localData:", localData)
        const source = {
          datatype: 'json',
          id: '1',
          datafields: [
            { name: 'id', type: 'string' },
            { name: 'STN', type: 'string' },
            { name: 'Taddrs', type: 'string' },
            { name: 'Address', type: 'string' },
          ],
          localdata: localData,
        };

        const dataAdapter = new window.$.jqx.dataAdapter(source);
        console.log('localdata', source.localdata)
        const columns = [
          // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
          { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '40%', cellclassname: 'frcl', },
          { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%', cellclassname: 'frcl', },
        ];

        const gridOptions = {
          width: '100%',
          source: dataAdapter,
          columns: columns,
          autoheight: true,
          sortable: true,
          altrows: false,
          enabletooltips: true,
          editable: false,
          // selectionmode: 'checkbox',
          pageable: true,
          pageSize: 10,
          pagerButtonsCount: 5,
          pagerMode: 'advanced',
          filterable: true,
          filterrowheight: 45,
          showfilterrow: true,
          rowsheight: 42,
          columnsheight: 45,

        };

        window.$(gridRef.current).jqxGrid(gridOptions);
        window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

        window.$(gridRef.current).on('rowclick', (event) => {
          console.log('event', event);
          const args = event.args.row.bounddata;
          console.log('args', args);
          const sendRowId = gridData;
          console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
          setSelectedRowData({ args, station_id: args.id });

          setShowDetails(true);
        });
        window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
          window.$(row).on('mouseover', () => {
            window.$(row).addClass('row-hover');
          });
          window.$(row).on('mouseout', () => {
            window.$(row).removeClass('row-hover');
          });
        });
        // row selection by checkbox
        window.$(gridRef.current).on('rowselect', (event) => {
          console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)

          if (event.args.row) {

            const selectedRow = event.args.row.id;

            if (!gridSelect.includes(selectedRow)) {
              setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
            }
            setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
          } else {
            // Handle "Select All" case
            // const allIds = planData.map(row => row.booking_id);
            // console.log("All IDs:", allIds);
            // setGridSelect(allIds);
            console.log("Select All triggered");
          }
        });
        // console.log('ggg', gridSelect);
        window.$(gridRef.current).on('rowunselect', (event) => {
          if (event.args.row) {
            const unselectedRowId = event.args.row.id;
            setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
          } else {
            setGridSelect([]);
          }
        });
        // End row selection 

      } else {
        const columns = [
          { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
          { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
        ];
      }
    }
  }, [gridData, showDetails]);
  // row click function start


  // const reloadGrid = async () => {
  //   try {
  //     const res = await withRFSToken.post(STATIONGRID, {})
  //     .then(function(res) {
  //       const innerData = res.data.response_data; 
  //       setGridData(innerData);
  //     });

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };



  return (
    <>
      <div className="">
        <Header />
        <div className="main-panel">
          <div className="row">
            <LeftSettings />
            <div className='col-9 col-xxl-9 stting_right'>
              <div className='settingbg_right'>
                {!showDetails &&
                  <>
                    <h4>Station Settings</h4>
                    <p>Set your notification preferences for when you are in or away from the dashboard.</p>
                    <div className='row'>
                      <div className='col-6'>
                        <div className="station_grid">
                          <div ref={gridRef} className='staion_grid' ></div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {showDetails && (
                  <>
                    <button className='backbtn' onClick={handleDetailsBackClick}> <FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <StationDetails rowData={selectedRowData} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StationSettings;