import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Header from "../../Componenets/Header/Header";
import {LeftSettings} from './LeftSettings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus, faLink } from '@fortawesome/free-solid-svg-icons';

export const CarrierSettings = () => {
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState(() => {
        // Retrieve data from local storage or use an empty array if not present
        const storedData = localStorage.getItem('gridData');
        return storedData ? JSON.parse(storedData) : [];
    });
    const [modalShow, setModalShow] = useState(false);
    const [dropdownData, setDropdownData] = useState([
        { value: 'AMS', label: 'Amsterdam', address: ['AMSaddress1', 'AMSaddress2', 'AMSaddress3',] },
        { value: 'BRU', label: 'Brussels', address: ['BRUaddress1', 'BRUaddress2', 'BRUaddress3',] },
        { value: 'CDG', label: 'Charles de Gaulle', address: ['CDGaddress1', 'CDGaddress2'] },
        { value: 'FRA', label: 'Frankfurt', address: ['FRAaddress1', 'FRAaddress2', 'FRAaddress3',] },
    ]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('');

    const handleAddButtonClick = () => {
        setModalShow(true);
    };

    const handleModalClose = () => {
        setModalShow(false);
    };

    const handleDropdownChange = (e) => {
        setSelectedDropdownValue(e.target.value);
    };

    const handleAddToGrid = () => {
        if (selectedDropdownValue) {
            console.log('adrs', selectedDropdownValue);
            //   const newData = { STN: selectedDropdownValue, Taddrs: '2' }; // Adjust Taddrs value accordingly
            const selectedData = dropdownData.find((item) => item.value === selectedDropdownValue);
            const newData = { STN: selectedData.value, Taddrs: selectedData.address.length.toString(), address: selectedData.address, };
            setGridData((prevData) => [...prevData, newData]);
            // Update autosuggest suggestions in the parent component
            // onGridDataUpdate([...autosuggestSuggestions, { name: newData.STN, options: newData.address }]);
            // onGridDataUpdate([...gridData, newData]);
            setModalShow(false);
        }
    };
    useEffect(() => {
        // Update Taddrs based on the address count when gridData changes
        const updatedGridData = gridData.map((item) => {
            const matchingDropdownItem = dropdownData.find((dropdownItem) => dropdownItem.value === item.STN);
            const updatedItem = { ...item, Taddrs: matchingDropdownItem ? matchingDropdownItem.address.length.toString() : '0', address: matchingDropdownItem.address };
            return updatedItem;
        });
        if (!arraysEqual(updatedGridData, gridData)) {
            setGridData(updatedGridData);
        }
    }, [gridData, dropdownData]);
    const arraysEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

    useEffect(() => {
        if (gridRef.current) {
            const source = {
                datatype: 'json',
                datafields: [
                    { name: 'STN', type: 'string' },
                    { name: 'Taddrs', type: 'string' },
                    { name: 'Address', type: 'string' },
                ],
                localdata: gridData,
            };

            const dataAdapter = new window.$.jqx.dataAdapter(source);

            const columns = [
                { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '25%' },
                { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '40%' },
                
            ];

            const gridOptions = {
                width: '100%',
                source: dataAdapter,
                columns: columns,
                autoheight: true,
                sortable: true,
                altrows: false,
                enabletooltips: true,
                editable: false,
                selectionmode: 'checkbox',
                pageable: false,
                pageSize: 10,
                pagerButtonsCount: 5,
                pagerMode: 'advanced',
                filterable: true,
                filterrowheight: 40,
                showfilterrow: true,
                rowsheight: 42,
            };

            window.$(gridRef.current).jqxGrid(gridOptions);
            window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');
        }
    }, [gridData]);

    useEffect(() => {
        localStorage.setItem('gridData', JSON.stringify(gridData));
    }, [gridData]);

    return (
        <>
            <div className="">
                <Header />
                <div className="main-panel">

                    <div className="row">

                        <LeftSettings />
                        <div className='col-9 col-xxl-9 stting_right'>
                            <div className='settingbg_right'>

                            <h4>Carrier Settings</h4>
                <p>Set your notification preferences for when you are in or away from the dashboard.</p>
                            <div className='row'>
                            <div className='col-7'>
                                <div>
                                    <Button variant="primary createplan" onClick={handleAddButtonClick}>
                                    <FontAwesomeIcon icon={faPlus} /> Add Carrier
                                    </Button>
                                </div>

                                <div  className='staion_grid' ref={gridRef}></div>

                                </div>
                                </div>

                                <Modal show={modalShow} onHide={handleModalClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Item</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group controlId="dropdown">
                                            <Form.Label>Select Station</Form.Label>
                                            <Form.Control as="select" onChange={handleDropdownChange}>
                                                <option value="">Select...</option>
                                                {dropdownData.map((item) => (
                                                    <option key={item.value} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleModalClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleAddToGrid}>
                                            Add to Grid
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarrierSettings