import React, { useState, useEffect, useRef } from 'react';
import 'jqwidgets-framework/jqwidgets/jqxcore';
import 'jqwidgets-framework/jqwidgets/jqxdata';
import 'jqwidgets-framework/jqwidgets/jqxbuttons';
import 'jqwidgets-framework/jqwidgets/jqxscrollbar';
import 'jqwidgets-framework/jqwidgets/jqxmenu';
import 'jqwidgets-framework/jqwidgets/jqxgrid';
import 'jqwidgets-framework/jqwidgets/jqxgrid.pager';
import 'jqwidgets-framework/jqwidgets/jqxgrid.sort';
import 'jqwidgets-framework/jqwidgets/jqxgrid.edit';
import 'jqwidgets-framework/jqwidgets/jqxgrid.filter';
import 'jqwidgets-framework/jqwidgets/jqxgrid.selection';
import 'jqwidgets-framework/jqwidgets/jqxgrid.columnsresize';
import 'jqwidgets-framework/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-framework/jqwidgets/styles/jqx.dark.css';
import $ from 'jqwidgets-framework/jqwidgets/jqx-all';
import 'jqwidgets-framework/jqwidgets/jqxgrid.export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import Trash from "../../assets/images/delete.svg";
import Arcv from "../../assets/images/archive.svg";
import Export from "../../assets/images/export.svg";
import Rapdf from "../../assets/images/pdf.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";



import { withoutToken, withToken } from '../../Services/axios.js';
import { ARCHIVEGRID } from '../../Utils/conts.js';
import { DASHBOARD } from '../../Utils/conts.js';
import {ARCHIVE_ROW_IDS} from '../../Utils/conts.js';
import {TRASH_ROW_IDS} from '../../Utils/conts.js';

export const ArchivedGrid = () => {
  
  const gridRef = useRef(null);

  const [planData, setPlanData] = useState([]);
  const [gridSelect, setGridSelect] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = axios(process.env.REACT_APP_BASE_URL + ARCHIVEGRID, {
          method: 'POST',
          body: {},
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }).then(function (res) {

          const innerData = res.data.data;
          setPlanData(innerData);
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function to fetch data from the API
    fetchData();
    console.log('LdDAgTA===archive', planData);
    console.log('Load data archive', planData.data);
  }, []); // Empty dependency array ensures this effect runs only once, after initial render
  // import jsPDF from 'jspdf';

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState(null);

  useEffect(() => {

    const setupGrid = () => {

      const source = {
        datatype: 'json',
        id: 'shipmentID',
        datafields: [
          // { name: 'selected', type: 'bool' },
          { name: 'booking_id', type: 'string' },
          { name: 'file_paths', type: 'string' },
          { name: 'Type', type: 'string' },
          { name: 'handler_address', type: 'string' },
          { name: 'handler_warehouse', type: 'string' },
          { name: 'Movement', type: 'string' },
          { name: 'Vehicle', type: 'string' },
          { name: 'LoadUnload', type: 'string' },
          { name: 'AConcerned', type: 'string' },
          { name: 'RNumber', type: 'string' },
          { name: 'Booking', type: 'string' },
          { name: 'DName', type: 'string' },
          { name: 'time_slot', type: 'string' },
          { name: 'door', type: 'string' },
        ],
        // localdata: localData.data,
        localdata: planData,

      };
      const dataAdapter = new window.$.jqx.dataAdapter(source);
      console.log("🚀 ~ setupGrid ~ dataAdapter:", dataAdapter)

      const columns = [
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },filtertype: 'bool',
        // { text: 'Select', datafield: 'selected', columntype: 'checkbox', width: 50 },
        {
          text: 'Actions',
          cellsrenderer: function () {
            return '<div class="grid_actions"><img src="'+trash+'" class="row_delete" /></div>'; //<img src="'+edit+'" class="row_edit" />
          },
          width: '5%'
        },
        // { text: 'Action', datafield: 'action', datafield: 'action', width: '6%' },
        { text: 'Booking Number', filtertype: 'textbox', datafield: 'booking_id', width: '10%' },
        {
          text: 'Receipt',
          // filtertype: 'textbox',
          width: '5%',
          datafield: 'file_paths',
          cellsrenderer: (rowIndex, dataField, cellValue, defaultHtml, column, data) => {
            if(cellValue){
              defaultHtml = "<div style='text-align: center;line-height: 38px;'><a target='_blank' href=' "+ process.env.REACT_APP_BASE_URL_IMAGE+'ShipmentPlan/'+cellValue +" '>  <img style='width: 25px;' src='"+Rapdf+"'/> </a></div>";
            }
            return (
              defaultHtml
            );
          },
        },
        // { text: 'Receipt', filtertype: 'textbox', datafield: 'receipt', width: '5%' },
        { text: 'Type', filtertype: 'list', datafield: 'Type', width: '8%' },
        { text: 'Movement Type', filtertype: 'list', datafield: 'Movement', width: '12%' },
        // { text: 'Type', filtertype: 'list', datafield: 'Type', width: '14%' },
        { text: 'Handler', filtertype: 'textbox', datafield: 'handler_address', width: '12%' },
        { text: 'Handler Warehouse', filtertype: 'textbox', datafield: 'handler_warehouse', width: '14%' },
        { text: 'Driver Name', filtertype: 'textbox', datafield: 'DName', width: '8%' },
        { text: 'Booking Date', filtertype: 'textbox', datafield: 'Booking', width: '10%' },
        { text: 'Time Slot', filtertype: 'textbox', datafield: 'time_slot', width: '8%' },
        { text: 'Door', filtertype: 'textbox', datafield: 'door', width: '5%' },
        // { text: 'Vehicle', filtertype: 'list', datafield: 'Vehicle', width: '14%' },
        // { text: 'Loading/Unloading', filtertype: 'textbox', datafield: 'LoadUnload', width: '8%' },
        // { text: 'Airline Concerned', filtertype: 'list', datafield: 'AConcerned', width: '14%' },
        // { text: 'Reference Number', filtertype: 'textbox', datafield: 'RNumber', width: '10%' },

        // { text: 'Driver Name', filtertype: 'textbox', datafield: 'DName', width: '14%' },
      ];
      // 2ND LEVEL DATA START
      const nestedColumns = [
        { text: 'Airway bill', datafield: 'ABill', width: '12%' },
        { text: 'Package', datafield: 'Pkg', width: '12%' },
        { text: 'Pieces', datafield: 'Pcs', width: '12%' },
        { text: 'Weight(KG)', datafield: 'Wgt', width: '12%' },
        { text: 'Custom Code', datafield: 'CCode', width: '12%' },
        { text: 'Special Handling Code', datafield: 'Shcode', width: '12%' },
        { text: 'Security Code', datafield: 'Securitycode', width: '12%' },
        // Add more columns as needed
      ];
      // const snend = [];
      const initrowdetails = (index, parentElement, gridElement, record) => {
        console.log('record', record);
        const id = record.uid.toString();

        const nestedGridContainer = parentElement.children[0];

        // this.nestedGrids[index] = nestedGridContainer;
        let filtergroup = new window.$.jqx.filter();
        let filter_or_operator = 1;
        let filtervalue = id;
        let filtercondition = 'equal';
        let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

        let orders = dataAdapter.loadedData[id].shipment;
        console.log("🚀 ~ initrowdetails ~ orders:", orders)
        // let ordersbyid = [];
        // for (let i = 0; i < orders.length; i++) {
        //     let result = filter.evaluate(orders[i]['shipmentID']);
        //     if (result)
        //         ordersbyid.push(orders[i]);
        // }
        console.log('oddqata', orders)

        const nestedSource = {
          datatype: 'json',
          id: 'shipmentChildID',
          datafields: [
            { name: 'shipmentID', type: 'string' },
            { name: 'ABill', type: 'string' },
            { name: 'Pkg', type: 'string' },
            { name: 'Pcs', type: 'string' },
            { name: 'Wgt', type: 'string' },
            { name: 'CCode', type: 'string' },
            { name: 'Shcode', type: 'string' },
            { name: 'Securitycode', type: 'string' },
          ],
          localdata: orders,
          // [
          //   {
          //     ABill: '255 2548', Pkg: 'Package', Pcs: '30', Wgt: '205',
          //     CCode: 'ZZZ-1111', Shcode: '#325648', Securitycode: 'ASR2365',
          //   },
          //   {
          //     ABill: '200 2548', Pkg: 'Package', Pcs: '30', Wgt: '205',
          //     CCode: 'ZZZ-1111', Shcode: '#325648', Securitycode: 'ASR2365',
          //   }
          // ]
        }

        const nestedDataAdapter = new window.$.jqx.dataAdapter(nestedSource);
        console.log("🚀 ~ initrowdetails ~ nestedDataAdapter:", nestedDataAdapter);

        // 3RD LEVEL DATA START

        const nestedHawbColumns = [
          { text: 'House Airway bill', datafield: 'HABill', width: '12%' },
          { text: 'Pieces', datafield: 'HPieces', width: '12%' },
          { text: 'Weight(KG)', datafield: 'HWgt', width: '12%' },
          // Add more columns as needed
        ];

        const initrowhawbdetails = (index, parentElement, gridElement, record) => {
          const id = record.uid.toString();
          console.log("🚀 ~ initrowhawbdetails ~ id:", id)

          const nestedGridContainer = parentElement.children[0];

          let orderssecond = nestedDataAdapter.loadedData[id].hawBills;
          console.log("🚀 ~ initrowhawbdetails ~ orderssecond:", orderssecond)
          // const thrd = snend
          console.log('orderssecond', orderssecond);
          const nestedSource = {
            datatype: 'json',
            datafields: [
              { name: 'HABill', type: 'string' },
              { name: 'HPieces', type: 'string' },
              { name: 'HWgt', type: 'string' },
            ],
            localdata: orderssecond,
            // [
            //   { HABill: '255 3964', HPieces: '22', HWgt: '105', },
            //   { HABill: '200 3965', HPieces: '20', HWgt: '305', },
            // ]
          }

          const nestedhawbDataAdapter = new window.$.jqx.dataAdapter(nestedSource);

          const nestedGridOptions = {
            width: '100%',
            pageable: false,
            sortable: false,
            autoheight: true,
            source: nestedhawbDataAdapter,
            filterable: false,
            columns: nestedHawbColumns,
            rowsheight: 42,
          };
          window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
        };
        // 3RD LEVEL DATA END
        const nestedGridOptions = {
          width: '100%',
          pageable: false,
          sortable: false,
          autoheight: true,
          source: nestedDataAdapter,
          filterable: false,
          columns: nestedColumns,
          initrowdetails: initrowhawbdetails, // add initrowdetails callback
          rowdetails: true,
          rowsheight: 42,
          rowdetailstemplate: {
            rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
            // rowdetailsheight: 300,
            rowdetailshidden: true,

          },
        };
        window.$(nestedGridContainer).jqxGrid(nestedGridOptions);
      };
      // 2ND LEVEL DATA END
      const gridOptions = {
        width: '100%',
        source: dataAdapter,
        columns: columns,
        autoheight: true,
        sortable: true,
        altrows: false,
        enabletooltips: true,
        editable: false,
        selectionmode: 'checkbox',
        pageable: true, // Enable pagination
        pageSize: 10, // Set the page size
        pagerButtonsCount: 5, // Set the number of pager buttons
        pagerMode: 'advanced',
        filterable: true, // Enable filter row
        filterrowheight: 40,
        showfilterrow: true,
        rowsheight: 42,
        // nested start
        initrowdetails: initrowdetails, // add initrowdetails callback
        rowdetails: true,
        rowdetailstemplate: {
          rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
          // rowdetailsheight: 300,
          rowdetailshidden: true,
        },
      };

      if (gridRef.current && planData && planData.length > 0) {

        window.$(gridRef.current).jqxGrid(gridOptions);

      }
      // window.$(gridRef.current).jqxGrid(gridOptions);
      
      window.$(gridRef.current).on('click', '.row_edit', function (row) {
        
        const rowDiv = window.$(this).closest('[role="row"]');
        
        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        const rowBookingId = rowData.booking_id;
        const detailsPageUrl = `/EditShipmentPlan?id=${rowBookingId}`;  // ${itemId} Change "/details/" to the actual path of your details page
      // Navigate to the details page
      window.location.href = detailsPageUrl;
        
      });

      window.$(gridRef.current).on('click', '.row_delete', function () {
        const rowDiv = window.$(this).closest('[role="row"]');
        
        const rowIndex = window.$(rowDiv).attr('row-id');
        const rowData = window.$(gridRef.current).jqxGrid('getrowdata', rowIndex);
        const rowBookingId = rowData.booking_id;
        const rowBookingType = rowData.Type;
        console.log("🚀 ~ rowBookingId:", rowBookingId);
        // if (!gridSelect.includes(rowBookingId)) {
        // gridSelect.push(rowBookingId);
        // }
        // deleteRow(gridSelect);
        let updatedGridSelect = [];
        if (gridSelect.includes(rowBookingId)) {
            // Clear the existing bookingId
            updatedGridSelect = gridSelect.filter(id => id !== rowBookingId);
        } else {
            // Add the new bookingId to the selection
            updatedGridSelect = [...gridSelect, {booking_id:rowBookingId, type:rowBookingType}];
        }
        setGridSelect(updatedGridSelect);
        handleShowConfirmation(deleteRow);
        // Handle click for button 2
        console.log('Button 2 clicked');
      });

      window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');
    };



    setupGrid();

    // if (gridRef.current) {
    //   setupGrid();
    // }

  }, [planData]);

  // const handleArchiveButtonClick = () => {
  //   archiveSelectedRows(gridSelect);
  // };
  // const archiveSelectedRows = async(selectedIds) => {
  //   // Perform deletion logic here using the selected IDs
  //   console.log("Archive selected IDs:", selectedIds);
  //   // Now you can send the selected IDs to your API or perform any other action
  //   try {
  //     const res = await withToken.post(ARCHIVE_ROW_IDS, {booking_ids: selectedIds});
  //     console.log("responsehg==>", res);
  //     window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
  //     const updategrid = window.$(gridRef.current).jqxGrid();
  //     // setupGrid();
  //     reloadGrid();
  //     window.$(gridRef.current).jqxGrid('clearselection');
  //     onReloadChildComponents();
      
  
  //     // setTimeout(() => {
  //     //    window.location.replace('/')  
  //     // },1000);
     
  //   } catch (error) {
  //     console.error('Error sending request:', error);
  //   }
  // };
  window.$(gridRef.current).on('rowselect', (event) => {
    console.log("🚀 ~ window.$ ~ event:00000000", event)
    
      // var args = event.args;                
      // var rowBoundIndex = args.rowindex;               
      // var rowData = args.row;
      // setTimeout(function () { window.$(gridRef.current).jqxGrid('unselectrow', rowBoundIndex); }, 100);
    
      if (event.args.row) {
          
          const selectedRow = event.args.row.booking_id;
          const selectedRowType = event.args.row.Type;
          if (!gridSelect.includes(selectedRow)) {
                setGridSelect((prevGridSelect) => [...prevGridSelect, {booking_id:selectedRow, type:selectedRowType}]);
            }
          // setGridSelect([...gridSelect, {booking_id:selectedRow, type:selectedRowType}]);
      } else {
          // Handle "Select All" case
          const allIds = planData.map(row => row.booking_id);
            console.log("All IDs:", allIds);
            setGridSelect(allIds);
          console.log("Select All triggered");
      }
    }); 
    window.$(gridRef.current).on('rowunselect', (event) => {
      if (event.args.row) {
      const unselectedRowId = event.args.row.booking_id;
      setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
      }else{
        console.log('00000000000000000000');
        setGridSelect([]);
      }
    });
    console.log("🚀 ~ window.$ ~ gridSelect:", gridSelect)
  const handleDeleteButtonClick = () => {
    // deleteSelectedRows(gridSelect);
    handleShowConfirmation(deleteSelectedRows);
  };
  const deleteSelectedRows = async(selectedIds) => {
    const uniqueIds = Array.from(new Set(selectedIds));
    try {
      const res = await withToken.post(TRASH_ROW_IDS, {booking_ids: uniqueIds});
      console.log("responsehg==>", res);
      window.$(gridRef.current).jqxGrid('unselectrow', selectedIds);
      const updategrid = window.$(gridRef.current).jqxGrid();
      // setupGrid();
      reloadGrid();
      window.$(gridRef.current).jqxGrid('clearselection');
      // onReloadChildComponents();
      
  
      // setTimeout(() => {
      //    window.location.replace('/')  
      // },1000);
     
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };
  
  const deleteRow = async(selectedId) => {
          
    try {
      const res = await withToken.post(TRASH_ROW_IDS, {booking_ids: selectedId});
      console.log("responsehg=000=>", res);
      
      // setupGrid();
      reloadGrid();
      // onReloadChildComponents();         
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };
    
  const reloadGrid = async () => {
    try {
        const res = await axios.post(process.env.REACT_APP_BASE_URL + ARCHIVEGRID, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });
        const innerData = res.data.data;
        setPlanData(innerData);
        setGridSelect([]);
    } catch (error) {
        console.error('Error reloading data:', error);
    }
  };
  
  useEffect(() => {
    if (gridRef.current && planData) {
        const gridElement = gridRef.current;
        // Initialize jqxGrid with planData
    }
  }, [planData]);




  const exportToExcel = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'xls', 'GridData');
    }
  };
  const exportToPDF = () => {
    if (gridRef.current) {
      window.$(gridRef.current).jqxGrid('exportdata', 'pdf', 'GridData');
    }
  };

  const handleShowConfirmation = (deleteFunction) => {
    setShowConfirmation(true);
    setDeleteFunction(() => deleteFunction);
};
  const handleConfirmDelete = () => {
    // deleteRow(gridSelect);
    deleteFunction(gridSelect)
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setGridSelect([]);
    setShowConfirmation(false);
  };

  return (
    <>
    <div>
      <div className='shipment_grid'>
        {/* <button onClick={handleArchiveButtonClick}> <img src={Arcv} /> Active</button> */}
        <button onClick={handleDeleteButtonClick}><img src={Trash} /> Move to Trash</button>
        <button onClick={exportToPDF}><img src={Export} /> Download</button>

      </div>

      <div ref={gridRef}></div>
    </div>
    <Modal className='raintrac' show={showConfirmation} onHide={() => setShowConfirmation(false)}>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
      <h2>Confirm Delete</h2>
      <p>If you proceed with the change, all Booking PLan data in this section will be moved to Trash.
        Are you sure you want to continue?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancelDelete}>
        No
      </Button>
      <Button variant="primary" onClick={handleConfirmDelete}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
  </>
  );
}

export default ArchivedGrid;