import React, { useState, useEffect, useRef } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import Header from "../../../../Componenets/Header/Header";
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faPlus, faXmark, faCircle, faPaperclip, faBolt, faEllipsis, faLink, faCircleCheck, faChevronDown, faGear } from '@fortawesome/free-solid-svg-icons';
import Export from "../../../../assets/images/export.svg";
import View from "../../../../assets/images/view.svg";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import Cargoplane from "../../../../assets/images/plane.svg";
import Cuser from "../../../../assets/images/cuser.png";
import { Link } from 'react-router-dom';
import ActivityComments from '../../../../Componenets/common/ActivityComments';
import ImageUpload from '../../../../Componenets/common/ImageUpload';
import { GETULDDETAILS } from '../../../../Utils/conts';
import { withRFSToken } from '../../../../Services/axios';
import Accordion from 'react-bootstrap/Accordion';

const MHDetails = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const Uldid = queryParameters.get("id");
  const detailsType = queryParameters.get("type");
  const backTab = queryParameters.get("tab");

  let storedData = localStorage.getItem('MHDetails');

  if (storedData) {
    storedData = JSON.parse(storedData)
  }
  const [operationDate, setOperationDate] = useState('');
  const [operationTime, setOperationTime] = useState('');

  const [gridData, setGridData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [gridSelect, setGridSelect] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    const operationDateTime = storedData.Form.operation_date;
    if (operationDateTime) {
      const [opDate, opTime] = operationDateTime.split(' ');
      setOperationDate(opDate);
      setOperationTime(opTime);
    }
    setGridData(storedData.ULD)
  }, [])

  // const history = useHistory();
  // const location = useLocation();

  // if (Uldid){
  //     setUldNumber(Uldid);
  //     queryParameters.delete('uld_number');
  //     history.push({
  //         pathname: location.pathname,
  //       });
  //     window.location.replace('/ULDControl/MovementHistory')
  // }
  const [uldNumber, setUldNumber] = useState(Uldid);



  useEffect(() => {
    if (gridRef.current) {
      if (gridData) {
        // const localData = gridData[activeTab].map(item => ({

        //     id: item.id,
        //     STN: item.station_code,
        //     Taddrs: item.station_addresses.length,
        //     Address: item.station_addresses // ?.join(', ')Join the address array into a string
        // }));
        // console.log("🚀 ~ localData ~ localData:", localData)
        const source = {
          datatype: 'json',
          id: '',
          datafields: [
            { name: 'id', type: 'string' },
            { name: 'uld_number', type: 'string' },
            { name: 'type_code', type: 'string' },
            { name: 'serial_number', type: 'string' },
            { name: 'owner_code', type: 'string' },
            { name: 'content_code', type: 'string' },
            { name: 'final_desti', type: 'string' },
          ],
          localdata: gridData,
        };

        const dataAdapter = new window.$.jqx.dataAdapter(source);
        // console.log('localdata', source.localdata)
        const columns = [
          { text: 'ULD Number', filtertype: 'textbox', datafield: 'uld_number', width: '11%', cellclassname: '', },
          { text: 'Type Code', filtertype: 'textbox', datafield: 'type_code', width: '10%', cellclassname: '', },
          { text: 'Serial Number', filtertype: 'textbox', datafield: 'serial_number', width: '10%', cellclassname: '', },
          { text: 'Owner Code', filtertype: 'textbox', datafield: 'owner_code', width: '10%', cellclassname: '', },
          { text: 'Content Code', filtertype: 'textbox', datafield: 'content_code', width: '10%', cellclassname: '', },
          { text: 'Final Destination', filtertype: 'textbox', datafield: 'final_desti', width: '10%', cellclassname: '', },
        ];

        const gridOptions = {
          width: '100%',
          source: dataAdapter,
          columns: columns,
          autoheight: true,
          sortable: true,
          altrows: false,
          enabletooltips: true,
          editable: false,
          // selectionmode: 'checkbox',
          pageable: true,
          pageSize: 10,
          pagerButtonsCount: 5,
          pagerMode: 'advanced',
          filterable: true,
          filterrowheight: 45,
          showfilterrow: true,
          rowsheight: 42,
          columnsheight: 45,

        };

        window.$(gridRef.current).jqxGrid(gridOptions);
        window.$(gridRef.current).find('.jqx-grid-column-header').css('background-color', 'white');

        window.$(gridRef.current).find('div[role="row"]').each((index, row) => {
          window.$(row).on('mouseover', () => {
            window.$(row).addClass('row-hover');
          });
          window.$(row).on('mouseout', () => {
            window.$(row).removeClass('row-hover');
          });
        });

      } else {
        const columns = [
          { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
          { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
        ];
      }
    }
  }, [gridData, selectedRowData]);

  // upload forward
  const imageUploadRef = useRef(null);
  const browseClick = () => {
      if (imageUploadRef.current) {
          imageUploadRef.current.handleBrowseFiles();
      }
  };
  // end
  const loadDetails = async (uldNumber) => {
    try {
        const res = await withRFSToken.post(GETULDDETAILS, { uld_number: uldNumber })
            .then(function (res) {
                const innerData = res.data.response_data;
                // console.log("🚀 ~ .then ~ innerData:654", innerData)
                setSelectedRowData(innerData);

            });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

  return (
    <>
      <div className="">
        <Header />


        <div className='main-panel'>
          <Link to={'/ULDControl/MovementHistory'} className='stk_back'> <FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
          <div className='maindiv'>

            <div className='SecondDiv mt-3'>
              {/* <button className='close' onClick={handleCloseClick}> <FontAwesomeIcon icon={faXmark} /> </button> */}
              <div className='girddtals_header'>
                <h3>
                  {storedData.Form.operation_type}
                </h3>
                <div className='d-flex actbtn'>

                  <Button variant="light" onClick={browseClick} > <FontAwesomeIcon icon={faPaperclip} /> Attach</Button>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <FontAwesomeIcon icon={faBolt} /> Actions <FontAwesomeIcon icon={faChevronDown} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Edit Movement</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Delete Movement</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>

              </div>
              <div className='d-flex justify-content-between rabord'>
                <div className='firstbrd'>
                  <p>Origin</p>
                  <span>{storedData.Form.origin}</span>
                </div>
                <div className='middlebrd'>
                  <p>{operationDate}</p>
                  <p><img src={Cargoplane} /></p>
                  <p>{operationTime}</p>
                </div>
                <div className='lastbrd'>
                  <p>destination</p>
                  <span>{storedData.Form.destination}</span>
                </div>
              </div>

              <div className='opttrauck'>
              <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Movement Information</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. 

        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
              </div>

              <ImageUpload
                ref={imageUploadRef}
                attachable_type={"uld"}
                attachable_id={storedData.Form.uld_number}
                attachments={storedData.Form.attachments}
              />
              <ActivityComments
                commentable_type={"uld"}
                commentable_id={storedData.Form.uld_number}
                allactivities={storedData.Form.activities}
                onReloadDetails={loadDetails}
              />
            </div>
            <div className='trashdonload mt-3'>
              <div ref={gridRef} className='bookinggrid' ></div>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default MHDetails;