import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Header from "../../../Componenets/Header/Header";
import {LeftSettings} from '../LeftSettings';
import {MultiSelect} from 'react-multi-select-component';
import StationDetails from './StationDetails';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Trash from "../../../assets/images/delete.svg";
import Arcv from "../../../assets/images/archive.svg";
import Active from "../../../assets/images/active.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faLink } from '@fortawesome/free-solid-svg-icons';

import  {withoutRFSToken, withRFSToken, }   from '../../../Services/axios.js';
import { STATIONS, ADDSTATION, STATIONGRID, ADDTRUCKSCHEDULE, ARCSTATIONGRID, ACTSTATIONGRID, REMSTATIONGRID } from '../../../Utils/conts.js';


export const StationSettings = ({ onGridDataUpdate }) => {

  const [dropdownData, setDropdownData] = useState([]);

  // useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await withRFSToken.post(STATIONS, {})
        .then(function(res) {
          const innerData = res.data.response_data;
          const transformedData = innerData ? innerData.map(item => ({
            id: item.port_Id,
            value: item.code,
            label: item.code +'-'+ item.name
          })) : [];    
          setDropdownData(transformedData);
        });
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
      // Call the fetchData function to fetch data from the API
      // fetchData();
      
    // }, []);

  const [selectedValues, setSelectedValues] = useState([]);


  const handleSelect = (values) => {
    setSelectedValues(values);
  };
  // +++++++++++++++++++++++++++++++ Multiselect dropdown

  const options = [
    { label: 'BRU', value: 'option1' },
    { label: 'CDG', value: 'option2' },
    { label: 'AMS', value: 'option3' },
    { label: 'FRA', value: 'option4' },
    { label: 'USA', value: 'option5' },
  ];

  const [selected, setSelected] = useState([]);

  const handleChange = (selected) => {
    setSelected(selected);
  };

  // +++++++++++++++++++++++++++++++ Multiselect dropdown

  const gridRef = useRef(null);

  const [gridData, setGridData] = useState({
      Active: [],
      Archived: [],
      Trash: []

  });

  useEffect(() => {
    const fetchDataGrid = async () => {
      try {
        const res = await withRFSToken.post(STATIONGRID, {})
        .then(function(res) {
          const innerData = res.data.response_data; 
          setGridData(innerData);
        });
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
      // Call the fetchData function to fetch data from the API
      fetchDataGrid();
      
    }, []);

  const [activeTab, setActiveTab] = useState('Active');

  const [modalShow, setModalShow] = useState(false);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [gridSelect, setGridSelect] = useState([]);

  const [formData, setFormData] = useState({
    port_Id: '',
    code: ''
  });

  const gridRefs = {
    Active: useRef(null),
    Archived: useRef(null),
    Trash: useRef(null),
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
  };
  const handleDetailsBackClick = (rowData) => {
    // setSelectedRowData(rowData);
    setShowDetails(false);
  };

  const handleAddButtonClick = () => {
    fetchData();
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleDropdownChange = (e) => {
    setSelectedDropdownValue(e.target.value);
  };
  // const handleAddToGrid = () => {
  //   if (selectedDropdownValue.length > 0) {
  //     const newData = selectedDropdownValue.map(selectedStation => {
  //       const selectedData = dropdownData.find(item => item.value === selectedStation.value);
  //       return {
  //         id: selectedData.id,
  //         STN: selectedData.value,
  //         // Taddrs: selectedData.address.length.toString(),
  //         // address: selectedData.address,
  //       };
  //     });
  
  //     // This is to remove dropdown data from the list ... we need to update Api later
  //     setDropdownData(prevDropdownData => prevDropdownData.filter(item => !selectedDropdownValue.find(selected => selected.value === item.value)));

  //     setGridData(prevData => {
  //       // Ensure prevData.Active is an array, initialize as an empty array if null or undefined
  //       const prevActiveData = Array.isArray(prevData.Active) ? prevData.Active : [];
      
  //       // Concatenate prevActiveData and newData to update Active tab's data
  //       const updatedActiveData = [...prevActiveData, ...newData];
        
  //       // Return the updated gridData object with only Active tab's data updated
  //       return {
  //         ...prevData,
  //         Active: updatedActiveData,
  //       };
  //     });
  //     setSelectedDropdownValue([]);
  //     setModalShow(false);
  //     localStorage.setItem('ActiveData', JSON.stringify([...gridData.Active, ...newData]));
  //   }
  // };

  const handleAddToGrid = async() => {
    if (selectedDropdownValue.length > 0) {
      const sendStationData = selectedDropdownValue.map(selectedStation => {
        const selectedData = dropdownData.find(item => item.value === selectedStation.value);
        return {
          port_Id: selectedData.id,
          code: selectedData.value,
          // Taddrs: selectedData.address.length.toString(),
          // address: selectedData.address,
        };
      });
      console.log("🚀 ~ sendStationData ~ sendStationData:", sendStationData)

      // setFormData(sendStationData);
      if (sendStationData.length > 0) {
        // dispatch(addData(sendStationData)); // Pass sendStationData to addData thunk
        try {
          const res = await withRFSToken.post(ADDSTATION, {addData:sendStationData})
          .then(function(res) {
            // const innerData = res;
            console.log("🚀 ~ .then ~ innerData:", res)
            if(res.data.response_code == 200){
              reloadGrid();
            }
          });
         
        } catch (error) {
          console.error('Error sending request:', error);
        }
      }
      
      // dispatch(addData(formData));
      // dispatch(fetchStationGridData());
      setSelectedDropdownValue([]);
      setModalShow(false);
    }
    console.log('65', formData);
  };



  // console.log('grid-data', gridData)
  // console.log('mm')
  // console.log("🚀 ~ localData ~ localData:", gridData[activeTab])
  // console.log("🚀 ~ localData ~ Active:", activeTab)
  useEffect(() => {
    
    if (gridRefs[activeTab].current && !showDetails) {
      
      if (gridData[activeTab]) {
      const localData = gridData[activeTab].map(item => ({

        id: item.id,
        STN: item.station_code,
        Taddrs: item.station_addresses.length,
        Address: item.station_addresses // ?.join(', ')Join the address array into a string
      }));    
     console.log("🚀 ~ localData ~ localData:", localData)
      const source = {
        datatype: 'json',
        id: `${activeTab}-`,
        datafields: [
          { name: 'id', type: 'string'},
          { name: 'STN', type: 'string' },
          { name: 'Taddrs', type: 'string' },
          { name: 'Address', type: 'string' },
        ],
        localdata: localData,
      };

      const dataAdapter = new window.$.jqx.dataAdapter(source);
      console.log('localdata',source.localdata)
      const columns = [
        // { text: 'id', filtertype: 'textbox', datafield: 'id', width: '40%' },
        { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '40%', cellclassname:'frcl', },
        { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%',cellclassname:'frcl', },
      ];

      const gridOptions = {
        width: '100%',
        source: dataAdapter,
        columns: columns,
        autoheight: true,
        sortable: true,
        altrows: false,
        enabletooltips: true,
        editable: false,
        selectionmode: 'checkbox',
        pageable: false,
        pageSize: 10,
        pagerButtonsCount: 5,
        pagerMode: 'advanced',
        filterable: true,
        filterrowheight: 45,
        showfilterrow: true,
        rowsheight: 42,
        columnsheight: 45,
        
      };

      window.$(gridRefs[activeTab].current).jqxGrid(gridOptions);
      window.$(gridRefs[activeTab].current).find('.jqx-grid-column-header').css('background-color', 'white');

      window.$(gridRefs[activeTab].current).on('rowclick', (event) => {
        console.log('event', event);
        const args = event.args.row.bounddata;
        console.log('args', args);
        const sendRowId = gridData[activeTab];
        console.log("🚀 ~ window.$ ~ sendRowId:", sendRowId)
        setSelectedRowData({args, station_id:args.id});
        
        setShowDetails(true);
      });
      window.$(gridRefs[activeTab].current).find('div[role="row"]').each((index, row) => {
        window.$(row).on('mouseover', () => {
            window.$(row).addClass('row-hover');
        });
        window.$(row).on('mouseout', () => {
            window.$(row).removeClass('row-hover');
        });
    });
    // row selection by checkbox
    window.$(gridRefs[activeTab].current).on('rowselect', (event) => {
      console.log("🚀 ~ window.$ ~ event:00000000", event.args.row)
      
        if (event.args.row) {
            
            const selectedRow = event.args.row.id;
            
            if (!gridSelect.includes(selectedRow)) {
                  setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
              }
              setGridSelect((prevGridSelect) => [...prevGridSelect, selectedRow]);
        } else {
            // Handle "Select All" case
            // const allIds = planData.map(row => row.booking_id);
              // console.log("All IDs:", allIds);
              // setGridSelect(allIds);
            console.log("Select All triggered");
        }
      }); 
      // console.log('ggg', gridSelect);
      window.$(gridRefs[activeTab].current).on('rowunselect', (event) => {
        if (event.args.row) {
        const unselectedRowId = event.args.row.id;
        setGridSelect(prevSelection => prevSelection.filter(id => id !== unselectedRowId));
        }else{
          setGridSelect([]);
        }
      });
      // End row selection 
      
    } else {
      const columns = [
        { text: 'Stations', filtertype: 'textbox', datafield: 'STN', width: '50%' },
        { text: 'Total Address', filtertype: 'textbox', datafield: 'Taddrs', width: '50%' },
      ];
    }
    }
  }, [activeTab, gridData, showDetails]);
// row click function start
const handleArchiveButtonClick = () => {
  archiveSelectedRows(gridSelect);
};
const archiveSelectedRows = async(selectedIds) => {
  // Perform deletion logic here using the selected IDs
  console.log("Archive selected IDs:", selectedIds);
  const uniqueIds = Array.from(new Set(selectedIds));
  // Now you can send the selected IDs to your API or perform any other action
  if (selectedIds.length > 0) {
    // dispatch(addStationArchiveData(selectedIds)); // Pass sendStationData to addData thunk
    try {
    
      // Make API call here await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
      // Example:
      const response = await withRFSToken.post(ARCSTATIONGRID, {truck_stations:uniqueIds});
      console.log("🚀 ~ addData ~ response:", response)
      if (!response.ok) {
        throw new Error('Failed to add data');
      }
      // dispatch(addDataSuccess(data));
      // dispatch(fetchStationGridData());
      reloadGrid();
    } catch (error) {
      // dispatch(addDataFailure(error.message));
      console.error('Error fetching data:', error);
    }
  }
  window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
  window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
  // dispatch(fetchStationGridData());
  reloadGrid();
};

const handleActiveButtonClick = () => {
  activeSelectedRows(gridSelect);
};
const activeSelectedRows = async(selectedIds) => {
  // Perform deletion logic here using the selected IDs
  console.log("Archive selected IDs:", selectedIds);
  const uniqueIds = Array.from(new Set(selectedIds));
  // Now you can send the selected IDs to your API or perform any other action
  if (selectedIds.length > 0) {
    // dispatch(addStationArchiveData(selectedIds)); // Pass sendStationData to addData thunk
    try {
    
      // Make API call here await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
      // Example:
      const response = await withRFSToken.post(ACTSTATIONGRID, {truck_stations:uniqueIds});
      console.log("🚀 ~ addData ~ response:", response)
      if (!response.ok) {
        throw new Error('Failed to add data');
      }
      // dispatch(addDataSuccess(data));
      // dispatch(fetchStationGridData());
      reloadGrid();
    } catch (error) {
      // dispatch(addDataFailure(error.message));
      console.error('Error fetching data:', error);
    }
  }
  window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
  window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
  // dispatch(fetchStationGridData());
  reloadGrid();
};

const handleDeleteButtonClick = () => {
  deleteSelectedRows(gridSelect);
  // if (gridSelect){
  //   handleShowConfirmation(deleteSelectedRows);
  // }
};
const deleteSelectedRows = async(selectedIds) => {
  // Perform deletion logic here using the selected IDs
  console.log("Archive selected IDs:", selectedIds);
  const uniqueIds = Array.from(new Set(selectedIds));
  // Now you can send the selected IDs to your API or perform any other action
  if (selectedIds.length > 0) {
    // dispatch(addStationRemoveData(selectedIds)); // Pass sendStationData to addData thunk
    try {
    
      // Make API call here await withToken.post(SUBMIT_SHIPMENT_FORM, {formData});
      // Example:
      const response = await withRFSToken.post(REMSTATIONGRID, {truck_stations:uniqueIds});
      console.log("🚀 ~ addData ~ response:", response)
      if (!response.ok) {
        throw new Error('Failed to add data');
      }
      // dispatch(addDataSuccess(data));
      // dispatch(fetchStationGridData());
      reloadGrid();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  window.$(gridRefs[activeTab].current).jqxGrid('unselectrow', selectedIds);
  window.$(gridRefs[activeTab].current).jqxGrid('clearselection');
  // dispatch(fetchStationGridData());
  reloadGrid();
};


const reloadGrid = async () => {
  try {
    const res = await withRFSToken.post(STATIONGRID, {})
    .then(function(res) {
      const innerData = res.data.response_data; 
      setGridData(innerData);
    });

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};



  return (
    <>
      <div className="">
        <Header />
        <div className="main-panel">
          <div className="row">
            <LeftSettings />
            <div className='col-9 col-xxl-9 stting_right'>
              <div className='settingbg_right'>
              {!showDetails && 
                <>    
                <h4>Station Settings</h4>
                <p>Set your notification preferences for when you are in or away from the dashboard.</p>

                <div className='row'>

                  <div className='col-6'>

                    <div>
                      <Button variant="primary createplan" onClick={handleAddButtonClick}>
                        <FontAwesomeIcon icon={faPlus} /> Add Station
                      </Button>
                    </div>

                    <Tabs defaultActiveKey={activeTab} onSelect={(tab) => handleTabChange(tab)} id="station-tabs" className="station_grid">
                        <Tab eventKey="Active" title={<span>Active</span>} className='moveto'> 
                          <div className='moveto_btn'>
                            <button onClick={handleArchiveButtonClick}> <img src={Arcv}/></button>
                            <button onClick={handleDeleteButtonClick}><img src={Trash} /></button>
                          </div>
                          <div ref={gridRefs.Active} className='staion_grid' ></div>
                        </Tab>
                        <Tab eventKey="Archived" title={<span>Archived</span>} className='moveto'>
                          <div className='moveto_btn'>
                            <button onCanPlay={handleActiveButtonClick}> <img title='' src={Active}/></button>
                            <button onClick={handleDeleteButtonClick}><img src={Trash} /></button>
                          </div>
                          <div ref={gridRefs.Archived} className='staion_grid' ></div>
                        </Tab>
                        <Tab eventKey="Trash" title={<span>Trash</span>} className='moveto'>
                          <div className='moveto_btn'>
                            <button onClick={handleActiveButtonClick}> <img src={Active}/></button>
                          </div>
                          <div ref={gridRefs.Trash} className='staion_grid' ></div>
                        </Tab>
                      </Tabs>
                    
                  </div>
                </div>

                <Modal show={modalShow} centered onHide={handleModalClose} className='addfrom' >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Stations</Modal.Title>
                    <p>Add stations by selecting the station.</p>
                  </Modal.Header>
                  <Modal.Body>

                  <Form.Group controlId="dropdown">
                  <MultiSelect
                    options={dropdownData}
                    value={selectedDropdownValue}
                    onChange={setSelectedDropdownValue}
                    labelledBy="Select "
                    className='multiselect'
                  />
                </Form.Group>

                    {/* <Form.Group controlId="dropdown">
                      <Form.Select as="select" onChange={handleDropdownChange}>
                        <option value="">Select Stations</option>
                        {dropdownData.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleAddToGrid}>
                      Add Stations
                    </Button>
                  </Modal.Footer>
                </Modal>
                </>
                }
                {showDetails && (
                  <>
                  <button className='backbtn' onClick={handleDetailsBackClick}> <FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                  <StationDetails rowData={selectedRowData} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StationSettings;